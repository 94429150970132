import {useState} from 'react';
import {IonModal, IonIcon} from '@ionic/react';
import {arrowBackOutline, closeOutline} from 'ionicons/icons';
import './styles.scss';
import Header from 'src/components/Header';
import Snackbar from 'src/components/Snackbar';
import ContactCustomer from '../ContactCustomer';
import EditOrderItems from '../EditOrderItems';
import DenyOrder from '../DenyOrder';
import SubstitutionsList from '../SubstitutionsList';
import EditOrderErrorModal from '../EditOrderErrorModal';
import orderService from 'src/services/order';
import inventoryService from 'src/services/inventory';
import {useAppDispatch, useAppSelector} from 'src/hooks';
import {hideLoading, showLoading} from 'src/store/loadingSlice';
import {patchCart, processOrder} from 'src/store/orderSlice';
import {showAlert} from 'src/store/alertSlice';
import {bulkArchiveItems, fetchInventoryCount} from 'src/store/inventorySlice';
import {IOrderUpdatePayloadItem, ISubstituteListItem} from 'src/interfaces/order';
import {PATCH_CART_TYPE, ORDER_STATUS, DENY_ORDER_REASON} from 'src/enums/order';
import AnalyticsHelper from 'src/utils/segment';
import {PARTNER} from 'src/enums/partner';
import {useFeatureFlagEnabled} from 'posthog-js/react';
import {IOrderItemDetails} from 'src/interfaces/item';

interface IProps {
  isOpen: boolean;
  order: any;
  fromOrders: boolean;
  setOrder: (order: any) => void;
  fetchOrder: (orderId: string) => void;
  goToOrders: () => void;
  closeEditModal: () => void;
}

const EditOrderModal = ({isOpen, fromOrders, order, setOrder, fetchOrder, goToOrders, closeEditModal}: IProps) => {
  const dispatch = useAppDispatch();

  const {id: storeId} = useAppSelector((state) => state.store);

  const [step, setStep] = useState(1);
  const [outOfStockItems, setOutOfStockItems] = useState<Array<string>>([]);
  const [showSubstitutionList, setShowSubstitutionsList] = useState(false);
  const [substitutionList, setSubstitutionList] = useState<Array<ISubstituteListItem>>([]);
  const [payload, setPayload] = useState<Array<IOrderUpdatePayloadItem>>([]);
  const [itemToSubstitute, setItemToSubstitute] = useState<any>();
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const isLingeringOrderHandlerEnabled = useFeatureFlagEnabled('lingering-order-handler');
  const [dataForDetailsModal, setDataForDetailsModal] = useState<IOrderItemDetails | null>(null);

  const dismissSnackbar = () => setShowSnackbar(false);

  const clearState = () => {
    setOutOfStockItems([]);
    setSubstitutionList([]);
    setPayload([]);
  };

  const goToPreviousStep = () => {
    if (showSubstitutionList) setShowSubstitutionsList(false);
    else if (step > 1) {
      setStep(step - 1);
      clearState();
    }
  };

  const closeOrderEditModal = async () => {
    closeEditModal();
    // Not sure why but somehow await works here.
    await setDataForDetailsModal(null);
    clearState();
    setStep(1);
    setShowSubstitutionsList(false);
  };

  const updateLingeringOrder = (order: any, callback: any) => {
    dispatch(showLoading());
    if (order?.storeId && order?.id) {
      orderService
        .updateLingeringOrder(order?.storeId, order?.id)
        .then((response) => {
          dispatch(hideLoading());
          if (response?.success) {
            dispatch(processOrder({id: order?.id, status: response?.status}));
            fetchOrder(order?.id);
            closeOrderEditModal();
          } else {
            callback();
          }
        })
        .catch((error) => {
          dispatch(hideLoading());
          callback();
        })
        .finally(() => {
          dispatch(hideLoading());
        });
    } else {
      dispatch(hideLoading());
      callback();
    }
  };

  const archiveItems = async (orderItemIds: Array<string>) => {
    // dispatch(showLoading());
    try {
      // TODO: Store store item ids in outOfStockItems array instead of order item ids.
      const parsedItems = orderItemIds.map((orderItemId) => {
        const storeItem = order?.orderItems?.find((oi: any) => oi.id === orderItemId);
        return {storeItemId: storeItem.storeItemId};
      });
      inventoryService.patchArchivedItem(
        storeId,
        {selectedItems: parsedItems, exceptItems: [], selectedCategories: []},
        false,
      );
      dispatch(fetchInventoryCount());
      dispatch(bulkArchiveItems({items: parsedItems}));
      for (let item of parsedItems) {
        AnalyticsHelper.trackItemOutOfStockSelected(order.id, item.storeItemId);
      }
      // dispatch(hideLoading());
    } catch (error: any) {
      // dispatch(hideLoading());
      dispatch(
        showAlert({
          heading: `Unable to set items out of stock.`,
          message: error?.message || `Some error occurred while setting items out of stock. Please, try again later`,
        }),
      );
    }
  };

  const updateOrder = (payload: Array<IOrderUpdatePayloadItem>) => {
    if (order) {
      const removedItems = payload.filter((p) => p.action_type === PATCH_CART_TYPE.REMOVE_ITEM);
      const inStockItems = payload.filter((p) => p.action_type === PATCH_CART_TYPE.REDUCE_QUANTITY);
      // Tracking for all items that have been edited or substituted in an order.
      if (substitutionList.length > 0 || inStockItems.length > 0) {
        AnalyticsHelper.trackOrderUpdated(order, substitutionList, inStockItems);
      }
      dispatch(showLoading());
      orderService
        .patchOrderCart(order?.id, payload)
        .then((response) => {
          if (response) {
            dispatch(hideLoading());
            // Marking items as out of stock in our system.
            if (outOfStockItems.length > 0) {
              archiveItems(outOfStockItems);
            }
            // Tracking items where quantity changed.
            if (inStockItems.length > 0) {
              for (let item of inStockItems) {
                if (item?.quantity) {
                  AnalyticsHelper.trackItemQuantityUpdated(order, item?.order_item_id, item?.quantity);
                }
              }
            }
            // Tracking removed items.
            if (removedItems.length > 0) {
              for (let item of removedItems) {
                AnalyticsHelper.trackRemoveItem(order, item?.order_item_id);
              }
            }
            setOrder(response);
            fetchOrder(order?.id);
            dispatch(patchCart(response));
            closeOrderEditModal();
            setShowSnackbar(true);
          }
        })
        .catch((_) => {
          dispatch(hideLoading());
          const onErrorShowErrorModal = () => {
            setShowErrorModal(true);
          };
          if (order.partner === PARTNER.UBER_EATS && isLingeringOrderHandlerEnabled) {
            updateLingeringOrder(order, onErrorShowErrorModal);
          } else {
            onErrorShowErrorModal();
          }
        });
    }
  };

  const cancelOrder = (reason: string, msg: string = '') => {
    if (reason) {
      if (order?.id) {
        dispatch(showLoading());
        let data: {store_id: string; reason: string; message?: string} = {store_id: storeId, reason};
        if (reason === DENY_ORDER_REASON.OTHER) {
          data.message = msg;
        }
        orderService
          .cancelOrder(order.id, data)
          .then((response) => {
            dispatch(hideLoading());
            dispatch(processOrder({id: order.id, status: ORDER_STATUS.DENIED}));
            AnalyticsHelper.trackDenyOrder(order, reason);
            // Marking items out of stock in our system.
            if (outOfStockItems.length > 0) {
              archiveItems(outOfStockItems);
            }
            closeOrderEditModal();
            // Redirecting back to orders.
            goToOrders();
          })
          .catch((error) => {
            dispatch(hideLoading());
            const onErrorShowDispatch = () => {
              dispatch(
                showAlert({
                  heading: 'Error',
                  message: error?.message || 'Sorry! Cannot cancel your order.',
                }),
              );
            };
            if (order.partner === PARTNER.UBER_EATS && isLingeringOrderHandlerEnabled) {
              updateLingeringOrder(order, onErrorShowDispatch);
            } else {
              onErrorShowDispatch();
            }
          });
      }
    } else {
      dispatch(showAlert({heading: 'Error', message: 'Please select a reason'}));
    }
  };

  const onChangeOutOfStock = (itemId: string) => {
    if (outOfStockItems.includes(itemId)) {
      setOutOfStockItems((outOfStockItems) => outOfStockItems.filter((id) => id !== itemId));
      // Only remove if previously action is of remove item.
      if (payload.some((i) => i.order_item_id === itemId && i.action_type === PATCH_CART_TYPE.REMOVE_ITEM)) {
        setPayload(payload.filter((p) => p.order_item_id !== itemId));
      }
    } else {
      setOutOfStockItems([...outOfStockItems, itemId]);
      // Handling a situation where user previously reduced quantity or marked out of stock.
      if (payload.some((p) => p.order_item_id === itemId && p.action_type !== PATCH_CART_TYPE.REPLACE_ITEM)) {
        const updatedPayload = payload.filter((i) => i.order_item_id !== itemId);
        setPayload([...updatedPayload, {action_type: PATCH_CART_TYPE.REMOVE_ITEM, order_item_id: itemId}]);
      }
      // If item is not present then add it.
      else if (!payload.some((p) => p.order_item_id === itemId)) {
        setPayload((payload) => [...payload, {action_type: PATCH_CART_TYPE.REMOVE_ITEM, order_item_id: itemId}]);
      }
    }
  };

  const onSubstituteItem = (substituteListItem: ISubstituteListItem) => {
    if (!outOfStockItems.includes(substituteListItem.orderItemId)) {
      setOutOfStockItems([...outOfStockItems, substituteListItem.orderItemId]);
    }
    setSubstitutionList([...substitutionList, substituteListItem]);
    // Remove item from payload if there is edit quantity or replace item action of same item.
    const updatedPayload = payload.filter((i) => i.order_item_id !== substituteListItem.orderItemId);
    // Add action to payload.
    const payloadObj = {
      action_type: PATCH_CART_TYPE.REPLACE_ITEM,
      order_item_id: substituteListItem.orderItemId,
      substituted_store_item_id: substituteListItem.substituteItem.storeItemId,
      substituted_store_item_quantity: substituteListItem.substituteItemQuantity,
    };
    setPayload([...updatedPayload, payloadObj]);
    AnalyticsHelper.trackSubstitutionItemSelected(order.id, itemToSubstitute, substituteListItem);
  };

  const onUndoSubstitution = (itemId: string) => {
    const substitutionListItem = substitutionList.find((item) => item.substituteItem.id === itemId);
    if (!!substitutionListItem) {
      // Remove item from substitution list.
      setSubstitutionList(substitutionList.filter((item) => item.substituteItem.id !== itemId));
      // Remove from out of stock items list.
      setOutOfStockItems((outOfStockItems) => outOfStockItems.filter((id) => id !== substitutionListItem.orderItemId));
      // Remove replace item action from payload.
      setPayload(payload.filter((item) => item.substituted_store_item_id !== itemId));
      AnalyticsHelper.trackSubstitutionItemReverted(order.id, itemToSubstitute.id, itemId);
    }
  };

  return (
    <>
      <IonModal className="app-modal edit-order-modal" isOpen={isOpen} onDidDismiss={closeOrderEditModal} showBackdrop>
        <Header
          border
          noBackground
          title={showSubstitutionList ? 'Select substitution' : step === 3 ? 'Denial reason' : 'Edit order'}
          rightButton={<IonIcon icon={closeOutline} onClick={closeOrderEditModal} />}
          leftButton={
            (step > 1 || showSubstitutionList) && <IonIcon icon={arrowBackOutline} onClick={goToPreviousStep} />
          }
        />
        <div className="modal-content-container">
          {showSubstitutionList ? (
            <SubstitutionsList
              isSubstitution // Prop to use in withScanner HOC.
              itemToSubstitute={itemToSubstitute}
              setShowSubstitutionsList={setShowSubstitutionsList}
              onSubstituteItem={onSubstituteItem}
              orderPartner={order.partner}
            />
          ) : (
            <>
              {step === 1 && <ContactCustomer order={order} goNext={() => setStep(2)} />}
              {step === 2 && (
                <EditOrderItems
                  order={order}
                  fromOrders={fromOrders}
                  payload={payload}
                  substitutionList={substitutionList}
                  outOfStockItems={outOfStockItems}
                  dataForDetailsModal={dataForDetailsModal}
                  onChangeOutOfStock={onChangeOutOfStock}
                  undoSubstitution={onUndoSubstitution}
                  setPayload={setPayload}
                  updateOrder={updateOrder}
                  closeEditModal={closeOrderEditModal}
                  setShowSubstitutionsList={setShowSubstitutionsList}
                  goNext={() => setStep(3)}
                  setItemToSubstitute={setItemToSubstitute}
                  setSubstitutionList={setSubstitutionList}
                  setDataForDetailsModal={setDataForDetailsModal}
                />
              )}
              {step === 3 && (
                <DenyOrder
                  order={order}
                  outOfStockItems={outOfStockItems}
                  setOutOfStockItems={setOutOfStockItems}
                  cancelOrder={cancelOrder}
                  updateOrder={updateOrder}
                />
              )}
            </>
          )}
        </div>
      </IonModal>

      <EditOrderErrorModal
        isOpen={showErrorModal}
        order={order}
        payload={payload}
        substitutionList={substitutionList}
        updateOrder={updateOrder}
        closeModal={() => setShowErrorModal(false)}
      />

      <Snackbar
        show={showSnackbar}
        type="success"
        title="Edit successful"
        onButtonClick={dismissSnackbar}
        onDismiss={dismissSnackbar}
      />
    </>
  );
};

export default EditOrderModal;
