import {CommandsArray, Printers, StarPRNT, RasterObj} from '@awesome-cordova-plugins/star-prnt';
import {Directory, Filesystem} from '@capacitor/filesystem';
import moment from 'moment';
import {OrderInfo, OrderItem} from 'src/interfaces/order';
import {store} from 'src/store';
import {PRINTER_ENUM, setPrinter, setStatusEvents} from 'src/store/printerSlice';
import AnalyticsHelper from 'src/utils/segment';
import {getStateAbbreviation} from 'src/utils/states';
import {getItem, setItem} from 'src/utils/storage';
import storeService from './store';
import posthog from 'posthog-js';

export enum PRINTER_CHAR_LIMIT {
  TWO_INCH = 27,
  THREE_INCH = 41,
}

export enum PRINT_TRIGGER {
  AUTO = 'AUTO',
  MANUAL = 'MANUAL',
}

export type PrintRequest = {
  id: string;
  cmd: CommandsArray;
  force: boolean;
  rasterObj?: RasterObj;
  order?: OrderInfo;
  macAddress?: string;
  trigger?: PRINT_TRIGGER.AUTO | PRINT_TRIGGER.MANUAL;
};

/************* If debug mode enabled, it will not print receipt *************/
const DEBUG_PRINTER = (process.env.REACT_APP_DEBUG_PRINTER || '') === 'TRUE';

export const PRINTER_MODEL_EMULATION_MAPPING: {[key: string]: string} = {
  mPOP: 'StarPRNT',
  'mC-Print3': 'StarLine',
  FVP10: 'StarLine',
  TSP100: 'StarGraphic',
  TSP650II: 'StarLine',
  TSP700II: 'StarLine',
  TSP800II: 'StarLine',
  SP700: 'StarDotImpact',
  'SM-S210i': 'EscPosMobile',
  'SM-S220i': 'EscPosMobile',
  'SM-S230i': 'EscPosMobile',
  'SM-T300i/T300': 'EscPosMobile',
  'SM-T400i': 'EscPosMobile',
  'SM-L200': 'StarPRNT',
  'SM-L300': 'StarPRNT',
  BSC10: 'EscPos',
  'mC-Print3 StarPRNT': 'StarPRNT',
  'SM-S210i StarPRNT': 'StarPRNT',
  'SM-S220i StarPRNT': 'StarPRNT',
  'SM-S230i StarPRNT': 'StarPRNT',
  'SM-T300i/T300 StarPRNT	': 'StarPRNT',
  'SM-T400i StarPRNT': 'StarPRNT',
};

export const PRINTER_MODEL_LOCAL_KEY = 'printer_model';
export const PRINTER_PORT_LOCAL_KEY = 'printer_port';

export class PrinterService {
  private static instance: PrinterService;
  private printRequestQueue: PrintRequest[] = [];
  private lastPrintedTime: Date = new Date(0);
  private interval: NodeJS.Timeout;

  constructor() {
    this.interval = setInterval(async () => {}, 10000000);
  }

  private async getPaperSize(): Promise<number> {
    const emulation = await this.getEmulation();

    // ! For some reason, StarGraphics models are a little bit smaller
    return emulation === 'StarGraphic' ? 38 : PRINTER_CHAR_LIMIT.THREE_INCH;
  }

  public async getEmulation(): Promise<string> {
    const model = await this.getModel();

    if (!model) {
      return '';
    }

    const emulation = PRINTER_MODEL_EMULATION_MAPPING[model];

    return emulation;
  }

  public async getModel(): Promise<string> {
    const model = await getItem(PRINTER_MODEL_LOCAL_KEY);

    if (!model) {
      return '';
    }

    return model;
  }

  public async getPort(): Promise<string> {
    const port = await getItem(PRINTER_PORT_LOCAL_KEY);

    if (!port) {
      return '';
    }

    return port;
  }

  public stopPrintingInterval(): void {
    if (this.interval) {
      clearInterval(this.interval);
    }

    this.printRequestQueue = [];
  }

  public startPrintingInterval(): NodeJS.Timeout {
    if (this.interval) {
      clearInterval(this.interval);
    }
    this.interval = setInterval(async () => {
      const printer = store.getState()?.printer.printer;
      const emulation = await this.getEmulation();
      const portName = await this.getPort();
      this.printRequestQueue = this.printRequestQueue.filter(
        (value, index, self) => index === self.findIndex((t) => t.id === value.id),
      );
      if (!printer || this.printRequestQueue.length === 0 || !printer.connectionStatus || !portName || !emulation) {
        return;
      }

      let req = this.printRequestQueue.pop()!;

      while (this.printRequestQueue.length > 0 && req.cmd.length === 0) {
        // * If it's a print for checkup, we can print the real one
        req = this.printRequestQueue.pop()!;
      }
      try {
        /******** If debug mode enabled, it will not print receipt ********/
        if (!DEBUG_PRINTER) {
          if (req.cmd.length === 0 || printer.shouldPrintOrder || req.force) {
            await this.print(printer.portName || '', emulation, req.cmd);
            if (req.rasterObj) {
              await StarPRNT.printRasterReceipt(printer.portName || '', emulation, req.rasterObj);
            }
          }
        }

        this.lastPrintedTime = new Date();
      } catch (err) {
        this.printRequestQueue.splice(0, 0, req);
        console.log('PRINTER DEBUG: ', err);
        return;
        // if it does not work, it should add the print job back in to the queue
      }
      if (req.order && req.trigger && req.macAddress) {
        AnalyticsHelper.trackPrinterJob(req.order, req.trigger, req.macAddress);
      }
    }, 6000);

    return this.interval;
  }

  private async getLogoUri(): Promise<string> {
    let printLogoImageUrl = '';
    try {
      printLogoImageUrl = await storeService.getStorePrintLogoImage();
    } catch (err) {}

    const response = await fetch(
      printLogoImageUrl ? printLogoImageUrl : 'https://lula-brand.s3.amazonaws.com/receiptLogo.jpg',
      {
        cache: 'no-store',
      },
    );

    const blob = await response.blob();
    const base64Data = (await this.convertBlobToBase64(blob)) as string;

    const savedFile = await Filesystem.writeFile({
      path: `${new Date().getTime()}.jpg`,
      data: base64Data,
      directory: Directory.Data,
    });

    return savedFile.uri;
  }

  private convertBlobToBase64 = (blob: Blob) =>
    new Promise((resolve, reject) => {
      let reader = new FileReader();

      const realFileReader = (reader as any)._realReader;
      if (realFileReader) {
        reader = realFileReader;
      }

      reader.onerror = reject;
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.readAsDataURL(blob);
    });

  public async autoSelectPrinter(): Promise<string> {
    const printer = store.getState()?.printer.printer;
    const emulation = await await this.getEmulation();
    if (emulation && emulation !== '' && (!printer.portName || printer.portName === '')) {
      const nearByPrinter = await this.getNearByPrinters();
      if (nearByPrinter && nearByPrinter.length !== 0) {
        setItem(PRINTER_PORT_LOCAL_KEY, nearByPrinter[0].portName || '');
        setPrinter(nearByPrinter[0]);
        return nearByPrinter[0]?.portName || '';
      }
    }
    return '';
  }

  public static getInstance(): PrinterService {
    if (!PrinterService.instance) {
      PrinterService.instance = new PrinterService();
    }

    return PrinterService.instance;
  }

  public async checkWasConnected(): Promise<boolean> {
    const wasConnected = await getItem(PRINTER_ENUM.wasConnected);

    if (!wasConnected) {
      return false;
    }

    return wasConnected === 'true';
  }

  public async getShouldPrintOrder(): Promise<boolean> {
    const shouldPrintOrder = await getItem(PRINTER_ENUM.shouldPrintOrder);

    if (!shouldPrintOrder) {
      return false;
    }

    return shouldPrintOrder === 'true';
  }

  public async getNearByPrinters(): Promise<Printers> {
    const printers = await StarPRNT.portDiscovery('Bluetooth');
    return printers;
  }

  public async print(portName: string, emulation: string, cmd: CommandsArray): Promise<void> {
    return await StarPRNT.print(portName, emulation, cmd);
  }

  public async disconnect() {
    store.dispatch(setStatusEvents(PRINTER_ENUM.printerOffline));
    return await StarPRNT.disconnect();
  }

  public connect(
    port: string,
    emulation: string,
    onNext: any,
    onError: any,
    onComplete: any,
    hasBarcodeReader = false,
  ) {
    const observer = {
      next: (successMessage: any) => {
        if (onNext) {
          onNext(successMessage);
        }
      },
      error: (errorMessage: any) => {
        if (onError) {
          onError(errorMessage);
        }
      },
      complete: () => {
        if (onComplete) {
          onComplete();
        }
      },
    };

    StarPRNT.connect(port, emulation, hasBarcodeReader).subscribe(observer);
  }

  public getStatus(callback: any) {
    StarPRNT.getStatus().subscribe((status) => {
      callback(status.dataType);
    });
  }

  public connectPrinter() {
    const printer = store.getState()?.printer.printer;
    if (!printer?.portName || printer?.portName === '') return;
    if (!printer?.emulation || printer?.emulation === '') return;
    const callback = (status: any) => {
      store.dispatch(setStatusEvents(status));
    };
    const onNext = (successMessage: any) => {
      this.getStatus(callback);
    };
    const onError = (errorMessage: any) => {
      store.dispatch(setStatusEvents(PRINTER_ENUM.printerImpossible));
    };
    const onComplete = () => {};
    this.connect(printer.portName, printer.emulation, onNext, onError, onComplete);
  }

  private async prepareReceiptHeader(cmd: CommandsArray): Promise<void> {
    cmd.push({appendCodePage: 'UTF8'});
    cmd.push({appendEncoding: 'UTF-8'});
    cmd.push({appendInternational: 'USA'});
    cmd.push({appendCharacterSpace: 2});
    cmd.push({appendAlignment: 'Right'});
  }

  public async schedulePrintOrder(order: OrderInfo, force = false): Promise<void> {
    const state = store.getState();
    const emulation = await this.getEmulation();
    const macAddress = state?.printer?.printer?.macAddress;
    const trigger = force ? PRINT_TRIGGER.MANUAL : PRINT_TRIGGER.AUTO;
    if (state?.printer?.printer?.shouldPrintOrder || DEBUG_PRINTER || force) {
      // * Star Graphic models can only print images (bitmaps)
      // TODO: Use strategy pattern here
      const {cmd, rasterObj} = await this.printOrder(order, emulation === 'StarGraphic');
      this.printRequestQueue.splice(0, 0, {
        cmd: cmd,
        id: order.id,
        rasterObj,
        force,
        order,
        macAddress,
        trigger,
      });
    }
  }

  // It tries to break the string at spaces to avoid splitting words, ensuring a more readable result
  private splitter(str: string, limit: number, MAX_CHAR: number = 150): string[] {
    const result = [];

    let target = str;
    if (target.length > MAX_CHAR) {
      target = target.slice(0, MAX_CHAR);
      target += '...';
    }

    while (target.length > limit) {
      let pos = target.substring(0, limit).lastIndexOf(' ');
      pos = pos <= 0 ? limit : pos;
      result.push(target.substring(0, pos));
      let i = target.indexOf(' ', pos) + 1;
      if (i < pos || i > pos + limit) {
        i = pos;
      }
      target = target.substring(i);
    }
    result.push(target);
    return result;
  }

  private normalizePhone(phoneNumber: string): string {
    let result = `${phoneNumber}`;
    result = result.replace(/\+/g, '');
    result = result.replace(/\(/g, '');
    result = result.replace(/\)/g, '');
    result = result.replace(/-/g, '');
    result = result.replace(/ /g, '');

    if (result.length > 0 && result[0] === '1') {
      result = result.substring(1);
    }

    if (result.length !== 10) {
      return phoneNumber;
    }

    result = `(${result.substring(0, 3)}) ${result.substring(3, 6)}-${result.substring(6, 10)}`;

    return result;
  }

  private addTextLine(
    line: string,
    cmd: CommandsArray,
    printData: string[],
    emphasis = false,
    rasterMode = false,
    cmdFormatObj: object = {},
  ): void {
    if (!rasterMode) {
      cmd.push({...cmdFormatObj, appendAlignment: 'Left'});
      if (emphasis) {
        cmd.push({...cmdFormatObj, appendEmphasis: line});
      } else {
        cmd.push({...cmdFormatObj, append: line});
      }
    }
    printData.push(line);
  }

  private async formatLineInTwoColumns(
    left: string,
    right: string,
    cmd: CommandsArray,
    printData: string[],
    rasterMode = false,
    leftEmphasis = false,
    rightEmphasis = false,
    cmdFormatObj: object = {},
  ): Promise<void> {
    if (!rasterMode) {
      const paperSize = await this.getPaperSize();
      const spacesNeeded = paperSize - (left.length + right.length);
      left = left.concat(' '.repeat(spacesNeeded));

      cmd.push({appendAlignment: 'Left'});
      if (leftEmphasis) cmd.push({...cmdFormatObj, appendEmphasis: left});
      else cmd.push({...cmdFormatObj, append: left});
      printData.push(left);

      cmd.push({appendAlignment: 'Right'});
      if (rightEmphasis) cmd.push({...cmdFormatObj, appendEmphasis: right});
      else cmd.push({...cmdFormatObj, append: right});
      printData.push(right + '\n');
    }
  }

  private async addBlackLine(cmd: CommandsArray, printData: string[], rasterMode = false): Promise<void> {
    const paperSize: number = await this.getPaperSize();
    if (!rasterMode) {
      cmd.push({appendUnderline: `${' '.repeat(paperSize)}\n\n`});
    }
    printData.push(`${'-'.repeat(paperSize)}\n\n`);
  }

  private async printOrder(order: OrderInfo, rasterMode = false): Promise<{cmd: CommandsArray; rasterObj?: RasterObj}> {
    const state = store.getState();
    const tabSpacing = ' '.repeat(4);
    const cmd: CommandsArray = [];
    const printData: string[] = ['[LOGO_HERE]\n\n'];
    const isModifiersEnabled: boolean = posthog.isFeatureEnabled('menu-modifiers');
    const paperSize: number = await this.getPaperSize();
    this.prepareReceiptHeader(cmd);

    /******************* Store Logo *******************/
    cmd.push({appendAlignment: 'Center'});
    cmd.push({
      appendBitmap: await this.getLogoUri(),
      diffusion: true,
      width: 576,
      bothScale: true,
      absolutePosition: 0,
      alignment: 'Center',
    });
    this.addTextLine('\n', cmd, printData, false, rasterMode);

    /******************* Store Name *******************/
    const storeNameSplitted = this.splitter(
      `${state.store.name || 'Store name'}`,
      paperSize,
      state.store.name.length + 1,
    );
    for (const splittedItem of storeNameSplitted) {
      this.addTextLine(`${splittedItem}\n`, cmd, printData, true, rasterMode);
    }

    /******************* Store Address *******************/
    const streetAddress = `${state.store.address.line_1 || 'Store street address'}`;
    const streetAddressSplitted = this.splitter(streetAddress, paperSize, streetAddress.length + 1);
    for (const splittedItem of streetAddressSplitted) {
      this.addTextLine(`${splittedItem}\n`, cmd, printData, false, rasterMode);
    }

    const addressTrail = `${state.store.address.city || 'City name'}, ${
      getStateAbbreviation(state.store.address.state) || 'ST'
    } ${state.store.address.zip || '11111'}`;

    const addressTrailSplitted = this.splitter(addressTrail, paperSize, addressTrail.length + 1);
    for (const addressTrailComp of addressTrailSplitted) {
      this.addTextLine(`${addressTrailComp}\n`, cmd, printData, false, rasterMode);
    }

    /******************* Store Phone *******************/
    this.addTextLine(
      `${this.normalizePhone(state.store.phone || '(000) 000-0000')}\n`,
      cmd,
      printData,
      false,
      rasterMode,
    );

    /******************* Black Line *******************/
    await this.addBlackLine(cmd, printData, rasterMode);

    /******************* Partner *******************/
    await this.formatLineInTwoColumns('Partner', `${order.partner}`, cmd, printData, rasterMode, false, true);

    /******************* Customer *******************/
    await this.formatLineInTwoColumns('Customer', `${order.customerName}`, cmd, printData, rasterMode, false, true);

    /******************* Order number *******************/
    const idComponents = order.id.split('-');
    const idTail = idComponents[idComponents.length - 1];
    await this.formatLineInTwoColumns(
      'Order number',
      `${idTail.substring(idTail.length - 7).toUpperCase()}`,
      cmd,
      printData,
      rasterMode,
      false,
      true,
    );

    /******************* Ordered At *******************/
    await this.formatLineInTwoColumns(
      'Order at',
      `${moment(new Date(order.createdAt)).format('M/D/YY @ h:mm A')}`,
      cmd,
      printData,
      rasterMode,
      false,
      true,
    );

    /******************* Black Line *******************/
    await this.addBlackLine(cmd, printData, rasterMode);

    /******************* Customer Allergens ***************/
    if (order.allergens && order.allergens !== 'N/A') {
      const customerAllergensSplitted = this.splitter(`${order.allergens}`, paperSize, order.allergens.length + 1);
      this.addTextLine(`Customer allergens*\n`, cmd, printData, true, rasterMode);

      for (const splittedItem of customerAllergensSplitted) {
        this.addTextLine(`${splittedItem}\n\n`, cmd, printData, false, rasterMode);
      }
    }

    /******************* Order instructions *******************/
    if (order.customerNote && order.customerNote !== 'N/A') {
      const orderInstructionSplitted = this.splitter(`${order.customerNote}`, paperSize, order.customerNote.length + 1);
      this.addTextLine(`Order instructions*\n`, cmd, printData, true, rasterMode);

      for (const splittedItem of orderInstructionSplitted) {
        this.addTextLine(`${splittedItem}\n`, cmd, printData, false, rasterMode);
      }
    }

    /******************* Black Line *******************/
    ((order.allergens && order.allergens !== 'N/A') || (order.customerNote && order.customerNote !== 'N/A')) &&
      (await this.addBlackLine(cmd, printData, rasterMode));

    /******************* Order Items List *******************/
    const orderItemsCloned: OrderItem[] = JSON.parse(JSON.stringify(order.orderItems));
    orderItemsCloned.sort((a, b) => (b.quantity || 0) - (a.quantity || 0));
    let totalQuantity = 0;

    for (const item of orderItemsCloned) {
      /*********** Items Name ***********/
      const itemNameSplitted = this.splitter(
        item.itemName,
        paperSize - (item.quantity?.toFixed(0)?.length + tabSpacing.length + 1),
        item.itemName.length + 1,
      );
      itemNameSplitted[0] = `[ ] ${itemNameSplitted[0]}`;

      await this.formatLineInTwoColumns(
        itemNameSplitted[0],
        `x${item.quantity?.toFixed(0)}`,
        cmd,
        printData,
        rasterMode,
        true,
        false,
      );

      for (let i = 1; i < itemNameSplitted.length; i++) {
        itemNameSplitted[i] = `${tabSpacing}${itemNameSplitted[i]}\n`;
        this.addTextLine(itemNameSplitted[i], cmd, printData, true, rasterMode);
      }

      /*********** Item Size ***********/
      if (item.size) {
        const sizeContent = `Size - ${item.size}`;
        const itemSizeSplitted = this.splitter(
          sizeContent,
          paperSize - (tabSpacing.length + 1),
          sizeContent.length + 1,
        );
        for (const splittedItem of itemSizeSplitted) {
          this.addTextLine(`${tabSpacing}${splittedItem}\n`, cmd, printData, false, rasterMode);
        }
      }

      /*********** Item Unit Count ***********/
      if (item.unitCount) {
        const unitCountContent = `Count - ${item.unitCount}`;
        const itemUnitCountSplitted = this.splitter(
          unitCountContent,
          paperSize - (tabSpacing.length + 1),
          unitCountContent.length + 1,
        );
        for (const splittedItem of itemUnitCountSplitted) {
          this.addTextLine(`${tabSpacing}${splittedItem}\n`, cmd, printData, false, rasterMode);
        }
      }

      /*********** Item Description ***********/
      if (!item.size && item.description) {
        const itemSizeSplitted = this.splitter(item.description, paperSize - (tabSpacing.length + 1));
        for (const splittedItem of itemSizeSplitted) {
          this.addTextLine(`${tabSpacing}${splittedItem}\n`, cmd, printData, false, rasterMode);
        }
      }

      /*********** Items Instructions ***********/
      if (item.itemLevelInstructions) {
        const itemInstructionSplitted = this.splitter(
          item.itemLevelInstructions,
          paperSize,
          item.itemLevelInstructions.length + 1,
        );
        this.addTextLine(`${tabSpacing}Item instructions*\n`, cmd, printData, true, rasterMode);

        for (const splittedItem of itemInstructionSplitted) {
          this.addTextLine(`${tabSpacing}${splittedItem}\n`, cmd, printData, false, rasterMode);
        }
      }

      /*********** Modifiers Group List ***********/
      if (isModifiersEnabled && !!item.modifierGroups?.length) {
        for (const modifierItem of item.modifierGroups) {
          /*********** Options List ***********/
          const modifierOptionsList = modifierItem.items?.map(
            (item) => `${item.modifierItemName}${item.quantity > 1 ? ` (${item.quantity})` : ''}`,
          );

          /****** Modifier Group Item ******/
          const modifierGroupItem = `${modifierItem.modifierGroupName} - ${modifierOptionsList?.join(', ')}`;

          const modifierGroupItemSplitted = this.splitter(
            modifierGroupItem,
            paperSize - (tabSpacing.length + 2),
            modifierGroupItem.length + 1,
          );

          for (let i = 0; i < modifierGroupItemSplitted.length; i++) {
            /****** Tab Spacing ******/
            this.addTextLine(tabSpacing, cmd, printData, false, rasterMode);

            /****** Bullet Character Encoding ******/
            i === 0 && cmd.push({appendBytes: [0xe2, 0x80, 0xa2]});

            this.addTextLine(
              `${i !== 0 ? ' ' : ''} ${modifierGroupItemSplitted[i]}\n`,
              cmd,
              printData,
              false,
              rasterMode,
            );
          }
        }
      }

      totalQuantity += Number(item.quantity);
      this.addTextLine(`\n`, cmd, printData, false, rasterMode);
    }

    /******************* Black Line *******************/
    await this.addBlackLine(cmd, printData, rasterMode);

    /******************* Total Items *******************/
    await this.formatLineInTwoColumns(
      'Total Items',
      `X${Number(totalQuantity).toFixed(0)}`,
      cmd,
      printData,
      rasterMode,
      false,
      true,
    );
    this.addTextLine('\n', cmd, printData, false, rasterMode);

    /******************* Cut Paper Action ********************/
    if (!rasterMode) {
      cmd.push({appendCharacterSpace: 0});
      cmd.push({appendCutPaper: 'PartialCutWithFeed'});
    }

    /******************* Debug Mode ********************/
    if (DEBUG_PRINTER) {
      console.log('🚀 ~ PRINTER DEBUG\n');
      console.log(printData.join(''));
    }

    /******************* Raster Mode ********************/
    /* Converts text into bitmap image & sends it to the printer */
    if (rasterMode) {
      const rasterText = [...printData];
      rasterText.shift();

      return {
        cmd,
        rasterObj: {
          text: rasterText.join(''),
          paperWidth: 576, // 3 inch paper
          cutReceipt: true,
        },
      };
    }

    return {cmd};
  }
}
