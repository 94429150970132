import {IonIcon, IonLabel} from '@ionic/react';
import {chevronDownOutline} from 'ionicons/icons';
import {useState} from 'react';
import PauseOptions from 'src/components/PauseOptions';
import './styles.scss';

interface IProps {
  partnerName?: string;
}

const StoreStatusMenuOpen = ({partnerName}: IProps) => {
  const [opened, setOpened] = useState(false);

  return (
    <div id="status-menu">
      <div
        className={`bg-green status-menu-container ${opened ? 'open' : 'close'}`}
        onClick={() => {
          setOpened(!opened);
        }}>
        <div className="status-menu">
          <IonLabel className="text-white">Accepting Order</IonLabel>
          {partnerName ? (
            <IonLabel className="text-white subtitle">{partnerName}</IonLabel>
          ) : (
            <IonLabel className="text-white subtitle">All partners</IonLabel>
          )}
        </div>
        <IonIcon icon={chevronDownOutline} slot="end" className={`text-white ${opened ? 'open' : 'close'}`} />
      </div>
      {opened && (
        <div className="status-menu-content">
          <PauseOptions partnerName={partnerName} />
        </div>
      )}
    </div>
  );
};

export default StoreStatusMenuOpen;
