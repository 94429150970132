import {useMediaQuery} from 'react-responsive';
import {store} from 'src/store';
import {setMenuIsOpen} from 'src/store/authSlice';
import {setIsDesktop, setIsMobile, setIsTablet} from 'src/store/platformSlice';

// Desktop or laptop.
export const Desktop = ({children}: any) => {
  const isDesktop = useMediaQuery({minWidth: 1200});
  return isDesktop ? children : null;
};

// Tablet.
export const Tablet = ({children}: any) => {
  const isTablet = useMediaQuery({minWidth: 768, maxWidth: 1199});
  return isTablet ? children : null;
};

// Mobile.
export const Mobile = ({children}: any) => {
  const isMobile = useMediaQuery({maxWidth: 767});
  return isMobile ? children : null;
};

// Not mobile (desktop or laptop or tablet)
export const Default = ({children}: any) => {
  const isNotMobile = useMediaQuery({minWidth: 768});
  return isNotMobile ? children : null;
};

// Storing current platform when media query's value changes
export const SetPlatform = () => {
  const desktop = useMediaQuery({minWidth: 1200}, undefined, (matches) => store.dispatch(setIsDesktop(matches)));

  const tablet = useMediaQuery({minWidth: 768, maxWidth: 1199}, undefined, (matches) => {
    store.dispatch(setIsTablet(matches));
    if (matches) store.dispatch(setMenuIsOpen(true));
  });

  const mobile = useMediaQuery({maxWidth: 767}, undefined, (matches) => {
    store.dispatch(setIsMobile(matches));
    if (matches) store.dispatch(setMenuIsOpen(false));
  });

  return {desktop, tablet, mobile};
};
