import {IonButton, IonItem, IonLabel, IonRadio, IonRadioGroup} from '@ionic/react';
import moment from 'moment';
import {IQueryObject} from 'src/interfaces/item';
import {Check} from 'react-feather';
import './styles.scss';
import {DATE_FILTERING} from 'src/enums/inventory_filtering';

interface IProps {
  filterObject: IQueryObject;
  selectFilter: (e: any) => void;
  isDesktop: boolean;
}

const DateFilter = (props: IProps) => {
  const dateFilter = [
    DATE_FILTERING.all,
    DATE_FILTERING.week,
    DATE_FILTERING.twoWeeks,
    DATE_FILTERING.month,
    DATE_FILTERING.quarter,
    DATE_FILTERING.halfYear,
    DATE_FILTERING.year,
  ];

  const calculateStartDate = (endDate: number, daysBefore: string): number => {
    if (daysBefore === DATE_FILTERING.all.split(' ')[0]) return new Date(0).getTime();
    var d = new Date(endDate);
    d.setDate(d.getDate() - Number(daysBefore));
    return d.getTime();
  };

  const checkSelected = (startDate: number, calculatedStartDate: number) => startDate === calculatedStartDate;

  const selectDateFilter = (value: string) => {
    const selectedDate = {
      startDate: props.filterObject.startDate,
      endDate: props.filterObject.endDate,
    };
    if (value === DATE_FILTERING.all.split(' ')[0]) selectedDate.startDate = new Date(0).getTime();
    else selectedDate.startDate = calculateStartDate(selectedDate.endDate, value);
    props.selectFilter(selectedDate);
  };

  const calculateIfSelected = (endDate: number, startDate: number) => {
    const a = moment(endDate);
    const b = moment(startDate);
    const diff = a.diff(b, 'days');
    if (diff === 7) return DATE_FILTERING.week.split(' ')[0];
    else if (diff === 14) return DATE_FILTERING.twoWeeks.split(' ')[0];
    else if (diff === 30) return DATE_FILTERING.month.split(' ')[0];
    else if (diff === 90) return DATE_FILTERING.quarter.split(' ')[0];
    else if (diff === 180) return DATE_FILTERING.halfYear.split(' ')[0];
    else if (diff === 365) return DATE_FILTERING.year.split(' ')[0];
    else return DATE_FILTERING.all.split(' ')[0];
  };

  return (
    <>
      {props.isDesktop && (
        <IonRadioGroup value={calculateIfSelected(props.filterObject.endDate, props.filterObject.startDate)}>
          <div className="filter-group-grid">
            {dateFilter.map((date, index) => (
              <IonItem
                key={index}
                onClick={() => {
                  selectDateFilter(date.split(' ')[0]);
                }}>
                <IonRadio slot="start" value={date.split(' ')[0]} />
                <IonLabel className="radio-text">{date}</IonLabel>
              </IonItem>
            ))}
          </div>
        </IonRadioGroup>
      )}
      {!props.isDesktop && (
        <div className="date-row">
          {dateFilter.map((date, index) => {
            const isSelected = checkSelected(
              props.filterObject.startDate,
              calculateStartDate(props.filterObject.endDate, date.split(' ')[0]),
            );
            return (
              <IonButton
                key={index}
                className={isSelected ? 'ion-button-selected' : ''}
                onClick={() => selectDateFilter(date.split(' ')[0])}>
                {isSelected ? (
                  <span className="check-selected">
                    <Check />
                    {date}
                  </span>
                ) : (
                  <span className="main-text">{date}</span>
                )}
              </IonButton>
            );
          })}
        </div>
      )}
    </>
  );
};

export default DateFilter;
