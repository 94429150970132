import {useState} from 'react';
import placeholder from 'src/assets/placeholder';
import loading from 'src/assets/images/loading.svg';
import item1 from 'src/assets/images/item1.svg';
import itemPlaceholderImg from 'src/assets/images/item-placeholder.png';

interface Props {
  category: string;
  image: string | Blob;
}

const ItemImage = ({category = '', image}: Props) => {
  const [imgLoading, setImageLoading] = useState(true);

  const handleImage = (imgLoad?: boolean) => {
    if (imgLoading || imgLoad) return loading;
    else if (!!image && typeof image === 'string') return image || item1;
    else if (placeholder.has(category)) return placeholder.get(category) || itemPlaceholderImg;
    else return itemPlaceholderImg;
  };

  return (
    <img
      alt="Item"
      src={handleImage()}
      onLoad={() => setImageLoading(false)}
      onError={({currentTarget}) => {
        currentTarget.onerror = null;
        currentTarget.src = placeholder.has(category)
          ? placeholder.get(category) || itemPlaceholderImg
          : itemPlaceholderImg;
      }}
    />
  );
};

export default ItemImage;
