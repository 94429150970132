import { AndroidPermissions } from '@awesome-cordova-plugins/android-permissions';
import { Permission } from 'src/interfaces/permission';

export class PermissionsService {
    async requestNotificationPermission() {
        const permissions = [
            AndroidPermissions.PERMISSION.SCHEDULE_EXACT_ALARM
        ]
        const response = this.checkAndRequestPermissions(permissions);
        return response;
    }
    async requestGPSPermission() {
        const permissions = [
            AndroidPermissions.PERMISSION.ACCESS_COARSE_LOCATION,
            AndroidPermissions.PERMISSION.ACCESS_FINE_LOCATION
        ]
        const response = this.checkAndRequestPermissions(permissions);
        return response;
    }
    async requestBluetoothPermission() {
        const permissions = [
            AndroidPermissions.PERMISSION.BLUETOOTH_CONNECT,
            AndroidPermissions.PERMISSION.BLUETOOTH_SCAN,
            AndroidPermissions.PERMISSION.BLUETOOTH,
        ]
        const response = this.checkAndRequestPermissions(permissions);
        return response;
    }
    async requestIgnoreBatteryOptimizationPermission() {
        const permissions = [
            AndroidPermissions.PERMISSION.REQUEST_IGNORE_BATTERY_OPTIMIZATIONS
        ]
        const response = this.checkAndRequestPermissions(permissions);
        return response;

    }
    async requestBackgroundServicePermission() {
        const permissions = [
            AndroidPermissions.PERMISSION.FOREGROUND_SERVICE
        ]
        const response = this.checkAndRequestPermissions(permissions);
        return response;
    }
    async requestWakeLockPermission() {
        const permissions = [
            AndroidPermissions.PERMISSION.WAKE_LOCK
        ]
        const response = this.checkAndRequestPermissions(permissions);
        return response;
    }
    async checkPermissions(permissions: any[]): Promise<Permission[]> {
        try {
            return await Promise.all(
                permissions.map((permission) => AndroidPermissions.checkPermission(permission))
            );
        } catch (error) {
            return [];
        }
    }

    async requestPermissions(permissions: string[]): Promise<Permission> {
        try {
            return await AndroidPermissions.requestPermissions(permissions);
        } catch (error) {
            return { hasPermission: false };
        }
    }

    async checkAndRequestPermissions(permissions: string[]): Promise<Permission[]> {
        try {
            const permissionStatuses = await this.checkPermissions(permissions);

            if (permissionStatuses.every((status) => status.hasPermission)) {
                return permissionStatuses;
            }
            const response: Permission = await this.requestPermissions(permissions);
            if (response.hasPermission) {
                return permissionStatuses.map((status) => {
                    return { ...status, hasPermission: true };
                });
            } else {
                return permissionStatuses;
            }
        } catch (error) {
            return [{
                hasPermission: false,
            }];
        }
    }
}
