import {makeRequest} from 'src/Axios';

const menuService = {
  getMenu: async (storeId: string) => {
    try {
      const response: any = await makeRequest({url: `/stores/${storeId}/menu`, isMicroservice: true});
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  createMenuHours: async (storeId: string, menuHours: any, menuName: string) => {
    try {
      const response: any = await makeRequest({
        url: `/stores/${storeId}/menu`,
        method: 'POST',
        body: {menu_name: menuName, menu_hours: menuHours},
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  updateMenuHours: async (storeId: string, menu_hours: any, menu_name: string, menu_id: string) => {
    try {
      const response: any = await makeRequest({
        url: `/stores/${storeId}/menu`,
        method: 'PUT',
        params: {menu_id},
        body: {menu_hours, menu_name},
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  deleteMenu: async (storeId: string, menuId: string) => {
    try {
      const response: any = await makeRequest({
        url: `/stores/${storeId}/menu/${menuId}`,
        method: 'DELETE',
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getMenuDetailsByMenuId: async (storeId: string, menuId: string) => {
    try {
      const response: any = await makeRequest({
        url: `/stores/${storeId}/menu/${menuId}`,
        isMicroservice: true,
      });
      if (response.data.menu_hours.length) {
        const hours = response.data.menu_hours
          .map(({id, open_time, close_time, day_of_week, is_closed}: any) => ({
            id,
            openTime: !open_time ? '09:00' : open_time,
            closeTime: !close_time ? '17:00' : close_time,
            storeId: '',
            dayOfWeek: day_of_week,
            isClosed: is_closed,
            invalid: false,
            updated: false,
          }))
          .sort((a: any, b: any) => a.dayOfWeek - b.dayOfWeek);
        const sunday = hours.pop();
        return {
          menu_hours: [sunday, ...hours],
          menu_name: response.data.menu_name,
          is_default: response.data.is_default,
        };
      } else {
        return {menu_hours: [], menu_name: response.data.menu_name, is_default: response.data.is_default};
      }
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getMenuHours: async (storeId: string, menu_list: Array<string>) => {
    try {
      const response: any = await makeRequest({
        url: `/stores/${storeId}/menu/timing?menu_ids=${menu_list}`,
        isMicroservice: true,
      });
      if (!!response.data.length) {
        const hours = response.data
          .map(({open_time, close_time, day_of_week, is_closed}: any) => ({
            id: '',
            openTime: !open_time ? '09:00' : open_time,
            closeTime: !close_time ? '17:00' : close_time,
            storeId: '',
            dayOfWeek: day_of_week,
            isClosed: is_closed,
            invalid: false,
            updated: false,
          }))
          .sort((a: any, b: any) => a.dayOfWeek - b.dayOfWeek);
        const sunday = hours.pop();
        return [sunday, ...hours];
      } else {
        return [];
      }
    } catch (error: any) {
      throw error.response?.data;
    }
  },
};

export default menuService;
