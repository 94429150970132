import {IonRouterLink} from '@ionic/react';
import './styles.scss';
import {TERMS_URL} from 'src/utils/constants';
import {useAppSelector} from 'src/hooks';

const TermsText = () => {
  const {isNative} = useAppSelector((state) => state.platform);
  return !isNative ? (
    <IonRouterLink id="terms-link" href={TERMS_URL} target="_blank">
      Terms of Service
    </IonRouterLink>
  ) : (
    <IonRouterLink id="terms-link" routerLink="/support/terms">
      Terms of Service
    </IonRouterLink>
  );
};

export default TermsText;
