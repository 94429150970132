import '../styles.scss';

interface Reports {
  user: any;
  token: string;
  companyId: string | undefined;
}

const ExploReports = ({user, token, companyId}: Reports) => (
  <explo-report-builder
    report-builder-token={`G2k1LD1yJw:${token}`}
    environment={process.env.REACT_APP_EXPLO_ENVIRONMENT}
    variables={JSON.stringify({user_id: user?.id, company_id: [companyId]})}
  />
);

export default ExploReports;
