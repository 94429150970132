import {useState} from 'react';
import {IonItem, IonRange} from '@ionic/react';
import './styles.scss';
import {IInventoryStats, IQueryObject} from 'src/interfaces/item';
import {useEffectOnce} from 'src/hooks';

interface IProps {
  inventoryStats: IInventoryStats;
  filterObject: IQueryObject;
  selectFilter: (e: any) => void;
}

const PriceFilter = ({inventoryStats, filterObject, selectFilter}: IProps) => {
  const [count, setCount] = useState(0);

  const {maxPrice, minPrice} = inventoryStats;
  const maxInventoryPrice = Math.ceil(Number(maxPrice));
  const minInventoryPrice = Math.floor(Number(minPrice));

  const max = maxInventoryPrice === minInventoryPrice ? maxInventoryPrice + 1 : maxInventoryPrice;
  const upper = Math.ceil(Number(filterObject.maxPrice));
  const lower = Math.floor(Number(filterObject.minPrice));

  useEffectOnce(() => {
    // Its a hack to rerender component because of slider ui.
    setTimeout(() => {
      setCount(count + 1);
    }, 500);
  });

  return (
    <IonItem className="ion-no-padding price-row">
      <IonRange
        dualKnobs
        snaps
        pin
        step={1}
        max={max}
        min={minInventoryPrice}
        disabled={max === minInventoryPrice}
        value={{upper, lower}}
        onIonChange={({detail}) => {
          const values = detail.value as {upper: number; lower: number};
          if (upper !== values.upper || lower !== values.lower) {
            selectFilter({maxPrice: values.upper, minPrice: values.lower});
          }
        }}
      />
    </IonItem>
  );
};

export default PriceFilter;
