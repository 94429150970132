/* eslint-disable react-hooks/exhaustive-deps */
import {IonIcon, IonToggle, IonButton, IonPopover, IonList, IonItem, IonLabel} from '@ionic/react';
import {ellipsisHorizontal, addOutline, trashOutline} from 'ionicons/icons';
import cloneDeep from 'lodash.clonedeep';
import './styles.scss';
import {DEFAULT_HOUR, WEEK_DAYS} from 'src/utils/constants';
import {ITiming} from 'src/interfaces/store';
import AutoCompleteInput from 'src/components/AutoCompleteInput';

interface IProps {
  timings: ITiming[][];
  disabled?: boolean;
  type?: string;
  validate: boolean;
  multiHours?: boolean;
  validateInputs: (timings: ITiming[][]) => boolean;
  setTimings: (timings: ITiming[][]) => void;
  onRemove?: (id: string) => void;
}

const StoreTiming = ({timings, disabled, type, validate, multiHours, validateInputs, setTimings, onRemove}: IProps) => {
  const addHour = (dayOfWeek: number) => {
    const updatedDays = cloneDeep(timings);
    updatedDays[dayOfWeek].push({
      ...DEFAULT_HOUR,
      openTime: '',
      closeTime: '',
      dayOfWeek,
    });
    setTimings(updatedDays);
  };

  const updateHour = (dayOfWeek: number, hourIndex: number, timeType: 'openTime' | 'closeTime', value: string) => {
    const updatedDays = cloneDeep(timings);
    updatedDays[dayOfWeek][hourIndex][timeType] = value;
    setTimings(updatedDays);
    if (validate) validateInputs(updatedDays);
  };

  const toggleStoreHour = (dayOfWeek: number) => {
    const updatedDays = cloneDeep(timings);
    for (let i = 0; i < updatedDays[dayOfWeek].length; i++) {
      updatedDays[dayOfWeek][i].isClosed = !updatedDays[dayOfWeek][i].isClosed;
    }
    setTimings(updatedDays);
  };

  const removeHour = (dayOfWeek: number, hourIndex: number) => {
    const updatedDays = cloneDeep(timings);
    const hourId = updatedDays[dayOfWeek][hourIndex].id;

    updatedDays[dayOfWeek].splice(hourIndex, 1);
    setTimings(updatedDays);

    if (hourId && onRemove) {
      onRemove(hourId);
    }
  };

  return (
    <>
      {timings.map((timing, index) => (
        <div key={index} id="store-timing" className={timing[0].isClosed ? 'closed-bg' : ''}>
          {type !== 'schedule-partner' && (
            <div className="title-container">
              <h3 className="timing-title">
                {WEEK_DAYS.find((day) => day.day_of_week === timing[0].dayOfWeek)?.title}
              </h3>
              <IonToggle
                disabled={disabled}
                checked={!timing[0].isClosed}
                onClick={(_) => toggleStoreHour(timing[0].dayOfWeek)}
              />
            </div>
          )}
          {timing[0].isClosed ? (
            <div className="closed-card">Closed</div>
          ) : (
            timing.map((hour, idx) => (
              <>
                <div key={hour.id + idx} className="timings-container">
                  <AutoCompleteInput
                    label="Opens"
                    value={hour.openTime}
                    onChange={(value: string) => updateHour(hour.dayOfWeek, idx, 'openTime', value)}
                    disabled={disabled}
                    type={type}
                    invalid={!!hour.error}
                  />
                  <p>{type === 'schedule-partner' ? 'till' : 'to'}</p>
                  <AutoCompleteInput
                    label="Closes"
                    value={hour.closeTime}
                    onChange={(value: string) => updateHour(hour.dayOfWeek, idx, 'closeTime', value)}
                    disabled={disabled}
                    type={type}
                    invalid={!!hour.error}
                  />
                  {multiHours && (
                    <>
                      <IonButton id={`popover-trigger-${hour.dayOfWeek}-${idx}`} size="small" fill="clear" color="dark">
                        <IonIcon slot="icon-only" size="small" icon={ellipsisHorizontal}></IonIcon>
                      </IonButton>
                      <IonPopover
                        className="store-hours-options"
                        dismissOnSelect
                        trigger={`popover-trigger-${hour.dayOfWeek}-${idx}`}
                        triggerAction="click">
                        <IonList className="ion-no-padding">
                          <IonItem className="option" onClick={() => addHour(hour.dayOfWeek)}>
                            <IonIcon size="small" color="dark" icon={addOutline} />
                            <IonLabel>Add time range</IonLabel>
                          </IonItem>
                          {timing.length > 1 && (
                            <IonItem className="option" onClick={() => removeHour(hour.dayOfWeek, idx)}>
                              <IonIcon size="small" color="danger" icon={trashOutline} />
                              <IonLabel color="danger">Remove time range</IonLabel>
                            </IonItem>
                          )}
                        </IonList>
                      </IonPopover>
                    </>
                  )}
                </div>
                {hour.error && <p className="error-msg">{hour.error}</p>}
              </>
            ))
          )}
        </div>
      ))}
    </>
  );
};

export default StoreTiming;
