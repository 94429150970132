import {IonButton, IonIcon, IonText} from '@ionic/react';
import './styles.scss';

interface IProps {
  className?: string;
  notificationIconClass: string;
  notificationMessage: string;
  actionButton: string;
  onClick?: () => void;
}

const NotificationBar = ({className, notificationIconClass, notificationMessage, actionButton, onClick}: IProps) => {
  return (
    <div id="notification-bar" className={className} onClick={onClick}>
      <div className="notification-main-container">
        <IonIcon className={notificationIconClass} />
        <IonText>{notificationMessage}</IonText>
      </div>
      <IonButton className="action-btn" color="none" fill="clear">
        {actionButton}
      </IonButton>
    </div>
  );
};

export default NotificationBar;
