/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect} from 'react';
import {RouteComponentProps} from 'react-router';
import {ITEM_STATUS} from 'src/enums/item_status';

import {useAppDispatch, useAppSelector} from 'src/hooks';
import {IQueryObject} from 'src/interfaces/item';
import {
  fetchArchivedItems,
  fetchInStockItems,
  refreshArchivedInventory,
  refreshInStockInventory,
  setArchivedInventory,
  setInStockInventory,
  setQueryObject,
} from 'src/store/inventorySlice';
import {mapSortSelectionToSortValues} from 'src/utils/helpers';

import {Desktop, Tablet, Mobile} from 'src/utils/platform';
import AnalyticsHelper from 'src/utils/segment';
import DesktopScreen from './Desktop';
import MobileScreen from './Mobile';
import TabScreen from './Tablet';
import {setIsSortChange} from 'src/store/inventorySlice';

interface IProps extends RouteComponentProps {
  location: any;
}

const InventorySorting = ({location, history}: IProps) => {
  const dispatch = useAppDispatch();

  const {queryObject} = useAppSelector((state) => state.inventory);
  const {isDesktop} = useAppSelector((state) => state.platform);
  const [sortObject, setSortObject] = useState<IQueryObject>({...queryObject});

  const selectSort = (value: string) => {
    setSortObject({...sortObject, ...mapSortSelectionToSortValues(parseInt(value))});
    dispatch(setIsSortChange(false));
  };

  const onSort = async () => {
    if (location.state?.title === ITEM_STATUS.IN_STOCK) {
      dispatch(refreshInStockInventory());
      await dispatch(fetchInStockItems())
        .unwrap()
        .then((items: any) => {
          if (items?.length) dispatch(setInStockInventory(items));
          onClose();
        });
    }
    if (location.state?.title === ITEM_STATUS.ARCHIVED) {
      dispatch(refreshArchivedInventory());
      await dispatch(fetchArchivedItems())
        .unwrap()
        .then((items: any) => {
          if (items?.length) dispatch(setArchivedInventory(items));
          onClose();
        });
    }
  };

  const onClose = () => {
    if (!isDesktop) history.replace({pathname: '/inventory'});
  };

  useEffect(() => {
    if (sortObject.sortDir === queryObject.sortDir && sortObject.order === queryObject.order) {
      return;
    }
    dispatch(setQueryObject({...queryObject, sortDir: sortObject.sortDir, order: sortObject.order}));
    AnalyticsHelper.trackInventorySort({
      ...queryObject,
      sortDir: sortObject.sortDir,
      order: sortObject.order,
    });
    onSort();
  }, [sortObject]);

  const props = {sortObject, selectSort, onSort, onClose};

  return (
    <>
      {/* Desktop design is similar to mobile design so using mobile screen with the required different prop */}
      <Desktop>
        <DesktopScreen {...props} />
      </Desktop>
      <Tablet>
        <TabScreen {...props} />
      </Tablet>
      <Mobile>
        <MobileScreen {...props} />
      </Mobile>
    </>
  );
};

export default InventorySorting;
