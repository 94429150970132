import {
  IonPage,
  IonContent,
  IonIcon,
  IonItem,
  IonInput,
  IonSpinner,
  IonGrid,
  IonRow,
  IonCol,
  IonButton,
  IonHeader,
  IonPopover,
  IonCheckbox,
} from '@ionic/react';
import {searchOutline} from 'ionicons/icons';
import './styles.scss';
import InventoryItem from 'src/components/InventoryItem';
import {ICategoryCount, IInventoryStats, IItem, IItemWithFirstSort, IQueryObject} from 'src/interfaces/item';
import {ITEM_STATUS} from 'src/enums/item_status';
import {ITEM_TYPE} from 'src/enums/item_type';
import NoItem from 'src/components/NoItem';
import ItemDetails from 'src/pages/Items/Details';
import {ICategory} from 'src/interfaces/item';
import {useState} from 'react';
import InventoryToggle from '../components/InventoryToggle';

import Filter from 'src/assets/icons/filter.svg';
import InventorySorting from '../../Sorting';
import {createItemArrayWithDivider, isElementIncludes, getTotalSelectItemCount} from 'src/utils/helpers';
import InventorySortRow from '../components/InventorySortRow';
import {QUERY_OBJECT} from 'src/enums/query_object';
import InventoryFiltering from '../../Filtering';
import FAB from '../components/FAB';
import ItemEditOption from 'src/components/ItemEditOption';
import {useAppDispatch, useAppSelector} from 'src/hooks';
import {setSelectedCategories, removeSelectedCategories, setIsEditable} from 'src/store/inventorySlice';
import CategoryOptionModalDesktop from 'src/components/modals/BulkEdit/Category';
import AvailabilityOptionModal from 'src/components/modals/BulkEdit/Availability';
import PriceOptionModal from 'src/components/modals/BulkEdit/Price';
import DeleteOptionModal from 'src/components/modals/BulkEdit/Delete';
import MenuModal from 'src/components/modals/BulkEdit/Menu';
import {IMenuList} from 'src/interfaces/store';
import NewMenuModal from 'src/components/modals/BulkEdit/NewMenu';
import Details from 'src/pages/Store/Menu/Details';
import NotificationBar from '../components/NotificationBar';

interface IProps {
  inventoryStats: IInventoryStats;
  queryObject: IQueryObject;
  location?: any;
  history?: any;
  match?: any;
  loading: boolean;
  showSearch: boolean;
  title: string;
  items: Array<IItem> | undefined;
  searchedItems: Array<IItem> | undefined;
  categories: Array<ICategory>;
  categoryCount: Array<ICategoryCount>;
  id?: string;
  searchTerm: string;
  showDetails?: boolean;
  canCreate?: boolean;
  canDelete?: boolean;
  canEdit?: boolean;
  isFiltered?: boolean;
  showDeleteModal: boolean;
  showEditOptionModal: boolean;
  showAvailabilityModal: boolean;
  showPriceModal: boolean;
  onTitleChange: (title: ITEM_STATUS.IN_STOCK | ITEM_STATUS.ARCHIVED) => void;
  setShowDetails?: (show: boolean) => void;
  goToCommonItems: () => void;
  openHelp: () => void;
  onInputChange: (e: any) => void;
  setShowSearch: (show: boolean) => void;
  onSegmentChange: (e: any) => void;
  fetchNextItems: (e: any) => void;
  goToDetails: (item: IItem, isNewItem?: boolean) => void;
  goToSorting: () => void;
  goToFiltering: () => void;
  setShowDeleteModal: (e: boolean) => void;
  setShowEditOptionModal: (e: boolean) => void;
  setShowAvailabilityModal: (e: boolean) => void;
  setShowPriceModal: (e: boolean) => void;
  searchInventory: (refreshOffset?: number) => void;
  patchInventory: (data: any, is_archive: boolean, active: boolean) => void;
  showCategoryModal: boolean;
  setShowCategoryModal: (e: boolean) => void;
  validatePriceInput: (data: any, active: boolean) => void;
  deleteInventory: (active: boolean) => void;
  showMenuModal: boolean;
  setShowMenuModal: (e: boolean) => void;
  showNewMenuModal: boolean;
  setShowNewMenuModal: (e: boolean) => void;
  menuList: Array<IMenuList>;
  menuBulkItem: (data: Array<string>, active: boolean, addToMenu: boolean) => void;
  showNewMenuDetail: boolean;
  setShowNewMenuDetail: (e: boolean) => void;
  onNewMenuClick: () => void;
  showBulkIncompleteDetails?: boolean;
  setShowBulkIncompleteDetails?: (e: boolean) => void;
  updateBulkIncompleteDetails?: () => void;
  showBulkOutOfStockDetails?: boolean;
  setShowBulkOutOfStockDetails?: (e: boolean) => void;
  updateBulkOutOfStockDetails?: () => void;
}

const DesktopScreen = (props: IProps) => {
  const itemArrayWithDivider: IItemWithFirstSort[] = createItemArrayWithDivider(
    props.queryObject,
    !!props.searchTerm ? (!!props.searchedItems ? props.searchedItems : []) : !!props.items ? props.items : [],
  );
  const [popoverState, setShowPopover] = useState({
    showPopover: false,
    event: undefined,
  });
  const {selectedItems, exceptItems, selectedCategories, inventoryCount, isEditable, isSortChange} = useAppSelector(
    (state) => state.inventory,
  );
  const dispatch = useAppDispatch();

  const onNewMenuClick = () => {
    setShowPopover({
      showPopover: false,
      event: undefined,
    });
    props.onNewMenuClick();
  };

  return (
    <IonPage id="inventory-list-desktop" className="snow-background">
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonContent fullscreen scrollY={false} className="page-container">
              <IonHeader className="custom-header ion-no-border">
                <div className="left">
                  <div className="title">Inventory</div>
                  <InventoryToggle
                    title={props.title}
                    onTitleChange={props.onTitleChange}
                    inventoryCount={inventoryCount}
                  />
                </div>
              </IonHeader>
              <div className="search-collate-container">
                <div className="search-header">
                  <IonItem className="show-search">
                    <IonIcon icon={searchOutline} color="dark" />
                    <IonInput
                      value={props.searchTerm}
                      placeholder="Search Inventory"
                      onIonChange={(e) => {
                        if (e.detail.value!.length === 0) props.setShowSearch(false);
                        else props.setShowSearch(true);
                        props.onInputChange(e);
                      }}
                    />
                  </IonItem>
                </div>

                <div className="collate">
                  <div className="sort">
                    <span>Sort by</span>
                    <InventorySorting
                      location={{state: {id: props.id, title: props.title}}}
                      history={props.history}
                      match={props.match}
                    />
                  </div>

                  <div className="filter">
                    {props.isFiltered ? <span className="badge"></span> : <></>}
                    <IonPopover
                      id="filter-popover"
                      className="menu"
                      event={popoverState.event}
                      isOpen={popoverState.showPopover}
                      onDidDismiss={() => setShowPopover({showPopover: false, event: undefined})}>
                      <InventoryFiltering
                        showSearch={props.showSearch}
                        searchInventory={props.searchInventory}
                        setShowFilters={setShowPopover}
                        location={{state: {id: props.id, title: props.title, onNewMenuClick}}}
                        history={props.history}
                        match={props.match}
                      />
                    </IonPopover>
                    <IonButton
                      className="select-button"
                      fill="clear"
                      onClick={(e: any) => {
                        e.persist();
                        setShowPopover({showPopover: true, event: e});
                      }}>
                      <IonIcon className="button-icon-small" icon={Filter} />
                      Filter
                    </IonButton>
                  </div>
                </div>
              </div>

              <div className={`body body-padding ${props.showSearch ? 'search' : ''}`} onScroll={props.fetchNextItems}>
                <div className="notification-alert-container">
                  {Number(inventoryCount.out_of_stock) !== 0 && (
                    <NotificationBar
                      className="out-of-stock-notification"
                      notificationIconClass="icon-stop-block"
                      notificationMessage={`${inventoryCount.out_of_stock} items are Out of stock`}
                      actionButton="Update item"
                      onClick={props.updateBulkOutOfStockDetails}
                    />
                  )}

                  {Number(inventoryCount.incomplete) !== 0 && (
                    <NotificationBar
                      className="incomplete-details-notification"
                      notificationIconClass="icon-warning-alert"
                      notificationMessage={`${inventoryCount.incomplete} items have Incomplete details`}
                      actionButton="Complete items"
                      onClick={props.updateBulkIncompleteDetails}
                    />
                  )}
                </div>
                {props.items?.length && !props.searchTerm
                  ? itemArrayWithDivider.map((item: any, i) => (
                      <div key={i}>
                        {item.firstOfSort && props.queryObject.order === QUERY_OBJECT.NAME && (
                          <InventorySortRow rowName={item.name.charAt(0)} />
                        )}
                        {item.firstOfSort && props.queryObject.order === QUERY_OBJECT.CATEGORY && (
                          <div className="label">
                            {props.queryObject.order === QUERY_OBJECT.CATEGORY && (
                              <div className="check">
                                <IonCheckbox
                                  className="select-check"
                                  disabled={!props.canEdit && !props.canDelete}
                                  checked={
                                    isElementIncludes(selectedCategories, item.category?.id) &&
                                    !exceptItems.some((x) => x.category?.id === item.category?.id)
                                  }
                                  onIonChange={(e) => {
                                    if (isSortChange) {
                                      if (e.detail.checked) {
                                        dispatch(setSelectedCategories(item));
                                      } else {
                                        dispatch(removeSelectedCategories(item));
                                      }
                                    }
                                  }}
                                />
                              </div>
                            )}
                            <InventorySortRow
                              rowName={item.category?.name}
                              count={
                                props.categoryCount?.find((categoryCount) => categoryCount?.id === item.category?.id)
                                  ?.category_count
                              }></InventorySortRow>
                          </div>
                        )}
                        {item.firstOfSort && props.queryObject.order === QUERY_OBJECT.DATE && (
                          <InventorySortRow rowName={`Sorted by created date ${props.queryObject.sortDir}`} />
                        )}
                        {item.firstOfSort && props.queryObject.order === QUERY_OBJECT.PRICE && (
                          <InventorySortRow rowName={`Sorted by price ${props.queryObject.sortDir}`} />
                        )}
                        <InventoryItem
                          key={i}
                          isEditable={isEditable}
                          canEdit={props.canEdit || props.canDelete}
                          item={item}
                          onClick={(e: any) => {
                            if (e.target.role !== 'checkbox') props.goToDetails(item);
                          }}
                        />
                      </div>
                    ))
                  : itemArrayWithDivider?.map((item: any, i) => (
                      <InventoryItem
                        isEditable={false}
                        key={i}
                        canEdit={props.canEdit || props.canDelete}
                        item={item}
                        onClick={(e: any) => {
                          if (e.target.role !== 'checkbox') props.goToDetails(item);
                        }}
                      />
                    ))}
                {!itemArrayWithDivider?.length && !props.loading && <NoItem message="No item found!" />}
                {props.loading && <IonSpinner color="primary" />}
              </div>
              {getTotalSelectItemCount(selectedCategories, selectedItems, exceptItems, props.categoryCount) > 0 && (
                <div className="edit-modal">
                  <ItemEditOption
                    setIsEditable={setIsEditable}
                    selectedItemsLength={getTotalSelectItemCount(
                      selectedCategories,
                      selectedItems,
                      exceptItems,
                      props.categoryCount,
                    )}
                    setShowEditModal={props.setShowEditOptionModal}
                    setShowDeleteModal={props.setShowDeleteModal}
                    setShowAvailabilityModal={props.setShowAvailabilityModal}
                    setShowCategoryModal={props.setShowCategoryModal}
                    setShowPriceModal={props.setShowPriceModal}
                    setShowMenuModal={props.setShowMenuModal}
                    canEdit={props.canEdit}
                    canDelete={props.canDelete}
                  />
                </div>
              )}
              <DeleteOptionModal
                setShowEditModal={props.setShowEditOptionModal}
                setShowDeleteModal={props.setShowDeleteModal}
                showDeleteModal={props.showDeleteModal}
                deleteInventory={props.deleteInventory}
                isInStock={props.title === ITEM_STATUS.IN_STOCK}
              />
              <AvailabilityOptionModal
                setShowEditModal={props.setShowEditOptionModal}
                setShowAvailabilityModal={props.setShowAvailabilityModal}
                showAvailabilityModal={props.showAvailabilityModal}
                patchInventory={props.patchInventory}
                isInStock={props.title === ITEM_STATUS.IN_STOCK}
              />
              <CategoryOptionModalDesktop
                setShowEditModal={props.setShowEditOptionModal}
                setShowCategoryModal={props.setShowCategoryModal}
                showCategoryModal={props.showCategoryModal}
                patchInventory={props.patchInventory}
                isInStock={props.title === ITEM_STATUS.IN_STOCK}
              />
              <PriceOptionModal
                setShowEditModal={props.setShowEditOptionModal}
                showPriceModal={props.showPriceModal}
                setShowPriceModal={props.setShowPriceModal}
                patchInventory={props.patchInventory}
                isInStock={props.title === ITEM_STATUS.IN_STOCK}
                validatePriceInput={props.validatePriceInput}
              />
              <MenuModal
                isInStock={props.title === ITEM_STATUS.IN_STOCK}
                setShowEditModal={props.setShowEditOptionModal}
                setShowMenuModal={props.setShowMenuModal}
                showMenuModal={props.showMenuModal}
                menuList={props.menuList}
                setShowNewMenuModal={props.setShowNewMenuModal}
                menuBulkItem={props.menuBulkItem}
              />
              <NewMenuModal
                setShowMenuModal={props.setShowMenuModal}
                showNewMenuModal={props.showNewMenuModal}
                setShowNewMenuModal={props.setShowNewMenuModal}
              />
              {props.canCreate &&
                getTotalSelectItemCount(selectedCategories, selectedItems, exceptItems, props.categoryCount) === 0 && (
                  <FAB onHelp={props.openHelp} onBrowseItems={props.goToCommonItems} onCreateItem={props.goToDetails} />
                )}
            </IonContent>
          </IonCol>
          <IonCol size="4">
            {props.showBulkIncompleteDetails && (
              <ItemDetails
                location={{state: {type: ITEM_TYPE.COMMON}}}
                history={props.history}
                match={props.match}
                setShowDetails={props.setShowBulkIncompleteDetails}
                onNewMenuClick={() => {}}
              />
            )}

            {props.showBulkOutOfStockDetails && (
              <ItemDetails
                location={{state: {type: ITEM_TYPE.OUT_OF_STOCK}}}
                history={props.history}
                match={props.match}
                setShowDetails={props.setShowBulkOutOfStockDetails}
                onNewMenuClick={() => {}}
              />
            )}

            {props.showDetails && props.id?.length !== 0 && (
              <ItemDetails
                location={{state: {id: props.id, type: ITEM_TYPE.EDIT}}}
                history={props.history}
                match={props.match}
                setShowDetails={props.setShowDetails}
                onNewMenuClick={props.onNewMenuClick}
              />
            )}

            {props.showDetails && props.id?.length === 0 && (
              <ItemDetails
                location={{state: {id: props.id, type: ITEM_TYPE.NEW}}}
                history={props.history}
                match={props.match}
                setShowDetails={props.setShowDetails}
                onNewMenuClick={props.onNewMenuClick}
              />
            )}

            {props.showNewMenuDetail && (
              <Details
                history={props.history}
                location={{state: {menuDetailId: ''}}}
                match={props.match}
                setShowMenuDetail={props.setShowNewMenuDetail}
              />
            )}
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonPage>
  );
};

export default DesktopScreen;
