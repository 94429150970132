import {IonImg} from '@ionic/react';
import watch from 'src/assets/images/watch.svg';
import Countdown from 'react-countdown';
import './styles.scss';

interface ITimerComponent {
  milliseconds: number;
  onEndPause: () => void;
}

const TimerComponent = ({milliseconds, onEndPause}: ITimerComponent) => {
  return milliseconds ? (
    <div id="timer-component">
      <div className="timer">
        <IonImg src={watch} />
        <p className="counter">
          <Countdown
            date={Date.now() + milliseconds}
            renderer={({days, hours, minutes, seconds}) => (
              <strong>
                {days}d {hours < 10 ? '0' + hours : hours}:{minutes < 10 ? '0' + minutes : minutes}:
                {seconds < 10 ? '0' + seconds : seconds}
              </strong>
            )}
            onComplete={onEndPause}
          />
        </p>
      </div>
      <div className="pause-button" onClick={onEndPause}>
        End Pause
      </div>
    </div>
  ) : (
    <></>
  );
};

export default TimerComponent;
