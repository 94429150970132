export const VIEW_ACCOUNT_DETAILS = 'view_account_details';
export const EDIT_ACCOUNT_DETAILS = 'edit_account_details';
export const VIEW_ACCOUNT_NOTIFICATIONS = 'view_account_notifications';
export const EDIT_ACCOUNT_NOTIFICATIONS = 'edit_account_notifications';
export const VIEW_EMPLOYEES = 'view_employees';
export const EDIT_EMPLOYEES = 'edit_employees';
export const CREATE_EMPLOYEES = 'create_employees';
export const DELETE_EMPLOYEES = 'delete_employees';
export const VIEW_CHAT_BOT = 'view_chat_bot';
export const VIEW_SHOP = 'view_shop';
export const VIEW_KNOWLEDGE_BASE = 'view_knowledge_base';
export const VIEW_INVENTORY = 'view_inventory';
export const EDIT_ITEM = 'edit_item';
export const CREATE_ITEM = 'create_item';
export const DELETE_ITEM = 'delete_item';
export const VIEW_MENU = 'view_menu';
export const EDIT_MENU = 'edit_menu';
export const CREATE_MENU = 'create_menu';
export const DELETE_MENU = 'delete_menu';
export const VIEW_ORDERS = 'view_orders';
export const EDIT_ORDERS = 'edit_orders';
export const DENY_ORDER = 'deny_order';
export const VIEW_ORDER_HISTORY = 'view_order_history';
export const VIEW_REPORTS = 'view_reports';
export const VIEW_STORE_DETAILS = 'view_store_details';
export const EDIT_STORE_DETAILS = 'edit_store_details';
export const EDIT_STORE_HOURS = 'edit_store_hours';
export const EDIT_STORE_STATUS = 'edit_store_status';
export const EDIT_PARTNERS_STATUS = 'edit_partners_status';
export const VIEW_PRINTER_SETTINGS = 'view_printer_settings';
export const EDIT_PRINTER_SETTINGS = 'edit_printer_settings';
export const CREATE_PRINTER_CONNECTION = 'create_printer_connection';
export const DELETE_PRINTER_CONNECTION = 'delete_printer_connection';
export const VIEW_STORE_SHIPPING_DETAILS = 'view_store_shipping_details';
export const EDIT_STORE_SHIPPING_DETAILS = 'edit_store_shipping_details';
export const VIEW_INSIGHTS_OVERVIEW = 'view_insights_overview';
export const VIEW_INSIGHTS_ORDERS = 'view_insights_orders';
export const VIEW_INSIGHTS_INVENTORY = 'view_insights_inventory';
export const VIEW_INSIGHTS_STORES = 'view_insights_stores';
export const VIEW_INSIGHTS_REPORTS = 'view_insights_reports';
export const VIEW_INSIGHTS_PAYOUTS = 'view_insights_payouts';
export const VIEW_BILLING = 'view_billing';
