import {useState} from 'react';
import {IonPage, IonContent} from '@ionic/react';
import './styles.scss';
import CommonHeader from 'src/components/CommonHeader';
import {useAppSelector, useEffectOnce} from 'src/hooks';

const Reports = () => {
  const {menuIsOpen} = useAppSelector((state) => state.auth);
  const {id: storeId} = useAppSelector((state) => state.store);
  const {isTablet} = useAppSelector((state) => state.platform);

  const [ready, setReady] = useState(false);

  useEffectOnce(() => {
    const script = document.createElement('script');
    script.src = 'https://embed.explo.co/bundle.js';
    script.async = false;
    script.crossOrigin = 'anonymous';
    document.body.appendChild(script);
    setReady(true);
  });

  return (
    <IonPage id="reports-page" className={`${isTablet && !menuIsOpen ? 'menu-close' : ''}`}>
      <IonContent fullscreen scrollY={false} className="page-container">
        <CommonHeader className="title-left" title="Reports" menuIsOpen={menuIsOpen} />

        {ready && (
          <div className="body">
            <explo-dashboard
              dash-user-group-token={process.env.REACT_APP_EXPLO_USER_GROUP_TOKEN}
              updateUrlParams={true}
              isProduction={process.env.REACT_APP_EXPLO_ENVIRONMENT === 'production'}
              environment={process.env.REACT_APP_EXPLO_ENVIRONMENT}
              refresh-minutes={10}
              variables={JSON.stringify({Stores: `('${storeId}')`})}
            />
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Reports;
