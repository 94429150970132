import {IonPage, IonContent, IonBackButton, IonButton, IonIcon, IonFooter} from '@ionic/react';
import './styles.scss';
import {RouteComponentProps} from 'react-router';
import {useAppDispatch, useAppSelector} from 'src/hooks';
import {IPartnerItem} from 'src/interfaces/store';
import {closeOutline} from 'ionicons/icons';
import {createPartnerTestOrder} from 'src/store/partnerSlice';
import Header from 'src/components/Header';
import arrowBack from 'src/assets/images/arrow-back-black.svg';
import StoreStatuses from 'src/components/StoreStatuses';
import {useFeatureFlagEnabled} from 'posthog-js/react';
import {PARTNER} from 'src/enums/partner';

interface IProps extends RouteComponentProps {
  location: any;
  history: any;
  setShowPartnerDetails?: (show: boolean) => void;
  setPartnerDetails: (e: IPartnerItem) => void;
}

const PartnerDetails = ({location, history, setShowPartnerDetails, setPartnerDetails}: IProps) => {
  const {partnerName, iconImg} = location?.state || {};
  const {partners} = useAppSelector((state) => state.store.storeStatus);
  const isClosed = useAppSelector((state) => state.store.isClosed);
  const {isDesktop, isTablet} = useAppSelector((state) => state.platform);
  const {menuIsOpen} = useAppSelector((state) => state.auth);
  const dispatch = useAppDispatch();
  const storePauseEnabled = useFeatureFlagEnabled('storefront_pause');

  const onClose = () => {
    if (setShowPartnerDetails) {
      setShowPartnerDetails(false);
      setPartnerDetails({icon: '', title: ''});
    } else history.goBack();
  };

  const getCurrentPartner = (partnerName: string) => {
    if (partnerName === PARTNER.DOOR_DASH) return partners?.doorDash[0];
    else if (partnerName === PARTNER.GRUB_HUB) return partners?.grubHub[0];
    else if (partnerName === PARTNER.UBER_EATS) return partners?.uberEats[0];
  };

  return (
    <IonPage id="partner-details-page" className={`${isTablet && !menuIsOpen ? 'menu-close' : ''} snow-background`}>
      <IonContent fullscreen scrollY={false} className={`${isTablet ? 'page-container' : ''}`}>
        <Header
          border
          noBackground
          leftButton={!isDesktop && <IonBackButton defaultHref="/partners" text="" icon={arrowBack} />}
          rightButton={
            isDesktop && (
              <IonButton onClick={onClose}>
                <IonIcon src={closeOutline} />
              </IonButton>
            )
          }
          iconImg={iconImg}
          title={partnerName}
        />

        <div className="body with-footer">
          {storePauseEnabled && (
            <StoreStatuses
              partnerName={partnerName}
              isAllPartnerOpen={!!getCurrentPartner(partnerName)?.isOpen}
              isAllPartnerOffline={!!getCurrentPartner(partnerName)?.isOffline}
              isAllPartnerPaused={!!getCurrentPartner(partnerName)?.isPaused}
              isSomePartnerPaused={!!getCurrentPartner(partnerName)?.isPaused}
              isClosed={isClosed}
            />
          )}
          <hr />

          <div slot="end" className="partners-toggle">
            <IonButton
              expand="block"
              className="btn-outline"
              mode="ios"
              onClick={async () => {
                await dispatch(createPartnerTestOrder({partnerName}));
              }}>
              Send test order
            </IonButton>
          </div>
        </div>

        <IonFooter>
          <IonButton className="btn-dark" expand="block" onClick={onClose}>
            Done
          </IonButton>
        </IonFooter>
      </IonContent>
    </IonPage>
  );
};

export default PartnerDetails;
