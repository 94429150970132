import {useState} from 'react';
import {IonModal, IonButton, IonIcon, IonFooter} from '@ionic/react';
import './styles.scss';
import dayjs from 'dayjs';
import {closeOutline} from 'ionicons/icons';
import Header from 'src/components/Header';
import NewMenu from 'src/components/NewMenu';
import {useAppDispatch} from 'src/hooks';
import {ITiming} from 'src/interfaces/store';
import {DEFAULT_HOURS} from 'src/utils/constants';
import {addMenu} from 'src/store/storeSlice';
import {setNewMenuId} from 'src/store/inventorySlice';

interface IProps {
  showNewMenuModal: boolean;
  setShowMenuModal: (e: boolean) => void;
  setShowNewMenuModal: (e: boolean) => void;
}

const BulkEditNewMenuModal = (props: IProps) => {
  const dispatch = useAppDispatch();

  const [menuName, setMenuName] = useState('');
  const [validate, setValidate] = useState(false);
  const [timings, setTimings] = useState<ITiming[][]>(DEFAULT_HOURS);

  const validateHours = (hours: ITiming[][]) => {
    let isValid = true;
    const updatedHours = hours.map((day) => day.map((hour) => ({...hour, error: ''})));
    for (let i = 0; i < updatedHours.length; i++) {
      const day = updatedHours[i];

      for (let j = 0; j < day.length; j++) {
        const hour = day[j];

        if (!hour.isClosed) {
          const openHour = dayjs(hour.openTime, 'HH:mm');
          const closeHour = dayjs(hour.closeTime, 'HH:mm');

          if (!hour.openTime || !hour.closeTime) {
            isValid = false;
            hour.error = 'Please enter open and close time';
          } else if (openHour.isSameOrAfter(closeHour) || !openHour.isValid() || !closeHour.isValid()) {
            isValid = false;
            hour.error = 'Invalid time range';
          }
        }
      }
    }
    setTimings(updatedHours);
    return isValid;
  };

  const createMenu = async () => {
    setValidate(true);
    if (validateHours(timings)) {
      const data = timings.flat().map((obj) => ({
        day_of_week: obj.dayOfWeek,
        open_time: obj.openTime,
        close_time: obj.closeTime,
        is_closed: obj.isClosed,
      }));
      const menuDetail = await dispatch(addMenu({data, menuName}));
      dispatch(setNewMenuId(menuDetail.payload.menu.id));
      props.setShowMenuModal(true);
      setMenuName('');
      props.setShowNewMenuModal(false);
    }
  };

  return (
    <IonModal
      id="new-menu"
      isOpen={props.showNewMenuModal}
      showBackdrop
      onDidDismiss={() => {
        props.setShowMenuModal(true);
        props.setShowNewMenuModal(false);
      }}>
      <div className="modal-content-container">
        <div className="modal-content">
          <Header
            border
            noBackground
            title="New Menu"
            rightButton={
              <IonIcon
                onClick={() => {
                  props.setShowMenuModal(true);
                  props.setShowNewMenuModal(false);
                }}
                icon={closeOutline}
              />
            }
          />
          <div className="modal-body">
            <NewMenu
              isDefault={false}
              menuName={menuName}
              setMenuName={setMenuName}
              timings={timings}
              setTimings={setTimings}
              validate={validate}
              validateInputs={validateHours}
              menuDetailId={''}
            />
          </div>
          <IonFooter>
            <div className="action">
              <IonButton
                className="action-btn cancel-btn"
                mode="ios"
                fill="clear"
                color="dark"
                onClick={() => {
                  setMenuName('');
                  props.setShowMenuModal(true);
                  props.setShowNewMenuModal(false);
                }}>
                Cancel
              </IonButton>
              <IonButton
                disabled={menuName === ''}
                className="action-btn"
                mode="ios"
                fill="solid"
                color="primary"
                expand="block"
                onClick={async () => await createMenu()}>
                Create Menu
              </IonButton>
            </div>
          </IonFooter>
        </div>
      </div>
    </IonModal>
  );
};

export default BulkEditNewMenuModal;
