import {Preferences} from '@capacitor/preferences';

export const getItem = async (key: string) => {
  const {value} = await Preferences.get({key});
  return value;
};

export const setItem = async (key: string, value: string) => {
  await Preferences.set({key, value});
};

export const clear = async () => {
  await Preferences.clear();
};

export const removeItem = async (key: string) => {
  await Preferences.remove({key});
};
