import {IonIcon, IonLabel} from '@ionic/react';
import moment from 'moment';
import './styles.scss';
import OrderTimelineEvent from 'src/components/OrderTimelineEvent';
import {EventLogs} from 'src/interfaces/order';
import {ORDER_TIMELINE} from 'src/enums/order';

interface IProps {
  timelineLogs: Array<EventLogs>;
}

const OrderTimeline = ({timelineLogs}: IProps) => {
  const isActiveBaggingEvent = () => {
    const lastEvent = timelineLogs[timelineLogs?.length - 1]?.title?.toLowerCase();
    return lastEvent === ORDER_TIMELINE.BAGGING_ORDER;
  };

  const isActiveWaitingEvent = () => {
    const lastEvent = timelineLogs[timelineLogs?.length - 1]?.title?.toLowerCase();
    return lastEvent === ORDER_TIMELINE.WAITING_FOR_DRIVER;
  };

  const getDotIconClass = (title: string) => {
    if (
      title === ORDER_TIMELINE.ORDER_RECEIVED ||
      title === ORDER_TIMELINE.MARKED_READY_FOR_PICK_UP ||
      title === ORDER_TIMELINE.MARKED_READY_FOR_PICK_UP_BY_GRUB_HUB ||
      title === ORDER_TIMELINE.MARKED_READY_FOR_PICK_UP_BY_DOOR_DASH ||
      title === ORDER_TIMELINE.MARKED_READY_FOR_PICK_UP_BY_UBER_EATS
    )
      return 'icon-dot black-dot';
    else if (
      title === ORDER_TIMELINE.ITEM_REMOVED ||
      title === ORDER_TIMELINE.ITEM_QUANTITY_EDITED ||
      title === ORDER_TIMELINE.ITEM_SUBSTITUTED
    )
      return 'icon-dot gray-dot';
    else if (title === ORDER_TIMELINE.ORDER_CANCELLED) return 'icon-dot yellow-dot';
    else if (title === ORDER_TIMELINE.ORDER_DENIED) return 'icon-dot red-dot';
    else if (
      title === ORDER_TIMELINE.PICKED_UP_BY_DRIVER ||
      title === ORDER_TIMELINE.PICKED_UP_BY_GRUB_HUB_RIDER ||
      title === ORDER_TIMELINE.PICKED_UP_BY_DOOR_DASH_RIDER ||
      title === ORDER_TIMELINE.PICKED_UP_BY_UBER_EATS_RIDER
    )
      return 'icon-dot green-dot';
    else if (title === ORDER_TIMELINE.BAGGING_ORDER && isActiveBaggingEvent()) return 'icon-dot blue-dot';
    else if (title === ORDER_TIMELINE.WAITING_FOR_DRIVER && isActiveWaitingEvent()) return 'icon-dot blue-dot';
    else return 'icon-dot';
  };

  const getTimelineIconClass = (title: string) => {
    if (
      title === ORDER_TIMELINE.ORDER_RECEIVED ||
      title === ORDER_TIMELINE.MARKED_READY_FOR_PICK_UP ||
      title === ORDER_TIMELINE.MARKED_READY_FOR_PICK_UP_BY_GRUB_HUB ||
      title === ORDER_TIMELINE.MARKED_READY_FOR_PICK_UP_BY_DOOR_DASH ||
      title === ORDER_TIMELINE.MARKED_READY_FOR_PICK_UP_BY_UBER_EATS
    )
      return 'ruler black-ruler';
    else if (
      title === ORDER_TIMELINE.ITEM_REMOVED ||
      title === ORDER_TIMELINE.ITEM_QUANTITY_EDITED ||
      title === ORDER_TIMELINE.ITEM_SUBSTITUTED
    )
      return 'ruler gray-ruler';
    else if (title === ORDER_TIMELINE.ORDER_CANCELLED) return 'ruler yellow-ruler';
    else if (title === ORDER_TIMELINE.ORDER_DENIED) return 'ruler red-ruler';
    else if (
      title === ORDER_TIMELINE.PICKED_UP_BY_DRIVER ||
      title === ORDER_TIMELINE.PICKED_UP_BY_GRUB_HUB_RIDER ||
      title === ORDER_TIMELINE.PICKED_UP_BY_DOOR_DASH_RIDER ||
      title === ORDER_TIMELINE.PICKED_UP_BY_UBER_EATS_RIDER
    )
      return 'ruler green-ruler';
    else if (title === ORDER_TIMELINE.BAGGING_ORDER && isActiveBaggingEvent()) return 'ruler blue-ruler';
    else if (title === ORDER_TIMELINE.BAGGING_ORDER && !isActiveBaggingEvent()) return 'ruler black-ruler';
    else if (title === ORDER_TIMELINE.WAITING_FOR_DRIVER && isActiveWaitingEvent()) return 'ruler blue-ruler';
    else if (title === ORDER_TIMELINE.WAITING_FOR_DRIVER && !isActiveWaitingEvent()) return 'ruler black-ruler';
    else return '';
  };

  return (
    <div id="order-timeline">
      <IonLabel>Timeline</IonLabel>
      <div className="order-timeline-body">
        {timelineLogs?.map((eventLogs: EventLogs, index) => {
          const isSameDate =
            index > 0 &&
            timelineLogs.length > 1 &&
            moment(timelineLogs[index - 1].timestamp.split('T')[0]).isSame(eventLogs.timestamp.split('T')[0]);

          return (
            <div className="timeline-event" key={index}>
              <div className={getTimelineIconClass(timelineLogs[index + 1]?.title?.toLowerCase())} />
              <IonIcon className={getDotIconClass(eventLogs.title?.toLowerCase())} />
              <OrderTimelineEvent
                date={isSameDate ? '' : moment(eventLogs.timestamp).format('MMMM DD, YYYY')}
                time={
                  index === timelineLogs?.length - 1 && (isActiveBaggingEvent() || isActiveWaitingEvent())
                    ? 'Actively'
                    : moment(eventLogs.timestamp).format('hh:mm A')
                }
                title={eventLogs?.title}
                description={eventLogs?.description}
                username={eventLogs.firstName && `${eventLogs.firstName} ${eventLogs.lastName}`}
              />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default OrderTimeline;
