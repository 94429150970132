import './styles.scss';
import {useAppSelector} from 'src/hooks';
import {IonButton, IonContent, IonIcon, IonItem, IonLabel, IonPage, IonRadio, IonRadioGroup} from '@ionic/react';
import {arrowBack, closeOutline} from 'ionicons/icons';
import Header from 'src/components/Header';
import {useEffect, useState} from 'react';
interface IProps {
  setShowEditModal: (e: boolean) => void;
  setShowCategoryModal: (e: boolean) => void;
  patchInventory: (data: any, is_archive: boolean, active: boolean) => void;
  isInStock: boolean;
}

const CategoryOptionsModal = (props: IProps) => {
  const [selectedCategory, setSelectedCategory] = useState<any>(null);
  const {categories} = useAppSelector((state) => state.category);
  const [showConfirmationOption, setShowConfirmationOption] = useState(false);

  useEffect(() => {}, [selectedCategory]);
  return (
    <IonPage id="category-options-mobile">
      <IonContent fullscreen scrollY={false}>
        <Header
          border
          noBackground
          title="Category"
          leftButton={
            <IonIcon
              onClick={() => {
                setSelectedCategory(null);
                props.setShowCategoryModal(false);
              }}
              icon={arrowBack}></IonIcon>
          }
          rightButton={
            <IonIcon
              onClick={() => {
                setSelectedCategory(null);
                props.setShowCategoryModal(false);
                props.setShowEditModal(false);
              }}
              icon={closeOutline}></IonIcon>
          }
        />
        {!showConfirmationOption ? (
          <>
            <div className="body">
              <IonRadioGroup
                className="category-radio-group"
                value={selectedCategory}
                onIonChange={(e) => setSelectedCategory(e.detail.value)}>
                {categories.map((category: any, index) => {
                  return (
                    <IonItem key={index}>
                      <IonLabel>{category.name}</IonLabel>
                      <IonRadio mode="ios" slot="start" value={category.id} />
                    </IonItem>
                  );
                })}
              </IonRadioGroup>
            </div>
            <div className="action-div">
              <IonButton
                disabled={selectedCategory ? false : true}
                className="action-btn"
                mode="ios"
                fill="solid"
                color="primary"
                expand="block"
                onClick={async () => {
                  setShowConfirmationOption(true);
                }}>
                Save edit
              </IonButton>
            </div>
          </>
        ) : (
          <div className="confirmation-div">
            <div className="label">Are you sure you want to Update these items?</div>
            <div className="action">
              <IonButton
                className="btn-outline"
                onClick={() => {
                  setShowConfirmationOption(false);
                }}>
                No
              </IonButton>
              <IonButton
                className="btn-primary"
                onClick={() => {
                  props.patchInventory({category_id: selectedCategory}, false, props.isInStock);
                  props.setShowCategoryModal(false);
                  props.setShowEditModal(false);
                  setSelectedCategory(null);
                }}>
                Yes
              </IonButton>
            </div>
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default CategoryOptionsModal;
