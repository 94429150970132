import {IonMenuToggle, IonMenuButton, IonIcon} from '@ionic/react';
import Header from 'src/components/Header';
import {useAppSelector} from 'src/hooks';
import {toggleMenu} from 'src/utils/helpers';
import menu from 'src/assets/images/menu.svg';
import arrowBack from 'src/assets/images/arrow-back-black.svg';

interface IProps {
  title: string;
  menuIsOpen: boolean;
  className?: string;
  noBorder?: boolean;
}

const CommonHeader = ({title, menuIsOpen, className, noBorder = false}: IProps) => {
  const {isDesktop, isTablet, isMobile} = useAppSelector((state) => state.platform);

  const getHeaderLeftButton = () => {
    if (isTablet) {
      return (
        <IonMenuToggle menu="menu" autoHide={false} onClick={toggleMenu}>
          <IonIcon icon={menu} />
        </IonMenuToggle>
      );
    } else if (isMobile) {
      return (
        <IonMenuButton menu="menu" autoHide={false} color="dark">
          <IonIcon icon={menuIsOpen ? arrowBack : menu} />
        </IonMenuButton>
      );
    } else {
      return null;
    }
  };

  return (
    <Header
      border={!noBorder}
      noBackground
      className={className}
      title={title}
      withSideMenu={!isMobile}
      alignTitleLeft={isDesktop}
      leftButton={getHeaderLeftButton()}
    />
  );
};

export default CommonHeader;
