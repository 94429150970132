import {useState} from 'react';
import {IonModal, IonButton, IonIcon, IonFooter} from '@ionic/react';
import './styles.scss';
import {closeOutline} from 'ionicons/icons';

import Header from 'src/components/Header';
import PriceInput from 'src/components/PriceInput';
import BulkEditConfirmation from 'src/components/modals/BulkEdit/Confirmation';
import {useAppDispatch} from 'src/hooks';
import {setIsValidInputPrice} from 'src/store/inventorySlice';
import {PRICE_UPDATE_OPTION, PRICE_VALIDATE_OPTION} from 'src/enums/inventory_sorting';

interface IProps {
  setShowEditModal: (e: boolean) => void;
  setShowPriceModal: (e: boolean) => void;
  showPriceModal: boolean;
  isInStock: boolean;
  patchInventory: (a: any, b: boolean, c: boolean) => void;
  validatePriceInput: (data: any, active: boolean) => void;
}

const BulkEditPriceModal = (props: IProps) => {
  const dispatch = useAppDispatch();
  const [priceOption, setPriceOption] = useState(PRICE_UPDATE_OPTION.new_price);
  const [isValidPrice, setIsValidPrice] = useState(true);
  const [newPrice, setNewPrice] = useState<string | number>('');
  const [isPositiveSelect, setIsPositiveSelect] = useState(true);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedOption, setSelectedOption] = useState({
    newPrice: 0,
    isPositiveSelect: true,
    priceOption: PRICE_UPDATE_OPTION.new_price,
  });

  const onConfirm = () => {
    props.patchInventory(
      {
        price: selectedOption.isPositiveSelect ? Number(selectedOption.newPrice) : Number(selectedOption.newPrice) * -1,
        action_type: PRICE_VALIDATE_OPTION[selectedOption.priceOption],
      },
      false,
      props.isInStock,
    );
    setIsValidPrice(true);
    dispatch(setIsValidInputPrice(true));
    setNewPrice('');
    setIsPositiveSelect(true);
    setPriceOption(PRICE_UPDATE_OPTION.new_price);
    props.setShowPriceModal(false);
  };

  return (
    <>
      <IonModal
        id="price-option"
        isOpen={props.showPriceModal}
        showBackdrop
        onDidDismiss={() => {
          setIsValidPrice(true);
          dispatch(setIsValidInputPrice(true));
          setNewPrice('');
          setIsPositiveSelect(true);
          setPriceOption(PRICE_UPDATE_OPTION.new_price);
          props.setShowPriceModal(false);
        }}>
        <div className="modal-content-container">
          <div className="modal-content">
            <Header
              border
              noBackground
              title="Price"
              rightButton={
                <IonIcon
                  onClick={() => {
                    setIsValidPrice(true);
                    dispatch(setIsValidInputPrice(true));
                    setNewPrice('');
                    setIsPositiveSelect(true);
                    setPriceOption(PRICE_UPDATE_OPTION.new_price);
                    props.setShowPriceModal(false);
                  }}
                  icon={closeOutline}
                />
              }
            />
            <div className="modal-body">
              <div className="label">
                <p>Type of change</p>
              </div>
              <div className="price-option-container">
                <div
                  className={`price-option new-price ${
                    priceOption === PRICE_UPDATE_OPTION.new_price
                      ? 'selected'
                      : priceOption === PRICE_UPDATE_OPTION.adjust_price
                      ? 'adjust-price-selected'
                      : ''
                  }`}
                  onClick={() => {
                    if (priceOption !== PRICE_UPDATE_OPTION.new_price) {
                      setIsValidPrice(true);
                      dispatch(setIsValidInputPrice(true));
                      setNewPrice('');
                      setIsPositiveSelect(true);
                      setPriceOption(PRICE_UPDATE_OPTION.new_price);
                    }
                  }}>
                  <p>New Price</p>
                </div>
                <div
                  className={`price-option percentage ${
                    priceOption === PRICE_UPDATE_OPTION.percentage ? 'selected' : 'unselected'
                  }`}
                  onClick={() => {
                    if (priceOption !== PRICE_UPDATE_OPTION.percentage) {
                      setIsValidPrice(true);
                      dispatch(setIsValidInputPrice(true));
                      setNewPrice('');
                      setIsPositiveSelect(true);
                      setPriceOption(PRICE_UPDATE_OPTION.percentage);
                    }
                  }}>
                  <p>Percentage</p>
                </div>
                <div
                  className={`price-option adjust-price ${
                    priceOption === PRICE_UPDATE_OPTION.adjust_price
                      ? 'selected'
                      : priceOption === PRICE_UPDATE_OPTION.new_price
                      ? 'new-price-selected'
                      : ''
                  }`}
                  onClick={() => {
                    if (priceOption !== PRICE_UPDATE_OPTION.adjust_price) {
                      setIsValidPrice(true);
                      dispatch(setIsValidInputPrice(true));
                      setNewPrice('');
                      setIsPositiveSelect(true);
                      setPriceOption(PRICE_UPDATE_OPTION.adjust_price);
                    }
                  }}>
                  <p>Adjust Price</p>
                </div>
              </div>
              {priceOption === PRICE_UPDATE_OPTION.new_price && (
                <PriceInput
                  type={PRICE_UPDATE_OPTION.new_price}
                  isValidPrice={isValidPrice}
                  setIsValidPrice={setIsValidPrice}
                  newPrice={newPrice}
                  setNewPrice={setNewPrice}
                  validatePriceInput={props.validatePriceInput}
                  isInStock={props.isInStock}
                  isPositiveSelect={isPositiveSelect}
                  setIsPositiveSelect={setIsPositiveSelect}
                />
              )}
              {priceOption === PRICE_UPDATE_OPTION.percentage && (
                <PriceInput
                  type={PRICE_UPDATE_OPTION.percentage}
                  isValidPrice={isValidPrice}
                  setIsValidPrice={setIsValidPrice}
                  newPrice={newPrice}
                  setNewPrice={setNewPrice}
                  validatePriceInput={props.validatePriceInput}
                  isInStock={props.isInStock}
                  isPositiveSelect={isPositiveSelect}
                  setIsPositiveSelect={setIsPositiveSelect}
                />
              )}
              {priceOption === PRICE_UPDATE_OPTION.adjust_price && (
                <PriceInput
                  type={PRICE_UPDATE_OPTION.adjust_price}
                  isValidPrice={isValidPrice}
                  setIsValidPrice={setIsValidPrice}
                  newPrice={newPrice}
                  setNewPrice={setNewPrice}
                  validatePriceInput={props.validatePriceInput}
                  isInStock={props.isInStock}
                  isPositiveSelect={isPositiveSelect}
                  setIsPositiveSelect={setIsPositiveSelect}
                />
              )}
            </div>
            <IonFooter>
              <div className="action">
                <IonButton
                  className="action-btn cancel-btn"
                  mode="ios"
                  fill="clear"
                  color="dark"
                  onClick={() => {
                    setIsValidPrice(true);
                    dispatch(setIsValidInputPrice(true));
                    setNewPrice('');
                    setIsPositiveSelect(true);
                    setPriceOption(PRICE_UPDATE_OPTION.new_price);
                    props.setShowPriceModal(false);
                  }}>
                  Cancel
                </IonButton>
                <IonButton
                  disabled={!isValidPrice || newPrice === '' || newPrice === 0}
                  className="action-btn"
                  mode="ios"
                  fill="solid"
                  color="primary"
                  expand="block"
                  onClick={() => {
                    setSelectedOption({
                      newPrice: Number(newPrice),
                      isPositiveSelect,
                      priceOption,
                    });
                    props.setShowPriceModal(false);
                    setShowConfirmationModal(true);
                  }}>
                  Save edit
                </IonButton>
              </div>
            </IonFooter>
          </div>
        </div>
      </IonModal>
      <BulkEditConfirmation
        showConfirmationModal={showConfirmationModal}
        setShowConfirmationModal={setShowConfirmationModal}
        setShowEditModal={props.setShowEditModal}
        setPreviousModal={props.setShowPriceModal}
        onConfirm={onConfirm}
        isInStock={props.isInStock}
      />
    </>
  );
};

export default BulkEditPriceModal;
