import {makeRequest} from 'src/Axios';

const notificationService = {
  setNotifications: async (storeId: string, data: any) => {
    try {
      const response: any = await makeRequest({
        url: `/notification/notification-setting/${storeId}`,
        method: 'PATCH',
        body: data,
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getNotificationSettings: async (storeId: string) => {
    try {
      const response: any = await makeRequest({
        url: `/notification/notification-setting/${storeId}`,
        isMicroservice: true,
      });
      const {new_orders, order_expired, order_expiring_soon, store_deactivated, low_battery} =
        response.data.notifications;
      return {
        newOrders: new_orders,
        orderExpired: order_expired,
        orderExpiringSoon: order_expiring_soon,
        storeDeactivated: store_deactivated,
        lowBattery: low_battery,
      };
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  activateEmployeeNotificationDevice: async (device_id: string, player_id: string) => {
    try {
      const body = {active: true, device_id, player_id};
      const response: any = await makeRequest({
        url: '/user-service/activate-employee-notification-device',
        body,
        method: 'POST',
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  deactivateEmployeeNotificationDevice: async (deviceId: string) => {
    try {
      const response: any = await makeRequest({
        url: '/user-service/deactivate-employee-notification-device',
        body: {device_id: deviceId},
        method: 'POST',
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
};

export default notificationService;
