/* eslint-disable react-hooks/exhaustive-deps */
import {IonButton, IonContent, IonIcon, IonImg, IonLabel, IonPage} from '@ionic/react';
import {checkmarkOutline, closeOutline} from 'ionicons/icons';
import {useState} from 'react';
import {useForm} from 'react-hook-form';
import {RouteComponentProps} from 'react-router';
import lulaStoreLogo from 'src/assets/images/store-logo.png';
import Footer from 'src/components/Footer';
import Input from 'src/components/Input';
import {useAppDispatch, useEffectOnce} from 'src/hooks';
import {IPasswordRequirements, IResetPasswordFormInput} from 'src/interfaces/form';
import ResetSuccess from 'src/pages/Auth/components/Success';
import userService from 'src/services/user';
import {showAlert} from 'src/store/alertSlice';
import {setMenuAvailable} from 'src/store/authSlice';
import {hideLoading, showLoading} from 'src/store/loadingSlice';
import {passwordRequirements, RULES} from 'src/utils/constants';
import {validatePassword} from 'src/utils/helpers';
import './styles.scss';

interface IProps extends RouteComponentProps {
  location: any;
}

const NewPassword = ({location}: IProps) => {
  const dispatch = useAppDispatch();
  const [touched, setTouched] = useState(false);
  const [requirements, setRequirements] = useState<Map<string, IPasswordRequirements>>(new Map(passwordRequirements));
  const [valid, setValid] = useState(false);
  const [success, setSuccess] = useState(false);

  const {control, handleSubmit} = useForm<IResetPasswordFormInput>({
    mode: 'onSubmit',
    defaultValues: {
      code: undefined,
      newPassword: '',
    },
  });

  const changePassword = (formData: IResetPasswordFormInput) => {
    dispatch(showLoading());
    const data = {
      email: location.state?.email,
      token: location.state?.token,
      password: formData.newPassword,
      confirm_password: formData.newPassword,
    };
    userService
      .resetPassword(data)
      .then((response) => {
        dispatch(hideLoading());
        setSuccess(true);
      })
      .catch((error) => {
        dispatch(hideLoading());
        dispatch(
          showAlert({
            heading: 'Unable to update',
            message: error?.message || 'Some error occurred while updating password. Please, try again later',
          }),
        );
      });
  };

  const checkRequirements = (value: any) => {
    validatePassword(value, requirements, setRequirements, setValid);
  };

  useEffectOnce(() => {
    dispatch(setMenuAvailable(false));
  });

  return (
    <>
      {success ? (
        <ResetSuccess />
      ) : (
        <IonPage id="auth-page">
          <IonContent fullscreen scrollY={false}>
            <div className="content-container">
              <div className="form-container m-12">
                <IonImg className="logo" src={lulaStoreLogo} />
                <div className="text-container">
                  <h4 className="heading mt-0 mb-0">Time for a new password</h4>
                  <p className="description mb-0">It’s about that time to change your password.</p>
                </div>
                <div>
                  <IonLabel title="Email">Email</IonLabel>
                  <p>{location.state?.email}</p>
                </div>

                <div className="new-password-container">
                  <Input
                    type="password"
                    name="newPassword"
                    wrapperClassName="input-field"
                    label="New password"
                    control={control}
                    onFocus={() => setTouched(true)}
                    onInputChange={(e) => checkRequirements(e)}
                    rules={RULES.password}
                  />
                  <div className="password-requirements">
                    <div className={!touched ? 'desc-text' : valid ? 'valid-text' : 'invalid-text'}>
                      <p className="mb-0">Password requirements: </p>
                    </div>
                    {Array.from(requirements.entries()).map(([key, value]) => {
                      return (
                        <div
                          key={key}
                          className={!touched ? 'desc-text' : value?.isValid ? 'valid-text' : 'invalid-text'}>
                          <IonIcon icon={value?.isValid ? checkmarkOutline : closeOutline} />
                          <p className="mb-0">{value?.message}</p>
                        </div>
                      );
                    })}
                  </div>
                </div>
                <IonButton className="btn-dark mb-0 input-field" expand="block" onClick={handleSubmit(changePassword)}>
                  Create new password
                </IonButton>
              </div>
              <Footer backgroundColor="var(--custom-color-white)" />
            </div>
          </IonContent>
        </IonPage>
      )}
    </>
  );
};

export default NewPassword;
