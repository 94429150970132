import './styles.scss';
import {IonPage, IonContent, IonButton, IonIcon, IonBackButton} from '@ionic/react';
import Header from 'src/components/Header';
import {closeOutline, addOutline, arrowBack} from 'ionicons/icons';
import SchedulePartnerCard from 'src/components/SchedulePartnerCard';
import {IStorePauseItem} from 'src/interfaces/store';

interface IProps {
  isDesktop: boolean;
  pauseScheduleList: Array<IStorePauseItem>;
  onAddStorePauseSchedule: () => void;
  onUpdateStorePauseSchedule: (pauseId: string, data: any) => void;
  onArchiveStorePauseSchedule: (data: any) => void;
  onClose?: () => void;
}

const MobileScreen = ({
  isDesktop,
  pauseScheduleList,
  onAddStorePauseSchedule,
  onUpdateStorePauseSchedule,
  onArchiveStorePauseSchedule,
  onClose,
}: IProps) => {
  return (
    <IonPage id="scheduled-partner-mobile">
      <IonContent fullscreen scrollY={false}>
        <Header
          border
          noBackground
          leftButton={
            isDesktop ? (
              <IonButton onClick={onClose}>
                <IonIcon src={closeOutline} />
              </IonButton>
            ) : (
              <IonBackButton defaultHref="/partners" text="" icon={arrowBack} />
            )
          }
          title={'Scheduled pauses'}
        />
        <div className="body">
          {pauseScheduleList.length ? (
            pauseScheduleList?.map((pauseScheduleItem: IStorePauseItem) => (
              <SchedulePartnerCard
                key={pauseScheduleItem.id}
                pauseScheduleItem={pauseScheduleItem}
                onUpdateStorePauseSchedule={onUpdateStorePauseSchedule}
                onArchiveStorePauseSchedule={onArchiveStorePauseSchedule}
              />
            ))
          ) : (
            <div className="no-scheduled-partner">
              <h3 className="heading">Going on vacation? Doctors appointment? Lunch plans for next week? </h3>
              <p>
                We have you covered with our new feature Scheduled pauses. This feature allows you to plan for the
                future by halting all orders for a few minutes or up one full day.
              </p>
            </div>
          )}
          <div>
            <IonButton
              size="default"
              fill="outline"
              expand="block"
              onClick={pauseScheduleList.length < 7 ? onAddStorePauseSchedule : () => {}}>
              <IonIcon icon={addOutline} />
              New
            </IonButton>
            {pauseScheduleList.length >= 7 && (
              <div className="max-schedule">
                <p>You have maxed out the number of scheduled pauses allowed.</p>
                <p>
                  To create a new scheduled pause, you may delete a live pause or contact our Customer Success team at
                  (267) 263-7197 or wecare@luladelivery.com
                </p>
              </div>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MobileScreen;
