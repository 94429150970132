import {createAsyncThunk, createSlice} from '@reduxjs/toolkit';
import {Capacitor} from '@capacitor/core';
import {AppVersion} from '@awesome-cordova-plugins/app-version';
import {Device} from '@capacitor/device';
import { IDevice } from 'src/interfaces/device';

interface IPlatform {
  isDesktop: boolean;
  isTablet: boolean;
  isMobile: boolean;
  isNative: boolean;
  isAndroid: boolean;
  versionNumber: string;
  versionCode: string | number;
  device: IDevice;
}

const initialState: IPlatform = {
  isDesktop: false,
  isTablet: false,
  isMobile: false,
  isNative: Capacitor.isNativePlatform(),
  isAndroid: Capacitor.getPlatform() === 'android',
  versionNumber: '',
  versionCode: '',
  device: {deviceInfo: null, deviceId: ''},
};

export const setDeviceInfo = createAsyncThunk(
  'auth/setDeviceInfo',
  async (_, {fulfillWithValue, rejectWithValue}): Promise<any> => {
    try {
      const deviceInfo = await Device.getInfo();
      const deviceId = await Device.getId();
      return fulfillWithValue({deviceInfo, deviceId});
    } catch (error: any) {
      console.error('Error fetching device info:');
      return rejectWithValue(error);
    }
  },
);

export const setAppVersion = createAsyncThunk(
  'platform/setAppVersion',
  async (_, {fulfillWithValue, rejectWithValue}): Promise<any> => {
    try {
      const response = {
        versionNumber: await AppVersion.getVersionNumber(),
        versionCode: await AppVersion.getVersionCode(),
      };
      return fulfillWithValue(response);
    } catch (error: any) {
      console.error('Error fetching app version and code:');
      return rejectWithValue(error);
    }
  },
);

const platformSlice = createSlice({
  name: 'platform',
  initialState,
  reducers: {
    setIsDesktop: (state, action) => {
      state.isDesktop = action.payload;
    },
    setIsTablet: (state, action) => {
      state.isTablet = action.payload;
    },
    setIsMobile: (state, action) => {
      state.isMobile = action.payload;
    },
    setIsNative: (state, action) => {
      state.isNative = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(setAppVersion.fulfilled, (state, action) => {
      state.versionNumber = action.payload.versionNumber;
      state.versionCode = action.payload.versionCode;
    });
    builder.addCase(setDeviceInfo.fulfilled, (state, action) => {
      state.device.deviceInfo = action.payload?.deviceInfo;
      state.device.deviceId = action.payload?.deviceId?.uuid;
    });
  },
});

export const {setIsDesktop, setIsMobile, setIsTablet, setIsNative} = platformSlice.actions;

export default platformSlice.reducer;
