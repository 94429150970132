import moment from 'moment';
import {useHistory} from 'react-router';
import {useAppDispatch, useAppSelector} from 'src/hooks';
import {addStorePauseSchedule, archiveStorePauseSchedule, updateStorePauseSchedule} from 'src/store/storeSlice';
import {Desktop, Tablet, Mobile} from 'src/utils/platform';
import MobileScreen from './Mobile';

interface IProps {
  setShowSchedulePause: (show: boolean) => void;
}

const SchedulePause = ({setShowSchedulePause}: IProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const {storePauseScheduleList} = useAppSelector((state) => state.store);
  const {isDesktop} = useAppSelector((state) => state.platform);

  const onAddStorePauseSchedule = () => {
    const data = {
      start_date: moment().add(1, 'day').format('YYYY-MM-DD'),
      end_date: moment().add(1, 'day').format('YYYY-MM-DD'),
      start_time: '00:00',
      end_time: '23:59',
      dsp_name: 'All',
    };
    dispatch(addStorePauseSchedule(data));
  };

  const onClose = () => {
    if (setShowSchedulePause) {
      setShowSchedulePause(false);
      history.replace({pathname: '/partners', state: {showSchedulePause: false}});
    } else history.goBack();
  };

  const onUpdateStorePauseSchedule = (pauseId: string, payload: any) => {
    const data = {
      pauseId,
      payload,
    };
    dispatch(updateStorePauseSchedule(data));
  };

  const onArchiveStorePauseSchedule = (data: any) => {
    dispatch(archiveStorePauseSchedule(data));
  };

  const props = {
    pauseScheduleList: storePauseScheduleList,
    onAddStorePauseSchedule,
    onUpdateStorePauseSchedule,
    onArchiveStorePauseSchedule,
    onClose,
    isDesktop,
  };
  return (
    <>
      <Desktop>
        <MobileScreen {...props} />
      </Desktop>
      <Tablet>
        <MobileScreen {...props} />
      </Tablet>
      <Mobile>
        <MobileScreen {...props} />
      </Mobile>
    </>
  );
};

export default SchedulePause;
