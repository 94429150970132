import {useState, useRef} from 'react';
import {IonIcon, IonLabel, IonDatetime, IonButton, IonModal, IonPopover, IonDatetimeButton} from '@ionic/react';
import moment from 'moment';
import './styles.scss';
import {useAppDispatch} from 'src/hooks';
import {showAlert} from 'src/store/alertSlice';

interface IProps {
  onDateChange: (startDate: string, endDate: string) => void;
}

const DATETIME_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss';
const TIME_FORMAT = 'hh:mm A';
const DATE_FORMAT = 'YYYY-MM-DD';

const OrderHistoryDate = (props: IProps) => {
  const dispatch = useAppDispatch();
  moment.updateLocale(moment.locale(), {invalidDate: '00/00/00'});
  const [openedFrom, setOpenedFrom] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [dateFrom, setDateFrom] = useState(moment().startOf('month').format(DATE_FORMAT));
  const [dateTo, setDateTo] = useState(moment().endOf('month').format(DATE_FORMAT));
  const [timeFrom, setTimeFrom] = useState('12:00 AM');
  const [timeTo, setTimeTo] = useState('11:59 PM');

  const popover = useRef<HTMLIonPopoverElement>(null);

  const getDate = (date: string) => moment(date).format('MM/DD/YYYY') ?? '00/00/0000';
  const getDatetimeObj = (datetime: string) => moment(moment(datetime).format(DATETIME_FORMAT));
  const getUTCDatetime = (datetime: string) => moment(datetime).utc().format(DATETIME_FORMAT);

  const openPopover = (e: any, from: boolean) => {
    popover.current!.event = e;
    setIsOpen(true);
    setOpenedFrom(from);
  };

  const onDateChange = (e: any) => {
    const date = e.detail.value;

    if (openedFrom) {
      if (getDatetimeObj(date + ' ' + timeFrom).isBefore(getDatetimeObj(dateTo + ' ' + timeTo))) {
        setDateFrom(date);
        props.onDateChange(getUTCDatetime(date + ' ' + timeFrom), getUTCDatetime(dateTo + ' ' + timeTo));
      } else {
        dispatch(
          showAlert({
            heading: 'Error',
            message: 'Start date time greater than end Date time!',
          }),
        );
      }
    } else {
      if (getDatetimeObj(dateFrom + ' ' + timeFrom).isBefore(getDatetimeObj(date + ' ' + timeTo))) {
        setDateTo(date);
        props.onDateChange(getUTCDatetime(dateFrom + ' ' + timeFrom), getUTCDatetime(date + ' ' + timeTo));
      } else {
        dispatch(
          showAlert({
            heading: 'Error',
            message: 'Start date time greater than end Date time!',
          }),
        );
      }
    }
  };

  const resetDateFilter = () => {
    setIsOpen(false);
    setTimeFrom('12:00 AM');
    setTimeTo('11:59 PM');
    setDateFrom(moment().startOf('month').format(DATE_FORMAT));
    setDateTo(moment().endOf('month').format(DATE_FORMAT));
    props.onDateChange(getUTCDatetime(dateFrom + ' ' + timeFrom), getUTCDatetime(dateTo + ' ' + timeTo));
  };

  const getDataByCustomDateTime = () => {
    props.onDateChange(getUTCDatetime(dateFrom + ' ' + timeFrom), getUTCDatetime(dateTo + ' ' + timeTo));
  };

  return (
    <>
      <div id="history-filter-date">
        <div className="filter-date-icon">
          <IonIcon className="icon-calendar" slot="start" />
        </div>
        <div
          className={`filter-date filter-date-from ${isOpen && openedFrom ? 'opened-from' : ''}`}
          onClick={(e) => openPopover(e, true)}>
          <IonLabel>
            {getDate(dateFrom)} {timeFrom}
          </IonLabel>
        </div>
        <div
          className={`filter-date filter-date-to ${isOpen && !openedFrom ? 'opened-to' : ''}`}
          onClick={(e) => openPopover(e, false)}>
          <IonLabel>
            {getDate(dateTo)} {timeTo}
          </IonLabel>
        </div>
      </div>

      <IonPopover
        className="history-filter-date-content"
        mode="md"
        showBackdrop={false}
        ref={popover}
        isOpen={isOpen}
        alignment={openedFrom ? 'start' : 'end'}
        onDidDismiss={() => setIsOpen(false)}>
        <IonDatetime presentation="date" value={openedFrom ? dateFrom : dateTo} onIonChange={onDateChange} />
        <div className="label-container">
          <IonLabel>Time</IonLabel>
          <div className="date-time">
            <IonDatetimeButton datetime="datetime" />
            <IonModal
              keepContentsMounted
              onDidDismiss={(e) => {
                if (getDatetimeObj(dateFrom + ' ' + timeFrom).isBefore(getDatetimeObj(dateTo + ' ' + timeTo))) {
                  getDataByCustomDateTime();
                } else {
                  dispatch(
                    showAlert({
                      heading: 'Error',
                      message: 'Start date time greater than end Date time!',
                    }),
                  );
                }
              }}>
              <IonDatetime
                id="datetime"
                presentation="time"
                onIonChange={(e) => {
                 openedFrom ? setTimeFrom(moment(e.detail.value).format(TIME_FORMAT)) : setTimeTo(moment(e.detail.value).format(TIME_FORMAT));
                }}
                value={openedFrom ? moment(dateFrom + ' ' + timeFrom).format(DATETIME_FORMAT) : moment(dateTo + ' ' + timeTo).format(DATETIME_FORMAT)}
              />
            </IonModal>
          </div>
        </div>
        <div className="action-btn">
          <IonButton className="btn-clear" mode="ios" fill="outline" expand="block" onClick={resetDateFilter}>
            Reset date range
          </IonButton>
        </div>
      </IonPopover>
    </>
  );
};

export default OrderHistoryDate;
