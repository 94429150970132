import * as PERMISSIONS from 'src/utils/permissions';
import {ROLES} from 'src/enums/roles';

export const ROLE_PERMISSIONS: any = {
  [ROLES.LIMITED_USER]: [
    {name: PERMISSIONS.VIEW_CHAT_BOT, description: ''},
    {name: PERMISSIONS.VIEW_ORDERS, description: ''},
    {name: PERMISSIONS.EDIT_ORDERS, description: ''},
    {name: PERMISSIONS.DENY_ORDER, description: ''},
    {name: PERMISSIONS.VIEW_SHOP, description: ''},
    {name: PERMISSIONS.VIEW_KNOWLEDGE_BASE, description: ''},
    {name: PERMISSIONS.VIEW_STORE_DETAILS, description: ''},
    {name: PERMISSIONS.EDIT_STORE_HOURS, description: ''},
    {name: PERMISSIONS.VIEW_ACCOUNT_DETAILS, description: ''},
    {name: PERMISSIONS.VIEW_ACCOUNT_NOTIFICATIONS, description: ''},
    {name: PERMISSIONS.EDIT_PARTNERS_STATUS, description: ''},
  ],
  [ROLES.REGULAR_USER]: [
    {name: PERMISSIONS.VIEW_ACCOUNT_DETAILS, description: ''},
    {name: PERMISSIONS.EDIT_ACCOUNT_DETAILS, description: ''},
    {name: PERMISSIONS.VIEW_ACCOUNT_NOTIFICATIONS, description: ''},
    {name: PERMISSIONS.EDIT_ACCOUNT_NOTIFICATIONS, description: ''},
    {name: PERMISSIONS.VIEW_CHAT_BOT, description: ''},
    {name: PERMISSIONS.VIEW_KNOWLEDGE_BASE, description: ''},
    {name: PERMISSIONS.VIEW_INVENTORY, description: ''},
    {name: PERMISSIONS.EDIT_ITEM, description: ''},
    {name: PERMISSIONS.CREATE_ITEM, description: ''},
    {name: PERMISSIONS.DELETE_ITEM, description: ''},
    {name: PERMISSIONS.VIEW_ORDERS, description: ''},
    {name: PERMISSIONS.EDIT_ORDERS, description: ''},
    {name: PERMISSIONS.DENY_ORDER, description: ''},
    {name: PERMISSIONS.EDIT_PARTNERS_STATUS, description: ''},
    {name: PERMISSIONS.VIEW_STORE_DETAILS, description: ''},
    {name: PERMISSIONS.EDIT_STORE_HOURS, description: ''},
  ],
  [ROLES.ADMIN]: [
    {name: PERMISSIONS.VIEW_ACCOUNT_DETAILS, description: ''},
    {name: PERMISSIONS.EDIT_ACCOUNT_DETAILS, description: ''},
    {name: PERMISSIONS.VIEW_ACCOUNT_NOTIFICATIONS, description: ''},
    {name: PERMISSIONS.EDIT_ACCOUNT_NOTIFICATIONS, description: ''},
    {name: PERMISSIONS.VIEW_EMPLOYEES, description: ''},
    {name: PERMISSIONS.EDIT_EMPLOYEES, description: ''},
    {name: PERMISSIONS.CREATE_EMPLOYEES, description: ''},
    {name: PERMISSIONS.DELETE_EMPLOYEES, description: ''},
    {name: PERMISSIONS.VIEW_CHAT_BOT, description: ''},
    {name: PERMISSIONS.VIEW_SHOP, description: ''},
    {name: PERMISSIONS.VIEW_KNOWLEDGE_BASE, description: ''},
    {name: PERMISSIONS.VIEW_INVENTORY, description: ''},
    {name: PERMISSIONS.EDIT_ITEM, description: ''},
    {name: PERMISSIONS.CREATE_ITEM, description: ''},
    {name: PERMISSIONS.DELETE_ITEM, description: ''},
    {name: PERMISSIONS.VIEW_MENU, description: ''},
    {name: PERMISSIONS.EDIT_MENU, description: ''},
    {name: PERMISSIONS.CREATE_MENU, description: ''},
    {name: PERMISSIONS.DELETE_MENU, description: ''},
    {name: PERMISSIONS.VIEW_ORDERS, description: ''},
    {name: PERMISSIONS.EDIT_ORDERS, description: ''},
    {name: PERMISSIONS.DENY_ORDER, description: ''},
    {name: PERMISSIONS.VIEW_ORDER_HISTORY, description: ''},
    {name: PERMISSIONS.VIEW_REPORTS, description: ''},
    {name: PERMISSIONS.VIEW_STORE_DETAILS, description: ''},
    {name: PERMISSIONS.EDIT_STORE_DETAILS, description: ''},
    {name: PERMISSIONS.EDIT_STORE_HOURS, description: ''},
    {name: PERMISSIONS.EDIT_STORE_STATUS, description: ''},
    {name: PERMISSIONS.EDIT_PARTNERS_STATUS, description: ''},
    {name: PERMISSIONS.VIEW_PRINTER_SETTINGS, description: ''},
    {name: PERMISSIONS.EDIT_PRINTER_SETTINGS, description: ''},
    {name: PERMISSIONS.CREATE_PRINTER_CONNECTION, description: ''},
    {name: PERMISSIONS.DELETE_PRINTER_CONNECTION, description: ''},
    {name: PERMISSIONS.VIEW_STORE_SHIPPING_DETAILS, description: ''},
    {name: PERMISSIONS.EDIT_STORE_SHIPPING_DETAILS, description: ''},
    {name: PERMISSIONS.VIEW_INSIGHTS_OVERVIEW, description: ''},
    {name: PERMISSIONS.VIEW_INSIGHTS_INVENTORY, description: ''},
    {name: PERMISSIONS.VIEW_INSIGHTS_STORES, description: ''},
    {name: PERMISSIONS.VIEW_INSIGHTS_ORDERS, description: ''},
    {name: PERMISSIONS.VIEW_INSIGHTS_REPORTS, description: ''},
    {name: PERMISSIONS.VIEW_INSIGHTS_PAYOUTS, description: ''},
    {name: PERMISSIONS.VIEW_BILLING, description: ''},
  ],
  [ROLES.STORE_ADMIN]: [
    {name: PERMISSIONS.VIEW_ACCOUNT_DETAILS, description: ''},
    {name: PERMISSIONS.EDIT_ACCOUNT_DETAILS, description: ''},
    {name: PERMISSIONS.VIEW_ACCOUNT_NOTIFICATIONS, description: ''},
    {name: PERMISSIONS.EDIT_ACCOUNT_NOTIFICATIONS, description: ''},
    {name: PERMISSIONS.VIEW_EMPLOYEES, description: ''},
    {name: PERMISSIONS.EDIT_EMPLOYEES, description: ''},
    {name: PERMISSIONS.CREATE_EMPLOYEES, description: ''},
    {name: PERMISSIONS.DELETE_EMPLOYEES, description: ''},
    {name: PERMISSIONS.VIEW_CHAT_BOT, description: ''},
    {name: PERMISSIONS.VIEW_SHOP, description: ''},
    {name: PERMISSIONS.VIEW_KNOWLEDGE_BASE, description: ''},
    {name: PERMISSIONS.VIEW_INVENTORY, description: ''},
    {name: PERMISSIONS.EDIT_ITEM, description: ''},
    {name: PERMISSIONS.CREATE_ITEM, description: ''},
    {name: PERMISSIONS.DELETE_ITEM, description: ''},
    {name: PERMISSIONS.VIEW_MENU, description: ''},
    {name: PERMISSIONS.EDIT_MENU, description: ''},
    {name: PERMISSIONS.CREATE_MENU, description: ''},
    {name: PERMISSIONS.DELETE_MENU, description: ''},
    {name: PERMISSIONS.VIEW_ORDERS, description: ''},
    {name: PERMISSIONS.EDIT_ORDERS, description: ''},
    {name: PERMISSIONS.DENY_ORDER, description: ''},
    {name: PERMISSIONS.VIEW_ORDER_HISTORY, description: ''},
    {name: PERMISSIONS.VIEW_REPORTS, description: ''},
    {name: PERMISSIONS.VIEW_STORE_DETAILS, description: ''},
    {name: PERMISSIONS.EDIT_STORE_DETAILS, description: ''},
    {name: PERMISSIONS.EDIT_STORE_HOURS, description: ''},
    {name: PERMISSIONS.EDIT_STORE_STATUS, description: ''},
    {name: PERMISSIONS.EDIT_PARTNERS_STATUS, description: ''},
    {name: PERMISSIONS.VIEW_PRINTER_SETTINGS, description: ''},
    {name: PERMISSIONS.EDIT_PRINTER_SETTINGS, description: ''},
    {name: PERMISSIONS.CREATE_PRINTER_CONNECTION, description: ''},
    {name: PERMISSIONS.DELETE_PRINTER_CONNECTION, description: ''},
    {name: PERMISSIONS.VIEW_STORE_SHIPPING_DETAILS, description: ''},
    {name: PERMISSIONS.EDIT_STORE_SHIPPING_DETAILS, description: ''},
    {name: PERMISSIONS.VIEW_INSIGHTS_OVERVIEW, description: ''},
    {name: PERMISSIONS.VIEW_INSIGHTS_INVENTORY, description: ''},
    {name: PERMISSIONS.VIEW_INSIGHTS_STORES, description: ''},
    {name: PERMISSIONS.VIEW_INSIGHTS_ORDERS, description: ''},
    {name: PERMISSIONS.VIEW_INSIGHTS_REPORTS, description: ''},
    {name: PERMISSIONS.VIEW_INSIGHTS_PAYOUTS, description: ''},
    {name: PERMISSIONS.VIEW_BILLING, description: ''},
  ],
};
