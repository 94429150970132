import {Controller} from 'react-hook-form';
import {IonIcon, IonLabel, IonItem, IonSelect, IonSelectOption, IonNote} from '@ionic/react';
import {chevronDownOutline} from 'ionicons/icons';
import './styles.scss';

interface IProps {
  name: string;
  rules?: any;
  control: any;
  label?: string;
  optional?: boolean;
  options: Array<{id: string; name: string}>;
  disabled?: boolean;
  wrapperClassName?: string;
}

const Select = ({control, name, label, optional, disabled, rules, options, wrapperClassName}: IProps) => (
  <Controller
    name={name}
    control={control}
    rules={rules || {}}
    render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
      <div id="select-input" className={wrapperClassName}>
        <IonLabel position="stacked">
          {label} {optional && <span> (optional)</span>}
        </IonLabel>
        <IonItem className={invalid ? 'invalid' : ''}>
          <IonSelect
            value={value}
            disabled={disabled}
            interface="popover"
            onIonChange={(e) => onChange(e.detail.value)}>
            <IonSelectOption value="">Select</IonSelectOption>
            {options.map((option) => (
              <IonSelectOption key={option.id} value={option.id}>
                {option.name}
              </IonSelectOption>
            ))}
          </IonSelect>
          <IonIcon icon={chevronDownOutline} slot="end" />
        </IonItem>
        {error && <IonNote slot="error">{error?.message}</IonNote>}
      </div>
    )}
  />
);

export default Select;
