import {makeRequest} from 'src/Axios';
import {getDetailsFromOrder, isValidOrderStatus} from 'src/utils/helpers';

const orderService = {
  getOrdersCount: async (storeId: string) => {
    try {
      const params = {store_id: storeId};
      const response: any = await makeRequest({url: '/orders/counts', params, isMicroservice: true});
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getOrders: async (storeId: string, status: 'active' | 'completed', offset: number) => {
    try {
      const params: any = {store_id: storeId, status, offset, limit: 20};
      const response: any = await makeRequest({url: '/orders', params, isMicroservice: true});
      return response.data.map((item: any) => getDetailsFromOrder(item));
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getNewIncomingOrders: async (storeId: string, datetime: string) => {
    try {
      const params: any = {from_date: datetime, store_id: storeId};
      const response: any = await makeRequest({url: '/orders/incoming', params, isMicroservice: true});
      return response.data.map((item: any) => getDetailsFromOrder(item));
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  acceptIncomingOrder: async (orderId: string, data: any) => {
    try {
      const response: any = await makeRequest({
        url: `/orders/${orderId}/accept`,
        method: 'PUT',
        body: data,
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  markOrderReadyForPickup: async (orderId: string) => {
    try {
      const response: any = await makeRequest({
        url: `/orders/${orderId}/make-ready`,
        method: 'PATCH',
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getOrderDetails: async (storeId: string, orderId: string) => {
    try {
      const params = {store_id: storeId, order_id: orderId};
      const response: any = await makeRequest({url: '/orders/details', params, isMicroservice: true});
      return getDetailsFromOrder(response.data);
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  updateLingeringOrder: async (store_id: string, order_id: string) => {
    try {
      const response: any = await makeRequest({
        url: `/orders/lingering-order`,
        method: 'PUT',
        params: {
          store_id,
          order_id,
        },
        isMicroservice: true,
      });
      if (!isValidOrderStatus(response?.data?.status) || !response?.data?.success) {
        throw Error(`lingering order update error`);
      }
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  cancelOrder: async (id: string, data: any) => {
    try {
      const response: any = await makeRequest({
        url: `/orders/${id}/cancel`,
        method: 'PUT',
        body: data,
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getOrdersHistory: async (params: any) => {
    try {
      const response: any = await makeRequest({url: '/orders/history', params, isMicroservice: true});
      const items = response.data.history_orders;
      return items.map((item: any) => getDetailsFromOrder(item));
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getOrdersHistoryMetrics: async (params: any) => {
    try {
      const response: any = await makeRequest({url: '/orders/history-stats', params, isMicroservice: true});
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  deliverOrder: async (storeId: string, orderId: string) => {
    try {
      const response: any = await makeRequest({
        url: `/orders/${orderId}/delivered`,
        body: {store_id: storeId},
        method: 'PATCH',
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  patchOrderCart: async (orderId: string, data: any) => {
    try {
      const response: any = await makeRequest({
        url: `/orders/${orderId}/patch-order`,
        method: 'PATCH',
        body: data,
        isMicroservice: true,
      });
      return getDetailsFromOrder(response.data);
    } catch (error: any) {
      throw error.response?.data;
    }
  },
};

export default orderService;
