/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect, useRef} from 'react';
import {
  IonPage,
  IonContent,
  IonButton,
  IonFooter,
  IonIcon,
  IonSpinner,
  IonGrid,
  IonRow,
  IonCol,
  IonItem,
  IonLabel,
  IonBackButton,
  IonRefresher,
  IonRefresherContent,
  RefresherEventDetail,
} from '@ionic/react';
import {searchOutline} from 'ionicons/icons';
import {RouteComponentProps} from 'react-router';
import './styles.scss';
import SearchItem from 'src/pages/Items/Search';
import ItemDetails from 'src/pages/Items/Details';
import Snackbar from 'src/components/Snackbar';
import Header from 'src/components/Header';
import TextWithLines from 'src/components/TextWithLines';
import CommonItem from 'src/components/CommonItem';
import NoItem from 'src/components/NoItem';
import itemService from 'src/services/item';
import {ISnackbar} from 'src/interfaces/snackbar';
import {ICategory} from 'src/interfaces/item';
import {ITEM_TYPE} from 'src/enums/item_type';
import {useAppDispatch, useAppSelector, useEffectOnce} from 'src/hooks';
import {showAlert} from 'src/store/alertSlice';
import {showLoading, hideLoading} from 'src/store/loadingSlice';
import {addToInventory, fetchInventoryCount, removeFromInventory} from 'src/store/inventorySlice';
import AnalyticsHelper from 'src/utils/segment';
import {getItemFromStoreItemNew} from 'src/utils/helpers';
import {
  fetchCommonItems,
  fetchItemsOfCategory,
  fetchSearchedItems,
  setCommonItems,
  setCommonOffset,
  setLoadMoreCommon,
  setSearchedItems,
} from 'src/store/itemSlice';
import arrowBack from 'src/assets/images/arrow-back-black.svg';

interface IProps extends RouteComponentProps {}

const CommonItems = ({history, location, match}: IProps) => {
  const dispatch = useAppDispatch();
  const initialRender = useRef(true);
  const {isDesktop, isTablet} = useAppSelector((state) => state.platform);
  const {id: storeId} = useAppSelector((state) => state.store);
  const {menuIsOpen} = useAppSelector((state) => state.auth);
  const {categories} = useAppSelector((state) => state.category);
  const {inventoryCount} = useAppSelector((state) => state.inventory);
  const {commonItems, searchItems, loading, loadMoreCommon, loadMoreSearch} = useAppSelector((state) => state.item);

  const [searchTerm, setSearchTerm] = useState('');
  const [selectedCategory, setSelectedCategory] = useState({id: '', name: ''});
  const [showAllCategories, setShowAllCategories] = useState(false);
  const [showSearch, setShowSearch] = useState(false);
  const [showDetails, setShowDetails] = useState(false);
  const [searchScreen, setSearchScreen] = useState(false);
  const [snackbar, setSnackbar] = useState<ISnackbar>({
    show: false,
    type: 'success',
    title: '',
    buttonTitle: 'undo',
    onButtonClick: () => {},
    onDismiss: () => {},
  });

  const onSearchClick = () => {
    if (isDesktop) {
      setShowSearch(true);
      setShowDetails(false);
    } else history.push('/items/common/search');
  };

  const addDetails = () => {
    if (isDesktop) {
      setShowDetails(true);
      setShowSearch(false);
    } else history.push({pathname: '/items/details', state: {type: ITEM_TYPE.COMMON}});
  };

  const dismissSnackbar = () => {
    setSnackbar({...snackbar, show: false, title: '', onButtonClick: () => {}});
  };

  const addItem = (id: string, undo: boolean = false) => {
    // First show user that item is selected.
    const items = getItems();
    const updatedCommonItems = items.map((item: any) => (item.id === id ? {...item, selected: true} : item));
    if (searchScreen) dispatch(setSearchedItems(updatedCommonItems));
    else dispatch(setCommonItems(updatedCommonItems));
    // Adding item to store inventory.
    dispatch(showLoading());
    itemService
      .addItemToStore(storeId, id, true)
      .then((response: any) => {
        dispatch(hideLoading());
        const currentItem = getItemFromStoreItemNew(response);
        dispatch(addToInventory(currentItem));
        AnalyticsHelper.trackAddNewItem(currentItem, response.is_reviewed, 'Selection');
        const updatedCommonItems = items.map((item: any) => {
          if (item.id === id) {
            const updatedItem = {...item, storeItemId: response.id};
            dispatch(fetchInventoryCount());
            return updatedItem;
          } else return item;
        });
        if (setShowDetails) setShowDetails(false);
        if (searchScreen) dispatch(setSearchedItems(updatedCommonItems));
        else dispatch(setCommonItems(updatedCommonItems));
        if (!undo) {
          setSnackbar({
            ...snackbar,
            show: true,
            type: 'success',
            title: 'Item added to Inventory.',
            onButtonClick: () => removeItem(id, response.id, true),
            onDismiss: dismissSnackbar,
          });
        }
      })
      .catch((error) => {
        dispatch(hideLoading());
        dispatch(
          showAlert({
            heading: 'Unable to add',
            message: error?.message
              ? error?.message
              : 'Some error occurred while adding item to your store. Please, try again later',
          }),
        );
        // If error occurred while adding item then unselect the item.
        const updatedCommonItems = items.map((item: any) => (item.id === id ? {...item, selected: false} : item));
        if (searchScreen) dispatch(setSearchedItems(updatedCommonItems));
        else dispatch(setCommonItems(updatedCommonItems));
      });
  };

  const removeItem = (id: string, storeItemId: string, undo: boolean = false) => {
    const items = getItems();
    dispatch(showLoading());
    itemService
      .removeItemFromStore(storeId, storeItemId)
      .then((_) => {
        dispatch(hideLoading());
        const updatedCommonItems = items.map((item: any) => {
          if (item.id === id) {
            const updatedItem = {...item, storeItemId: 0};
            dispatch(removeFromInventory(storeItemId));
            return updatedItem;
          } else return item;
        });
        if (searchScreen) dispatch(setSearchedItems(updatedCommonItems));
        else dispatch(setCommonItems(updatedCommonItems));
        if (!undo) {
          setSnackbar({
            ...snackbar,
            show: true,
            type: 'error',
            title: 'Item removed fom Inventory.',
            onButtonClick: () => addItem(id, true),
            onDismiss: dismissSnackbar,
          });
        }
        dispatch(fetchInventoryCount());
      })
      .catch((error) => {
        dispatch(hideLoading());
        dispatch(
          showAlert({
            heading: 'Unable to remove',
            message: error?.message || 'Some error occurred while removing item from store. Please, try again later',
          }),
        );
      });
  };

  const fetchItems = async () => {
    try {
      dispatch(fetchCommonItems());
      if (initialRender.current) initialRender.current = false;
    } catch (error) {
      dispatch(hideLoading());
      dispatch(showAlert({heading: 'Error', message: 'Some error occurred. Please, try again later'}));
    }
  };

  const fetchNextItems = (e: any) => {
    if (commonItems.length >= 20) {
      const {scrollHeight, scrollTop, clientHeight} = e.target;
      if (scrollHeight - scrollTop <= clientHeight + 10 && !loading) {
        if (loadMoreCommon && selectedCategory.id) dispatch(fetchItemsOfCategory(selectedCategory.id));
        if (loadMoreCommon && !searchScreen && !selectedCategory.id) dispatch(fetchCommonItems());
        if (loadMoreSearch && searchScreen) dispatch(fetchSearchedItems(searchTerm));
      }
    }
  };

  const onCategorySelect = (category: ICategory) => {
    if (category.id === selectedCategory.id) setSelectedCategory({id: '', name: ''});
    else setSelectedCategory({...category});
  };

  const getItems = () => {
    if (searchTerm.length > 0 && searchScreen) {
      return searchItems;
    } else return commonItems;
  };

  const onRefresh = async (event: CustomEvent<RefresherEventDetail>) => {
    dispatch(setCommonItems([]));
    dispatch(setCommonOffset(0));
    dispatch(setLoadMoreCommon(true));
    if (selectedCategory.id) {
      await dispatch(fetchItemsOfCategory(selectedCategory.id));
    } else await dispatch(fetchCommonItems());
    // Completing refresh.
    event.detail.complete();
  };

  useEffectOnce(() => {
    fetchItems();
    return () => {
      setSelectedCategory({id: '', name: ''});
      setShowAllCategories(false);
      setCommonItems([]);
      setSnackbar({
        show: false,
        type: 'success',
        title: '',
        buttonTitle: 'undo',
        onButtonClick: () => {},
        onDismiss: () => {},
      });
    };
  });

  useEffect(() => {
    if (searchScreen) {
      dispatch(setCommonItems([]));
    }
  }, [searchScreen]);

  useEffect(() => {
    if (!initialRender.current) {
      setSearchScreen(false);
      setShowSearch(false);
      dispatch(setCommonItems([]));
      dispatch(setSearchedItems([]));
      dispatch(setCommonOffset(0));
      dispatch(setLoadMoreCommon(true));
      getItems();
      setSearchTerm('');
      dispatch(fetchItemsOfCategory(selectedCategory.id));
    }
  }, [selectedCategory.id]);

  const allCategories = showAllCategories ? categories : categories.slice(0, 5);
  const items = getItems();
  const {incomplete: incompleteCount} = inventoryCount;

  return (
    <IonPage id="common-items-page" className="snow-background">
      <IonGrid>
        <IonRow>
          <IonCol sizeXl="8" className={`${isTablet && !menuIsOpen ? 'menu-close' : ''}`}>
            <IonContent fullscreen scrollY={false} className="page-container">
              <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
                <IonRefresherContent></IonRefresherContent>
              </IonRefresher>

              <Header
                noBackground
                alignTitleLeft
                title="Browse Items"
                rightButton={
                  isDesktop && (
                    <IonButton fill="clear" strong onClick={onSearchClick}>
                      <IonIcon icon={searchOutline} />
                    </IonButton>
                  )
                }
                leftButton={!isDesktop && <IonBackButton text="" icon={arrowBack} />}
              />

              <div className="body with-footer" onScroll={fetchNextItems}>
                {!isDesktop && (
                  <IonItem className="search-bar" onClick={onSearchClick}>
                    <IonIcon className="search-icon" icon={searchOutline} />
                    <IonLabel>What are you looking for?</IonLabel>
                  </IonItem>
                )}

                <div className="cat-container">
                  <div className="header">
                    <h2 className="title">Categories</h2>
                    <IonButton fill="clear" onClick={() => setShowAllCategories(!showAllCategories)}>
                      {showAllCategories ? 'Show less' : 'Show all'}
                    </IonButton>
                  </div>
                  {allCategories.map((category, i) => (
                    <IonButton
                      key={i}
                      fill="clear"
                      className={`cat-button ${category.id === selectedCategory.id ? 'selected' : ''}`}
                      onClick={() => onCategorySelect(category)}>
                      {category.name}
                    </IonButton>
                  ))}
                </div>
                <div>
                  <TextWithLines text={selectedCategory.name ? `${selectedCategory.name} products` : 'all products'} />

                  {items.length ? (
                    <div className="items-list" onScroll={fetchNextItems}>
                      {items.map((item, i) => (
                        <CommonItem
                          key={i}
                          {...{
                            ...item,
                            // We don't have the storeItem details in the global items API. We can attach it in the feature.
                            onClick: item.storeItemId
                              ? () => removeItem(item.id, item.storeItemId)
                              : () => addItem(item.id),
                          }}
                        />
                      ))}
                      {loading && <IonSpinner color="primary" />}
                    </div>
                  ) : (
                    <NoItem message="No item found!" />
                  )}
                </div>
              </div>

              <Snackbar
                show={snackbar.show}
                type={snackbar.type}
                title={snackbar.title}
                buttonTitle={snackbar.buttonTitle}
                onButtonClick={snackbar.onButtonClick}
                onDismiss={snackbar.onDismiss}
              />

              <IonFooter>
                <IonButton disabled={incompleteCount <= 0} className="btn-primary" expand="block" onClick={addDetails}>
                  {incompleteCount && incompleteCount > 0
                    ? `Add Details for (${incompleteCount}) Items`
                    : 'Choose Items to add'}
                </IonButton>
              </IonFooter>
            </IonContent>
          </IonCol>
          {isDesktop && (
            <IonCol sizeXl="4">
              {showSearch && (
                <SearchItem location={location} history={history} match={match} setShowSearch={setShowSearch} />
              )}
              {showDetails && (
                <ItemDetails
                  location={{state: {type: ITEM_TYPE.COMMON}}}
                  history={history}
                  match={match}
                  setShowDetails={setShowDetails}
                  onNewMenuClick={() => {}}
                />
              )}
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
    </IonPage>
  );
};

export default CommonItems;
