import {useState, useEffect} from 'react';
import {IonPage, IonContent, IonSpinner, IonGrid, IonRow, IonCol} from '@ionic/react';
import './styles.scss';
import {RouteComponentProps} from 'react-router';
import dayjs from 'dayjs';
import utc from 'dayjs/plugin/utc';
import PullToRefresh from 'react-simple-pull-to-refresh';
import CommonHeader from 'src/components/CommonHeader';
import Filter from './components/Filter';
import OrderHistoryItems from './components/OrderHistoryItems';
import OrderHistoryMetrics from './components/OrderHistoryMetrics';
import OrderDetails from 'src/pages/Orders/Details';
import {useAppDispatch, useAppSelector} from 'src/hooks';
import {activeOrderIndex, getOrdersHistory, setOrdersHistory} from 'src/store/orderSlice';
import {ROUTE_LOCATION} from 'src/enums/route_location';
import {HISTORY_FILTERS} from 'src/utils/constants';
import {showAlert} from 'src/store/alertSlice';
import orderService from 'src/services/order';
import {IHistoryMetrics} from 'src/interfaces/item';

dayjs.extend(utc);

const DATE_FORMAT = 'YYYY-MM-DD[T]HH:mm:ss';

interface IProps extends RouteComponentProps {}

const OrdersHistory = ({history, match}: IProps) => {
  const dispatch = useAppDispatch();
  const {isDesktop} = useAppSelector((state) => state.platform);
  const {ordersHistory} = useAppSelector((state) => state.order);
  const store = useAppSelector((state) => state.store);
  const menuIsOpen = useAppSelector((state) => state.auth.menuIsOpen);

  const [initialStartDate, setInitialStartDate] = useState(dayjs().startOf('M').utc().format(DATE_FORMAT));
  const [initialEndDate, setInitialEndDate] = useState(dayjs().endOf('M').utc().format(DATE_FORMAT));
  const [historyMetrics, setHistoryMetrics] = useState<IHistoryMetrics>({
    ordersCount: 0,
    revenueEarned: 0,
    revenueLost: 0,
  });
  const [offset, setOffset] = useState(0);
  const [loadMore, setLoadMore] = useState(true);
  const [loading, setLoading] = useState(false);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [id, setId] = useState('');
  const initialOrderTypes = HISTORY_FILTERS.type.map((item) => item.value);
  const [orderTypes, setOrdersTypes] = useState<Array<string>>([...initialOrderTypes]);

  const onDateChange = (startDate: string, endDate: string) => {
    setInitialStartDate(startDate);
    setInitialEndDate(endDate);
    getHistory(startDate, endDate, 0, orderTypes);
    getHistoryMetrics(startDate, endDate, orderTypes);
  };

  const onChangeOrderType = (value: any) => {
    setOrdersTypes(value);
    if (value.length) {
      getHistory(initialStartDate, initialEndDate, 0, value);
      getHistoryMetrics(initialStartDate, initialEndDate, value);
    }
  };

  const onClickOrder = (id: string) => {
    if (isDesktop) {
      setId(id);
      setShowOrderDetails(true);
      dispatch(activeOrderIndex(id));
    } else {
      history.push({pathname: '/orders/details', state: {id, from: ROUTE_LOCATION.ORDER_HISTORY}});
    }
  };

  const fetchNextOrders = async (e: any) => {
    if (ordersHistory.length >= 20) {
      const {scrollHeight, scrollTop, clientHeight} = e.target;
      if (scrollHeight - scrollTop <= clientHeight + 10 && loadMore && !loading) {
        setLoading(true);
        const updatedOffset = offset + 20;
        const data = {
          from_date: initialStartDate,
          to_date: initialEndDate,
          offset: updatedOffset,
          order_type: orderTypes,
          store_id: store?.id,
        };
        await dispatch(getOrdersHistory(data))
          .unwrap()
          .then((response: any) => {
            if (response?.length < 20) setLoadMore(false);
            else setOffset(updatedOffset);
            setLoading(false);
          })
          .catch((error) => setLoading(false));
      }
    }
  };

  const getHistory = async (startDate: string, endDate: string, offset: number, orderTypes: string[]) => {
    const data = {
      from_date: startDate,
      to_date: endDate,
      offset: offset,
      order_type: orderTypes,
      store_id: store?.id,
    };
    if (orderTypes.length > 0) {
      await dispatch(getOrdersHistory(data))
        .unwrap()
        .then((orders) => {
          if (orders) dispatch(setOrdersHistory(orders));
        });
    }
  };

  const getHistoryMetrics = async (startDate: string, endDate: string, orderTypes: string[]) => {
    const data = {
      from_date: startDate,
      to_date: endDate,
      order_type: orderTypes,
      store_id: store?.id,
    };
    if (orderTypes.length > 0) {
      await orderService
        .getOrdersHistoryMetrics(data)
        .then((res) => {
          setHistoryMetrics({
            ordersCount: res.orders_count ?? 0,
            revenueEarned: res.revenue_earned ?? 0,
            revenueLost: res.revenue_lost ?? 0,
          });
        })
        .catch((error: any) => {
          dispatch(
            showAlert({
              heading: 'Unable to fetch orders history',
              message: error?.message || 'Some error occurred while fetching data. Please, try again later',
            }),
          );
        });
    }
  };

  const onRefresh = async () => {
    setLoadMore(true);
    setOffset(0);
    dispatch(setOrdersHistory([]));
    dispatch(activeOrderIndex(null));
    await getHistory(initialStartDate, initialEndDate, 0, orderTypes);
    await getHistoryMetrics(initialStartDate, initialEndDate, orderTypes);
  };

  useEffect(() => {
    getHistory(initialStartDate, initialEndDate, offset, orderTypes);
    getHistoryMetrics(initialStartDate, initialEndDate, orderTypes);
  }, [store?.id]);

  useEffect(() => {
    setLoadMore(true);
    setOffset(0);
    dispatch(setOrdersHistory([]));
    setShowOrderDetails(false);
    dispatch(activeOrderIndex(null));
  }, [initialStartDate, initialEndDate, store?.id, orderTypes]);

  return (
    <IonPage id="orders-history-page" className="snow-background">
      <IonGrid>
        <IonRow className="page-content">
          <IonCol sizeXl="8">
            <IonContent fullscreen scrollY={false} className="page-container">
              <CommonHeader className="title-left" title="Order History" menuIsOpen={menuIsOpen} />
              <PullToRefresh
                onRefresh={onRefresh}
                pullingContent=""
                refreshingContent={<IonSpinner className="pull-to-refresh" />}>
                <div className="body p-0" onScroll={fetchNextOrders}>
                  <div className="page-header">
                    <Filter
                      orderTypes={orderTypes}
                      onChangeOrderTypes={onChangeOrderType}
                      onDateChange={onDateChange}
                    />
                  </div>

                  <OrderHistoryMetrics
                    historyMetrics={historyMetrics}
                    completed={true}
                    cancelled={true}
                    denied={true}
                  />

                  <OrderHistoryItems onClickOrder={onClickOrder} />
                  {loading && <IonSpinner color="primary" />}
                </div>
              </PullToRefresh>
            </IonContent>
          </IonCol>
          {isDesktop && (
            <IonCol sizeXl="4">
              {showOrderDetails && id && (
                <OrderDetails
                  location={{state: {id: id, from: ROUTE_LOCATION.ORDER_HISTORY}}}
                  history={history}
                  match={match}
                  setShowDetails={setShowOrderDetails}
                />
              )}
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
    </IonPage>
  );
};

export default OrdersHistory;
