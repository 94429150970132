import {IonText} from '@ionic/react';
import {ReactNode} from 'react';
import './styles.scss';

interface IProps {
  value: string | ReactNode;
  title: string;
}

interface IOrderDetailsListItemProps {
  className?: string;
  leftTitle: string;
  rightTitle: string;
  leftValue: string | ReactNode;
  rightValue: string | ReactNode;
}

const ListInnerItem = ({title, value}: IProps) => {
  return (
    <div id="list-inner-item">
      <p>{title}</p>
      <div>{value}</div>
    </div>
  );
};

const OrderDetailsListItem = ({
  className,
  leftTitle,
  leftValue,
  rightTitle,
  rightValue,
}: IOrderDetailsListItemProps) => {
  return (
    <div id="order-detail-list-item" className={className}>
      <ListInnerItem title={leftTitle} value={leftValue} />
      <ListInnerItem title={rightTitle} value={rightValue} />
    </div>
  );
};

export default OrderDetailsListItem;
