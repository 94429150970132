import {useEffect} from 'react';
import {IonButton, IonIcon, CreateAnimation} from '@ionic/react';
import {checkmarkOutline} from 'ionicons/icons';
import './styles.scss';

interface IProps {
  show: boolean;
  type: 'success' | 'error';
  title: string;
  buttonTitle?: string;
  onButtonClick: () => void;
  onDismiss: () => void;
}

const Snackbar = ({show, type, title, buttonTitle, onButtonClick, onDismiss}: IProps) => {
  useEffect(() => {
    let timeoutID: NodeJS.Timeout;

    if (show) {
      timeoutID = setTimeout(onDismiss, 2000);
    }

    return () => {
      clearTimeout(timeoutID);
    };
  }, [show]);

  return show ? (
    <CreateAnimation play duration={250} fromTo={{property: 'opacity', fromValue: '0', toValue: '1'}}>
      <div id="snackbar-container">
        <div className={`snackbar ${type}`}>
          <div className="details-container">
            {type === 'success' && <IonIcon icon={checkmarkOutline} />}
            <p className="desc">{title}</p>
          </div>
          <IonButton fill="clear" onClick={onButtonClick}>
            {buttonTitle || <IonIcon className="icon-close" />}
          </IonButton>
        </div>
      </div>
    </CreateAnimation>
  ) : null;
};

export default Snackbar;
