import {useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router';
import {
  IonPage,
  IonContent,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonInput,
  IonSpinner,
  IonText,
  IonBadge,
} from '@ionic/react';
import {searchOutline} from 'ionicons/icons';
import './styles.scss';
import CommonHeader from 'src/components/CommonHeader';
import {useAppDispatch, useAppSelector} from 'src/hooks';
import {setStores, setStoreCount} from 'src/store/authSlice';
import {clearInventory} from 'src/store/inventorySlice';
import {showLoading, hideLoading} from 'src/store/loadingSlice';
import {clearItems} from 'src/store/itemSlice';
import {clearOrderSlice} from 'src/store/orderSlice';
import {
  getStoreEmployeesCount,
  getStoreStatus,
  setCloseStore,
  setDeactivateStore,
  setStore,
} from 'src/store/storeSlice';
import storeService from 'src/services/store';
import SocketHelper from 'src/utils/socket';
import {setItem} from 'src/utils/storage';
import {STORAGE} from 'src/enums/storage';

interface IProps extends RouteComponentProps {}

const StoresList = ({history}: IProps) => {
  const dispatch = useAppDispatch();

  const [searchTerm, setSearchTerm] = useState('');
  const [loading, setLoading] = useState(false);

  const {user, userStores, userStoresCount, menuIsOpen} = useAppSelector((state) => state.auth);
  const selectedStore = useAppSelector((state) => state.store);
  const {isDesktop, isTablet} = useAppSelector((state) => state.platform);

  const activateStore = async (id: string | undefined) => {
    if (id) {
      // Remove listeners for previous store.
      SocketHelper.disconnectSocket();
      // Add listeners for new store.
      SocketHelper.connectSocket(id, dispatch);

      const store = userStores.find((store: any) => store?.id === id);
      dispatch(clearOrderSlice());
      dispatch(clearInventory());
      dispatch(clearItems());
      dispatch(setStore(store));
      setItem(STORAGE.SELECTED_STORE, id);
      dispatch(setDeactivateStore(store.isDeactivated));
      dispatch(setCloseStore(store.isClosed));
      await dispatch(getStoreEmployeesCount());
      await dispatch(getStoreStatus());
    }
  };

  const fetchStores = (offset = 0) => {
    if (offset === 0) dispatch(showLoading());
    else setLoading(true);

    const params = {
      search_string: searchTerm.trimStart(),
      offset,
      user_id: user?.id,
    };
    storeService
      .getAllUserStores(params)
      .then((resp) => {
        dispatch(setStoreCount(resp.count));

        if (offset > 0) {
          dispatch(setStores([...userStores, ...resp.stores]));
        } else {
          dispatch(setStores(resp.stores));
        }
      })
      .finally(() => {
        if (offset === 0) dispatch(hideLoading());
        else setLoading(false);
      });
  };

  const fetchNextItems = (e: any) => {
    if (userStores.length >= 10) {
      const {scrollHeight, scrollTop, clientHeight} = e.target;
      const loadMore = scrollHeight - scrollTop <= clientHeight + 10;

      if (loadMore && !loading && userStores.length < userStoresCount) {
        fetchStores(userStores.length);
      }
    }
  };

  const onSearch = (e: any) => {
    setSearchTerm(e.detail.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => fetchStores(), 750);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  return (
    <IonPage id="stores-list-page">
      <IonGrid>
        <IonRow>
          <IonCol sizeXl="8" className={`${isTablet && !menuIsOpen ? 'menu-close' : ''}`}>
            <IonContent fullscreen scrollY={false} className="page-container">
              <CommonHeader className="title-left" title="Stores" menuIsOpen={menuIsOpen} noBorder={true} />

              <div className="search-container">
                <div className="search-bar">
                  <IonIcon icon={searchOutline} />
                  <IonInput value={searchTerm} clearInput={true} placeholder="Search" onIonChange={onSearch} />
                </div>
                {userStoresCount > 5 && <p className="store-count">{userStoresCount} Stores</p>}
              </div>

              <div className="body" onScroll={fetchNextItems}>
                {userStores.map((store: any) => (
                  <div
                    key={store.id}
                    className={`store-card ${store.id === selectedStore.id ? 'selected' : ''}`}
                    onClick={() => {
                      if (store.id !== selectedStore.id) activateStore(store?.id);
                    }}>
                    <div className="details-container">
                      <IonText>{store.name}</IonText>
                      <IonText className="address">
                        {store?.address.line_1}, {store?.address.city}, {store?.address.state}
                      </IonText>
                    </div>
                    {store.id === selectedStore.id && <IonBadge>Selected</IonBadge>}
                  </div>
                ))}
                {loading && <IonSpinner color="primary" />}
              </div>
            </IonContent>
          </IonCol>
          {isDesktop && <IonCol sizeXl="4"></IonCol>}
        </IonRow>
      </IonGrid>
    </IonPage>
  );
};

export default StoresList;
