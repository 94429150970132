import {useState, useEffect} from 'react';
import '../styles.scss';
import {useAppSelector} from 'src/hooks';

interface Props {
  fetchToken: (dashboardId: string, key: string) => Promise<string>;
  dispatchEvent: (name: string, value: any) => void;
}

const ExploInventory = ({fetchToken, dispatchEvent}: Props) => {
  const [token, setToken] = useState('');

  const {company} = useAppSelector((state) => state.store);
  const {user} = useAppSelector((state) => state.auth);

  useEffect(() => {
    (async () => {
      const token = await fetchToken('EbY2bgaYyD', 'menuPerformance');
      setToken(token);
    })();

    const listener = (e: any) => {
      e.preventDefault();

      if (e.detail.varName === 'kpi_toggle') {
        // Updating tab value.
        dispatchEvent('kpi_toggle', e.detail.newValue);
        // Showing appropriate table based on the selected tab.
        dispatchEvent('item_sales_table.hide', e.detail.newValue !== 'Items Sold');
        dispatchEvent(
          'item_availability_table.hide',
          e.detail.newValue !== 'Items for Sale' && e.detail.newValue !== 'Items on Hold',
        );
        dispatchEvent('percent_categories_sold_table.hide', e.detail.newValue !== '% Categories Sold');
        dispatchEvent('percent_category_optimized_table.hide', e.detail.newValue !== '% Category Optimized');
      }
    };

    // Add the event listener to the window
    window.addEventListener('sendVariableUpdatedEvent', listener);
    window.addEventListener('unselect_category', listener);

    // Cleanup the listener on component unmount
    return () => {
      window.removeEventListener('sendVariableUpdatedEvent', listener);
      window.removeEventListener('unselect_category', listener);
    };
  }, []);

  return token ? (
    <explo-dashboard
      dash-jwt={token}
      updateUrlParams={true}
      isProduction={process.env.REACT_APP_EXPLO_ENVIRONMENT === 'production'}
      environment={process.env.REACT_APP_EXPLO_ENVIRONMENT}
      refresh-minutes={10}
      variables={JSON.stringify({
        user_id: user?.id,
        company_id: [company?.id],
        'item_sales_table.hide': false,
        'item_availability_table.hide': true,
        'percent_categories_sold_table.hide': true,
        'percent_category_optimized_table.hide': true,
        kpi_toggle: 'Items Sold',
      })}
    />
  ) : null;
};

export default ExploInventory;
