/* eslint-disable react-hooks/exhaustive-deps */
import {useState} from 'react';
import {IonPage, IonContent, IonButton, IonBackButton, IonFooter, IonIcon} from '@ionic/react';
import './styles.scss';
import {closeOutline} from 'ionicons/icons';
import {RouteComponentProps} from 'react-router';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import isSameOrBefore from 'dayjs/plugin/isSameOrBefore';
import Header from 'src/components/Header';
import StoreTimings from './components/StoreTimings';
import storeService from 'src/services/store';
import {useAppDispatch, useAppSelector, useEffectOnce} from 'src/hooks';
import {showLoading, hideLoading} from 'src/store/loadingSlice';
import {showAlert} from 'src/store/alertSlice';
import {DEFAULT_HOUR, WEEK_DAYS} from 'src/utils/constants';
import {ITiming} from 'src/interfaces/store';
import {checkPermission, storeHoursErrorListToString} from 'src/utils/helpers';
import arrowBack from 'src/assets/images/arrow-back-black.svg';
import * as PERMISSIONS from 'src/utils/permissions';

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);
dayjs.extend(isSameOrBefore);

const isBetween = (start: dayjs.Dayjs, end: dayjs.Dayjs, time: dayjs.Dayjs) =>
  time.isSameOrAfter(start) && time.isSameOrBefore(end);

interface IProps extends RouteComponentProps {
  setShowStoreHours?: (show: boolean) => void;
}

const StoreHours = ({setShowStoreHours}: IProps) => {
  const dispatch = useAppDispatch();

  const {id: storeId} = useAppSelector((state) => state.store);
  const {user, menuIsOpen} = useAppSelector((state) => state.auth);
  const {isDesktop, isTablet} = useAppSelector((state) => state.platform);

  const [validate, setValidate] = useState(false);
  const [timings, setTimings] = useState<ITiming[][]>(
    Array(7)
      .fill({})
      .map((_, idx) => [{...DEFAULT_HOUR, dayOfWeek: idx}]),
  );

  const canEditStoreHours = checkPermission(user?.role.permissions, PERMISSIONS.EDIT_STORE_HOURS);

  const validateHours = (hours: ITiming[][]) => {
    let isValid = true;
    const updatedHours = hours.map((day) => day.map((hour) => ({...hour, error: ''})));
    for (let i = 0; i < updatedHours.length; i++) {
      const day = updatedHours[i];

      for (let j = 0; j < day.length; j++) {
        const hour = day[j];

        if (!hour.isClosed) {
          const openHour = dayjs(hour.openTime, 'HH:mm');
          const closeHour = dayjs(hour.closeTime, 'HH:mm');

          if (!hour.openTime || !hour.closeTime) {
            isValid = false;
            hour.error = 'Please enter open and close time';
          } else if (openHour.isSameOrAfter(closeHour) || !openHour.isValid() || !closeHour.isValid()) {
            isValid = false;
            hour.error = 'Invalid time range';
          } else {
            // Check if any other hours overlap with the current hour.
            for (let k = 0; k < j; k++) {
              const prevOpenHour = dayjs(day[k].openTime, 'HH:mm');
              const prevCloseHour = dayjs(day[k].closeTime, 'HH:mm');

              if (
                isBetween(prevOpenHour, prevCloseHour, openHour) ||
                isBetween(prevOpenHour, prevCloseHour, closeHour) ||
                isBetween(openHour, closeHour, prevOpenHour) ||
                isBetween(openHour, closeHour, prevCloseHour)
              ) {
                isValid = false;
                hour.error = 'Time ranges must NOT overlap';
              }
            }
          }
        }
      }
    }
    setTimings(updatedHours);
    return isValid;
  };

  const onRemoveHour = (id: string) => {
    storeService
      .deleteStoreHour(storeId, id)
      .then(() =>
        dispatch(
          showAlert({
            heading: 'Deleted Successfully',
            message: 'Store hour deleted successfully',
          }),
        ),
      )
      .catch((error) => dispatch(showAlert({heading: 'Unable to Delete', message: error})));
  };

  const fetchStoreHours = (showLoader: boolean) => {
    if (showLoader) dispatch(showLoading());
    storeService
      .getStoreHours(storeId)
      .then((hours) => {
        if (showLoader) dispatch(hideLoading());
        const dayHours = [];
        for (let i = 0; i < 7; i++) {
          const updatedHours = hours.filter((hour) => hour.dayOfWeek === i);
          if (updatedHours.length > 0) {
            dayHours[i] = updatedHours;
          } else {
            dayHours[i] = [{...DEFAULT_HOUR, dayOfWeek: i}];
          }
        }
        setTimings(dayHours);
      })
      .catch((error) => {
        if (showLoader) dispatch(hideLoading());
        dispatch(
          showAlert({
            heading: 'Unable to fetch data',
            message: error?.message || 'We are unable to fetch hours data at this moment. Please, try again later',
          }),
        );
      });
  };

  const saveChanges = () => {
    setValidate(true);
    if (validateHours(timings)) {
      dispatch(showLoading());
      // Update store hours.
      storeService
        .updateStoreHours(storeId, timings.flat())
        .then((response) => {
          dispatch(hideLoading());
          dispatch(showAlert({heading: 'Successfully updated', message: 'Store hours are successfully updated.'}));
          fetchStoreHours(false);
        })
        .catch((error) => {
          dispatch(hideLoading());
          dispatch(
            showAlert({
              heading: 'Unable to update hours',
              message: error?.message || storeHoursErrorListToString(error),
            }),
          );
        });
    }
  };

  const onClose = () => {
    if (setShowStoreHours) setShowStoreHours(false);
  };

  useEffectOnce(() => {
    fetchStoreHours(true);
  });

  return (
    <IonPage id="manage-store-hours-page" className={`${isTablet && !menuIsOpen ? 'menu-close' : ''}`}>
      <IonContent fullscreen scrollY={false} className={`${isTablet ? 'page-container' : ''}`}>
        <Header
          noBackground
          title="Store Hours"
          leftButton={!isDesktop && <IonBackButton defaultHref="/store/manage/employees" text="" icon={arrowBack} />}
          rightButton={
            isDesktop && (
              <IonButton onClick={onClose}>
                <IonIcon src={closeOutline} />
              </IonButton>
            )
          }
        />

        <div className={`body ${canEditStoreHours ? 'with-footer' : 'without-footer'}`}>
          {canEditStoreHours ? (
            <StoreTimings
              multiHours
              validate={validate}
              timings={timings}
              setTimings={setTimings}
              onRemove={onRemoveHour}
              validateInputs={validateHours}
            />
          ) : (
            <div className="menu-hours">
              {WEEK_DAYS.map((day, i) => (
                <div key={i} className="day-item">
                  <p>{day.title}</p>
                  <div>
                    {timings[day.day_of_week].map((timing) => (
                      <p>
                        {dayjs(timing.openTime, 'HH:mm').format('hh:mm A')}
                        <span>to</span>
                        {dayjs(timing.closeTime, 'HH:mm').format('hh:mm A')}
                      </p>
                    ))}
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>

        {canEditStoreHours && (
          <IonFooter>
            <IonButton className="btn-dark" expand="block" onClick={saveChanges}>
              Save Changes
            </IonButton>
          </IonFooter>
        )}
      </IonContent>
    </IonPage>
  );
};

export default StoreHours;
