import {useState, useEffect, Fragment} from 'react';
import {IonModal, IonIcon, IonFooter, IonButton} from '@ionic/react';
import {refreshOutline, chatbubbleOutline} from 'ionicons/icons';
import './styles.scss';
import {IOrderUpdatePayloadItem, ISubstituteListItem} from 'src/interfaces/order';
import {PATCH_CART_TYPE} from 'src/enums/order';

interface IProps {
  isOpen: boolean;
  order: any;
  payload: Array<IOrderUpdatePayloadItem>;
  substitutionList: Array<ISubstituteListItem>;
  updateOrder: (data: any) => void;
  closeModal: () => void;
}

const EditOrderErrorModal = ({isOpen, order, payload, substitutionList, updateOrder, closeModal}: IProps) => {
  const [retried, setRetried] = useState(false);

  const getMultiplePayloadItemDetails = () =>
    payload.map((p) => {
      const orderItem = order?.orderItems?.find((oi: any) => oi.id === p.order_item_id);
      const item = `${orderItem?.itemName} ( x${orderItem?.quantity} )`;

      switch (p.action_type) {
        case PATCH_CART_TYPE.REMOVE_ITEM:
          return `Remove: ${item}`;
        case PATCH_CART_TYPE.REDUCE_QUANTITY:
          return `Quantity edit: ${item} to ( x${p.quantity} )`;
        case PATCH_CART_TYPE.REPLACE_ITEM:
          const substitutedItem = substitutionList.find((s) => s.orderItemId === p.order_item_id);
          return `Substitution: ${item} to ${substitutedItem?.substituteItem?.name} ( x${substitutedItem?.substituteItemQuantity} )`;
        default:
          return '';
      }
    });

  const getSinglePayloadItemDetails = () =>
    payload.map((p) => {
      const orderItem = order?.orderItems?.find((oi: any) => oi.id === p.order_item_id);
      const item = `${orderItem?.itemName} (x${orderItem?.quantity})`;

      switch (p.action_type) {
        case PATCH_CART_TYPE.REMOVE_ITEM:
          return {item, desc: 'Remove item'};
        case PATCH_CART_TYPE.REDUCE_QUANTITY:
          return {item, desc: `Update quantity to ( x${p.quantity} )`};
        case PATCH_CART_TYPE.REPLACE_ITEM:
          const substitutedItem = substitutionList.find((s) => s.orderItemId === p.order_item_id);
          return {
            item,
            desc: `Substitute for ${substitutedItem?.substituteItem?.name} ( x${substitutedItem?.substituteItemQuantity} )`,
          };
        default:
          return {item: '', desc: ''};
      }
    });

  const onTryAgain = () => {
    if (!retried) {
      setRetried(true);
      updateOrder(payload);
    } else {
      window.Intercom('show');
    }
    closeModal();
  };

  useEffect(() => {
    if (payload.length === 0) {
      // Assuming that order updated successfully.
      setRetried(false);
    }
  }, [payload.length]);

  return (
    <IonModal className="app-modal edit-order-error-modal" isOpen={isOpen} showBackdrop backdropDismiss={false}>
      <div className="modal-content-container">
        <div className="modal-content">
          <div className="details">
            <p className="title">
              Edit unsuccessful <IonIcon className="icon-stop-block" slot="start" />
            </p>

            {payload.length === 1 &&
              getSinglePayloadItemDetails()?.map((item, idx) => (
                <Fragment key={idx}>
                  <p className="item-name">
                    <strong>Item: </strong> {item?.item}
                  </p>
                  <p>
                    <strong>Attempted edit: </strong> {item?.desc}
                  </p>
                </Fragment>
              ))}

            {payload.length > 1 && (
              <ul className="ion-padding-start">
                {getMultiplePayloadItemDetails()?.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            )}

            <p className="msg">Please try again, choose a different item or contact our support team for assistance.</p>
          </div>

          <IonFooter>
            <IonButton className="btn-outline" onClick={onTryAgain}>
              <IonIcon icon={retried ? chatbubbleOutline : refreshOutline} slot="start" size="small" />
              {retried ? 'Contact support' : 'Try again'}
            </IonButton>

            <IonButton className="btn-dark" onClick={closeModal}>
              Ok
            </IonButton>
          </IonFooter>
        </div>
      </div>
    </IonModal>
  );
};

export default EditOrderErrorModal;
