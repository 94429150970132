import './styles.scss';
import {IonButton, IonContent, IonIcon, IonPage} from '@ionic/react';
import {closeOutline} from 'ionicons/icons';
import Header from 'src/components/Header';
import ListItem from 'src/components/ListItem';
import {useState} from 'react';
import {setNewMenuId} from 'src/store/inventorySlice';
import {useAppDispatch} from 'src/hooks';

interface IProps {
  setShowEditModal: (e: boolean) => void;
  setShowAvailabilityModal: (e: boolean) => void;
  setShowPriceModal: (e: boolean) => void;
  setShowCategoryModal?: (e: boolean) => void;
  deleteInventory: (active: boolean) => void;
  setShowMenuModal: (e: boolean) => void;
  isInStock: boolean;
  canDelete: boolean;
}

const EditOptionModal = (props: IProps) => {
  const dispatch = useAppDispatch();
  const [showDeleteAction, setShowDeleteAction] = useState(false);

  return (
    <IonPage id="edit-option-mobile">
      <IonContent fullscreen scrollY={false}>
        <Header
          border
          noBackground
          title="Edit"
          rightButton={
            <IonIcon
              onClick={() => {
                props.setShowEditModal(false);
              }}
              icon={closeOutline}></IonIcon>
          }
        />

        <div className="body">
          <ListItem
            fullWidth
            icon=""
            title="Availability"
            description=""
            onClick={() => {
              props.setShowAvailabilityModal(true);
            }}
          />
          <ListItem
            fullWidth
            icon=""
            title="Category"
            description=""
            onClick={() => {
              props?.setShowCategoryModal?.(true);
            }}
          />
          <ListItem
            fullWidth
            icon=""
            title="Price"
            description=""
            onClick={() => {
              props.setShowPriceModal(true);
            }}
          />
          <ListItem
            fullWidth
            icon=""
            title="Menu"
            description=""
            onClick={() => {
              dispatch(setNewMenuId(''));
              props.setShowMenuModal(true);
            }}
          />
        </div>
        {props.canDelete ? (
          <div className="action-div">
            {!showDeleteAction ? (
              <>
                {' '}
                <IonButton
                  className="delete-btn"
                  mode="ios"
                  fill="outline"
                  color="danger"
                  expand="block"
                  onClick={() => {
                    setShowDeleteAction(true);
                  }}>
                  <IonIcon className="icon-trash"></IonIcon>
                  Delete items
                </IonButton>
              </>
            ) : (
              <>
                <div className="label">Are you sure you want to delete these items?</div>
                <div className="action">
                  <IonButton
                    className="btn-outline"
                    onClick={() => {
                      setShowDeleteAction(false);
                    }}>
                    No
                  </IonButton>
                  <IonButton
                    className="btn-danger"
                    onClick={() => {
                      props.deleteInventory(props.isInStock);
                    }}>
                    Yes
                  </IonButton>
                </div>
              </>
            )}
          </div>
        ) : (
          <></>
        )}
      </IonContent>
    </IonPage>
  );
};

export default EditOptionModal;
