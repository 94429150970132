export enum ITEM_STATUS {
  ALL = 'All',
  IN_STOCK = 'In Stock',
  ARCHIVED = 'Archived',
  INCOMPLETE = 'Incomplete',
  RESTRICTED = 'Restricted',
}

export enum INVENTORY_STATUS {
  IN_STOCK = 1,
  ARCHIVED = 2,
}

export enum MODIFIER_ITEM_STATUS {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export enum MODIFIER_GROUP_CONSTRAINT {
  OPTIONAL = 'Optional',
  REQUIRED = 'Required',
}
