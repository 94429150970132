import {IonRouterLink, IonImg} from '@ionic/react';
import appPartners from 'src/assets/images/app-partners.svg';
import {PARTNERS_URL} from 'src/utils/constants';
import './styles.scss';

const AppPartners = () => {
  return (
    <div id="partners">
      <IonImg src={appPartners} />
      <h4 className="heading">
        Your Key to <span className="text-primary">Increased</span>
        <br /> Online Sales
      </h4>
      <p className="description">
        Tailor Made for Convenience. Seamless Integrations. Advanced Reporting. World Class Customer Service.
      </p>
      <p className="description">
        No account?{' '}
        <IonRouterLink className="link" href={PARTNERS_URL} target="_blank">
          Partner with Lula Convenience
        </IonRouterLink>
      </p>
    </div>
  );
};

export default AppPartners;
