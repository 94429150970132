import {IonItem, IonLabel, IonNote, IonIcon, IonImg} from '@ionic/react';
import './styles.scss';
import {chevronForwardOutline} from 'ionicons/icons';

interface IProps {
  icon?: any;
  iconImg?: any;
  title: string;
  description?: string;
  incomplete?: boolean;
  verified?: boolean;
  status?: boolean;
  activityStatus?: string;
  fullWidth?: boolean;
  selected?: boolean;
  onClick?: () => void;
}

const ListItem = ({
  icon,
  iconImg,
  title,
  description,
  incomplete,
  verified,
  status,
  activityStatus,
  fullWidth,
  selected,
  onClick,
}: IProps) => (
  <IonItem
    id="list-item"
    className={`${selected ? 'selected-item' : ''} ${fullWidth ? 'full-width' : ''}`}
    onClick={onClick}>
    {iconImg ? <IonImg slot="start" src={iconImg} /> : <IonIcon slot="start" className={icon} />}
    <div className="list-item-content">
      <IonLabel>
        <div id="label-item">
          <span className="settings-title">{title}</span>
          {status !== undefined && (
            <div className="status" style={{background: status ? '#ECF6ED' : '#EEEEEE'}}>
              <div className="status-text" style={{color: status ? '#43A047' : '#828282'}}>
                {status ? 'CONNECTED' : 'NOT CONNECTED'}
              </div>
            </div>
          )}
          {incomplete && <IonNote className="incomplete">Incomplete</IonNote>}
          {verified && <IonNote className="verified">Verified</IonNote>}
          {!verified && title === 'E-Mail' && <IonNote className="incomplete">Not verified</IonNote>}
        </div>
      </IonLabel>
      {description && <IonNote>{description}</IonNote>}
    </div>
    {activityStatus && <span className={activityStatus}>{activityStatus}</span>}
    <IonIcon icon={chevronForwardOutline} className="chevron" />
  </IonItem>
);

export default ListItem;
