import '../styles.scss';

interface Payout {
  user: any;
  token: string;
  companyId: string | undefined;
}

const ExploPayouts = ({user, token, companyId}: Payout) => (
  <explo-dashboard
    dash-customer-token={`BKxg2bBYLR:${token}`}
    updateUrlParams={true}
    isProduction={process.env.REACT_APP_EXPLO_ENVIRONMENT === 'production'}
    environment={process.env.REACT_APP_EXPLO_ENVIRONMENT}
    refresh-minutes={10}
    variables={JSON.stringify({user_id: user?.id, company_id: [companyId]})}
  />
);

export default ExploPayouts;
