import {IonButton, IonIcon, IonFooter} from '@ionic/react';
import {alertCircleOutline, swapVerticalOutline} from 'ionicons/icons';
import './styles.scss';
import ReceiptItem from 'src/pages/Orders/components/ReceiptItem';
import SubstitutedReceiptItem from 'src/pages/Orders/components/SubstitutedReceiptItem';
import {useAppSelector} from 'src/hooks';
import {ORDER_STATUS, PATCH_CART_TYPE} from 'src/enums/order';
import {checkPermission} from 'src/utils/helpers';
import * as PERMISSIONS from 'src/utils/permissions';
import {IOrderUpdatePayloadItem, ISubstituteListItem} from 'src/interfaces/order';
import ItemDetailsModal from 'src/components/modals/ItemDetail';
import {IOrderItemDetails} from 'src/interfaces/item';

interface IProps {
  order: any;
  fromOrders: boolean;
  outOfStockItems: Array<string>;
  substitutionList: Array<ISubstituteListItem>;
  payload: Array<IOrderUpdatePayloadItem>;
  dataForDetailsModal: IOrderItemDetails | null;
  onChangeOutOfStock: (itemId: string) => void;
  updateOrder: (data: any) => void;
  goNext: () => void;
  closeEditModal: () => void;
  setShowSubstitutionsList: (isOpen: boolean) => void;
  setItemToSubstitute: (item: any) => void;
  setPayload: (obj: any) => void;
  setSubstitutionList: (list: Array<ISubstituteListItem>) => void;
  undoSubstitution: (itemId: string) => void;
  setDataForDetailsModal: (e: IOrderItemDetails | null) => void;
}

const EditOrderItems = ({
  fromOrders,
  order,
  payload,
  substitutionList,
  outOfStockItems,
  dataForDetailsModal,
  setPayload,
  onChangeOutOfStock,
  updateOrder,
  goNext,
  closeEditModal,
  setShowSubstitutionsList,
  undoSubstitution,
  setItemToSubstitute,
  setSubstitutionList,
  setDataForDetailsModal,
}: IProps) => {
  const {user} = useAppSelector((state) => state.auth);

  const canDenyOrder = checkPermission(user?.role.permissions, PERMISSIONS.DENY_ORDER);

  const onChangeQuantity = (obj: IOrderUpdatePayloadItem) => {
    const payloadItem = payload.find((p: any) => p.order_item_id === obj.order_item_id);
    if (!payloadItem) {
      setPayload([...payload, obj]);
    } else {
      const orderItem = order?.orderItems.find((item: any) => item.id === obj.order_item_id);
      // If quantity is same then remove item from payload.
      if (obj.quantity === orderItem?.quantity) {
        setPayload(payload.filter((p) => p.order_item_id !== obj.order_item_id));
      } else {
        setPayload(payload.map((p) => (p.order_item_id === obj.order_item_id ? obj : p)));
      }
    }
  };

  const onChangeSubstituteQuantity = (itemId: string, quantity: number) => {
    // Update the substitution list.
    setSubstitutionList(
      substitutionList.map((item) =>
        item.substituteItem.id === itemId ? {...item, substituteItemQuantity: quantity} : item,
      ),
    );
    // Update the payload.
    setPayload(
      payload.map((item) =>
        item.substituted_store_item_id === itemId ? {...item, substituted_store_item_quantity: quantity} : item,
      ),
    );
  };

  const onSubmit = () => {
    updateOrder(payload);
  };

  const removedItems = payload.filter((p) => p.action_type === PATCH_CART_TYPE.REMOVE_ITEM);

  return (
    <div id="edit-order-items" className="modal-content">
      {order?.orderItems?.map((item: any) => {
        const substitutionListItem = substitutionList.find(
          (subItem: ISubstituteListItem) => subItem.orderItemId === item.id,
        );
        return substitutionListItem ? (
          <div key={item.id} className="sub-item-container">
            <SubstitutedReceiptItem
              item={item}
              outOfStockItems={outOfStockItems}
              onChangeOutOfStock={onChangeOutOfStock}
              setDataForDetailsModal={setDataForDetailsModal}
            />
            <IonIcon className="swap-vertical-icon" icon={swapVerticalOutline} />
            <SubstitutedReceiptItem
              isSubstitute
              item={substitutionListItem.substituteItem}
              orderItem={item}
              originalQuantity={substitutionListItem.substituteItemQuantity}
              outOfStockItems={outOfStockItems}
              onChangeQuantity={onChangeSubstituteQuantity}
              onUndoSubstitution={undoSubstitution}
              onChangeOutOfStock={onChangeOutOfStock}
              setDataForDetailsModal={setDataForDetailsModal}
            />
          </div>
        ) : (
          <ReceiptItem
            key={item.id}
            orderId={order.id}
            item={item}
            payload={payload}
            partner={order?.partner}
            closeEditModal={closeEditModal}
            outOfStockItems={outOfStockItems}
            onChangeQuantity={onChangeQuantity}
            onChangeOutOfStock={onChangeOutOfStock}
            setShowSubstitutionsList={setShowSubstitutionsList}
            setItemToSubstitute={setItemToSubstitute}
            setDataForDetailsModal={setDataForDetailsModal}
          />
        );
      })}
      <ItemDetailsModal
        fromEditModal
        item={dataForDetailsModal}
        isOpen={!!dataForDetailsModal?.id}
        onClose={() => closeEditModal()}
        onBack={() => setDataForDetailsModal(null)}
      />
      {fromOrders && !order?.ongoing && order?.status === ORDER_STATUS.WAITING && canDenyOrder && (
        <IonButton className="btn-outline" fill="clear" expand="block" onClick={goNext}>
          Deny order
        </IonButton>
      )}
      <IonFooter>
        <div className="caution-msg">
          <IonIcon icon={alertCircleOutline} />
          <p>All edits are final and can not be reversed</p>
        </div>
        <IonButton
          className="btn-dark"
          disabled={
            // Not allowed to out of stock or removed, if there is only one item in order.
            (outOfStockItems.length >= order?.orderItems?.length ||
              removedItems.length >= order?.orderItems?.length ||
              !payload.length) &&
            !substitutionList.length
          }
          expand="block"
          mode="ios"
          fill="clear"
          onClick={onSubmit}>
          Save edit
        </IonButton>
      </IonFooter>
    </div>
  );
};

export default EditOrderItems;
