/* eslint-disable react-hooks/exhaustive-deps */
import {useState} from 'react';
import {
  IonPage,
  IonContent,
  IonBackButton,
  IonButton,
  IonFooter,
  IonIcon,
  IonImg,
  IonInput,
  IonItem,
  IonInfiniteScroll,
  IonInfiniteScrollContent,
  InputCustomEvent,
  InfiniteScrollCustomEvent,
} from '@ionic/react';
import './styles.scss';
import {RouteComponentProps} from 'react-router';
import {addOutline, bookOutline, closeOutline, searchOutline} from 'ionicons/icons';
import Header from 'src/components/Header';
import EmployeeCard from '../components/EmployeeCard';
import {IEmployee} from 'src/interfaces/user';
import {useAppDispatch, useAppSelector, useEffectOnce} from 'src/hooks';
import {getEmployees} from 'src/store/userSlice';
import {getAllRoles} from 'src/store/authSlice';
import arrowBack from 'src/assets/images/arrow-back-black.svg';
import usersIcon from 'src/assets/images/users-icon.svg';

interface IProps extends RouteComponentProps {
  setShowEmployeeList?: (show: boolean) => void;
  setShowAddEmployee?: (show: boolean) => void;
  setShowEditEmployee?: (show: boolean) => void;
  setSelectedEmployee?: (employee: IEmployee | null) => void;
}

const EmployeesList = ({
  history,
  setShowEmployeeList,
  setShowAddEmployee,
  setShowEditEmployee,
  setSelectedEmployee,
}: IProps) => {
  const dispatch = useAppDispatch();

  const {isDesktop, isTablet} = useAppSelector((state) => state.platform);
  const {users, usersCount} = useAppSelector((state) => state.user);
  const {menuIsOpen} = useAppSelector((state) => state.auth);
  const {isRBACStore} = useAppSelector((state) => state.store);

  const [searchTerm, setSearchTerm] = useState('');

  const addUser = () => {
    if (isDesktop && setShowAddEmployee) setShowAddEmployee(true);
    else history.push('/store/manage/employees/add');
  };

  const editUser = (employee: IEmployee) => {
    if (isDesktop && setShowEditEmployee) {
      setShowEditEmployee(true);
      if (setSelectedEmployee && employee) setSelectedEmployee(employee);
    } else history.push({pathname: '/store/manage/employees/edit', state: {employee}});
  };

  const onClose = () => {
    if (setShowEmployeeList) setShowEmployeeList(false);
  };

  const onSearch = (e: InputCustomEvent) => {
    setSearchTerm(e.detail.value!);
    dispatch(getEmployees({search_string: e.detail.value!, offset: 0}));
  };

  const loadMoreEmployees = (e: InfiniteScrollCustomEvent) => {
    dispatch(getEmployees({search_string: searchTerm, offset: users.length}))
      .unwrap()
      .then(() => {
        e.target.complete();
      });
  };

  useEffectOnce(() => {
    dispatch(getEmployees({search_string: '', offset: 0}));
    if (isRBACStore) dispatch(getAllRoles());
  });

  return (
    <IonPage id="employees-list-page" className={`${isTablet && !menuIsOpen ? 'menu-close' : ''}`}>
      <Header
        border
        noBackground
        title="Employees"
        leftButton={!isDesktop && <IonBackButton defaultHref="/store/manage" text="" icon={arrowBack} />}
        rightButton={
          isDesktop && (
            <IonButton onClick={onClose}>
              <IonIcon src={closeOutline} />
            </IonButton>
          )
        }
      />
      <IonContent className={`${isTablet ? 'page-container' : ''}`}>
        {!users.length && !searchTerm ? (
          <div className="no-employee-container">
            <IonImg src={usersIcon} />
            <div className="text-container">
              <p className="msg-title">No employees at this time</p>
              <p className="msg-description">
                Currently you do not have any employees under your account. Create one now or reach out to your Super
                Admin regarding your employee management permissions.
              </p>
            </div>
            <div className="buttons-container">
              <IonButton className="btn-outline" mode="ios" onClick={addUser}>
                <IonIcon slot="start" icon={bookOutline} />
                Learn more
              </IonButton>
              <IonButton className="btn-primary" mode="ios" onClick={addUser}>
                <IonIcon slot="start" icon={addOutline} />
                Create Employee
              </IonButton>
            </div>
          </div>
        ) : (
          <div className="body">
            <div className="search-container" slot="fixed">
              <div className="search-header">
                <IonItem className="show-search">
                  <IonIcon icon={searchOutline} />
                  <IonInput placeholder="Search" debounce={750} onIonChange={onSearch} />
                </IonItem>
              </div>
              <div className="employee-count">{usersCount} employees</div>
            </div>

            {users.map((user) => (
              <EmployeeCard key={user.id} user={user} onClick={editUser} />
            ))}
            <IonInfiniteScroll threshold="5%" disabled={users.length === usersCount} onIonInfinite={loadMoreEmployees}>
              <IonInfiniteScrollContent loadingSpinner="circular" />
            </IonInfiniteScroll>
          </div>
        )}
      </IonContent>
      {users.length > 0 ? (
        <IonFooter>
          <IonButton className="btn-primary" expand="block" onClick={addUser}>
            Add new User
          </IonButton>
        </IonFooter>
      ) : null}
    </IonPage>
  );
};

export default EmployeesList;
