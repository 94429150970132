import {useEffect, useState} from 'react';
import {IonIcon, IonCheckbox, IonItem, IonLabel} from '@ionic/react';
import moment from 'moment';
import './styles.scss';
// import StoreTimings from 'src/pages/Store/Hours/components/StoreTimings/index';
import {IStorePauseItem, ITiming} from 'src/interfaces/store';
import SchedulePauseDatePicker from 'src/components/SchedulePauseDatePicker';

interface ISchedulePartnerCard {
  pauseScheduleItem: IStorePauseItem;
  onUpdateStorePauseSchedule: (pauseId: string, payload: any) => void;
  onArchiveStorePauseSchedule: (data: any) => void;
}

const SchedulePartnerCard = ({
  pauseScheduleItem,
  onUpdateStorePauseSchedule,
  onArchiveStorePauseSchedule,
}: ISchedulePartnerCard) => {
  const [showTiming, setShowTiming] = useState<boolean>(false);
  const [isValidScheduleTime, setIsValidScheduleTime] = useState<boolean>(true);
  const [selectedDate, setSelectedDate] = useState<string>(
    pauseScheduleItem.startDate || moment().format('YYYY-MM-DD'),
  );

  const [payload, setPayload] = useState({
    start_time: pauseScheduleItem.startTime,
    end_time: pauseScheduleItem.endTime,
    start_date: pauseScheduleItem.startDate,
    end_date: pauseScheduleItem.endDate,
    dsp_name: 'All',
  });

  const [scheduleTime, setScheduleTime] = useState<ITiming>({
    openTime: pauseScheduleItem.startTime,
    closeTime: pauseScheduleItem.endTime,
    storeId: pauseScheduleItem.storeId,
    id: pauseScheduleItem.id,
    dayOfWeek: 7,
    isClosed: false,
    invalid: false,
  });

  useEffect(() => {
    if (scheduleTime.openTime >= scheduleTime.closeTime) {
      setIsValidScheduleTime(false);
    } else {
      (scheduleTime.openTime !== pauseScheduleItem.startTime || scheduleTime.closeTime !== pauseScheduleItem.endTime) &&
        onUpdateStorePauseSchedule(pauseScheduleItem.id, {
          ...payload,
          start_time: scheduleTime.openTime,
          end_time: scheduleTime.closeTime,
        });
      setIsValidScheduleTime(true);
    }
  }, [scheduleTime]);

  useEffect(() => {
    selectedDate !== pauseScheduleItem.startDate &&
      onUpdateStorePauseSchedule(pauseScheduleItem.id, {...payload, start_date: selectedDate, end_date: selectedDate});
  }, [selectedDate]);

  useEffect(() => {
    if (pauseScheduleItem.startTime === '00:00' && pauseScheduleItem.endTime === '23:59') setShowTiming(false);
    else setShowTiming(true);
  }, [pauseScheduleItem.startTime, pauseScheduleItem.endTime]);

  return (
    <div id="schedule-partner-card">
      <div className="scheduler">
        <div className="date-and-check-box-container">
          <SchedulePauseDatePicker selectedDate={selectedDate} setSelectedDate={setSelectedDate} />
          <IonItem>
            <IonCheckbox
              checked={!showTiming}
              onClick={() => {
                showTiming &&
                  setScheduleTime({
                    ...scheduleTime,
                    openTime: '00:00',
                    closeTime: '23:59',
                  });
                setShowTiming(!showTiming);
              }}
            />
            <IonLabel>Paused all day</IonLabel>
          </IonItem>
        </div>
        <IonIcon
          className="icon-trash"
          onClick={() =>
            onArchiveStorePauseSchedule({
              pasueId: pauseScheduleItem.id,
            })
          }
        />
      </div>
      {/* TODO: Update the props whenever we make store pause live. */}
      {/* {showTiming && (
        <StoreTimings timing={scheduleTime} onChange={setScheduleTime} disabled={false} type="schedule-partner" />
      )} */}
      {!isValidScheduleTime && (
        <div className="inValid">
          <IonIcon className="icon-exclamation error" />
          <span className="error">Start time must be before end time</span>
        </div>
      )}
    </div>
  );
};

export default SchedulePartnerCard;
