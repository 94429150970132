import {useState} from 'react';
import './styles.scss';
import {IonButton, IonContent, IonIcon, IonPage} from '@ionic/react';
import {arrowBack, closeOutline} from 'ionicons/icons';
import dayjs from 'dayjs';
import Header from 'src/components/Header';
import NewMenu from 'src/components/NewMenu';
import {useAppDispatch} from 'src/hooks';
import {ITiming} from 'src/interfaces/store';
import {DEFAULT_HOURS} from 'src/utils/constants';
import {addMenu} from 'src/store/storeSlice';
import {setNewMenuId} from 'src/store/inventorySlice';

interface IProps {
  setShowEditModal: (e: boolean) => void;
  setShowMenuModal: (e: boolean) => void;
  setShowNewMenuModal: (e: boolean) => void;
  showNewMenuModal: boolean;
}

const NewMenuOptionModal = (props: IProps) => {
  const dispatch = useAppDispatch();

  const [timings, setTimings] = useState<ITiming[][]>(DEFAULT_HOURS);
  const [menuName, setMenuName] = useState('');
  const [validate, setValidate] = useState(false);

  const validateHours = (hours: ITiming[][]) => {
    let isValid = true;
    const updatedHours = hours.map((day) => day.map((hour) => ({...hour, error: ''})));
    for (let i = 0; i < updatedHours.length; i++) {
      const day = updatedHours[i];

      for (let j = 0; j < day.length; j++) {
        const hour = day[j];

        if (!hour.isClosed) {
          const openHour = dayjs(hour.openTime, 'HH:mm');
          const closeHour = dayjs(hour.closeTime, 'HH:mm');

          if (!hour.openTime || !hour.closeTime) {
            isValid = false;
            hour.error = 'Please enter open and close time';
          } else if (openHour.isSameOrAfter(closeHour) || !openHour.isValid() || !closeHour.isValid()) {
            isValid = false;
            hour.error = 'Invalid time range';
          }
        }
      }
    }
    setTimings(updatedHours);
    return isValid;
  };

  const createMenu = async () => {
    setValidate(true);
    if (validateHours(timings)) {
      const data = timings.flat().map((obj) => ({
        day_of_week: obj.dayOfWeek,
        open_time: obj.openTime,
        close_time: obj.closeTime,
        is_closed: obj.isClosed,
      }));
      const menuDetail = await dispatch(addMenu({data, menuName}));
      dispatch(setNewMenuId(menuDetail.payload.menu.id));
    }
  };

  return (
    <IonPage id="new-menu-option-mobile">
      <IonContent fullscreen scrollY={false}>
        <Header
          border
          noBackground
          title="New Menu"
          leftButton={
            <IonIcon
              onClick={() => {
                props.setShowNewMenuModal(false);
              }}
              icon={arrowBack}></IonIcon>
          }
          rightButton={
            <IonIcon
              onClick={() => {
                props.setShowMenuModal(false);
                props.setShowNewMenuModal(false);
                props.setShowEditModal(false);
              }}
              icon={closeOutline}></IonIcon>
          }
        />

        <div className="body">
          <NewMenu
            isDefault={false}
            menuName={menuName}
            setMenuName={setMenuName}
            timings={timings}
            setTimings={setTimings}
            validate={validate}
            validateInputs={validateHours}
            menuDetailId={''}
          />
        </div>
        <div className="action-div">
          <IonButton
            disabled={false}
            className="action-btn"
            mode="ios"
            fill="solid"
            color="primary"
            expand="block"
            onClick={async () => {
              await createMenu();
              props.setShowMenuModal(true);
              props.setShowNewMenuModal(false);
            }}>
            Save edit
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NewMenuOptionModal;
