import {IonImg, IonIcon, IonFooter, IonButton} from '@ionic/react';
import {callOutline} from 'ionicons/icons';
import './styles.scss';
import customerLogo from 'src/assets/images/customer-service.png';
import AnalyticsHelper from 'src/utils/segment';

interface IContactCustomer {
  order: any;
  goNext: () => void;
}

const ContactCustomer = ({order, goNext}: IContactCustomer) => (
  <div id="contact-customer">
    <div className="modal-content">
      <IonImg src={customerLogo} />

      <h4 className="modal-title">Contact the customer</h4>
      <p className="ion-text-center">
        If you plan to edit the order or have a question regarding a customer’s note please contact the customer
        directly.
      </p>

      <div className="customer-info">
        <div className="info-container">
          <div className="title-container">
            <IonIcon className="icon-user" />
            <p className="text">Customer</p>
          </div>
          <div className="value-container">
            <p>{order?.customerName}</p>
          </div>
        </div>

        <div className="info-container">
          <div className="title-container">
            <IonIcon icon={callOutline} />
            <p className="text">Phone number</p>
          </div>
          <div className="value-container">
            <p>{order?.customerPhone}</p>
            {order?.customerPhoneCode && <p>Pin: {order?.customerPhoneCode}</p>}
          </div>
        </div>
      </div>
    </div>

    <div className="confirm-text">Should you contact the customer?</div>

    <IonFooter>
      <IonButton
        className="btn-dark"
        expand="block"
        mode="ios"
        fill="clear"
        onClick={() => {
          goNext();
          AnalyticsHelper.trackBeginEditOrderClick(order.id);
        }}>
        Begin editing order
      </IonButton>
    </IonFooter>
  </div>
);

export default ContactCustomer;
