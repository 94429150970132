import {useEffect, useState} from 'react';
import {
  IonImg,
  IonItem,
  IonLabel,
  IonBadge,
  IonToggle,
  IonAlert,
  IonIcon,
  IonMenu,
  IonMenuToggle,
  IonRouterLink,
  IonButton,
  IonText,
} from '@ionic/react';
import './styles.scss';
import {useHistory, useLocation} from 'react-router-dom';
import {useFeatureFlagEnabled} from 'posthog-js/react';
import {SHOP_URL} from 'src/utils/constants';
import {useAppDispatch, useAppSelector} from 'src/hooks';
import {logoutUser, setMenuIsOpen} from 'src/store/authSlice';
import {closeStore, getStoreStatus, openStore} from 'src/store/storeSlice';
import {hideLoading, showLoading} from 'src/store/loadingSlice';
import {showAlert} from 'src/store/alertSlice';
import userService from 'src/services/user';
import SocketHelper from 'src/utils/socket';
import logo from 'src/assets/images/store-logo.png';
import {ROUTE_LOCATION} from 'src/enums/route_location';
import AnalyticsHelper from 'src/utils/segment';
import * as PERMISSIONS from 'src/utils/permissions';
import {checkPermission, checkSomePermissions} from 'src/utils/helpers';
import StoreStatuses from 'src/components/StoreStatuses';
import notificationService from 'src/services/notifications';
import {PARTNER} from 'src/enums/partner';

interface IProps {
  disabled?: boolean;
}

const SideMenu = ({disabled}: IProps) => {
  const history = useHistory();
  const reportsEnabled = useFeatureFlagEnabled('reports');
  const billingEnabled = useFeatureFlagEnabled('billing');
  const storefrontToggleEnabled = useFeatureFlagEnabled('storefront_toggle');
  const storePauseEnabled = useFeatureFlagEnabled('storefront_pause');
  const dispatch = useAppDispatch();
  const {state, pathname} = useLocation<any>();
  const {
    isClosed,
    partners,
    isAllPartnerOpen,
    isAllPartnerOffline,
    isSomePartnerOffline,
    isAllPartnerPaused,
    isSomePartnerPaused,
  } = useAppSelector((state) => state.store.storeStatus);
  const {user, userStores} = useAppSelector((state) => state.auth);
  const store = useAppSelector((state) => state.store);
  const orderCount = useAppSelector((state) => state.order.activeOrdersCount);
  const {isNative, isAndroid, isDesktop, versionNumber, device} = useAppSelector((state) => state.platform);
  const [showStoreAlert, setShowStoreAlert] = useState(false);
  const userPermissions = user?.role.permissions || [];

  const canViewSettings = checkSomePermissions(userPermissions, [
    PERMISSIONS.VIEW_ACCOUNT_DETAILS,
    PERMISSIONS.VIEW_ACCOUNT_NOTIFICATIONS,
  ]);
  const canViewOrderHistory = checkPermission(userPermissions, PERMISSIONS.VIEW_ORDER_HISTORY);
  const canViewReports = checkPermission(userPermissions, PERMISSIONS.VIEW_REPORTS);
  const canViewInsights = checkSomePermissions(userPermissions, [
    PERMISSIONS.VIEW_INSIGHTS_OVERVIEW,
    PERMISSIONS.VIEW_INSIGHTS_INVENTORY,
    PERMISSIONS.VIEW_INSIGHTS_ORDERS,
    PERMISSIONS.VIEW_INSIGHTS_PAYOUTS,
    PERMISSIONS.VIEW_INSIGHTS_REPORTS,
    PERMISSIONS.VIEW_INSIGHTS_STORES,
  ]);
  const canViewStore = checkSomePermissions(userPermissions, [
    PERMISSIONS.VIEW_STORE_DETAILS,
    PERMISSIONS.EDIT_STORE_DETAILS,
    PERMISSIONS.EDIT_STORE_HOURS,
    PERMISSIONS.VIEW_STORE_SHIPPING_DETAILS,
    PERMISSIONS.EDIT_STORE_SHIPPING_DETAILS,
    PERMISSIONS.VIEW_PRINTER_SETTINGS,
    PERMISSIONS.VIEW_MENU,
    PERMISSIONS.VIEW_EMPLOYEES,
  ]);
  const canViewShop = checkPermission(userPermissions, PERMISSIONS.VIEW_SHOP);
  const canViewHelp = checkPermission(userPermissions, PERMISSIONS.VIEW_KNOWLEDGE_BASE);
  const canViewInventory = checkPermission(userPermissions, PERMISSIONS.VIEW_INVENTORY);
  const canEditStoreStatus = checkPermission(userPermissions, PERMISSIONS.EDIT_STORE_STATUS);
  const canEditPartnerDetails = checkPermission(userPermissions, PERMISSIONS.EDIT_PARTNERS_STATUS);
  const canViewBilling = checkPermission(userPermissions, PERMISSIONS.VIEW_BILLING);
  const canViewOrders = checkPermission(userPermissions, PERMISSIONS.VIEW_ORDERS);

  const isActive = (paths: any, routingFrom?: string) => {
    if (paths.includes(pathname)) {
      if (routingFrom && state?.from) {
        return state?.from === routingFrom;
      } else return paths.includes(pathname);
    }
  };

  const logout = async () => {
    dispatch(showLoading());
    if (isNative && isAndroid) {
      notificationService.deactivateEmployeeNotificationDevice(device.deviceId);
      AnalyticsHelper.trackEmployeeDeviceUpdate(false);
    }
    await userService
      .logoutEmployee()
      .then(() => {
        AnalyticsHelper.trackLogout();
        dispatch(logoutUser());
        dispatch(hideLoading());
      })
      .catch((error: any) => {
        dispatch(hideLoading());
        dispatch(
          showAlert({
            heading: 'Error',
            message: error?.message || 'Some error occurred while logging you out. Try again later',
          }),
        );
      });
  };

  const StorePartnerTrackList = (partner: any, new_status: string) => {
    const d = Object.entries(partner);
    let name: string[] = [];
    let oldStatus: string[] = [];
    let newStatus: string[] = [];
    let toggleTime: Date[] = [];
    d.forEach((v: any) => {
      if (v[1]?.length) {
        name.push(v[0]);
        oldStatus.push(v[1][0].isOpen ? 'open' : 'close');
        newStatus.push(new_status);
        toggleTime.push(new Date());
      }
    });
    return {
      partners: name,
      old_status: oldStatus,
      new_status: newStatus,
      toggle_time: toggleTime,
    };
  };

  const storeOpeningHandler = async () => {
    if (isClosed) {
      await dispatch(openStore(PARTNER.All));
      AnalyticsHelper.trackStorefrontToggle(StorePartnerTrackList(partners, 'open'));
      await dispatch(getStoreStatus());
      SocketHelper.connectSocket(store?.id, dispatch);
    } else {
      await dispatch(closeStore(PARTNER.All));
      AnalyticsHelper.trackStorefrontToggle(StorePartnerTrackList(partners, 'close'));
      await dispatch(getStoreStatus());
      // SocketHelper.disconnectSocket();
    }
  };

  const onClickShop = () => {
    if (!isNative) window.open(SHOP_URL);
    else history.replace('/support/lula-shop');
  };

  useEffect(() => {
    setShowStoreAlert(false);
  }, []);

  const handleIconClass = () => {
    if (!isClosed && isAllPartnerOpen) return 'icon-dot green-dot';
    else if (!isClosed && (isSomePartnerPaused || isAllPartnerPaused || isSomePartnerOffline))
      return 'icon-dot yellow-dot';
    else return 'icon-dot red-dot';
  };

  return (
    <IonMenu
      menuId="menu"
      id="side-menu"
      contentId="main"
      type="custom-push"
      disabled={disabled}
      onIonDidClose={() => dispatch(setMenuIsOpen(false))}
      onIonDidOpen={() => dispatch(setMenuIsOpen(true))}>
      <IonAlert
        isOpen={showStoreAlert}
        onDidDismiss={() => setShowStoreAlert(false)}
        header={!isClosed ? 'Close all storefronts' : 'Open all storefronts'}
        message={
          !isClosed
            ? 'This action will CLOSE all of your connected delivery partners. Visit the partners screen to manage partners.'
            : 'This action will OPEN all of your connected delivery partners. Visit the partners screen to manage partners.'
        }
        buttons={[
          {
            text: 'OK',
            handler: storeOpeningHandler,
          },
          'Cancel',
        ]}
      />
      <>
        <div className="menu-container">
          <div>
            <div className="logo-container">
              <IonImg className="logo" src={logo} />
            </div>
            <div className="store-location">
              <div className="stores-list-item">
                <IonLabel>{store?.name}</IonLabel>
                <IonText>{store?.address.line_1}</IonText>
                <IonText>{`${store?.address.city}, ${store?.address.state}`}</IonText>
                {userStores.length > 1 && (
                  <IonRouterLink routerLink="/store/manage/stores"> View all locations</IonRouterLink>
                )}
              </div>
            </div>
            <div>
              <div
                className={`status ${
                  !isClosed && isAllPartnerOpen
                    ? 'open'
                    : !isClosed && (isSomePartnerPaused || isAllPartnerPaused || isSomePartnerOffline)
                    ? 'some-open'
                    : 'closed'
                }`}>
                <div className="info">
                  {canEditStoreStatus ? (
                    <p className="title">
                      Currently <span>{!isClosed ? 'Open' : 'Closed'}</span>
                    </p>
                  ) : (
                    <p className="title">
                      <span>{!isClosed ? 'Currently Open' : 'Currently Closed'}</span>
                    </p>
                  )}
                  {canEditStoreStatus && storefrontToggleEnabled && (
                    <>
                      <p className={`desc ${!isClosed && isAllPartnerOpen ? 'd-block' : 'd-none'}`}>
                        Toggle switch to close until tomorrow
                      </p>
                      <p className={`desc ${isClosed ? 'd-block' : 'd-none'} `}>Toggle switch to open store</p>
                      <p className={`desc ${!isClosed && isAllPartnerPaused ? 'd-block' : 'd-none'}`}>
                        All partners are paused
                      </p>
                      <p className={`desc ${!isClosed && isAllPartnerOffline ? 'd-block' : 'd-none'}`}>
                        All partners are offline
                      </p>
                      <p
                        className={`desc ${
                          !isClosed && (isSomePartnerPaused || isSomePartnerOffline) ? 'd-block' : 'd-none'
                        }`}>
                        Some partners are paused/offline
                      </p>
                      {!store.isDeactivated ? (
                        <IonMenuToggle menu="menu" autoHide={false}>
                          <IonButton fill={'clear'} className="desc partners-button">
                            <IonRouterLink routerLink="/partners">Manage Partners</IonRouterLink>
                          </IonButton>
                        </IonMenuToggle>
                      ) : null}
                    </>
                  )}
                </div>
                {canEditStoreStatus && storefrontToggleEnabled && (
                  <div
                    className={
                      !isClosed && (isSomePartnerPaused || isSomePartnerOffline)
                        ? 'partially-open'
                        : isClosed
                        ? 'switch'
                        : 'switch'
                    }>
                    <IonToggle
                      disabled={store.isDeactivated}
                      checked={!isClosed}
                      value="checkbox"
                      onClick={() => setShowStoreAlert(true)}
                    />
                  </div>
                )}
              </div>
            </div>
            {canEditStoreStatus && storePauseEnabled && isDesktop && (
              <StoreStatuses
                className="store-status-menu"
                isAllPartnerOpen={isAllPartnerOpen}
                isAllPartnerOffline={isAllPartnerOffline}
                isAllPartnerPaused={isAllPartnerPaused}
                isSomePartnerPaused={isSomePartnerPaused}
                isClosed={isClosed}
              />
            )}

            {(canViewOrders || canViewInventory) && <p className="heading">Sell</p>}
            {canViewOrders && (
              <IonMenuToggle
                menu="menu"
                autoHide={false}
                className="orders-nav-button"
                data-intercom-target="Orders nav button">
                <IonItem
                  className={
                    isActive(
                      ['/orders', '/orders/details', '/store/manage/details', '/store/manage/hours'],
                      ROUTE_LOCATION.ORDER_LIST,
                    )
                      ? 'selected'
                      : ''
                  }
                  onClick={() => history.replace('/orders')}>
                  <IonIcon slot="start" className="icon-receipt" />
                  <IonLabel>
                    Orders <IonBadge>{orderCount}</IonBadge>
                  </IonLabel>
                </IonItem>
              </IonMenuToggle>
            )}

            {canViewInventory && (
              <IonMenuToggle
                menu="menu"
                autoHide={false}
                data-intercom-target="Inventory nav button"
                className="inventory-nav-button">
                <IonItem
                  className={
                    isActive(['/inventory', '/items/details', '/items/common', '/items/common/search'])
                      ? 'selected'
                      : ''
                  }
                  onClick={() => history.replace('/inventory')}>
                  <IonIcon slot="start" className="icon-box" />
                  <IonLabel>Inventory</IonLabel>
                </IonItem>
              </IonMenuToggle>
            )}

            {(canViewOrderHistory || canViewReports) && (
              <>
                <p className="heading">Track</p>
                {reportsEnabled && canViewInsights && (
                  <IonMenuToggle
                    menu="menu"
                    autoHide={false}
                    data-intercom-target="Analytics nav button"
                    className="analytics-nav-button">
                    <IonItem
                      className={isActive(['/insights']) ? 'selected' : ''}
                      onClick={() => history.replace('/insights')}>
                      <IonIcon slot="start" className="icon-insights" />
                      <IonLabel>Analytics</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                )}

                {canViewOrderHistory && (
                  <IonMenuToggle menu="menu" autoHide={false}>
                    <IonItem
                      className={
                        isActive(['/orders/history', '/orders/details'], ROUTE_LOCATION.ORDER_HISTORY) ? 'selected' : ''
                      }
                      onClick={() => {
                        history.replace('/orders/history');
                      }}>
                      <IonIcon className="icon-calendar" slot="start" />
                      <IonLabel>History</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                )}

                {canViewReports && (
                  <IonMenuToggle menu="menu" autoHide={false}>
                    <IonItem
                      className={isActive(['/reports']) ? 'selected' : ''}
                      onClick={() => history.replace('/reports')}>
                      <IonIcon slot="start" className="icon-trends" />
                      <IonLabel>Reports</IonLabel>
                    </IonItem>
                  </IonMenuToggle>
                )}
              </>
            )}

            <p className="heading">Manage</p>
            {canViewStore && (
              <IonMenuToggle
                menu="menu"
                autoHide={false}
                data-intercom-target="Stores nav button"
                className="stores-nav-button">
                <IonItem
                  className={pathname.startsWith('/store/manage') ? 'selected' : ''}
                  onClick={() => history.replace('/store/manage')}>
                  <IonIcon slot="start" className="icon-store" />
                  <IonLabel>Store</IonLabel>
                </IonItem>
              </IonMenuToggle>
            )}

            {billingEnabled && canViewBilling && (
              <IonMenuToggle
                menu="menu"
                autoHide={false}
                data-intercom-target="Inventory nav button"
                className="inventory-nav-button">
                <IonItem
                  className={isActive(['/billing']) ? 'selected' : ''}
                  onClick={() => history.replace('/billing')}>
                  <IonIcon slot="start" className="icon-credit-card" />
                  <IonLabel>Billing</IonLabel>
                </IonItem>
              </IonMenuToggle>
            )}

            {canEditPartnerDetails && (
              <IonMenuToggle
                menu="menu"
                autoHide={false}
                data-intercom-target="Partners nav button"
                className="partners-nav-button">
                <IonItem
                  className={isActive(['/partners']) ? 'selected' : ''}
                  onClick={() => history.replace('/partners')}>
                  <IonIcon slot="start" className="icon-partners" />
                  <IonLabel>Partners</IonLabel>
                </IonItem>
              </IonMenuToggle>
            )}

            {canViewSettings && (
              <IonMenuToggle
                menu="menu"
                autoHide={false}
                data-intercom-target="Settings nav button"
                className="settings-nav-button">
                <IonItem
                  className={pathname.startsWith('/settings') ? 'selected' : ''}
                  onClick={() => history.replace('/settings')}>
                  <IonIcon slot="start" className="icon-setting" />
                  <IonLabel>Settings</IonLabel>
                </IonItem>
              </IonMenuToggle>
            )}

            {/* {(isStoreAdmin || isAdmin) && !isMobile && (
              <IonItem
                className={
                  isActive(
                    ['/inbox', '/settings/email', '/store/manage/details', '/store/manage/hours'],
                    ROUTE_LOCATION.INBOX,
                  )
                    ? 'selected'
                    : ''
                }
                onClick={() => history.replace('/inbox')}>
                <IonIcon slot="start" className={`icon-inbox ${inboxUnattended ? 'danger' : ''}`} />
                <IonLabel>Inbox</IonLabel>
              </IonItem>
            )} */}

            <div className="divider"></div>
            {canViewShop && (
              <IonMenuToggle menu="menu" autoHide={false}>
                <IonItem onClick={onClickShop}>
                  <IonIcon slot="start" className="icon-store" />
                  <IonLabel>Lula Shop</IonLabel>
                </IonItem>
              </IonMenuToggle>
            )}

            {canViewHelp && (
              <IonMenuToggle menu="menu" autoHide={false}>
                <IonItem onClick={() => window.Intercom('show')}>
                  <IonIcon slot="start" className="icon-chats" />
                  <IonLabel>Get Help</IonLabel>
                </IonItem>
              </IonMenuToggle>
            )}

            <IonItem onClick={logout}>
              <IonIcon slot="start" className="icon-logout" />
              <IonLabel>Log Out</IonLabel>
            </IonItem>
          </div>
          {isNative && versionNumber && <p className="version-info">Version: {versionNumber}</p>}
        </div>
      </>
    </IonMenu>
  );
};

export default SideMenu;
