import { PowerManagement } from '@awesome-cordova-plugins/power-management';
export class PowerService {
    private handleResponse(response: any): { success: boolean } {
        return {
            success: response === 'OK',
        };
    }

    private handleError(error: any): { success: boolean } {
        return {
            success: false,
        };
    } 

    async acquireWakeLock(): Promise<{ success: boolean }> {
        try {
            const response = await PowerManagement.acquire();
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    async acquireScreenDimLock(): Promise<{ success: boolean }> {
        try {
            const response = await PowerManagement.dim();
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    async releaseWakeLock(): Promise<{ success: boolean }> {
        try {
            const response = await PowerManagement.release();
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }

    async acquireWakeLockOnPause(isRelease = true): Promise<{ success: boolean }> {
        try {
            const response = await PowerManagement.setReleaseOnPause(isRelease);
            return this.handleResponse(response);
        } catch (error) {
            return this.handleError(error);
        }
    }
}
