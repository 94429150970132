import {ITEM_STATUS} from 'src/enums/item_status';
import './styles.scss';
import {IInventoryCount} from 'src/interfaces/item';

interface IProps {
  title: string;
  onTitleChange: (title: ITEM_STATUS.IN_STOCK | ITEM_STATUS.ARCHIVED) => void;
  inventoryCount: IInventoryCount;
}

const InventoryToggle = ({title, onTitleChange, inventoryCount}: IProps) => (
  <div id="inventory-toggle">
    <div className={title === ITEM_STATUS.IN_STOCK ? 'toggle-left' : 'toggle-right'}></div>

    <div className="toggle-text-container">
      <div className="toggle-text" onClick={() => onTitleChange(ITEM_STATUS.IN_STOCK)}>
        <span className={title === ITEM_STATUS.IN_STOCK ? 'active ' : 'inactive'}>For sale</span>
        <span className={title === ITEM_STATUS.IN_STOCK ? 'toggle-num active ' : 'toggle-num inactive'}>
          {inventoryCount.for_sale}
        </span>
      </div>

      <div className="toggle-text" onClick={() => onTitleChange(ITEM_STATUS.ARCHIVED)}>
        <span className={title === ITEM_STATUS.ARCHIVED ? 'active' : 'inactive'}>On hold</span>
        <span className={title === ITEM_STATUS.ARCHIVED ? 'toggle-num active' : 'toggle-num inactive'}>
          {inventoryCount.on_hold}
        </span>
      </div>
    </div>
  </div>
);

export default InventoryToggle;
