import React, { useState } from 'react';
import {IonItem, IonLabel, IonRadio, IonList, IonPopover, IonIcon, IonRadioGroup} from '@ionic/react';

interface Props {
  options: string[];
  placeholder: string;
  selectedValue: string;
  disabled: boolean;
  onHandleSelect: (option: any) => void;
}

const CustomDropdown: React.FC<Props> = ({
  options,
  disabled,
  placeholder,
  selectedValue,
  onHandleSelect,
}) => {
  const [showPopover, setShowPopover] = useState<boolean>(false);

  return (
    <>
      <div
        style={{
          cursor: 'pointer',
          borderWidth: '1px',
          borderStyle: 'solid',
          borderColor: '#CBCBCB',
          padding: '0.7rem',
          paddingLeft: '1rem',
          borderRadius: '4px',
          opacity: disabled ? 0.7 : 1,
        }}
        onClick={disabled ? undefined : () => setShowPopover(true)}>
        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center'}}>
          <IonLabel>
            <span
              style={{
                color: selectedValue ? 'black' : '#888888',
              }}>
              {selectedValue || placeholder}
            </span>
          </IonLabel>
          <div style={{display: 'flex', width: '24px'}}>
            <IonIcon slot="start" className="icon-chevron-down gray-icon" />
          </div>
        </div>
      </div>
      <IonPopover isOpen={showPopover} onDidDismiss={() => setShowPopover(false)}>
        <IonList>
          <IonRadioGroup value={selectedValue}>
            {options.map((option) => (
              <IonItem
                key={option}
                button
                onClick={() => {
                  setShowPopover(false);
                  onHandleSelect(option);
                }}>
                <IonLabel>{option}</IonLabel>
                <IonRadio value={option} />
              </IonItem>
            ))}
          </IonRadioGroup>
        </IonList>
      </IonPopover>
    </>
  );
};

export default CustomDropdown;
