import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {showLoading, hideLoading} from 'src/store/loadingSlice';
import {showAlert} from 'src/store/alertSlice';
import {RootState} from './index';
import {IInventoryCount, IInventoryStats, IItem, IQueryObject} from 'src/interfaces/item';
import {canAddToForSaleTab, checkCategoryCount, inInventory, removeMultipleItems} from 'src/utils/helpers';
import inventoryService from 'src/services/inventory';
import {DEFAULT_QUERY_OBJECT} from 'src/utils/constants';
import {ICategoryCount} from '../interfaces/item';
import {ITEM_STATUS} from 'src/enums/item_status';

interface IInventory {
  loading: boolean;

  inStockOffset: number;
  archivedOffset: number;
  outOfStockOffset: number;
  incompleteOffset: number;

  loadMoreInStock: boolean;
  loadMoreArchived: boolean;
  loadMoreIncomplete: boolean;
  loadMoreOutOfStock: boolean;

  inStockItems: Array<IItem>;
  archivedItems: Array<IItem>;
  outOfStockItems: Array<IItem>;
  incompleteItems: Array<IItem>;

  queryObject: IQueryObject;
  inventoryStats: IInventoryStats;
  isFiltered: boolean;
  inventoryTab: string;
  selectedItems: Array<IItem>;
  selectedCategories: Array<string>;
  exceptItems: Array<IItem>;
  inventoryCount: IInventoryCount;
  categoryCount: Array<ICategoryCount>;
  filteredCategoryCount: Array<ICategoryCount>;
  isEditable: boolean;
  isValidInputPrice: boolean;
  isSortChange: boolean;
  newMenuId: string;
}

export const fetchInStockItems = createAsyncThunk(
  'inventory/fetchInStockItems',
  async (_, {dispatch, fulfillWithValue, getState, rejectWithValue}) => {
    const {inventory, store} = getState() as RootState;
    const {inStockOffset, queryObject} = inventory;
    try {
      if (inStockOffset === 0) dispatch(showLoading());
      else dispatch(setLoading(true));
      const items = await inventoryService.getInStockInventory(inStockOffset, queryObject, store);
      if (inStockOffset === 0) dispatch(hideLoading());
      else dispatch(setLoading(false));
      return fulfillWithValue(items);
    } catch (error: any) {
      if (inStockOffset === 0) dispatch(hideLoading());
      else dispatch(setLoading(false));
      dispatch(
        showAlert({
          heading: 'Unable to fetch data',
          message: error?.message || 'Some error occurred while fetching data. Please, try again later',
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const fetchArchivedItems = createAsyncThunk(
  'inventory/fetchArchivedItems',
  async (_, {dispatch, fulfillWithValue, getState, rejectWithValue}) => {
    const {inventory, store} = getState() as RootState;
    const {archivedOffset, queryObject} = inventory;
    try {
      if (archivedOffset === 0) dispatch(showLoading());
      else dispatch(setLoading(true));
      const items = await inventoryService.getArchivedInventory(archivedOffset, queryObject, store);
      if (archivedOffset === 0) dispatch(hideLoading());
      else dispatch(setLoading(false));
      return fulfillWithValue(items);
    } catch (error: any) {
      if (archivedOffset === 0) dispatch(hideLoading());
      else dispatch(setLoading(false));
      dispatch(
        showAlert({
          heading: 'Unable to fetch data',
          message: error?.message || 'Some error occurred while fetching data. Please, try again later',
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const fetchOutOfStockItems = createAsyncThunk(
  'inventory/fetchOutOfStockItems',
  async (_, {dispatch, fulfillWithValue, getState, rejectWithValue}) => {
    const {inventory, store} = getState() as RootState;
    const {outOfStockOffset, queryObject} = inventory;
    try {
      if (outOfStockOffset === 0) dispatch(showLoading());
      else dispatch(setLoading(true));
      const items = await inventoryService.getOutOfStockInventory(outOfStockOffset, queryObject, store);
      if (outOfStockOffset === 0) dispatch(hideLoading());
      else dispatch(setLoading(false));
      return fulfillWithValue(items);
    } catch (error: any) {
      if (outOfStockOffset === 0) dispatch(hideLoading());
      else dispatch(setLoading(false));
      dispatch(
        showAlert({
          heading: 'Unable to fetch data',
          message: error?.message || 'Some error occurred while fetching data. Please, try again later',
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const fetchIncompleteItems = createAsyncThunk(
  'item/fetchIncompleteItems',
  async (_, {dispatch, fulfillWithValue, getState, rejectWithValue}) => {
    const {inventory, store} = getState() as RootState;
    const {incompleteOffset, queryObject} = inventory;
    try {
      if (incompleteOffset === 0) dispatch(showLoading());
      else dispatch(setLoading(true));
      const storeItems = await inventoryService.getIncompleteStoreItems(queryObject, store);
      dispatch(fetchInventoryCount());
      if (incompleteOffset === 0) dispatch(hideLoading());
      else dispatch(setLoading(false));
      return fulfillWithValue(storeItems);
    } catch (error: any) {
      if (incompleteOffset === 0) dispatch(hideLoading());
      else dispatch(setLoading(false));
      dispatch(
        showAlert({
          heading: 'Unable to fetch data',
          message: error?.message || 'Some error occurred while fetching data. Please, try again later',
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const fetchInventoryStats = createAsyncThunk(
  'inventory/fetchInventoryStats',
  async (_, {dispatch, fulfillWithValue, getState, rejectWithValue}: any) => {
    try {
      const {store} = getState() as RootState;
      const response = await inventoryService.getInventoryStats(store.id);
      return fulfillWithValue(response);
    } catch (error: any) {
      dispatch(
        showAlert({
          heading: 'Unable to fetch data',
          message: error?.message || 'Some error occurred while fetching data. Please, try again later',
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const fetchCategoryCount = createAsyncThunk(
  'inventory/fetchCategoryCount',
  async (active: boolean, {dispatch, fulfillWithValue, getState, rejectWithValue}: any) => {
    try {
      const {inventory, store} = getState() as RootState;
      const {queryObject} = inventory;
      const response = await inventoryService.getCategoryCount(store.id, active, queryObject);
      return fulfillWithValue(response);
    } catch (error: any) {
      dispatch(
        showAlert({
          heading: 'Unable to fetch data',
          message: error?.message || 'Some error occurred while fetching data. Please, try again later',
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const fetchInventoryCount = createAsyncThunk(
  'inventory/fetchInventoryCount',
  async (_, {dispatch, fulfillWithValue, getState, rejectWithValue}: any) => {
    try {
      const {inventory, store} = getState() as RootState;
      const {queryObject} = inventory;
      const response = await inventoryService.getInventoryCount(queryObject, store);
      return fulfillWithValue(response);
    } catch (error: any) {
      dispatch(
        showAlert({
          heading: 'Unable to fetch data',
          message: error?.message || 'Some error occurred while fetching data. Please, try again later',
        }),
      );
      return rejectWithValue(error);
    }
  },
);

const initialQueryState: IQueryObject = {
  ...DEFAULT_QUERY_OBJECT,
};

const initialState: IInventory = {
  loading: false,
  inStockOffset: 0,
  archivedOffset: 0,
  outOfStockOffset: 0,
  incompleteOffset: 0,
  loadMoreInStock: true,
  loadMoreArchived: true,
  loadMoreOutOfStock: true,
  loadMoreIncomplete: true,
  inStockItems: [],
  archivedItems: [],
  outOfStockItems: [],
  incompleteItems: [],
  queryObject: {...initialQueryState},
  inventoryStats: {maxPrice: 0, minPrice: 0, inventoryCount: 0, maxDate: '0', minDate: '0'},
  isFiltered: false,
  inventoryTab: ITEM_STATUS.IN_STOCK,
  selectedItems: [],
  selectedCategories: [],
  exceptItems: [],
  inventoryCount: {
    for_sale: 0,
    on_hold: 0,
    out_of_stock: 0,
    incomplete: 0,
  },
  categoryCount: [],
  filteredCategoryCount: [],
  isEditable: false,
  isValidInputPrice: true,
  isSortChange: true,
  newMenuId: '',
};

const inventorySlice = createSlice({
  name: 'inventory',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    addToInventory: (state, {payload}) => {
      // When incomplete item (whose details are missing) is added to store, so put that item to archivedItems and incomplete items
      // otherwise put that item into to inStockItems
      // is_reviewed = false item put in archivedItems.
      if (payload.completed) {
        if (!payload.is_reviewed) {
          state.archivedItems.unshift(payload);
        } else {
          state.inStockItems.unshift(payload);
        }
      } else {
        state.archivedItems.unshift(payload);
        state.incompleteItems.unshift(payload);
      }
    },
    archiveItem: (state, {payload}) => {
      if (payload.completed) {
        // if(payload.item_id)
        state.inStockItems = state.inStockItems.filter((item) => item.storeItemId !== payload.storeItemId);
        // Adding item into archived items and out of stock item.
        if (state.archivedItems.length > 0) state.archivedItems.unshift({...payload, active: false});
        if (state.outOfStockItems.length > 0) state.outOfStockItems.unshift({...payload, active: false});
      } else {
        state.archivedItems = state.archivedItems.map((item) =>
          item.storeItemId === payload.storeItemId ? {...payload, active: false} : item,
        );
      }
    },
    unarchiveItem: (state, {payload}) => {
      if (payload.completed) {
        state.inStockItems.unshift({...payload, active: true});

        // Removing item from archived items & out of stock items.
        state.archivedItems = state.archivedItems.filter((item) => item.storeItemId !== payload.storeItemId);
        state.outOfStockItems = state.outOfStockItems.filter((item) => item.storeItemId !== payload.storeItemId);
      } else {
        state.archivedItems = state.archivedItems.map((item) =>
          item.storeItemId === payload.storeItemId ? {...payload, active: true} : item,
        );
      }
    },

    bulkArchiveItems: (state, {payload}) => {
      state.inStockItems = state.inStockItems.filter((item) => {
        for (let index = 0; index < payload.items.length; index++) {
          return item.storeItemId !== payload.items[index].storeItemId;
        }
      });

      // Adding items into archived items.
      for (let index = 0; index < payload.items.length; index++) {
        if (state.archivedItems.length > 0) state.archivedItems.unshift({...payload.items[index], active: false});
      }
    },
    updateItemInInventory: (state, {payload}) => {
      if (inInventory(payload.storeItemId, state.inStockItems)) {
        if (!canAddToForSaleTab(payload)) {
          state.archivedItems.unshift(payload);
          state.inStockItems = state.inStockItems.filter((item) => item.storeItemId !== payload.storeItemId);
        } else {
          state.inStockItems = state.inStockItems.map((item) =>
            item.storeItemId === payload.storeItemId ? {...payload, recent: item.recent} : item,
          );
        }
      } else if (inInventory(payload.storeItemId, state.archivedItems)) {
        if (canAddToForSaleTab(payload)) {
          state.inStockItems.unshift(payload);
          state.archivedItems = state.archivedItems.filter((item) => item.storeItemId !== payload.storeItemId);
        } else {
          state.archivedItems = state.archivedItems.map((item) =>
            item.storeItemId === payload.storeItemId ? {...payload, recent: item.recent} : item,
          );
        }
      }
      if (inInventory(payload.storeItemId, state.incompleteItems)) {
        state.incompleteItems = state.incompleteItems.filter((item) => item.storeItemId !== payload.storeItemId);
      }
    },
    removeFromInventory: (state, {payload}) => {
      if (inInventory(payload, state.inStockItems)) {
        state.inStockItems = state.inStockItems.filter((item) => item.storeItemId !== payload);
      } else if (inInventory(payload, state.archivedItems)) {
        state.archivedItems = state.archivedItems.filter((item) => item.storeItemId !== payload);
      } else if (inInventory(payload, state.incompleteItems)) {
        state.incompleteItems = state.incompleteItems.filter((item) => item.storeItemId !== payload);
      }
    },
    activeItem: (state, {payload}) => {
      if (payload) {
        if (inInventory(payload.storeItemId, state.inStockItems)) {
          state.inStockItems = state.inStockItems.map((item) =>
            item.storeItemId === payload.storeItemId
              ? {...payload, activeIndex: true, recent: item.recent}
              : {...item, activeIndex: false},
          );
        } else if (inInventory(payload.storeItemId, state.archivedItems)) {
          state.archivedItems = state.archivedItems.map((item) =>
            item.storeItemId === payload.storeItemId ? {...payload, activeIndex: true} : {...item, activeIndex: false},
          );
        }
      } else {
        state.inStockItems = state.inStockItems.map((item) => ({...item, activeIndex: false}));
        state.archivedItems = state.archivedItems.map((item) => ({...item, activeIndex: false}));
      }
    },
    setInStockInventory: (state, action) => {
      state.inStockItems = action.payload;
    },
    setArchivedInventory: (state, action) => {
      state.archivedItems = action.payload;
    },
    setIncompleteInventory: (state, action) => {
      state.incompleteItems = action.payload;
    },
    setOutOfStockInventory: (state, action) => {
      state.outOfStockItems = action.payload;
    },
    refreshInStockInventory: (state) => {
      state.inStockOffset = 0;
      state.loadMoreInStock = true;
    },
    refreshArchivedInventory: (state) => {
      state.archivedOffset = 0;
      state.loadMoreArchived = true;
    },
    refreshOutOfStockInventory: (state) => {
      state.outOfStockOffset = 0;
      state.loadMoreOutOfStock = true;
    },
    refreshIncompleteInventory: (state) => {
      state.incompleteOffset = 0;
      state.loadMoreIncomplete = true;
    },
    clearInventory: () => {
      return initialState;
    },
    clearInventoryQuery: (state) => {
      state.queryObject = {...initialQueryState, ...state.inventoryStats};
    },
    setQueryObject: (state, action) => {
      const query = {...state.queryObject, ...action.payload};
      // state.queryObject.order = action.payload.order;
      // state.queryObject.sortDir = action.payload.sortDir;
      state.queryObject = query;
    },
    setInventoryStats: (state, action) => {
      state.inventoryStats = {...action.payload};
    },
    setIsFiltered: (state, action) => {
      state.isFiltered = action.payload;
    },
    setInventoryTab: (state, action) => {
      state.inventoryTab = action.payload;
    },
    setSelectedItems: (state, action) => {
      state.selectedItems = action.payload;
    },
    setExceptItems: (state, action) => {
      state.exceptItems = action.payload;
    },
    setSelectedCategory: (state, action) => {
      state.selectedCategories = action.payload;
    },
    setOutOfStockOffset: (state, action) => {
      state.outOfStockOffset = action.payload;
    },
    setIncompleteOffset: (state, action) => {
      state.incompleteOffset = action.payload;
    },
    setSelectedItem: (state, action) => {
      const item = action.payload;
      if (state.selectedCategories.includes(item.category.id)) {
        state.exceptItems = state.exceptItems.filter((x) => x.storeItemId !== item.storeItemId);
      } else {
        if (!state.selectedItems.some((x) => x.storeItemId === item.storeItemId)) {
          state.selectedItems = [...state.selectedItems, item];

          if (checkCategoryCount(state.selectedItems, state.categoryCount, item.category.id)) {
            if (!state.selectedCategories.includes(item.category.id)) {
              state.selectedCategories = [...state.selectedCategories, item.category.id];
              state.selectedItems = removeMultipleItems(state.selectedItems, item.category.id);
            }
          }
        }
      }
    },
    removeSelectedItem: (state, action) => {
      const item = action.payload;
      const totalItems = state.categoryCount.find((x) => x.id === item.category.id)?.category_count;

      if (state.selectedCategories.includes(item.category.id)) {
        if (!state.exceptItems.some((x) => x.storeItemId === item.storeItemId)) {
          const updatedExceptItems = [...state.exceptItems, item];

          if (updatedExceptItems.length === Number(totalItems)) {
            state.exceptItems = state.exceptItems.filter((x) => x.category.id !== item.category.id);
            state.selectedCategories = state.selectedCategories.filter((catId) => catId !== item.category.id);
          } else {
            state.exceptItems = [...state.exceptItems, item];
          }
        }
      } else {
        state.selectedItems = state.selectedItems.filter((x) => x.storeItemId !== item.storeItemId);
      }
    },
    setSelectedCategories: (state, action) => {
      const item = action.payload;
      const inSelectedCategories = state.selectedCategories.includes(item.category.id);
      const inExceptItems = state.exceptItems.some((x) => x.category.id === item.category.id);

      if (inSelectedCategories && inExceptItems) {
        state.selectedItems = state.selectedItems.filter((x) => x.category.id !== item.category.id);
        state.exceptItems = state.exceptItems.filter((x) => x.category.id !== item.category.id);
      }

      if (inSelectedCategories && !inExceptItems) {
        state.selectedItems = state.selectedItems.filter((x) => x.category.id !== item.category.id);
      }

      if (!inSelectedCategories) {
        state.selectedCategories = [...state.selectedCategories, item.category.id];
        state.selectedItems = state.selectedItems.filter((x) => x.category.id !== item.category.id);
      }
    },
    removeSelectedCategories: (state, action) => {
      const item = action.payload;
      if (
        state.selectedCategories.includes(item.category.id) &&
        !state.exceptItems.some((x) => x.category.id === item.category.id)
      ) {
        state.selectedCategories = state.selectedCategories.filter((cat) => cat !== item.category.id);
        state.exceptItems = state.exceptItems.filter((x) => x.category.id !== item.category.id);
        state.selectedItems = state.selectedItems.filter((x) => x.category.id !== item.category.id);
      }
    },
    setIsEditable: (state, action) => {
      state.isEditable = action.payload;
    },
    setIsValidInputPrice: (state, action) => {
      state.isValidInputPrice = action.payload;
    },
    setIsSortChange: (state, action) => {
      state.isSortChange = action.payload;
    },
    setNewMenuId: (state, action) => {
      state.newMenuId = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchInStockItems.fulfilled, (state, action) => {
        if ((action.payload as unknown as any[]).length < 20) state.loadMoreInStock = false;
        else state.inStockOffset = state.inStockOffset + 20;
        state.inStockItems.push(...(action.payload as unknown as any[]));
      })
      .addCase(fetchInStockItems.rejected, (state) => {
        state.loadMoreInStock = false;
      })
      .addCase(fetchArchivedItems.fulfilled, (state, action) => {
        if ((action.payload as unknown as any[]).length < 20) state.loadMoreArchived = false;
        else state.archivedOffset = state.archivedOffset + 20;
        state.archivedItems.push(...(action.payload as unknown as any[]));
      })
      .addCase(fetchArchivedItems.rejected, (state) => {
        state.loadMoreArchived = false;
      })
      .addCase(fetchOutOfStockItems.fulfilled, (state, action) => {
        if ((action.payload as unknown as any[]).length < 20) state.loadMoreOutOfStock = false;
        else state.outOfStockOffset = state.outOfStockOffset + 20;
        state.outOfStockItems.push(...(action.payload as unknown as any[]));
      })
      .addCase(fetchOutOfStockItems.rejected, (state) => {
        state.loadMoreOutOfStock = false;
      })
      .addCase(fetchIncompleteItems.fulfilled, (state, action) => {
        if ((action.payload as unknown as any[]).length < 20) state.loadMoreIncomplete = false;
        else state.incompleteOffset = state.incompleteOffset + 20;
        state.incompleteItems.push(...(action.payload as unknown as any[]));
      })
      .addCase(fetchIncompleteItems.rejected, (state) => {
        state.loadMoreIncomplete = false;
      })
      .addCase(fetchInventoryCount.fulfilled, (state, action) => {
        state.inventoryCount = action.payload;
      })
      .addCase(fetchCategoryCount.fulfilled, (state, action) => {
        state.categoryCount = action.payload.categoryCount;
        state.filteredCategoryCount = action.payload.filteredCategoryCount;
      })
      .addCase(fetchInventoryStats.fulfilled, (state, action) => {
        state.inventoryStats = {...action.payload};
      });
  },
});

export const {
  setLoading,
  addToInventory,
  updateItemInInventory,
  removeFromInventory,
  archiveItem,
  unarchiveItem,
  bulkArchiveItems,
  activeItem,
  clearInventory,
  setInStockInventory,
  setArchivedInventory,
  setOutOfStockInventory,
  setIncompleteInventory,
  refreshInStockInventory,
  refreshArchivedInventory,
  refreshOutOfStockInventory,
  refreshIncompleteInventory,
  setOutOfStockOffset,
  setIncompleteOffset,
  setQueryObject,
  clearInventoryQuery,
  setIsFiltered,
  setSelectedItems,
  setExceptItems,
  setSelectedCategory,
  setSelectedCategories,
  removeSelectedCategories,
  setSelectedItem,
  removeSelectedItem,
  setIsEditable,
  setIsValidInputPrice,
  setIsSortChange,
  setNewMenuId,
  setInventoryTab,
} = inventorySlice.actions;

export default inventorySlice.reducer;
