import {IonIcon} from '@ionic/react';
import './styles.scss';
import {IHistoryMetrics} from 'src/interfaces/item';

interface IProps {
  historyMetrics: IHistoryMetrics;
  completed: boolean;
  cancelled: boolean;
  denied: boolean;
}

const OrderHistoryMetrics = (props: IProps) => (
  <div id="order-history-metrics">
    <div className="metric-container">
      <div className="metric-title">Total Orders</div>
      <span className="metric-value">{props.historyMetrics.ordersCount}</span>
    </div>

    {props.completed && (
      <div className="metric-container b-left">
        <div className="metric-title">Completed Sales</div>
        <div>
          {props.completed && <IonIcon className="badge-completed" />}
          <span className="metric-value">${props.historyMetrics.revenueEarned}</span>
        </div>
      </div>
    )}

    {(props.denied || props.cancelled) && (
      <div className="metric-container b-left">
        <div className="metric-title">Lost Sales</div>
        <div>
          {props.denied && <IonIcon className="badge-denied" />}
          {props.cancelled && <IonIcon className="badge-cancelled" />}
          <span className="metric-value">${props.historyMetrics.revenueLost}</span>
        </div>
      </div>
    )}
  </div>
);

export default OrderHistoryMetrics;
