import {useState} from 'react';
import Countdown from 'react-countdown';
import {IonModal, IonButton, IonBadge} from '@ionic/react';
import dayjs from 'dayjs';
import './styles.scss';
import {useHistory} from 'react-router-dom';
import moment from 'moment';
import NewOrderItem from 'src/components/NewOrderItem';
import orderService from 'src/services/order';
import {useAppDispatch, useAppSelector} from 'src/hooks';
import {setShowNewOrderModal} from 'src/store/storeSlice';
import {setNewIncomingOrdersCount, acceptOrder, setAcceptedOrderId, processOrder} from 'src/store/orderSlice';
import {hideLoading, showLoading} from 'src/store/loadingSlice';
import {showAlert} from 'src/store/alertSlice';
import {getPartnerImage, getTimeInMilliseconds} from 'src/utils/helpers';
import {ROUTE_LOCATION} from 'src/enums/route_location';
import AnalyticsHelper from 'src/utils/segment';
import watch from 'src/assets/images/watch.svg';
import footer from 'src/assets/images/order-modal-footer.svg';
import utensils from 'src/assets/images/utensils.svg';
import InstructionsCard from 'src/pages/Orders/components/InstructionsCard';
import {PARTNER} from 'src/enums/partner';
import {useFeatureFlagEnabled} from 'posthog-js/react';

const ACCEPT_IN = 10 * 60 * 1000;

const NewOrderModal = () => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const isLingeringOrderHandlerEnabled = useFeatureFlagEnabled('lingering-order-handler');
  const {newIncomingOrdersCount, incomingOrder} = useAppSelector((state) => state.order);
  const {showNewOrderModal, id: storeId} = useAppSelector((state) => state.store);
  const {isDesktop} = useAppSelector((state) => state.platform);

  const [bagTime, setBagTime] = useState(0);
  const [acceptOrderDisable, setAcceptOrderDisable] = useState(false);

  const hideModal = () => {
    dispatch(setNewIncomingOrdersCount(0));
    dispatch(setShowNewOrderModal(false));
    setBagTime(0);
  };

  const onSelectTime = (time: number) => {
    setAcceptOrderDisable(false);
    setBagTime(time);
  };

  const updateLingeringOrder = (order: any, callback: any) => {
    dispatch(showLoading());
    if (order?.storeId && order?.id) {
      orderService
        .updateLingeringOrder(order?.storeId, order?.id)
        .then((response) => {
          dispatch(hideLoading());
          dispatch(processOrder({id: order?.id, status: response?.status}));
          hideModal();
          if (response) {
            if (isDesktop) {
              history.push('/orders');
            } else {
              history.push({pathname: '/orders/details', state: {bagTime, from: ROUTE_LOCATION.ORDER_LIST}});
            }
          } else {
            callback();
          }
        })
        .catch((error) => {
          dispatch(hideLoading());
          callback();
        })
        .finally(() => {
          dispatch(hideLoading());
        });
    } else {
      dispatch(hideLoading());
      callback();
    }
  };
  const onAcceptIncomingOrder = (id: string) => {
    dispatch(showLoading());
    const data = {prep_time: dayjs().add(bagTime, 'm').unix() * 1000, is_auto_accept_request: false, store_id: storeId};
    orderService
      .acceptIncomingOrder(id, data)
      .then((_) => {
        dispatch(hideLoading());
        dispatch(acceptOrder({id, bagTime, time: moment().format()}));
        dispatch(setShowNewOrderModal(false));
        AnalyticsHelper.trackViewAndEditOrder(id);

        if (isDesktop) {
          dispatch(setAcceptedOrderId(id));
          history.push('/orders');
        } else {
          history.push({pathname: '/orders/details', state: {bagTime, from: ROUTE_LOCATION.ORDER_LIST}});
        }
      })
      .catch((e) => {
        dispatch(hideLoading());
        const onErrorShowDispatch = () => {
          dispatch(
            showAlert({
              heading: 'Error',
              message: e?.message || 'Error occurred in accepting order. Please, try again later.',
            }),
          );
          dispatch(setShowNewOrderModal(false));
        };
        if (incomingOrder?.partner === PARTNER.UBER_EATS && isLingeringOrderHandlerEnabled) {
          updateLingeringOrder(incomingOrder, onErrorShowDispatch);
        } else {
          onErrorShowDispatch();
        }
      });
  };

  return (
    <IonModal className="app-modal new-order-modal" isOpen={showNewOrderModal} showBackdrop onDidDismiss={hideModal}>
      <div className="modal-content-container">
        <div className="modal-content">
          <img className="logo" src={getPartnerImage(incomingOrder?.partner || '')} alt="" />
          {!!newIncomingOrdersCount && (
            <IonBadge className="incoming-order-count-badge">
              {`${newIncomingOrdersCount < 10 ? '0' : ''}${newIncomingOrdersCount}`}
            </IonBadge>
          )}
          <div className="new-order-modal-content">
            <h2 className="modal-title">Incoming order</h2>
            <div className="details">
              <div className="items">
                <p className="desc">
                  <strong>{incomingOrder?.quantity}</strong>&nbsp; Items
                </p>
              </div>
              <div className="action">
                <img src={watch} alt="" />
                <p className="desc">
                  {`View within: `}
                  <strong className="danger">
                    <Countdown
                      date={getTimeInMilliseconds(incomingOrder?.createdAt || '') + ACCEPT_IN}
                      renderer={({minutes, seconds, completed}) =>
                        !completed ? (
                          <strong className="danger">{`${minutes < 10 ? '0' + minutes : minutes}:${
                            seconds < 10 ? '0' + seconds : seconds
                          }`}</strong>
                        ) : (
                          <strong className="danger">00:00</strong>
                        )
                      }
                      onComplete={() => console.log('Order Expired')}
                    />
                  </strong>
                </p>
              </div>
            </div>
            <div className="products">
              {!!incomingOrder?.disposableIncluded && (
                <InstructionsCard variant="warning" icon={utensils} title="Include utensils" />
              )}
              {incomingOrder?.orderItems?.map((item: any, i: number) => (
                <NewOrderItem key={i} item={item} />
              ))}
            </div>
            <p className="heading">Select time to Bag</p>
            <div className="bag-time-container">
              <div className={`bag-time ${bagTime === 5 ? 'selected' : ''}`} onClick={() => onSelectTime(5)}>
                <p>5 min</p>
              </div>
              <div className={`bag-time bordered ${bagTime === 10 ? 'selected' : ''}`} onClick={() => onSelectTime(10)}>
                <p>10 min</p>
              </div>
              <div className={`bag-time ${bagTime === 15 ? 'selected' : ''}`} onClick={() => onSelectTime(15)}>
                <p>15 min</p>
              </div>
            </div>
            <IonButton
              className="btn-primary"
              disabled={!bagTime}
              expand="block"
              onClick={(e) => {
                if (!acceptOrderDisable) {
                  setAcceptOrderDisable(true);
                  onAcceptIncomingOrder(incomingOrder?.id || '');
                }
              }}>
              View and Edit Order
            </IonButton>
            <IonButton
              className="btn-primary footer-btn mb-0 mt-0 deny"
              expand="block"
              fill="clear"
              onClick={() => {
                AnalyticsHelper.trackOrderPopupDismissed(incomingOrder?.id || '');
                hideModal();
              }}>
              Dismiss order pop-up
            </IonButton>
          </div>
          <img className="footer-img" src={footer} alt="" />
        </div>
      </div>
    </IonModal>
  );
};

export default NewOrderModal;
