import {IonItem, IonCheckbox} from '@ionic/react';
import './styles.scss';
import ItemImage from 'src/components/ItemImage';
import {IOrderItemDetails} from 'src/interfaces/item';

interface IProps {
  item: IOrderItemDetails;
  isChecked: boolean;
  isDisabled: boolean;
  onCheckboxClick: (checked: boolean, item: IOrderItemDetails) => void;
}

const DenialOrderItem = ({item, isChecked, isDisabled, onCheckboxClick}: IProps) => (
  <IonItem id="denial-item-component" className={`${isChecked ? 'checked' : ''}`} disabled={isDisabled}>
    <div className="receipt-item">
      <div className="receipt-content">
        <IonCheckbox
          checked={isChecked}
          slot="start"
          value={item}
          disabled={isDisabled}
          onClick={(e: any) => onCheckboxClick(e.target.checked, item)}
        />
        <div className="details-container">
          <div className="item-desc">
            <div className="name">{item.itemName}</div>
            {item.description && (
              <p className="size">
                <span>Size</span> {item.description}
              </p>
            )}
            {item.unitCount && (
              <p className="size">
                <span>Count</span> {item.unitCount}
              </p>
            )}
          </div>
          <div className="img-container">
            <ItemImage category={item.categoryName} image={item.image} />
          </div>
        </div>
      </div>
      <div className={`size-price-info`}>
        {item.quantity && <p className="quantity">{`x ${item.quantity}`}</p>}
        <p className="price">{`$ ${Number(item.itemPrice).toFixed(2) || item.itemTotalPrice}`}</p>
      </div>
    </div>
  </IonItem>
);

export default DenialOrderItem;
