export enum ROUTE_LOCATION {
  REGISTER = 'register',
  FORGOT_PASSWORD = 'forgot-password',
  VERIFY = 'verify',
  LOGIN = 'login',
  ORDER_HISTORY = 'order-history',
  ORDER_LIST = 'order-list',
  INBOX = 'inbox',
  MANAGE_STORE = 'manage-store',
  SETTINGS = 'settings',
}
