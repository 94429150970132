import {IonButton, IonIcon, IonLabel, IonMenuToggle, IonRouterLink} from '@ionic/react';
import {chevronDownOutline} from 'ionicons/icons';
import {useState} from 'react';
import {useHistory} from 'react-router';
import {useAppSelector} from 'src/hooks';
import './styles.scss';

interface IProps {
  partnerName?: string;
}

const StoreStatusMenuOffline = ({partnerName}: IProps) => {
  const history = useHistory();
  const [opened, setOpened] = useState(false);
  const {isDesktop} = useAppSelector((state) => state.platform);

  return (
    <div id="status-menu">
      <div
        className={`bg-red status-menu-container ${opened ? 'open' : 'close'}`}
        onClick={() => {
          setOpened(!opened);
        }}>
        <div className="status-menu">
          <IonLabel className="text-white">Offline</IonLabel>
          {partnerName ? (
            <IonLabel className="text-white subtitle">{partnerName}</IonLabel>
          ) : (
            <IonLabel className="text-white subtitle">All partners</IonLabel>
          )}
        </div>
        <IonIcon icon={chevronDownOutline} slot="end" className={`text-white ${opened ? 'open' : 'close'}`} />
      </div>
      {opened && (
        <div className="status-menu-content">
          <p className="text">
            To go online, reach out to our Lula Customer Success team, and they will be able to assist you.
          </p>
          <p className="text">Call: (267) 263-7197</p>
          <p className="text">Email: wecare@luladelivery.com</p>
          <p className="text">Have a few questions?</p>
          <IonRouterLink href="https://help.luladelivery.com/" target="_blank">
            Navigate to the Help center
          </IonRouterLink>

          {!partnerName && (
            <div className="partner-button">
              <IonMenuToggle menu="menu" autoHide={false}>
                <IonButton className="btn-outline" expand="block" mode="md" onClick={() => history.push('/partners')}>
                  Visit partners page
                </IonButton>
              </IonMenuToggle>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default StoreStatusMenuOffline;
