import {useAppSelector} from 'src/hooks';
import {IonRow, IonCol, IonImg, IonGrid, IonBadge} from '@ionic/react';
import moment from 'moment';
import {getPartnerImage} from 'src/utils/helpers';
import './styles.scss';
import NoItem from 'src/components/NoItem';
import completedBadge from 'src/assets/images/completed-badge.svg';
import cancelledBadge from 'src/assets/images/cancelled-badge.svg';
import deniedBadge from 'src/assets/images/denied-badge.svg';

interface IProps {
  onClickOrder: (id: string) => void;
}

const statuses: any = {
  completed: completedBadge,
  cancelled: cancelledBadge,
  denied: deniedBadge,
};

const OrderItem = ({order, onClickOrder}: any) => {
  const {isMobile, isTablet, isDesktop} = useAppSelector((state) => state.platform);
  return (
    <IonRow
      key={order.id}
      className={`order-item ${order.activeIndex ? 'select' : ''} `}
      onClick={() => onClickOrder(order.id)}>
      <IonCol size-lg="1" size-md="2" size="2" className="avatar-container">
        <IonImg className="partner-avatar" src={getPartnerImage(order.partner)} />
        {isTablet && <IonImg className="status-img" src={statuses[order.status]} />}
        {isMobile && <IonBadge className={`status-badge-${order.status}`}>{` `}</IonBadge>}
      </IonCol>
      <IonCol size-lg="3.5" size-md="3" size="4">
        <div className="customer-id-col">
          <p className="row-text-normal title name-col">{order.customerName}</p>
          <p className="row-text-normal desc order-id-col">
            {!isDesktop && 'ID: '}
            {order.id.slice(-6)}
          </p>
        </div>
      </IonCol>
      {isDesktop && (
        <IonCol size-lg="2" className="status row-text-normal">
          <IonBadge className={`status-badge-${order.status}`}>
            {order.status.charAt(0).toUpperCase() + order.status.slice(1)}
          </IonBadge>
        </IonCol>
      )}
      <IonCol size-lg="4" size-md="5" size="3.5">
        <div className="time-date-col">
          <p className={`row-text-normal col-text time-col ${order.status !== 'completed' ? 'font-gray' : ''}`}>
            {moment(order.createdAt).format('LT')}
          </p>
          <p className={`row-text-normal col-text date-col ${order.status !== 'completed' ? 'font-gray' : ''}`}>
            {moment(order.createdAt).format('MM/DD/YYYY')}
          </p>
        </div>
      </IonCol>
      <IonCol size-lg="1.5" size-md="2" size="2.5" className="price">
        <IonBadge className={`status-badge-${order.status}`}>{` `}</IonBadge>
        <p className={`row-text-normal price-text ${order.status !== 'completed' ? 'font-gray' : ''}`}>
          ${order.totalPrice}
        </p>
      </IonCol>
    </IonRow>
  );
};

const OrderHistoryItems = (props: IProps) => {
  const {isDesktop} = useAppSelector((state) => state.platform);
  const {ordersHistory: orders} = useAppSelector((state) => state.order);
  return (
    <IonGrid id="order-history-items">
      {isDesktop && (
        <IonRow className="history-items-header">
          <IonCol size-lg="1" className="header-text image-col">
            DSP
          </IonCol>
          <IonCol size-lg="3.5">
            <div className="customer-id-col">
              <p className="header-text name-col">Customer</p>
              <p className="header-text order-id-col">Order ID</p>
            </div>
          </IonCol>
          <IonCol size-lg="2" className="header-text">
            Status
          </IonCol>
          <IonCol size-lg="4" className="header-text">
            <div className="time-date-col">
              <p className="header-text time-col">Time</p>
              <p className="header-text date-col">Date</p>
            </div>
          </IonCol>
          <IonCol size-lg="1.5" className="header-text price">
            Price
          </IonCol>
        </IonRow>
      )}

      {orders?.length > 0 ? (
        orders.map((order, i) => <OrderItem key={order.id} order={order} onClickOrder={props.onClickOrder} />)
      ) : (
        <NoItem message="There is not any history of orders yet!" />
      )}
    </IonGrid>
  );
};

export default OrderHistoryItems;
