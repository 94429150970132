import {IonPage, IonContent, IonImg, IonButton, IonRouterLink} from '@ionic/react';
import './styles.scss';
import logo from 'src/assets/images/logo.png';

interface IProps {
  goToOnboarding: () => void;
}

const MobileScreen = (props: IProps) => {
  return (
    <IonPage id="welcome-page-mobile">
      <IonContent fullscreen scrollY={false}>
        <div className="body">
          <div className="logo-container">
            <IonImg src={logo} alt="logo" />
            <h2 className="ion-text-center title">Empower your Store to deliver locally.</h2>
          </div>

          <div className="btn-container">
            <IonButton className="btn-primary" expand="block" onClick={props.goToOnboarding}>
              Get Started
            </IonButton>

            <p className="ion-text-center footer-text">
              Already have and Account? <IonRouterLink routerLink="/auth/login">Sign In</IonRouterLink>
            </p>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MobileScreen;
