import {IonSelect} from '@ionic/react';
import './styles.scss';
import {IQueryObject} from 'src/interfaces/item';

import {mapSortSelectionToSortValues} from 'src/utils/helpers';
import SortSelectOption from '../components/SortSelectOption';

interface IProps {
  sortObject: IQueryObject;
  selectSort: (e: any) => void;
  onSort: () => void;
  onClose: () => void;
}

const DesktopScreen = (props: IProps) => {
  const sortButtons = Array(7)
    .fill(false)
    .map((item, index, array) => {
      const currentSortObject = mapSortSelectionToSortValues(index + 1);
      return (
        currentSortObject.sortDir === props.sortObject.sortDir &&
        currentSortObject.order === props.sortObject.order
      );
    });

  return (
    <IonSelect
      id="item-sorting-desktop"
      className="select-sort"
      value={sortButtons.findIndex((isSelected) => isSelected) + 1}
      interface="popover"
      onIonChange={(e) => {
        props.selectSort(e.detail.value);
      }}>
      {sortButtons.map((isSelected, index) => (
        <SortSelectOption key={index} selected={isSelected} sortSelection={index + 1} />
      ))}
    </IonSelect>
  );
};

export default DesktopScreen;
