/* eslint-disable react-hooks/exhaustive-deps */
import {IonPage, IonContent, IonImg, IonLabel, isPlatform} from '@ionic/react';
import './styles.scss';
import {SplashScreen} from '@capacitor/splash-screen';
import {RouteComponentProps} from 'react-router';
import {useAppDispatch, useAppSelector, useEffectOnce} from 'src/hooks';
import {getItem} from 'src/utils/storage';
import {STORAGE} from 'src/enums/storage';
import {setMenuAvailable} from 'src/store/authSlice';
import logo from 'src/assets/images/logo.png';

interface IProps extends RouteComponentProps {}

const Splash = ({history}: IProps) => {
  const dispatch = useAppDispatch();
  const {token} = useAppSelector((state) => state.auth);
  const {isDesktop, isNative} = useAppSelector((state) => state.platform);
  const isMobileWeb = isPlatform('mobileweb');

  const checkUserToken = async () => {
    const hideWelcome = await getItem(STORAGE.HIDE_WELCOME);
    if (token) {
      history.replace('/orders');
    } else {
      if (hideWelcome || isNative) history.replace('auth/login');
      else history.replace('/welcome');
    }
  };

  useEffectOnce(() => {
    if (!isMobileWeb) SplashScreen.hide();
    dispatch(setMenuAvailable(false));
    if (isDesktop || isMobileWeb) {
      checkUserToken();
    } else {
      setTimeout(() => {
        checkUserToken();
      }, 4000);
    }
  });

  return (
    <>
      {!(isDesktop || isMobileWeb) && (
        <IonPage id="splash-screen">
          <IonContent fullscreen scrollY={false}>
            <div className="logo-container">
              <IonImg src={logo} alt="logo" />
              <IonLabel>Loading..</IonLabel>
            </div>
            <div className="loading-container">
              <div className="loader">
                <div className="filled"></div>
              </div>
            </div>
          </IonContent>
        </IonPage>
      )}
    </>
  );
};

export default Splash;
