import {useState} from 'react';
import {IonModal, IonButton, IonIcon, IonFooter, IonItem, IonLabel, IonRadio, IonRadioGroup} from '@ionic/react';
import './styles.scss';
import {closeOutline} from 'ionicons/icons';

import Header from 'src/components/Header';
import BulkEditConfirmation from 'src/components/modals/BulkEdit/Confirmation';
import {useAppSelector} from 'src/hooks';

interface IProps {
  setShowEditModal: (e: boolean) => void;
  setShowCategoryModal: (e: boolean) => void;
  patchInventory: (data: any, is_archive: boolean, active: boolean) => void;
  showCategoryModal: boolean;
  isInStock: boolean;
}

const BulkEditCategoryModal = (props: IProps) => {
  const [selectedCategory, setSelectedCategory] = useState(null);
  const {categories} = useAppSelector((state) => state.category);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  const onConfirm = () => {
    props.patchInventory({category_id: selectedCategory}, false, props.isInStock);
    props.setShowCategoryModal(false);
    props.setShowEditModal(false);
    setSelectedCategory(null);
  };

  return (
    <>
      <IonModal
        id="category-option"
        isOpen={props.showCategoryModal}
        showBackdrop
        onDidDismiss={() => {
          if(!showConfirmationModal){
            setSelectedCategory(null);
          }
          props.setShowCategoryModal(false);
        }}
        >
        <div className="modal-content-container">
          <div className="modal-content">
            <Header
              border
              noBackground
              title="Category"
              rightButton={
                <IonIcon
                  onClick={() => {
                    setSelectedCategory(null);
                    props.setShowCategoryModal(false);
                  }}
                  icon={closeOutline}></IonIcon>
              }
            />

            <div className="modal-body">
              <IonRadioGroup
                className="category-radio-group"
                value={selectedCategory}
                onIonChange={(e) => {
                  setSelectedCategory(e.detail.value);
                }}>
                {categories.map((category: any, index) => {
                  return (
                    <IonItem key={index}>
                      <IonLabel>{category.name}</IonLabel>
                      <IonRadio mode="md" slot="start" value={category.id} />
                    </IonItem>
                  );
                })}
              </IonRadioGroup>
            </div>
            <IonFooter>
              <div className="action">
                <IonButton
                  className="action-btn cancel-btn"
                  mode="ios"
                  fill="clear"
                  color="dark"
                  onClick={() => {
                    setSelectedCategory(null);
                    props.setShowCategoryModal(false);
                  }}>
                  Cancel
                </IonButton>
                <IonButton
                  disabled={selectedCategory ? false : true}
                  className="action-btn"
                  mode="ios"
                  fill="solid"
                  color="primary"
                  expand="block"
                  onClick={async () => {
                    props.setShowCategoryModal(false);
                    setShowConfirmationModal(true);
                  }}>
                  Save edit
                </IonButton>
              </div>
            </IonFooter>
          </div>
        </div>
      </IonModal>
      <BulkEditConfirmation
        showConfirmationModal={showConfirmationModal}
        setShowConfirmationModal={setShowConfirmationModal}
        setShowEditModal={props.setShowEditModal}
        setPreviousModal={props.setShowCategoryModal}
        onConfirm={onConfirm}
        isInStock={props.isInStock}
      />
    </>
  );
};

export default BulkEditCategoryModal;
