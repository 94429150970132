import axios from 'axios';
import {store} from 'src/store';
import {showAlert} from 'src/store/alertSlice';
import {hideLoading} from 'src/store/loadingSlice';
import firebaseCrashlytics from 'src/utils/firebase-crashlytics';
import {IRequestParams} from 'src/interfaces/network';
import AnalyticsHelper from 'src/utils/segment';
import {STORAGE} from 'src/enums/storage';
import {getItem} from 'src/utils/storage';

export const axiosObj = axios.create();

export const makeRequest = ({
  url,
  method = 'GET',
  headers,
  body,
  params,
  isMicroservice = false,
  isDev = false,
}: IRequestParams) => {
  let BASE_URL = isMicroservice
    ? process.env.REACT_APP_MICROSERVICE_BASE_URL || ''
    : process.env.REACT_APP_API_BASE_URL || '';
  if (isDev) BASE_URL = process.env.REACT_APP_MICROSERVICE_DEV_ENV_BASE_URL || '';
  const URL = BASE_URL + url;

  return new Promise(async (resolve, reject) => {
    const token = await getItem(STORAGE.TOKEN);
    const idToken = await getItem(STORAGE.ID_TOKEN);
    const {store: userStore, auth} = store.getState();

    let updatedHeaders: any = {...headers};
    if (token) updatedHeaders = {...updatedHeaders, Authorization: token};
    if (!!userStore.id) updatedHeaders = {...updatedHeaders, store_id: userStore.id};
    if (isMicroservice && idToken) updatedHeaders = {...updatedHeaders, idToken: idToken};

    if (auth.internetAvailable) {
      axiosObj({url: URL, method, headers: updatedHeaders, data: body, params})
        .then((response: any) => {
          firebaseCrashlytics.log(JSON.stringify({url: URL, method, body, params, response: response.data}));
          resolve(response);
        })
        .catch((error: any) => {
          firebaseCrashlytics.log(
            JSON.stringify({url: URL, method, body, params, errorResponse: error?.response?.data}),
          );
          // Tracking the API error.
          AnalyticsHelper.trackError({
            message: error?.response?.data?.message ? error?.response?.data?.message : error?.message,
            failedService: URL,
            errCode: error?.response?.status,
          });

          if (error?.response?.status === 401) {
            store.dispatch(
              showAlert({
                heading: 'Alert',
                message: 'Your current session is expired please login again',
                disableBackdropDismiss: true,
              }),
            );
          }
          // Handle error here.
          reject(error);
        });
    } else {
      store.dispatch(
        showAlert({
          heading: 'Error',
          message: 'Internet not available. Try again later!',
          disableBackdropDismiss: true,
        }),
      );
      store.dispatch(hideLoading());
    }
  });
};
