import {IonIcon, IonSelectOption} from '@ionic/react';
import {List, DollarSign, Calendar} from 'react-feather';
import './styles.scss';

import {mapSortSelectionToIcon, mapSortSelectionToSortOutput} from 'src/utils/helpers';
interface IProps {
  selected: boolean;
  sortSelection: number;
}

const SortSelectOption = (props: IProps) => {
  return (
    <IonSelectOption value={props.sortSelection}>
      {[2, 3].includes(props.sortSelection) ? (
        <IonIcon icon={mapSortSelectionToIcon(props.sortSelection)} />
      ) : props.sortSelection === 1 ? (
        <List />
      ) : [4, 5].includes(props.sortSelection) ? (
        <DollarSign />
      ) : [6, 7].includes(props.sortSelection) ? (
        <Calendar />
      ) : (
        <IonIcon icon={mapSortSelectionToIcon(props.sortSelection)} />
      )}
      <p className={`description ${props.selected ? 'selected' : ''}`}>
        {mapSortSelectionToSortOutput(props.sortSelection)}
      </p>
    </IonSelectOption>
  );
};

export default SortSelectOption;
