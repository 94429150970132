import {IonCard, IonCardHeader, IonCardContent, IonImg, IonButton} from '@ionic/react';
import './styles.scss';
import TextWithLines from 'src/components/TextWithLines';

interface IProps {
  img: any;
  title: string;
  desc: string;
  startApp?: () => void;
  openHelp?: () => void;
}

const Slide = (props: IProps) => {
  return (
    <IonCard id="slide">
      <IonCardHeader>
        <IonImg src={props.img} alt="" />
      </IonCardHeader>

      <IonCardContent>
        <div className="details-container">
          <h2>{props.title}</h2>
          <p>{props.desc}</p>
        </div>

        <div>
          {props.startApp ? (
            <IonButton className="btn-primary" expand="block" onClick={props.startApp}>
              Go to Sign In
            </IonButton>
          ) : (
            <>
              <TextWithLines text="any questions?" />
              <IonButton className="btn-secondary" expand="block" onClick={props.openHelp}>
                Ask us Anything
              </IonButton>
            </>
          )}
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default Slide;
