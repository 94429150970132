/* eslint-disable react-hooks/exhaustive-deps */
import {IonPage, IonContent, IonBackButton, IonButton, IonFooter, IonIcon} from '@ionic/react';
import './styles.scss';
import {RouteComponentProps} from 'react-router';
import {useForm} from 'react-hook-form';
import {closeOutline} from 'ionicons/icons';
import {IUserDetailsFormInput} from 'src/interfaces/form';
import {useAppDispatch, useAppSelector} from 'src/hooks';
import {updateProfile} from 'src/store/authSlice';
import {maskValue, unMaskValue} from 'src/utils/helpers';
import {INPUT_MASK} from 'src/enums/input_mask';
import {RULES} from 'src/utils/constants';
import Header from 'src/components/Header';
import Input from 'src/components/Input';
import AnalyticsHelper from 'src/utils/segment';
import arrowBack from 'src/assets/images/arrow-back-black.svg';
import * as PERMISSIONS from 'src/utils/permissions';

interface IProps extends RouteComponentProps {
  setShowUserDetails?: (show: boolean) => void;
}

const UserDetails = ({setShowUserDetails}: IProps) => {
  const dispatch = useAppDispatch();

  const {user, menuIsOpen} = useAppSelector((state) => state.auth);
  const {isDesktop, isTablet} = useAppSelector((state) => state.platform);
  const canEditInput = user?.role.permissions?.some((item: any) => item.name === PERMISSIONS.EDIT_ACCOUNT_DETAILS);

  const {control, handleSubmit} = useForm<IUserDetailsFormInput>({
    mode: 'onSubmit',
    defaultValues: {
      firstName: user?.firstName || '',
      lastName: user?.lastName || '',
      phoneNo: maskValue(INPUT_MASK.DISPLAY_PHONE, user?.phone || ''),
    },
  });

  const updateDetails = (formData: IUserDetailsFormInput) => {
    const data = {
      id: user?.id,
      firstName: formData.firstName,
      lastName: formData.lastName,
      phone: `+1${unMaskValue(INPUT_MASK.PHONE, formData.phoneNo)}`,
    };
    dispatch(updateProfile(data));
    AnalyticsHelper.identifyProfile({...data, email: user?.email});
    AnalyticsHelper.groupStore();
  };

  const onClose = () => {
    if (setShowUserDetails) setShowUserDetails(false);
  };

  return (
    <IonPage id="user-details-page" className={`${isTablet && !menuIsOpen ? 'menu-close' : ''} snow-background`}>
      <IonContent fullscreen scrollY={false} className={`${isTablet ? 'page-container' : ''}`}>
        <Header
          noBackground
          title="Personal Details"
          leftButton={!isDesktop && <IonBackButton defaultHref="/settings" text="" icon={arrowBack} />}
          rightButton={
            isDesktop && (
              <IonButton onClick={onClose}>
                <IonIcon src={closeOutline} />
              </IonButton>
            )
          }
        />

        <div className="body with-footer">
          <Input
            type="text"
            name="firstName"
            label="First Name"
            placeholder="First Name"
            control={control}
            rules={RULES.required}
            disabled={!canEditInput}
          />

          <Input
            type="text"
            name="lastName"
            label="Last Name"
            placeholder="Last Name"
            control={control}
            rules={RULES.required}
            disabled={!canEditInput}
          />

          <Input
            type="text"
            inputmode="numeric"
            name="phoneNo"
            label="Phone Number"
            placeholder="Phone Number"
            control={control}
            mask={INPUT_MASK.PHONE}
            rules={RULES.phoneNumber}
            disabled={!canEditInput}
          />
        </div>

        <IonFooter>
          <IonButton className="btn-dark" expand="block" onClick={handleSubmit(updateDetails)} disabled={!canEditInput}>
            Save Changes
          </IonButton>
        </IonFooter>
      </IonContent>
    </IonPage>
  );
};

export default UserDetails;
