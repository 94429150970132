import {useEffect, useState} from 'react';
import {IonButton, IonFooter, IonIcon, IonInput, IonSpinner} from '@ionic/react';
import './styles.scss';
import {barcodeOutline, closeOutline, searchOutline, swapHorizontalOutline} from 'ionicons/icons';
import SubstitutionListItem from 'src/pages/Orders/components/SubstitutionListItem';
import ItemImage from 'src/components/ItemImage';
import {INVENTORY_STATUS} from 'src/enums/item_status';
import {PARTNER} from 'src/enums/partner';
import {withScanner} from 'src/hoc/WithScanner';
import {ISubstituteListItem} from 'src/interfaces/order';
import {useAppSelector} from 'src/hooks';
import inventoryService from 'src/services/inventory';

interface SubstituteOrderItemProps {
  upc: string;
  ean: string;
  itemToSubstitute: any;
  orderPartner: PARTNER;
  setShowSubstitutionsList: (e: boolean) => void;
  onSubstituteItem: (payload: ISubstituteListItem) => void;
  openScanner: () => void;
}

const SubstitutionsList = ({
  upc,
  ean,
  itemToSubstitute,
  orderPartner,
  setShowSubstitutionsList,
  onSubstituteItem,
  openScanner,
}: SubstituteOrderItemProps) => {
  const {queryObject} = useAppSelector((state) => state.inventory);
  const selectedStore = useAppSelector((state) => state.store);
  const {isDesktop} = useAppSelector((state) => state.platform);

  const [substituteItem, setSubstituteItem] = useState<any>('');
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [items, setItems] = useState<any>([]);
  const [recommendationsCount, setRecommendationsCount] = useState(0);

  const fetchItems = async (offset = 0) => {
    setLoading(true);
    try {
      const params = {
        ...queryObject,
        minPrice: 0,
        maxPrice: itemToSubstitute.itemTotalPrice,
      };
      // Only get items in the same category in first call.
      if (!searchTerm) {
        params.categories = [itemToSubstitute.category?.id];
      }
      const count = await inventoryService.getInventoryCount(
        {...params, searchString: searchTerm.trimStart()},
        selectedStore,
        orderPartner,
      );
      // Subtracting one because we already have the item to substitute in the list.
      const updatedCount = Number(count.for_sale) - 1 || 0;
      setRecommendationsCount(updatedCount >= 0 ? updatedCount : 0);

      const recommendedItems = await inventoryService.searchInventory(
        searchTerm.trimStart(),
        INVENTORY_STATUS.IN_STOCK,
        offset,
        selectedStore,
        params,
        orderPartner,
      );
      if (offset > 0) {
        setItems([...items, ...recommendedItems]);
      } else {
        setItems(recommendedItems);
      }
    } finally {
      setLoading(false);
    }
  };

  const fetchNextItems = (e: any) => {
    const {scrollHeight, scrollTop, clientHeight} = e.target;
    const loadMore = scrollHeight - scrollTop <= clientHeight + 10;

    if (loadMore && !loading && items.length < recommendationsCount) {
      fetchItems(items.length);
    }
  };

  const onSearch = (e: any) => {
    setSearchTerm(e.detail.value);
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => fetchItems(), 750);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    setSearchTerm(upc || ean);
  }, [upc, ean]);

  return (
    <div id="substitute-list-items" className="modal-content">
      <div className="content-header">
        <div className="items-container">
          <div className="item-card">
            <ItemImage category={itemToSubstitute.categoryName} image={itemToSubstitute.image} />
            <p>{itemToSubstitute.itemName}</p>
          </div>
          <IonIcon className={substituteItem ? 'with-sub-item' : ''} icon={swapHorizontalOutline} />
          <div className={`item-card ${substituteItem ? 'selected' : ''}`}>
            {substituteItem && <ItemImage category={substituteItem?.category?.name} image={substituteItem.image} />}
            {substituteItem ? <p>{substituteItem.name}</p> : <p className="no-selection">No Selection</p>}
            {substituteItem && (
              <div onClick={() => setSubstituteItem('')}>
                <IonIcon icon={closeOutline} />
              </div>
            )}
          </div>
        </div>
        <div className="inputs-container">
          <div className="search-bar">
            <IonIcon icon={searchOutline} />
            <IonInput value={searchTerm} clearInput={true} placeholder="Search" onIonChange={onSearch} />
          </div>
          {/* {!isDesktop && (
            <div className="scan-item" onClick={openScanner}>
              <IonIcon icon={barcodeOutline} />
              <p>Scan Item</p>
            </div>
          )} */}
        </div>
      </div>

      {!searchTerm && <p className="recommendations">Recommendations {recommendationsCount}</p>}
      <div className="items-list" onScroll={fetchNextItems}>
        {items.map((obj: any) =>
          obj.id !== itemToSubstitute.storeItemId ? (
            <SubstitutionListItem
              key={obj.id}
              item={obj}
              selected={obj?.id === substituteItem?.id}
              onClick={(item, clear?: boolean) => {
                if (clear) setSubstituteItem('');
                else setSubstituteItem(item);
              }}
            />
          ) : null,
        )}
      </div>

      {loading && <IonSpinner color="primary" />}

      <div className="no-item">
        {/* Checking for length 1 or less because list will have one item (item to substitute) */}
        {!items.length && !loading && <p className="msg-text">No item found</p>}
        {!items.length && searchTerm && !loading && (
          <p className="clear" onClick={() => setSearchTerm('')}>
            Clear Search
          </p>
        )}
      </div>

      <IonFooter>
        <IonButton
          className="btn-dark"
          expand="block"
          mode="ios"
          fill="clear"
          disabled={!substituteItem}
          onClick={() => {
            // TODO: add callback for item substitution API call
            onSubstituteItem({
              orderItemId: itemToSubstitute.id,
              substituteItem: {...substituteItem},
              substituteItemQuantity: itemToSubstitute.quantity,
            });
            setShowSubstitutionsList(false);
          }}>
          Substitute item
        </IonButton>
      </IonFooter>
    </div>
  );
};

export default withScanner(SubstitutionsList);
