export enum INVENTORY_SORTING_TYPE {
  category = 'category',
  name = 'name',
  price = 'price',
  createdAt = 'createdAt',
}

export enum INVENTORY_SORTING_DIRECTION {
  DESC = 'DESC',
  ASC = 'ASC',
}

export enum PRICE_UPDATE_OPTION {
  new_price = 'New Price',
  percentage = 'Percentage',
  adjust_price = 'Adjust Price',
}

export enum PRICE_VALIDATE_OPTION {
  'New Price' = 'fixed',
  'Percentage' = 'percentage',
  'Adjust Price' = 'relative',
}
