import {useState, useEffect} from 'react';
import {IonPage, IonContent, IonSegment, IonSegmentButton, IonLabel} from '@ionic/react';
import './styles.scss';
import axios from 'axios';
import moment from 'moment';
import {useAppSelector} from 'src/hooks';
import CommonHeader from 'src/components/CommonHeader';
import ExploDashboard from 'src/pages/Insights/components/dashboard';
import ExploInventory from 'src/pages/Insights/components/inventory';
import ExploReports from 'src/pages/Insights/components/reports';
import ExploOrders from 'src/pages/Insights/components/orders';
import ExploPayouts from 'src/pages/Insights/components/payout';
import * as PERMISSIONS from 'src/utils/permissions';
import {checkPermission} from 'src/utils/helpers';
import {getItem, setItem} from 'src/utils/storage';

const dispatchCustomEvent = (name: string, value: any) => {
  window.dispatchEvent(new CustomEvent('updateExploDashboardVariable', {detail: {varName: name, value}}));
};

const Insights = () => {
  const [ready, setReady] = useState(false);
  const [activeTab, setActiveTab] = useState('overview');
  const [token, setToken] = useState('');

  const {company} = useAppSelector((state) => state.store);
  const {user, menuIsOpen} = useAppSelector((state) => state.auth);

  const userPermissions = user?.role.permissions || [];

  const canViewOverview = checkPermission(userPermissions, PERMISSIONS.VIEW_INSIGHTS_OVERVIEW);
  const canViewOrders = checkPermission(userPermissions, PERMISSIONS.VIEW_INSIGHTS_ORDERS);
  const canViewInventory = checkPermission(userPermissions, PERMISSIONS.VIEW_INSIGHTS_INVENTORY);
  const canViewReports = checkPermission(userPermissions, PERMISSIONS.VIEW_INSIGHTS_REPORTS);
  const canViewPayout = checkPermission(userPermissions, PERMISSIONS.VIEW_INSIGHTS_PAYOUTS);

  const fetchJWT = async (dashboardId: string, key: string) => {
    const token = await getItem(`${key}Token`);
    const lastFetched = await getItem(`${key}TokenLastFetched`);
    const isExpired = moment().diff(lastFetched, 'hours') >= 24;

    if (!token || !lastFetched || isExpired) {
      try {
        const response = await axios.post(
          'https://api.explo.co/api/generate_jwt/',
          {
            provided_id: company?.id,
            dashboard_embed_id: dashboardId,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              'Explo-Authorization': `Token ${process.env.REACT_APP_EXPLO_AUTH_TOKEN}`,
            },
          },
        );
        const jwt = response.data.jwt;
        setItem(`${key}Token`, jwt);
        setItem(`${key}TokenLastFetched`, moment().format());
        return jwt;
      } catch (error: any) {
        console.log('Error in fetching jwt token', error?.message);
      }
    } else {
      return token;
    }
  };

  const onTabChange = (e: any) => {
    setActiveTab(e.target.value);
  };

  useEffect(() => {
    (async () => {
      const headers = {
        'Content-Type': 'application/json',
        'Explo-Authorization': `Token ${process.env.REACT_APP_EXPLO_AUTH_TOKEN}`,
      };
      try {
        const response: any = await axios.post(
          'https://api.explo.co/api/create_customer/',
          {
            name: company?.name || '',
            provided_id: company?.id || '',
            is_demo_group: false,
          },
          {headers: headers},
        );
        if (response) {
          setToken(response.data.customer.token);
        }
      } catch (e: any) {
        console.log('Error in fetching report builder token');
      }
    })();

    const script = document.createElement('script');
    script.src = 'https://embed.explo.co/bundle.js';
    script.async = false;
    script.crossOrigin = 'anonymous';
    document.body.appendChild(script);
    setReady(true);
  }, []);

  return (
    <IonPage id="insights-page" className="snow-background">
      <IonContent fullscreen className="page-container">
        <CommonHeader className="title-left" title="Analytics" menuIsOpen={menuIsOpen} />

        <IonSegment mode="md" scrollable value={activeTab} onIonChange={onTabChange}>
          {canViewOverview && (
            <IonSegmentButton value="overview">
              <IonLabel>Overview</IonLabel>
            </IonSegmentButton>
          )}
          {canViewOrders && (
            <IonSegmentButton value="operations">
              <IonLabel>Operations</IonLabel>
            </IonSegmentButton>
          )}
          {canViewInventory && (
            <IonSegmentButton value="menu-performance">
              <IonLabel>Menu Performance</IonLabel>
            </IonSegmentButton>
          )}
          {company ? (
            <>
              {canViewReports && (
                <IonSegmentButton value="reports">
                  <IonLabel>Reports</IonLabel>
                </IonSegmentButton>
              )}
              {canViewPayout && (
                <IonSegmentButton value="payouts">
                  <IonLabel>Payouts</IonLabel>
                </IonSegmentButton>
              )}
            </>
          ) : null}
        </IonSegment>

        {ready && (
          <div className="body">
            {activeTab === 'overview' && <ExploDashboard fetchToken={fetchJWT} dispatchEvent={dispatchCustomEvent} />}
            {activeTab === 'operations' && <ExploOrders fetchToken={fetchJWT} dispatchEvent={dispatchCustomEvent} />}
            {activeTab === 'menu-performance' && (
              <ExploInventory fetchToken={fetchJWT} dispatchEvent={dispatchCustomEvent} />
            )}
            {activeTab === 'reports' && <ExploReports user={user} token={token} companyId={company?.id} />}
            {activeTab === 'payouts' && <ExploPayouts user={user} token={token} companyId={company?.id} />}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Insights;
