import './styles.scss';
import {IonButton, IonContent, IonIcon, IonPage} from '@ionic/react';
import {arrowBack, closeOutline} from 'ionicons/icons';
import Header from 'src/components/Header';
import {useState} from 'react';
import {PRICE_UPDATE_OPTION, PRICE_VALIDATE_OPTION} from 'src/enums/inventory_sorting';
import PriceInput from 'src/components/PriceInput';
import {setIsValidInputPrice} from 'src/store/inventorySlice';
import {useAppDispatch} from 'src/hooks';

interface IProps {
  setShowEditModal: (e: boolean) => void;
  setShowPriceModal: (e: boolean) => void;
  patchInventory: (a: any, b: boolean, c: boolean) => void;
  isInStock: boolean;
  validatePriceInput: (data: any, active: boolean) => void;
}

const PriceOptionModal = (props: IProps) => {
  const dispatch = useAppDispatch();
  const [priceOption, setPriceOption] = useState(PRICE_UPDATE_OPTION.new_price);
  const [isValidPrice, setIsValidPrice] = useState(true);
  const [newPrice, setNewPrice] = useState<string | number>('');
  const [isPositiveSelect, setIsPositiveSelect] = useState(true);

  const [showConfirmationOption, setShowConfirmationOption] = useState(false);

  return (
    <IonPage id="price-option-mobile">
      <IonContent fullscreen scrollY={false}>
        <Header
          border
          noBackground
          title="Price"
          leftButton={
            <IonIcon
              onClick={() => {
                setIsValidPrice(true);
                dispatch(setIsValidInputPrice(true));
                setNewPrice('');
                setIsPositiveSelect(true);
                setPriceOption(PRICE_UPDATE_OPTION.new_price);
                props.setShowPriceModal(false);
              }}
              icon={arrowBack}></IonIcon>
          }
          rightButton={
            <IonIcon
              onClick={() => {
                setIsValidPrice(true);
                dispatch(setIsValidInputPrice(true));
                setNewPrice('');
                setIsPositiveSelect(true);
                setPriceOption(PRICE_UPDATE_OPTION.new_price);
                props.setShowPriceModal(false);
                props.setShowEditModal(false);
              }}
              icon={closeOutline}></IonIcon>
          }
        />
  {!showConfirmationOption ?
        <>
        <div className="body">
          <div className="label">
            <p>Type of change</p>
          </div>
          <div className="price-option-container">
            <div
              className={`price-option new-price ${
                priceOption === PRICE_UPDATE_OPTION.new_price
                  ? 'selected'
                  : priceOption === PRICE_UPDATE_OPTION.adjust_price
                  ? 'adjust-price-selected'
                  : ''
              }`}
              onClick={() => {
                if (priceOption !== PRICE_UPDATE_OPTION.new_price) {
                  setIsValidPrice(true);
                  dispatch(setIsValidInputPrice(true));
                  setNewPrice('');
                  setIsPositiveSelect(true);
                  setPriceOption(PRICE_UPDATE_OPTION.new_price);
                }
              }}>
              <p>New Price</p>
            </div>
            <div
              className={`price-option percentage ${
                priceOption === PRICE_UPDATE_OPTION.percentage ? 'selected' : 'unselected'
              }`}
              onClick={() => {
                if (priceOption !== PRICE_UPDATE_OPTION.percentage) {
                  setIsValidPrice(true);
                  dispatch(setIsValidInputPrice(true));
                  setNewPrice('');
                  setIsPositiveSelect(true);
                  setPriceOption(PRICE_UPDATE_OPTION.percentage);
                }
              }}>
              <p>Percentage</p>
            </div>
            <div
              className={`price-option adjust-price ${
                priceOption === PRICE_UPDATE_OPTION.adjust_price
                  ? 'selected'
                  : priceOption === PRICE_UPDATE_OPTION.new_price
                  ? 'new-price-selected'
                  : ''
              }`}
              onClick={() => {
                if (priceOption !== PRICE_UPDATE_OPTION.adjust_price) {
                  setIsValidPrice(true);
                  dispatch(setIsValidInputPrice(true));
                  setNewPrice('');
                  setIsPositiveSelect(true);
                  setPriceOption(PRICE_UPDATE_OPTION.adjust_price);
                }
              }}>
              <p>Adjust Price</p>
            </div>
          </div>
          {priceOption === PRICE_UPDATE_OPTION.new_price && (
            <PriceInput
              type={PRICE_UPDATE_OPTION.new_price}
              isValidPrice={isValidPrice}
              setIsValidPrice={setIsValidPrice}
              newPrice={newPrice}
              setNewPrice={setNewPrice}
              validatePriceInput={props.validatePriceInput}
              isInStock={props.isInStock}
              isPositiveSelect={isPositiveSelect}
              setIsPositiveSelect={setIsPositiveSelect}></PriceInput>
          )}
          {priceOption === PRICE_UPDATE_OPTION.percentage && (
            <PriceInput
              type={PRICE_UPDATE_OPTION.percentage}
              isValidPrice={isValidPrice}
              setIsValidPrice={setIsValidPrice}
              newPrice={newPrice}
              setNewPrice={setNewPrice}
              validatePriceInput={props.validatePriceInput}
              isInStock={props.isInStock}
              isPositiveSelect={isPositiveSelect}
              setIsPositiveSelect={setIsPositiveSelect}></PriceInput>
          )}
          {priceOption === PRICE_UPDATE_OPTION.adjust_price && (
            <PriceInput
              type={PRICE_UPDATE_OPTION.adjust_price}
              isValidPrice={isValidPrice}
              setIsValidPrice={setIsValidPrice}
              newPrice={newPrice}
              setNewPrice={setNewPrice}
              validatePriceInput={props.validatePriceInput}
              isInStock={props.isInStock}
              isPositiveSelect={isPositiveSelect}
              setIsPositiveSelect={setIsPositiveSelect}></PriceInput>
          )}
        </div>
        <div className="action-div">
          <IonButton
            disabled={!isValidPrice || newPrice === '' || newPrice === 0}
            className="action-btn"
            mode="ios"
            fill="solid"
            color="primary"
            expand="block"
            onClick={() => {
              setShowConfirmationOption(true);
            }}>
            Save edit
          </IonButton>
        </div>
        </>
        :
        <div className="confirmation-div">
            <div className="label">Are you sure you want to Update these items?</div>
            <div className="action">
              <IonButton
                className="btn-outline"
                onClick={() => {
                  setShowConfirmationOption(false);
                }}>
                No
              </IonButton>
              <IonButton
                className="btn-primary"
                onClick={() => {
                  props.patchInventory(
                    {
                      price: isPositiveSelect ? Number(newPrice) : Number(newPrice) * -1,
                      action_type: PRICE_VALIDATE_OPTION[priceOption],
                    },
                    false,
                    props.isInStock,
                  );
                  setIsValidPrice(true);
                  dispatch(setIsValidInputPrice(true));
                  setNewPrice('');
                  setIsPositiveSelect(true);
                  setPriceOption(PRICE_UPDATE_OPTION.new_price);
                  props.setShowPriceModal(false);
                  props.setShowEditModal(false);
                }}>
                Yes
              </IonButton>
            </div>
          </div>
}
      </IonContent>
    </IonPage>
  );
};

export default PriceOptionModal;
