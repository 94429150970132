import {combineReducers, configureStore} from '@reduxjs/toolkit';
import loadingSlice from './loadingSlice';
import alertSlice from './alertSlice';
import authSlice from './authSlice';
import storeSlice from './storeSlice';
import userSlice from './userSlice';
import inventorySlice from './inventorySlice';
import orderSlice from './orderSlice';
import itemSlice from './itemSlice';
import categorySlice from './categorySlice';
import platformSlice from './platformSlice';
import partnerSlice from './partnerSlice';
import printerSlice from './printerSlice';

const combinedReducer = combineReducers({
  loading: loadingSlice,
  alert: alertSlice,
  auth: authSlice,
  store: storeSlice,
  user: userSlice,
  inventory: inventorySlice,
  order: orderSlice,
  item: itemSlice,
  category: categorySlice,
  platform: platformSlice,
  partner: partnerSlice,
  printer: printerSlice,
});

const rootReducer = (state: any, action: any) => {
  if (action.type === 'auth/logoutUser') {
    state = {} as RootState;
  }
  return combinedReducer(state, action);
};

export const store = configureStore({
  reducer: rootReducer,
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
