/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect} from 'react';
import {IonPage, IonContent, IonBackButton, IonButton, IonFooter, IonIcon} from '@ionic/react';
import './styles.scss';
import {RouteComponentProps} from 'react-router';
import {closeOutline} from 'ionicons/icons';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import isSameOrAfter from 'dayjs/plugin/isSameOrAfter';
import Header from 'src/components/Header';
import NewMenu from 'src/components/NewMenu';
import {useAppDispatch, useAppSelector} from 'src/hooks';
import {showLoading, hideLoading} from 'src/store/loadingSlice';
import {addMenu, editMenu, getMenu} from 'src/store/storeSlice';
import {setNewMenuId} from 'src/store/inventorySlice';
import {showAlert} from 'src/store/alertSlice';
import menuService from 'src/services/menu';
import {DEFAULT_HOUR, DEFAULT_HOURS} from 'src/utils/constants';
import {ITiming} from 'src/interfaces/store';
import arrowBack from 'src/assets/images/arrow-back-black.svg';
import {checkPermission} from 'src/utils/helpers';
import * as PERMISSIONS from 'src/utils/permissions';

dayjs.extend(customParseFormat);
dayjs.extend(isSameOrAfter);

interface IProps extends RouteComponentProps {
  location: any;
  setShowMenuDetail?: (show: boolean) => void;
  isStayForEdit?: boolean;
}

const Details = ({setShowMenuDetail, isStayForEdit, location, history}: IProps) => {
  const dispatch = useAppDispatch();

  const {isDesktop, isTablet} = useAppSelector((state) => state.platform);
  const {menuIsOpen} = useAppSelector((state) => state.auth);
  const {id: storeId} = useAppSelector((state) => state.store);

  const [timings, setTimings] = useState<ITiming[][]>(DEFAULT_HOURS);
  const [menuName, setMenuName] = useState('');
  const [validate, setValidate] = useState(false);
  const [isDefault, setIsDefault] = useState(false);
  const [menuDetailId, setMenuDetailId] = useState(location.state?.menuDetailId);

  const {user} = useAppSelector((state) => state.auth);
  const canEditMenu = checkPermission(user?.role.permissions, PERMISSIONS.EDIT_MENU);
  const canCreateMenu = checkPermission(user?.role.permissions, PERMISSIONS.CREATE_MENU);
  const canDeleteMenu = checkPermission(user?.role.permissions, PERMISSIONS.DELETE_MENU);

  const onClose = () => {
    if (setShowMenuDetail) setShowMenuDetail(false);
    else history.goBack();
  };

  const validateHours = (hours: ITiming[][]) => {
    let isValid = true;
    const updatedHours = hours.map((day) => day.map((hour) => ({...hour, error: ''})));
    for (let i = 0; i < updatedHours.length; i++) {
      const day = updatedHours[i];

      for (let j = 0; j < day.length; j++) {
        const hour = day[j];

        if (!hour.isClosed) {
          const openHour = dayjs(hour.openTime, 'HH:mm');
          const closeHour = dayjs(hour.closeTime, 'HH:mm');

          if (!hour.openTime || !hour.closeTime) {
            isValid = false;
            hour.error = 'Please enter open and close time';
          } else if (openHour.isSameOrAfter(closeHour) || !openHour.isValid() || !closeHour.isValid()) {
            isValid = false;
            hour.error = 'Invalid time range';
          }
        }
      }
    }
    setTimings(updatedHours);
    return isValid;
  };

  const updateMenu = async () => {
    setValidate(true);
    if (validateHours(timings)) {
      if (menuDetailId) {
        const data = timings.flat().map((obj) => ({
          id: obj.id,
          day_of_week: obj.dayOfWeek,
          open_time: obj.openTime,
          close_time: obj.closeTime,
          is_closed: obj.isClosed,
        }));
        dispatch(editMenu({data, menuName, menuId: menuDetailId})).then((menuDetail) => {
          if (menuDetail.payload?.success) {
            dispatch(getMenu());
          }
        });
      } else {
        const data = timings.flat().map((obj) => ({
          day_of_week: obj.dayOfWeek,
          open_time: obj.openTime,
          close_time: obj.closeTime,
          is_closed: obj.isClosed,
        }));
        dispatch(addMenu({data, menuName}))
          .unwrap()
          .then((menuDetail) => {
            if (menuDetail?.id) {
              setMenuDetailId(menuDetail.id);
              dispatch(setNewMenuId(menuDetail.id));
              if ((isDesktop && !isStayForEdit) || (!isDesktop && !location.state?.isStayForEdit)) {
                onClose();
              }
            }
          });
      }
    }
  };

  const deleteMenu = async () => {
    if (menuDetailId) {
      dispatch(showLoading());
      menuService
        .deleteMenu(storeId, menuDetailId)
        .then((_) => {
          dispatch(getMenu());
          dispatch(hideLoading());
          onClose();
        })
        .catch((error) => {
          dispatch(hideLoading());
          dispatch(
            showAlert({
              heading: 'Error',
              message: error?.message || 'Error occurred in rejecting this order.Please, try again later.',
            }),
          );
        });
    }
  };

  const getMenuDetail = async () => {
    if (menuDetailId) {
      dispatch(showLoading());
      menuService
        .getMenuDetailsByMenuId(storeId, menuDetailId)
        .then((response) => {
          if (response.menu_hours.length) {
            const dayHours = [];
            for (let i = 0; i < 7; i++) {
              const updatedHours = response.menu_hours.filter((hour) => hour.dayOfWeek === i);
              if (updatedHours.length > 0) {
                dayHours[i] = updatedHours;
              } else {
                dayHours[i] = [{...DEFAULT_HOUR, dayOfWeek: i}];
              }
            }
            setTimings(dayHours);
          }
          setMenuName(response.menu_name);
          setIsDefault(response.is_default);
          dispatch(hideLoading());
        })
        .catch((error) => {
          dispatch(hideLoading());
        });
    }
  };

  useEffect(() => {
    getMenuDetail();
  }, [menuDetailId]);

  return (
    <IonPage id="menu-detail-page" className={`${!menuIsOpen ? 'menu-close' : ''}`}>
      <IonContent fullscreen scrollY={false} className={`${isTablet ? 'page-container' : ''}`}>
        <Header
          noBackground
          leftButton={!isDesktop && <IonBackButton defaultHref="/store/manage" text="" icon={arrowBack} />}
          rightButton={
            isDesktop && (
              <IonButton onClick={onClose}>
                <IonIcon src={closeOutline} />
              </IonButton>
            )
          }
          title={menuDetailId ? 'Edit menu' : 'New menu'}
        />

        <div className="body with-footer">
          <NewMenu
            isDefault={isDefault}
            menuName={menuName}
            setMenuName={setMenuName}
            timings={timings}
            canEditMenu={canEditMenu}
            canDeleteMenu={canDeleteMenu}
            menuDetailId={menuDetailId}
            deleteMenu={deleteMenu}
            setTimings={setTimings}
            validate={validate}
            validateInputs={validateHours}
          />
        </div>

        <IonFooter>
          <IonButton
            className="btn-primary"
            expand="block"
            disabled={menuName === '' || !canCreateMenu || !canEditMenu}
            onClick={updateMenu}>
            {menuDetailId ? (
              'Save edit'
            ) : (
              <>
                <IonIcon className="icon-add" />
                Create Menu
              </>
            )}
          </IonButton>
        </IonFooter>
      </IonContent>
    </IonPage>
  );
};

export default Details;
