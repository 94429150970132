import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {showLoading, hideLoading} from 'src/store/loadingSlice';
import {showAlert} from 'src/store/alertSlice';
import storeService from 'src/services/store';
import {setStores, updateStoreList} from './authSlice';
import {RootState} from 'src/store';
import menuService from 'src/services/menu';
import {storeHoursErrorListToString} from 'src/utils/helpers';
import {IMenuList, IStorePauseItem, IStoreStatus, IStoreType} from 'src/interfaces/store';
import moment from 'moment';
import AnalyticsHelper from 'src/utils/segment';
import {PARTNER} from 'src/enums/partner';

interface IStore {
  id: string;
  name: string;
  email: string;
  phone: string;
  address: {id: string; line_1: string; city: string; state: string; zip: string};
  employeesCount: number;
  shippingAddress: {id: string; line_1: string; city: string; state: string; zip: string};
  company: {id: string; name: string} | null;
  isClosed: boolean;
  isDeactivated: boolean;
  isRBACStore: boolean;
  showGlobalItemsContent: boolean;
  showNewOrderModal: boolean;
  menuList: Array<IMenuList>;
  storeType: IStoreType;
  storePauseScheduleList: Array<IStorePauseItem>;
  storeStatus: IStoreStatus;
  companyId: string;
  createdAt: string;
}

export const getStoreEmployeesCount = createAsyncThunk('store/count', async (_, {dispatch, getState}) => {
  try {
    const {store: selectedStore} = getState() as RootState;
    if (selectedStore.id) {
      dispatch(showLoading());
      const response = await storeService.getEmployeesCount();
      dispatch(setEmployeesCount(response));
      dispatch(hideLoading());
    }
  } catch (error: any) {
    dispatch(hideLoading());
    dispatch(
      showAlert({
        heading: 'Unable to fetch data',
        message: error?.message || 'Some error occurred while fetching data. Please, try again later',
      }),
    );
  }
});

export const getStorePauseSchedules = createAsyncThunk(
  'store/storePauseSchedule',
  async (_, {dispatch, getState, fulfillWithValue, rejectWithValue}: any) => {
    try {
      dispatch(showLoading());
      const {store} = getState() as RootState;
      const response = await storeService.getPauseSchedules(store?.id, {
        current_local_date: moment().format('YYYY-MM-DD'),
        current_time: moment().format('hh:mm'),
      });
      dispatch(setStorePauseScheduleList(response));
      dispatch(hideLoading());
      return fulfillWithValue(response);
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(
        showAlert({
          heading: 'Unable to fetch data',
          message: error?.message || 'Some error occurred while fetching data. Please, try again later',
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const createInstentStorePause = createAsyncThunk(
  'store/createInstentStorePause',
  async (data: any, {dispatch, getState, fulfillWithValue, rejectWithValue}: any) => {
    try {
      dispatch(showLoading());
      const {store} = getState() as RootState;
      const response = await storeService.createInstentStorePause(store?.id, data);
      dispatch(hideLoading());
      return fulfillWithValue(response);
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(
        showAlert({
          heading: 'Unable to Store Pause',
          message: error?.message || 'Some error occurred while pausing store. Please, try again later',
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const endStorePauseNow = createAsyncThunk(
  'store/endStorePauseNow',
  async (data: any, {dispatch, getState, fulfillWithValue, rejectWithValue}) => {
    try {
      dispatch(showLoading());
      const {store} = getState() as RootState;
      const response = await storeService.endStorePauseNow(store.id, data);
      dispatch(hideLoading());
      return fulfillWithValue(response);
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(
        showAlert({
          heading: 'Unable to End Pause',
          message: error?.message || 'Some error occurred while ending store pause. Please, try again later',
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const addStorePauseSchedule = createAsyncThunk(
  'store/addStorePauseSchedule',
  async (data: any, {dispatch, getState, fulfillWithValue, rejectWithValue}: any) => {
    try {
      dispatch(showLoading());
      const {store} = getState() as RootState;
      const response = await storeService.createPauseSchedule(store?.id, data);
      dispatch(hideLoading());
      return fulfillWithValue(response);
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(
        showAlert({
          heading: 'Unable to Schedule New Store Pause',
          message: error?.message || 'Some error occurred while scheduling new store pause. Please, try again later',
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const archiveStorePauseSchedule = createAsyncThunk(
  'store/archiveStorePauseSchedule',
  async (data: any, {dispatch, getState, fulfillWithValue, rejectWithValue}: any) => {
    try {
      dispatch(showLoading());
      const {store} = getState() as RootState;
      await storeService.deletePauseSchedule(store?.id, data.pasueId);
      dispatch(hideLoading());
      return fulfillWithValue({id: data.pasueId});
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(
        showAlert({
          heading: 'Unable to Delete Pause Schedule',
          message: error?.message || 'Some error occurred while deleting store pause schedule. Please, try again later',
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const updateStorePauseSchedule = createAsyncThunk(
  'store/updateStorePauseSchedule',
  async (data: any, {dispatch, getState, fulfillWithValue, rejectWithValue}: any) => {
    try {
      dispatch(showLoading());
      const {store} = getState() as RootState;
      await storeService.updatePauseSchedule(store?.id, data.pauseId, data.payload);
      dispatch(hideLoading());
      return fulfillWithValue({id: data.pauseId, ...data.payload});
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(
        showAlert({
          heading: 'Unable to Update Pause Schedule',
          message: error?.message || 'Some error occurred while Updating store pause schedule. Please, try again later',
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const updateStore = createAsyncThunk('store/update', async (data: any, {dispatch, getState}) => {
  try {
    dispatch(showLoading());
    const {store} = getState() as RootState;
    await storeService.updateStore(store.company?.id || '', data.id, data);
    const {id, phone_number, addresses, ...rest} = data;
    const updatedStoreObj = {...store, ...rest, phone: phone_number, address: addresses[0]};
    dispatch(setStore(updatedStoreObj));
    dispatch(hideLoading());
    dispatch(updateStoreList(updatedStoreObj));
  } catch (error: any) {
    dispatch(hideLoading());
    dispatch(
      showAlert({
        heading: 'Unable to update',
        message: error?.message || 'Some error occurred while updating store details. Please, try again later',
      }),
    );
  }
});

export const updateStoreStatus = createAsyncThunk('store/updateStatus', async (data: any, {dispatch, getState}) => {
  try {
    dispatch(showLoading());
    const {auth, store} = getState() as RootState;
    await storeService.updateStoreStatus(store.id, data);
    dispatch(setCloseStore(false));
    const updatedStores = auth.userStores.map((storeItem: any) =>
      storeItem.id === store.id ? {...storeItem, isClosed: false} : storeItem,
    );
    dispatch(setStores(updatedStores));
  } catch (error: any) {
    dispatch(hideLoading());
    dispatch(
      showAlert({
        heading: 'Unable to update',
        message: error?.message || 'Some error occurred while updating store status. Please, try again later',
      }),
    );
  }
});

export const getStoreStatus = createAsyncThunk(
  'store/getStatus',
  async (_, {dispatch, getState, fulfillWithValue, rejectWithValue}: any) => {
    try {
      const {store} = getState() as RootState;
      if (store.id) {
        dispatch(showLoading());
        const response = await storeService.getStoreStatus(store.id, {
          current_local_date: moment().format('MMM DD YYYY HH:mm:ss'),
        });
        dispatch(hideLoading());
        AnalyticsHelper.trackGetStoreStatus({
          doorDash: response.doorDash,
          uberEats: response.uberEats,
          grubHub: response.grubHub,
        });
        return fulfillWithValue(response);
      }

      return fulfillWithValue({});
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(
        showAlert({
          heading: 'Unable to get',
          message: error?.message || 'Some error occurred while getting store status. Please, try again later',
        }),
      );
      throw rejectWithValue(error);
    }
  },
);

export const openStore = createAsyncThunk('store/open', async (dsp_name: PARTNER, {dispatch, getState}) => {
  const {auth, store} = getState() as RootState;
  const {id: sid} = store;
  try {
    dispatch(showLoading());
    await storeService.updateStoreStatus(store.id, {
      dsp_name,
      is_active: true,
    });
    dispatch(setCloseStore(false));
    const updatedStores = auth.userStores.map((store: any) => (store.id === sid ? {...store, isClosed: false} : store));
    dispatch(setStores(updatedStores));
    dispatch(hideLoading());
  } catch (error: any) {
    dispatch(hideLoading());
    dispatch(
      showAlert({
        heading: 'Error',
        message: error?.error || 'Unable to open your store. Please, try again later',
      }),
    );
  }
});

export const closeStore = createAsyncThunk('store/close', async (dsp_name: PARTNER, {dispatch, getState}) => {
  const {auth, store} = getState() as RootState;
  const {id: sid} = store;
  try {
    dispatch(showLoading());
    await storeService.updateStoreStatus(store.id, {
      dsp_name,
      is_active: false,
    });
    dispatch(setCloseStore(true));
    const updatedStores = auth.userStores.map((store: any) => (store.id === sid ? {...store, isClosed: true} : store));
    dispatch(setStores(updatedStores));
    dispatch(hideLoading());
  } catch (error: any) {
    dispatch(hideLoading());
    dispatch(
      showAlert({
        heading: 'Error',
        message: error?.error || 'Unable to close your store. Please, try again later',
      }),
    );
  }
});

export const addMenu = createAsyncThunk(
  'menu/add',
  async (data: any, {dispatch, getState, fulfillWithValue, rejectWithValue}) => {
    try {
      dispatch(showLoading());
      const {store} = getState() as RootState;
      const response = await menuService.createMenuHours(store.id, data.data, data.menuName);
      dispatch(hideLoading());
      dispatch(
        showAlert({
          heading: 'Successfully Created',
          message: 'Menu successfully Created',
        }),
      );
      return fulfillWithValue(response);
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(
        showAlert({
          heading: 'Unable to add',
          message: error?.message || storeHoursErrorListToString(error),
        }),
      );
      throw rejectWithValue(error);
    }
  },
);

export const editMenu = createAsyncThunk(
  'menu/update',
  async (data: any, {dispatch, fulfillWithValue, rejectWithValue, getState}) => {
    const {store} = getState() as RootState;
    const {id: storeId} = store;
    try {
      dispatch(showLoading());
      const response = await menuService.updateMenuHours(storeId, data.data, data.menuName, data.menuId);
      dispatch(hideLoading());
      dispatch(
        showAlert({
          heading: 'Successfully Updated',
          message: 'Menu successfully Updated',
        }),
      );
      return fulfillWithValue(response);
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(
        showAlert({
          heading: 'Unable to update',
          message: error?.message || storeHoursErrorListToString(error),
        }),
      );
      throw rejectWithValue(error);
    }
  },
);

export const getMenu = createAsyncThunk('menu', async (_, {dispatch, getState, fulfillWithValue, rejectWithValue}) => {
  try {
    dispatch(showLoading());
    const {store} = getState() as RootState;
    const response = await menuService.getMenu(store.id);
    dispatch(hideLoading());
    return fulfillWithValue(response);
  } catch (error: any) {
    dispatch(hideLoading());
    dispatch(
      showAlert({
        heading: 'Unable to get',
        message: error?.message || 'Some error occurred while getting menu. Please, try again later',
      }),
    );
    throw rejectWithValue(error);
  }
});

const initialState: IStore = {
  id: '',
  name: '',
  email: '',
  phone: '',
  address: {id: '', line_1: '', city: '', state: '', zip: ''},
  employeesCount: 0,
  shippingAddress: {id: '', line_1: '', city: '', state: '', zip: ''},
  company: null,
  isClosed: true,
  isDeactivated: false,
  isRBACStore: false,
  showGlobalItemsContent: false,
  showNewOrderModal: false,
  menuList: [],
  storeType: {id: '', name: '', store_type: 0},
  storePauseScheduleList: [],
  storeStatus: {
    isClosed: true,
    isAllPartnerOpen: true,
    isAllPartnerOffline: false,
    isSomePartnerOffline: false,
    isAllPartnerPaused: false,
    isSomePartnerPaused: false,
    partners: {
      currentPause: null,
      grubHub: [],
      doorDash: [],
      uberEats: [],
    },
  },
  companyId: '',
  createdAt: '',
};

const storeSlice = createSlice({
  name: 'store',
  initialState,
  reducers: {
    setStore: (state, action) => {
      state.id = action.payload.id;
      state.name = action.payload.name;
      state.email = action.payload.email;
      state.phone = action.payload?.phone || action.payload.phone_number;
      state.address = action.payload.address;
      state.isRBACStore = action.payload.isRBACStore;
      state.showGlobalItemsContent = action.payload.showGlobalItemsContent;
      state.company = action.payload.company;
      if (action.payload.shippingAddress) state.shippingAddress = action.payload.shippingAddress;
      if (action.payload.store_type) state.storeType = action.payload.store_type;
      if (action.payload.createdAt) state.createdAt = action.payload.createdAt;
    },
    setCloseStore: (state, action) => {
      state.storeStatus.isClosed = action.payload;
    },
    setEmployeesCount: (state, action) => {
      state.employeesCount = action.payload;
    },
    setDeactivateStore: (state, action) => {
      state.isDeactivated = action.payload;
    },
    setShippingAddress: (state, action) => {
      state.shippingAddress = action.payload.shippingAddress;
    },
    setShowNewOrderModal: (state, action) => {
      state.showNewOrderModal = action.payload;
    },
    setMenuList: (state, action) => {
      state.menuList = action.payload;
    },
    setStorePauseScheduleList: (state, action) => {
      state.storePauseScheduleList = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(addMenu.fulfilled, (state, action) => {
        state.menuList.push(action.payload);
      })
      .addCase(editMenu.fulfilled, (state, action) => {})
      .addCase(getMenu.fulfilled, (state, action) => {
        state.menuList = action.payload;
      })
      .addCase(getStorePauseSchedules.fulfilled, (state, action) => {
        state.storePauseScheduleList = action.payload;
      })
      .addCase(addStorePauseSchedule.fulfilled, (state, action) => {
        state.storePauseScheduleList.push(action.payload);
      })
      .addCase(archiveStorePauseSchedule.fulfilled, (state, action) => {
        state.storePauseScheduleList = state.storePauseScheduleList.filter((item) => item.id !== action.payload.id);
      })
      .addCase(updateStorePauseSchedule.fulfilled, (state, {payload}) => {
        state.storePauseScheduleList = state.storePauseScheduleList.map((item) =>
          item.id === payload.id
            ? {
                ...item,
                startTime: payload.start_time,
                endTime: payload.end_time,
                startDate: payload.start_date,
                endDate: payload.end_date,
              }
            : item,
        );
      })
      .addCase(getStoreStatus.fulfilled, ({storeStatus}, {payload}) => {
        storeStatus.partners = payload;
        storeStatus.isClosed = payload.isClosed;
        storeStatus.isAllPartnerPaused = !!payload.currentPause;
        storeStatus.isSomePartnerPaused =
          !payload.currentPause &&
          Object.keys(payload)
            .filter((key) => payload[key]?.length)
            .map((key) => payload[key][0])
            .some((item) => item.isPaused);
        storeStatus.isSomePartnerOffline =
          !payload.currentPause &&
          Object.keys(payload)
            .filter((key) => payload[key]?.length)
            .map((key) => payload[key][0])
            .some((item) => item.isOffline);
        storeStatus.isAllPartnerOffline = Object.keys(payload)
          .filter((key) => payload[key]?.length)
          .map((key) => payload[key][0])
          .every((item) => item.isOffline);
        storeStatus.isAllPartnerOpen = Object.keys(payload)
          .filter((key) => payload[key]?.length)
          .map((key) => payload[key][0])
          .every((item) => item.isOpen);
      });
  },
});

export const {
  setStore,
  setDeactivateStore,
  setCloseStore,
  setEmployeesCount,
  setShippingAddress,
  setShowNewOrderModal,
  setMenuList,
  setStorePauseScheduleList,
} = storeSlice.actions;

export default storeSlice.reducer;
