import {IonImg, IonLabel} from '@ionic/react';
import './styles.scss';
import {PARTNER} from 'src/enums/partner';
import {getPartnerImage} from 'src/utils/helpers';
import OrderDetailsListItem from 'src/components/OrderDetailsListItem';
import {formatPhoneNumber} from 'src/utils/helpers';

interface IProps {
  order: any;
}

const GetDspSupportNumber = ({order}: IProps) => {
  if (order?.partner === PARTNER.GRUB_HUB) {
    return <a href="tel: (877) 866-4482"> (877) 866-4482</a>;
  } else if (order?.partner === PARTNER.DOOR_DASH) {
    return <a href="tel: (855) 973-10407"> (855) 973-1040</a>;
  } else if (order?.partner === PARTNER.UBER_EATS) {
    return <a href="tel: (833) 275-3287"> (833) 275-3287</a>;
  } else return <></>;
};

const OrderDetailsList = ({order}: IProps) => (
  <div id="order-details-list">
    <IonLabel>Order details</IonLabel>
    <OrderDetailsListItem
      leftTitle="Partner"
      rightTitle="Partner Order #"
      rightValue={order?.dspOrderNumber}
      leftValue={
        <div className="d-flex">
          <IonImg className="partenr-icon" src={getPartnerImage(order?.partner)} />
          {order?.partner}
        </div>
      }
    />
    <OrderDetailsListItem
      leftTitle="Customer"
      rightTitle="Customer's number"
      leftValue={order?.customerName}
      rightValue={
        <>
          <a href={`tel:${order?.customerPhone}`}>
            {order?.partner === PARTNER.DOOR_DASH ? formatPhoneNumber(order?.customerPhone) : order?.customerPhone}
          </a>
          {order?.partner === PARTNER.UBER_EATS && order?.customerPhoneCode && (
            <div>Pin: {order?.customerPhoneCode}</div>
          )}
        </>
      }
    />
    <OrderDetailsListItem
      className="no-divider"
      leftTitle="Lula Order ID"
      rightTitle="Lula customer support"
      rightValue={<GetDspSupportNumber order={order} />}
      leftValue={order?.id}
    />
  </div>
);

export default OrderDetailsList;
