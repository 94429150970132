import moment from 'moment';
import {createAnimation, MenuI} from '@ionic/core';
import {ITEM_STATUS, INVENTORY_STATUS, MODIFIER_ITEM_STATUS} from 'src/enums/item_status';
import {PARTNER} from 'src/enums/partner';
import {REPORT_TYPE} from 'src/enums/report_type';
import {
  ICategoryCount,
  IInventoryQueryObject,
  IItem,
  IItemWithFirstSort,
  IModifierGroup,
  IModifierItem,
  IOrderItem,
  IOrderItemDetails,
  IQueryObject,
} from 'src/interfaces/item';
import {IMenuList} from 'src/interfaces/store';
import {IPermission} from 'src/interfaces/user';
import ubereats from 'src/assets/images/ubereats.svg';
import doordash from 'src/assets/images/doordash.svg';
import grubhub from 'src/assets/images/grubhub.svg';
import {store} from 'src/store';
import {setMenuIsOpen} from 'src/store/authSlice';
import {ITEM_PRICE_RANGE} from 'src/utils/constants';
import A_Z_Icon from 'src/assets/icons/a_z.svg';
import Z_A_Icon from 'src/assets/icons/z_a.svg';
//@ts-ignore
import {SIZE_TO_MEDIA} from '@ionic/core/dist/collection/utils/media';
import {INPUT_MASK} from 'src/enums/input_mask';
import {QUERY_OBJECT} from 'src/enums/query_object';
import {DATE_FILTERING} from 'src/enums/inventory_filtering';
import {ROLE_ICON} from 'src/utils/constants';
import {IPasswordRequirements} from 'src/interfaces/form';
import {ORDER_CUSTOM_FEES, ORDER_STATUS} from 'src/enums/order';

export const maskValue = (mask: string, value: string) => {
  let s = value,
    r = '';
  for (let i = 0, j = 0; i < mask.length && j < s.length; i++) {
    r += mask.charAt(i) === 'x' ? s.charAt(j++) : mask.charAt(i);
  }
  return r;
};

export const unMaskValue = (mask: string, value: string) => {
  if (value.startsWith('+')) {
    let s = value,
      r = '';
    for (let i = 0; i < value.length; i++) {
      r += mask.charAt(i) === 'x' ? s.charAt(i) : s.charAt(i) === mask.charAt(i) ? '' : s.charAt(i);
    }
    return r;
  } else {
    return value;
  }
};

export const formatPhoneNumber = (phoneNumber: string) => {
  // converts +11234567890 to 123-456-7890
  return maskValue(INPUT_MASK.PHONE, unMaskValue(INPUT_MASK.PHONE, phoneNumber)).substring(3).replace(/\s+/g, '-');
};

export const getInventoryType = (value: string) => {
  if (value === ITEM_STATUS.IN_STOCK) return INVENTORY_STATUS.IN_STOCK;
  if (value === ITEM_STATUS.ARCHIVED) return INVENTORY_STATUS.ARCHIVED;
  return 0;
};

export const getItemFromStoreItemNew = (sItem: any, recent?: boolean) => ({
  id: sItem?.id,
  storeItemId: sItem?.id,
  quantity: sItem?.quantity,
  price: sItem?.price,
  dspPrice: sItem?.dsp_item_price?.toFixed(2),
  size: sItem?.size,
  description: sItem?.description,
  unitCount: sItem?.unit_count,
  name: sItem?.name,
  upc: sItem?.upc,
  ean: '',
  active: sItem?.active || false,
  image: sItem?.image,
  category: sItem?.category,
  subcategory: sItem?.subcategory,
  selected: false,
  recent: recent || false,
  externalId: sItem?.external_id || '',
  completed: !!sItem?.completed,
  isAddedToDoorDash: !!sItem?.is_added_to_doordash,
  isAddedToUberEats: !!sItem?.is_added_to_ubereats,
  isAddedToGrubHub: !!sItem?.is_added_to_grub_hub,
  isRestricted: !!sItem?.is_restricted,
  isReviewed: !!sItem?.is_reviewed,
  isEditable: !!sItem?.is_editable,
  canSellIndependently: !!sItem?.sell_independently,
  modifierGroups: sItem?.modifier_groups?.length ? getModifierGroups(sItem?.modifier_groups) : [],
});

export const mapCategoryCount = (sItem: any) => {
  const {category_count, name, id} = sItem;
  return {category_count, name, id};
};

export const getStoreItemFromItemDetails = (data: any) => {
  return {
    ...data,
    storeItemId: data?.id,
    price: data?.price ? parseFloat(data.price).toFixed(2) : '',
    image: data?.image ? getValidImageUrl([data.image]) : '',
    dspPrice: data?.dsp_item_price?.toFixed(2),
    description: data?.description ? data?.description : data?.size,
    unitCount: data?.unit_count || '',
    externalId: data?.external_id || '',
    isEditable: !!data?.is_editable,
    isRestricted: !!data?.is_restricted,
    isReviewed: !!data?.is_reviewed,
    isAddedToDoorDash: !!data?.is_added_to_doordash,
    isAddedToUberEats: !!data?.is_added_to_ubereats,
    isAddedToGrubHub: !!data?.is_added_to_grub_hub,
    canSellIndependently: !!data?.sell_independently,
    modifierGroups: data?.modifier_groups?.length ? getModifierGroups(data?.modifier_groups) : [],
  };
};

export const getItemFromStoreItem = (sItem: any, recent?: boolean) => {
  const {
    id = null,
    name = null,
    upc = null,
    ean = null,
    images = [],
    item_category = [],
    item_subcategory = [],
    createdAt = null,
  } = sItem.item ?? {};
  const getCategory = () => {
    if (Array.isArray(sItem.store_item_category))
      return sItem.store_item_category[0] ? sItem.store_item_category[0] : item_category[0];
    else return sItem.store_item_category.id ? sItem.store_item_category : item_category;
  };

  const getSubCategory = () => {
    if (Array.isArray(sItem.store_item_subcategory))
      return sItem.store_item_subcategory.length
        ? sItem.store_item_subcategory[0]
          ? sItem.store_item_subcategory[0]
          : item_subcategory.length
          ? item_subcategory[0]
          : null
        : item_subcategory
        ? item_subcategory.length
          ? item_subcategory[0]
          : null
        : null;
    else
      return sItem.store_item_subcategory.length
        ? sItem.store_item_subcategory?.id
          ? sItem.store_item_subcategory
          : item_subcategory.length
          ? item_subcategory
          : null
        : item_subcategory
        ? item_subcategory.length
          ? item_subcategory
          : null
        : null;
  };

  const category = getCategory();
  const subCategory = getSubCategory();

  return {
    id,
    storeItemId: sItem.id,
    price: sItem.price ? parseFloat(sItem.price)?.toFixed(2) : '',
    size: sItem.size,
    quantity: sItem?.quantity,
    unitCount: sItem.unit_count || '',
    name: sItem.name || name,
    upc,
    ean,
    active: sItem.active,
    image: sItem.images ? getValidImageUrl(sItem.images) : images ? getValidImageUrl(images) : '',
    category: category,
    subcategory: subCategory,
    selected: false,
    recent: recent || false,
    completed: !!sItem.completed,
    description: sItem?.description ? sItem?.description : sItem?.size,
    isAddedToDoorDash: !!sItem.is_added_to_doordash,
    isAddedToUberEats: !!sItem.is_added_to_ubereats,
    isAddedToGrubHub: !!sItem.is_added_to_grub_hub,
    createdAt: new Date(createdAt).getTime(),
    canSellIndependently: !!sItem?.sell_independently,
    modifierGroups: sItem?.modifier_groups?.length ? getModifierGroups(sItem?.modifier_groups) : [],
  };
};

export const getItemFromCommonItem = (item: any) => ({
  id: item.id,
  name: item.name,
  upc: item.upc,
  ean: item.ean,
  image: item.images ? getValidImageUrl(item.images) : item.images ? getValidImageUrl(item.images) : '',
  category: item.categories[0],
  subcategory: item.subCategories[0],
  description: item.description,
  selected: false,
});

export const getItemFromStorePauseItem = (storePauseItem: any) => {
  const {
    id,
    store_id,
    start_time,
    end_time,
    start_date,
    end_date,
    daily,
    weekly,
    yearly,
    week_days,
    created_by,
    updated_by,
    created_at,
    updated_at,
    deleted_at,
  } = storePauseItem;
  return {
    id,
    storeId: store_id,
    startTime: start_time,
    endTime: end_time,
    startDate: start_date,
    endDate: end_date,
    daily,
    weekly,
    yearly,
    weekDays: week_days,
    createdBy: created_by,
    updatedBy: updated_by,
    createdAt: created_at,
    updatedAt: updated_at,
    deletedAt: deleted_at,
  };
};

export const getDetailsFromOrder = (order: any) => {
  let orderItemQuantity = 0;
  order?.orderItems?.map((item: any) => {
    orderItemQuantity += item.quantity;
    return 0;
  });

  const orderBagFee = order?.custom_fee?.filter((item: any) => item?.type === ORDER_CUSTOM_FEES.BAG_FEE)?.[0]?.price;
  return {
    id: order?.id,
    storeId: order?.store_id,
    customerName: order?.customer?.customer_name || '',
    customerPhone: order?.customer?.contact_phone || '',
    customerPhoneCode: order?.customer.phone_code || '',
    quantity: orderItemQuantity || 0,
    status: order?.fulfillments?.length ? order?.fulfillments[0]?.fulfillmentStatus?.delivery_status_name : 'incoming',
    subtotal: !isNaN(order?.original_subtotal) ? Number(order?.original_subtotal) : 0,
    tax: !isNaN(order?.tax_amount) ? Number(order?.tax_amount) : 0,
    taxRemitted: !isNaN(order?.tax_remitted) ? Number(order?.tax_remitted) : 0,
    bagFee: !isNaN(orderBagFee) ? Number(orderBagFee) : 0,
    discount: !isNaN(order?.discount) ? Number(order?.discount) : 0,
    totalPrice: !isNaN(order?.original_price) ? Number(order?.original_price) : 0,
    createdAt: order?.createdAt,
    partner: order?.partner || PARTNER.UBER_EATS,
    orderItems: order?.orderItems?.map((item: any) => getOrderItem(item)),
    customerNote: order?.special_instructions || 'N/A',
    ongoing: (!!order?.fulfillments?.length && !!order?.fulfillments[0]?.date_ready) || false,
    acceptedAt: order?.fulfillments?.length && order?.fulfillments[0]?.createdAt,
    readyAt: order?.fulfillments?.length && order?.fulfillments[0]?.date_ready,
    bagTime: getBagTime(
      order?.fulfillments?.length && order?.fulfillments[0]?.estimated_date_delivered,
      order?.fulfillments?.length && order?.fulfillments[0]?.createdAt,
    ),
    allergens: order?.exclude_allergens_items || 'N/A',
    disposableIncluded: order?.is_include_disposables,
    dspOrderNumber: order?.dsp_order_number || order?.order_id,
    orderCompletionDate: order?.fulfillments?.length && order?.fulfillments[0]?.estimated_date_delivered,
    timelineLogs: order?.fulfillments?.length
      ? order?.fulfillments[0]?.fulfillmentsLogs?.map((logItem: any) => ({
          timestamp: logItem.date_created,
          title: logItem.order_status_title,
          description: logItem?.description,
          firstName: logItem.user?.first_name,
          lastName: logItem.user?.last_name,
        }))
      : [],
  };
};

// TODO: Remove this function when all new order APIs integrated.
export const getDetailsFromOrderOld = (order: any) => {
  let orderItemQuantity = 0;
  order?.OrderItems?.map((item: any) => {
    orderItemQuantity += item.quantity;
    return 0;
  });

  const orderBagFee = order?.custom_fee?.filter((item: any) => item?.type === ORDER_CUSTOM_FEES.BAG_FEE)?.[0]?.price;
  return {
    id: order?.id,
    storeId: order?.store_id,
    customerName: order?.Customer?.customer_name || '',
    customerPhone: order?.Customer?.contact_phone || '',
    quantity: orderItemQuantity || 0,
    status: order?.fulfillments?.length ? order?.fulfillments[0]?.fulfillmentStatus?.delivery_status_name : 'incoming',
    subtotal: !isNaN(order?.original_subtotal) ? Number(order?.original_subtotal) : 0,
    tax: !isNaN(order?.tax_amount) ? Number(order?.tax_amount) : 0,
    taxRemitted: !isNaN(order?.tax_remitted) ? Number(order?.tax_remitted) : 0,
    bagFee: !isNaN(orderBagFee) ? Number(orderBagFee) : 0,
    discount: !isNaN(order?.discount) ? Number(order?.discount) : 0,
    totalPrice: !isNaN(order?.original_price) ? Number(order?.original_price) : 0,
    createdAt: order?.createdAt,
    partner: order?.partner || PARTNER.UBER_EATS,
    orderItems: order?.OrderItems?.map((item: any) => getOrderItemOld(item)),
    customerNote: order?.special_instructions || 'N/A',
    ongoing: (!!order?.fulfillments?.length && !!order?.fulfillments[0]?.date_ready) || false,
    acceptedAt: order?.fulfillments?.length && order?.fulfillments[0]?.createdAt,
    readyAt: order?.fulfillments?.length && order?.fulfillments[0]?.date_ready,
    bagTime: getBagTime(
      order?.fulfillments?.length && order?.fulfillments[0]?.estimated_date_delivered,
      order?.fulfillments?.length && order?.fulfillments[0]?.createdAt,
    ),
    allergens: order?.exclude_allergens_items || 'N/A',
    disposableIncluded: order?.is_include_disposables,
    customerPhoneCode: order?.Customer.phone_code || '',
    dspOrderNumber: order?.dsp_order_number || order?.order_id,
    orderCompletionDate: order?.fulfillments?.length && order?.fulfillments[0]?.estimated_date_delivered,
    timelineLogs: order?.fulfillments?.length
      ? order?.fulfillments[0]?.fulfillmentsLogs?.map((logItem: any) => {
          return {
            timestamp: logItem.date_created,
            title: logItem.order_status_title,
            description: logItem?.description,
            firstName: logItem.Employee?.first_name,
            lastName: logItem.Employee?.last_name,
          };
        })
      : [],
  };
};

export const getOrderItemOld = (item: any) => ({
  id: item.id,
  storeItemId: item.order_item?.store_item ? item.order_item?.store_item[0]?.id : '',
  quantity: item.quantity,
  action: item.action,
  unitCount: item.order_item?.store_item ? item.order_item?.store_item[0]?.unit_count : '',
  itemPrice: item.store_item_price ? parseFloat(item.store_item_price).toFixed(2) : '',
  itemTotalPrice: item.store_item_total_price ? parseFloat(item.store_item_total_price).toFixed(2) : '',
  image: item.order_item?.images ? getValidImageUrl(item.order_item.images) : '',
  itemName: item.store_item_name,
  size: item.store_item_size && item.store_item_size !== 'NA' ? item.store_item_size : '',
  description: item.store_item_description,
  itemLevelInstructions: item.special_instructions,
  proposedSubstitutes: item?.customer_proposed_substitutes?.map((substitute: any) => ({
    id: substitute.id,
    name: substitute.name,
    unitCount: substitute.unit_count,
    size: substitute.size && substitute.size !== 'NA' ? substitute.size : '',
  })),
  category: item.order_item?.store_item?.[0]?.store_item_category?.[0]
    ? item.order_item?.store_item?.[0]?.store_item_category?.[0]
    : item.order_item?.item_category?.[0],
  categoryName: item.store_item_category,
  upc: item.order_item?.upc,
  purchasePrice: item.item_price,
  external_id: item.order_item?.store_item ? item.order_item?.store_item[0]?.external_id : '',
  location: item.order_item?.store_item ? item.order_item?.store_item[0]?.location : '',
  modifierGroups: !!item?.modifiers?.modifier_groups?.length ? getModifierGroups(item?.modifiers?.modifier_groups) : [],
});

export const getOrderItem = (orderItem: any) => ({
  id: orderItem.id,
  storeItemId: orderItem.item?.storeItems ? orderItem.item?.storeItems[0]?.id : '',
  quantity: orderItem.quantity,
  action: orderItem.action,
  unitCount: orderItem.item?.storeItems ? orderItem.item?.storeItems[0]?.unit_count : '',
  itemPrice: orderItem.store_item_price ? parseFloat(orderItem.store_item_price).toFixed(2) : '',
  itemTotalPrice: orderItem.store_item_total_price ? parseFloat(orderItem.store_item_total_price).toFixed(2) : '',
  image: orderItem.item?.images ? getValidImageUrl(orderItem.item.images) : '',
  itemName: orderItem.store_item_name,
  size: orderItem.store_item_size && orderItem.store_item_size !== 'NA' ? orderItem.store_item_size : '',
  description: orderItem.store_item_description,
  itemLevelInstructions: orderItem.special_instructions,
  proposedSubstitutes: orderItem?.customer_proposed_substitutes?.map((substitute: any) => ({
    id: substitute.id,
    name: substitute.name,
    unitCount: substitute.unit_count,
    size: substitute.size && substitute.size !== 'NA' ? substitute.size : '',
  })),
  category: orderItem.item?.storeItems?.[0]?.categories?.[0]
    ? orderItem.item?.storeItems?.[0]?.categories?.[0]
    : orderItem.item?.categories?.[0],
  categoryName: orderItem.store_item_category,
  upc: orderItem.item?.upc,
  purchasePrice: orderItem.item_price, // Not present in API response.
  external_id: orderItem.item?.storeItems ? orderItem.item?.storeItems[0]?.external_id : '',
  location: orderItem.item?.storeItems ? orderItem.item?.storeItems[0]?.location : '',
  modifierGroups: !!orderItem?.modifiers?.modifier_groups?.length
    ? getModifierGroups(orderItem?.modifiers?.modifier_groups)
    : [],
});

export const inInventory = (id: string, inventory: Array<IItem>) => {
  return inventory.some((item) => item.storeItemId === id);
};

export const inOrderList = (id: string, orderList: Array<IOrderItem>) => {
  return orderList.some((item) => item.id === id);
};

export const getImageUrl = (url: string) => {
  return url.startsWith('http') ? url : process.env.REACT_APP_S3_BASE_PATH + url;
};

export const getTimeInMilliseconds = (time: string) => {
  const ms = new Date(`${time}`).getTime();
  return ms;
};

export const getDate = (date: string) => {
  return moment(new Date(date)).format('LL');
};

export const getBagTime = (time1: string, time2: string) => {
  const dateDelivered = moment(time1);
  const acceptedAt = moment(time2);
  const duration = moment.duration(dateDelivered.diff(acceptedAt));
  const minutes = duration.minutes();
  return minutes;
};

export const isValidImageExist = (image: any) => {
  if (image instanceof Blob) return image.type.startsWith('image/');
  else return getValidImageUrl([image]) || false;
};

export const getValidImageUrl = (urls: Array<string>) => {
  if (!urls) return '';
  let validUrl = urls.find((url) => {
    const arr = url.split('.');
    const ext = arr[arr.length - 1].toLowerCase();
    return ext === 'png' || ext === 'jpg' || ext === 'jpeg' || ext === 'webp';
  });
  if (!validUrl) {
    validUrl = urls.find((url) => {
      return url.indexOf('blob:http://') !== -1 || url.indexOf('blob:https://') !== -1;
    });
  }
  if (!validUrl) {
    validUrl = urls.find((url) => {
      return (
        url.includes('.png') ||
        url.includes('.PNG') ||
        url.includes('.jpg') ||
        url.includes('.JPG') ||
        url.includes('.jpeg') ||
        url.includes('.JPEG')
      );
    });
  }
  return validUrl ? validUrl : '';
};

export const getPartnerImage = (partner: string) => {
  if (partner === PARTNER.UBER_EATS) return ubereats;
  if (partner === PARTNER.DOOR_DASH) return doordash;
  if (partner === PARTNER.GRUB_HUB) return grubhub;
};

export const getAddressDetails = (placeDetails: any) => {
  const streetAddress = placeDetails?.address_components?.find((item: any) => item.types.includes('route'));

  const addressCity = placeDetails?.address_components?.find((item: any) => item.types.includes('locality'));
  const addressState = placeDetails?.address_components?.find((item: any) =>
    item.types.includes('administrative_area_level_1'),
  );
  const postalCode = placeDetails?.address_components?.find((item: any) => item.types.includes('postal_code'));

  return {
    streetAddress: streetAddress?.long_name,
    addressCity: addressCity?.long_name,
    addressState: addressState?.short_name,
    postalCode: postalCode?.long_name,
  };
};

export const getReportType = (from: any, to: any) => {
  let isValid = moment(from).isSameOrBefore(moment(to)) && moment(to).isSameOrAfter(moment(from));
  let type: any;

  const daysDifference = moment(to).diff(moment(from), 'day', true);

  if (daysDifference === 0) {
    type = REPORT_TYPE.HOUR;
  } else if (daysDifference < 8) {
    type = REPORT_TYPE.DAY;
  } else {
    const weekDifference = moment(to).diff(moment(from), 'week', true);

    if (weekDifference === 0) {
      type = REPORT_TYPE.DAY;
    } else if (weekDifference < 8) {
      type = REPORT_TYPE.WEEK;
    } else {
      const monthDifference = moment(to).diff(moment(from), 'month', true);

      if (monthDifference <= 12) {
        type = REPORT_TYPE.MONTH;
      } else {
        type = REPORT_TYPE.YEAR;
      }
    }
  }

  return {type, isValid};
};

export const getIntervalsDisplayFormat = (date: any, type: string) => {
  switch (type) {
    case REPORT_TYPE.HOUR:
      return moment(date).format('LT');

    case REPORT_TYPE.MONTH:
      return moment(date).format('MMM');

    case REPORT_TYPE.YEAR:
      return moment(date).format('YYYY');

    default:
      return moment(date).format('DD MMM');
  }
};

export const toggleMenu = async () => {
  const splitPane = document.querySelector('ion-split-pane');
  if (splitPane) {
    if (window.matchMedia(SIZE_TO_MEDIA[splitPane.when] || splitPane.when).matches) {
      splitPane.classList.toggle('split-pane-visible');
      if (store.getState().auth.menuIsOpen) store.dispatch(setMenuIsOpen(false));
      else store.dispatch(setMenuIsOpen(true));
    }
  }
};

export const customMenuAnimation = (menu: MenuI) => {
  let contentOpenedX;
  let menuClosedX;
  const width = menu.width;
  if (menu.isEndSide) {
    contentOpenedX = -width + 'px';
    menuClosedX = width + 'px';
  } else {
    contentOpenedX = width + 'px';
    menuClosedX = -width + 'px';
  }
  const menuAnimation = createAnimation()
    .addElement(menu.menuInnerEl as Element)
    .fromTo('transform', `translateX(${menuClosedX})`, 'translateX(0px)');
  const contentAnimation = createAnimation()
    .addElement(menu.contentEl as Element)
    .fromTo('transform', 'translateX(0px)', ` scale(0.9) translateX(${contentOpenedX})`)
    .fromTo('boxShadow', 'none', '0px 0px 20px rgba(0, 0, 0, 0.1)')
    .fromTo('borderRadius', '0px', '20px');

  const backdropAnimation = createAnimation()
    .addElement(menu.backdropEl as Element)
    .fromTo('opacity', 0.01, 0.32);

  return createAnimation()
    .easing('ease-in-out')
    .duration(100)
    .addAnimation([menuAnimation, contentAnimation, backdropAnimation]);
};

export const isItemSynced = (partners: any, item: any) => {
  let syncedBooleanList: any = [];

  Object.keys(partners).forEach((key) => {
    switch (key) {
      case 'doorDash':
        if (partners.doorDash.length && partners.doorDash[0]?.name) {
          if (item.isAddedToDoorDash) {
            syncedBooleanList.push(true);
          } else {
            syncedBooleanList.push(false);
          }
        }
        break;

      case 'uberEats':
        if (partners.uberEats.length && partners.uberEats[0]?.name) {
          if (item.isAddedToUberEats) {
            syncedBooleanList.push(true);
          } else {
            syncedBooleanList.push(false);
          }
        }
        break;

      case 'grubHub':
        if (partners.grubHub.length && partners.grubHub[0]?.name) {
          if (item.isAddedToGrubHub) {
            syncedBooleanList.push(true);
          } else {
            syncedBooleanList.push(false);
          }
        }
        break;
    }
  });

  return syncedBooleanList.length !== 0 ? syncedBooleanList?.every((value: any) => value === true) : false;
};

export const mapPartnersToReportFilters = (partners: any) => {
  let filters: any = [];

  Object.keys(partners).forEach((key) => {
    switch (key) {
      case 'doorDash':
        if (partners.doorDash.length) {
          filters.push({label: PARTNER.DOOR_DASH, value: PARTNER.DOOR_DASH});
        }
        break;

      case 'uberEats':
        if (partners.uberEats.length) {
          filters.push({label: PARTNER.UBER_EATS, value: PARTNER.UBER_EATS});
        }
        break;

      case 'grubHub':
        if (partners.grubHub.length) {
          filters.push({label: PARTNER.GRUB_HUB, value: PARTNER.GRUB_HUB});
        }
        break;
    }
  });

  return filters;
};

export const mapSortSelectionToSortValues = (sortSelection: number) => {
  switch (sortSelection) {
    case 1:
      return {order: QUERY_OBJECT.CATEGORY, sortDir: QUERY_OBJECT.ASC};
    case 2:
      return {order: QUERY_OBJECT.NAME, sortDir: QUERY_OBJECT.ASC};
    case 3:
      return {order: QUERY_OBJECT.NAME, sortDir: QUERY_OBJECT.DESC};
    case 4:
      return {order: QUERY_OBJECT.PRICE, sortDir: QUERY_OBJECT.ASC};
    case 5:
      return {order: QUERY_OBJECT.PRICE, sortDir: QUERY_OBJECT.DESC};
    case 6:
      return {order: QUERY_OBJECT.CREATEDAT, sortDir: QUERY_OBJECT.DESC};
    case 7:
      return {order: QUERY_OBJECT.CREATEDAT, sortDir: QUERY_OBJECT.ASC};
    default:
      return {order: QUERY_OBJECT.CATEGORY, sortDir: QUERY_OBJECT.ASC};
  }
};

export const mapSortSelectionToSortOutput = (sortSelection: number): string => {
  switch (sortSelection) {
    case 1:
      return 'Categories';
    case 2:
      return 'Alphabetical: A to Z';
    case 3:
      return 'Alphabetical: Z to A';
    case 4:
      return 'Price: Low to High';
    case 5:
      return 'Price: High to Low';
    case 6:
      return 'Date added: Newest first';
    case 7:
      return 'Date added: Oldest first';
    default:
      return 'Categories';
  }
};

export const mapSortSelectionToIcon = (sortSelection: number): string => {
  switch (sortSelection) {
    case 1:
      return 'list';
    case 2:
      return A_Z_Icon;
    case 3:
      return Z_A_Icon;
    case 4:
      return 'dollar-sign';
    case 5:
      return 'dollar-sign';
    case 6:
      return 'calendar';
    case 7:
      return 'calendar';
    default:
      return 'list';
  }
};

export const createItemArrayWithDivider = (queryObject: IQueryObject, items: IItem[]) => {
  const itemsWithOutRecent: IItemWithFirstSort[] = [...items.filter((item) => !item.recent)];
  if (itemsWithOutRecent.length === 0) return itemsWithOutRecent;
  if (queryObject.order === QUERY_OBJECT.CATEGORY) {
    itemsWithOutRecent[0] = {...itemsWithOutRecent[0], firstOfSort: 'true'};
    for (let i = 1; i < itemsWithOutRecent.length; i++) {
      if (itemsWithOutRecent[i - 1].category?.name !== itemsWithOutRecent[i].category?.name) {
        itemsWithOutRecent[i] = {...itemsWithOutRecent[i], firstOfSort: 'true'};
      }
    }
    return itemsWithOutRecent;
  } else if (queryObject.order === QUERY_OBJECT.NAME) {
    itemsWithOutRecent[0] = {...itemsWithOutRecent[0], firstOfSort: 'true'};
    for (let i = 1; i < itemsWithOutRecent.length; i++) {
      if (
        itemsWithOutRecent[i - 1].name.charAt(0).toLowerCase() !== itemsWithOutRecent[i].name.charAt(0).toLowerCase()
      ) {
        itemsWithOutRecent[i] = {...itemsWithOutRecent[i], firstOfSort: 'true'};
      }
    }
    return itemsWithOutRecent;
  } else if (queryObject.order === QUERY_OBJECT.CREATEDAT) {
    itemsWithOutRecent[0] = {...itemsWithOutRecent[0], firstOfSort: 'true'};
  } else if (queryObject.order === QUERY_OBJECT.PRICE) {
    itemsWithOutRecent[0] = {...itemsWithOutRecent[0], firstOfSort: 'true'};
  }

  return itemsWithOutRecent;
};

export const validateCreateOrUpdateItemsInputs = (item: IItem) => {
  const {name, price, category, canSellIndependently} = item;
  const parsedPrice = !price ? '-1' : parseFloat(price).toFixed(2);
  const isNameValid = name && name.length !== 0;
  const isSizeValid = item.size && item.size.length !== 0;
  const isParsedPriceValid = parsedPrice && 0 <= parseFloat(parsedPrice);
  const isCategoryIdValid = category?.id && category.id.length !== 0;
  let inputsValid = isNameValid && isParsedPriceValid && isCategoryIdValid && isSizeValid;

  if (!canSellIndependently) {
    inputsValid = isNameValid && isParsedPriceValid;
  }

  return inputsValid;
};

export const removeItemWithId = (arr: Array<IItem>, id: string) => {
  const objWithIdIndex = arr.findIndex((obj) => obj.id === id);
  arr.splice(objWithIdIndex, 1);
  return arr;
};

export const removeElementWithId = ([...arr]: Array<string>, id: string) => {
  const objWithIdIndex = arr.findIndex((obj) => obj === id);
  arr.splice(objWithIdIndex, 1);
  return arr;
};

export const isElementIncludes = ([...arr]: Array<string>, id: string) => {
  if (arr.length) {
    const result = arr.includes(id);
    return result;
  } else {
    return false;
  }
};

export const isItemIncludes = ([...arr]: Array<IItem>, store_item_id: string) => {
  if (arr.length) {
    const result = arr.find((v) => v.storeItemId === store_item_id);
    return !!result;
  } else {
    return false;
  }
};

export const removeMultipleItems = ([...arr]: Array<IItem>, category_id: string) => {
  arr.forEach((item, index) => {
    if (item.category.id === category_id) {
      arr.splice(index, 1);
    }
  });

  return arr;
};

export const getTotalSelectItemCount = (
  selectedCategories: Array<string>,
  selectedItems: Array<IItem>,
  exceptItems: Array<IItem>,
  categoryCount: Array<ICategoryCount>,
) => {
  // categories -> categorycount
  //totalCount = categories - exceptitems + selectedItems
  //

  let totalCount = 0;
  if (selectedCategories.length) {
    selectedCategories.forEach((category_id) => {
      const v = categoryCount.find((value) => value.id === category_id)?.category_count || 0;
      totalCount = totalCount + Number(v);
    });
  }

  totalCount = totalCount + selectedItems.length - exceptItems.length;

  // totalCount = totalCount + selectedItems.length;

  // totalCount = totalCount - exceptItems.length;

  return totalCount < 0 ? 0 : totalCount;
};

export const checkCategoryCount = (
  selectedItems: Array<IItem>,
  categoryCount: Array<ICategoryCount>,
  category_id: string,
) => {
  let totalCount = 0;
  if (selectedItems.length) {
    selectedItems.forEach((selectedItem) => {
      if (selectedItem.category.id === category_id) {
        totalCount++;
      }
    });
  }
  const count = categoryCount.find((value) => value.id === category_id)?.category_count || 0;

  if (totalCount === Number(count)) {
    return true;
  } else {
    return false;
  }
};

export const calculateDateForFiltering = (endDate: number, startDate: number) => {
  const a = moment(endDate);
  const b = moment(startDate);
  const diff = a.diff(b, 'days');
  if (diff === 7) return DATE_FILTERING.week;
  else if (diff === 14) return DATE_FILTERING.twoWeeks;
  else if (diff === 30) return DATE_FILTERING.month;
  else if (diff === 90) return DATE_FILTERING.quarter;
  else if (diff === 180) return DATE_FILTERING.halfYear;
  else if (diff === 365) return DATE_FILTERING.year;
  else return DATE_FILTERING.all;
};

export const sortValuesToSortOutput = (queryObject: IQueryObject) => {
  for (let i = 0; i < 7; i++) {
    const currentSortObject = mapSortSelectionToSortValues(i + 1);
    if (currentSortObject.sortDir === queryObject.sortDir && currentSortObject.order === queryObject.order) {
      const sortOutput = mapSortSelectionToSortOutput(i + 1);
      return i === 0 ? sortOutput + ' ' + queryObject.sortDir : sortOutput;
    }
  }
};

export const checkValidPrice = (price: number) => {
  if (price <= ITEM_PRICE_RANGE.max && price >= ITEM_PRICE_RANGE.min) return true;
  else return false;
};

export const createFilterParams = (queryObject: IQueryObject) => {
  const {menuList, categories, ...params} = queryObject;
  return {...params, categories: categories.join(','), menus: menuList.join(',')};
};

export const createQueryParams = (queryObject: IInventoryQueryObject) => {
  const {
    order,
    sortDir,
    minPrice,
    maxPrice,
    startDate,
    endDate,
    categories,
    search_string,
    menuList,
    active,
    offset,
    out_of_stock,
    incomplete,
    show_global_items_content,
  } = queryObject;
  return {
    order,
    sort_dir: sortDir,
    min_price: minPrice,
    max_price: maxPrice,
    start_date: startDate,
    end_date: endDate,
    menus: menuList ? menuList.join(',') : '',
    active: active,
    search_string: search_string || '',
    offset: offset || 0,
    categories: categories ? categories.join(',') : '',
    show_global_items_content,
    out_of_stock: out_of_stock || false,
    incomplete: incomplete || false,
  };
};

// storeHoursErrorListToString Function:
// Input: [Error("error 1"), Error("error 2")]
// Output: `• error 1
//          • error 2`
export const storeHoursErrorListToString = (errors: []) => {
  if (errors.length === 0) {
    return 'Some error occurred while saving hours. Please, try again later';
  }
  let errorString: string = '';
  errors.forEach((error: any) => {
    errorString += `• ${error.message} <br>`;
  });
  return errorString.substring(0, errorString.length - 4);
};

export const calculateMilliSeconds = (bagTime: number): number => {
  if (bagTime === 15) return 900000;
  if (bagTime === 10) return 600000;
  return 300000;
};

export const calculateDurationFromEndDateAndTime = (date: string | undefined, time: string | undefined) => {
  const currentTime = moment();
  const endTime = moment(`${date} ${time}`, 'YYYY-MM-DD HH:mm:ss');
  const duration = moment.duration(endTime.diff(currentTime));
  const durationInMilliseconds = duration.asMilliseconds();
  return durationInMilliseconds;
};

export const parseMenuList = (menuList: Array<IMenuList>) => {
  const arr: any = [];
  menuList.forEach((menu) => {
    if (menu.is_default) arr.unshift(menu);
    else arr.push(menu);
  });
  return arr;
};

export const checkPermission = (permissionsList: Array<IPermission> | undefined, permission: string) =>
  permissionsList?.some((item: any) => item.name === permission) || false;

export const checkSomePermissions = (permissionsList: Array<IPermission> | undefined, permissions: Array<string>) => {
  let permissionGranted = false;

  for (let i = 0; i < permissions.length; i++) {
    const exist = permissionsList?.some((item: any) => item.name === permissions[i]);
    if (exist) {
      permissionGranted = true;
      break;
    }
  }

  return permissionGranted;
};

export const getIcon = (key: string) => ROLE_ICON[key];

export const validatePassword = (
  value: any,
  requirements: Map<string, IPasswordRequirements>,
  setRequirements: any,
  setValid: any,
) => {
  let newRequirements = new Map(requirements);
  let valid = true;

  if (value.length >= 8) {
    newRequirements.set('length', {message: '8 characters long including', isValid: true});
  } else {
    valid = false;
    newRequirements.set('length', {message: '8 characters long including', isValid: false});
  }

  if (/[A-Z]/.test(value)) {
    newRequirements.set('capitalLetter', {message: '1 capital letter', isValid: true});
  } else {
    valid = false;
    newRequirements.set('capitalLetter', {message: '1 capital letter', isValid: false});
  }

  if (/\d/.test(value)) {
    newRequirements.set('number', {message: '1 number', isValid: true});
  } else {
    valid = false;
    newRequirements.set('number', {message: '1 number', isValid: false});
  }

  if (/[!@#$%^&*()_+{}[\]:;<>,.?~`|\\/\-=]/.test(value)) {
    newRequirements.set('specialCharacter', {message: '1 special character', isValid: true});
  } else {
    valid = false;
    newRequirements.set('specialCharacter', {message: '1 special character', isValid: false});
  }

  setRequirements(newRequirements);
  setValid(valid);
};

export const getModifierGroups = (data: any) => {
  return data?.map((modifierGroupItem: any) => {
    const {id, name, items, modifier_groups, min_quantity, max_quantity, status} = modifierGroupItem;
    return {
      modifierGroupId: id,
      modifierGroupName: name,
      minQuantity: Number(min_quantity),
      maxQuantity: Number(max_quantity),
      isAvailable: status === MODIFIER_ITEM_STATUS.ACTIVE,
      items: items?.map((item: any) => {
        return {
          partnerItemId: item?.partner_item_id,
          modifierItemId: item?.id,
          storeItemId: item?.store_item_id,
          modifierItemName: item?.name,
          modifierItemStatus: item?.status,
          totalPrice: item?.total_price,
          unitPrice: item?.unit_price || item?.price,
          quantity: item?.quantity,
        };
      }) as IModifierItem[],
      modifierGroup: !!modifier_groups?.length ? getModifierGroups(modifier_groups) : undefined,
    };
  }) as IModifierGroup[];
};

export const getSubstitutedItemQuantity = (
  itemToSubstitute: IOrderItemDetails | undefined,
  substituteItem: IItem,
  substituteItemQuantity = 1,
): number => {
  const originalTotalPrice = Number(itemToSubstitute?.itemTotalPrice || 0);
  const orderItemPrice = Number(itemToSubstitute?.itemPrice || 0);
  const itemPrice = Number(substituteItem.price);

  if (itemPrice <= orderItemPrice) {
    return substituteItemQuantity;
  } else if (itemPrice < originalTotalPrice / 2) {
    return Math.floor(originalTotalPrice / itemPrice);
  } else {
    return 1;
  }
};

export const canAddToForSaleTab = (item: IItem) => {
  const isLinkedWithGlobalItem = item.id !== '';
  return (
    item.active &&
    item.completed &&
    item.isReviewed &&
    !item.isRestricted &&
    isLinkedWithGlobalItem &&
    Number(item.price) > 0 &&
    item.quantity > 0 &&
    item.category.id !== ''
  );
};

export const hideIntercomLauncherBtn = () =>
  window.Intercom('update', {
    hide_default_launcher: true,
  });

export const showIntercomLauncherBtn = () =>
  window.Intercom('update', {
    hide_default_launcher: false,
  });

export const isValidOrderStatus = (status: string): status is ORDER_STATUS => {
  return Object.values(ORDER_STATUS).includes(status as ORDER_STATUS);
};

export const convertBlobToBase64 = (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
    reader.readAsDataURL(blob);
  });
};

export const camelToSnakeCase = (obj: any): any => {
  const newObj: any = {};

  for (const key in obj) {
    if (obj.hasOwnProperty(key)) {
      newObj[key.replace(/([A-Z])/g, '_$1').toLowerCase()] = obj[key];
    }
  }

  return newObj;
};
