import moment from 'moment';
import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {RootState} from 'src/store';
import {hideLoading, showLoading} from 'src/store/loadingSlice';
import {showAlert} from 'src/store/alertSlice';
import {setShowNewOrderModal} from 'src/store/storeSlice';
import {IOrderItem} from 'src/interfaces/item';
import {PrinterService} from 'src/services/printer';
import orderService from 'src/services/order';
import {ORDER_STATUS, ORDER_FETCH_TYPE} from 'src/enums/order';
import {inOrderList} from 'src/utils/helpers';
import {orderNotification} from 'src/utils/order-notification';
import AnalyticsHelper from 'src/utils/segment';
import firebaseCrashlytics from 'src/utils/firebase-crashlytics';
import {OrderInfo} from 'src/interfaces/order';

interface IOrder {
  loading: boolean;
  loadMoreActiveOrders: boolean;
  loadMoreCompletedOrders: boolean;
  activeOrders: Array<IOrderItem>;
  completedOrders: Array<IOrderItem>;
  ordersHistory: Array<IOrderItem>;
  incomingOrder: IOrderItem | null;
  activeOrdersCount: number;
  completedOrdersCount: number;
  newIncomingOrdersCount: number;
  acceptedOrderId: string;
  lastOrdersFetchTime: string;
}

export const getOrdersCount = createAsyncThunk(
  'order/getOrdersCount',
  async (_, {fulfillWithValue, getState, rejectWithValue}: any) => {
    const {store} = getState() as RootState;
    try {
      const counts = await orderService.getOrdersCount(store.id);
      return fulfillWithValue(counts);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const getActiveOrders = createAsyncThunk(
  'order/getActiveOrders',
  async (_, {dispatch, fulfillWithValue, getState, rejectWithValue}: any) => {
    const {order, store} = getState() as RootState;
    const {activeOrders} = order;
    try {
      if (activeOrders.length === 0) dispatch(showLoading());
      else dispatch(setLoading(true));
      const items = await orderService.getOrders(store.id, 'active', activeOrders.length);
      if (activeOrders.length === 0) dispatch(hideLoading());
      else dispatch(setLoading(false));
      if (activeOrders.length === 0) {
        dispatch(setActiveOrders([]));
      }
      return fulfillWithValue(items);
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(
        showAlert({
          heading: 'Stay current, Refresh now',
          message: 'Refresh by pulling down the page to ensure your orders page is updated',
        }),
      );
      AnalyticsHelper.trackActiveOrdersError(error);
      return rejectWithValue(error);
    }
  },
);

export const getCompletedOrders = createAsyncThunk(
  'order/getCompletedOrders',
  async (_, {dispatch, fulfillWithValue, getState, rejectWithValue}) => {
    const {order, store} = getState() as RootState;
    const {completedOrders} = order;
    try {
      if (completedOrders.length === 0) dispatch(showLoading());
      else dispatch(setLoading(true));
      const items = await orderService.getOrders(store.id, 'completed', completedOrders.length);
      if (completedOrders.length === 0) dispatch(hideLoading());
      else dispatch(setLoading(false));
      return fulfillWithValue(items);
    } catch (error: any) {
      if (completedOrders.length === 0) dispatch(hideLoading());
      else dispatch(setLoading(false));
      dispatch(
        showAlert({
          heading: 'Unable to fetch completed orders',
          message: error?.message || 'Some error occurred while fetching data. Please, try again later',
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const getOrdersHistory = createAsyncThunk(
  'order/getOrdersHistory',
  async (data: any, {dispatch, fulfillWithValue, rejectWithValue}: any) => {
    try {
      if (data.offset === 0) dispatch(showLoading());
      const items = await orderService.getOrdersHistory(data);
      if (data.offset === 0) dispatch(hideLoading());
      return fulfillWithValue(items);
    } catch (error: any) {
      if (data.offset === 0) dispatch(hideLoading());
      dispatch(
        showAlert({
          heading: 'Unable to fetch orders history',
          message: error?.message || 'Some error occurred while fetching data. Please, try again later',
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const getNewIncomingOrders = createAsyncThunk(
  'order/getNewIncomingOrders',
  async (_, {dispatch, getState, fulfillWithValue, rejectWithValue}: any) => {
    const {order, printer, store} = getState() as RootState;
    const {printer: sPrinter} = printer;

    try {
      if (!!order.lastOrdersFetchTime) {
        const items: Array<OrderInfo> = await orderService.getNewIncomingOrders(store.id, order.lastOrdersFetchTime);
        const filteredItems = items.filter((item) => !order.activeOrders.some((o) => o.id === item.id));

        if (filteredItems.length > 0) {
          if (filteredItems.length > 1) {
            dispatch(setNewIncomingOrdersCount(filteredItems.length));
          }
          // Setting first order details as incoming order.
          dispatch(setIncomingOrder(filteredItems[0]));
          dispatch(setShowNewOrderModal(true));
          // Haptic feedback.
          if (orderNotification.paused) {
            orderNotification.play().catch(() => console.log('Cannot play audio'));
          }
          AnalyticsHelper.groupStore();
          // Print all orders using Star Printer through Bluetooth
          for (let i = 0; i < filteredItems.length; i++) {
            try {
              // Segment new order track.
              AnalyticsHelper.trackNewOrder(filteredItems[i], ORDER_FETCH_TYPE.poll);
              if (sPrinter.hasBluetoothPermission) {
                await PrinterService.getInstance().schedulePrintOrder(filteredItems[i]);
              }
            } catch (err: any) {
              firebaseCrashlytics.log(`Order# ${filteredItems[i].id} cannot be printed. ${err.toString()}`);
              dispatch(
                showAlert({
                  heading: 'Print Failed',
                  message: `Order# ${filteredItems[i].id} cannot be printed. Please double check your Bluetooth connection with the printer.`,
                }),
              );
            }
          }
        }
        // Remove first element because we already set it via setIncomingOrder.
        filteredItems.splice(0, 1);
        return fulfillWithValue(filteredItems);
      }
      return fulfillWithValue([]);
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

const initialState: IOrder = {
  loading: false,
  loadMoreActiveOrders: true,
  loadMoreCompletedOrders: true,
  activeOrders: [],
  completedOrders: [],
  ordersHistory: [],
  incomingOrder: null,
  activeOrdersCount: 0,
  completedOrdersCount: 0,
  newIncomingOrdersCount: 0,
  acceptedOrderId: '',
  lastOrdersFetchTime: '',
};

const orderSlice = createSlice({
  name: 'order',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setIncomingOrder: (state, action) => {
      state.incomingOrder = action.payload;
      const index = state.activeOrders.findIndex((item) => item.id === action.payload.id);
      if (index === -1) {
        state.activeOrders.unshift(action.payload);
        state.activeOrdersCount++;
      }
    },
    setAcceptedOrderId: (state, action) => {
      state.acceptedOrderId = action.payload;
    },
    acceptOrder: (state, {payload}) => {
      const i = state.activeOrders.findIndex((item) => item.id === payload.id);
      if (i !== -1) {
        state.activeOrders[i].status = ORDER_STATUS.WAITING;
        state.activeOrders[i].bagTime = payload.bagTime;
        state.activeOrders[i].acceptedAt = payload.time;
      }
    },
    processOrder: (state, {payload}) => {
      const order = state.activeOrders.find((order) => order.id === payload.id);
      if (order) {
        if (state.ordersHistory.length > 0) state.ordersHistory.unshift(order);
        const historyIndex = state.ordersHistory.indexOf(order);
        if (historyIndex !== -1) {
          state.ordersHistory[historyIndex].status = payload.status;
          state.ordersHistory[historyIndex].ongoing = false;
        }

        if (state.completedOrders.length > 0) state.completedOrders.unshift(order);
        const completedIndex = state.completedOrders.indexOf(order);
        if (completedIndex !== -1) {
          state.completedOrders[completedIndex].status = payload.status;
          state.completedOrders[completedIndex].ongoing = false;
        }
      }
      state.activeOrders = state.activeOrders.filter((item) => item.id !== payload.id);
      if (state.activeOrdersCount > 0) state.activeOrdersCount--;
      state.completedOrdersCount++;
    },
    readyForPickup: (state, {payload}) => {
      const obj: any = state.activeOrders.find((item) => item.id === payload.id);
      const i = state.activeOrders.indexOf(obj);
      state.activeOrders[i].ongoing = true;
      state.activeOrders[i].status = ORDER_STATUS.WAITING;
      state.activeOrders[i].readyAt = payload.time;
    },
    patchCart: (state, {payload}) => {
      state.activeOrders = state.activeOrders.map((order) =>
        order.id === payload.id ? {...payload, activeIndex: true} : order,
      );
    },
    setOrdersHistory: (state, action) => {
      state.ordersHistory = action.payload;
    },
    setCompletedOrders: (state, action) => {
      state.completedOrders = action.payload;
    },
    setActiveOrders: (state, action) => {
      state.activeOrders = action.payload;
    },
    setNewIncomingOrdersCount: (state, action) => {
      state.newIncomingOrdersCount = action.payload;
    },
    refreshActiveOrders: (state) => {
      state.loadMoreActiveOrders = true;
    },
    refreshCompletedOrders: (state) => {
      state.loadMoreCompletedOrders = true;
    },
    activeOrderIndex: (state, action) => {
      if (action.payload) {
        if (inOrderList(action.payload, state.ordersHistory)) {
          state.ordersHistory = state.ordersHistory.map((item) =>
            item.id === action.payload ? {...item, activeIndex: true} : {...item, activeIndex: false},
          );
        } else if (inOrderList(action.payload, state.activeOrders)) {
          state.activeOrders = state.activeOrders.map((item) =>
            item.id === action.payload ? {...item, activeIndex: true} : {...item, activeIndex: false},
          );
        } else if (inOrderList(action.payload, state.completedOrders)) {
          state.completedOrders = state.completedOrders.map((item) =>
            item.id === action.payload ? {...item, activeIndex: true} : {...item, activeIndex: false},
          );
        }
      } else {
        state.ordersHistory = state.ordersHistory.map((item) => ({...item, activeIndex: false}));
        state.activeOrders = state.activeOrders.map((item) => ({...item, activeIndex: false}));
        state.completedOrders = state.completedOrders.map((item) => ({...item, activeIndex: false}));
      }
    },
    clearOrderSlice: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getOrdersCount.fulfilled, (state, action) => {
        state.activeOrdersCount = action.payload.active;
        state.completedOrdersCount = action.payload.completed;
      })
      .addCase(getActiveOrders.fulfilled, (state, action) => {
        if (action.payload.length < 20) state.loadMoreActiveOrders = false;
        state.activeOrders.push(...action.payload);
        state.lastOrdersFetchTime = moment.utc().format();
      })
      .addCase(getCompletedOrders.fulfilled, (state, action) => {
        if ((action.payload as unknown as any[]).length < 20) state.loadMoreCompletedOrders = false;
        state.completedOrders.push(...(action.payload as unknown as any[]));
      })
      .addCase(getOrdersHistory.fulfilled, (state, action) => {
        state.ordersHistory.push(...action.payload);
      })
      .addCase(getNewIncomingOrders.fulfilled, (state, action) => {
        state.activeOrders.unshift(...action.payload);
        state.lastOrdersFetchTime = moment.utc().format();
      });
  },
});

export const {
  setLoading,
  setIncomingOrder,
  acceptOrder,
  readyForPickup,
  processOrder,
  setOrdersHistory,
  activeOrderIndex,
  clearOrderSlice,
  refreshCompletedOrders,
  setCompletedOrders,
  setNewIncomingOrdersCount,
  refreshActiveOrders,
  setActiveOrders,
  patchCart,
  setAcceptedOrderId,
} = orderSlice.actions;

export default orderSlice.reducer;
