import {useEffect, useState} from 'react';
import {IonButton, IonFooter, IonRadioGroup, IonItem, IonLabel, IonRadio, IonTextarea, IonText} from '@ionic/react';
import {alertCircleOutline} from 'ionicons/icons';
import './styles.scss';
import DenialOrderItem from 'src/pages/Orders/components/DenialOrderItem';
import InstructionsCard from 'src/pages/Orders/components/InstructionsCard';
import {IOrderItemDetails} from 'src/interfaces/item';
import {PATCH_CART_TYPE, DENY_ORDER_REASON} from 'src/enums/order';
import {DENY_ORDER_REASONS} from 'src/utils/constants';

interface IProps {
  order: any;
  outOfStockItems: Array<string>;
  setOutOfStockItems: (orderItemIds: Array<string>) => void;
  updateOrder: (data: any) => void;
  cancelOrder: (reason: string, msg?: string) => void;
}

const DenyOrder = ({order, outOfStockItems, setOutOfStockItems, cancelOrder, updateOrder}: IProps) => {
  const [denyReason, setDenyReason] = useState('');
  const [denyMessage, setDenyMessage] = useState('');

  const outOfStockSelected = denyReason === DENY_ORDER_REASON.ITEM_OUT_OF_STOCK;
  const otherSelected = denyReason === DENY_ORDER_REASON.OTHER;
  const denyOrder = !outOfStockSelected || (outOfStockSelected && order?.orderItems.length === outOfStockItems?.length);

  const closeModal = () => {
    setDenyReason('');
    setDenyMessage('');
  };

  const onDenyOrder = () => {
    if (outOfStockSelected) {
      cancelOrder(denyReason);
    } else {
      cancelOrder(denyReason, otherSelected ? denyMessage : '');
    }
    closeModal();
  };

  const onUpdateOrder = () => {
    if (outOfStockSelected) {
      if (outOfStockItems.length !== order.orderItems.length) {
        updateOrder(
          outOfStockItems.map((itemId) => ({order_item_id: itemId, action_type: PATCH_CART_TYPE.REMOVE_ITEM})),
        );
      } else {
        cancelOrder(denyReason);
      }
      closeModal();
    }
  };

  const onCheckboxClick = (checked: boolean, item: IOrderItemDetails) => {
    if (!checked) {
      setOutOfStockItems(outOfStockItems.filter((orderItemId) => orderItemId !== item.id));
    } else {
      setOutOfStockItems([...outOfStockItems, item.id]);
    }
  };

  useEffect(() => {
    if (order?.orderItems && outOfStockSelected) {
      setOutOfStockItems(order?.orderItems.map((item: IOrderItemDetails) => item.id));
    } else {
      setOutOfStockItems([]);
    }
  }, [order?.orderItems, outOfStockSelected]);

  const substitutedItemPresent = order?.orderItems.some((item: IOrderItemDetails) => item.action === 'substitute');

  return (
    <div id="deny-order" className="modal-content">
      <IonRadioGroup
        className="category-radio-group"
        value={denyReason}
        onIonChange={(e) => setDenyReason(e.detail.value)}>
        {DENY_ORDER_REASONS.map((reason) => (
          <IonItem key={reason.value} onClick={() => setDenyReason(reason.value)}>
            <IonLabel>{reason.label}</IonLabel>
            <IonRadio mode="md" slot="start" value={reason.value} />
          </IonItem>
        ))}
      </IonRadioGroup>

      {otherSelected && (
        <div className="text-area">
          <IonText>Please describe the reason you are canceling this order.</IonText>
          <IonTextarea
            autoGrow={true}
            autofocus={true}
            rows={10}
            minlength={10}
            inputmode="text"
            placeholder="Reason for cancelation"
            value={denyMessage}
            onIonChange={(e) => setDenyMessage(e.detail.value || '')}
          />
          <IonText className="instruction">10 character minimum</IonText>
        </div>
      )}

      {outOfStockSelected && (
        <div className="items-container">
          {order?.orderItems.length !== outOfStockItems.length && (
            <InstructionsCard
              variant="muted"
              title="Denial Invalid"
              description="You may not deny this order for ‘All items out of stock’ unless every ordered item is out of stock."
              icon={alertCircleOutline}
            />
          )}
          {substitutedItemPresent && (
            <InstructionsCard
              variant="muted"
              title="Order denial only"
              description={`You are only able to deny an order from this screen due to a substituted item in the list. \n If all items are not out of stock return to previous screen to complete the order. `}
            />
          )}

          <div>
            <p className="ion-no-margin">Select out of stock items</p>
            <p className="text-muted ion-no-margin">Selected items will be marked as out of stock for future orders</p>
          </div>

          {order?.orderItems.map((item: any, index: number) => (
            <DenialOrderItem
              key={index}
              item={item}
              isChecked={(outOfStockItems && outOfStockItems.includes(item.id)) || substitutedItemPresent}
              isDisabled={order?.orderItems.length === 1 || substitutedItemPresent}
              onCheckboxClick={onCheckboxClick}
            />
          ))}
        </div>
      )}

      <IonFooter>
        <IonButton
          className={denyOrder ? 'btn-danger' : 'btn-dark'}
          disabled={
            !denyReason || (otherSelected && denyMessage.length < 10) || (outOfStockSelected && !outOfStockItems.length)
          }
          expand="block"
          onClick={denyOrder ? onDenyOrder : onUpdateOrder}>
          {denyOrder ? 'Deny order' : `Remove item${outOfStockItems?.length > 1 ? 's' : ''}`}
        </IonButton>
      </IonFooter>
    </div>
  );
};

export default DenyOrder;
