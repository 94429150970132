/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {IonBackButton, IonButton, IonContent, IonFooter, IonIcon, IonPage} from '@ionic/react';
import './styles.scss';
import {closeOutline} from 'ionicons/icons';
import {Controller, useForm} from 'react-hook-form';
import {RouteComponentProps} from 'react-router';
import Header from 'src/components/Header';
import Input from 'src/components/Input';
import RolesDropdown from 'src/pages/Store/Employees/components/RolesDropdown';
import Snackbar from 'src/components/Snackbar';
import DeleteEmployeeModal from 'src/pages/Store/Employees/components/DeleteEmployeeModal';
import {INPUT_MASK} from 'src/enums/input_mask';
import {useAppDispatch, useAppSelector} from 'src/hooks';
import {IEmployeeDetailFormInput} from 'src/interfaces/form';
import {getAllRoles} from 'src/store/authSlice';
import {deleteEmployee, updateEmployee} from 'src/store/userSlice';
import {RULES} from 'src/utils/constants';
import arrowBack from 'src/assets/images/arrow-back-black.svg';

interface IProps extends RouteComponentProps {
  location: any;
  setShowEditEmployee?: (show: boolean) => void;
}

const EditEmployee = ({history, location, setShowEditEmployee}: IProps) => {
  const dispatch = useAppDispatch();
  const user = location.state?.employee;

  const [showDeleteModal, setShowDeleteModal] = useState<boolean>(false);
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [name, setName] = useState(user?.firstName + ' ' + user?.lastName);

  const {isDesktop, isTablet} = useAppSelector((state) => state.platform);
  const {menuIsOpen} = useAppSelector((state) => state.auth);
  const {isRBACStore} = useAppSelector((state) => state.store);

  const {control, handleSubmit, reset} = useForm<IEmployeeDetailFormInput>({
    mode: 'onSubmit',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      role: '',
    },
  });

  const updateUser = (data: IEmployeeDetailFormInput) => {
    dispatch(updateEmployee({id: user?.id, ...data}))
      .unwrap()
      .then(() => {
        setName(data?.firstName + ' ' + data?.lastName);
        setShowSnackbar(true);
      });
  };

  const removeUser = (id: number) => {
    dispatch(deleteEmployee(id))
      .unwrap()
      .then((originalPromiseResult) => {
        if (isDesktop) {
          if (setShowEditEmployee) setShowEditEmployee(false);
        } else history.goBack();
      });
  };

  const dismissSnackbar = () => {
    setShowSnackbar(false);
  };

  const onClose = () => {
    if (setShowEditEmployee) setShowEditEmployee(false);
  };

  useEffect(() => {
    if (isRBACStore) {
      dispatch(getAllRoles());
    }
    setName(user?.firstName + ' ' + user?.lastName);
    // Setting data to hook form state.
    reset({
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      phone: user?.phone,
      role: user?.role.id,
    });
  }, []);

  return (
    <IonPage id="edit-employee-page" className={`${!menuIsOpen ? 'menu-close' : ''}`}>
      <Header
        border
        noBackground
        leftButton={!isDesktop && <IonBackButton defaultHref="/store/manage/employees" text="" icon={arrowBack} />}
        rightButton={
          isDesktop && (
            <IonButton onClick={onClose}>
              <IonIcon src={closeOutline} />
            </IonButton>
          )
        }
        title={name}
      />
      <IonContent className={`${isTablet ? 'page-container' : ''}`}>
        <div className="body">
          <h2 className="title">Employee details</h2>

          <Input
            type="text"
            wrapperClassName="input-field"
            name="firstName"
            label="First Name"
            placeholder="First Name"
            control={control}
            rules={RULES.required}
          />

          <Input
            type="text"
            wrapperClassName="input-field"
            name="lastName"
            label="Last Name"
            placeholder="Last Name"
            control={control}
            rules={RULES.required}
          />

          <Input
            type="text"
            wrapperClassName="input-field"
            inputmode="numeric"
            name="phone"
            label="Phone Number"
            placeholder="Phone Number"
            control={control}
            rules={RULES.phoneNumber}
            mask={INPUT_MASK.PHONE}
          />

          <div className="divider"></div>
          <h2 className="title">Login Credentials</h2>

          <Input
            type="email"
            wrapperClassName="input-field"
            inputmode="email"
            name="email"
            label="E-Mail"
            placeholder="E-Mail"
            control={control}
            rules={RULES.email}
          />

          <Controller
            name="password"
            control={control}
            render={({fieldState: {isDirty}}) => (
              <Input
                type="password"
                wrapperClassName="input-field"
                name="password"
                label="Password"
                placeholder="Password"
                control={control}
                rules={{validate: isDirty ? RULES.password.validate : true}}
              />
            )}
          />

          <div className="divider"></div>
          <h2 className="title">Permissions</h2>
          <RolesDropdown control={control} />

          <div className="divider"></div>
          <IonButton className="btn-remove" fill="clear" expand="block" onClick={() => setShowDeleteModal(true)}>
            <IonIcon slot="start" className="icon-trash" size="small" />
            Delete employee
          </IonButton>
        </div>

        <DeleteEmployeeModal
          showDeleteModal={showDeleteModal}
          setShowDeleteModal={setShowDeleteModal}
          employeeId={user?.id}
          employeeName={name}
          deleteEmployee={(id) => removeUser(id || 0)}
        />
      </IonContent>
      <Snackbar
        show={showSnackbar}
        type="success"
        title="Update successful"
        onButtonClick={dismissSnackbar}
        onDismiss={dismissSnackbar}
      />
      <IonFooter>
        <IonButton className="btn-dark" expand="block" onClick={handleSubmit(updateUser)}>
          Save update
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default EditEmployee;
