import {IonButton, IonIcon, IonModal} from '@ionic/react';
import {closeOutline} from 'ionicons/icons';
import './styles.scss';
import Header from 'src/components/Header';

interface IProps {
  employeeId: number;
  employeeName: string;
  showDeleteModal: boolean;
  deleteEmployee: (id: number) => void;
  setShowDeleteModal: (e: boolean) => void;
}

const DeleteEmployeeModal = (props: IProps) => (
  <IonModal
    id="delete-option"
    isOpen={props.showDeleteModal}
    showBackdrop
    onDidDismiss={() => props.setShowDeleteModal(false)}>
    <div className="modal-content-container">
      <div className="modal-content">
        <Header
          border
          noBackground
          title="Delete"
          rightButton={<IonIcon onClick={() => props.setShowDeleteModal(false)} icon={closeOutline} />}
        />
        <div className="modal-body">
          <div className="label">Are you sure you want to delete {props.employeeName}?</div>
          <div className="caution">This action can not be undone.</div>

          <div className="action">
            <IonButton className="btn-outline" onClick={() => props.setShowDeleteModal(false)}>
              Cancel
            </IonButton>
            <IonButton
              className="btn-danger"
              onClick={() => {
                props.deleteEmployee(props.employeeId);
                props.setShowDeleteModal(false);
              }}>
              <IonIcon slot="start" className="icon-trash" size="small" />
              Delete
            </IonButton>
          </div>
        </div>
      </div>
    </div>
  </IonModal>
);

export default DeleteEmployeeModal;
