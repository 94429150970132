import './styles.scss';
import {useState, useEffect} from 'react';
import {IonToggle, IonItem, IonLabel, IonCheckbox, IonImg} from '@ionic/react';
import email from 'src/assets/images/email.svg';
import sms from 'src/assets/images/sms.svg';

interface ICheckboxValues {
  mobile: boolean;
  sms: boolean;
  web: boolean;
  email: boolean;
}

interface IProps {
  title: string;
  description: string;
  value: ICheckboxValues;
  canEdit?: boolean;
  onChange: (value: ICheckboxValues) => void;
  setDisabled: (value: boolean) => void;
}

const NotificationItem = ({title, description, value, onChange, setDisabled, canEdit = true}: IProps) => {
  const [expanded, setExpanded] = useState(true);

  useEffect(() => {
    if (!expanded) onChange({mobile: false, sms: false, web: false, email: false});
  }, [expanded]);

  /* For Email, Mobile and SMS notifications only */
  useEffect(() => {
    if (value) setExpanded(value?.email || value?.sms);
  }, [value?.email, value?.sms]);

  return (
    <div id="notification-item">
      <div className="header">
        <div className="title-container">
          <p className="title">{title}</p>
          <p className="desc">{description}</p>
        </div>
        <IonToggle
          disabled={!canEdit}
          checked={expanded}
          onIonChange={(e) => {
            setDisabled(false);
            setExpanded(e.detail.checked);
          }}
        />
      </div>
      {expanded && (
        <div className="content">
          <div>
            <IonItem
              className={`${value?.email ? 'selected' : ''} right`}
              onClick={() => {
                setDisabled(false);
                onChange({...value, email: !value?.email});
              }}>
              <IonImg src={email} slot="start" />
              <IonLabel>E-Mail</IonLabel>
              <IonCheckbox mode="md" slot="end" value="email" checked={value?.email} disabled={!canEdit} />
            </IonItem>

            <IonItem
              className={`${value?.sms ? 'selected' : ''} left`}
              onClick={() => {
                setDisabled(false);
                onChange({...value, sms: !value?.sms});
              }}>
              <IonImg src={sms} slot="start" />
              <IonLabel>SMS</IonLabel>
              <IonCheckbox mode="md" slot="end" value="sms" checked={value?.sms} disabled={!canEdit} />
            </IonItem>
          </div>
        </div>
      )}
    </div>
  );
};

export default NotificationItem;
