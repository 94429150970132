import './styles.scss';
import {IonIcon, IonInput, IonItem, IonLabel} from '@ionic/react';
import {PRICE_UPDATE_OPTION, PRICE_VALIDATE_OPTION} from 'src/enums/inventory_sorting';
import {useAppSelector, useAppDispatch} from 'src/hooks';
import {useEffect} from 'react';
import {setIsValidInputPrice} from 'src/store/inventorySlice';
import {checkValidPrice} from 'src/utils/helpers';

interface IProps {
  type: PRICE_UPDATE_OPTION;
  isValidPrice: boolean;
  setIsValidPrice: (e: boolean) => void;
  newPrice: number | string;
  setNewPrice: (e: number | string) => void;
  validatePriceInput: (data: any, active: boolean) => void;
  isInStock: boolean;
  setIsPositiveSelect: (e: boolean) => void;
  isPositiveSelect: boolean;
}

const PriceInput = (props: IProps) => {
  const dispatch = useAppDispatch();
  const {isDesktop} = useAppSelector((state) => state.platform);
  const {isValidInputPrice} = useAppSelector((state) => state.inventory);

  const typeFilter = () => {
    return PRICE_VALIDATE_OPTION[props.type];
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (props.type !== PRICE_UPDATE_OPTION.new_price) {
        if (props.newPrice !== '') {
          props.validatePriceInput(
            {
              price: props.isPositiveSelect ? Number(props.newPrice) : Number(props.newPrice) * -1,
              action_type: typeFilter(),
            },
            props.isInStock,
          );
        } else {
          dispatch(setIsValidInputPrice(true));
        }
      }
    }, 750);
    return () => clearTimeout(delayDebounceFn);
  }, [props.newPrice]);

  return (
    <>
      <div id="price-input" className={`${isDesktop ? 'desktop' : ''}`}>
        {props.type !== PRICE_UPDATE_OPTION.new_price && (
          <div className="price-btn">
            <div
              className={`border positive ${props.isPositiveSelect ? 'price-selected' : ''}`}
              onClick={() => {
                props.setIsPositiveSelect(true);
                if (props.newPrice !== '') {
                  props.validatePriceInput(
                    {
                      price: Number(props.newPrice),
                      action_type: typeFilter(),
                    },
                    props.isInStock,
                  );
                }
              }}>
              <IonIcon className={'icon-add'} />
            </div>
            <div
              className={`border negative ${!props.isPositiveSelect ? 'price-selected' : ''}`}
              onClick={() => {
                props.setIsPositiveSelect(false);
                if (props.newPrice !== '') {
                  props.validatePriceInput(
                    {
                      price: Number(props.newPrice) * -1,
                      action_type: typeFilter(),
                    },
                    props.isInStock,
                  );
                }
              }}>
              <IonIcon className={'icon-remove'} />
            </div>
          </div>
        )}

        <div className="price-container">
          <IonItem className={`item-price ${!props.isValidPrice ? 'invalid' : ''}`}>
            {props.type !== PRICE_UPDATE_OPTION.percentage && <IonLabel>$</IonLabel>}
            <IonInput
              type="number"
              min="0"
              inputmode="numeric"
              placeholder="0.00"
              value={props.newPrice}
              onIonChange={async (e) => {
                props.setNewPrice(e.detail.value === '' ? e.detail.value : Number(e.detail.value));
                if (props.type === PRICE_UPDATE_OPTION.new_price) {
                  if (checkValidPrice(Number(e.detail.value))) {
                    props.setIsValidPrice(true);
                  } else if (e.detail.value === '') {
                    props.setIsValidPrice(true);
                  } else {
                    props.setIsValidPrice(false);
                  }
                }
              }}
            />
            {props.type === PRICE_UPDATE_OPTION.percentage && <IonIcon className={'icon-percent'}></IonIcon>}
            {!props.isValidPrice && <IonIcon className={'icon-exclamation invalid'}></IonIcon>}
          </IonItem>
          {!props.isValidPrice && props.type === PRICE_UPDATE_OPTION.new_price && (
            <p className="err">Value must be between $0.49 - 199.99</p>
          )}
        </div>
      </div>
      {!isValidInputPrice && props.type !== PRICE_UPDATE_OPTION.new_price && (
        <div className={`warning-box ${isDesktop ? 'desktop' : ''}`}>
          <IonIcon className={'icon-warning-alert'}></IonIcon>
          <p>
            One or more of your selected items price is going {props.isPositiveSelect ? 'above' : 'below'} the price
            range (0.49 - 199.99).
          </p>
        </div>
      )}
    </>
  );
};

export default PriceInput;
