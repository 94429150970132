import {useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router';
import {IonPage, IonContent, IonBackButton, IonFooter, IonButton, IonIcon} from '@ionic/react';
import {closeOutline} from 'ionicons/icons';
import './styles.scss';
import moment from 'moment';
import OrderPickupModal from 'src/components/modals/OrderPickup';
import OrderStatusBar from 'src/pages/Orders/components/OrderStatusBar';
import OrderReceipt from 'src/pages/Orders/components/OrderReceipt';
import OrderDetailsList from 'src/pages/Orders/components/OrderDetailsList';
import OrderTimeline from 'src/pages/Orders/components/OrderTimeline';
import NoItem from 'src/components/NoItem';
import Header from 'src/components/Header';
import EditOrderModal from '../components/EditOrderModal';
import {ORDER_STATUS} from 'src/enums/order';
import {ROUTE_LOCATION} from 'src/enums/route_location';
import {useAppDispatch, useAppSelector, useEffectOnce} from 'src/hooks';
import {IOrderItem} from 'src/interfaces/item';
import orderService from 'src/services/order';
import {showAlert} from 'src/store/alertSlice';
import {hideLoading, showLoading} from 'src/store/loadingSlice';
import {activeOrderIndex, processOrder, readyForPickup, setAcceptedOrderId} from 'src/store/orderSlice';
import {checkPermission, hideIntercomLauncherBtn, showIntercomLauncherBtn} from 'src/utils/helpers';
import AnalyticsHelper from 'src/utils/segment';
import * as PERMISSIONS from 'src/utils/permissions';
import arrowBack from 'src/assets/images/arrow-back-black.svg';
import {PARTNER} from 'src/enums/partner';
import {useFeatureFlagEnabled} from 'posthog-js/react';

interface IProps extends RouteComponentProps {
  location: any;
  setShowDetails: (show: boolean) => void;
}

const OrdersDetails = ({history, location, setShowDetails}: IProps) => {
  const dispatch = useAppDispatch();
  const {isDesktop, isTablet} = useAppSelector((state) => state.platform);
  const {incomingOrder} = useAppSelector((state) => state.order);
  const {menuIsOpen, user} = useAppSelector((state) => state.auth);
  const {id: storeId} = useAppSelector((state) => state.store);

  const canEditOrder = checkPermission(user?.role.permissions, PERMISSIONS.EDIT_ORDERS);

  const [openPickupModal, setOpenPickupModal] = useState(false);
  const [order, setOrder] = useState<IOrderItem>();
  const [showEditModal, setShowEditModal] = useState(false);
  const [checkedItems, setCheckedItems] = useState<Array<string>>([]);
  const isLingeringOrderHandlerEnabled = useFeatureFlagEnabled('lingering-order-handler');

  const fetchOrderDetails = (orderId: string) => {
    dispatch(showLoading());
    orderService
      .getOrderDetails(storeId, orderId)
      .then((response: any) => {
        dispatch(hideLoading());
        setOrder(response);
      })
      .catch((error) => {
        dispatch(hideLoading());
        dispatch(
          showAlert({
            heading: 'Error',
            message: error?.message || 'Some error occurred while fetching order details. Please, try again later',
          }),
        );
      });
  };

  const updateLingeringOrder = (order: any, callback: any) => {
    dispatch(showLoading());
    if (order?.storeId && order?.id) {
      orderService
        .updateLingeringOrder(order?.storeId, order?.id)
        .then((response) => {
          dispatch(hideLoading());
          if (response?.success) {
            dispatch(processOrder({id: order?.id, status: response?.status}));
            fetchOrderDetails(order?.id);
            if (isDesktop) {
              if (setShowDetails) {
                setShowDetails(false);
                dispatch(activeOrderIndex(null));
              }
            } else history.goBack();
          } else {
            callback();
          }
        })
        .catch((error) => {
          dispatch(hideLoading());
          callback();
        })
        .finally(() => {
          dispatch(hideLoading());
        });
    } else {
      dispatch(hideLoading());
    }
  };

  const showPickupModal = (item: IOrderItem) => {
    dispatch(showLoading());
    orderService
      .markOrderReadyForPickup(location.state?.id || incomingOrder?.id)
      .then((_) => {
        dispatch(hideLoading());
        setOpenPickupModal(true);
        fetchOrderDetails(location.state?.id || incomingOrder?.id);
        dispatch(readyForPickup({id: location.state?.id || incomingOrder?.id, time: moment().format()}));
        AnalyticsHelper.trackMarkReadyForPickup(location.state?.id || incomingOrder?.id);
        const a = {...item, ongoing: true, readyAt: moment().format()};
        setOrder(a);
      })
      .catch((error) => {
        dispatch(hideLoading());
        const onErrorShowDispatch = () => {
          dispatch(
            showAlert({
              heading: 'Error',
              message: error?.message || 'Sorry! Cannot mark your order ready for pickup. Try again later',
            }),
          );
        };
        if (item.partner === PARTNER.UBER_EATS && isLingeringOrderHandlerEnabled) {
          updateLingeringOrder(order, onErrorShowDispatch);
        } else {
          onErrorShowDispatch();
        }
      });
  };

  const pickedUpByRider = (item: IOrderItem) => {
    dispatch(showLoading());
    orderService
      .deliverOrder(storeId, location.state?.id || incomingOrder?.id)
      .then((_) => {
        dispatch(hideLoading());
        dispatch(processOrder({id: item.id, status: ORDER_STATUS.COMPLETED}));
        fetchOrderDetails(location.state?.id || incomingOrder?.id);
        AnalyticsHelper.trackOrderCompleted(order);
        if (isDesktop) {
          if (setShowDetails) {
            setShowDetails(false);
            dispatch(activeOrderIndex(null));
          }
        } else history.goBack();
      })
      .catch((error) => {
        dispatch(hideLoading());
        dispatch(
          showAlert({
            heading: 'Error',
            message: error?.message || 'Sorry! Cannot mark your order as delivered. Try again later',
          }),
        );
      });
  };

  const hidePickupModal = () => {
    setOpenPickupModal(false);
  };

  const goBackToOrders = () => {
    setOpenPickupModal(false);
    history.replace('/orders');
  };

  const onClose = () => {
    if (setShowDetails) {
      setShowDetails(false);
      dispatch(activeOrderIndex(null));
      dispatch(setAcceptedOrderId(''));
    }
  };

  const goToOrders = () => {
    // Redirecting back to orders.
    if (isDesktop) {
      if (setShowDetails) {
        setShowDetails(false);
        dispatch(activeOrderIndex(null));
      }
    } else history.replace('/orders');
  };

  useEffectOnce(() => {
    if (!isDesktop) {
      fetchOrderDetails(location.state?.id || incomingOrder?.id);
    }
  });

  useEffect(() => {
    if (isDesktop) {
      fetchOrderDetails(location.state?.id || incomingOrder?.id);
    }
  }, [location.state?.id]);

  const fromOrderHistory = location.state?.from === ROUTE_LOCATION.ORDER_HISTORY;
  const fromOrders = location.state?.from === ROUTE_LOCATION.ORDER_LIST;
  const showFooter = fromOrders && order?.status === ORDER_STATUS.WAITING;
  const showEditOrder = !order?.ongoing && order?.status === ORDER_STATUS.WAITING && canEditOrder;
  const pickupDisabled = order?.orderItems?.some((item) => !checkedItems.includes(item.id)) && !order?.readyAt;
  const orderNumber = order?.dspOrderNumber;

  return (
    <>
      <IonPage id="orders-details-page" className={` ${isTablet && !menuIsOpen ? 'menu-close' : ''} snow-background`}>
        <IonContent fullscreen scrollY={false} className={`${isTablet ? 'page-container' : ''}`}>
          <Header
            noBackground
            title={order?.customerName}
            subtitle={orderNumber ? `${order?.partner} #${orderNumber}` : ''}
            leftButton={
              !isDesktop && (
                <IonBackButton
                  defaultHref={fromOrderHistory ? '/orders/history' : '/orders'}
                  text=""
                  icon={arrowBack}
                />
              )
            }
            rightButton={
              isDesktop && (
                <IonButton onClick={onClose}>
                  <IonIcon src={closeOutline} />
                </IonButton>
              )
            }
          />

          <OrderStatusBar order={order} completionDate={order ? order.orderCompletionDate : moment().format()} />

          <div className={`body ${fromOrderHistory || !showFooter ? 'without-footer' : 'with-footer'}`}>
            {order ? (
              <>
                <OrderReceipt
                  order={order}
                  isSelectable={showEditOrder}
                  checkedItems={checkedItems}
                  setCheckedItems={setCheckedItems}
                />
                <OrderDetailsList order={order} />
                <OrderTimeline timelineLogs={order?.timelineLogs} />
              </>
            ) : (
              <NoItem message="No Order here!" />
            )}
          </div>

          {showFooter && (
            <IonFooter className={showEditOrder ? 'd-flex ion-justify-content-between' : ''}>
              {showEditOrder && (
                <IonButton
                  className="btn-outline btn-edit"
                  expand="block"
                  onClick={() => {
                    setShowEditModal(true);
                    hideIntercomLauncherBtn();
                    AnalyticsHelper.trackEditOrderClick(order?.id);
                  }}>
                  <IonIcon className="icon-pencil" slot="start" />
                  Edit Order
                </IonButton>
              )}
              <IonButton
                className="btn-dark"
                expand="block"
                disabled={pickupDisabled}
                onClick={() => {
                  if (order) {
                    if (order?.readyAt) {
                      pickedUpByRider(order);
                    } else {
                      showPickupModal(order);
                    }
                  }
                }}>
                {order?.readyAt ? (
                  <>
                    <IonIcon className="icon-complete-2" slot="start" />
                    Picked Up by Driver
                  </>
                ) : (
                  <>
                    <IonIcon className="icon-car" slot="start" />
                    Ready for Pickup
                  </>
                )}
              </IonButton>
            </IonFooter>
          )}
        </IonContent>
      </IonPage>

      <OrderPickupModal isOpen={openPickupModal} onBack={goBackToOrders} onClose={hidePickupModal} />

      <EditOrderModal
        isOpen={showEditModal}
        order={order}
        setOrder={setOrder}
        fromOrders={fromOrders}
        goToOrders={goToOrders}
        fetchOrder={fetchOrderDetails}
        closeEditModal={() => {
          setShowEditModal(false);
          showIntercomLauncherBtn();
        }}
      />
    </>
  );
};

export default OrdersDetails;
