import {IonContent, IonButton, IonLabel, IonFooter, IonIcon} from '@ionic/react';
import './styles.scss';
import {closeOutline} from 'ionicons/icons';
import {ICategory, IInventoryStats, IQueryObject} from 'src/interfaces/item';
import {IMenuList} from 'src/interfaces/store';
import Header from 'src/components/Header';
import PriceFilter from 'src/pages/Inventory/Filtering/components/PriceFilter';
import DateFilter from 'src/pages/Inventory/Filtering/components/DateFilter';
import CategoryFilter from 'src/pages/Inventory/Filtering/components/CategoryFilter';
import MenuFilter from 'src/components/MenuFilter';
import ItemStatusFilter from '../components/ItemStatusFilter';
import {ITEM_STATUS} from 'src/enums/item_status';

interface IProps {
  isDesktop: boolean;
  inventoryTab: string;
  categories: Array<ICategory>;
  menuList: Array<IMenuList>;
  filterObject: IQueryObject;
  inventoryStats: IInventoryStats;
  selectFilter: (e: any) => void;
  onFilter: () => void;
  onClose: () => void;
  onReset: () => void;
  closeFilterPage: () => void;
  onNewMenuClick: () => void;
}

const DesktopScreen = (props: IProps) => (
  <div id="item-filter-desktop">
    <IonContent scrollY className="page-container snow-background">
      <Header
        noBackground
        title="Filter"
        rightButton={
          <IonButton className="reset" onClick={props.closeFilterPage}>
            <IonIcon icon={closeOutline} />
          </IonButton>
        }
        leftButton={
          <IonButton className="reset" onClick={props.onReset}>
            Reset
          </IonButton>
        }
      />
      <div className="filter-group price-group ">
        <IonLabel className="filter-title">Price</IonLabel>
        <PriceFilter
          inventoryStats={props.inventoryStats}
          filterObject={props.filterObject}
          selectFilter={props.selectFilter}
        />
      </div>

      <div className="filter-group ">
        <div className="filter-title-group">
          <IonLabel className="filter-title">
            Date<span className="filter-sub-title">Items added in the last</span>
          </IonLabel>
        </div>
        <DateFilter isDesktop={props.isDesktop} filterObject={props.filterObject} selectFilter={props.selectFilter} />
      </div>

      {/* {props.inventoryTab === ITEM_STATUS.ARCHIVED && (
        <div className="filter-group ">
          <div className="filter-title-group">
            <IonLabel className="filter-title">Item status</IonLabel>
          </div>
          <ItemStatusFilter
            isDesktop={props.isDesktop}
            filterObject={props.filterObject}
            selectFilter={props.selectFilter}
          />
        </div>
      )} */}

      <div className="filter-group">
        <IonLabel className="filter-title">Categories</IonLabel>
        <CategoryFilter
          categories={props.categories}
          filterObject={props.filterObject}
          selectFilter={props.selectFilter}
          isDesktop={props.isDesktop}
        />
      </div>

      <div className="filter-group">
        <IonLabel className="filter-title">Menu</IonLabel>
        <MenuFilter
          isFilter={true}
          menuList={props.menuList}
          filterObject={props.filterObject}
          selectFilter={props.selectFilter}
          isDesktop={props.isDesktop}
          onNewMenuClick={props.onNewMenuClick}
        />
      </div>
      <IonFooter>
        <IonButton className="btn-primary mb-0" expand="block" onClick={props.onClose}>
          Show Results
        </IonButton>
      </IonFooter>
    </IonContent>
  </div>
);

export default DesktopScreen;
