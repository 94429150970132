export enum DENY_ORDER_REASON {
  ITEM_OUT_OF_STOCK = 'ITEM_OUT_OF_STOCK',
  CUSTOMER_CALLED_TO_CANCEL = 'CUSTOMER_CALLED_TO_CANCEL',
  ITEM_ISSUE = 'ITEM_ISSUE',
  PRICING = 'PRICING',
  STORE_CLOSED = 'STORE_CLOSED',
  STORE_BUSY = 'STORE_BUSY',
  SPECIAL_INSTRUCTIONS = 'SPECIAL_INSTRUCTIONS',
  OTHER = 'OTHERS',
}

export enum ORDER_CUSTOM_FEES {
  BAG_FEE = 'BAG_FEE',
}

export enum ORDER_FETCH_TYPE {
  poll = 'polled',
  socket = 'socket',
}

export enum ORDER_STATUS {
  INCOMING = 'incoming',
  COMPLETED = 'completed',
  EXPIRED = 'expired',
  WAITING = 'waiting',
  CANCELLED = 'cancelled',
  DENIED = 'denied',
}

export enum ORDER_BY {
  DESC = 'DESC',
  ASC = 'ASC',
}

export enum PATCH_CART_TYPE {
  REMOVE_ITEM = 'remove_item',
  REDUCE_QUANTITY = 'reduce_quantity',
  REPLACE_ITEM = 'replace_item',
}

export enum ORDER_TIMELINE {
  ORDER_RECEIVED = 'order received',
  BAGGING_ORDER = 'bagging order',
  MARKED_READY_FOR_PICK_UP = 'order marked ready for pick up',
  MARKED_READY_FOR_PICK_UP_BY_GRUB_HUB = 'order marked ready for pick up by grubhub',
  MARKED_READY_FOR_PICK_UP_BY_DOOR_DASH = 'order marked ready for pick up by doordash',
  MARKED_READY_FOR_PICK_UP_BY_UBER_EATS = 'order marked ready for pick up by ubereats',
  WAITING_FOR_DRIVER = 'waiting for driver',
  ITEM_REMOVED = 'item removed',
  ITEM_QUANTITY_EDITED = 'item quantity edited',
  ORDER_CANCELLED = 'order cancelled',
  ORDER_DENIED = 'order denied',
  PICKED_UP_BY_DRIVER = 'order picked up by driver',
  PICKED_UP_BY_GRUB_HUB_RIDER = 'order has been picked up by grubhub rider',
  PICKED_UP_BY_DOOR_DASH_RIDER = 'order has been picked up by doordash rider',
  PICKED_UP_BY_UBER_EATS_RIDER = 'order has been picked up by ubereats rider',
  ITEM_SUBSTITUTED = 'item substituted',
}


