import {DENY_ORDER_REASON, ORDER_BY, ORDER_STATUS} from 'src/enums/order';
import {PARTNER} from 'src/enums/partner';
import {QUERY_OBJECT} from 'src/enums/query_object';
import {IItem, IQueryObject} from 'src/interfaces/item';
import {IPasswordRequirements} from 'src/interfaces/form';

export const HELP_URL = 'https://help.luladelivery.com';
export const TERMS_URL = 'https://www.luladelivery.com/terms-of-service';
export const PRIVACY_URL = 'https://www.luladelivery.com/privacy-policy';
export const SHOP_URL = 'https://www.luladelivery.store/shop?Collection=Lula+Store+Materials';
export const PARTNERS_URL = 'https://www.lulaconvenience.com/get-started';
export const LULA_MANAGER_URL = 'https://www.lulastoremanager.com/';
export const WEEK_DAYS = [
  {day_of_week: 6, title: 'Sunday', disabled: false, selected: false},
  {day_of_week: 0, title: 'Monday', disabled: false, selected: false},
  {day_of_week: 1, title: 'Tuesday', disabled: false, selected: false},
  {day_of_week: 2, title: 'Wednesday', disabled: false, selected: false},
  {day_of_week: 3, title: 'Thursday', disabled: false, selected: false},
  {day_of_week: 4, title: 'Friday', disabled: false, selected: false},
  {day_of_week: 5, title: 'Saturday', disabled: false, selected: false},
];
export const HOURS = [
  '12:00 AM',
  '12:30 AM',
  '01:00 AM',
  '01:30 AM',
  '02:00 AM',
  '02:30 AM',
  '03:00 AM',
  '03:30 AM',
  '04:00 AM',
  '04:30 AM',
  '05:00 AM',
  '05:30 AM',
  '06:00 AM',
  '06:30 AM',
  '07:00 AM',
  '07:30 AM',
  '08:00 AM',
  '08:30 AM',
  '09:00 AM',
  '09:30 AM',
  '10:00 AM',
  '10:30 AM',
  '11:00 AM',
  '11:30 AM',
  '12:00 PM',
  '12:30 PM',
  '01:00 PM',
  '01:30 PM',
  '02:00 PM',
  '02:30 PM',
  '03:00 PM',
  '03:30 PM',
  '04:00 PM',
  '04:30 PM',
  '05:00 PM',
  '05:30 PM',
  '06:00 PM',
  '06:30 PM',
  '07:00 PM',
  '07:30 PM',
  '08:00 PM',
  '08:30 PM',
  '09:00 PM',
  '09:30 PM',
  '10:00 PM',
  '10:30 PM',
  '11:00 PM',
  '11:30 PM',
];

export const REPORTS_FILTERS = {
  intervals: [
    {label: 'Today', value: 'today'},
    {label: 'Last Week', value: 'week'},
    {label: 'Last Month', value: 'month'},
    {label: 'Custom', value: 'custom'},
  ],
  partners: [
    {label: PARTNER.UBER_EATS, value: PARTNER.UBER_EATS},
    {label: PARTNER.DOOR_DASH, value: PARTNER.DOOR_DASH},
    {label: PARTNER.GRUB_HUB, value: PARTNER.GRUB_HUB},
  ],
};

export const ITEM_PRICE_RANGE = {
  min: 0.49,
  max: 199.99,
};

export const HISTORY_FILTERS = {
  type: [
    {label: 'Completed', value: ORDER_STATUS.COMPLETED},
    {label: 'Denied', value: ORDER_STATUS.DENIED},
    {label: 'Cancelled', value: ORDER_STATUS.CANCELLED},
  ],
  sortBy: [
    {label: 'Ascending', value: ORDER_BY.ASC},
    {label: 'Descending', value: ORDER_BY.DESC},
  ],
};

export const DENY_ORDER_REASONS = [
  {label: 'Customer called to cancel', value: DENY_ORDER_REASON.CUSTOMER_CALLED_TO_CANCEL},
  {label: 'Unable to complete customer instructions', value: DENY_ORDER_REASON.SPECIAL_INSTRUCTIONS},
  {label: 'Customer did not want to substitute item', value: DENY_ORDER_REASON.ITEM_ISSUE},
  {label: 'All item prices incorrect', value: DENY_ORDER_REASON.PRICING},
  {label: 'Store is closed', value: DENY_ORDER_REASON.STORE_CLOSED},
  {label: 'Store is too busy', value: DENY_ORDER_REASON.STORE_BUSY},
  {label: 'All items out of stock', value: DENY_ORDER_REASON.ITEM_OUT_OF_STOCK},
  {label: 'Other', value: DENY_ORDER_REASON.OTHER},
];

export const DEFAULT_QUERY_OBJECT: IQueryObject = {
  order: QUERY_OBJECT.CATEGORY,
  sortDir: QUERY_OBJECT.ASC,
  minPrice: 0,
  maxPrice: 99999,
  startDate: new Date(0).getTime(),
  endDate: Date.now(),
  categories: [],
  menuList: [],
  searchString: '',
  active: true,
};

export const EMPTY_ITEM: IItem = {
  id: '',
  storeItemId: '',
  price: '',
  quantity: 0,
  image: '',
  name: '',
  active: true,
  selected: false,
  completed: false,
  createdAt: 0,  
  category: {id: '', name: ''},
  subcategory: {id: '', name: ''},
  upc: '',
  ean: '',
  size: '',
  description: '',
};

interface Map {
  [key: string]: string | undefined;
}

export const ROLE_ICON: Map = {
  admin: 'icon-cog-outlined',
  super_admin: 'icon-cog-filled',
  staff: 'icon-staff-outlined',
  staff_plus: 'icon-staff-filled',
  manager: 'icon-bag-outlined',
  supervisor: 'icon-bag-filled',
};

// Common react-hook-form rules
export const RULES = {
  required: {
    required: 'Value is required',
  },
  name: {
    required: 'Value is required',
    validate: {
      isMinLength: (value: string) => value.length >= 2 || 'Minimum of two characters',
    },
  },
  email: {
    required: 'Value is required',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      message: 'Invalid email',
    },
  },
  password: {
    required: 'Value is required',
    validate: {
      hasCapitalLetter: (v: string) => /^(?=.*[A-Z])/.test(v) || 'Password must include 1 capital letter',
      hasNumber: (v: string) => /^(?=.*\d)/.test(v) || 'Password must include 1 number',
      hasSpecialCharacter: (v: string) =>
        /^(?=.*[!@#$%^&*()_~\-`\\/"'+|[\]}{:;'?/>.<,])(?!.*\s)(?!.*\s)/.test(v) ||
        'Password must include 1 special character',
      isMinLength: (value: string) => value.length >= 8 || 'Minimum length required is 8',
    },
  },
  code: {
    required: 'Value is required',
    validate: {
      hasValidLength: (value: string) => value.length === 6 || 'Verification code must be at least 6 characters long',
    },
  },
  phoneNumber: {
    required: 'Value is required',
    validate: {
      isNumeric: (value: string) => /^\+[0-9\s]*$/.test(value) || 'Only numeric input is allowed',
      minLength: (value: string) => value.replace(/\s/g, '').length > 9 || 'Minimum length required is 8',
    },
  },
  price: {
    required: 'Item must have price',
    validate: (value: string) =>
      (Number(value) >= 0.49 && Number(value) <= 199.99) || 'Value must be between $0.49 - 199.99',
  },
  modifiersEnabledPrice: {
    required: 'Item must have price',
    validate: (value: string) => Number(value) <= 199.99 || 'Value must be less than or equal to 199.99',
  },
};

export const nonRBACRoles = [
  {
    id: 'admin',
    icon: 'icon-cog-filled',
    color: '#000',
    name: 'Admin',
    description: 'Admin',
  },
  {
    id: 'store_admin',
    icon: 'icon-bag-outlined',
    color: '#000',
    name: 'Store Admin',
    description: 'Store Admin',
  },
  {
    id: 'user',
    icon: 'icon-staff-filled',
    color: '#000',
    name: 'Regular User',
    description: 'Regular User',
  },
  {
    id: 'restricted_user',
    icon: 'icon-staff-outlined',
    color: '#000',
    name: 'Limited User',
    description: 'Limited User',
  },
];

export const passwordRequirements = new Map<string, IPasswordRequirements>();
passwordRequirements.set('length', {message: '8 characters long including', isValid: false});
passwordRequirements.set('capitalLetter', {message: '1 capital letter', isValid: false});
passwordRequirements.set('number', {message: '1 number', isValid: false});
passwordRequirements.set('specialCharacter', {message: '1 special character', isValid: false});

export const DEFAULT_HOUR = {
  id: '',
  openTime: '09:00',
  closeTime: '17:00',
  isClosed: false,
  dayOfWeek: 0,
  error: '',
  invalid: false,
  updated: false,
};

export const DEFAULT_HOURS = Array(7)
  .fill({})
  .map((_, idx) => [{...DEFAULT_HOUR, dayOfWeek: idx}]);
