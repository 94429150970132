import './styles.scss';
import {IonIcon, IonImg} from '@ionic/react';
import {useHistory} from 'react-router-dom';
import emptyBag from 'src/assets/images/empty-shopping-bag.png';

interface IProps {
  noOrders: boolean;
}

const OrderHistoryLink = ({noOrders}: IProps) => {
  const history = useHistory();

  return (
    <div id="order-history-link" className={noOrders ? 'centered-container' : ''}>
      {noOrders ? (
        <>
          <div className="order-msg order-title">No completed orders today</div>
          <div className="d-flex ion-justify-content-center">
            <IonImg className="bag-icon" src={emptyBag} />
          </div>
        </>
      ) : (
        <>
          <div className="order-msg">No More Orders</div>
          <div className="divider" />
        </>
      )}
      {/* Will uncomment when enable order history module */}
      <div className={`order-msg ${noOrders ? 'link-description' : ''}`}>
        To view all past orders visit the History Page.
      </div>
      <div className="order-msg  history-link" onClick={() => history.replace('orders/history')}>
        <div>Order History</div>
        <IonIcon className="icon-arrow-right" />
      </div>
    </div>
  );
};

export default OrderHistoryLink;
