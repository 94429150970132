interface IProps {
  rowName: string;
  count?: number;
}

const InventorySortRow = ({rowName, count = 0}: IProps) => {
  return (
    <div className="category-title">
      <p className="heading">{rowName}</p>
      <span className="heading category-count">{count !== 0 ? count : ''}</span>
    </div>
  );
};

export default InventorySortRow;
