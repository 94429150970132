import {useState, useEffect} from 'react';
import {IonModal, IonButton, IonIcon, IonFooter} from '@ionic/react';
import './styles.scss';
import {closeOutline} from 'ionicons/icons';

import Header from 'src/components/Header';
import BulkEditConfirmation from 'src/components/modals/BulkEdit/Confirmation';

interface IProps {
  isInStock: boolean;
  showAvailabilityModal: boolean;
  patchInventory: (a: any, b: boolean, c: boolean) => void;
  setShowEditModal: (e: boolean) => void;
  setShowAvailabilityModal: (e: boolean) => void;
}

const BulkEditAvailabilityModal = (props: IProps) => {
  const [newInStockState, setNewInStockState] = useState(props.isInStock);
  const [selectedInStockState, setSelectedInStockState] = useState(false);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);

  useEffect(() => {
    setNewInStockState(props.isInStock);
  }, [props.showAvailabilityModal]);

  const onConfirm = () => {
    props.patchInventory({}, true, selectedInStockState);
  };

  return (
    <>
      <IonModal
        id="availability-option"
        isOpen={props.showAvailabilityModal}
        showBackdrop
        onDidDismiss={() => {
          props.setShowAvailabilityModal(false);
        }}>
        <div className="modal-content-container">
          <div className="modal-content">
            <Header
              border
              noBackground
              title="Availability"
              rightButton={
                <IonIcon
                  icon={closeOutline}
                  onClick={() => {
                    props.setShowAvailabilityModal(false);
                  }}
                />
              }
            />
            <div className="modal-body">
              <div className="availability-option-container">
                <div
                  className={`availability-option instock ${newInStockState ? 'selected' : ''}`}
                  onClick={() => {
                    setNewInStockState(true);
                    setSelectedInStockState(true);
                  }}>
                  <p>In stock</p>
                </div>
                <div
                  className={`availability-option outstock ${!newInStockState ? 'selected' : ''}`}
                  onClick={() => {
                    setNewInStockState(false);
                    setSelectedInStockState(false);
                  }}>
                  <p>Out of stock</p>
                </div>
              </div>
            </div>
            <IonFooter>
              <div className="action">
                <IonButton
                  className="action-btn cancel-btn"
                  mode="ios"
                  fill="clear"
                  color="dark"
                  onClick={() => {
                    props.setShowAvailabilityModal(false);
                  }}>
                  Cancel
                </IonButton>
                <IonButton
                  disabled={props.isInStock === newInStockState}
                  className="action-btn"
                  mode="ios"
                  fill="solid"
                  color="primary"
                  expand="block"
                  onClick={() => {
                    setShowConfirmationModal(true);
                    props.setShowAvailabilityModal(false);
                  }}>
                  Save edit
                </IonButton>
              </div>
            </IonFooter>
          </div>
        </div>
      </IonModal>
      <BulkEditConfirmation
        showConfirmationModal={showConfirmationModal}
        setShowConfirmationModal={setShowConfirmationModal}
        setShowEditModal={props.setShowEditModal}
        setPreviousModal={props.setShowAvailabilityModal}
        onConfirm={onConfirm}
        isInStock={props.isInStock}
      />
    </>
  );
};

export default BulkEditAvailabilityModal;
