import {IonButton, IonCheckbox, IonItem, IonLabel} from '@ionic/react';
import {Check} from 'react-feather';
import {ICategory, IQueryObject} from 'src/interfaces/item';
import './styles.scss';

interface IProps {
  categories: Array<ICategory>;
  filterObject: IQueryObject;
  selectFilter: (e: any) => void;
  isDesktop: boolean;
}

const CategoryFilter = (props: IProps) => {
  const selectCategoryFilter = (filterObject: IQueryObject, category: ICategory, all = false) => {
    let newCategories: string[];
    if (all) {
      newCategories = [];
    } else {
      newCategories = [...filterObject.categories];
      if (newCategories.includes(category.id)) newCategories = newCategories.filter((item) => item !== category.id);
      else newCategories.push(category.id);
    }
    props.selectFilter({categories: newCategories});
  };

  const allSelected =
    !props.filterObject.categories.length || props.filterObject.categories.length === props.categories.length;

  return props.isDesktop === true ? (
    <div className="filter-group-grid">
      <IonItem>
        <IonCheckbox
          checked={allSelected}
          onClick={() =>
            selectCategoryFilter(
              props.filterObject,
              {
                id: '',
                name: '',
              },
              true,
            )
          }
        />
        <IonLabel className={allSelected ? 'selected' : ''}>All</IonLabel>
      </IonItem>
      {props.categories.map((category, index) => {
        const isSelected =
          !!props.filterObject.categories.length && props.filterObject.categories.includes(category.id);
        return (
          <IonItem key={index}>
            <IonCheckbox checked={isSelected} onClick={() => selectCategoryFilter(props.filterObject, category)} />
            <IonLabel className={`ion-text-wrap ${isSelected ? 'selected' : ''}`}>{category.name}</IonLabel>
          </IonItem>
        );
      })}
    </div>
  ) : (
    <div className="category-row">
      <IonButton
        className={allSelected ? 'ion-button-selected' : ''}
        onClick={() =>
          selectCategoryFilter(
            props.filterObject,
            {
              id: '',
              name: '',
            },
            true,
          )
        }>
        {allSelected ? (
          <span className="check-selected">
            <Check />
            All
          </span>
        ) : (
          <span className="main-text">All</span>
        )}
      </IonButton>
      {props.categories.map((category, index) => {
        const isSelected =
          !!props.filterObject.categories.length && props.filterObject.categories.includes(category.id);
        return (
          <IonButton
            key={index}
            className={isSelected ? 'ion-button-selected' : ''}
            onClick={() => selectCategoryFilter(props.filterObject, category)}>
            {isSelected ? (
              <span className="check-selected">
                <Check />
                {category.name}
              </span>
            ) : (
              <span className="main-text">{category.name}</span>
            )}
          </IonButton>
        );
      })}
    </div>
  );
};

export default CategoryFilter;
