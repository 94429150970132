import {FirebaseCrashlytics} from '@awesome-cordova-plugins/firebase-crashlytics';
import {store} from 'src/store';

class firebaseCrashlytics {
  static crashlytics: any;

  static async initialize() {
    if (store.getState().platform.isNative) this.crashlytics = await FirebaseCrashlytics.initialise();
  }

  static async setUserIdentifier(user: any) {
    if (this.crashlytics) {
      this.crashlytics.setUserIdentifier(user.id);
      this.crashlytics.setString('email', user.email);
      this.crashlytics.setString('name', user.firstName + ' ' + user.lastName);
    }
  }

  static async setStringValueForKey(key: string, value: string) {
    if (this.crashlytics) this.crashlytics.setString(key, value);
  }

  static async logPriority(priority: any, tag: string, message: string) {
    if (this.crashlytics) this.crashlytics.logPriority(priority, tag, message);
  }

  static async log(message: string) {
    if (this.crashlytics) this.crashlytics.log(message);
  }

  static async crash() {
    if (this.crashlytics) this.crashlytics.crash();
  }
}

export default firebaseCrashlytics;
