export enum QUERY_OBJECT {
  DESC = 'DESC',
  ASC = 'ASC',
  DATE = 'createdAt',
  PRICE = 'price',
  NAME = 'name',
  CATEGORY = 'category',
  CREATEDAT = 'createdAt',
  ALL = 'all',
}
