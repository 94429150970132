import {IModifierGroup, IModifierItem} from 'src/interfaces/item';
import './styles.scss';

interface IProps {
  className?: string;
  modifierGroupItem: IModifierGroup;
}

const ModifierGroup = ({className, modifierGroupItem}: IProps) => {
  const {modifierGroupId, modifierGroupName, items, modifierGroup} = modifierGroupItem;
  return (
    <>
      <div className={`modifier-group ${className ? className : ''}`} key={modifierGroupId}>
        <div className="modifier-group-name">{modifierGroupName}</div>
        {items.map((modifierItem: IModifierItem, number: number) => {
          return (
            <div className="modifier-group-items" key={number}>
              <div className={`modifier-name-quantity ${modifierItem.totalPrice > 0 ? 'has-price' : ''}`}>
                <span>{modifierItem.modifierItemName}</span>
                {modifierItem.quantity > 1 && <span>(x{modifierItem.quantity})</span>}
              </div>
              {modifierItem.totalPrice > 0 && (
                <span className="modifier-price">+ ${parseFloat(modifierItem.totalPrice.toString()).toFixed(2)}</span>
              )}
            </div>
          );
        })}
        {!!modifierGroup?.length && (
          <div className="sub-modifier-group">
            {modifierGroup?.map((modifierGroupItem: IModifierGroup, index: number) => {
              return <ModifierGroup key={index} modifierGroupItem={modifierGroupItem} />;
            })}
          </div>
        )}
      </div>
    </>
  );
};

export default ModifierGroup;
