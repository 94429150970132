import {makeRequest} from 'src/Axios';
import {IItem} from 'src/interfaces/item';
import {getItemFromCommonItem, getStoreItemFromItemDetails} from 'src/utils/helpers';

const itemService = {
  // Create new store item to inventory by capturing details
  createItem: async (storeId: string, data: any) => {
    try {
      const response: any = await makeRequest({
        url: `/inventory/${storeId}/item`,
        method: 'POST',
        body: data,
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  createStoreItemByUPC: async (storeId: string, upc: string) => {
    try {
      const response: any = await makeRequest({
        url: `/inventory/${storeId}/upc`,
        method: 'POST',
        body: {upc},
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  addItemToStore: async (storeId: string, itemId: string, showItemInPopularSearches?: boolean) => {
    try {
      let params: any = {};
      if (showItemInPopularSearches) params.is_searched_item = showItemInPopularSearches;
      const response: any = await makeRequest({
        url: `/inventory/${storeId}/store-item-with-global-item`,
        method: 'POST',
        body: {item_id: itemId},
        params: params,
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  removeItemFromStore: async (storeId: string, itemId: string) => {
    try {
      const response: any = await makeRequest({
        url: `/inventory/store/store_item?store_id=${storeId}&store_item_id=${itemId}`,
        method: 'DELETE',
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  updateStoreItem: async (storeId: string, items: any, showGlobalItemContent: boolean) => {
    try {
      const response: any = await makeRequest({
        url: `/inventory/${storeId}/item`,
        method: 'PUT',
        isMicroservice: true,
        body: {store_items: items},
        params: {
          show_global_items_content: showGlobalItemContent,
        },
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getCommonItems: async (offset: number, searchTerm: string = '', category?: string) => {
    try {
      const params = {
        is_common: true,
        is_certified: true,
        offset,
        limit: 20,
        ...(category && {category_id: category}),
        ...(searchTerm && {search_string: searchTerm}),
      };
      const response: any = await makeRequest({url: '/inventory/items/global-items', params, isMicroservice: true});
      return response.data.map((item: any) => getItemFromCommonItem(item));
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getPopularItems: async () => {
    try {
      const response: any = await makeRequest({url: '/inventory/items/global-items/popular', isMicroservice: true});
      return response.data.map(({item}: any) => item.name);
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getStoreItemDetails: async (storeId: string, itemId: string, showGlobalItemContent: boolean) => {
    try {
      const response: any = await makeRequest({
        url: `/inventory/${storeId}/item/${itemId}`,
        isMicroservice: true,
        params: {
          show_global_items_content: showGlobalItemContent,
        },
      });

      let menu_timing: any = [];
      if (response?.data?.menu_timing?.length) {
        const hours = response?.data?.menu_timing
          ?.map(({open_time, close_time, day_of_week, is_closed}: any) => ({
            id: '',
            openTime: !open_time ? '09:00' : open_time,
            closeTime: !close_time ? '17:00' : close_time,
            storeId: '',
            dayOfWeek: day_of_week,
            isClosed: is_closed,
            invalid: false,
            updated: false,
          }))
          ?.sort((a: any, b: any) => a.dayOfWeek - b.dayOfWeek);
        const sunday = hours?.pop();
        menu_timing = [sunday, ...hours];
      } else {
        menu_timing = [];
      }
      return {
        storeItem: getStoreItemFromItemDetails(response?.data?.store_item) as IItem,
        menu_timing,
        menu_item: response?.data?.menus?.map((item: any) => item.id) || [],
      };
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  archiveItem: async (storeId: string, storeItemId: string) => {
    try {
      const response: any = await makeRequest({
        url: `/inventory/${storeId}/status?status=false`,
        method: 'PATCH',
        body: {store_item_id: storeItemId},
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  unarchiveItem: async (storeId: string, storeItemId: string) => {
    try {
      const response: any = await makeRequest({
        url: `/inventory/${storeId}/status?status=true`,
        method: 'PATCH',
        body: {store_item_id: storeItemId},
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
};

export default itemService;
