import {IonButton, IonIcon, IonText} from '@ionic/react';
import './styles.scss';

interface IProps {
  className?: string;
  title: string;
  totalItems: number;
  currentItem: number;
  goToPrevItem?: () => void;
  goToNextItem?: () => void;
}

const ItemHeaderBar = ({className, title, totalItems, currentItem, goToPrevItem, goToNextItem}: IProps) => {
  return (
    <div id="item-header-bar">
      <div className="item-nav-bar">
        <div className="nav-btn">
          {currentItem !== 0 && (
            <IonButton size="small" slot="start" fill="outline" onClick={goToPrevItem}>
              Back
            </IonButton>
          )}
        </div>
        <IonText>{`${totalItems ? currentItem + 1 : 0} of ${totalItems}`}</IonText>
        <div className="nav-btn">
          {currentItem + 1 !== Number(totalItems) && (
            <IonButton size="small" slot="end" fill="outline" onClick={goToNextItem}>
              Next
            </IonButton>
          )}
        </div>
      </div>
      <div className={`item-status-bar ${className}`}>
        {className === 'completed' && <IonIcon className="icon-complete-2" />}
        {className === 'out-of-stock' && <IonIcon className="icon-stop-block" />}
        <div>{title}</div>
      </div>
    </div>
  );
};

export default ItemHeaderBar;
