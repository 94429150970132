import {IonPage, IonContent, IonBackButton} from '@ionic/react';
import './styles.scss';
import {RouteComponentProps} from 'react-router';
import {useParams} from 'react-router-dom';
import {useAppDispatch, useEffectOnce} from 'src/hooks';
import {hideLoading, showLoading} from 'src/store/loadingSlice';
import {HELP_URL, PRIVACY_URL, TERMS_URL, SHOP_URL} from 'src/utils/constants';
import Header from 'src/components/Header';
import arrowBackBlack from 'src/assets/images/arrow-back-black.svg';

interface IProps extends RouteComponentProps {}

const Help = ({history}: IProps) => {
  const dispatch = useAppDispatch();
  const {type}: any = useParams();

  const title =
    type === 'privacy'
      ? 'Privacy Policy'
      : type === 'terms'
      ? 'Terms of Use'
      : type === 'lula-shop'
      ? 'Lula Shop'
      : 'Help Center';
  const link =
    type === 'privacy' ? PRIVACY_URL : type === 'terms' ? TERMS_URL : type === 'lula-shop' ? SHOP_URL : HELP_URL;

  useEffectOnce(() => {
    dispatch(showLoading());
  });

  return (
    <IonPage id="in-app-browser-page">
      <IonContent fullscreen scrollY={false}>
        <Header noBackground leftButton={<IonBackButton text="" icon={arrowBackBlack} />} title={title} />

        <div className="body">
          <iframe onLoad={() => dispatch(hideLoading())} title={title} src={link}></iframe>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Help;
