import {useState, useEffect} from 'react';
import {IonInput, IonIcon, IonButton, IonLabel} from '@ionic/react';
import {chevronDown} from 'ionicons/icons';
import moment from 'moment';
import './styles.scss';
import {HOURS} from 'src/utils/constants';

interface IProps {
  label?: string;
  value: string;
  disabled?: boolean;
  onChange: (value: string) => void;
  type?: string;
  invalid?: boolean;
}

const AutoCompleteInput = ({label, value, disabled, type, invalid, onChange}: IProps) => {
  const [time, setTime] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [filteredHours, setFilteredHours] = useState(HOURS);
  const [isInvalidFormat, setIsInvalidFormat] = useState(false);

  const handleInput = (e: any) => {
    setTime(e.target.value);
    setFilteredHours(HOURS.filter((hour) => hour.includes(e.target.value)));
    if (!validateTime(e.target.value)) {
      setIsInvalidFormat(true);
      onChange('');
    } else {
      setIsInvalidFormat(false);
      if (e.target.value) onChange(moment(e.target.value, 'hh:mm A').format('HH:mm'));
      else onChange('');
    }
  };

  const validateTime = (time: string) => {
    const timeRegex = /^(0?[1-9]|1[0-2]):[0-5][0-9] (AM|PM)$/i;
    return timeRegex.test(time);
  };

  useEffect(() => {
    if (!moment(value, 'HH:mm').isValid()) {
    } else {
      setIsInvalidFormat(false);
      setTime(moment(value, 'HH:mm').format('hh:mm A'));
    }
  }, [value]);

  return (
    <div id="auto-complete-input">
      {type !== 'menu' && type !== 'schedule-partner' && <IonLabel className="input-label">{label}</IonLabel>}
      <div className={`input-container ${isInvalidFormat || invalid ? 'border-error-color' : ''}`}>
        <IonInput
          type="text"
          mode="md"
          disabled={disabled}
          value={time}
          onIonInput={(e: any) => handleInput(e)}
          onFocus={() => setDropdownOpen(true)}
          onBlur={() => {
            setTimeout(() => {
              setFilteredHours(HOURS);
              setDropdownOpen(false);
            }, 100);
          }}
        />
        <IonIcon icon={chevronDown} />
      </div>

      {dropdownOpen && (
        <div className="suggestions-container">
          <div className="suggestions">
            {filteredHours.map((hour, index) => (
              <IonButton
                type="button"
                key={index}
                fill="clear"
                onClick={() => {
                  setTime(hour);
                  setIsInvalidFormat(false);
                  onChange(moment(hour, 'hh:mm A').format('HH:mm'));
                  setDropdownOpen(false);
                }}>
                {hour}
              </IonButton>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default AutoCompleteInput;
