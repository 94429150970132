import {IonImg, IonBadge} from '@ionic/react';
import './styles.scss';
import Countdown from 'react-countdown';
import moment from 'moment';
import doordash from 'src/assets/images/doordash.svg';
import uberEats from 'src/assets/images/ubereats.svg';
import grubhub from 'src/assets/images/grubhub.svg';
import watch from 'src/assets/images/watch.svg';
import bag from 'src/assets/images/bag.svg';
import hourglass from 'src/assets/images/hourglass.svg';
import complete from 'src/assets/images/complete.svg';
import {PARTNER} from 'src/enums/partner';
import {calculateMilliSeconds, getTimeInMilliseconds} from 'src/utils/helpers';
import {ORDER_STATUS} from 'src/enums/order';

interface IProps {
  className?: string;
  order?: any;
  bagTime?: any;
  fromOrderHistory?: boolean;
  selected?: boolean;
  onClick: (id: string) => void;
}

const OrderCard = ({className, order, bagTime, fromOrderHistory, selected, onClick}: IProps) => {
  const milliSeconds = calculateMilliSeconds(bagTime);
  const acceptIn = 600000;

  const completionTime =
    order.orderCompletionDate > order.acceptedAt
      ? order.orderCompletionDate
      : getTimeInMilliseconds(order.acceptedAt) + milliSeconds;

  return (
    <div
      id="order-card"
      className={`${className} ${!fromOrderHistory && selected ? 'select' : ''} ${
        order?.status === ORDER_STATUS.INCOMING ? 'order-border-pulse' : ''
      }`}
      onClick={() => onClick(order?.id)}>
      <div className="details">
        {order?.partner === PARTNER.DOOR_DASH && <IonImg src={doordash} />}
        {order?.partner === PARTNER.GRUB_HUB && <IonImg src={grubhub} />}
        {order?.partner === PARTNER.UBER_EATS && <IonImg src={uberEats} />}
        <div className="details-inner-container">
          <div className="name-status-container">
            <h2 className="title">{order?.customerName}</h2>
            <IonBadge
              className={
                order?.status === ORDER_STATUS.CANCELLED || order?.status === ORDER_STATUS.DENIED
                  ? 'expired'
                  : order?.status
              }>
              {order?.status}
            </IonBadge>
          </div>
          {order?.dspOrderNumber && <p className="order-number">#{order?.dspOrderNumber}</p>}
        </div>
      </div>
      <div className="actions">
        <div className="items">
          <p className="desc">
            <strong>{order?.quantity}</strong>&nbsp; Items
          </p>
        </div>
        {order?.status === ORDER_STATUS.INCOMING && (
          <div className="action">
            <IonImg src={watch} />
            <p className="desc">
              {`View within: `}
              <Countdown
                date={getTimeInMilliseconds(order.createdAt) + acceptIn}
                renderer={({minutes, seconds, completed}) =>
                  !completed ? (
                    <strong className="danger">{`${minutes < 10 ? '0' + minutes : minutes}:${
                      seconds < 10 ? '0' + seconds : seconds
                    }`}</strong>
                  ) : (
                    <strong className="danger">00:00</strong>
                  )
                }
                onComplete={() => console.log('Order Expired')}
              />
            </p>
          </div>
        )}
        {order?.status === ORDER_STATUS.WAITING &&
          (!order?.ongoing ? (
            <div className="action">
              <IonImg src={bag} />
              <p className="desc">
                Bag within:{' '}
                <Countdown
                  date={completionTime}
                  renderer={({minutes, seconds, completed}) =>
                    !completed ? (
                      <strong className="danger">{`${minutes < 10 ? '0' + minutes : minutes}:${
                        seconds < 10 ? '0' + seconds : seconds
                      }`}</strong>
                    ) : (
                      <strong className="danger">00:00</strong>
                    )
                  }
                  onComplete={() => console.log('Bag within time Expired')}
                />
              </p>
            </div>
          ) : (
            <div className="action">
              <IonImg src={hourglass} />
              <p className="desc">Ready for pick up</p>
            </div>
          ))}

        {order?.status === ORDER_STATUS.COMPLETED &&
          (fromOrderHistory ? (
            <div className="action">
              <div className="desc order-completed">
                <strong>Ordered </strong>
                <p className="date">{moment(order.createdAt).format('MMM[.] Do')}</p>
                <p className="time">at {moment(order.createdAt).format('LT')}</p>
              </div>
            </div>
          ) : (
            <div className="action">
              <IonImg src={complete} />
              <p className="desc">Delivered 00:00</p>
            </div>
          ))}

        {order?.status === ORDER_STATUS.EXPIRED &&
          (fromOrderHistory ? (
            <div className="action">
              <div className="desc order-completed">
                <strong>Ordered </strong>
                <p className="date">{moment(order.createdAt).format('MMM[.] Do')}</p>
                <p className="time">at {moment(order.createdAt).format('LT')}</p>
              </div>
            </div>
          ) : (
            <div className="action">
              <IonImg src={watch} />
              <p className="desc">00:00</p>
            </div>
          ))}

        {order?.status === ORDER_STATUS.CANCELLED &&
          (fromOrderHistory ? (
            <div className="action">
              <div className="desc order-completed">
                <strong>Ordered </strong>
                <p className="date">{moment(order.createdAt).format('MMM[.] Do')}</p>
                <p className="time">at {moment(order.createdAt).format('LT')}</p>
              </div>
            </div>
          ) : (
            <div className="action">
              <IonImg src={watch} />
              <p className="desc">00:00</p>
            </div>
          ))}

        {order?.status === ORDER_STATUS.DENIED &&
          (fromOrderHistory ? (
            <div className="action">
              <div className="desc order-completed">
                <strong>Ordered </strong>
                <p className="date">{moment(order.createdAt).format('MMM[.] Do')}</p>
                <p className="time">at {moment(order.createdAt).format('LT')}</p>
              </div>
            </div>
          ) : (
            <div className="action">
              <IonImg src={watch} />
              <p className="desc">00:00</p>
            </div>
          ))}
      </div>
    </div>
  );
};

export default OrderCard;
