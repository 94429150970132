import {showAlert} from 'src/store/alertSlice';
import {store} from 'src/store';
import {IDevice} from 'src/interfaces/device';
import {DeviceState} from 'onesignal-cordova-plugin/dist/Subscription';
import notificationService from 'src/services/notifications';
import AnalyticsHelper from 'src/utils/segment';

const {isNative} = store.getState().platform;

const getOneSignal = async () =>
  isNative ? (await import('onesignal-cordova-plugin')).default : (await import('react-onesignal')).default;

class OneSignalHelper {
  static oneSignal: any = null;

  static async init() {
    if (!this.oneSignal) {
      this.oneSignal = await getOneSignal();
    }
  }

  static subscribe(dispatch: any, user: any, device: IDevice) {
    const appId = process.env.REACT_APP_ONESIGNAL_APP_ID || '';

    if (user && this.oneSignal) {
      if (isNative) {
        this.oneSignal.setLogLevel(6, 0);
        this.oneSignal.setAppId(appId);
        this.oneSignal.setNotificationOpenedHandler(function (jsonData: any) {});
        // Asking for user permission on IOS.
        this.oneSignal.promptForPushNotificationsWithUserResponse((accepted: any) => {
          if (!accepted) {
            dispatch(
              showAlert({
                heading: 'Permission denied!',
                message: 'Please, allow lula to send notifications in settings.',
              }),
            );
          }
        });
        // this will fire first, if it device is already subscribed, it will activate the device notifications
        this.oneSignal.getDeviceState((response: DeviceState) => {
          if (response?.userId) {
            notificationService.activateEmployeeNotificationDevice(device?.deviceId, response?.userId).then(() => {
              AnalyticsHelper.trackEmployeeDeviceUpdate(true);
            });
          }
        });
        // if the device is not subscribed, it will activate the device notifications when there is a call back. This only fires once when the device is subscribed.
        this.oneSignal.addSubscriptionObserver(function (state: any) {
          if (state.from.isSubscribed && !state.to.isSubscribed) {
            if (user?.id) {
              notificationService.deactivateEmployeeNotificationDevice(device?.deviceId).then(() => {
                AnalyticsHelper.trackEmployeeDeviceUpdate(false);
              });
            }
          }
          if (!state.from.isSubscribed && state.to.isSubscribed) {
            if (state.to.userId) {
              notificationService.activateEmployeeNotificationDevice(device?.deviceId, state.to.userId).then(() => {
                AnalyticsHelper.trackEmployeeDeviceUpdate(true);
              });
            }
          }
        });
      } else {
        this.oneSignal.init({appId});
      }
      // Setting attributes.
      this.setExternalUserId(user);
    }
  }

  static setExternalUserId(user: any) {
    if (user && this.oneSignal) {
      this.oneSignal.setExternalUserId(user.id);
      this.oneSignal.setEmail(user.email);
      this.oneSignal.setSMSNumber(user.phone);
    }
  }
}

export default OneSignalHelper;
