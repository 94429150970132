import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import {makeRequest} from 'src/Axios';
import {ADDRESS_TYPE} from 'src/enums/address_type';
import {ITiming} from 'src/interfaces/store';
import {getItemFromStorePauseItem} from 'src/utils/helpers';

dayjs.extend(customParseFormat);

const storeService = {
  getPauseSchedules: async (storeId: any, data: any) => {
    try {
      const response: any = await makeRequest({url: `/stores/${storeId}/pauses`, params: data, isMicroservice: true});
      const list = response.data?.map((storePauseItem: any) => getItemFromStorePauseItem(storePauseItem));
      return list;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  createPauseSchedule: async (storeId: any, data: any) => {
    try {
      const response: any = await makeRequest({
        url: `/stores/${storeId}/pauses`,
        method: 'POST',
        body: data,
        isMicroservice: true,
      });
      return getItemFromStorePauseItem(response.data);
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  createInstentStorePause: async (storeId: any, data: any) => {
    try {
      const response: any = await makeRequest({
        url: `/stores/${storeId}/pauses/now`,
        method: 'POST',
        body: data,
        isMicroservice: true,
      });
      return getItemFromStorePauseItem(response.data);
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  endStorePauseNow: async (storeId: any, data: any) => {
    try {
      const response: any = await makeRequest({
        url: `/stores/${storeId}/pauses/end-now`,
        method: 'PUT',
        body: data,
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  deletePauseSchedule: async (storeId: any, pasueId: any) => {
    try {
      const response: any = await makeRequest({
        url: `/stores/${storeId}/pauses/${pasueId}`,
        method: 'DELETE',
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  updatePauseSchedule: async (storeId: any, pasueId: any, data: any) => {
    try {
      const response: any = await makeRequest({
        url: `/stores/${storeId}/pauses/${pasueId}`,
        method: 'PUT',
        body: data,
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  updateStore: async (companyId: string, storeId: string, data: any) => {
    try {
      const response: any = await makeRequest({
        url: `/stores/${storeId}`,
        method: 'PUT',
        params: {company_id: companyId},
        body: data,
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  updateStoreStatus: async (storeId: any, data: any) => {
    try {
      const response: any = await makeRequest({
        url: `/stores/${storeId}/status`,
        method: 'PUT',
        body: data,
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getStoreStatus: async (storeId: string, data: any) => {
    try {
      const response: any = await makeRequest({url: `/stores/${storeId}/status`, params: data, isMicroservice: true});
      const {is_closed, current_pause, DoorDash, UberEats, GrubHub} = response.data;
      const defaultPartner = [
        {
          name: '',
          isOpen: false,
          isPaused: false,
          isOffline: true,
          currentPause: null,
        },
      ];
      return {
        isClosed: is_closed,
        currentPause: current_pause ? getItemFromStorePauseItem(current_pause) : current_pause,
        doorDash:
          DoorDash.length > 0
            ? DoorDash.map((partnerItem: any) => ({
                currentPause: partnerItem.current_pause
                  ? getItemFromStorePauseItem(partnerItem.current_pause)
                  : partnerItem.current_pause,
                name: partnerItem.name,
                isOpen: partnerItem.is_open,
                isPaused: !partnerItem.is_open,
                isOffline: false,
              }))
            : defaultPartner,
        uberEats:
          UberEats.length > 0
            ? UberEats.map((partnerItem: any) => ({
                currentPause: partnerItem.current_pause
                  ? getItemFromStorePauseItem(partnerItem.current_pause)
                  : partnerItem.current_pause,
                name: partnerItem.name,
                isOpen: partnerItem.is_open,
                isPaused: !partnerItem.is_open,
                isOffline: false,
              }))
            : defaultPartner,
        grubHub:
          GrubHub.length > 0
            ? GrubHub.map((partnerItem: any) => ({
                currentPause: partnerItem.current_pause
                  ? getItemFromStorePauseItem(partnerItem.current_pause)
                  : partnerItem.current_pause,
                name: partnerItem.name,
                isOpen: partnerItem.is_open,
                isPaused: !partnerItem.is_open,
                isOffline: false,
              }))
            : defaultPartner,
      };
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getStorePrintLogoImage: async () => {
    try {
      const response: any = await makeRequest({url: '/store/print-logo-image'});
      const print_logo_image = response.data.print_logo_image;
      return print_logo_image ? print_logo_image : '';
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getStoreHours: async (storeId: string) => {
    try {
      const response: any = await makeRequest({url: `/stores/${storeId}/store-hours`, isMicroservice: true});
      const hours: ITiming[] = response.data.map(({id, open_time, close_time, day_of_week, isClosed}: any) => ({
        id,
        openTime: !open_time ? '09:00' : open_time,
        closeTime: !close_time ? '17:00' : close_time,
        dayOfWeek: day_of_week,
        isClosed,
        invalid: false,
        updated: false,
      }));
      // sort store hours based on open and close time
      hours.sort((a, b) => {
        const openA = dayjs(a.openTime, 'HH:mm');
        const openB = dayjs(b.openTime, 'HH:mm');
        if (openA.isSame(openB)) {
          const closeA = dayjs(a.closeTime, 'HH:mm');
          const closeB = dayjs(b.closeTime, 'HH:mm');
          return closeA.isBefore(closeB) ? -1 : 1;
        }
        return openA.isBefore(openB) ? -1 : 1;
      });

      return hours;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  updateStoreHours: async (storeId: string, hours: ITiming[]) => {
    try {
      const data = hours.map(({id, openTime, closeTime, isClosed, dayOfWeek}) => ({
        ...(!!id && {id}),
        open_time: openTime,
        close_time: closeTime,
        day_of_week: dayOfWeek,
        isClosed,
      }));
      const response: any = await makeRequest({
        url: `/stores/${storeId}/store-hours`,
        method: 'PUT',
        body: data,
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  deleteStoreHour: async (storeId: string, id: string) => {
    try {
      const response: any = await makeRequest({
        url: `/stores/${storeId}/store-hours/${id}`,
        method: 'DELETE',
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getAllUserStores: async (params: {user_id?: string; search_string?: string; offset: number}) => {
    try {
      const response: any = await makeRequest({url: '/stores/v2/user-store', params: params, isMicroservice: true});
      const {count, stores} = response.data;
      const updatedStores = stores.map((store: any) => {
        const storeAddress = store?.addresses?.find(
          (address: any) => address?.address_type?.type === ADDRESS_TYPE.STORE_ADDRESS,
        );
        const shippingAddress = store?.addresses?.find(
          (address: any) => address?.address_type?.type === ADDRESS_TYPE.WELCOME_PACKAGE,
        );

        return {
          id: store.id,
          name: store.name,
          email: store.email,
          phone: store.phone_number,
          company: store.company,
          isClosed: !!store.closed_till,
          isDeactivated: store.is_disabled,
          isRBACStore: store.is_rbac_store,
          createdAt: store.createdAt,
          showGlobalItemsContent: store.show_global_items_content,
          address: {
            id: storeAddress?.address.id,
            line_1: storeAddress?.address.line_1,
            state: storeAddress?.address.state,
            city: storeAddress?.address.city,
            zip: storeAddress?.address.zip,
          },
          shippingAddress: {
            id: shippingAddress?.address.id,
            line_1: shippingAddress?.address.line_1,
            state: shippingAddress?.address.state,
            city: shippingAddress?.address.city,
            zip: shippingAddress?.address.zip,
          },
          storeType: store.store_type,
        };
      });
      return {count, stores: updatedStores};
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getEmployeesCount: async () => {
    try {
      const response: any = await makeRequest({url: '/store/count'});
      const employeesCount = response.data.employeesCount;
      return employeesCount;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getStoreAddress: async (storeId: string) => {
    try {
      const response: any = await makeRequest({
        url: `/stores/${storeId}/address/type/${ADDRESS_TYPE.STORE_ADDRESS}`,
        isMicroservice: true,
      });
      const {address} = response.data[0];
      return address;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getWelcomePackageAddress: async (storeId: string) => {
    try {
      const response: any = await makeRequest({
        url: `/stores/${storeId}/address/type/${ADDRESS_TYPE.WELCOME_PACKAGE}`,
        isMicroservice: true,
      });
      if (response.data.length) {
        const {id, address, same_as_store_address} = response.data[0];
        return {id, address, sameAsStoreAddress: same_as_store_address};
      } else {
        return {id: null, address: null, sameAsStoreAddress: false};
      }
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  addAddress: async (storeId: string, data: any) => {
    try {
      const response: any = await makeRequest({
        url: `/stores/${storeId}/address`,
        method: 'POST',
        body: data,
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  updateAddress: async (storeId: string, data: any) => {
    try {
      const response: any = await makeRequest({
        url: `/stores/${storeId}/address/`,
        method: 'PUT',
        body: data,
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getActivePartners: async () => {
    try {
      // TODO: update the api url to call microservice URL whenever we activate this code.
      const response: any = await makeRequest({url: '/store/store-partners'});
      const {current_pause, door_dash, uber_eats, grub_hub} = response.data;
      return {
        currentPause: current_pause,
        doorDash: door_dash
          ? door_dash.map((store: any) => ({
              currentPause: store.current_pause,
              name: store.name,
              id: store.id,
              isOpen: store.is_open,
              isPaused: !store.is_open, //&& !!store.current_pause,
              isOffline: false,
              // isOffline: !store.is_open && !store.current_pause,
            }))
          : [],
        uberEats: uber_eats
          ? uber_eats.map((store: any) => ({
              currentPause: store.current_pause,
              name: store.name,
              id: store.id,
              isOpen: store.is_open,
              isPaused: !store.is_open, // && !!store.current_pause,
              isOffline: false,
              // isOffline: !store.is_open && !store.current_pause,
            }))
          : [],
        grubHub: grub_hub
          ? grub_hub.map((store: any) => ({
              currentPause: store.current_pause,
              name: store.name,
              id: store.id,
              isOpen: store.is_open,
              isPaused: !store.is_open, // && !!store.current_pause,
              isOffline: false,
              // isOffline: !store.is_open && !store.current_pause,
            }))
          : [],
      };
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  updatePartnerIsOpen: async (data: any) => {
    try {
      // TODO: update the api url to call microservice URL whenever we activate this code.
      const response: any = await makeRequest({url: '/store/store-partners', method: 'PUT', body: data});

      switch (data.partner_name) {
        case 'DoorDash':
          return {doorDash: response.data[data.partner_name]};

        case 'GrubHub':
          return {grubHub: response.data[data.partner_name]};

        case 'UberEats':
          return {uberEats: response.data[data.partner_name]};

        default:
          return {};
      }

      // return {[data.partner_name]: response.data[data.partner_name]};
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  createPartnerTestOrder: async (data: any) => {
    try {
      const response: any = await makeRequest({
        url: '/v2/store/orders/dummy-orders',
        method: 'POST',
        body: {partner_name: data.partnerName},
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
};

export default storeService;
