import {io} from 'socket.io-client';
import {getActiveOrders, processOrder, readyForPickup, setIncomingOrder} from 'src/store/orderSlice';
import {setDeactivateStore, setShowNewOrderModal} from 'src/store/storeSlice';
import {showAlert} from 'src/store/alertSlice';
import {ORDER_STATUS, ORDER_FETCH_TYPE} from 'src/enums/order';
import {store} from 'src/store';
import {setStores} from 'src/store/authSlice';
import {PrinterService} from 'src/services/printer';
import {getDetailsFromOrderOld} from 'src/utils/helpers';
import {orderNotification} from 'src/utils/order-notification';
import AnalyticsHelper from 'src/utils/segment';
import firebaseCrashlytics from 'src/utils/firebase-crashlytics';
import moment from 'moment';

class SocketHelper {
  static socket: any = null;

  static connectSocket(storeId: string, dispatch: any) {
    if (!this.socket || this.socket.disconnected) {
      this.socket = io(process.env.REACT_APP_API_BASE_URL || '', {
        extraHeaders: {
          Authorization: store.getState().auth.token || '',
          store_id: storeId,
        },
      });

      this.socket.on('connect', () => {
        this.listenEvents(dispatch);
        dispatch(getActiveOrders());
      });
      this.socket.on("disconnect", (reason: any, details: any) => {
        AnalyticsHelper.trackSocketDisconnect(storeId, reason, details?.message, details?.description);
      });
    }
  }

  static disconnectSocket() {
    if (this.socket && this.socket.connected) {
      this.socket.off('order');
      this.socket.off('store');
      this.socket.disconnect();
    }
  }

  static listenEvents(dispatch: any) {  
    if (this.socket.connected) {
      this.socket.on('order_ready_for_pickup', (data: any) => {
        const order = store.getState().order.activeOrders.find((order) => order.id === data.orderDetail.id);
        if (data.orderDetail.id && order) {
          AnalyticsHelper.trackMarkReadyForPickup(data.orderDetail);
          dispatch(readyForPickup({id: data.orderDetail.id, time: moment().format()}));
        }
      });
      this.socket.on('order_completed', (data: any) => {
        const order = store.getState().order.activeOrders.find((order) => order.id === data.orderDetail.id);
        if (data.orderDetail.id && order) {
          AnalyticsHelper.trackOrderCompleted(order);
          dispatch(processOrder({id: data.orderDetail.id, status: ORDER_STATUS.COMPLETED}));
        }
      });
      this.socket.on('order', async (data: any) => {
        if (data.new_order) {
          const order = getDetailsFromOrderOld(data.new_order);

          dispatch(setIncomingOrder(order));
          dispatch(setShowNewOrderModal(true));

          // Segment new order track.
          AnalyticsHelper.trackNewOrder(order, ORDER_FETCH_TYPE.socket);
          AnalyticsHelper.groupStore();

          // Haptic feedback.
          if (orderNotification.paused) {
            orderNotification.play().catch(() => console.log('Cannot play audio'));
          }

          // Print using Star Printer through Bluetooth
          try {            
            const {printer} = store.getState().printer;
            if (printer.hasBluetoothPermission) {
              await PrinterService.getInstance().schedulePrintOrder(order);
            }
          } catch (err: any) {
            firebaseCrashlytics.log(`Order# ${order.id} cannot be printed. ${err.toString()}`);
            dispatch(
              showAlert({
                heading: 'Print Failed',
                message: `Order# ${order.id} cannot be printed. Please double check your Bluetooth connection with the printer.`,
              }),
            );
          }
        }
        if (data.cancelled_order_id) {
          dispatch(
            showAlert({
              heading: 'Order Canceled',
              message: `Order# ${data.cancelled_order_id} is cancelled by user. You cannot process it anymore.`,
            }),
          );
          //segment: cancelled order
          const cancelOrder = store.getState().order.activeOrders.find((order) => order.id === data.cancelled_order_id);
          AnalyticsHelper.trackCancelledOrder(cancelOrder);
          dispatch(processOrder({id: data.cancelled_order_id, status: ORDER_STATUS.CANCELLED}));
        }
        if (data.expired_order) {
          orderNotification.pause();

          dispatch(setShowNewOrderModal(false));
          dispatch(
            showAlert({
              heading: 'Order Expired',
              message: `Order# ${data.expired_order} is Expired. You cannot process it anymore.`,
            }),
          );
          dispatch(processOrder({id: data.expired_order, status: ORDER_STATUS.EXPIRED}));
        }
      });
      this.socket.on('store', (data: any) => {
        if (data.store) {
          dispatch(setDeactivateStore(data.store.is_disabled));
          const updatedStores = store
            .getState()
            .auth.userStores?.map((store: any) =>
              store.id === data.store.id ? {...store, isDeactivated: data.store.is_disabled} : store,
            );
          dispatch(setStores(updatedStores));
        }
      });
    }
  }
}

export default SocketHelper;
