import {IonFooter, IonImg} from '@ionic/react';
import './styles.scss';
import TermsText from 'src/components/TermsText';
import PrivacyText from '../PrivacyText';
import logo from 'src/assets/images/commerce-logo.png';

interface IProps {
  backgroundColor: string;
}

const Footer = ({backgroundColor}: IProps) => {
  return (
    <IonFooter id="footer" className="ion-no-border" style={{background: backgroundColor}}>
      <ul>
        <li>
          <IonImg src={logo} alt="" />
        </li>
        <li>&copy; Copyright Lula 2023</li>
        <li>
          <TermsText />
        </li>
        <li>
          <PrivacyText />
        </li>
      </ul>
    </IonFooter>
  );
};

export default Footer;
