/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect} from 'react';
import {IonBackButton, IonButton, IonContent, IonFooter, IonIcon, IonPage} from '@ionic/react';
import './styles.scss';
import {closeOutline} from 'ionicons/icons';
import {useForm} from 'react-hook-form';
import {RouteComponentProps} from 'react-router';
import Header from 'src/components/Header';
import Input from 'src/components/Input';
import {INPUT_MASK} from 'src/enums/input_mask';
import {useAppDispatch, useAppSelector} from 'src/hooks';
import {IEmployeeDetailFormInput} from 'src/interfaces/form';
import {getAllRoles} from 'src/store/authSlice';
import {getStoreEmployeesCount} from 'src/store/storeSlice';
import {addEmployee} from 'src/store/userSlice';
import {RULES} from 'src/utils/constants';
import RolesDropdown from '../components/RolesDropdown';
import Snackbar from 'src/components/Snackbar';
import arrowBack from 'src/assets/images/arrow-back-black.svg';

interface IProps extends RouteComponentProps {
  setShowAddEmployee?: (show: boolean) => void;
}

const AddEmployee = ({setShowAddEmployee}: IProps) => {
  const dispatch = useAppDispatch();

  const [showSnackbar, setShowSnackbar] = useState(false);

  const {menuIsOpen} = useAppSelector((state) => state.auth);
  const {isDesktop, isTablet} = useAppSelector((state) => state.platform);
  const {isRBACStore} = useAppSelector((state) => state.store);

  const {control, handleSubmit, reset} = useForm<IEmployeeDetailFormInput>({
    mode: 'onSubmit',
    defaultValues: {
      firstName: '',
      lastName: '',
      email: '',
      phone: '',
      password: '',
      role: '',
    },
  });

  const dismissSnackbar = () => {
    setShowSnackbar(false);
  };

  const addUser = (data: IEmployeeDetailFormInput) => {
    dispatch(addEmployee(data))
      .unwrap()
      .then((user: any) => {
        setShowSnackbar(true);
        if (user?.id) reset();
        dispatch(getStoreEmployeesCount());
      });
  };

  const onClose = () => {
    if (setShowAddEmployee) setShowAddEmployee(false);
  };

  useEffect(() => {
    if (isRBACStore) {
      dispatch(getAllRoles());
    }
  }, []);

  return (
    <IonPage id="add-employee-page" className={`${!menuIsOpen ? 'menu-close' : ''}`}>
      <Header
        border
        noBackground
        leftButton={!isDesktop && <IonBackButton defaultHref="/store/manage/employees" text="" icon={arrowBack} />}
        rightButton={
          isDesktop && (
            <IonButton onClick={onClose}>
              <IonIcon src={closeOutline} />
            </IonButton>
          )
        }
        title="Create employee"
      />
      <IonContent className={`${isTablet ? 'page-container' : ''}`}>
        <div className="body">
          <h2 className="title">Employee Details</h2>

          <Input
            wrapperClassName="input-field"
            type="text"
            name="firstName"
            label="First Name"
            control={control}
            rules={RULES.name}
          />

          <Input
            wrapperClassName="input-field"
            type="text"
            name="lastName"
            label="Last Name"
            control={control}
            rules={RULES.name}
          />

          <Input
            wrapperClassName="input-field"
            type="text"
            inputmode="numeric"
            name="phone"
            label="Phone Number"
            control={control}
            rules={RULES.phoneNumber}
            mask={INPUT_MASK.PHONE}
          />

          <div className="divider"></div>
          <h2 className="title">Login Credentials</h2>
          <Input
            wrapperClassName="input-field"
            type="email"
            inputmode="email"
            name="email"
            label="E-Mail"
            control={control}
            rules={RULES.email}
          />

          <Input
            wrapperClassName="input-field"
            type="password"
            name="password"
            label="Password"
            control={control}
            rules={RULES.password}
          />

          <div className="divider"></div>
          <h2 className="title">Permissions</h2>
          <RolesDropdown control={control} />
        </div>
      </IonContent>
      <Snackbar
        show={showSnackbar}
        type="success"
        title="Employee created"
        onButtonClick={dismissSnackbar}
        onDismiss={dismissSnackbar}
      />
      <IonFooter>
        <IonButton className="btn-dark" expand="block" onClick={handleSubmit(addUser)}>
          Create employee
        </IonButton>
      </IonFooter>
    </IonPage>
  );
};

export default AddEmployee;
