/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {IonItem, IonLabel, IonInput, IonButton, IonIcon, IonTextarea, IonImg, IonToggle, IonNote} from '@ionic/react';
import {Camera, CameraResultType} from '@capacitor/camera';
import {warningOutline} from 'ionicons/icons';
import {Controller, useForm, useWatch} from 'react-hook-form';
import './styles.scss';
import {ICategory, ISubcategory, IItem} from 'src/interfaces/item';
import {ITEM_TYPE} from 'src/enums/item_type';
import Input from 'src/components/Input';
import Select from 'src/components/Select';
import ItemImage from 'src/components/ItemImage';
import ModifiersList from 'src/components/ModifiersList';
import expandIcon from 'src/assets/images/expand.svg';
import shrinkIcon from 'src/assets/images/shrink.svg';
import addWhite from 'src/assets/images/add-white.svg';
import loading from 'src/assets/images/loading.svg';
import {useAppDispatch, useAppSelector} from 'src/hooks';
import MenuItemHours from '../MenuItemHours';
import {IMenuList, ITiming} from 'src/interfaces/store';
import menuService from 'src/services/menu';
import {RULES} from 'src/utils/constants';
import {useFeatureFlagEnabled} from 'posthog-js/react';
import {showAlert} from 'src/store/alertSlice';

interface IProps {
  validate: boolean;
  item: IItem;
  categories: Array<ICategory>;
  subcategories: Array<ISubcategory>;
  type: ITEM_TYPE.NEW | ITEM_TYPE.EDIT | ITEM_TYPE.SCANNED | ITEM_TYPE.COMMON | ITEM_TYPE.OUT_OF_STOCK;
  expandedForTab?: boolean;
  menuList: Array<IMenuList>;
  timings: Array<ITiming>;
  selectedMenu: Array<string>;
  menuItem: Array<string>;
  removedMenu: Array<string>;
  newMenu: Array<string>;
  onItemUpdate: (item: IItem) => void;
  onItemRemove?: (item: IItem) => void;
  archiveUnarchiveItem?: (item: IItem) => void;
  goToPrevItem?: () => void;
  goToNextItem?: () => void;
  setIsPriceValid: (e: boolean) => void;
  createHours: () => void;
  setTimings: (e: Array<ITiming>) => void;
  setSelectedMenu: (e: Array<string>) => void;
  setRemovedMenu: (e: Array<string>) => void;
  setNewMenu: (e: Array<string>) => void;
  onNewMenuClick: () => void;
  canEdit?: boolean;
  canDelete?: boolean;
}

interface IItemDetailForm {
  name: string;
  price: string;
  category: string;
  subcategory: string;
  description: string;
  size: string;
  unitCount: string;
  canSellIndependently: boolean;
}

const ItemDetail = ({
  item,
  categories,
  subcategories,
  type,
  expandedForTab,
  menuList,
  timings,
  selectedMenu,
  menuItem,
  removedMenu,
  newMenu,
  canEdit,
  canDelete,
  onItemUpdate,
  onItemRemove,
  archiveUnarchiveItem,
  createHours,
  setTimings,
  setSelectedMenu,
  setRemovedMenu,
  setNewMenu,
  onNewMenuClick,
}: IProps) => {
  const dispatch = useAppDispatch();
  const {newMenuId} = useAppSelector((state) => state.inventory);
  const {id: storeId} = useAppSelector((state) => state.store);
  const isModifiersEnabled = useFeatureFlagEnabled('menu-modifiers');
  const {control, formState, trigger} = useForm<IItemDetailForm>({
    mode: 'onChange',
    defaultValues: {
      name: item.name,
      price: item.price,
      category: item.category?.id || '',
      subcategory: item.subcategory?.id || '',
      size: item.size,
      unitCount: item.unitCount || '',
      description: item.description || '',
      canSellIndependently: type === ITEM_TYPE.NEW || type === ITEM_TYPE.SCANNED ? true : !!item.canSellIndependently,
    },
  });

  const fields = useWatch({control});
  const [selectedImg, setSelectedImg] = useState('');
  const [imgExpanded, setImgExpanded] = useState(false);
  const [descDisabled, setDescDisabled] = useState(!!item.description);
  const [imgLoading, setImageLoading] = useState(true);

  const setMenu = (value: string) => {
    let tempRemoveMenus = [...removedMenu];
    let tempSelectedMenus = [...selectedMenu];
    let tempNewMenus = [...newMenu];

    if (menuItem.includes(value)) {
      if (selectedMenu.includes(value)) {
        tempSelectedMenus = tempSelectedMenus.filter((item) => item !== value);
        setSelectedMenu(tempSelectedMenus);
        tempRemoveMenus.push(value);
        setRemovedMenu(tempRemoveMenus);
      } else if (removedMenu.includes(value)) {
        tempRemoveMenus = tempRemoveMenus.filter((item) => item !== value);
        setRemovedMenu(tempRemoveMenus);
        tempSelectedMenus.push(value);
        setSelectedMenu(tempSelectedMenus);
      }
    } else {
      if (selectedMenu.includes(value)) {
        tempSelectedMenus = tempSelectedMenus.filter((item) => item !== value);
        setSelectedMenu(tempSelectedMenus);
        tempNewMenus = tempNewMenus.filter((item) => item !== value);
        setNewMenu(tempNewMenus);
      } else {
        tempSelectedMenus.push(value);
        setSelectedMenu(tempSelectedMenus);
        tempNewMenus.push(value);
        setNewMenu(tempNewMenus);
      }
    }
    if (!!tempSelectedMenus.length) {
      menuService.getMenuHours(storeId, tempSelectedMenus).then((res) => {
        setTimings(res);
      });
    } else {
      createHours();
    }
  };

  useEffect(() => {
    if (formState.isDirty) {
      const formValues = {
        ...fields,
        image: item.image || selectedImg,
        category: categories.find((c) => c.id === fields.category) || {id: '', name: ''},
        subcategory: subcategories.find((c) => c.id === fields.subcategory) || {id: '', name: ''},
      };
      onItemUpdate({...item, ...formValues});
    }
  }, [...Object.values(fields), formState.isDirty]);

  const takePicture = async () => {
    const image = await Camera.getPhoto({
      quality: 90,
      allowEditing: false,
      correctOrientation: true,
      resultType: CameraResultType.Uri,
    });
    let imageUrl = image.webPath;
    if (imageUrl) {
      const blob = await fetch(imageUrl).then((r) => r.blob());
      const MAX_SIZE_MB = 5;
      const MAX_SIZE_BYTES = MAX_SIZE_MB * 1024 * 1024; // Convert MB to bytes
      const sizeInBytes = blob.size;
      if (sizeInBytes > MAX_SIZE_BYTES) {
        dispatch(
          showAlert({
            heading: 'Image File too large',
            message: 'The file size must not exceed 5MB',
          }),
        );
        return;
      }
      setSelectedImg(imageUrl);
      onItemUpdate({...item, image: blob, selectedImg: imageUrl});
    }
  };

  useEffect(() => {
    if (newMenuId) {
      setMenu(newMenuId);
    }
  }, [newMenuId]);

  useEffect(() => {
    const subcategoryExist = subcategories
      .filter((subcategory) => subcategory.category_id === item.category?.id)
      .some((subcategory) => subcategory.id === item.subcategory?.id);
    onItemUpdate({
      ...item,
      ...(!subcategoryExist && {subcategory: {id: '', name: ''}}),
    });
  }, [item.category?.id]);

  return (
    <div id="item-details">
      {type === ITEM_TYPE.NEW ? (
        selectedImg ? (
          <div className={`item-img-container ${imgExpanded || expandedForTab ? 'expanded' : ''}`}>
            <IonImg
              onIonImgDidLoad={() => setImageLoading(false)}
              className="selected-img"
              src={imgLoading ? loading : selectedImg}
            />
            <div className="actions-container">
              <IonButton onClick={takePicture}>
                <IonIcon className="icon-swap" />
              </IonButton>
              <div></div>
              {!expandedForTab && (
                <IonButton onClick={() => setImgExpanded(!imgExpanded)}>
                  <IonIcon icon={imgExpanded ? shrinkIcon : expandIcon} />
                </IonButton>
              )}
            </div>
          </div>
        ) : (
          <div className="select-img-container" onClick={takePicture}>
            <IonImg
              onIonImgDidLoad={() => setImageLoading(false)}
              className="add-img"
              src={imgLoading ? loading : addWhite}
            />
            <p className="title">Add Product Image</p>
            <p className="desc">File format can be any image, but the size must not exceed 5MB.</p>
          </div>
        )
      ) : (
        <div className={`item-img-container ${imgExpanded || expandedForTab ? 'expanded' : ''}`}>
          {selectedImg ? <IonImg src={selectedImg} /> : <ItemImage category={item.category?.name} image={item.image} />}
          <div className="actions-container">
            {((type === ITEM_TYPE.EDIT && item.isEditable) || type !== ITEM_TYPE.EDIT) && (
              <IonButton onClick={takePicture}>
                <IonIcon className="icon-swap" />
              </IonButton>
            )}
            {!expandedForTab && (
              <IonButton onClick={() => setImgExpanded(!imgExpanded)}>
                <IonIcon icon={imgExpanded ? shrinkIcon : expandIcon} />
              </IonButton>
            )}
          </div>
        </div>
      )}

      {type === ITEM_TYPE.SCANNED && (
        <p className="desc">We loaded general information about this item but need your store-specific details.</p>
      )}

      {type !== ITEM_TYPE.NEW && archiveUnarchiveItem && (
        <div className="availability-option-container">
          <div
            className={`availability-option instock ${item.active ? 'selected' : ''}`}
            onClick={() => {
              if (!item.active) archiveUnarchiveItem(item);
            }}>
            <p>In stock</p>
          </div>
          {canEdit && (
            <div
              className={`availability-option outstock ${!item.active ? 'selected' : ''}`}
              onClick={() => {
                if (item.active) archiveUnarchiveItem(item);
              }}>
              <p>Out of stock</p>
            </div>
          )}
        </div>
      )}

      <Input
        label="Name"
        type="text"
        name="name"
        placeholder="Item Name"
        control={control}
        disabled={!canEdit || (type === ITEM_TYPE.EDIT && !item.isEditable)}
        rules={RULES.required}
      />

      <div className="price-container">
        <Controller
          name="price"
          control={control}
          rules={isModifiersEnabled ? RULES.modifiersEnabledPrice : RULES.price}
          render={({field: {value, onChange}, fieldState: {invalid, error}}) => (
            <>
              <p className={canEdit ? 'desc' : 'text-muted'}>Price</p>
              <IonItem className={`item-price ${invalid ? 'invalid' : ''}`}>
                <IonLabel>$</IonLabel>
                <IonInput
                  type="number"
                  min="0"
                  inputmode="numeric"
                  disabled={!canEdit}
                  placeholder="0.00"
                  value={value}
                  onIonChange={onChange}
                />
              </IonItem>
              {error && <p className="err">{error.message}</p>}
              {isModifiersEnabled && !error && fields.canSellIndependently && Number(value) === 0.0 && (
                <div className="price-warning">
                  <IonIcon icon={warningOutline} slot="start" />
                  Warning: Item will be sold independently at no cost
                </div>
              )}
            </>
          )}
        />
      </div>

      {(type === ITEM_TYPE.COMMON || type === ITEM_TYPE.OUT_OF_STOCK) && (
        <div className="name-container">
          <p className={canEdit ? 'desc' : 'text-muted'}>Description</p>
          <Input type="text" name="description" disabled={!canEdit} placeholder="Description" control={control} />
        </div>
      )}

      <div className="details-container">
        <Select
          name="category"
          label="Category"
          control={control}
          optional={isModifiersEnabled && !fields.canSellIndependently}
          rules={fields.canSellIndependently ? {required: 'Item must be Categorized'} : {required: false}}
          options={categories}
          disabled={!canEdit || (type === ITEM_TYPE.EDIT && !item.isEditable)}
        />

        <Select
          optional
          name="subcategory"
          label="Sub-Category"
          control={control}
          options={subcategories.filter((subcategory) => subcategory.category_id === fields.category)}
          disabled={!canEdit || !fields.category || (type === ITEM_TYPE.EDIT && !item.isEditable)}
        />
      </div>

      <div className="details-container">
        <Input
          wrapperClassName="mb-0"
          label="Size"
          type="text"
          name="size"
          placeholder="Size"
          optional={isModifiersEnabled && !fields.canSellIndependently}
          control={control}
          disabled={!canEdit || (type === ITEM_TYPE.EDIT && !item.isEditable)}
          rules={fields.canSellIndependently ? RULES.required : {required: false}}
        />

        <Input
          wrapperClassName="mb-0"
          optional
          type="text"
          label="Unit Count"
          name="unitCount"
          placeholder="Unit Count"
          control={control}
          disabled={!canEdit || (type === ITEM_TYPE.EDIT && !item.isEditable)}
        />
      </div>

      {!!fields?.canSellIndependently ? (
        <MenuItemHours
          isMenuTitle={true}
          onNewMenuClick={onNewMenuClick}
          menuList={menuList}
          selectedMenu={selectedMenu}
          setMenu={setMenu}
          timings={timings}
          menuItem={menuItem}
        />
      ) : (
        <div className="menu-container">
          <IonLabel className="menu-title">Menu</IonLabel>
          <div className="menu-warning-info">
            <IonIcon icon={warningOutline} slot="start" />
            <div>Items must be sold independently to be added to a menu</div>
          </div>
        </div>
      )}

      {type !== ITEM_TYPE.COMMON && type !== ITEM_TYPE.OUT_OF_STOCK && (
        <div className="full-details-container">
          {type !== ITEM_TYPE.NEW && (
            <div className="product-info-container">
              <h2 className="title">Product Information</h2>
              {type === ITEM_TYPE.SCANNED && (
                <>
                  <p className="desc">Item Name</p>
                  <p className="desc info">{item.name}</p>
                </>
              )}
              <p className="desc">{item.upc ? 'Universal Product Code (UPC)' : 'European Article Number (EAN)'}</p>
              <p className="desc info">{item.upc ? item.upc : item.ean || ''}</p>
            </div>
          )}

          <Controller
            name="description"
            control={control}
            render={({field: {value, onChange}}) => (
              <div className="desc-container">
                <div className="desc-header">
                  <h2 className="title">Description</h2>
                  {!!fields.description &&
                    ((type === ITEM_TYPE.EDIT && item.isEditable) || type !== ITEM_TYPE.EDIT) && (
                      <IonButton fill="clear" disabled={!canEdit} onClick={() => setDescDisabled(false)}>
                        Edit
                      </IonButton>
                    )}
                </div>

                <IonTextarea
                  autoGrow
                  placeholder="No description yet."
                  value={value}
                  disabled={descDisabled || !canEdit}
                  onIonChange={onChange}
                />
              </div>
            )}
          />
        </div>
      )}

      {isModifiersEnabled && (
        <Controller
          name="canSellIndependently"
          control={control}
          render={({field: {value, onChange}}) => (
            <div className="sell-independent-container">
              <hr />
              <div className="sell-independent-section">
                <IonLabel className="sell-independent-title">
                  <h3>Sell independently</h3>
                  <IonNote>Determines whether a customers can order this item independently.</IonNote>
                </IonLabel>
                <IonToggle
                  name="canSellIndependently"
                  checked={value}
                  onIonChange={() => {
                    trigger(['name', 'price', 'size', 'category']);
                    onChange(!value);
                  }}
                />
              </div>
            </div>
          )}
        />
      )}

      {isModifiersEnabled && type !== ITEM_TYPE.NEW && !!item.modifierGroups?.length && (
        <ModifiersList modifiersListItems={item.modifierGroups} />
      )}

      {canDelete && type !== ITEM_TYPE.COMMON && type !== ITEM_TYPE.OUT_OF_STOCK && onItemRemove && (
        <IonButton
          className="btn-remove"
          mode="ios"
          fill="outline"
          color="danger"
          expand="block"
          onClick={() => onItemRemove(item)}>
          Delete item
        </IonButton>
      )}
    </div>
  );
};

export default ItemDetail;
