import {IonButton, IonIcon, IonImg, IonLabel, IonMenuToggle} from '@ionic/react';
import {chevronDownOutline} from 'ionicons/icons';
import {useState} from 'react';
import {useHistory} from 'react-router';
import {useDispatch} from 'react-redux';
import Countdown from 'react-countdown';
import './styles.scss';
import PauseOptions from 'src/components/PauseOptions';
import doordash from 'src/assets/images/doordash.svg';
import ubereats from 'src/assets/images/ubereats.svg';
import grubhub from 'src/assets/images/grubhub.svg';
import {useAppSelector} from 'src/hooks';
import TimerComponent from '../TimerComponent';
import {getStorePauseSchedules} from 'src/store/storeSlice';
import {calculateDurationFromEndDateAndTime} from 'src/utils/helpers';
import {IPartnerItem} from 'src/interfaces/store';

interface IProps {
  partnerName?: string;
  isSomePartnerPaused: boolean;
  currentPauseEndDate: string | undefined;
  currentPauseEndTime: string | undefined;
  onEndPause: () => void;
}

const GetStatus = ({partnerItem}: {partnerItem: IPartnerItem}) => {
  if (partnerItem.isOpen) return <p>Accepting Orders</p>;
  else if (partnerItem.isPaused)
    return (
      <>
        <p>Paused</p>
        <div className="countdown">
          <Countdown
            date={
              Date.now() +
              Math.abs(
                calculateDurationFromEndDateAndTime(
                  partnerItem.currentPause?.endDate,
                  partnerItem.currentPause?.endTime,
                ),
              )
            }
            renderer={({days, hours, minutes, seconds}) => (
              <strong className="paused-counter">
                {days}d {hours < 10 ? '0' + hours : hours}:{minutes < 10 ? '0' + minutes : minutes}:
                {seconds < 10 ? '0' + seconds : seconds}
              </strong>
            )}
          />
        </div>
      </>
    );
  else if (partnerItem.isOffline) return <p>Offline</p>;
  else return <></>;
};

const StoreStatusItem = () => {
  const {uberEats, grubHub, doorDash} = useAppSelector((state) => state.store.storeStatus.partners);
  return (
    <>
      <div className="store-status">
        <IonImg src={grubhub} />
        <GetStatus partnerItem={grubHub[0]} />
      </div>

      <div className="store-status">
        <IonImg src={doordash} />
        <GetStatus partnerItem={doorDash[0]} />
      </div>

      <div className="store-status bottom-partner">
        <IonImg src={ubereats} />
        <GetStatus partnerItem={uberEats[0]} />
      </div>
    </>
  );
};

const StoreStatusMenuPaused = ({
  partnerName,
  isSomePartnerPaused,
  currentPauseEndDate,
  currentPauseEndTime,
  onEndPause,
}: IProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [opened, setOpened] = useState(false);
  const [partnerClick, setPartnerClick] = useState(false);
  const {isDesktop} = useAppSelector((state) => state.platform);
  const {storePauseScheduleList} = useAppSelector((state) => state.store);
  const {currentPause, uberEats, grubHub, doorDash} = useAppSelector((state) => state.store?.storeStatus?.partners);
  const partnerList = [doorDash?.[0], grubHub?.[0], uberEats?.[0]];
  const partnerPausedCount = partnerList.filter((dspItem) => !dspItem.isOpen).length;
  const partnerOfflineCount = partnerList.filter((dspItem) => dspItem.isOffline).length;
  const milliseconds = Math.abs(calculateDurationFromEndDateAndTime(currentPauseEndDate, currentPauseEndTime));

  const getSubtitle = () => {
    if (!!currentPause) {
      return 'All partner paused';
    } else {
      return `${partnerPausedCount} partner paused${
        partnerOfflineCount ? `, ${partnerOfflineCount} partner offline` : ''
      }`;
    }
  };

  return (
    <>
      <TimerComponent milliseconds={milliseconds} onEndPause={onEndPause} />
      <div id="status-menu">
        <div
          className={`bg-yellow status-menu-container ${opened ? 'open' : 'close'}`}
          onClick={() => setOpened(!opened)}>
          {isSomePartnerPaused && !partnerName ? (
            <div className="status-menu">
              <IonLabel>{partnerPausedCount === 3 ? 'Order Paused' : 'Accepting orders'} </IonLabel>
              <IonLabel className="subtitle">{getSubtitle()}</IonLabel>
            </div>
          ) : (
            <div className="status-menu">
              <IonLabel>Order Paused</IonLabel>
              {partnerName ? (
                <IonLabel className="subtitle">{partnerName}</IonLabel>
              ) : (
                <IonLabel className="subtitle">All partners</IonLabel>
              )}
            </div>
          )}
          <IonIcon icon={chevronDownOutline} slot="end" className={`${opened ? 'open' : 'close'}`} />
        </div>
        {isSomePartnerPaused && !partnerName
          ? opened && (
              <div className="status-menu-content" onClick={() => setPartnerClick(!partnerClick)}>
                <StoreStatusItem />

                {partnerClick ? (
                  <PauseOptions onEndPause={onEndPause} />
                ) : (
                  <div className="partner-button">
                    <IonMenuToggle menu="menu" autoHide={false}>
                      <IonButton
                        className="btn-outline"
                        expand="block"
                        mode="md"
                        onClick={() => {
                          dispatch(getStorePauseSchedules());
                          isDesktop
                            ? history.push({pathname: '/partners', state: {showSchedulePause: true}})
                            : history.push({pathname: '/partners/schedule'});
                        }}>
                        {`Scheduled pauses (${storePauseScheduleList.length})`}
                      </IonButton>
                    </IonMenuToggle>
                  </div>
                )}
              </div>
            )
          : opened && (
              <div className="status-menu-content">
                <PauseOptions partnerName={partnerName} onEndPause={onEndPause} />
              </div>
            )}
      </div>
    </>
  );
};

export default StoreStatusMenuPaused;
