import {IonPage, IonContent, IonImg, IonButton} from '@ionic/react';
import './styles.scss';
import Footer from 'src/components/Footer';
import TermsText from 'src/components/TermsText';
import PrivacyText from 'src/components/PrivacyText';
import logo from 'src/assets/images/logo.png';

interface IProps {
  goToLogin: () => void;
}

const DesktopScreen = (props: IProps) => {
  return (
    <IonPage id="welcome-page-desktop">
      <IonContent fullscreen>
        <div className="body">
          <div className="content-container">
            <div className="logo-container">
              <IonImg src={logo} alt="logo" />
            </div>

            <div className="details-container">
              <div className="content">
                <h2 className="ion-text-center title">Empower your Store to deliver locally.</h2>

                <p className="ion-text-center desc">
                  Join the 100’s of other stores already delivering the products that their Customers love, locally and
                  sustainably empowered by Lula.
                </p>

                <IonButton className="btn-primary" expand="block" onClick={props.goToLogin}>
                  Sign In
                </IonButton>

                <p className="ion-text-center terms-privacy-text">
                  By registering an account and using this Service, you agree to our <TermsText /> and <PrivacyText />.
                </p>
              </div>
            </div>
          </div>
        </div>
      </IonContent>

      <Footer backgroundColor="var(--custom-color-white-smoke)" />
    </IonPage>
  );
};

export default DesktopScreen;
