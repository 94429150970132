import React from 'react';
import ReactDOM from 'react-dom';
import {IonApp} from '@ionic/react';
import {IonReactRouter} from '@ionic/react-router';
import {PostHogProvider} from 'posthog-js/react';
import {Provider} from 'react-redux';
import {store} from 'src/store';
import App from './App';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import reportWebVitals from './reportWebVitals';

const options = {
  api_host: process.env.REACT_APP_POSTHOG_HOST,
};

ReactDOM.render(
  <React.StrictMode>
    <IonApp>
      <Provider store={store}>
        <PostHogProvider apiKey={process.env.REACT_APP_POSTHOG_KEY} options={options}>
          <IonReactRouter>
            <App />
          </IonReactRouter>
        </PostHogProvider>
      </Provider>
    </IonApp>
  </React.StrictMode>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://cra.link/PWA
serviceWorkerRegistration.unregister();

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
