import {useState, useRef} from 'react';
import {IonIcon, IonDatetime} from '@ionic/react';
import './styles.scss';
import {useAppSelector, useClickOutside} from 'src/hooks';
import moment from 'moment';
import {IStorePauseItem} from 'src/interfaces/store';

interface IProps {
  selectedDate: string;
  setSelectedDate: (e: string) => void;
}

const SchedulePauseDatePicker = ({selectedDate, setSelectedDate}: IProps) => {
  const [opened, setOpened] = useState(false);
  const {storePauseScheduleList} = useAppSelector((state) => state.store);
  const wrapperRef = useRef(null);

  //this is for close on click outside the modal.
  useClickOutside(wrapperRef, () => {
    setOpened(false);
  });

  const dateChange = (e: any) => {
    setSelectedDate(e.detail.value);
  };

  return (
    <div id="schedule-pause-date-picker" ref={wrapperRef}>
      <div
        className="filter-header"
        onClick={() => {
          if (opened) setOpened(false);
          else setOpened(true);
        }}>
        <IonIcon className="icon-calendar" slot="start" />
        <div className="date-label">{moment(selectedDate).format('LL')}</div>
      </div>

      {opened && (
        <div className="calender-menu">
          <IonDatetime
            presentation="date"
            onIonChange={dateChange}
            value={selectedDate}
            min={moment().format()}
            highlightedDates={storePauseScheduleList.map((item: IStorePauseItem) => {
              return {
                date: item.startDate,
                textColor: 'var(--ion-color-primary)',
                backgroundColor: 'var(--custom-color-wisp-pink)',
              };
            })}
          />
        </div>
      )}
    </div>
  );
};

export default SchedulePauseDatePicker;
