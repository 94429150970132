import {IonIcon} from '@ionic/react';
import './styles.scss';
import {IEmployee} from 'src/interfaces/user';

interface IProps {
  user: IEmployee;
  onClick: (employee: IEmployee) => void;
}

const EmployeeCard = ({user, onClick}: IProps) => (
  <div id="employee-card" className="set-height" onClick={() => onClick(user)}>
    <div className="details">
      <div className="profile">
        <p>
          {user.firstName.charAt(0)}
          {user.lastName.charAt(0)}
        </p>
      </div>

      <div className="name-container">
        <p className="name">
          {user.firstName} {user.lastName}
        </p>
        <p className="email">{user.email}</p>
      </div>
    </div>

    <div className="role-container">
      <IonIcon className={user.role.icon || 'icon-cog-filled'} style={{color: user.role.color}} />
      <p className="role">{user.role.name}</p>
    </div>
  </div>
);

export default EmployeeCard;
