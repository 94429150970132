import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {showLoading, hideLoading} from 'src/store/loadingSlice';
import {showAlert} from 'src/store/alertSlice';
import {IEmployee} from 'src/interfaces/user';
import userService from 'src/services/user';
import {INPUT_MASK} from 'src/enums/input_mask';
import {unMaskValue} from 'src/utils/helpers';
import {nonRBACRoles} from 'src/utils/constants';
import {RootState} from 'src/store';

interface IUser {
  usersCount: number;
  users: Array<IEmployee>;
}

export const getEmployees = createAsyncThunk(
  'users/getEmployees',
  async (params: {search_string: string; offset: number}, {dispatch, getState, fulfillWithValue, rejectWithValue}) => {
    const {store, user} = getState() as RootState;
    try {
      if (params.offset === 0) dispatch(showLoading());
      const employees = await userService.getAllEmployees(store.id, {
        ...params,
        is_rbac_user: store.isRBACStore,
      });
      dispatch(setEmployeeCount(employees.count));
      if (params.offset === 0) dispatch(hideLoading());
      return fulfillWithValue(params.offset === 0 ? employees.users : [...user.users, ...employees.users]);
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(
        showAlert({
          heading: 'Unable to fetch data',
          message: error?.message || 'Some error occurred while fetching data. Please, try again later',
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const addEmployee = createAsyncThunk(
  'users/addEmployee',
  async (user: any, {dispatch, getState, fulfillWithValue, rejectWithValue}) => {
    const {store: selectedStore, auth} = getState() as RootState;

    const ROLE = selectedStore.isRBACStore
      ? auth.roles.find((r) => r.id === user.role)
      : nonRBACRoles.find((r) => r.id === user.role);

    try {
      dispatch(showLoading());
      const data: any = {
        first_name: user.firstName,
        last_name: user.lastName,
        email: user.email,
        phone_number: `+1${unMaskValue(INPUT_MASK.PHONE, user.phone)}`,
        password: user.password,
        role: selectedStore.isRBACStore ? ROLE?.name : ROLE?.id,
        role_id: user.role,
        user_rbac: selectedStore.isRBACStore,
      };
      if (selectedStore.company?.id) data.company_id = selectedStore.company?.id;
      const response = await userService.addEmployee(data);
      dispatch(hideLoading());
      return fulfillWithValue({id: response.id, ...user, role: ROLE});
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(
        showAlert({
          heading: 'Unable to add',
          message:
            error?.message[0] ||
            error?.message ||
            'Some error occurred while adding employee to store. Please, try again later',
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const updateEmployee = createAsyncThunk(
  'users/updateEmployee',
  async (user: any, {dispatch, getState, fulfillWithValue, rejectWithValue}) => {
    const {store: selectedStore, auth} = getState() as RootState;

    const ROLE = selectedStore.isRBACStore
      ? auth.roles.find((r) => r.id === user.role)
      : nonRBACRoles.find((r) => r.id === user.role);

    try {
      dispatch(showLoading());
      const data: any = {
        first_name: user.firstName,
        last_name: user.lastName,
        phone_number: `+1${unMaskValue(INPUT_MASK.PHONE, user.phone)}`,
        role: selectedStore.isRBACStore ? ROLE?.name : ROLE?.id,
        role_id: user.role,
        user_rbac: selectedStore.isRBACStore,
      };
      if (user.password) data.password = user.password;
      if (selectedStore.company?.id) data.company_id = selectedStore.company?.id;
      await userService.updateEmployee(user.id, data);
      dispatch(hideLoading());
      return fulfillWithValue({...user, role: ROLE});
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(
        showAlert({
          heading: 'Unable to update',
          message:
            error?.message[0] ||
            error?.message ||
            'Some error occurred while updating employee details. Please, try again later',
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const deleteEmployee = createAsyncThunk(
  'users/removeEmployee',
  async (userId: any, {dispatch, getState, fulfillWithValue, rejectWithValue}) => {
    const {store: selectedStore} = getState() as RootState;
    try {
      dispatch(showLoading());
      await userService.deleteEmployee(userId, {user_rbac: selectedStore.isRBACStore});
      dispatch(hideLoading());
      return fulfillWithValue(userId);
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(
        showAlert({
          heading: 'Unable to delete',
          message: error?.message || 'Some error occurred while deleting employee. Please, try again later',
        }),
      );
      return rejectWithValue(error);
    }
  },
);

const initialState: IUser = {
  usersCount: 0,
  users: [],
};

const userSlice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    setEmployeeCount: (state, action) => {
      state.usersCount = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getEmployees.fulfilled, (state, action) => {
        state.users = action.payload;
      })
      .addCase(addEmployee.fulfilled, (state, action) => {
        state.users.unshift(action.payload);
        state.usersCount += 1;
      })
      .addCase(updateEmployee.fulfilled, (state, action) => {
        state.users = state.users.map((user) => (user.id === action.payload.id ? action.payload : user));
      })
      .addCase(deleteEmployee.fulfilled, (state, action) => {
        state.users = state.users.filter((user) => user.id !== action.payload);
        state.usersCount -= 1;
      });
  },
});

export const {setEmployeeCount} = userSlice.actions;

export default userSlice.reducer;
