import {IonPage, IonContent, IonImg, IonText, IonButton} from '@ionic/react';
import './styles.scss';
import {useAppDispatch, useAppSelector} from 'src/hooks';
import {hideLoading, showLoading} from 'src/store/loadingSlice';
import {showAlert} from 'src/store/alertSlice';
import {logoutUser} from 'src/store/authSlice';
import notificationService from 'src/services/notifications';
import userService from 'src/services/user';
import AnalyticsHelper from 'src/utils/segment';
import noStore from 'src/assets/images/no-store.png';

const NoStore = () => {
  const dispatch = useAppDispatch();

  const {isNative, isAndroid, device} = useAppSelector((state) => state.platform);

  const logout = async () => {
    dispatch(showLoading());
    if (isNative && isAndroid) {
      notificationService.deactivateEmployeeNotificationDevice(device.deviceId);
      AnalyticsHelper.trackEmployeeDeviceUpdate(false);
    }
    await userService
      .logoutEmployee()
      .then(() => {
        AnalyticsHelper.trackLogout();
        dispatch(logoutUser());
        dispatch(hideLoading());
      })
      .catch((error: any) => {
        dispatch(hideLoading());
        dispatch(
          showAlert({
            heading: 'Error',
            message: error?.message || 'Some error occurred while logging you out. Try again later',
          }),
        );
      });
  };

  return (
    <IonPage id="no-store-page">
      <IonContent fullscreen scrollY={false}>
        <div className="body">
          <IonImg className="logo" src={noStore} />

          <IonText className="title">No linked store</IonText>
          <IonText className="desc">Sorry, your account is not linked with any store.</IonText>
          <IonText className="desc">Contact your Admin to update store access</IonText>

          <IonButton className="btn-outline" mode="ios" onClick={logout}>
            Log out
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default NoStore;
