import {IonModal, IonButton, IonImg} from '@ionic/react';
import './styles.scss';
import itemNotFound from 'src/assets/images/item-not-found.svg';

interface IProps {
  isOpen: boolean;
  addDetails: () => void;
  browseItems: () => void;
  onClose: () => void;
}

const ItemNotFoundModal = (props: IProps) => (
  <IonModal className="app-modal item-not-found-modal" isOpen={props.isOpen} showBackdrop backdropDismiss={false}>
    <div className="modal-content-container">
      <div className="modal-content">
        <div className="img-container">
          <IonImg src={itemNotFound} />
        </div>

        <h2 className="modal-title">Item was not found in the Database.</h2>

        <IonButton className="btn-primary mb-0" expand="block" onClick={props.addDetails}>
          Add Details Manually
        </IonButton>

        <IonButton className="btn-secondary mb-0" expand="block" onClick={props.browseItems}>
          Browse common Items
        </IonButton>

        <IonButton className="btn-primary footer-btn mb-0" expand="block" fill="clear" onClick={props.onClose}>
          Close this message
        </IonButton>
      </div>
    </div>
  </IonModal>
);

export default ItemNotFoundModal;
