/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect} from 'react';
import {IonPage, IonContent, IonBackButton, IonButton, IonFooter, IonIcon} from '@ionic/react';
import './styles.scss';
import {RouteComponentProps} from 'react-router';
import {useForm} from 'react-hook-form';
import {closeOutline} from 'ionicons/icons';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import Header from 'src/components/Header';
import Input from 'src/components/Input';
import Select from 'src/components/Select';
import {IStoreSetupDetailFormInput} from 'src/interfaces/form';
import {useAppDispatch, useAppSelector} from 'src/hooks';
import {updateStore} from 'src/store/storeSlice';
import {getAddressDetails, maskValue, unMaskValue, checkPermission} from 'src/utils/helpers';
import {INPUT_MASK} from 'src/enums/input_mask';
import {ADDRESS_TYPE} from 'src/enums/address_type';
import {RULES} from 'src/utils/constants';
import AnalyticsHelper from 'src/utils/segment';
import arrowBack from 'src/assets/images/arrow-back-black.svg';
import * as PERMISSIONS from 'src/utils/permissions';
import {STATES} from 'src/utils/states';

interface IProps extends RouteComponentProps {
  setShowStoreDetails?: (show: boolean) => void;
}

const StoreDetailsUpdate = ({setShowStoreDetails}: IProps) => {
  const dispatch = useAppDispatch();

  const {id, name: sName, address: sAddress, phone: sPhone, email: sEmail} = useAppSelector((state) => state.store);
  const {user, menuIsOpen} = useAppSelector((state) => state.auth);
  const {isDesktop, isTablet} = useAppSelector((state) => state.platform);
  const canEditDetails = checkPermission(user?.role.permissions, PERMISSIONS.EDIT_STORE_DETAILS);

  const {control, handleSubmit, setValue, getValues} = useForm<IStoreSetupDetailFormInput>({
    mode: 'onSubmit',
    defaultValues: {
      name: '',
      address: '',
      city: '',
      state: '',
      zipCode: '',
      phno: '',
      email: '',
    },
  });

  const [addressPredictions, setPredictions] = useState<Array<any>>();
  const [showPredictions, setShowPredictions] = useState(false);

  const {placesService, placePredictions, getPlacePredictions} = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    debounce: 200,
  });

  const saveChanges = (formData: IStoreSetupDetailFormInput) => {
    const data = {
      id,
      name: formData.name,
      email: formData.email,
      phone_number: `+1${unMaskValue(INPUT_MASK.PHONE, formData.phno)}`,
      addresses: [
        {
          id: sAddress.id,
          line_1: formData.address,
          city: formData.city,
          state: formData.state,
          zip: formData.zipCode,
          address_type: ADDRESS_TYPE.STORE_ADDRESS,
        },
      ],
    };
    dispatch(updateStore(data))
      .unwrap()
      .then(async (store: any) => {
        AnalyticsHelper.groupStore();
        AnalyticsHelper.identifyStore(user, data);
      });
  };

  const onTypingAddress = (value: any) => {
    setValue('address', value);
    getPlacePredictions({input: value, componentRestrictions: {country: 'US'}, types: ['address']});
    const data = placePredictions.map((item) => ({description: item.description, placeID: item.place_id}));

    setPredictions(data);
    if (placePredictions.length > 0 && getValues('address').length > 0) {
      setShowPredictions(true);
    } else {
      setShowPredictions(false);
    }
  };

  const onClickAddress = (id: string) => {
    placesService?.getDetails({placeId: id, fields: ['address_components']}, (placeDetails: any) => {
      const {streetAddress, addressCity, addressState, postalCode} = getAddressDetails(placeDetails);
      if (streetAddress) setValue('address', streetAddress);
      if (addressCity) setValue('city', addressCity);
      if (addressState) setValue('state', addressState);
      if (postalCode) setValue('zipCode', postalCode);
      setShowPredictions(false);
    });
  };

  const onClose = () => {
    if (setShowStoreDetails) setShowStoreDetails(false);
  };

  // Change state of component when redux state changes.
  useEffect(() => {
    if (sName) setValue('name', sName);
    if (sAddress.line_1) setValue('address', sAddress.line_1);
    if (sAddress.city) setValue('city', sAddress.city);
    if (sAddress.state) setValue('state', sAddress.state);
    if (sAddress.zip) setValue('zipCode', sAddress.zip);
    if (sPhone) setValue('phno', maskValue(INPUT_MASK.DISPLAY_PHONE, sPhone));
    if (sEmail) setValue('email', sEmail);
  }, [id, sName, sPhone, sEmail, sAddress]);

  return (
    <IonPage id="manage-store-details-page" className={`${isTablet && !menuIsOpen ? 'menu-close' : ''}`}>
      <IonContent fullscreen scrollY={false} className={`${isTablet ? 'page-container' : ''}`}>
        <Header
          noBackground
          title="Store Details"
          leftButton={!isDesktop && <IonBackButton defaultHref="/store/manage" text="" icon={arrowBack} />}
          rightButton={
            isDesktop && (
              <IonButton onClick={onClose}>
                <IonIcon src={closeOutline} />
              </IonButton>
            )
          }
        />

        <div className={`body ${canEditDetails ? 'with-footer' : 'without-footer'}`}>
          <Input
            type="text"
            name="name"
            label="Store Name"
            placeholder="Store Name"
            control={control}
            rules={RULES.required}
            disabled={!!sName}
          />
          {!!sName ? <p className="desc">Please contact the Lula Support team to update your store name</p> : null}
          <p className="heading">Location</p>
          <Input
            type="text"
            name="address"
            label="Address"
            placeholder="Address"
            control={control}
            rules={{required: true}}
            disabled={!canEditDetails}
            onTypingAddress={onTypingAddress}
            addressPredictions={addressPredictions}
            onClickAddress={onClickAddress}
            showPredictions={showPredictions}
          />

          <Input
            type="text"
            name="city"
            label="City"
            placeholder="City"
            control={control}
            rules={RULES.required}
            disabled={!canEditDetails}
          />

          <div className="state-zip-container">
            <Select
              wrapperClassName="state-select"
              name="state"
              label="State"
              rules={RULES.required}
              control={control}
              options={Object.keys(STATES).map((s) => ({id: s, name: s}))}
              disabled={!canEditDetails}
            />

            <Input
              wrapperClassName="zip-code-input"
              type="number"
              inputmode="numeric"
              name="zipCode"
              label="ZIP-code"
              placeholder="Zip-code"
              control={control}
              rules={RULES.required}
              disabled={!canEditDetails}
            />
          </div>

          <p className="heading">Contact</p>
          <Input
            type="text"
            inputmode="numeric"
            name="phno"
            label="Store phone Number"
            placeholder="Store Phone Number"
            control={control}
            rules={RULES.phoneNumber}
            disabled={!canEditDetails}
            mask={INPUT_MASK.PHONE}
          />

          <Input
            type="email"
            inputmode="email"
            name="email"
            label="Store e-mail"
            placeholder="Store Email"
            control={control}
            rules={RULES.email}
            disabled={!canEditDetails}
          />
        </div>

        {canEditDetails && (
          <IonFooter>
            <IonButton className="btn-dark" expand="block" onClick={handleSubmit(saveChanges)}>
              Save Changes
            </IonButton>
          </IonFooter>
        )}
      </IonContent>
    </IonPage>
  );
};

export default StoreDetailsUpdate;
