import {useState, useEffect} from 'react';
import {IonPage, IonContent, IonGrid, IonRow, IonCol} from '@ionic/react';
import './styles.scss';
import {RouteComponentProps} from 'react-router';
import CommonHeader from 'src/components/CommonHeader';
import ListItem from 'src/components/ListItem';
import StoreDetails from 'src/pages/Store/Details';
import StoreHours from 'src/pages/Store/Hours';
import WelcomePackage from 'src/pages/Store/Welcome';
import EmployeeList from 'src/pages/Store/Employees/List';
import AddEmployee from 'src/pages/Store/Employees/Add';
import EditEmployee from 'src/pages/Store/Employees/Edit';
import MenuList from 'src/pages/Store/Menu/List';
import Details from 'src/pages/Store/Menu/Details';
import {IEmployee} from 'src/interfaces/user';
import {useAppSelector} from 'src/hooks';
import {ROUTE_LOCATION} from 'src/enums/route_location';
import * as PERMISSIONS from 'src/utils/permissions';
import {checkPermission} from 'src/utils/helpers';

interface IProps extends RouteComponentProps {}

const ManageStore = ({history, location, match}: IProps) => {
  const {user, menuIsOpen} = useAppSelector((state) => state.auth);
  const {isDesktop, isTablet} = useAppSelector((state) => state.platform);
  const store = useAppSelector((state) => state.store);
  const userPermissions = user?.role.permissions;
  const {printer} = useAppSelector((state) => state.printer);

  const [showStoreDetails, setShowStoreDetails] = useState(false);
  const [showStoreHours, setShowStoreHours] = useState(false);
  const [showWelcomePackage, setShowWelcomePackage] = useState(false);
  const [showEmployeeList, setShowEmployeeList] = useState(false);
  const [showAddEmployee, setShowAddEmployee] = useState(false);
  const [showEditEmployee, setShowEditEmployee] = useState(false);
  const [showMenuList, setShowMenuList] = useState(false);
  const [showMenuDetail, setShowMenuDetail] = useState(false);
  const [selectedEmployee, setSelectedEmployee] = useState<IEmployee | null>(null);
  const [menuDetailId, setMenuDetailId] = useState('');

  const canViewMenu = checkPermission(userPermissions, PERMISSIONS.VIEW_MENU);
  const canViewStoreDetails = checkPermission(userPermissions, PERMISSIONS.VIEW_STORE_DETAILS);
  const canEditStoreHours = checkPermission(userPermissions, PERMISSIONS.EDIT_STORE_HOURS);
  const canViewShippingDetails = checkPermission(userPermissions, PERMISSIONS.VIEW_STORE_SHIPPING_DETAILS);
  const canViewEmployees = checkPermission(userPermissions, PERMISSIONS.VIEW_EMPLOYEES);
  const canViewPrinter = checkPermission(userPermissions, PERMISSIONS.VIEW_PRINTER_SETTINGS);

  const resetState = () => {
    setShowStoreDetails(false);
    setShowStoreHours(false);
    setShowWelcomePackage(false);
    setShowEmployeeList(false);
    setShowAddEmployee(false);
    setShowEditEmployee(false);
    setShowMenuList(false);
    setShowMenuDetail(false);
  };

  const detailsClick = () => {
    if (isDesktop) {
      resetState();
      setShowStoreDetails(true);
    } else history.push({pathname: '/store/manage/details', state: {from: ROUTE_LOCATION.MANAGE_STORE}});
  };

  const hoursClick = () => {
    if (isDesktop) {
      resetState();
      setShowStoreHours(true);
    } else history.push({pathname: '/store/manage/hours', state: {from: ROUTE_LOCATION.MANAGE_STORE}});
  };

  const packageClick = () => {
    if (isDesktop) {
      resetState();
      setShowWelcomePackage(true);
    } else history.push('/store/manage/welcome');
  };

  const employeesClick = () => {
    if (isDesktop) {
      resetState();
      setShowEmployeeList(true);
    } else history.push('/store/manage/employees');
  };

  const printerClick = () => {
    if (isDesktop) {
      resetState();
    } else history.push({pathname: '/store/manage/printer'});
  };

  const menuClick = () => {
    if (isDesktop) {
      resetState();
      setShowMenuList(true);
    } else history.push({pathname: '/store/manage/menu', state: {from: ROUTE_LOCATION.MANAGE_STORE}});
  };

  const menuDetailClick = () => {
    if (isDesktop) {
      resetState();
      setShowMenuDetail(true);
    }
  };

  useEffect(() => {
    resetState();
  }, [store?.id]);

  return (
    <IonPage id="store-manage-page" className="snow-background">
      <IonGrid>
        <IonRow>
          <IonCol sizeXl="8" className={`${isTablet && !menuIsOpen ? 'menu-close' : ''}`}>
            <IonContent fullscreen scrollY={false} className="page-container">
              <CommonHeader className="title-left" title="Store" menuIsOpen={menuIsOpen} />

              <div className="body">
                <p className="heading">General</p>
                {canViewStoreDetails && (
                  <ListItem
                    fullWidth
                    icon="icon-pencil"
                    title="Store Details"
                    description="Store Name and contact Details"
                    onClick={detailsClick}
                    selected={showStoreDetails}
                  />
                )}
                {canEditStoreHours && (
                  <ListItem
                    fullWidth
                    icon="icon-clock"
                    title="Store Hours"
                    description="Edit days and exceptions"
                    onClick={hoursClick}
                    selected={showStoreHours}
                  />
                )}

                <>
                  {canViewShippingDetails && (
                    <ListItem
                      fullWidth
                      icon="icon-gift"
                      title="Shipping Details"
                      description="Claim your complementary items"
                      onClick={packageClick}
                      selected={showWelcomePackage}
                    />
                  )}
                  {!isDesktop && canViewPrinter && (
                    <ListItem
                      fullWidth
                      icon="icon-print"
                      title="Printer"
                      status={printer?.connectionStatus}
                      description="Manage your store printer"
                      onClick={printerClick}
                    />
                  )}
                  {canViewMenu && (
                    <ListItem
                      fullWidth
                      icon="icon-hourglass"
                      title="Menus"
                      description="Control when items are sold"
                      selected={showMenuList}
                      onClick={menuClick}
                    />
                  )}
                  {canViewEmployees && (
                    <>
                      <p className="heading">Account</p>
                      <ListItem
                        fullWidth
                        icon="icon-users"
                        title="Employees"
                        description="Users and permissions"
                        onClick={employeesClick}
                        selected={showEmployeeList}
                      />
                    </>
                  )}
                </>
              </div>
            </IonContent>
          </IonCol>
          {isDesktop && (
            <IonCol sizeXl="4">
              {showStoreDetails && canViewStoreDetails && (
                <StoreDetails {...{history, location, match, setShowStoreDetails}} />
              )}
              {showStoreHours && canViewStoreDetails && (
                <StoreHours {...{history, location, match, setShowStoreHours}} />
              )}
              {showWelcomePackage && <WelcomePackage {...{history, location, match, setShowWelcomePackage}} />}
              {showEmployeeList && (
                <EmployeeList
                  history={history}
                  location={location}
                  match={match}
                  setShowEmployeeList={setShowEmployeeList}
                  setShowAddEmployee={setShowAddEmployee}
                  setShowEditEmployee={setShowEditEmployee}
                  setSelectedEmployee={setSelectedEmployee}
                />
              )}
              {showAddEmployee && <AddEmployee {...{history, location, match, setShowAddEmployee}} />}
              {showEditEmployee && (
                <EditEmployee
                  {...{history, location: {state: {employee: selectedEmployee}}, match, setShowEditEmployee}}
                />
              )}
              {showMenuList && <MenuList {...{setShowMenuList, menuDetailClick, setMenuDetailId}} />}
              {showMenuDetail && (
                <Details
                  {...{history, match, location: {state: {menuDetailId}}, setShowMenuDetail, isStayForEdit: true}}
                />
              )}
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
    </IonPage>
  );
};

export default ManageStore;
