import {IonItem, IonLabel, IonIcon} from '@ionic/react';
import './styles.scss';

const StoreHeader = ({store}: any) => (
  <IonItem id="store-location">
    <IonIcon className="stores-icon" />
    <div className="stores-list-item">
      <IonLabel className="label">{store?.name}</IonLabel>
      <p>{store?.address?.line_1} </p>
    </div>
  </IonItem>
);

export default StoreHeader;
