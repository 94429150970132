import {useState, useEffect, useRef} from 'react';
import {IonIcon, IonCheckbox, IonLabel, IonNote, IonList} from '@ionic/react';
import './styles.scss';
import {chevronDown} from 'ionicons/icons';
import {useClickOutside, useEffectOnce} from 'src/hooks';

interface IValue {
  label: string;
  value: string | number;
}

interface IOption extends IValue {
  selected: boolean;
  count: number;
}

interface IProps {
  selectedValues: Array<string | number>;
  values: Array<IValue>;
  onChange: (values: any) => void;
}

const OrderHistoryFilter = ({selectedValues, values, onChange}: IProps) => {
  const [options, setOptions] = useState<Array<IOption>>([]);
  const [opened, setOpened] = useState(false);
  const wrapperRef = useRef(null);

  useClickOutside(wrapperRef, () => {
    setOpened(false);
  });

  const onOptionClick = (filter: IOption) => {
    let updatedOptions = [];
    if (filter.value === 'all' && !filter.selected) {
      updatedOptions = options.map((option) => ({...option, selected: true}));
    } else if (filter.value === 'all' && filter.selected) {
      updatedOptions = options.map((option) => ({...option, selected: false}));
    } else {
      updatedOptions = options.map((option) => ({
        ...option,
        selected: filter.value === option.value ? !option.selected : option.selected,
      }));

      const selectedCount = updatedOptions.filter((option) => option.selected).length;
      if (selectedCount === updatedOptions.length - 1 && !updatedOptions[0].selected) {
        // If all options are selected, but the 'All' option is not selected.
        updatedOptions[0].selected = true;
      } else if (selectedCount === updatedOptions.length - 1 && updatedOptions[0].selected) {
        // If all options are not selected, but the 'All' option is selected.
        updatedOptions[0].selected = false;
      }
    }
    setOptions(updatedOptions);
    onChange(
      updatedOptions.filter((option) => option.selected && option.value !== 'all').map((option) => option.value),
    );
  };

  const getSelectedLabel = () => {
    const selectedOptions = options.filter((option) => option.selected);
    switch (selectedOptions.length) {
      case 0:
        return 'No Selection';
      case 1:
        return selectedOptions?.map((option) => option.label);
      case 2:
        return '2 selected';
      default:
        return 'All';
    }
  };

  useEffectOnce(() => {
    const updatedOptions = values.map((item) => ({
      ...item,
      selected: selectedValues.some((v) => v === item.value),
      count: 1111, // This will come from API
    }));
    updatedOptions.unshift({
      label: 'All',
      value: 'all',
      selected: true,
      count: updatedOptions?.map((item) => item.count)?.reduce((accumulator, current) => accumulator + current, 0),
    });
    setOptions(updatedOptions);
  });

  return (
    <div id="order-history-filter" ref={wrapperRef}>
      <div className={`filter-header ${opened ? 'opened' : ''}`} onClick={() => setOpened(!opened)}>
        <div className="filter-status">
          <IonLabel> {getSelectedLabel()} </IonLabel>
          {/* //for total count... it'll show when API ready. */}
          {/* {options.filter((option) => option.selected).length > 0 && (
            <IonNote>
              {options
                .filter((option) => option.selected && option.value !== 'all')
                ?.map((item) => item.count)
                ?.reduce((accumulator, current) => accumulator + current, 0)}
            </IonNote>
          )} */}
          <div className="badges">
            {options
              .filter((option) => option.selected && option.value !== 'all')
              .map((option, i) => (
                <IonIcon key={i} className={`${option.value}`} />
              ))}
          </div>
        </div>
        <IonIcon icon={chevronDown} slot="end" className={`chevron ${opened ? 'open' : 'close'}`} />
      </div>
      {opened && (
        <div className="filter-menu-list">
          {options.map((options, index) => (
            <IonList className="list-item" key={index} onClick={() => onOptionClick(options)}>
              <div className="list-item-content">
                <IonCheckbox checked={options.selected} />
                <IonLabel> {options.label}</IonLabel>
                {/* <IonNote>{options.count}</IonNote> */}
              </div>
              <IonIcon slot="end" className={`${options.value}`} />
            </IonList>
          ))}
        </div>
      )}
    </div>
  );
};

export default OrderHistoryFilter;
