import {useEffect, useState} from 'react';
import {
  IonPage,
  IonContent,
  IonLabel,
  IonGrid,
  IonRow,
  IonCol,
  IonIcon,
  IonSegment,
  IonSegmentButton,
  IonSpinner,
  IonBadge,
} from '@ionic/react';
import './styles.scss';
import {alertCircleOutline} from 'ionicons/icons';
import {RouteComponentProps} from 'react-router';
import PullToRefresh from 'react-simple-pull-to-refresh';
import CommonHeader from 'src/components/CommonHeader';
import ListItem from 'src/components/ListItem';
import OrderCard from 'src/components/OrderCard';
import OrderHistoryLink from 'src/pages/Orders/List/components/OrderHistoryLink';
// import StorePausedAlert from 'src/pages/Orders/components/StorePausedAlert';
import NoItem from 'src/components/NoItem';
import OrderDetails from 'src/pages/Orders/Details';
import {ORDER_STATUS} from 'src/enums/order';
import {ROUTE_LOCATION} from 'src/enums/route_location';
import {useAppDispatch, useAppSelector} from 'src/hooks';
import {
  getOrdersCount,
  getActiveOrders,
  getCompletedOrders,
  setIncomingOrder,
  activeOrderIndex,
  refreshCompletedOrders,
  setCompletedOrders,
  refreshActiveOrders,
  setActiveOrders,
  setAcceptedOrderId,
} from 'src/store/orderSlice';
import {setMenuAvailable} from 'src/store/authSlice';
import {setShowNewOrderModal} from 'src/store/storeSlice';
import AnalyticsHelper from 'src/utils/segment';

interface IProps extends RouteComponentProps {}

const OrdersList = ({history, location, match}: IProps) => {
  const dispatch = useAppDispatch();

  const {isDesktop, isTablet, isMobile} = useAppSelector((state) => state.platform);
  const {
    loading,
    activeOrders,
    completedOrders,
    loadMoreActiveOrders,
    loadMoreCompletedOrders,
    activeOrdersCount,
    completedOrdersCount,
    acceptedOrderId,
  } = useAppSelector((state) => state.order);
  const {menuIsOpen, user} = useAppSelector((state) => state.auth);
  const store = useAppSelector((state) => state.store);

  const [selectedTab, setSelectedTab] = useState('active');
  const [orderBagTime, setOrderBagTime] = useState(5);
  const [showOrderDetails, setShowOrderDetails] = useState(false);
  const [selectedOrderId, setSelectedOrderId] = useState('');

  const onTabChange = (e: any) => {
    setSelectedTab(e.detail.value);
    if (isDesktop) {
      setShowOrderDetails(false);
      dispatch(activeOrderIndex(null));
    }
    dispatch(getOrdersCount());

    if (e.detail.value === 'active' && activeOrders?.length === 0) dispatch(getActiveOrders());
    if (e.detail.value === 'completed' && completedOrders?.length === 0) dispatch(getCompletedOrders());
  };

  const orderClick = (id: string) => {
    const a = activeOrders.find((item) => item.id === id);
    if (a?.status === ORDER_STATUS.INCOMING) {
      dispatch(setShowNewOrderModal(true));
      dispatch(setIncomingOrder(a));
    } else {
      AnalyticsHelper.trackOrderDetailsClick(id);
      if (isDesktop) {
        setSelectedOrderId(id);
        setShowOrderDetails(true);

        dispatch(activeOrderIndex(id));
      } else {
        history.push({
          pathname: '/orders/details',
          state: {id, bagTime: orderBagTime, from: ROUTE_LOCATION.ORDER_LIST},
        });
      }
    }
  };

  const fetchNextOrders = async (e: any) => {
    if (ORDERS && ORDERS.length >= 20) {
      const {scrollHeight, scrollTop, clientHeight} = e.target;
      const loadMore = scrollHeight - scrollTop <= clientHeight + 10;
      if (loadMore && !loading) {
        if (loadMoreActiveOrders && selectedTab === 'active') dispatch(getActiveOrders());
        if (loadMoreCompletedOrders && selectedTab === 'completed') dispatch(getCompletedOrders());
      }
    }
  };

  const onRefresh = async () => {
    dispatch(getOrdersCount());

    if (selectedTab === 'active') {
      dispatch(refreshActiveOrders());
      await dispatch(getActiveOrders())
        .unwrap()
        .then((orders) => {
          if (orders.items.length) dispatch(setActiveOrders(orders.items));
        });
    }
    if (selectedTab === 'completed') {
      dispatch(refreshCompletedOrders());
      await dispatch(getCompletedOrders())
        .unwrap()
        .then((orders: any) => {
          if (orders?.length) dispatch(setCompletedOrders(orders));
        });
    }
  };

  useEffect(() => {
    dispatch(getOrdersCount());
    dispatch(getActiveOrders());
  }, [store?.id]);

  useEffect(() => {
    dispatch(setMenuAvailable(true));

    return () => {
      setSelectedTab('active');
      dispatch(setShowNewOrderModal(false));
      setOrderBagTime(5);
    };
  }, []);

  useEffect(() => {
    if (acceptedOrderId) {
      setSelectedOrderId(acceptedOrderId);
      setShowOrderDetails(true);
      dispatch(activeOrderIndex(acceptedOrderId));
    }
    setAcceptedOrderId('');
  }, [acceptedOrderId]);

  const ORDERS = selectedTab === 'active' ? activeOrders : selectedTab === 'completed' ? completedOrders : [];

  return (
    <IonPage id="orders-list-page" className="snow-background">
      <IonGrid>
        <IonRow>
          <IonCol sizeXl="8" className={`${isTablet && !menuIsOpen ? 'menu-close' : ''}`}>
            <IonContent fullscreen scrollY={false} className="page-container">
              <CommonHeader className="title-left" title="Orders" noBorder menuIsOpen={menuIsOpen} />

              <IonSegment mode="md" value={selectedTab} onIonChange={onTabChange}>
                <IonSegmentButton value="active">
                  <IonLabel>
                    Active <IonBadge>{activeOrdersCount}</IonBadge>
                  </IonLabel>
                </IonSegmentButton>
                <IonSegmentButton value="completed">
                  <IonLabel>
                    Completed <IonBadge>{completedOrdersCount}</IonBadge>
                  </IonLabel>
                </IonSegmentButton>
              </IonSegment>

              <PullToRefresh
                isPullable={!isDesktop}
                onRefresh={onRefresh}
                pullingContent=""
                refreshingContent={<IonSpinner className="pull-to-refresh" />}>
                <div className="body" onScroll={fetchNextOrders}>
                  {store.isDeactivated ? (
                    <div className="alert">
                      <div className="header">
                        <IonIcon icon={alertCircleOutline} />
                        <p>Attention!</p>
                      </div>
                      <ListItem
                        icon="icon-store"
                        title="Store Deactivated"
                        description="Your store has been temporarily deactivated due to missing too many orders. Please contact the Lula Support team to get help reactivating your store. (267) 263-7197"
                      />
                    </div>
                  ) : (
                    ORDERS &&
                    ORDERS.length > 0 &&
                    isMobile && (
                      <>
                        <h2 className="title mt-0">Hello there, {user?.firstName}!</h2>
                        <p className="desc">Today is going to be a great day!</p>
                      </>
                    )
                  )}

                  {/* {!isDesktop && <StorePausedAlert />} */}
                  {ORDERS && ORDERS.length > 0 ? (
                    selectedTab === 'active' ? (
                      <>
                        {ORDERS.filter((order) => !order.ongoing).length > 0 && <p className="heading">Pending</p>}
                        {ORDERS.slice()
                          .filter((order) => !order.ongoing)
                          .map((order, i) => (
                            <OrderCard
                              key={i}
                              order={order}
                              onClick={() => orderClick(order?.id)}
                              bagTime={order?.bagTime}
                              selected={order?.activeIndex}
                            />
                          ))}
                        {ORDERS.filter((order) => order.ongoing).length > 0 && <p className="heading">Ongoing</p>}
                        {ORDERS.filter((order) => order.ongoing).map((order, i) => (
                          <OrderCard
                            key={i}
                            order={order}
                            onClick={() => orderClick(order?.id)}
                            selected={order?.activeIndex}
                          />
                        ))}
                      </>
                    ) : (
                      <>
                        {ORDERS.map((order, i) => (
                          <OrderCard
                            key={i}
                            order={order}
                            onClick={() => orderClick(order?.id)}
                            selected={order?.activeIndex}
                          />
                        ))}
                        <OrderHistoryLink noOrders={false} />
                      </>
                    )
                  ) : (
                    !store.isDeactivated &&
                    (selectedTab === 'active' ? (
                      <NoItem message="There is not any order here yet!" />
                    ) : (
                      <OrderHistoryLink noOrders />
                    ))
                  )}

                  {loading && <IonSpinner color="primary" />}
                </div>
              </PullToRefresh>
            </IonContent>
          </IonCol>
          {isDesktop && (
            <IonCol sizeXl="4">
              {showOrderDetails && (
                <OrderDetails
                  location={{state: {id: selectedOrderId, from: ROUTE_LOCATION.ORDER_LIST}}}
                  history={history}
                  match={match}
                  setShowDetails={setShowOrderDetails}
                />
              )}
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
    </IonPage>
  );
};

export default OrdersList;
