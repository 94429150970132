import {useEffect, useState} from 'react';
import {useLocation} from 'react-router';
import {RouteComponentProps} from 'react-router';
import {IonPage, IonContent, IonLabel, IonGrid, IonRow, IonCol} from '@ionic/react';
import './styles.scss';
import {getStoreStatus} from 'src/store/storeSlice';
import {useAppDispatch, useAppSelector} from 'src/hooks';
import {showAlert} from 'src/store/alertSlice';
import {hideLoading, showLoading} from 'src/store/loadingSlice';
import {PARTNER} from 'src/enums/partner';
import {IPartnerItem} from 'src/interfaces/store';
import CommonHeader from 'src/components/CommonHeader';
import NoItem from 'src/components/NoItem';
import ListItem from 'src/components/ListItem';
import PartnerDetails from 'src/pages/Partners/Details';
import StoreHeader from 'src/components/StoreHeader';
import doorDash from 'src/assets/images/doordash.svg';
import uberEats from 'src/assets/images/ubereats.svg';
import grubHub from 'src/assets/images/grubhub.svg';
import SchedulePause from 'src/pages/Partners/Schedule';
import StoreStatuses from 'src/components/StoreStatuses';
import {useFeatureFlagEnabled} from 'posthog-js/react';

interface IProps extends RouteComponentProps {}

const Partners = ({history, match}: IProps) => {
  const dispatch = useAppDispatch();
  const location: any = useLocation();
  const store = useAppSelector((state) => state.store);
  const {menuIsOpen} = useAppSelector((state) => state.auth);
  const {isDesktop, isTablet} = useAppSelector((state) => state.platform);
  const {partners, isAllPartnerOpen, isAllPartnerOffline, isAllPartnerPaused, isSomePartnerPaused} = useAppSelector(
    (state) => state.store.storeStatus,
  );
  const storePauseEnabled = useFeatureFlagEnabled('storefront_pause');
  const [showPartnerDetails, setShowPartnerDetails] = useState(false);
  const [showSchedulePause, setShowSchedulePause] = useState(false);
  const [partnerDetails, setPartnerDetails] = useState<IPartnerItem>({
    icon: '',
    title: '',
  });

  const partnerList = [
    ...partners.grubHub.map((item: IPartnerItem) => ({
      ...item,
      icon: grubHub,
      title: PARTNER.GRUB_HUB,
    })),
    ...partners.doorDash.map((item: IPartnerItem) => ({
      ...item,
      icon: doorDash,
      title: PARTNER.DOOR_DASH,
    })),
    ...partners.uberEats.map((item: IPartnerItem) => ({
      ...item,
      icon: uberEats,
      title: PARTNER.UBER_EATS,
    })),
  ];

  const partnerDetailsClick = (partnerItem: IPartnerItem) => {
    if (isDesktop) {
      setPartnerDetails(partnerItem);
      setShowPartnerDetails(true);
    }
  };

  const getPartners = async () => {
    try {
      dispatch(showLoading());
      await dispatch(getStoreStatus());
      dispatch(hideLoading());
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(
        showAlert({
          heading: 'Error',
          message: error?.message || 'Some error occurred while fetching data. Try again later.',
        }),
      );
    }
  };

  useEffect(() => {
    getPartners();
    setPartnerDetails({icon: '', title: ''});
    setShowPartnerDetails(false);
  }, [store?.id]);

  const getActivityStatus = (partnerItem: IPartnerItem) => {
    if (partnerItem.isPaused) return 'paused';
    else if (partnerItem.isOffline) return 'offline';
    else return '';
  };

  useEffect(() => {
    if (location?.state?.showSchedulePause) {
      setShowSchedulePause(true);
      setShowPartnerDetails(false);
      setPartnerDetails({icon: '', title: ''});
    }
  }, [location?.state?.showSchedulePause]);

  return (
    <IonPage id="partners-manage-page" className="snow-background">
      <IonGrid>
        <IonRow>
          <IonCol sizeXl="8" className={`${isTablet && !menuIsOpen ? 'menu-close' : ''}`}>
            <IonContent fullscreen scrollY={false} className="page-container">
              <CommonHeader className="title-left" title="Partners" menuIsOpen={menuIsOpen} />
              <StoreHeader store={store} />

              <div className="body">
                {!isDesktop && storePauseEnabled && (
                  <StoreStatuses
                    isAllPartnerOpen={isAllPartnerOpen}
                    isAllPartnerOffline={isAllPartnerOffline}
                    isAllPartnerPaused={isAllPartnerPaused}
                    isSomePartnerPaused={isSomePartnerPaused}
                    isClosed={store.isClosed}
                  />
                )}

                <div>
                  {partnerList?.length > 0 && <p className="heading">Partners</p>}
                  {partnerList?.length > 0 &&
                    partnerList?.map((partnerItem, i) => (
                      <ListItem
                        key={i}
                        fullWidth
                        iconImg={partnerItem.icon}
                        title={partnerItem.title}
                        activityStatus={getActivityStatus(partnerItem)}
                        selected={partnerItem.title === partnerDetails?.title}
                        onClick={() => {
                          if (isDesktop) {
                            history.replace({pathname: '/partners', state: {showSchedulePause: false}});
                            setShowSchedulePause(false);
                            partnerDetailsClick({
                              icon: partnerItem.icon,
                              title: partnerItem.title,
                            });
                          } else {
                            history.replace({
                              pathname: '/partners/details',
                              state: {
                                iconImg: partnerItem.icon,
                                partnerName: partnerItem.title,
                              },
                            });
                          }
                        }}
                      />
                    ))}
                  {partnerList?.length === 0 && <NoItem message="No partner found!" />}
                </div>
                <IonLabel>
                  To turn a specific partner on or off please reach out to Lula Support: (267) 263-7197
                </IonLabel>
              </div>
            </IonContent>
          </IonCol>

          {isDesktop && (
            <IonCol sizeXl="4">
              {showPartnerDetails && (
                <PartnerDetails
                  history={history}
                  match={match}
                  location={{
                    state: {
                      iconImg: partnerDetails?.icon,
                      partnerName: partnerDetails?.title,
                    },
                  }}
                  setShowPartnerDetails={setShowPartnerDetails}
                  setPartnerDetails={setPartnerDetails}
                />
              )}
              {showSchedulePause && <SchedulePause setShowSchedulePause={setShowSchedulePause} />}
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
    </IonPage>
  );
};

export default Partners;
