import React from 'react';
import './styles.scss';

interface IProps {
  text: string;
  background?: string;
}

const TextWithLines = ({text, background}: IProps) => {
  return (
    <p id="text-with-lines">
      <span style={background ? {background: background} : {}}>{text}</span>
    </p>
  );
};

export default TextWithLines;
