import {IonCheckbox} from '@ionic/react';
import './styles.scss';
import ItemImage from 'src/components/ItemImage';
import {useAppDispatch, useAppSelector, useEffectOnce} from 'src/hooks';
import {isElementIncludes, isItemIncludes, isItemSynced} from 'src/utils/helpers';
import {setSelectedItem, removeSelectedItem, setIsSortChange} from 'src/store/inventorySlice';
import {IItem} from 'src/interfaces/item';

interface IProps {
  item: any;
  isEditable?: boolean;
  selected?: boolean;
  canEdit?: boolean;
  onClick: (e: any) => void;
}

const InventoryItem = ({item, selected, onClick, isEditable, canEdit}: IProps) => {
  const dispatch = useAppDispatch();

  const {isDesktop} = useAppSelector((state) => state.platform);
  const {partners} = useAppSelector((state) => state?.store.storeStatus);
  const {selectedItems, selectedCategories, exceptItems, isSortChange} = useAppSelector((state) => state.inventory);

  const inSelectedCategories = isElementIncludes(selectedCategories, item.category?.id);
  const inExceptItems = isItemIncludes(exceptItems, item.storeItemId);
  const inSelectedItems = isItemIncludes(selectedItems, item.storeItemId);

  const selectItem = () => {
    if (!((inSelectedCategories && !inExceptItems) || (!inSelectedCategories && inSelectedItems))) {
      dispatch(setSelectedItem(item));
    } else {
      dispatch(removeSelectedItem(item));
    }
  };

  const getDetails = (item: IItem) => {
    if (item.size && item.size !== 'NA') {
      return item.unitCount ? `${item.size} - ${item.unitCount}` : `${item.size}`;
    } else {
      return item.description && item.description !== 'NA'
        ? `${item.description}`
        : `${item.unitCount ? item.unitCount : ''}`;
    }
  };

  useEffectOnce(() => {
    dispatch(setIsSortChange(true));
  });

  return (
    <div id="inventory-item" className={selected ? 'select' : ''}>
      <div className="container">
        {(isEditable || isDesktop) && (
          <div className="check">
            <IonCheckbox
              className="select-check"
              disabled={!canEdit}
              checked={(inSelectedCategories && !inExceptItems) || (!inSelectedCategories && inSelectedItems)}
              onIonChange={(event) => {
                if (isSortChange) {
                  if (!((inSelectedCategories && !inExceptItems) || (!inSelectedCategories && inSelectedItems))) {
                    dispatch(setSelectedItem(item));
                  } else {
                    dispatch(removeSelectedItem(item));
                  }
                }
              }}
            />
          </div>
        )}

        <ItemImage category={item.category?.name} image={item.image} />

        <div className="details" onClick={!isDesktop && isEditable ? selectItem : (e) => onClick(e)}>
          <div className="price-quantity-container">
            <p className="item-name">
              {item.name ? item.name : <span className="item-placeholder">No item name</span>}
            </p>
            <div className="item-price">
              <span className={Number(item.price) === 0 ? 'item-placeholder' : ''}>
                ${Number(item.price).toFixed(2)}
              </span>
            </div>
          </div>

          <div className="description-container">{getDetails(item)}</div>

          <div className="product-tag-container">
            {!isItemSynced(partners, item) && (
              <div className="item-tag pending-tag">
                <span>Pending</span>
              </div>
            )}

            {item.isRestricted && (
              <div className="item-tag restricted-tag">
                <span>Restricted</span>
              </div>
            )}

            {!item.active && (
              <div className="item-tag out-of-stock-tag">
                <span>Out of Stock</span>
              </div>
            )}

            {!item.completed && (
              <div className="item-tag incomplete-tag">
                <span>Incomplete</span>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default InventoryItem;
