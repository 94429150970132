import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {IPrinter} from 'src/interfaces/printer';
import {PrinterService} from 'src/services/printer';
import {setItem} from 'src/utils/storage';

interface IState {
  printer: IPrinter;
}

export const getPrinterEmulation = createAsyncThunk(
  'printer/printer_emulation',
  async (_, {dispatch, fulfillWithValue, rejectWithValue}: any) => {
    try {
      const emulation = await PrinterService.getInstance().getEmulation();
      return fulfillWithValue({emulation});
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getPrinterModel = createAsyncThunk(
  'printer/printer_model',
  async (_, {dispatch, fulfillWithValue, rejectWithValue}: any) => {
    try {
      const model = await PrinterService.getInstance().getModel();
      return fulfillWithValue({model});
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getPrinterPort = createAsyncThunk(
  'printer/printer_port',
  async (_, {dispatch, fulfillWithValue, rejectWithValue}: any) => {
    try {
      const port = await PrinterService.getInstance().getPort();
      return fulfillWithValue({port});
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

export const getShouldPrintOrder = createAsyncThunk(
  'printer/should_print_order',
  async (_, {dispatch, fulfillWithValue, rejectWithValue}: any) => {
    try {
      const shouldPrintOrder = await PrinterService.getInstance().getShouldPrintOrder();
      return fulfillWithValue({shouldPrintOrder});
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);
export const getNearByPrinters = createAsyncThunk(
  'printer/get_nearby_printers',
  async (_, {dispatch, fulfillWithValue, rejectWithValue}: any) => {
    try {
      const printers = await PrinterService.getInstance().getNearByPrinters();
      return fulfillWithValue({printers});
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);
export const checkWasConnected = createAsyncThunk(
  'printer/check_was_connected',
  async (_, {dispatch, fulfillWithValue, rejectWithValue}: any) => {
    try {
      const wasConnected = await PrinterService.getInstance().checkWasConnected();
      return fulfillWithValue({wasConnected});
    } catch (err: any) {
      return rejectWithValue(err);
    }
  },
);

const initialState: IState = {
  printer: {
    connectionStatus: false,
    shouldPrintOrder: false,
    wasConnected: false,
    emulation: '',
    model: '',
    hasBluetoothPermission: false,
    printerStatus: {offline: true, coverOpen: false, ModelName: '', receiptPaperEmpty: false},
    modelName: '',
    portName: '',
    macAddress: '',
    statusEvents: {
      printerOnline: false,
      printerOffline: false,
      printerImpossible: true,
      printerPaperEmpty: false,
      printerPaperNearEmpty: false,
      printerPaperReady: true,
      printerCoverOpen: false,
      printerCoverClose: true,
      cashDrawerOpen: false,
      cashDrawerClose: true,
    },
  },
};
export enum PRINTER_ENUM {
  wasConnected= 'isVirginInPrinting',
  shouldPrintOrder = 'shouldPrintOrder',
  printerOffline = 'printerOffline',
  printerImpossible = 'printerImpossible',
}

const printerSlice = createSlice({
  name: 'printer',
  initialState,
  reducers: {
    setPrinter: (state, action) => {
      const {modelName, macAddress, portName} = action.payload;
      state.printer.modelName = modelName;
      state.printer.macAddress = macAddress;
      state.printer.portName = portName;
    },
    setPrinterModel: (state, action) => {
      state.printer.model = action.payload;
    },
    setPrinterEmulation: (state, action) => {
      state.printer.emulation = action.payload;
    },
    setShouldPrintOrder: (state, action) => {
      setItem(PRINTER_ENUM.shouldPrintOrder, action.payload ? 'true' : 'false');
      state.printer.shouldPrintOrder = action.payload;
    },
    setWasConnected: (state, action) => {
      setItem(PRINTER_ENUM.wasConnected, action.payload ? 'true' : 'false');
      state.printer.wasConnected= action.payload;
    },
    setHasBluetoothPermission: (state, action) => {
      state.printer.hasBluetoothPermission = action.payload;
    },
    setStatusEvents: (state, action) => {
      const events = {...state.printer.statusEvents};
      switch (action.payload) {
        case 'printerImpossible':
          events['printerImpossible'] = true;
          break;
        case 'printerOffline':
          events['printerOnline'] = false;
          events['printerOffline'] = true;
          break;
        case 'printerOnline':
          events['printerOnline'] = true;
          events['printerOffline'] = false;
          events['printerImpossible'] = false;
          break;
        case 'printerPaperEmpty':
          events['printerPaperEmpty'] = true;
          events['printerPaperNearEmpty'] = false;
          events['printerPaperReady'] = false;
          break;
        case 'printerPaperNearEmpty':
          events['printerPaperEmpty'] = false;
          events['printerPaperNearEmpty'] = true;
          break;
        case 'printerPaperReady':
          events['printerPaperEmpty'] = false;
          events['printerPaperReady'] = true;
          break;
        case 'printerCoverOpen':
          events['printerCoverOpen'] = true;
          events['printerCoverClose'] = false;
          break;
        case 'printerCoverClose':
          events['printerCoverClose'] = true;
          events['printerCoverOpen'] = false;
          break;
        case 'cashDrawerOpen':
          events['cashDrawerOpen'] = true;
          events['cashDrawerClose'] = false;
          break;
        case 'cashDrawerClose':
          events['cashDrawerClose'] = true;
          events['cashDrawerOpen'] = false;
          break;
        default:
          break;
      }
      state.printer.statusEvents = events;
      state.printer.connectionStatus =
        !events['printerImpossible'] &&
        !events['printerOffline'] &&
        !events['printerPaperEmpty'] &&
        !events['printerCoverOpen'] &&
        !events['cashDrawerOpen'] &&
        events['printerOnline'] &&
        events['printerPaperReady'] &&
        events['printerCoverClose'] &&
        events['cashDrawerClose'];

      if (action.payload.connectionStatus && !state.printer.wasConnected) {
        setItem(PRINTER_ENUM.wasConnected, 'true');
        state.printer.wasConnected = true;
      }
      },   
  },
  extraReducers: (builder) => {
    builder.addCase(getShouldPrintOrder.fulfilled, (state, action) => {
      state.printer.shouldPrintOrder = action.payload.shouldPrintOrder;
    });
    builder.addCase(checkWasConnected.fulfilled, (state, action) => {
      state.printer.wasConnected = action.payload.wasConnected;
    });
    builder.addCase(getPrinterEmulation.fulfilled, (state, action) => {
      state.printer.emulation = action.payload.emulation;
    });
    builder.addCase(getPrinterModel.fulfilled, (state, action) => {
      state.printer.model = action.payload.model;
    });
    builder.addCase(getPrinterPort.fulfilled, (state, action) => {
      state.printer.portName = action.payload.port;
    });
  },
});

export const {
  setShouldPrintOrder,
  setWasConnected,
  setPrinterEmulation,
  setPrinterModel,
  setHasBluetoothPermission,
  setPrinter,
  setStatusEvents,
} = printerSlice.actions;

export default printerSlice.reducer;
