import {
  IonPage,
  IonContent,
  IonButton,
  IonIcon,
  IonItem,
  IonInput,
  IonSpinner,
  IonMenuToggle,
  IonCheckbox,
} from '@ionic/react';
import PullToRefresh from 'react-simple-pull-to-refresh';
import {searchOutline} from 'ionicons/icons';
import './styles.scss';
import menu from 'src/assets/images/menu.svg';
import {ICategory, ICategoryCount, IInventoryStats, IItem, IItemWithFirstSort, IQueryObject} from 'src/interfaces/item';
import {ITEM_STATUS} from 'src/enums/item_status';
import Header from 'src/components/Header';
import InventoryItem from 'src/components/InventoryItem';
import NoItem from 'src/components/NoItem';
import FAB from '../components/FAB';
import {createItemArrayWithDivider, isElementIncludes, getTotalSelectItemCount, toggleMenu} from 'src/utils/helpers';
import checkBoxIcon from 'src/assets/icons/checkBoxIcon.svg';
import InventoryToggle from '../components/InventoryToggle';

import Filter from 'src/assets/icons/filter.svg';
import Sort from 'src/assets/icons/sort.svg';
import InventorySortRow from '../components/InventorySortRow';
import {QUERY_OBJECT} from 'src/enums/query_object';
import ItemEditOption from 'src/components/ItemEditOption';
import EditOptionModal from '../components/Editoptions';
import AvailabilityOptionModal from 'src/pages/Inventory/List/components/AvailabilityOptions';
import {setSelectedCategories, removeSelectedCategories, setIsEditable} from 'src/store/inventorySlice';
import {useAppDispatch, useAppSelector} from 'src/hooks';
import CategoryOptionsModal from '../components/CategoryOptions';
import PriceOptionModal from '../components/PriceOptions/index';
import MenuOptionModal from '../components/MenuOptions/index';
import {IMenuList} from 'src/interfaces/store';
import NewMenuOptionModal from '../components/NewMenuOptions/index';
import NotificationBar from '../components/NotificationBar';

interface IProps {
  inventoryStats: IInventoryStats;
  queryObject: IQueryObject;
  menuIsOpen: boolean;
  loading: boolean;
  showSearch: boolean;
  title: string;
  items: Array<IItem> | undefined;
  searchedItems: Array<IItem> | undefined;
  categories: Array<ICategory>;
  categoryCount: Array<ICategoryCount>;
  searchTerm: string;
  isFiltered?: boolean;
  showEditOptionModal: boolean;
  showDeleteModal: boolean;
  showAvailabilityModal: boolean;
  showPriceModal: boolean;
  onTitleChange: (title: ITEM_STATUS.IN_STOCK | ITEM_STATUS.ARCHIVED) => void;
  onRefresh: () => Promise<any>;
  openScanner: () => void;
  goToCommonItems: () => void;
  openHelp: () => void;
  onInputChange: (e: any) => void;
  setShowSearch: (show: boolean) => void;
  onSegmentChange: (e: any) => void;
  fetchNextItems: (e: any) => void;
  goToDetails: (item: IItem, isNewItem?: boolean) => void;
  goToSorting: () => void;
  goToFiltering: () => void;
  setShowEditOptionModal: (e: boolean) => void;
  setShowDeleteModal: (e: boolean) => void;
  setShowAvailabilityModal: (e: boolean) => void;
  setShowPriceModal: (e: boolean) => void;
  patchInventory: (data: any, is_archive: boolean, active: boolean) => void;
  showCategoryModal: boolean;
  setShowCategoryModal: (e: boolean) => void;
  validatePriceInput: (data: any, active: boolean) => void;
  deleteInventory: (active: boolean) => void;
  showMenuModal: boolean;
  menuList: Array<IMenuList>;
  setShowMenuModal: (e: boolean) => void;
  menuBulkItem: (data: Array<string>, active: boolean, addToMenu: boolean) => void;
  showNewMenuModal: boolean;
  setShowNewMenuModal: (e: boolean) => void;
  showBulkIncompleteDetails: boolean;
  setShowBulkIncompleteDetails: (e: boolean) => void;
  updateBulkIncompleteDetails: () => void;
  updateBulkOutOfStockDetails?: () => void;
  canEdit: boolean;
  canDelete: boolean;
  canCreate: boolean;
}

const TabScreen = (props: IProps) => {
  const itemArrayWithDivider: IItemWithFirstSort[] = createItemArrayWithDivider(
    props.queryObject,
    !!props.searchTerm ? (!!props.searchedItems ? props.searchedItems : []) : !!props.items ? props.items : [],
  );
  const dispatch = useAppDispatch();
  const {selectedItems, exceptItems, selectedCategories, inventoryCount, isEditable} = useAppSelector(
    (state) => state.inventory,
  );

  return (
    <IonPage id="inventory-list-tablet" className={` ${!props.menuIsOpen ? 'menu-close' : ''} snow-background`}>
      <IonContent fullscreen scrollY={false} className="page-container">
        <Header
          noBackground
          title="Inventory"
          leftButton={
            <IonMenuToggle menu="menu" autoHide={false} onClick={toggleMenu}>
              <IonIcon icon={menu} />
            </IonMenuToggle>
          }
          rightButton={
            <IonButton fill="clear" strong onClick={() => props.setShowSearch(!props.showSearch)}>
              <IonIcon icon={searchOutline} />
            </IonButton>
          }
        />

        {props.showSearch && (
          <IonItem className="search-bar">
            <IonIcon className="search-icon" icon={searchOutline} />
            <IonInput value={props.searchTerm} placeholder="Search Inventory" onIonChange={props.onInputChange} />
          </IonItem>
        )}

        <div style={{padding: '0 24px'}}>
          <InventoryToggle title={props.title} inventoryCount={inventoryCount} onTitleChange={props.onTitleChange} />
        </div>
        <div className="manage-container">
          <div className="manage-content">
            {props.queryObject.order === QUERY_OBJECT.CATEGORY && props.canEdit ? (
              <div
                className="icon-container-center"
                onClick={() => {
                  dispatch(setIsEditable(!isEditable));
                }}>
                <IonIcon className="icon" icon={checkBoxIcon} />
                <span>{isEditable ? 'Editing' : 'Edit items'}</span>
              </div>
            ) : (
              <div className="icon-container-center" />
            )}

            <div className="icon-group">
              <div className="icon-container">
                <IonIcon className="icon" icon={Sort} slot="end" onClick={props.goToSorting} />
              </div>

              <div className="icon-container">
                <IonIcon className="icon" icon={Filter} slot="end" onClick={props.goToFiltering} />
                {props.isFiltered ? <span className="badge"></span> : <></>}
              </div>
            </div>
          </div>
        </div>

        <PullToRefresh
          onRefresh={props.onRefresh}
          pullingContent=""
          backgroundColor="var(--custom-color-snow)"
          refreshingContent={<IonSpinner className="pull-to-refresh" />}>
          <div
            className={`body ${props.showSearch ? 'search' : ''}`}
            onScroll={(e: any) => {
              props.fetchNextItems(e);
            }}>
            <div className="notification-alert-container">
              {Number(inventoryCount.out_of_stock) !== 0 && (
                <NotificationBar
                  className="out-of-stock-notification"
                  notificationIconClass="icon-stop-block"
                  notificationMessage={`${inventoryCount.out_of_stock} items are Out of stock`}
                  actionButton="Update items"
                  onClick={props.updateBulkOutOfStockDetails}
                />
              )}

              {Number(inventoryCount.incomplete) !== 0 && (
                <NotificationBar
                  className="incomplete-details-notification"
                  notificationIconClass="icon-warning-alert"
                  notificationMessage={`${inventoryCount.incomplete} items have Incomplete details`}
                  actionButton="Complete items"
                  onClick={props.updateBulkIncompleteDetails}
                />
              )}
            </div>
            {props.items?.length && !props.searchTerm
              ? itemArrayWithDivider.map((item: any, i) => (
                  <div key={i}>
                    {item.firstOfSort && props.queryObject.order === QUERY_OBJECT.CATEGORY && (
                      <div className="label">
                        {isEditable && (
                          <div className="check">
                            <IonCheckbox
                              className="select-check"
                              disabled={!props.canDelete && !props.canEdit}
                              checked={
                                isElementIncludes(selectedCategories, item.category?.id) &&
                                !exceptItems.some((x) => x.category?.id === item.category?.id)
                              }
                              onClick={(event) => event.stopPropagation()}
                              onIonChange={(e) => {
                                if (e.detail.checked) {
                                  dispatch(setSelectedCategories(item));
                                } else {
                                  dispatch(removeSelectedCategories(item));
                                }
                              }}
                            />
                          </div>
                        )}
                        <InventorySortRow
                          rowName={item.category?.name}
                          count={
                            props.categoryCount.find((categoryCount) => categoryCount.id === item.category?.id)
                              ?.category_count
                          }
                        />
                      </div>
                    )}
                    {item.firstOfSort && props.queryObject.order === QUERY_OBJECT.NAME && (
                      <InventorySortRow rowName={item.name.charAt(0)} />
                    )}
                    {item.firstOfSort && props.queryObject.order === QUERY_OBJECT.DATE && (
                      <InventorySortRow rowName={`Sorted by created date ${props.queryObject.sortDir}`} />
                    )}
                    {item.firstOfSort && props.queryObject.order === QUERY_OBJECT.PRICE && (
                      <InventorySortRow rowName={`Sorted by price ${props.queryObject.sortDir}`} />
                    )}
                    <InventoryItem
                      isEditable={isEditable}
                      item={item}
                      canEdit={props.canEdit || props.canDelete}
                      onClick={(e: any) => {
                        if (e.target.role !== 'checkbox') props.goToDetails(item);
                      }}
                    />
                  </div>
                ))
              : itemArrayWithDivider?.map((item: any, i) => (
                  <InventoryItem
                    isEditable={false}
                    key={i}
                    item={item}
                    canEdit={props.canEdit || props.canDelete}
                    onClick={(e: any) => {
                      if (e.target.role !== 'checkbox') props.goToDetails(item);
                    }}
                  />
                ))}
            {!itemArrayWithDivider?.length && !props.loading && <NoItem message="No item found!" />}
            {props.loading && <IonSpinner color="primary" />}
          </div>
        </PullToRefresh>

        {isEditable && (
          <div className="edit-modal">
            <ItemEditOption
              setIsEditable={setIsEditable}
              selectedItemsLength={getTotalSelectItemCount(
                selectedCategories,
                selectedItems,
                exceptItems,
                props.categoryCount,
              )}
              canEdit={props.canEdit}
              setShowEditModal={props.setShowEditOptionModal}
              setShowAvailabilityModal={props.setShowAvailabilityModal}
              setShowCategoryModal={props.setShowCategoryModal}
              setShowPriceModal={props.setShowPriceModal}
              setShowDeleteModal={props.setShowDeleteModal}
              setShowMenuModal={props.setShowMenuModal}
            />
          </div>
        )}

        {props.showEditOptionModal && (
          <EditOptionModal
            setShowEditModal={props.setShowEditOptionModal}
            setShowAvailabilityModal={props.setShowAvailabilityModal}
            setShowCategoryModal={props.setShowCategoryModal}
            setShowPriceModal={props.setShowPriceModal}
            deleteInventory={props.deleteInventory}
            setShowMenuModal={props.setShowMenuModal}
            canDelete={props.canDelete}
            isInStock={props.title === ITEM_STATUS.IN_STOCK}
          />
        )}
        {props.showAvailabilityModal && (
          <AvailabilityOptionModal
            setShowEditModal={props.setShowEditOptionModal}
            setShowAvailabilityModal={props.setShowAvailabilityModal}
            patchInventory={props.patchInventory}
            isInStock={props.title === ITEM_STATUS.IN_STOCK}
          />
        )}
        {props.showCategoryModal && (
          <CategoryOptionsModal
            setShowEditModal={props.setShowEditOptionModal}
            setShowCategoryModal={props.setShowCategoryModal}
            patchInventory={props.patchInventory}
            isInStock={props.title === ITEM_STATUS.IN_STOCK}
          />
        )}
        {props.showPriceModal && (
          <PriceOptionModal
            setShowEditModal={props.setShowEditOptionModal}
            setShowPriceModal={props.setShowPriceModal}
            patchInventory={props.patchInventory}
            isInStock={props.title === ITEM_STATUS.IN_STOCK}
            validatePriceInput={props.validatePriceInput}
          />
        )}
        {props.showMenuModal && (
          <MenuOptionModal
            setShowEditModal={props.setShowEditOptionModal}
            setShowMenuModal={props.setShowMenuModal}
            setShowNewMenuModal={props.setShowNewMenuModal}
            menuList={props.menuList}
            menuBulkItem={props.menuBulkItem}
            isInStock={props.title === ITEM_STATUS.IN_STOCK}
          />
        )}
        {props.showNewMenuModal && (
          <NewMenuOptionModal
            setShowEditModal={props.setShowEditOptionModal}
            setShowMenuModal={props.setShowMenuModal}
            showNewMenuModal={props.showNewMenuModal}
            setShowNewMenuModal={props.setShowNewMenuModal}
          />
        )}
        {!isEditable && props.canCreate && (
          <FAB
            onHelp={props.openHelp}
            onBrowseItems={props.goToCommonItems}
            onScanBarcode={props.openScanner}
            onCreateItem={props.goToDetails}
          />
        )}
      </IonContent>
    </IonPage>
  );
};

export default TabScreen;
