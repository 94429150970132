import {useState, useEffect} from 'react';
import {IonModal, IonButton, IonIcon, IonFooter} from '@ionic/react';
import './styles.scss';
import {closeOutline} from 'ionicons/icons';
import Header from 'src/components/Header';
import MenuItemHours from 'src/components/MenuItemHours';
import {IMenuList} from 'src/interfaces/store';
import {ITiming} from 'src/interfaces/store';
import {WEEK_DAYS} from 'src/utils/constants';
import menuService from 'src/services/menu';
import {useAppSelector, useEffectOnce} from 'src/hooks';

interface IProps {
  isInStock: boolean;
  showMenuModal: boolean;
  menuList: Array<IMenuList>;
  setShowNewMenuModal: (e: boolean) => void;
  setShowEditModal: (e: boolean) => void;
  setShowMenuModal: (e: boolean) => void;
  menuBulkItem: (data: Array<string>, active: boolean, addToMenu: boolean) => void;
}

const BulkEditMenuModal = (props: IProps) => {
  const [selectedMenu, setSelectedMenu] = useState<Array<string>>([]);
  const [timings, setTimings] = useState<Array<ITiming>>([]);
  const [addToMenu, setAddToMenu] = useState(true);

  const {newMenuId} = useAppSelector((state) => state.inventory);
  const {id: storeId} = useAppSelector((state) => state.store);

  const onNewMenuClick = () => {
    props.setShowMenuModal(false);
    props.setShowNewMenuModal(true);
  };

  const setMenu = (value: string) => {
    let newMenus: string[] = [...selectedMenu];
    if (newMenus.includes(value)) newMenus = newMenus.filter((item) => item !== value);
    else newMenus.push(value);
    setSelectedMenu(newMenus);
    if (!!newMenus.length) {
      menuService.getMenuHours(storeId, newMenus).then((res) => {
        setTimings(res);
      });
    } else {
      createHours();
    }
  };

  const createHours = () => {
    const hours = WEEK_DAYS.map((day) => ({
      id: '',
      openTime: '09:00',
      closeTime: '17:00',
      storeId: '',
      dayOfWeek: day.day_of_week,
      isClosed: true,
      invalid: false,
      updated: true,
    }));
    setTimings(hours);
  };

  const onSave = async () => {
    if (selectedMenu.length) {
      props.menuBulkItem(selectedMenu, props.isInStock, addToMenu);
      props.setShowMenuModal(false);
      createHours();
      setSelectedMenu([]);
    }
  };

  const onCancel = () => {
    setSelectedMenu([]);
    createHours();
    props.setShowMenuModal(false);
  };

  useEffect(() => {
    if (newMenuId) {
      setMenu(newMenuId);
    }
  }, [newMenuId]);

  useEffect(() => {
    setSelectedMenu([]);
    createHours();
  }, [addToMenu]);

  useEffectOnce(() => {
    createHours();
  });

  return (
    <IonModal
      id="menu-option"
      isOpen={props.showMenuModal}
      showBackdrop
      onDidDismiss={() => {
        setSelectedMenu([]);
        createHours();
        props.setShowMenuModal(false);
      }}>
      <div className="modal-content-container">
        <div className="modal-content">
          <Header
            border
            noBackground
            title="Menu"
            rightButton={
              <IonIcon
                icon={closeOutline}
                onClick={() => {
                  createHours();
                  setSelectedMenu([]);
                  props.setShowMenuModal(false);
                }}
              />
            }
          />
          <div className="modal-body">
            <MenuItemHours
              menuList={props.menuList}
              onNewMenuClick={onNewMenuClick}
              isMenuTitle={false}
              selectedMenu={selectedMenu}
              setMenu={setMenu}
              timings={timings}
              addToMenu={addToMenu}
              setAddToMenu={setAddToMenu}
              isBulkEditing={true}
            />
          </div>
          <IonFooter>
            <div className="action">
              <IonButton className="action-btn cancel-btn" mode="ios" fill="clear" color="dark" onClick={onCancel}>
                Cancel
              </IonButton>
              <IonButton
                disabled={!selectedMenu.length}
                className="action-btn"
                mode="ios"
                fill="solid"
                color="primary"
                expand="block"
                onClick={onSave}>
                Save edit
              </IonButton>
            </div>
          </IonFooter>
        </div>
      </div>
    </IonModal>
  );
};

export default BulkEditMenuModal;
