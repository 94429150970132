import {useState, useRef, useEffect} from 'react';
import {useHistory} from 'react-router-dom';
import {BarcodeScanner} from '@ionic-native/barcode-scanner';
import {IonButton} from '@ionic/react';
import {Html5Qrcode} from 'html5-qrcode';
import {useAppDispatch, useAppSelector} from 'src/hooks';
import {showLoading, hideLoading} from 'src/store/loadingSlice';
import {addToInventory} from 'src/store/inventorySlice';
import {showAlert} from 'src/store/alertSlice';
import {ITEM_TYPE} from 'src/enums/item_type';
import {getImageUrl} from 'src/utils/helpers';
import itemService from 'src/services/item';
import ItemExistModal from 'src/components/modals/ItemExist';
import ItemAddedModal from 'src/components/modals/ItemAdded';
import ItemNotFoundModal from 'src/components/modals/ItemNotFound';
import {isPlatform} from '@ionic/react';
import AnalyticsHelper from 'src/utils/segment';

export const withScanner = (WrappedComponent: any) => (props: any) => {
  const history = useHistory();

  const dispatch = useAppDispatch();
  const {id: storeId} = useAppSelector((state) => state.store);

  let html5QrCode: any = useRef(null);

  const [upc, setUPC] = useState('');
  const [ean, setEan] = useState('');
  const [showItemExist, setShowItemExist] = useState(false);
  const [showItemAdded, setShowItemAdded] = useState(false);
  const [showItemNotFound, setShowItemNotFound] = useState(false);
  const [showScanner, setShowScanner] = useState(false);
  const [item, setItem] = useState({id: 0, name: '', image: ''});
  const [storeItem, setStoreItem] = useState({id: 0, item_id: 0, store_id: 0});

  const goToCommonItems = () => {
    setShowItemNotFound(false);
    history.push('/items/common');
  };

  const getItemByUPC = (upc: string) => {
    dispatch(showLoading());
    itemService
      .createStoreItemByUPC(storeId, upc)
      .then((response: any) => {
        dispatch(hideLoading());
        const {code, store_item: sItem, item: itemFound, items} = response;
        if (!!itemFound) {
          const {id, name, images, upc, ean, size} = itemFound;
          const item = {
            id,
            storeItemId: sItem.id,
            price: null,
            quantity: 30,
            image: images && images?.[0] ? getImageUrl(images[0]) : '',
            name,
            active: sItem.active,
            selected: false,
            completed: sItem.completed,
            category: sItem.category?.id ? sItem.category : null,
            subcategory: sItem.subcategory?.id ? sItem.subcategory : null,
            upc,
            ean,
            description: size,
          };
          AnalyticsHelper.trackAddNewItem(item, sItem.is_reviewed, 'Scan');
          dispatch(addToInventory(item));
          setItem({id: item.id, name: item.name, image: item.image});
          setShowItemAdded(true);
        } else if (code === 'ALREADY_EXIST') {
          setStoreItem(sItem);
          setShowItemExist(true);
        } else if (code === 'OK' && items.length) {
          setItem({id: items[0]?.id, name: items[0]?.title || '', image: items[0]?.images[0] || ''});
        } else if (code === 'OK' && !items.length) setShowItemNotFound(true);
      })
      .catch((error: any) => {
        dispatch(hideLoading());
        if (error?.code === 'OK' && !error?.items?.length) setShowItemNotFound(true);
        else dispatch(showAlert({heading: 'Invalid UPC', message: error?.message}));
      });
  };

  const closeScanner = async () => {
    if (!!html5QrCode.current) {
      await html5QrCode.current.stop();
      setShowScanner(false);
    }
  };

  const onNewScanResult = (text: string, format: string) => {
    try {
      if (format === 'EAN_13' || format === 'EAN_8') {
        setEan(text);
        setUPC('');
      }
      if (format === 'UPC_A' || format === 'UPC_E') {
        setUPC(text);
        setEan('');
      }
      // If it is from substitution then don't call the api to create item.
      if (text !== '' && !props.isSubstitution) {
        getItemByUPC(text);
      }
    } catch (err) {
      dispatch(showAlert({heading: 'Error', message: err}));
    }
  };

  const openScanner = async () => {
    try {
      if (isPlatform('mobileweb')) {
        setShowScanner(true);

        if (!html5QrCode.current) {
          html5QrCode.current = new Html5Qrcode('web-barcode-reader', {
            verbose: false,
            experimentalFeatures: {
              useBarCodeDetectorIfSupported: true,
            },
          });
        }

        html5QrCode.current
          ?.start(
            {facingMode: 'environment'},
            {
              fps: 30,
              qrbox: 250,
              aspectRatio: 1,
            },
            (decodedText: any, decodedResult: any) => {
              onNewScanResult(decodedResult.result.text, decodedResult.result.format.formatName);
              closeScanner();
            },
            (errorMessage: any) => {},
          )
          .catch((error: any) => {
            closeScanner();
          });
      } else {
        const {text, format} = await BarcodeScanner.scan({prompt: '', disableSuccessBeep: false});
        onNewScanResult(text, format);
      }
    } catch (err) {
      if (err === 'Illegal access') {
        dispatch(
          showAlert({
            heading: 'Permission not granted',
            message: 'Please, give lula stores app camera permission in settings in order to scan barcode.',
          }),
        );
      }
    }
  };

  const addItemDetails = () => {
    setShowItemAdded(false);
    history.push({pathname: '/items/details', state: {type: ITEM_TYPE.SCANNED}});
  };

  const reScanItem = () => {
    setShowItemAdded(false);
    openScanner();
  };

  const scanAnotherBarcode = () => {
    setShowItemExist(false);
    openScanner();
  };

  const goToItem = () => {
    setShowItemExist(false);
    history.push({pathname: '/items/details', state: {id: storeItem?.id, type: ITEM_TYPE.SCANNED}});
  };

  const addDetailsManually = () => {
    setShowItemNotFound(false);
    history.push({pathname: '/items/details', state: {upc, ean, type: ITEM_TYPE.NEW}});
  };

  useEffect(() => {
    return () => {
      if (!!html5QrCode.current) {
        closeScanner();
        html5QrCode.current = null;
      }
    };
  }, []);

  return (
    <>
      <div id="web-barcode-reader" className={showScanner ? 'show' : ''}></div>
      {showScanner && (
        <IonButton className="scanner-close-button" expand="block" fill="clear" onClick={closeScanner}>
          Close
        </IonButton>
      )}
      {!showScanner && <WrappedComponent {...{...props, upc, ean, openScanner, goToCommonItems}} />}
      {/* Modals */}
      <ItemAddedModal
        isOpen={showItemAdded}
        image={item.image}
        title={item.name}
        addDetails={addItemDetails}
        scanNext={reScanItem}
      />
      <ItemExistModal
        isOpen={showItemExist}
        scanBarcode={scanAnotherBarcode}
        goToItem={goToItem}
        onClose={() => setShowItemExist(false)}
      />
      <ItemNotFoundModal
        isOpen={showItemNotFound}
        addDetails={addDetailsManually}
        browseItems={goToCommonItems}
        onClose={() => setShowItemNotFound(false)}
      />
    </>
  );
};
