import {IonButton, IonIcon, IonLabel} from '@ionic/react';
import {chevronDownOutline} from 'ionicons/icons';
import {useState} from 'react';
import {useHistory} from 'react-router';
import './styles.scss';
import {calculateDurationFromEndDateAndTime} from 'src/utils/helpers';
import TimerComponent from 'src/components/TimerComponent';
import PauseOptions from 'src/components/PauseOptions';

interface IProps {
  partnerName?: string;
  currentPauseEndDate: string | undefined;
  currentPauseEndTime: string | undefined;
  onEndPause: () => void;
}

const StoreStatusMenuClosed = ({partnerName, currentPauseEndDate, currentPauseEndTime, onEndPause}: IProps) => {
  const history = useHistory();
  const [opened, setOpened] = useState(false);
  const milliseconds = Math.abs(calculateDurationFromEndDateAndTime(currentPauseEndDate, currentPauseEndTime));

  return (
    <>
      <TimerComponent milliseconds={milliseconds} onEndPause={onEndPause} />

      <div id="status-menu">
        <div
          className={`bg-grey status-menu-container ${opened ? 'open' : 'close'}`}
          onClick={() => {
            setOpened(!opened);
          }}>
          <div className="status-menu">
            <IonLabel>Store Closed</IonLabel>
            {partnerName ? (
              <IonLabel className="subtitle">{partnerName} paused</IonLabel>
            ) : (
              <IonLabel className="subtitle">All partners paused</IonLabel>
            )}
          </div>
          <IonIcon icon={chevronDownOutline} slot="end" className={`ion-icon ${opened ? 'open' : 'close'}`} />
        </div>
        {opened && (
          <div className="status-menu-content">
            <div className="store-button">
              <IonButton
                className="btn-outline"
                expand="block"
                mode="md"
                onClick={() => history.replace({pathname: '/store/manage', state: {showStoreHours: true}})}>
                Store Hours
              </IonButton>
            </div>
            <PauseOptions partnerName={partnerName} onEndPause={onEndPause} />
          </div>
        )}
      </div>
    </>
  );
};

export default StoreStatusMenuClosed;
