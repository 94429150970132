import {useRef} from 'react';
import {useIonViewWillEnter} from '@ionic/react';
import {Capacitor} from '@capacitor/core';
import {RouteComponentProps} from 'react-router';
import {HELP_URL} from 'src/utils/constants';
import {Desktop, Mobile, Tablet} from 'src/utils/platform';
import MobileScreen from './Mobile';
import TabScreen from './Tablet';
import {useAppSelector} from 'src/hooks';

interface IProps extends RouteComponentProps {}

const Onboarding = ({history}: IProps) => {
  const slideRef = useRef<HTMLIonSlidesElement>(null);
  const {isNative} = useAppSelector((state) => state.platform);

  useIonViewWillEnter(() => {
    slideRef.current?.update();
  });

  const startApp = () => {
    history.replace('/auth/login');
  };

  const openHelp = () => {
    if (!isNative) window.open(HELP_URL);
    else history.push('/support/help');
  };

  const props = {startApp, openHelp, slideRef};

  return (
    <>
      <Desktop>Desktop Screen.</Desktop>
      <Tablet>
        <TabScreen {...props} />
      </Tablet>
      <Mobile>
        <MobileScreen {...props} />
      </Mobile>
    </>
  );
};

export default Onboarding;
