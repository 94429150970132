import {IonPage, IonContent, IonImg, IonButton} from '@ionic/react';
import './styles.scss';
import TextWithLines from 'src/components/TextWithLines';
import TermsText from 'src/components/TermsText';
import PrivacyText from 'src/components/PrivacyText';
import logo from 'src/assets/images/logo.png';

interface IProps {
  goToLogin: () => void;
  goToOnboarding: () => void;
}

const TabScreen = (props: IProps) => {
  return (
    <IonPage id="welcome-page-tablet">
      <IonContent fullscreen>
        <div className="body">
          <div className="content-container">
            <div className="logo-container">
              <IonImg src={logo} alt="logo" />
            </div>

            <h2 className="ion-text-center title">Empower your Store to deliver locally.</h2>

            <IonButton className="btn-primary" expand="block" onClick={props.goToOnboarding}>
              Get started Today
            </IonButton>

            <TextWithLines text="existing user?" />

            <IonButton className="btn-secondary" expand="block" onClick={props.goToLogin}>
              Sign In
            </IonButton>

            <p className="ion-text-center desc">
              By registering an account and using this Service, you agree to our <TermsText /> and <PrivacyText />.
            </p>
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default TabScreen;
