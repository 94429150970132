/* eslint-disable react-hooks/exhaustive-deps */
import {useState} from 'react';
import {IonPage, IonContent, IonBackButton, IonButton, IonIcon} from '@ionic/react';
import './styles.scss';
import moment from 'moment';
import {closeOutline} from 'ionicons/icons';
import {RouteComponentProps} from 'react-router';
import {Controller} from 'react-hook-form';
import {useForm} from 'react-hook-form';
import Header from 'src/components/Header';
import Input from 'src/components/Input';
import TextWithLines from 'src/components/TextWithLines';
import {IUpdatePasswordFormInput} from 'src/interfaces/form';
import {useAppDispatch, useAppSelector, useEffectOnce} from 'src/hooks';
import {updateProfile} from 'src/store/authSlice';
import {RULES} from 'src/utils/constants';
import arrowBack from 'src/assets/images/arrow-back-black.svg';
import * as PERMISSIONS from 'src/utils/permissions';

interface IProps extends RouteComponentProps {
  setShowPasswordSettings?: (show: boolean) => void;
}

const ChangePassword = ({setShowPasswordSettings}: IProps) => {
  const dispatch = useAppDispatch();

  const {user, menuIsOpen} = useAppSelector((state) => state.auth);
  const {isDesktop, isTablet} = useAppSelector((state) => state.platform);
  const canEditInput = user?.role.permissions?.some((item: any) => item.name === PERMISSIONS.EDIT_ACCOUNT_DETAILS);

  const {control, handleSubmit, setValue} = useForm<IUpdatePasswordFormInput>({
    mode: 'onSubmit',
    defaultValues: {
      oldPassword: '••••••••',
      password: '',
      lastTimePasswordChanged: '',
    },
  });

  const [showPassword, setShowPassword] = useState(false);

  const showNewPassword = () => {
    setShowPassword(true);
  };

  const updatePassword = async (formData: IUpdatePasswordFormInput) => {
    const data = {
      id: user?.id,
      password: formData.password,
    };
    dispatch(updateProfile(data));
  };

  const onClose = () => {
    if (setShowPasswordSettings) setShowPasswordSettings(false);
  };

  useEffectOnce(() => {
    if (user?.passwordLastChanged) {
      setValue('lastTimePasswordChanged', moment(user?.passwordLastChanged).fromNow());
    }
  });

  return (
    <IonPage id="change-password-page" className={`${isTablet && !menuIsOpen ? 'menu-close' : ''} snow-background`}>
      <IonContent fullscreen scrollY={false} className={`${isTablet ? 'page-container' : ''}`}>
        <Header
          noBackground
          leftButton={!isDesktop && <IonBackButton defaultHref="/settings" text="" icon={arrowBack} />}
          rightButton={
            isDesktop && (
              <IonButton onClick={onClose}>
                <IonIcon src={closeOutline} />
              </IonButton>
            )
          }
          title="Password"
        />

        <div className="body">
          <p className="desc">Keep your Account safe by changing your password now and then.</p>

          <Input disabled type="text" name="oldPassword" placeholder="Password" control={control} />

          {canEditInput && <TextWithLines text="Update Password?" />}

          {showPassword && (
            <Input type="password" name="password" placeholder="Password" control={control} rules={RULES.password} />
          )}

          {canEditInput && (
            <IonButton
              expand="block"
              className={showPassword ? 'btn-dark' : 'btn-outline'}
              onClick={showPassword ? handleSubmit(updatePassword) : showNewPassword}>
              {showPassword ? 'Save Password' : 'Enter new Password'}
            </IonButton>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ChangePassword;
