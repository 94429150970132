import {AnalyticsBrowser} from '@segment/analytics-next';
import {IOrderItem, IQueryObject, IItem, IOrderItemDetails} from 'src/interfaces/item';
import {store} from 'src/store';
import {v4 as uuid} from '@lukeed/uuid';
import {calculateDateForFiltering, getSubstitutedItemQuantity, sortValuesToSortOutput} from 'src/utils/helpers';
import {IOrderUpdatePayloadItem, ISubstituteListItem, OrderInfo} from 'src/interfaces/order';
import {PRINT_TRIGGER} from 'src/services/printer';
import {ORDER_FETCH_TYPE} from 'src/enums/order';
import {IPartnerItem} from 'src/interfaces/store';

const analytics = AnalyticsBrowser.load({writeKey: process.env.REACT_APP_SEGMENT_KEY || ''});

const AnalyticsHelper = {
  identify: async (id: string, traits?: {}) => {
    await analytics.identify(id, traits);
  },
  identifyProfile: async (user: any) => {
    const {platform} = store.getState();
    await analytics.identify(user.id, {
      firstName: user.firstName,
      lastName: user.lastName,
      email: user?.email,
      phone: user.phone,
      phone_number: user?.phone,
      username: user?.email,
      'app-version': platform.isDesktop ? 'desktop' : platform.versionNumber,
    });
  },
  identifyLoginOrSignUp: async (user: any) => {
    const {platform} = store.getState();
    await analytics.identify(user.id, {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      phone: user?.phone,
      phone_number: user?.phone,
      username: user?.email,
      createdAt: user?.createdAt,
      'app-version': platform.isDesktop ? 'desktop' : platform.versionNumber,
    });
  },
  identifyStore: async (user: any, storeData: any) => {
    const {store: userStore, platform} = store.getState();
    await analytics.identify(user?.id, {
      firstName: user?.firstName,
      lastName: user?.lastName,
      email: user?.email,
      phone: user?.phone,
      phone_number: user?.phone,
      username: user?.email,
      createdAt: user?.createdAt,
      company: storeData.name,
      address: {
        street: userStore.address.line_1,
        city: userStore.address.city,
        state: userStore.address.state,
        postalCode: userStore.address.zip,
        country: 'USA',
      },
      companyInfo: {
        name: storeData.name,
        id: storeData.id,
        type: userStore.storeType.name,
        employee_count: userStore.employeesCount,
        shipping_address: userStore.shippingAddress
          ? `${userStore.shippingAddress.line_1}, ${userStore.shippingAddress.city}, ${userStore.shippingAddress.state}, ${userStore.shippingAddress.zip}`
          : '',
        store_phone: storeData.phone_number ?? storeData.phone,
        store_email: storeData.email,
      },
      'app-version': platform.isDesktop ? 'desktop' : platform.versionNumber,
    });
  },
  identifyOnEveryPage: () => {
    const {user} = store.getState().auth;
    const {store: userStore, platform} = store.getState();
    if (user) {
      if (userStore.id !== '') {
        const address = {
          street: userStore.address.line_1,
          city: userStore.address.city,
          state: userStore.address.state,
          postalCode: userStore.address.zip,
          country: 'USA',
        };
        const companyInfo = {
          name: userStore.name,
          id: userStore.id,
          type: userStore.storeType.name,
          employee_count: userStore.employeesCount,
          shipping_address: userStore.shippingAddress
            ? `${userStore.shippingAddress.line_1}, ${userStore.shippingAddress.city}, ${userStore.shippingAddress.state}, ${userStore.shippingAddress.zip}`
            : '',
          store_phone: userStore.phone,
          store_email: userStore.email,
        };
        analytics.identify(user?.id, {
          firstName: user?.firstName,
          lastName: user?.lastName,
          email: user?.email,
          phone: user?.phone,
          phone_number: user?.phone,
          username: user?.email,
          createdAt: user?.createdAt,
          company: {name: userStore.name},
          address,
          companyInfo,
          'app-version': platform.isDesktop ? 'desktop' : platform.versionNumber,
        });
      }
    }
  },
  trackNewOrder: (order: any, fetchType = ORDER_FETCH_TYPE.socket) => {
    const {store: userStore} = store.getState();
    const items = order.orderItems.map((item: any) => ({
      id: item.id,
      name: item.itemName,
      upc: item.upc,
      store_price: item.itemPrice,
      purchase_price: item.purchasePrice,
      quantity: item.quantity,
    }));
    const messageId = uuid();
    const payload = {
      'Order arrived': Math.round(Date.now() / 1000),
      messageId,
      orderId: order.id,
      source: order.partner,
      customer_name: order.customerName,
      no_of_items: order.quantity,
      items: items,
      total_price: order.totalPrice,
      'fetch type': fetchType,
      'store id': userStore.id,
      'store name': userStore.name,
    };
    analytics.track('Order arrived', payload);
  },
  trackAppIsConnectedToInternet: () => {
    const {store: userStore} = store.getState();
    if (userStore.id) {
      const messageId = uuid();
      analytics.track('Internet connected', {
        'Internet connected': Math.round(Date.now() / 1000),
        messageId,
        'store id': userStore.id,
        'store name': userStore.name,
      });
    }
  },
  trackAppIsNotConnectedToInternet: () => {
    const {store: userStore} = store.getState();
    if (userStore.id) {
      const messageId = uuid();
      analytics.track('Internet disconnected', {
        'Internet disconnected': Math.round(Date.now() / 1000),
        messageId,
        'store id': userStore.id,
        'store name': userStore.name,
      });
    }
  },
  trackInventorySort: (queryObject: IQueryObject) => {
    const {store: userStore} = store.getState();
    const sortOutput = sortValuesToSortOutput(queryObject);
    const messageId = uuid();
    analytics.track('Inventory Sorted', {
      'Inventory Sorted': Math.round(Date.now() / 1000),
      messageId,
      sortBy: sortOutput,
      'store id': userStore.id,
      'store name': userStore.name,
    });
  },
  trackInventoryFilter: (queryObject: IQueryObject) => {
    const messageId = uuid();
    const {store: userStore} = store.getState();
    analytics.track('Inventory Filtered', {
      'Inventory Filtered': Math.round(Date.now() / 1000),
      'store id': userStore.id,
      'store name': userStore.name,
      messageId,
      filterBy: {
        Availability: queryObject.availability,
        Price: `${queryObject.minPrice} to ${queryObject.maxPrice}`,
        Date: calculateDateForFiltering(queryObject.endDate, queryObject.startDate),
        Categories:
          queryObject.categories.length === 0
            ? 'All'
            : queryObject.categoryNames
            ? (queryObject.categoryNames as string[]).join(', ')
            : queryObject.categories.join(', '),
      },
      numberOfResults: queryObject.numberOfResults,
    });
  },
  trackCancelledOrder: (order: IOrderItem | undefined) => {
    if (order) {
      const {store: userStore} = store.getState();
      const items = order.orderItems?.map((item: any) => ({
        'item name': item.itemName,
        'item price': item.itemPrice,
        'item quantity': item.quantity,
        'item category': item.categoryName,
        'item upc': item.upc || '',
      }));
      const messageId = uuid();
      analytics.track('Order Cancelled', {
        'Order Cancelled': Math.round(Date.now() / 1000),
        messageId,
        orderId: order.id,
        source: order.partner,
        'customer name': order.customerName,
        items: items,
        'total order price': order.totalPrice,
        'store id': userStore.id,
        'store name': userStore.name,
      });
    }
  },
  trackOrderCompleted: (order: any) => {
    const items = order.orderItems.map((item: any) => ({
      id: item.id,
      name: item.itemName,
      upc: item.upc,
      store_price: item.itemPrice,
      purchase_price: item.purchasePrice,
      quantity: item.quantity,
    }));
    const messageId = uuid();
    const {store: userStore} = store.getState();
    analytics.track('Order completed', {
      'Order completed': Math.round(Date.now() / 1000),
      messageId,
      orderId: order.id,
      source: order.partner,
      customer_name: order.customerName,
      no_of_items: order.quantity,
      items: items,
      total_price: order.totalPrice,
      'store id': userStore.id,
      'store name': userStore.name,
    });
  },
  trackEditItem: (oldItem: any, newItem: IItem) => {
    if (oldItem && newItem) {
      const {store: userStore} = store.getState();
      const messageId = uuid();
      analytics.track('Edit Item', {
        'Edit Item': Math.round(Date.now() / 1000),
        messageId,
        'new item name': newItem.name || '',
        'old item name': oldItem.name || '',
        'new item price': newItem.price,
        'old item price': oldItem.price,
        'new item category': newItem.category ? newItem.category.name || '' : '',
        'old item category': oldItem.category ? oldItem.category.name || '' : '',
        'new item subcategory': newItem.subcategory ? newItem.subcategory.name || '' : '',
        'old item subcategory': oldItem.subcategory ? oldItem.subcategory.name || '' : '',
        'new item description': newItem.description,
        'old item description': oldItem.description,
        'new availability status': newItem.active ? 'In Stock' : 'Out of Stock',
        'old availability status': oldItem.active ? 'In Stock' : 'Out of Stock',
        'item id': newItem.id,
        upc: newItem.upc,
        'edit mechanism': 'Direct',
        'store id': userStore.id,
        'store name': userStore.name,
      });
    }
  },
  trackEditBulkItem: (count: Number, details: string | undefined, type: string) => {
    if (count && type) {
      const {store: userStore} = store.getState();
      const messageId = uuid();
      analytics.track('Bulk Items Edit', {
        'Bulk Items Edit': Math.round(Date.now() / 1000),
        messageId,
        'bulk edit type': type,
        'bulk edit details': details,
        'no. of items': count,
        'store id': userStore.id,
        'store name': userStore.name,
      });
    }
  },
  trackLogout: () => {
    const messageId = uuid();
    analytics.track('Logout', {
      Logout: Math.round(Date.now() / 1000),
      messageId,
      logoutAt: new Date(),
    });
  },
  trackLogin: (userRole: string) => {
    const messageId = uuid();
    const {store: userStore} = store.getState();
    const {isNative} = store.getState().platform;
    analytics.track('Login', {
      Login: Math.round(Date.now() / 1000),
      messageId,
      'permission level': userRole,
      'platform used': isNative ? 'Android' : 'Web',
      'store id': userStore.id,
      'store name': userStore.name,
    });
  },
  trackViewAndEditOrder: (incomingOrderID: string) => {
    const messageId = uuid();
    const {store: userStore} = store.getState();
    analytics.track('View & edit order', {
      'View & edit order': Math.round(Date.now() / 1000),
      messageId,
      orderID: incomingOrderID,
      'store id': userStore.id,
      'store name': userStore.name,
    });
  },
  trackOrderPopupDismissed: (incomingOrderID: string) => {
    const messageId = uuid();
    const {store: userStore} = store.getState();
    analytics.track('Order Popup Dismissed', {
      'Order Popup Dismissed': Math.round(Date.now() / 1000),
      messageId,
      orderID: incomingOrderID,
      'store id': userStore.id,
      'store name': userStore.name,
    });
  },
  trackOrderDetailsClick: (orderID: string) => {
    const messageId = uuid();
    const {store: userStore} = store.getState();
    analytics.track('Order Details Clicked', {
      'Order Details Clicked': Math.round(Date.now() / 1000),
      messageId,
      orderID: orderID,
      'store id': userStore.id,
      'store name': userStore.name,
    });
  },
  trackEditOrderClick: (orderID: string | undefined) => {
    const messageId = uuid();
    const {store: userStore} = store.getState();
    analytics.track('Edit Order Clicked', {
      'Edit Order Clicked': Math.round(Date.now() / 1000),
      messageId,
      orderID: orderID,
      'store id': userStore.id,
      'store name': userStore.name,
    });
  },
  trackBeginSubstitutionFlowClick: (orderID: string, orderItem: IOrderItemDetails) => {
    const messageId = uuid();
    const {store: userStore} = store.getState();
    analytics.track('Begin Substitution Flow Clicked', {
      'Begin Substitution Flow Clicked': Math.round(Date.now() / 1000),
      messageId,
      orderID,
      itemID: orderItem.id,
      itemName: orderItem.itemName,
      itemPrice: orderItem.itemTotalPrice,
      itemQuantity: orderItem.quantity,
      'store id': userStore.id,
      'store name': userStore.name,
    });
  },
  trackSubstitutionItemSelected: (
    orderID: string,
    itemToSubstitute: IOrderItemDetails,
    substituteListItem: ISubstituteListItem,
  ) => {
    const messageId = uuid();
    const {store: userStore} = store.getState();
    const {substituteItem, substituteItemQuantity} = substituteListItem;
    let subItemQuantity = getSubstitutedItemQuantity(itemToSubstitute, substituteItem, substituteItemQuantity);
    let subItemPrice = subItemQuantity * Number(substituteItem.price);
    analytics.track('Substitution Item Selected', {
      'Substitution Item Selected': Math.round(Date.now() / 1000),
      messageId,
      orderID,
      subItemPrice,
      subItemQuantity,
      subItemName: substituteItem.name,
      itemSubstituteID: substituteItem.id,
      itemID: itemToSubstitute.id,
      itemName: itemToSubstitute.itemName,
      itemPrice: itemToSubstitute.itemTotalPrice,
      itemQuantity: itemToSubstitute.quantity,
      'store id': userStore.id,
      'store name': userStore.name,
    });
  },
  trackSubstitutionItemReverted: (orderID: string, itemID: string, itemSubstituteID: string) => {
    const messageId = uuid();
    const {store: userStore} = store.getState();
    analytics.track('Substitution Item Reverted', {
      'Substitution Item Reverted': Math.round(Date.now() / 1000),
      messageId,
      orderID,
      itemID,
      itemSubstituteID,
      'store id': userStore.id,
      'store name': userStore.name,
    });
  },
  trackBeginEditOrderClick: (orderID: string) => {
    const messageId = uuid();
    const {store: userStore} = store.getState();
    analytics.track('Begin Edit Order Clicked', {
      'Begin Edit Order Clicked': Math.round(Date.now() / 1000),
      messageId,
      orderID: orderID,
      'store id': userStore.id,
      'store name': userStore.name,
    });
  },
  trackItemOutOfStockSelected: (orderID: string, itemID: string) => {
    const messageId = uuid();
    const {store: userStore} = store.getState();
    analytics.track('Item Out of Stock Selected', {
      'Item Out of Stock Selected': Math.round(Date.now() / 1000),
      messageId,
      orderID: orderID,
      itemID: itemID,
      'store id': userStore.id,
      'store name': userStore.name,
    });
  },
  trackOrderUpdated: (
    order: IOrderItem,
    substituteListItem: Array<ISubstituteListItem>,
    editedListItem: Array<IOrderUpdatePayloadItem>,
  ) => {
    const messageId = uuid();
    const substitutedItems = substituteListItem.map((substituteListItem: ISubstituteListItem) => {
      const {orderItemId, substituteItem, substituteItemQuantity} = substituteListItem;
      const originalItem = order.orderItems?.find((orderItem) => orderItem.id === orderItemId);
      const subItemQuantity = getSubstitutedItemQuantity(originalItem, substituteItem, substituteItemQuantity);
      const subItemPrice = subItemQuantity * Number(substituteItem.price);
      return {
        orderID: order.id,
        containSubstitution: true,
        subItemPrice,
        subItemQuantity,
        subItemName: substituteItem.name,
        itemSubstituteID: substituteItem.id,
        itemID: originalItem?.id,
        itemName: originalItem?.itemName,
        itemPrice: originalItem?.itemTotalPrice,
        itemQuantity: originalItem?.quantity,
        updatedItemQuantity: '',
        updatedItemPrice: '',
      };
    });

    const editedItems = editedListItem.map((item) => {
      const originalItem = order.orderItems?.find((orderItem) => orderItem.id === item.order_item_id);
      const updatedItemPrice = (item?.quantity || 0) * Number(originalItem?.itemPrice || 0);
      return {
        orderID: order.id,
        containSubstitution: false,
        subItemPrice: '',
        subItemQuantity: '',
        subItemName: '',
        itemSubstituteID: '',
        itemID: originalItem?.id,
        itemName: originalItem?.itemName,
        itemPrice: originalItem?.itemTotalPrice,
        itemQuantity: originalItem?.quantity,
        updatedItemQuantity: item.quantity,
        updatedItemPrice,
      };
    });
    const {store: userStore} = store.getState();
    analytics.track('Order Updated', {
      'Order Updated': Math.round(Date.now() / 1000),
      messageId,
      items: [...substitutedItems, ...editedItems],
      'store id': userStore.id,
      'store name': userStore.name,
    });
  },
  trackItemQuantityUpdated: (order: IOrderItem | undefined, itemID: string, newQuantity: number) => {
    if (order) {
      const {store: userStore} = store.getState();
      const item = order.orderItems?.find((item: any) => item.id === itemID);
      const messageId = uuid();
      analytics.track('Item Quantity Updated', {
        'Item Quantity Updated': Math.round(Date.now() / 1000),
        messageId,
        orderId: order.id,
        itemID: item?.storeItemId,
        'item name': item?.itemName,
        'item price': item?.itemPrice,
        'new item quantity': newQuantity,
        'original item quantity': item?.quantity,
        'store id': userStore.id,
        'store name': userStore.name,
      });
    }
  },
  trackRemoveItem: (order: IOrderItem | undefined, itemID: string) => {
    if (order) {
      const {store: userStore} = store.getState();
      const item = order.orderItems?.find((item: any) => item.id === itemID);
      const messageId = uuid();
      analytics.track('Remove Item', {
        'Remove Item': Math.round(Date.now() / 1000),
        messageId,
        orderId: order.id,
        'item name': item?.itemName,
        'item price': item?.itemPrice,
        'item quantity': item?.quantity,
        'store id': userStore.id,
        'store name': userStore.name,
      });
    }
  },
  trackDenyOrder: (order: any | undefined, denyReason: string) => {
    const {auth: userAuth, store: userStore} = store.getState();
    const messageId = uuid();
    if (order) {
      let itemNames = [];
      let itemPrices = [];
      let itemQuantities = [];
      let itemCategories = [];
      let itemUPCs = [];

      for (let item of order.orderItems) {
        itemNames.push(item.itemName);
        itemPrices.push(`$${item.itemPrice}`);
        itemQuantities.push(item.quantity);
        itemCategories.push(item.categoryName);
        itemUPCs.push(item.upc || '');
      }
      analytics.track('Deny Order', {
        OrderID: order.id,
        source: order.partner,
        messageId,
        'customer name': order.customerName,
        'item names': itemNames,
        'item prices': itemPrices,
        'item quantities': itemQuantities,
        'item categories': itemCategories,
        'item upcs': itemUPCs,
        'deny reason': denyReason,
        UserID: userAuth?.user?.id,
        'store id': userStore.id,
        'store name': userStore.name,
      });
    }
  },
  trackStorefrontToggle: (data: any) => {
    const messageId = uuid();
    const {store: userStore} = store.getState();

    analytics.track('Storefront Toggle', {
      'Storefront Toggle': Math.round(Date.now() / 1000),
      messageId,
      'old store status': data.old_status,
      'new store status': data.new_status,
      'delivery partners': data.partners,
      'toggle time': data.toggle_time,
      'store id': userStore.id,
      'store name': userStore.name,
    });
  },
  trackGetStoreStatus: (data: {
    doorDash: Array<IPartnerItem>;
    grubHub: Array<IPartnerItem>;
    uberEats: Array<IPartnerItem>;
  }) => {
    const messageId = uuid();
    const {store: userStore} = store.getState();
    analytics.track('Get Store Status', {
      StoreStatus: Math.round(Date.now() / 1000),
      messageId,
      'DD store status': data.doorDash[0].isOpen,
      'GH store status': data.grubHub[0].isOpen,
      'UE store status': data.uberEats[0].isOpen,
      'store id': userStore.id,
      'store name': userStore.name,
    });
  },
  trackMarkReadyForPickup: (incomingOrderID: string | undefined) => {
    const messageId = uuid();
    const {store: userStore} = store.getState();
    analytics.track('Mark ready for Pickup', {
      'Mark ready for Pickup': Math.round(Date.now() / 1000),
      messageId,
      orderID: incomingOrderID,
      'store id': userStore.id,
      'store name': userStore.name,
    });
  },
  trackAddNewItem: (item: any, is_reviewed: boolean, type: string) => {
    const {store: userStore} = store.getState();
    analytics.track('Add item', {
      'Add item': Math.round(Date.now() / 1000),
      'entry point': type,
      'item status': is_reviewed ? 'Ready' : 'Needs review',
      'item name': item.name,
      'item price': item.price,
      'item category': item?.category?.name || 'No Category Added',
      'item subcategory': item?.subcategory?.name || 'No Subcategory Added',
      'item description': item.description || '',
      'item id': item.id,
      upc: item.upc || '',
      'store id': userStore.id,
      'store name': userStore.name,
    });
  },
  trackActiveOrdersError: (error: any) => {
    const {store: userStore} = store.getState();
    analytics.track('ERR Order Retrieval', {
      'Store Name': userStore.name,
      'Store ID': userStore.id,
      Error: error,
    });
  },
  groupStore: () => {
    const {user} = store.getState().auth;
    const {store: userStore} = store.getState();
    if (user && userStore) {
      analytics.group(userStore.id, {
        address: {
          state: userStore.address.state,
          city: userStore.address.city,
          postalCode: userStore.address.zip,
          street: userStore.address.line_1,
        },
        createdAt: userStore.createdAt,
        email: userStore.email,
        employees: userStore.employeesCount,
        userId: user.id,
        ownership: userStore.storeType.name,
        name: userStore.name,
        phone: userStore.phone,
      });
    }
  },
  trackSetPrinter: (printerModel: string, macAddress: string) => {
    const {store: userStore} = store.getState();
    const track = {
      'Printer Model Name': printerModel?.trim(),
      'Printer Mac Address': macAddress,
      'Store Name': userStore.name,
      'Store ID': userStore.id,
      address: {
        street: userStore.address.line_1,
        city: userStore.address.city,
        state: userStore.address.state,
        postalCode: userStore.address.zip,
        country: 'USA',
      },
    };
    analytics.track('Printer Set', track);
  },
  trackPrinter: (printerModel: string) => {
    const {store: userStore} = store.getState();
    const track = {
      'Printer Model': printerModel?.trim(),
      'Store Name': userStore.name,
      'Store ID': userStore.id,
      address: {
        street: userStore.address.line_1,
        city: userStore.address.city,
        state: userStore.address.state,
        postalCode: userStore.address.zip,
        country: 'USA',
      },
    };
    analytics.track('Printer Paired', track);
  },
  trackPrinterJob: (order: OrderInfo, printTrigger: PRINT_TRIGGER.AUTO | PRINT_TRIGGER.MANUAL, macAddress: string) => {
    const {store: userStore} = store.getState();
    const items = order?.orderItems?.map((item: any) => ({
      'item name': item.itemName,
      'item price': item.itemPrice,
      'item quantity': item.quantity,
      'item category': item.categoryName,
      'item upc': item.upc || '',
    }));
    const address = `${userStore.address.line_1} ${userStore.address.city} ${userStore.address.state} ${userStore.address.zip}`;
    const track = {
      'customer name': order?.customerName,
      'no. of items': order?.quantity,
      source: order?.partner,
      'total price': order?.totalPrice,
      'order id': order?.id,
      items,
      date: new Date(),
      'local time': new Date().toLocaleTimeString(),
      'store id': userStore.id,
      'store name': userStore.name,
      address,
      'print trigger': printTrigger,
      'printer Id': macAddress,
    };
    analytics.track('Receipt Printed', track);
  },
  trackError: ({message, failedService, errCode}: any) => {
    const {store: userStore, platform} = store.getState();
    const track = {
      'store name': userStore.name,
      'store id': userStore.id,
      'device type': platform.isNative ? 'Native' : 'Web',
      'app version': platform.isNative ? `${platform.versionNumber} (${platform.versionCode})` : '',
      'alert message': message,
      'failed service': failedService,
      'error code': errCode,
    };
    analytics.track('Error detected', track);
  },
  trackSocketDisconnect: (
    storeId: string | null,
    reason: string | null,
    message: string | null,
    description: string | null,
  ) => {
    const trackData = {
      'store id': storeId ?? 'Unknown Store ID',
      reason: reason ?? 'Unknown Reason',
      message: message ?? 'No Message',
      description: description ?? 'No Description',
    };

    analytics.track('Socket Disconnected', trackData);
  },
  trackEmployeeDeviceUpdate: (isActivate: boolean) => {
    const {store: userStore, platform: userDevice} = store.getState();
    const track = {
      'store name': userStore.name,
      'store id': userStore.id,
      'device id': userDevice?.device?.deviceId,
      activated: isActivate,
    };
    analytics.track('Employee Notification Device Updated', track);
  },
};

export default AnalyticsHelper;
