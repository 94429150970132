import Baby from 'src/assets/placeholder/baby.png';
import Bath_Beauty from 'src/assets/placeholder/bath-beauty.png';
import Cleaning from 'src/assets/placeholder/cleaning.png';
import Drinks from 'src/assets/placeholder/drinks.png';
import Health from 'src/assets/placeholder/health.png';
import Ice_Cream from 'src/assets/placeholder/ice-cream.png';
import Snacks from 'src/assets/placeholder/snacks.png';
import Quick_Meals from 'src/assets/placeholder/quick-meals.png';
import Home_Office from 'src/assets/placeholder/home-office.png';
import Pets from 'src/assets/placeholder/pets.png';
import Candy from 'src/assets/placeholder/candy.png';
import Alcohol from 'src/assets/placeholder/alcohol.png';
import Personal_Care_Health from 'src/assets/placeholder/personal-health-care.png';
import Grocery from 'src/assets/placeholder/grocery.png';

export default new Map([
  ['Quick Meals', Quick_Meals],
  ['Home & Office', Home_Office],
  ['Pets', Pets],
  ['Candy', Candy],
  ['Alcohol', Alcohol],
  ['Beverages', Drinks],
  ['Personal Care & Health', Personal_Care_Health],
  ['Grocery', Grocery],
  ['Groceries', Grocery],
  ['Baby', Baby],
  ['Bath & Beauty', Bath_Beauty],
  ['Cleaning', Cleaning],
  ['Drinks', Drinks],
  ['Health', Health],
  ['Ice Cream', Ice_Cream],
  ['Snacks', Snacks],
]);
