import {useState} from 'react';
import {IonPage, IonContent, IonBackButton, IonButton, IonFooter, IonIcon, useIonViewDidEnter} from '@ionic/react';
import './styles.scss';
import {closeOutline} from 'ionicons/icons';
import {RouteComponentProps} from 'react-router';
import Header from 'src/components/Header';
import NotificationItem from './components/Item';
import notificationService from 'src/services/notifications';
import {useAppDispatch, useAppSelector, useEffectOnce} from 'src/hooks';
import {hideLoading, showLoading} from 'src/store/loadingSlice';
import {showAlert} from 'src/store/alertSlice';
import {formatPhoneNumber} from 'src/utils/helpers';
import arrowBack from 'src/assets/images/arrow-back-black.svg';
import * as PERMISSIONS from 'src/utils/permissions';
import {checkPermission} from 'src/utils/helpers';

interface IProps extends RouteComponentProps {
  setShowNotifications?: (show: boolean) => void;
}

const Notifications = ({setShowNotifications}: IProps) => {
  const dispatch = useAppDispatch();

  const {menuIsOpen, user} = useAppSelector((state) => state.auth);
  const {isDesktop, isTablet} = useAppSelector((state) => state.platform);
  const {id: storeId} = useAppSelector((state) => state.store);
  const canEditInput = checkPermission(user?.role.permissions, PERMISSIONS.EDIT_ACCOUNT_NOTIFICATIONS);

  const [disabled, setDisabled] = useState(false);
  const [newOrders, setNewOrders] = useState({mobile: true, sms: false, web: false, email: false});
  const [orderExpiringSoon, setOrderExpiringSoon] = useState({mobile: true, sms: false, web: false, email: false});

  const getSettings = () => {
    dispatch(showLoading());
    notificationService
      .getNotificationSettings(storeId)
      .then((settingsData) => {
        dispatch(hideLoading());
        setNewOrders(settingsData.newOrders);
        setOrderExpiringSoon(settingsData.orderExpiringSoon);
      })
      .catch((error) => {
        dispatch(hideLoading());
        dispatch(
          showAlert({
            heading: 'Error',
            message:
              error.message ||
              'Some error occurred while fetching your notifications settings. Please, try again later',
          }),
        );
      });
  };

  const saveChanges = () => {
    dispatch(showLoading());
    const data = {
      new_order: {...newOrders, mobile: true},
      order_expiring_soon: {...orderExpiringSoon, mobile: true},
    };
    notificationService
      .setNotifications(storeId, data)
      .then((_) => {
        dispatch(hideLoading());
        dispatch(
          showAlert({
            heading: 'Success',
            message: 'Notifications settings updated successfully',
          }),
        );
      })
      .catch((error) => {
        dispatch(hideLoading());
        dispatch(
          showAlert({
            heading: 'Unable to update',
            message:
              error?.message ||
              'Some error occurred while updating your notifications settings. Please, try again later',
          }),
        );
      });
  };

  const onClose = () => {
    if (setShowNotifications) setShowNotifications(false);
  };

  useIonViewDidEnter(() => setDisabled(true));

  useEffectOnce(() => {
    getSettings();
  });

  return (
    <IonPage id="notifications-page" className={`${isTablet && !menuIsOpen ? 'menu-close' : ''} snow-background`}>
      <IonContent fullscreen scrollY={false} className={`${isTablet ? 'page-container' : ''}`}>
        <Header
          noBackground
          leftButton={!isDesktop && <IonBackButton defaultHref="/settings" text="" icon={arrowBack} />}
          rightButton={
            isDesktop && (
              <IonButton onClick={onClose}>
                <IonIcon src={closeOutline} />
              </IonButton>
            )
          }
          title="Notifications"
        />

        <div className="body with-footer">
          <div className="notification-places-body">
            <p>
              <b>E-mail:</b> {user?.email}
            </p>
            <p>
              <b>SMS/Text:</b> {formatPhoneNumber(user?.phone!)}
            </p>
          </div>
          <NotificationItem
            title="New Orders"
            description="Incoming Order alert"
            value={newOrders}
            onChange={setNewOrders}
            canEdit={canEditInput}
            setDisabled={setDisabled}
          />
          <NotificationItem
            title="Order Expiring Soon"
            description="Order expiring soon alert"
            value={orderExpiringSoon}
            onChange={setOrderExpiringSoon}
            setDisabled={setDisabled}
            canEdit={canEditInput}
          />
        </div>

        <IonFooter>
          <IonButton
            className="btn-dark"
            expand="block"
            disabled={disabled}
            onClick={() => {
              saveChanges();
              setDisabled(true);
            }}>
            Save Changes
          </IonButton>
        </IonFooter>
      </IonContent>
    </IonPage>
  );
};

export default Notifications;
