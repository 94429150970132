/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect, useRef} from 'react';
import {IonLoading, IonAlert, isPlatform, useIonRouter, setupIonicReact} from '@ionic/react';
import {menuController} from '@ionic/core/components';
import {ScreenOrientation} from '@ionic-native/screen-orientation';
import {Network} from '@capacitor/network';
import {App as Plugin} from '@capacitor/app';
import moment from 'moment';
import {usePostHog} from 'posthog-js/react';
import {Device} from '@capacitor/device';
import {AndroidPermissions} from '@awesome-cordova-plugins/android-permissions';

import {routes} from 'src/router/config';
import {useAppSelector, useAppDispatch} from 'src/hooks';
import {getStoreEmployeesCount, getStoreStatus} from 'src/store/storeSlice';
import {hideAlert, showAlert} from 'src/store/alertSlice';
import {
  setToken,
  getProfile,
  setHideWelcome,
  logoutUser,
  setInternetAvailable,
  setMenuAvailable,
  refreshAccessToken,
} from 'src/store/authSlice';
import {fetchCategoriesAndSubcategories} from 'src/store/categorySlice';
import {getNewIncomingOrders} from 'src/store/orderSlice';
import {
  checkWasConnected,
  getPrinterEmulation,
  getPrinterModel,
  getPrinterPort,
  getShouldPrintOrder,
  setHasBluetoothPermission,
} from 'src/store/printerSlice';
import {setAppVersion, setDeviceInfo, setIsDesktop, setIsMobile, setIsTablet} from 'src/store/platformSlice';
import {store} from 'src/store';
import {getItem} from 'src/utils/storage';
import SocketHelper from 'src/utils/socket';
import {customMenuAnimation} from 'src/utils/helpers';
import NewOrderModal from 'src/components/modals/NewOrder';
import NoStore from 'src/pages/Store/NoStore';
import Screens from 'src/router/Router';
import {PrinterService} from 'src/services/printer';
import {PermissionsService} from 'src/services/permission';
import {PowerService} from 'src/services/power';
import {SetPlatform} from 'src/utils/platform';
import {orderNotification} from 'src/utils/order-notification';
import AnalyticsHelper from 'src/utils/segment';
import OneSignalHelper from 'src/utils/onesignal';
import firebaseCrashlytics from 'src/utils/firebase-crashlytics';
import {STORAGE} from 'src/enums/storage';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';

/* Custom styles */
import './App.scss';

setupIonicReact({});

const REFRESH_TOKEN_INTERVAL_TIME = 15 * 60 * 1000;
const ORDER_POLLING_INTERVAL_TIME = 1 * 60 * 1000;

const App = () => {
  const posthog = usePostHog();
  const router = useIonRouter();
  const dispatch = useAppDispatch();
  const {desktop, mobile, tablet} = SetPlatform();

  let orderPollingInterval: any = useRef(null);
  let refreshTokenInterval: any = useRef(null);

  const {isLoading} = useAppSelector((state) => state.loading);
  const {show, heading, message, disableBackdropDismiss} = useAppSelector((state) => state.alert);
  const {user, userStoresCount, internetAvailable} = useAppSelector((state) => state.auth);
  const {isNative, isAndroid, device} = useAppSelector((state) => state.platform);
  const {printer} = useAppSelector((state) => state.printer);
  
  const [loading, setLoading] = useState(true);

  const getToken = async () => await getItem(STORAGE.TOKEN);

  const OKHandler = () => {
    if (disableBackdropDismiss) {
      dispatch(logoutUser());
    }
  };

  const checkBluetoothPermission = async () => {
    const deviceInfo = await Device.getInfo();
    if (isNative && isAndroid && Number(deviceInfo.osVersion) < 12) {
      dispatch(setHasBluetoothPermission(true));
      return;
    }
    const permissions = [
      AndroidPermissions.PERMISSION.BLUETOOTH_CONNECT,
      AndroidPermissions.PERMISSION.BLUETOOTH_SCAN,
      AndroidPermissions.PERMISSION.BLUETOOTH,
    ];
    const permissionStatuses = await Promise.all(
      permissions.map((permission) => AndroidPermissions.checkPermission(permission)),
    );
    if (permissionStatuses.every((status) => status.hasPermission)) {
      dispatch(setHasBluetoothPermission(true));
      return;
    }
    const response = await AndroidPermissions.requestPermissions(permissions);

    if (response.hasPermission) {
      dispatch(setHasBluetoothPermission(true));
    } else {
      dispatch(
        showAlert({
          heading: 'Cannot connect to printer',
          message: 'Please allow Lula to access bluetooth in order to connect with printer.',
        }),
      );
    }
  };

  const getWakeLock = async () => {
    const p = new PermissionsService();
    await p.requestWakeLockPermission();
    await new PowerService().acquireScreenDimLock();
  };

  const fetchNewToken = async () => {
    const refreshToken = await getItem(STORAGE.REFRESH_TOKEN);
    if (refreshToken) {
      const lastFetched = await getItem(STORAGE.LAST_FETCHED);

      if (lastFetched) {
        const tokenExpirationHours = 10;
        const isTokenExpired = moment().diff(lastFetched, 'hours') >= tokenExpirationHours;

        if (isTokenExpired) {
          await dispatch(refreshAccessToken(refreshToken));
        }
      } else {
        await dispatch(refreshAccessToken(refreshToken));
      }
    }
  };

  const clearIntervals = () => {
    if (orderPollingInterval.current) clearInterval(orderPollingInterval.current);
    if (refreshTokenInterval.current) clearInterval(refreshTokenInterval.current);
  };

  const initializeApp = async () => {
    // Initializing OneSignal.
    await OneSignalHelper.init();

    // Setting current platform
    dispatch(setIsDesktop(desktop));
    dispatch(setIsMobile(mobile));
    dispatch(setIsTablet(tablet));
    await dispatch(setDeviceInfo());
    if (isNative) await dispatch(setAppVersion());

    // Custom animation for app side menu
    menuController.registerAnimation('custom-push', customMenuAnimation);

    // Only exit from app when there is no route in navigation history.
    document.addEventListener('backbutton', (ev) => {
      if (!router.canGoBack()) Plugin.exitApp();
    });

    // Only show welcome screen first time a user uses an app.
    const hide = await getItem(STORAGE.HIDE_WELCOME);
    if (hide) dispatch(setHideWelcome(hide));

    // Checking internet connection on app start
    const internet = await Network.getStatus();
    dispatch(setInternetAvailable(internet?.connected));

    // Show alert when internet connectivity change
    await Network.addListener('networkStatusChange', (status) => {
      dispatch(setInternetAvailable(status.connected));
    });

    // Check if we need to refresh the access token when user refreshes/reopens the app.
    await fetchNewToken();

    // Checking user token.
    await getToken().then(async (token) => {
      if (token) {
        dispatch(setToken(token));
        if (internet?.connected) {
          await dispatch(getProfile()).then(() => {
            if (router.routeInfo.pathname === '/') {
              setTimeout(async () => {
                dispatch(setMenuAvailable(true));
              }, 5000);
            } else dispatch(setMenuAvailable(true));
          });
          // if (user && user?.role?.name !== ROLES.LIMITED_USER) {
          await dispatch(fetchCategoriesAndSubcategories());
          // }
          await dispatch(getStoreEmployeesCount());
          // TODO: Activate this code when we have store pause schedules.
          // await dispatch(getStorePauseSchedules());
          await dispatch(getStoreStatus());
        }
      }
    });

    // Lock app in portrait mode on mobile.
    if (isNative && !isPlatform('ipad' || 'phablet' || 'tablet')) {
      ScreenOrientation.lock(ScreenOrientation.ORIENTATIONS.PORTRAIT);
    }

    // Initialize Firebase Crashlytics.
    firebaseCrashlytics.initialize();
    if (user) firebaseCrashlytics.setUserIdentifier(user);

    // Pause new order notification on any activity.
    setTimeout(() => {
      document.addEventListener('mousemove', () => orderNotification.pause());
      document.addEventListener('scroll', () => orderNotification.pause());
      document.addEventListener('keydown', () => orderNotification.pause());
      document.addEventListener('click', () => orderNotification.pause());
      document.addEventListener('touchstart', () => orderNotification.pause());
    }, 500);

    // Display the app.
    setLoading(false);
  };

  useEffect(() => {
    initializeApp();

    // Cleanup function.
    return () => {
      SocketHelper.disconnectSocket();
      clearIntervals();
      new PowerService().releaseWakeLock();
    };
  }, []);

  const loadPrinterData = () => {
    dispatch(getShouldPrintOrder());
    dispatch(checkWasConnected());
    dispatch(getPrinterEmulation());
    dispatch(getPrinterModel());
    dispatch(getPrinterPort());
  };

  useEffect(() => {
    if (printer.hasBluetoothPermission) {
      loadPrinterData();
      setTimeout(async () => {
        const printerService = PrinterService.getInstance();
        const printer = store.getState()?.printer?.printer;
        if (printer && printer.emulation && printer.portName) {
          printerService.connectPrinter();
        }
        if (printer && printer.emulation && (!printer.portName || printer.portName === '')) {
          const portName = await printerService.autoSelectPrinter();
          if (portName === '') {
            printerService.connectPrinter();
          }
        }
        printerService.startPrintingInterval();
      }, 5000);
      return () => {
        PrinterService.getInstance().stopPrintingInterval();
        PrinterService.getInstance().disconnect();
      };
    } else {
      checkBluetoothPermission();
    }
  }, [printer.hasBluetoothPermission]);

  useEffect(() => {
    if (user) {
      OneSignalHelper.subscribe(dispatch, user, device);
      posthog?.identify(user.email, {email: user.email});

      // Tablet settings.
      if (isNative) {
        getWakeLock();
      }
      // Order polling.
      if (!orderPollingInterval.current) {
        orderPollingInterval.current = setInterval(() => {
          dispatch(getNewIncomingOrders());
        }, ORDER_POLLING_INTERVAL_TIME);
      }
      // Checking if we need to refresh the access token or not.
      // Only register for RBAC users.
      if (!refreshTokenInterval.current && user.isRBACUser) {
        // Register interval to periodically check for access token refresh.
        refreshTokenInterval.current = setInterval(() => {
          fetchNewToken();
        }, REFRESH_TOKEN_INTERVAL_TIME);
      }
    } else if (!user) {
      clearIntervals();
      new PowerService().releaseWakeLock();
    }

    return () => {
      clearIntervals();
      new PowerService().releaseWakeLock();
    };
  }, [user?.id]);

  // Show Alert if internet is not available
  useEffect(() => {
    if (internetAvailable) {
      AnalyticsHelper.trackAppIsConnectedToInternet();
      if (show) {
        dispatch(hideAlert());
      }
    } else {
      AnalyticsHelper.trackAppIsNotConnectedToInternet();
      dispatch(
        showAlert({
          heading: 'No Internet!',
          message: 'Please refresh app after checking your internet connection.',
          disableBackdropDismiss: true,
        }),
      );
    }
  }, [internetAvailable]);

  return (
    <>
      <IonLoading isOpen={isLoading} message="Please wait..." />
      <IonAlert
        isOpen={show}
        onDidDismiss={() => {
          if (!disableBackdropDismiss) {
            dispatch(hideAlert());
          }
        }}
        header={heading}
        message={message}
        buttons={internetAvailable ? [{text: 'OK', handler: OKHandler}] : []}
        backdropDismiss={!disableBackdropDismiss}
      />
      <NewOrderModal />
      {loading ? (
        <IonLoading isOpen={loading} message="Please wait..." />
      ) : userStoresCount === 0 && !!user?.id ? (
        <NoStore />
      ) : (
        <Screens routes={routes} />
      )}
    </>
  );
};

export default App;
