import {makeRequest} from 'src/Axios';

const fileService = {
  uploadFiles: async (data: any) => {
    try {
      const response: any = await makeRequest({
        url: '/inventory/image-enrichment/upload',
        method: 'POST',
        body: data,
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error?.response?.data;
    }
  },
};

export default fileService;
