import {Desktop, Tablet, Mobile} from 'src/utils/platform';
import {RouteComponentProps} from 'react-router';
import MobileScreen from './Mobile';
import TabScreen from './Tablet';
import DesktopScreen from './Desktop';

interface IProps extends RouteComponentProps {}

const Welcome = ({history}: IProps) => {
  const goToLogin = () => history.replace('/auth/login');
  const goToOnboarding = () => history.replace('/onboarding');

  return (
    <>
      <Desktop>
        <DesktopScreen {...{goToLogin}} />
      </Desktop>
      <Tablet>
        <TabScreen {...{goToLogin, goToOnboarding}} />
      </Tablet>
      <Mobile>
        <MobileScreen {...{goToOnboarding}} />
      </Mobile>
    </>
  );
};

export default Welcome;
