/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect} from 'react';
import {IonPage, IonContent, IonBackButton, IonButton, IonImg, IonIcon, IonCheckbox, IonFooter} from '@ionic/react';
import './styles.scss';
import usePlacesService from 'react-google-autocomplete/lib/usePlacesAutocompleteService';
import {RouteComponentProps} from 'react-router';
import {useForm} from 'react-hook-form';
import {closeOutline} from 'ionicons/icons';
import Header from 'src/components/Header';
import Input from 'src/components/Input';
import Select from 'src/components/Select';
import {IShippingDetailFormInput} from 'src/interfaces/form';
import storeService from 'src/services/store';
import {useAppDispatch, useAppSelector, useEffectOnce} from 'src/hooks';
import {showLoading, hideLoading} from 'src/store/loadingSlice';
import {showAlert} from 'src/store/alertSlice';
import {getAddressDetails, checkPermission} from 'src/utils/helpers';
import {setShippingAddress} from 'src/store/storeSlice';
import {RULES} from 'src/utils/constants';
import * as PERMISSIONS from 'src/utils/permissions';
import packageImg from 'src/assets/images/welcome-package.svg';
import arrowBack from 'src/assets/images/arrow-back-black.svg';
import {STATES} from 'src/utils/states';

interface IProps extends RouteComponentProps {
  setShowWelcomePackage?: (show: boolean) => void;
}

const WelcomePackage = ({setShowWelcomePackage}: IProps) => {
  const dispatch = useAppDispatch();
  const {isDesktop, isTablet} = useAppSelector((state) => state.platform);
  const {menuIsOpen, user} = useAppSelector((state) => state.auth);
  const {id: storeId} = useAppSelector((state) => state.store);

  const [isSame, setIsSame] = useState(false);

  const canEditDetails = checkPermission(user?.role.permissions, PERMISSIONS.EDIT_STORE_SHIPPING_DETAILS) && !isSame;

  const {control, handleSubmit, setValue, getValues, reset} = useForm<IShippingDetailFormInput>({
    mode: 'onSubmit',
    defaultValues: {
      address: '',
      city: '',
      state: '',
      zipCode: '',
    },
  });

  const {placesService, placePredictions, getPlacePredictions} = usePlacesService({
    apiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
    debounce: 200,
  });

  const [storeAddress, setStoreAddress] = useState({id: '', line_1: '', city: '', state: '', zip: ''});
  const [addressPredictions, setPredictions] = useState<Array<any>>();
  const [showPredictions, setShowPredictions] = useState(false);

  const clearInputs = () => {
    reset();
  };

  const fetchStoreDetails = () => {
    if (storeAddress.line_1 && storeAddress.city && storeAddress.state && storeAddress.zip) {
      setValue('address', storeAddress?.line_1);
      setValue('city', storeAddress?.city);
      setValue('state', storeAddress?.state);
      setValue('zipCode', storeAddress?.zip);
    } else {
      dispatch(showLoading());
      storeService
        .getStoreAddress(storeId)
        .then((address: any) => {
          dispatch(hideLoading());
          setStoreAddress({...address});
          setValue('address', address?.line_1);
          setValue('city', address?.city);
          setValue('state', address?.state);
          setValue('zipCode', address?.zip);
        })
        .catch((error) => {
          dispatch(hideLoading());
          dispatch(
            showAlert({
              heading: 'Unable to fetch data',
              message: error?.message || 'We are unable to fetch store data at this moment. Please, try again later',
            }),
          );
        });
    }
  };

  const fetchPackageAddress = () => {
    dispatch(showLoading());
    storeService
      .getWelcomePackageAddress(storeId)
      .then(({id, address, sameAsStoreAddress}) => {
        dispatch(hideLoading());
        if (address) {
          setStoreAddress({...address});
          setIsSame(sameAsStoreAddress);
          setValue('address', address?.line_1);
          setValue('city', address?.city);
          setValue('state', address?.state);
          setValue('zipCode', address?.zip);
        }
      })
      .catch((error) => {
        dispatch(hideLoading());
        dispatch(
          showAlert({
            heading: 'Unable to fetch data',
            message: error?.message || 'We are unable to fetch store data at this moment. Please, try again later',
          }),
        );
      });
  };

  const saveDetails = (formData: IShippingDetailFormInput) => {
    const data = {
      id: storeAddress.id,
      line_1: formData.address,
      city: formData.city,
      state: formData.state,
      zip: formData.zipCode,
      address_type: 1,
      same_as_store_address:
        storeAddress.line_1 === formData.address &&
        storeAddress.city === formData.city &&
        storeAddress.state === formData.state &&
        storeAddress.zip === formData.zipCode,
    };
    storeService
      .updateAddress(storeId, data)
      .then((_) => {
        dispatch(
          showAlert({
            heading: 'Successfully saved',
            message: 'Welcome package address saved successfully.',
          }),
        );
        dispatch(setShippingAddress({shippingAddress: data}));
      })
      .catch((error) => {
        dispatch(
          showAlert({
            heading: 'Unable to save data',
            message: error?.message || 'We are unable to save data at this moment. Please, try again later',
          }),
        );
      });
  };

  const addDetails = (formData: IShippingDetailFormInput) => {
    const data = {
      line_1: formData.address,
      city: formData.city,
      state: formData.state,
      zip: formData.zipCode,
      address_type: 1,
      same_as_store_address:
        storeAddress.line_1 === formData.address &&
        storeAddress.city === formData.city &&
        storeAddress.state === formData.state &&
        storeAddress.zip === formData.zipCode,
    };
    storeService
      .addAddress(storeId, data)
      .then((_) => {
        dispatch(
          showAlert({
            heading: 'Successfully added',
            message: 'Welcome package address added successfully.',
          }),
        );
      })
      .catch((error) => {
        dispatch(
          showAlert({
            heading: 'Unable to add',
            message: error?.message || 'We are unable to add data at this moment. Please, try again later',
          }),
        );
      });
  };

  const onTypingAddress = (value: any) => {
    setValue('address', value);
    getPlacePredictions({input: value, componentRestrictions: {country: 'US'}, types: ['address']});
    const data = placePredictions.map((item) => ({description: item.description, placeID: item.place_id}));

    setPredictions(data);
    if (placePredictions.length > 0 && getValues('address').length > 0) {
      setShowPredictions(true);
    } else {
      setShowPredictions(false);
    }
  };

  const onClickAddress = (id: string) => {
    placesService?.getDetails({placeId: id, fields: ['address_components']}, (placeDetails: any) => {
      const {streetAddress, addressCity, addressState, postalCode} = getAddressDetails(placeDetails);
      if (streetAddress) setValue('address', streetAddress);
      if (addressCity) setValue('city', addressCity);
      if (addressState) setValue('state', addressState);
      if (postalCode) setValue('zipCode', postalCode);
      setShowPredictions(false);
    });
  };

  const onClose = () => {
    if (setShowWelcomePackage) setShowWelcomePackage(false);
  };

  useEffect(() => {
    if (isSame) fetchStoreDetails();
    else clearInputs();
  }, [isSame]);

  useEffectOnce(() => {
    fetchPackageAddress();
  });

  return (
    <IonPage id="manage-welcome-package-page" className={`${!menuIsOpen ? 'menu-close' : ''}`}>
      <IonContent fullscreen scrollY={false} className={`${isTablet ? 'page-container' : ''}`}>
        <Header
          noBackground
          title="Shipping Details"
          leftButton={!isDesktop && <IonBackButton defaultHref="/store/manage" text="" icon={arrowBack} />}
          rightButton={
            isDesktop && (
              <IonButton onClick={onClose}>
                <IonIcon src={closeOutline} />
              </IonButton>
            )
          }
        />

        <div className={`body ${canEditDetails ? 'with-footer' : ''}`}>
          <div className="img-container">
            <IonImg src={packageImg} />
          </div>
          <div className="checkbox-container">
            <div className="checkbox">
              <IonCheckbox checked={isSame} onIonChange={(e) => setIsSame(e.detail.checked)} />
            </div>
            <div className="text">
              <p>Same as store address</p>
            </div>
          </div>

          <Input
            type="text"
            name="address"
            placeholder="Address"
            label="Address"
            control={control}
            disabled={!canEditDetails}
            rules={RULES.required}
            onTypingAddress={onTypingAddress}
            addressPredictions={addressPredictions}
            onClickAddress={onClickAddress}
            showPredictions={showPredictions}
          />

          <Input
            type="text"
            name="city"
            label="City"
            disabled={!canEditDetails}
            placeholder="City"
            control={control}
            rules={RULES.required}
          />

          <div className="state-zip-container">
            <Select
              wrapperClassName="state-select"
              name="state"
              label="State"
              rules={RULES.required}
              control={control}
              options={Object.keys(STATES).map((s) => ({id: s, name: s}))}
              disabled={!canEditDetails}
            />

            <Input
              wrapperClassName="zip-code-input"
              type="number"
              label="ZIP-code"
              inputmode="numeric"
              name="zipCode"
              disabled={!canEditDetails}
              placeholder="Zip-code"
              control={control}
              rules={RULES.required}
            />
          </div>
        </div>
        {canEditDetails && (
          <IonFooter>
            <IonButton
              className="btn-dark"
              expand="block"
              onClick={handleSubmit(storeAddress?.id ? saveDetails : addDetails)}>
              Submit
            </IonButton>
          </IonFooter>
        )}
      </IonContent>
    </IonPage>
  );
};

export default WelcomePackage;
