import {IonButton, IonCheckbox, IonIcon, IonItem, IonLabel} from '@ionic/react';
import {Check} from 'react-feather';
import {useHistory} from 'react-router';
import {IQueryObject} from 'src/interfaces/item';
import {IMenuList} from 'src/interfaces/store';
import './styles.scss';
import {useAppSelector} from 'src/hooks';
import {checkPermission} from 'src/utils/helpers';
import * as PERMISSIONS from 'src/utils/permissions';

interface IProps {
  menuList: Array<IMenuList>;
  filterObject?: IQueryObject;
  selectFilter?: (e: any) => void;
  isDesktop: boolean;
  isFilter: boolean;
  onNewMenuClick: () => void;
  selectedMenu?: Array<string>;
  setMenu?: (e: string) => void;
  isBulkEditing?: boolean;
}

const MenuFilter = (props: IProps) => {
  const history = useHistory();
  const {isDesktop} = useAppSelector((state) => state.platform);
  const {user} = useAppSelector((state) => state.auth);
  const canCreateMenu = checkPermission(user?.role.permissions, PERMISSIONS.CREATE_MENU);

  const selectMenuFilter = (filterObject: IQueryObject, menu: IMenuList, all = false) => {
    let newMenus: string[];
    if (all) {
      newMenus = [];
    } else {
      newMenus = [...filterObject.menuList];
      if (newMenus.includes(menu.id)) newMenus = newMenus.filter((item) => item !== menu.id);
      else newMenus.push(menu.id);
    }
    if (props.selectFilter) props.selectFilter({menuList: newMenus});
  };

  const newMenuClick = () => {
    if (isDesktop || props.isBulkEditing) {
      props.onNewMenuClick();
    } else {
      history.push({pathname: '/store/manage/menu/details', state: {menuDetailId: ''}});
    }
  };

  const getDefaultMenuId = () => props.menuList.find((menu) => menu.is_default)?.id || '';

  return props.isDesktop === true ? (
    <div className="filter-group-grid">
      {props.menuList.map((menu, index) => {
        const isSelected = props.isFilter
          ? props.filterObject
            ? !!props.filterObject.menuList.length && props.filterObject.menuList.includes(menu.id)
            : false
          : props.selectedMenu
          ? !!props.selectedMenu.length && props.selectedMenu.includes(menu.id)
          : false;
        return (
          <IonItem key={index}>
            <IonCheckbox
              className="select-check"
              checked={isSelected}
              disabled={
                props.selectedMenu &&
                props.selectedMenu.length === 1 &&
                props.selectedMenu.includes(getDefaultMenuId()) &&
                isSelected &&
                !props.isBulkEditing &&
                !props.isFilter
              }
              onClick={() => {
                if (props.isFilter && props.filterObject) {
                  selectMenuFilter(props.filterObject, menu);
                } else if (!props.isFilter) {
                  if (props.setMenu) props.setMenu(menu.id);
                }
              }}
            />
            <IonLabel className={`ion-text-wrap ${isSelected ? 'selected' : ''}`}>{menu.menu_name}</IonLabel>
          </IonItem>
        );
      })}
      {canCreateMenu && (
        <IonItem onClick={newMenuClick}>
          <IonIcon className="icon-add" slot="start" />
          <IonLabel>New Menu</IonLabel>
        </IonItem>
      )}
    </div>
  ) : (
    <div className="menu-row">
      {props.menuList.map((menuList, index) => {
        const isSelected = props.isFilter
          ? props.filterObject
            ? !!props.filterObject.menuList.length && props.filterObject.menuList.includes(menuList.id)
            : false
          : true;
        return (
          <IonButton
            key={index}
            className={isSelected ? 'ion-button-selected' : ''}
            onClick={() => {
              if (props.isFilter && props.filterObject) {
                selectMenuFilter(props.filterObject, menuList);
              }
            }}>
            {isSelected ? (
              <span className="check-selected">
                <Check />
                {menuList.menu_name}
              </span>
            ) : (
              <span className="main-text">{menuList.menu_name}</span>
            )}
          </IonButton>
        );
      })}
      {canCreateMenu && (
        <IonButton onClick={newMenuClick}>
          <IonIcon className="icon-add" slot="start" />
          New Menu
        </IonButton>
      )}
    </div>
  );
};
export default MenuFilter;
