import {IonButton} from '@ionic/react';
import './styles.scss';
import {useAppDispatch, useAppSelector} from 'src/hooks';
import {setExceptItems, setSelectedCategory, setSelectedItems, setNewMenuId} from 'src/store/inventorySlice';

interface IProps {
  setShowDeleteModal: (e: boolean) => void;
  setShowAvailabilityModal: (e: boolean) => void;
  setShowEditModal: (e: boolean) => void;
  selectedItemsLength: number;
  setIsEditable: (e: boolean) => void;
  setShowCategoryModal: (e: boolean) => void;
  setShowPriceModal: (e: boolean) => void;
  setShowMenuModal: (e: boolean) => void;
  canEdit?: boolean;
  canDelete?: boolean;
}

const ItemEditOption = (props: IProps) => {
  const dispatch = useAppDispatch();
  const {isMobile, isTablet} = useAppSelector((state) => state.platform);

  return (
    <div id="item-edit-option-container">
      <p>
        <span>{props.selectedItemsLength || 0}</span> items selected
      </p>
      <div className="edit-btns">
        {!(isMobile || isTablet) && (
          <>
            {props.canEdit && (
              <>
                <IonButton
                  size="small"
                  mode="ios"
                  fill="outline"
                  color="dark"
                  expand="block"
                  className="edit-btn"
                  disabled={props.selectedItemsLength > 0 ? false : true}
                  onClick={() => {
                    props.setShowAvailabilityModal(true);
                  }}>
                  Availability
                </IonButton>
                <IonButton
                  size="small"
                  mode="ios"
                  fill="outline"
                  color="dark"
                  expand="block"
                  className="edit-btn"
                  disabled={props.selectedItemsLength > 0 ? false : true}
                  onClick={() => {
                    props.setShowCategoryModal(true);
                  }}>
                  Category
                </IonButton>
                <IonButton
                  size="small"
                  mode="ios"
                  fill="outline"
                  color="dark"
                  expand="block"
                  className="edit-btn"
                  disabled={props.selectedItemsLength > 0 ? false : true}
                  onClick={() => {
                    props.setShowPriceModal(true);
                  }}>
                  Price
                </IonButton>
                <IonButton
                  size="small"
                  mode="ios"
                  fill="outline"
                  color="dark"
                  expand="block"
                  className="edit-btn"
                  disabled={props.selectedItemsLength > 0 ? false : true}
                  onClick={() => {
                    dispatch(setNewMenuId(''));
                    props.setShowMenuModal(true);
                  }}>
                  Menu
                </IonButton>
              </>
            )}
            {props.canDelete && (
              <IonButton
                size="small"
                mode="ios"
                fill="outline"
                color="danger"
                expand="block"
                className="edit-btn"
                disabled={props.selectedItemsLength > 0 ? false : true}
                onClick={() => {
                  props.setShowDeleteModal(true);
                }}>
                Delete
              </IonButton>
            )}
          </>
        )}
        <IonButton
          size="small"
          mode="ios"
          color="dark"
          fill="clear"
          className="edit-cancel-btn"
          onClick={() => {
            dispatch(setSelectedItems([]));
            dispatch(setSelectedCategory([]));
            dispatch(setExceptItems([]));
            dispatch(props.setIsEditable(false));
          }}>
          Cancel
        </IonButton>
        {(isMobile || isTablet) && props.canEdit && (
          <IonButton
            className="edit-button"
            size="small"
            mode="ios"
            fill="solid"
            color="primary"
            expand="block"
            disabled={props.selectedItemsLength > 0 ? false : true}
            onClick={() => {
              props.setShowEditModal(true);
            }}>
            Edit items
          </IonButton>
        )}
      </div>
    </div>
  );
};

export default ItemEditOption;
