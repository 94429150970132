import {IonImg, IonButton, IonIcon} from '@ionic/react';
import './styles.scss';
import ItemImage from 'src/components/ItemImage';
import {IItem} from 'src/interfaces/item';
import itemSelected from 'src/assets/images/item-selected.svg';

interface IProps extends IItem {
  searchedItem?: boolean;
  onClick: () => void;
}

const CommonItem = ({image, name, category, selected, searchedItem, storeItemId, onClick}: IProps) => (
  <div id="common-item">
    <div className="img-container">
      <ItemImage category={category?.name} image={image} />
    </div>
    <div className="details-container">
      <p className="name">{name}</p>
      <p className="category">{searchedItem ? `In "${category?.name}"` : category?.name}</p>
    </div>
    <div className="action-container">
      <IonButton fill="clear" onClick={onClick}>
        {!storeItemId && !selected && <IonIcon className="icon-add" />}
        {!!storeItemId && <IonIcon className="icon-remove" />}
        {selected && <IonImg src={itemSelected} />}
      </IonButton>
    </div>
  </div>
);

export default CommonItem;
