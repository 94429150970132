import {IModifierGroup, IModifierItem} from 'src/interfaces/item';
import './styles.scss';
import {IonIcon, IonLabel, IonNote} from '@ionic/react';
import {chevronForwardOutline} from 'ionicons/icons';
import {useAppSelector} from 'src/hooks';
import {useHistory} from 'react-router';
import {ITEM_TYPE} from 'src/enums/item_type';
import {MODIFIER_GROUP_CONSTRAINT, MODIFIER_ITEM_STATUS} from 'src/enums/item_status';

interface IModifiersList {
  className?: string;
  modifiersListItems: IModifierGroup[];
}

interface IModifiersGroupItem {
  className?: string;
  modifierGroupItem: IModifierGroup;
}

const ModifiersGroupItem = ({className, modifierGroupItem}: IModifiersGroupItem) => {
  const {modifierGroupId, modifierGroupName, items, maxQuantity, minQuantity, isAvailable} = modifierGroupItem;
  const {isDesktop} = useAppSelector((state) => state.platform);
  const history = useHistory();

  const onModifierItemClick = (modifierItemId: string) => {
    // TODO: Implement recursive open modifier item details on desktop.
    if (!isDesktop) {
      history.push({pathname: '/items/details', state: {id: modifierItemId, type: ITEM_TYPE.EDIT}});
    }
  };

  const getModifierGroupConstraint = () => {
    const constraint = minQuantity !== 0 ? MODIFIER_GROUP_CONSTRAINT.REQUIRED : MODIFIER_GROUP_CONSTRAINT.OPTIONAL;
    const rules = [minQuantity > 0 && `Min ${minQuantity}`, maxQuantity > 0 && `Max ${maxQuantity}`]
      .filter(Boolean)
      .join(' - ');
    return `${constraint} ${rules ? `(${rules})` : ''}`;
  };

  return (
    <div id="modifier-group" key={modifierGroupId} className={className ? className : ''}>
      <IonLabel className="modifier-group-title">
        <div className="modifier-group-name">
          <h4>{modifierGroupName}</h4>
          {!isAvailable ? (
            <div className="unavailable-tag">
              <span>Unavailable</span>
            </div>
          ) : null}
        </div>
        <IonNote>{getModifierGroupConstraint()}</IonNote>
      </IonLabel>
      <div className="modifier-group-list">
        {items?.map((modifierItem: IModifierItem, index: number) => {
          const isOutOfStock = modifierItem.modifierItemStatus === MODIFIER_ITEM_STATUS.INACTIVE;
          return (
            <div
              className={`modifier-list-option ${isOutOfStock ? 'out-of-stock-modifier' : ''}`}
              key={index}
              id={modifierItem.modifierItemId}
              onClick={() => onModifierItemClick(modifierItem.storeItemId)}>
              <div className="modifier-details">
                <div className="name">
                  {modifierItem.modifierItemName}
                  {isOutOfStock && (
                    <div className="out-of-stock-tag">
                      <span>out of stock</span>
                    </div>
                  )}
                </div>

                {modifierItem.unitPrice > 0 && (
                  <div className="price">+ ${parseFloat(modifierItem.unitPrice.toString()).toFixed(2)}</div>
                )}
              </div>
              <IonIcon icon={chevronForwardOutline} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

const ModifiersList = ({className, modifiersListItems}: IModifiersList) => {
  return (
    <div id="modifiers-list" className={className ? className : ''}>
      <IonLabel className="modifier-section-title">
        <h2>Modifiers</h2>
        <IonNote>Modifier groups allow customers to use toppings, sides and more to customize items.</IonNote>
      </IonLabel>
      <div className="modifiers-list-item">
        {modifiersListItems?.map((modifierGroupItem: IModifierGroup, index: number) => {
          return <ModifiersGroupItem key={index} modifierGroupItem={modifierGroupItem} />;
        })}
      </div>
    </div>
  );
};

export default ModifiersList;
