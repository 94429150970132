import moment from 'moment';
import {makeRequest} from 'src/Axios';
import {INPUT_MASK} from 'src/enums/input_mask';
import {nonRBACRoles} from 'src/utils/constants';
import {maskValue} from 'src/utils/helpers';
import {ROLE_PERMISSIONS} from 'src/utils/role-permissions';

const userService = {
  login: async (data: any) => {
    try {
      const response: any = await makeRequest({
        url: '/user-service/authenticate',
        method: 'POST',
        body: data,
        isMicroservice: true,
      });
      const {new_password_required} = response.data;

      if (new_password_required) {
        const {auth_token} = response.data;
        return {new_password_required: new_password_required || false, token: auth_token};
      } else {
        const {is_rbac_user} = response.data;
        if (is_rbac_user) {
          const {accessToken, refreshToken, idToken} = response.data;
          return {
            token: `Bearer ${accessToken}`,
            refreshToken,
            idToken,
            lastFetched: moment().format(),
            new_password_required: false,
            isRBACUser: true,
          };
        } else {
          const {token_response} = response.data;
          return {token: token_response.token, refreshToken: '', lastFetched: '', isRBACUser: false};
        }
      }
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  refreshToken: async (token: string | null | undefined) => {
    try {
      const response: any = await makeRequest({
        url: '/user-service/refresh_token',
        headers: {refreshtoken: token},
        isMicroservice: true,
      });
      const {accessToken, idToken} = response.data;
      return {accessToken: `Bearer ${accessToken}`, lastFetched: moment().format(), idToken};
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  sendToken: async () => {
    try {
      const response: any = await makeRequest({url: '/user-service/resend-otp', method: 'POST', isMicroservice: true});
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  sendOTP: async (email: string) => {
    try {
      const params: any = {};
      const body = {
        email: email,
      };
      const response: any = await makeRequest({
        url: `/user-service/send-otp`,
        method: 'POST',
        body: body,
        params: params,
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  verifyToken: async (data: {code: string}) => {
    try {
      const response: any = await makeRequest({
        url: '/user-service/verify-otp',
        method: 'POST',
        body: data,
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  resetPassword: async (data: any) => {
    try {
      const response: any = await makeRequest({
        url: '/user-service/change-password',
        method: 'POST',
        body: data,
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  forgotPassword: async (data: any) => {
    try {
      const response: any = await makeRequest({
        url: '/user-service/forgot-password',
        method: 'POST',
        body: data,
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getProfile: async () => {
    try {
      const response: any = await makeRequest({url: '/user-service/fetch_profile', isMicroservice: true});
      const user = response.data;

      if (!user.employees && user.is_rbac_user) {
        return {
          id: user.id,
          username: user.user_name,
          email: user.email,
          phone: user.phone,
          firstName: user.first_name,
          lastName: user.last_name,
          role: user.roles[0],
          passwordLastChanged: user.last_password_changed,
          isEmailVerified: user.is_email_verified,
          createdAt: user.created_at,
          managerId: user.manager_id,
          isRBACUser: user.is_rbac_user,
        };
      } else {
        return {
          id: user.employees.id,
          username: user.employees.cognito_user_name,
          email: user.employees.email,
          phone: user.employees.phone_number,
          firstName: user.employees.first_name,
          lastName: user.employees.last_name,
          role: {
            id: null,
            name: user.employees.employee_role.name,
            icon: null,
            color: null,
            permissions: ROLE_PERMISSIONS[user.employees.employee_role.name],
          },
          passwordLastChanged: user.employees.date_password_changed,
          isEmailVerified: user.employees.is_email_verified,
          createdAt: user.employees.createdAt,
          managerId: user.employees.manager_id,
          isRBACUser: false,
        };
      }
    } catch (error: any) {
      throw error?.response?.data;
    }
  },
  getAllEmployees: async (storeId: string, params: {search_string?: string; offset: number; is_rbac_user: boolean}) => {
    try {
      const response: any = await makeRequest({
        url: `/user-service/store/${storeId}/users`,
        params: {...params, limit: 20},
        isMicroservice: true,
      });
      return {
        count: response.data.count,
        users: response.data.users.map((employee: any) => ({
          id: employee.id,
          firstName: employee.first_name,
          lastName: employee.last_name,
          email: employee.email,
          phone: maskValue(INPUT_MASK.DISPLAY_PHONE, employee.phone_number),
          cognitoUsername: employee.user_name,
          role: params.is_rbac_user ? employee.role : nonRBACRoles.find((r) => r.id === employee.role.name),
        })),
      };
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getEmployeeByCognitoUsername: async (userId: string) => {
    try {
      const response: any = await makeRequest({url: `/user-service/find-by-user-name/${userId}`, isMicroservice: true});
      const user = response.data;
      return {
        id: user.id,
        username: user.username,
        firstName: user.first_name,
        lastName: user.last_name,
        email: user.email,
        phone: user.phone,
        role: user.roles[0],
      };
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  updateProfile: async (id: string, data: any) => {
    try {
      const response: any = await makeRequest({
        url: `/user-service/update_profile/${id}`,
        method: 'PUT',
        body: data,
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  addEmployee: async (data: any) => {
    try {
      const response: any = await makeRequest({
        url: '/user-service/add_employee',
        method: 'POST',
        body: data,
        isMicroservice: true,
      });
      if (data.user_rbac) {
        return response.data;
      } else {
        return response.data[0].employee;
      }
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  updateEmployee: async (id: string, data: any) => {
    try {
      const response: any = await makeRequest({
        url: `/user-service/update_employee/${id}`,
        method: 'PUT',
        body: data,
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  deleteEmployee: async (id: string, data: any) => {
    try {
      const response: any = await makeRequest({
        url: `/user-service/delete_user/${id}`,
        method: 'DELETE',
        body: data,
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  logoutEmployee: async () => {
    try {
      const response: any = await makeRequest({url: '/user-service/logout', isMicroservice: true});
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getRoles: async () => {
    try {
      const response: any = await makeRequest({url: '/user-service/accessible-roles', isMicroservice: true});
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
};

export default userService;
