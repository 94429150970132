import './styles.scss';

interface IProps {
  message: string;
}

const NoItem = ({message}: IProps) => {
  return (
    <div id="no-item-container">
      <p className="no-item">{message}</p>
    </div>
  );
};

export default NoItem;
