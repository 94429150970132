import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {showLoading, hideLoading} from 'src/store/loadingSlice';
import {showAlert} from 'src/store/alertSlice';
import {RootState} from './index';
import {IItem} from 'src/interfaces/item';
import itemService from 'src/services/item';

interface ICommonItems {
  loading: boolean;
  loadingSearch: boolean;
  commonOffset: number;
  searchOffset: number;
  loadMoreCommon: boolean;
  loadMoreSearch: boolean;
  commonItems: Array<IItem>;
  searchItems: Array<IItem>;
}

export const fetchCommonItems = createAsyncThunk(
  'item/fetchCommonItems',
  async (_, {dispatch, fulfillWithValue, getState, rejectWithValue}) => {
    const {item} = getState() as RootState;
    const {commonOffset} = item;
    try {
      if (commonOffset === 0) dispatch(showLoading());
      else dispatch(setLoading(true));
      const items = await itemService.getCommonItems(commonOffset, '');
      if (commonOffset === 0) dispatch(hideLoading());
      else dispatch(setLoading(false));
      return fulfillWithValue(items);
    } catch (error: any) {
      if (commonOffset === 0) dispatch(hideLoading());
      else dispatch(setLoading(false));
      dispatch(
        showAlert({
          heading: 'Unable to fetch data',
          message: error?.message || 'Some error occurred while fetching data. Please, try again later',
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const fetchSearchedItems = createAsyncThunk(
  'item/fetchSearchedItems',
  async (searchTerm: any, {dispatch, fulfillWithValue, getState, rejectWithValue}) => {
    const {item} = getState() as RootState;
    const {searchOffset} = item;
    try {
      if (searchOffset === 0) dispatch(setLoadingSearch(true));
      else dispatch(setLoading(true));
      const items = await itemService.getCommonItems(searchOffset, searchTerm);
      if (searchOffset === 0) dispatch(setLoadingSearch(false));
      else dispatch(setLoading(false));
      return fulfillWithValue(items);
    } catch (error: any) {
      if (searchOffset === 0) dispatch(setLoadingSearch(false));
      else dispatch(setLoading(false));
      dispatch(
        showAlert({
          heading: 'Unable to fetch data',
          message: error?.message || 'Some error occurred while fetching data. Please, try again later',
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const fetchItemsOfCategory = createAsyncThunk(
  'item/fetchItemsOfCategory',
  async (category: any, {dispatch, fulfillWithValue, getState, rejectWithValue}) => {
    const {item} = getState() as RootState;
    const {commonOffset} = item;
    try {
      if (commonOffset === 0) dispatch(showLoading());
      else dispatch(setLoading(true));
      const items = await itemService.getCommonItems(commonOffset, '', category);
      if (commonOffset === 0) dispatch(hideLoading());
      else dispatch(setLoading(false));
      return fulfillWithValue(items);
    } catch (error: any) {
      if (commonOffset === 0) dispatch(setLoadingSearch(false));
      else dispatch(setLoading(false));
      dispatch(
        showAlert({
          heading: 'Unable to fetch data',
          message: error?.message || 'Some error occurred while fetching data. Please, try again later',
        }),
      );
      return rejectWithValue(error);
    }
  },
);

const initialState: ICommonItems = {
  loading: false,
  loadingSearch: false,
  commonOffset: 0,
  searchOffset: 0,
  loadMoreCommon: true,
  loadMoreSearch: true,
  commonItems: [],
  searchItems: [],
};

const itemSlice = createSlice({
  name: 'item',
  initialState,
  reducers: {
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
    setLoadingSearch: (state, action) => {
      state.loadingSearch = action.payload;
    },
    setCommonItems: (state, action) => {
      state.commonItems = action.payload;
    },
    setSearchedItems: (state, action) => {
      state.searchItems = action.payload;
    },

    setCommonOffset: (state, action) => {
      state.commonOffset = action.payload;
    },
    setSearchOffset: (state, action) => {
      state.searchOffset = action.payload;
    },

    setLoadMoreSearch: (state, action) => {
      state.loadMoreSearch = action.payload;
    },
    setLoadMoreCommon: (state, action) => {
      state.loadMoreCommon = action.payload;
    },
    clearItems: () => {
      return initialState;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCommonItems.fulfilled, (state, action) => {
        if ((action.payload as unknown as any[]).length < 20) state.loadMoreCommon = false;
        else state.commonOffset = state.commonOffset + 20;
        state.commonItems.push(...(action.payload as unknown as any[]));
      })
      .addCase(fetchCommonItems.rejected, (state) => {
        state.loadMoreCommon = false;
      })
      .addCase(fetchSearchedItems.fulfilled, (state, action) => {
        if ((action.payload as unknown as any[]).length < 20) state.loadMoreSearch = false;
        else state.searchOffset = state.searchOffset + 20;
        state.searchItems.push(...(action.payload as unknown as any[]));
      })
      .addCase(fetchItemsOfCategory.fulfilled, (state, action) => {
        if ((action.payload as unknown as any[]).length < 20) state.loadMoreCommon = false;
        else state.commonOffset = state.commonOffset + 20;
        state.commonItems.push(...(action.payload as unknown as any[]));
      });
  },
});

export const {
  setLoading,
  setLoadingSearch,
  setCommonItems,
  setSearchedItems,
  setCommonOffset,
  setSearchOffset,
  setLoadMoreSearch,
  setLoadMoreCommon,
  clearItems,
} = itemSlice.actions;

export default itemSlice.reducer;
