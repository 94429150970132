/* eslint-disable react-hooks/exhaustive-deps */
import {useState} from 'react';
import {IonPage, IonContent, IonBackButton, IonButton, IonIcon} from '@ionic/react';
import './styles.scss';
import {closeOutline} from 'ionicons/icons';
import {RouteComponentProps} from 'react-router';
import {useForm} from 'react-hook-form';
import VerifyCodeModal from 'src/components/modals/VerifyCode';
import Header from 'src/components/Header';
import Input from 'src/components/Input';
import TextWithLines from 'src/components/TextWithLines';
import userService from 'src/services/user';
import {useAppDispatch, useAppSelector, useEffectOnce} from 'src/hooks';
import {IUpdateEmailFormInput} from 'src/interfaces/form';
import {RULES} from 'src/utils/constants';
import {showAlert} from 'src/store/alertSlice';
import {updateProfile, verifyToken} from 'src/store/authSlice';
import {hideLoading, showLoading} from 'src/store/loadingSlice';
import arrowBack from 'src/assets/images/arrow-back-black.svg';
import * as PERMISSIONS from 'src/utils/permissions';

interface IProps extends RouteComponentProps {
  setShowEmail?: (show: boolean) => void;
}

const ChangeEmail = ({setShowEmail}: IProps) => {
  const dispatch = useAppDispatch();
  const {user, menuIsOpen} = useAppSelector((state) => state.auth);
  const {isDesktop, isTablet} = useAppSelector((state) => state.platform);
  const canEditInput = user?.role.permissions?.some((item: any) => item.name === PERMISSIONS.EDIT_ACCOUNT_DETAILS);

  const {control, handleSubmit, setValue} = useForm<IUpdateEmailFormInput>({
    mode: 'onSubmit',
    defaultValues: {
      oldEmail: 'jannike@bykira.com',
      email: '',
    },
  });

  const [open, setOpen] = useState(false);

  const updateEmail = (formData: IUpdateEmailFormInput) => {
    const data = {
      id: user?.id,
      email: formData.email,
    };
    dispatch(updateProfile(data));
  };

  const sendCode = async () => {
    await userService.sendToken();
    setOpen(true);
  };

  const validateCode = (code: string) => {
    dispatch(verifyToken({code})).then(() => {
      setOpen(false);
    });
  };

  const resendCode = () => {
    dispatch(showLoading());
    userService
      .sendToken()
      .then((_) => {
        dispatch(hideLoading());
        dispatch(
          showAlert({
            heading: 'Code Sent',
            message: 'We have sent you a verification code on your email.',
          }),
        );
      })
      .catch((error) => {
        dispatch(hideLoading());
        dispatch(
          showAlert({
            heading: 'Unable to send code',
            message: error?.message || 'Some error occurred while sending verification code. Please, try again later',
          }),
        );
      });
  };

  const onContinue = () => {
    setOpen(false);
  };

  const onClose = () => {
    if (setShowEmail) setShowEmail(false);
  };

  useEffectOnce(() => {
    setValue('oldEmail', user?.email || '');
  });

  return (
    <>
      <IonPage id="change-email-page" className={`${isTablet && !menuIsOpen ? 'menu-close' : ''} snow-background`}>
        <IonContent fullscreen scrollY={false} className={`${isTablet ? 'page-container' : ''}`}>
          <Header
            noBackground
            title="E-Mail"
            leftButton={!isDesktop && <IonBackButton defaultHref="/settings" text="" icon={arrowBack} />}
            rightButton={
              isDesktop && (
                <IonButton onClick={onClose}>
                  <IonIcon src={closeOutline} />
                </IonButton>
              )
            }
          />

          <div className="body">
            <Input disabled type="text" name="oldEmail" placeholder="E-Mail" control={control} />

            {canEditInput && (
              <>
                <TextWithLines text="Need to change?" />

                <p className="heading">Update e-mail Address</p>
                <p className="desc">A verification code will be sent.</p>

                <Input
                  type="email"
                  inputmode="email"
                  name="email"
                  placeholder="E-Mail"
                  control={control}
                  rules={RULES.email}
                />

                <IonButton className="btn-primary" expand="block" onClick={handleSubmit(updateEmail)}>
                  Next Step
                </IonButton>
              </>
            )}
            {!user?.isEmailVerified && (
              <IonButton className="btn-primary" expand="block" onClick={sendCode}>
                Verify your Email
              </IonButton>
            )}
          </div>
        </IonContent>
      </IonPage>

      <VerifyCodeModal
        isOpen={open}
        verified={user?.isEmailVerified || false}
        onContinue={onContinue}
        validateCode={validateCode}
        resendCode={resendCode}
        onClose={() => setOpen(false)}
      />
    </>
  );
};

export default ChangeEmail;
