/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect} from 'react';
import {Route, Redirect, useLocation, useHistory} from 'react-router-dom';
import {IonRouterOutlet, IonSplitPane, useIonRouter} from '@ionic/react';
import {menuController} from '@ionic/core/components';

import {IRoute} from './config';
import {useAppDispatch, useAppSelector} from 'src/hooks';
import SideMenu from 'src/components/SideMenu';
import {setMenuIsOpen} from 'src/store/authSlice';
import AnalyticsHelper from 'src/utils/segment';
import firebaseCrashlytics from 'src/utils/firebase-crashlytics';

interface IProps {
  routes: IRoute[];
}

const Router = ({routes}: IProps) => {
  const dispatch = useAppDispatch();
  const router = useIonRouter();
  const history = useHistory();
  const {pathname} = useLocation<any>();

  const {hideWelcome, menuAvailable, token} = useAppSelector((state) => state.auth);
  const {user} = useAppSelector((state) => state.auth);
  const {isMobile, isDesktop} = useAppSelector((state) => state.platform);

  useEffect(() => {
    AnalyticsHelper.identifyOnEveryPage();
    firebaseCrashlytics.log(history.location.pathname);
  }, []);

  useEffect(
    () =>
      history.listen((location) => {
        AnalyticsHelper.identifyOnEveryPage();
        AnalyticsHelper.groupStore();
        firebaseCrashlytics.log(history.location.pathname);
      }),
    [history],
  );

  const getRoute = (route: IRoute, props: any) => {
    return !route.neutral ? (
      !route.public ? (
        token ? (
          route.permissions?.some((item) =>
            user?.role.permissions?.map((permission: any) => permission.name).includes(item),
          ) &&
          route.component && <route.component {...props} />
        ) : hideWelcome === 'true' ? (
          <Redirect from={route.path} to="/auth/login" />
        ) : (
          <Redirect from={route.path} to="/welcome" />
        )
      ) : token ? (
        <Redirect
          from={route.path}
          to={{
            pathname: '/orders',
            state: {redirected: 'true'},
          }}
        />
      ) : (
        route.component && <route.component {...props} />
      )
    ) : (
      route.component && <route.component {...props} />
    );
  };

  const checkIfMenuIsOpen = async () => {
    const isOpen = await menuController.isOpen();
    if (isMobile) dispatch(setMenuIsOpen(isOpen));
    else {
      dispatch(setMenuIsOpen(true));
    }
  };

  useEffect(() => {
    if (!router.canGoBack() && router.routeInfo.routeAction !== 'pop') checkIfMenuIsOpen();
  }, [pathname]);

  return (
    <IonSplitPane when="md" contentId="main" className="snow-background">
      <SideMenu disabled={!token || !menuAvailable} />
      <IonRouterOutlet id="main">
        {routes.map((route: IRoute, index: number) => (
          <Route
            exact
            key={index}
            path={route.path}
            render={(props) => {
              return isDesktop ? !route.mobileOnly && getRoute(route, props) : getRoute(route, props);
            }}
          />
        ))}
        {/* Handle 404 case. */}
        <Route
          path=""
          render={() =>
            token ? (
              <Redirect
                to={{
                  pathname: '/orders',
                  state: {redirected: 'true'},
                }}
              />
            ) : (
              <Redirect to="/auth/login" />
            )
          }
        />
      </IonRouterOutlet>
    </IonSplitPane>
  );
};

export default Router;
