import {IonIcon, IonItem, IonLabel, IonFab, IonFabButton, IonFabList, isPlatform} from '@ionic/react';
import './styles.scss';
import {addOutline} from 'ionicons/icons';
import barcode from 'src/assets/images/barcode.svg';
import browseItems from 'src/assets/images/browse-items.svg';
import addItems from 'src/assets/images/add.svg';
import getHelp from 'src/assets/images/get-help.svg';
import {IItem} from 'src/interfaces/item';
import {EMPTY_ITEM} from 'src/utils/constants';
import {useAppSelector} from 'src/hooks';

interface IProps {
  onHelp: () => void;
  onBrowseItems: () => void;
  onScanBarcode?: () => void;
  onCreateItem?: (item: IItem, isNewItem?: boolean) => void;
}

const FAB = ({onHelp, onBrowseItems, onScanBarcode, onCreateItem}: IProps) => {
  const {isNative} = useAppSelector((state) => state.platform);
  return (
    <IonFab id="inventory-fab" vertical="bottom" horizontal="start" slot="fixed">
      <IonFabButton>
        <IonIcon icon={addOutline} />
      </IonFabButton>
      <IonFabList side="top">
        <IonItem onClick={onHelp}>
          <IonIcon slot="start" icon={getHelp} />
          <IonLabel>Get Help Adding</IonLabel>
        </IonItem>
        <IonItem onClick={onBrowseItems}>
          <IonIcon slot="start" icon={browseItems} />
          <IonLabel>Browse Items</IonLabel>
        </IonItem>
        {onCreateItem && (
          <IonItem onClick={() => onCreateItem(EMPTY_ITEM, true)}>
            <IonIcon slot="start" icon={addItems} />
            <IonLabel>Add Item</IonLabel>
          </IonItem>
        )}
        {(isNative || isPlatform('mobileweb')) && (
          <IonItem onClick={onScanBarcode}>
            <IonIcon slot="start" icon={barcode} />
            <IonLabel>Scan Barcode</IonLabel>
          </IonItem>
        )}
      </IonFabList>
    </IonFab>
  );
};

export default FAB;
