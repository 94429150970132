import {IonPage, IonContent, IonBackButton, IonButton, IonLabel, IonFooter} from '@ionic/react';
import './styles.scss';
import {ICategory, IInventoryStats, IQueryObject} from 'src/interfaces/item';
import Header from 'src/components/Header';
import {closeOutline} from 'ionicons/icons';
import PriceFilter from '../components/PriceFilter';
import DateFilter from '../components/DateFilter';
import CategoryFilter from '../components/CategoryFilter';
import MenuFilter from 'src/components/MenuFilter';
import {IMenuList} from 'src/interfaces/store';
import ItemStatusFilter from '../components/ItemStatusFilter';
import {ITEM_STATUS} from 'src/enums/item_status';

interface IProps {
  isDesktop: boolean;
  inventoryTab: string;
  categories: Array<ICategory>;
  menuList: Array<IMenuList>;
  filterObject: IQueryObject;
  inventoryStats: IInventoryStats;
  selectFilter: (e: any) => void;
  onFilter: () => void;
  onClose: () => void;
  onReset: () => void;
}

const TabScreen = (props: IProps) => (
  <IonPage id="item-filter-tablet" className={`snow-background`}>
    <IonContent fullscreen scrollY={true} className="page-container">
      <div className="top-padding">
        <Header
          rightButton={<IonBackButton text="" icon={closeOutline} />}
          leftButton={
            <IonButton className="reset" onClick={props.onReset}>
              Reset
            </IonButton>
          }
          noBackground
          title="Filter"
        />
      </div>
      <div className="filter-group price-group ">
        <IonLabel className="filter-title">Price</IonLabel>
        <PriceFilter
          inventoryStats={props.inventoryStats}
          filterObject={props.filterObject}
          selectFilter={props.selectFilter}
        />
      </div>

      <div className="filter-group">
        <div className="filter-title-group">
          <IonLabel className="filter-title">
            Date<span className="filter-sub-title">Items added in the last</span>
          </IonLabel>
        </div>
        <DateFilter isDesktop={props.isDesktop} filterObject={props.filterObject} selectFilter={props.selectFilter} />
      </div>

      {/* {props.inventoryTab === ITEM_STATUS.ARCHIVED && (
        <div className="filter-group ">
          <div className="filter-title-group">
            <IonLabel className="filter-title">Item status</IonLabel>
          </div>
          <ItemStatusFilter
            isDesktop={props.isDesktop}
            filterObject={props.filterObject}
            selectFilter={props.selectFilter}
          />
        </div>
      )} */}

      <div className="filter-group">
        <IonLabel className="filter-title">Categories</IonLabel>
        <CategoryFilter
          isDesktop={props.isDesktop}
          categories={props.categories}
          filterObject={props.filterObject}
          selectFilter={props.selectFilter}
        />
      </div>

      <div className="filter-group">
        <IonLabel className="filter-title">Menu</IonLabel>
        <div className="filter-group-grid">
          <MenuFilter
            isFilter={true}
            menuList={props.menuList}
            filterObject={props.filterObject}
            selectFilter={props.selectFilter}
            isDesktop={props.isDesktop}
            onNewMenuClick={() => {}}
          />
        </div>
      </div>
    </IonContent>

    <IonFooter>
      <IonButton className="btn-primary footer-btn mb-0" expand="block" onClick={props.onClose}>
        Show Results
      </IonButton>
    </IonFooter>
  </IonPage>
);

export default TabScreen;
