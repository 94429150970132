import {IonModal, IonIcon, IonImg} from '@ionic/react';
import {arrowBackOutline, closeOutline} from 'ionicons/icons';
import './styles.scss';
import Header from 'src/components/Header';

interface IProps {
  item: any;
  isOpen: boolean;
  fromEditModal?: boolean;
  onClose: () => void;
  onBack?: () => void;
}

interface IRowItem {
  title: string;
  description: string | undefined;
}

const RowItem = ({title, description}: IRowItem) => (
  <div className="row-item">
    <div className="right-col">{title}</div>
    <div className={`${description ? 'left-col' : 'left-col text-muted'}`}>{description || '—'}</div>
  </div>
);

const ItemDetailsModal = ({isOpen, onClose, fromEditModal, onBack, item}: IProps) => (
  <IonModal className="app-modal item-details-modal" isOpen={isOpen} showBackdrop backdropDismiss={false}>
    <Header
      border
      noBackground
      title="Item details"
      rightButton={<IonIcon size="large" icon={closeOutline} onClick={() => onClose()} />}
      leftButton={
        fromEditModal ? <IonIcon size="large" icon={arrowBackOutline} onClick={() => onBack && onBack()} /> : <></>
      }
    />
    <div className="modal-content-container">
      <div className="modal-content">
        <div className="top-container">
          <div className="quantity">x {item?.quantity}</div>
          <IonImg src={item?.image} />
        </div>
        <RowItem title="Name" description={item?.itemName} />
        <RowItem title="Size" description={item?.description} />
        <RowItem title="Count" description={item?.unitCount} />
        <RowItem title="Price" description={item?.itemPrice} />
        <RowItem title="UPC" description={item?.upc} />
        <RowItem title="Location" description={item?.location} />
        <RowItem title="Store Item ID" description={item?.storeItemId} />
        <RowItem title="Description" description={item?.description} />
        <RowItem title="Category" description={item?.categoryName} />
        <RowItem title="Sub-category" description={item?.subCategory ? item?.subCategory : item?.categoryName} />

        {/* <IonFooter>
            <IonButton
              className="btn-dark btn-end"
              expand={!isDesktop ? 'block' : undefined}
              mode="ios"
              fill="clear"
              onClick={() => {}}>
              Visit item page
            </IonButton>
          </IonFooter> */}
      </div>
    </div>
  </IonModal>
);

export default ItemDetailsModal;
