import React from 'react';
import {IonHeader, IonToolbar, IonButtons, IonImg} from '@ionic/react';
import './styles.scss';

interface IProps {
  className?: string;
  leftButton?: React.ReactNode;
  title?: string;
  subtitle?: string | undefined;
  rightButton?: React.ReactNode;
  noBackground?: boolean;
  border?: boolean;
  withSideMenu?: boolean;
  alignTitleLeft?: boolean;
  rightButtons?: React.ReactNode;
  iconImg?: any;
}

const Header = (props: IProps) => (
  <IonHeader
    id="header"
    className={`${props.className} ion-no-border ${props.noBackground ? 'no-background' : ''} ${
      !!props.border ? 'border' : ''
    } ${props.withSideMenu ? 'with-side-menu' : ''} ${!!props.subtitle ? 'with-subtitle' : ''}`}>
    <IonToolbar mode={props.alignTitleLeft ? 'md' : 'ios'}>
      {props.leftButton && <IonButtons slot="start">{props.leftButton}</IonButtons>}
      {props.title && (
        <div className="title-subtitle-container">
          <div className="title-container">
            {props.iconImg && <IonImg src={props.iconImg} />}
            <p>{props.title}</p>
          </div>
          {props.subtitle && <p>{props.subtitle}</p>}
        </div>
      )}
      {props.rightButton && <IonButtons slot="end">{props.rightButton}</IonButtons>}
      {props.rightButtons && <div slot="end">{props.rightButtons}</div>}
    </IonToolbar>
  </IonHeader>
);

export default Header;
