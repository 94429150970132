import {useDispatch} from 'react-redux';
import {useAppSelector} from 'src/hooks';
import StoreStatusMenuOpen from './Open';
import StoreStatusMenuPaused from './Paused';
import StoreStatusMenuOffline from './Offline';
import StoreStatusMenuClosed from './Closed';
import {endStorePauseNow, getStoreStatus, updateStoreStatus} from 'src/store/storeSlice';

interface IProps {
  className?: string;
  partnerName?: string;
  isAllPartnerOpen: boolean;
  isAllPartnerOffline: boolean;
  isAllPartnerPaused: boolean;
  isSomePartnerPaused: boolean;
  isClosed?: boolean;
}

const StoreStatuses = ({
  className,
  partnerName,
  isAllPartnerOpen,
  isAllPartnerOffline,
  isAllPartnerPaused,
  isSomePartnerPaused,
  isClosed,
}: IProps) => {
  const dispatch = useDispatch();
  const {currentPause, uberEats, grubHub, doorDash} = useAppSelector((state) => state.store?.storeStatus?.partners);

  const getCurrentPause = () => {
    if (!!currentPause) {
      return currentPause;
    } else if (partnerName === 'DoorDash' && !!doorDash[0]?.currentPause) {
      return doorDash[0]?.currentPause;
    } else if (partnerName === 'GrubHub' && !!grubHub[0]?.currentPause) {
      return grubHub[0]?.currentPause;
    } else if (partnerName === 'UberEats' && !!uberEats[0]?.currentPause) {
      return uberEats[0]?.currentPause;
    } else return null;
  };

  // end pause for specific DSP, otherwise end pause for all DSP having current pause
  const onEndPause = async () => {
    if (partnerName) {
      !!currentPause
        ? await dispatch(
            updateStoreStatus({
              dsp_name: partnerName,
              is_active: true,
            }),
          )
        : getCurrentPause()?.id &&
          (await dispatch(
            endStorePauseNow({
              dsp_name: partnerName,
              store_pause_schedules_id: getCurrentPause()?.id,
            }),
          ));
      await dispatch(getStoreStatus());
    } else {
      if (currentPause) {
        await dispatch(
          endStorePauseNow({
            dsp_name: 'All',
            store_pause_schedules_id: currentPause?.id,
          }),
        );
      } else {
        let partnerList = [doorDash[0], grubHub[0], uberEats[0]];
        await partnerList.map(async (partnerItem) => {
          partnerItem.currentPause?.id &&
            (await dispatch(
              endStorePauseNow({
                dsp_name: 'All',
                store_pause_schedules_id: partnerItem.currentPause?.id,
              }),
            ));
        });
      }
      await dispatch(getStoreStatus());
    }
  };

  return (
    <div className={className}>
      {isClosed ? (
        <StoreStatusMenuClosed
          partnerName={partnerName}
          onEndPause={onEndPause}
          currentPauseEndDate={getCurrentPause()?.endDate}
          currentPauseEndTime={getCurrentPause()?.endTime}
        />
      ) : isAllPartnerOpen ? (
        <StoreStatusMenuOpen partnerName={partnerName} />
      ) : isSomePartnerPaused || isAllPartnerPaused ? (
        <StoreStatusMenuPaused
          partnerName={partnerName}
          isSomePartnerPaused={isSomePartnerPaused}
          onEndPause={onEndPause}
          currentPauseEndDate={getCurrentPause()?.endDate}
          currentPauseEndTime={getCurrentPause()?.endTime}
        />
      ) : isAllPartnerOffline ? (
        <StoreStatusMenuOffline partnerName={partnerName} />
      ) : (
        ''
      )}
    </div>
  );
};

export default StoreStatuses;
