import {Dispatch, useState} from 'react';
import {IonButton, IonIcon, IonInput, IonFooter, IonModal} from '@ionic/react';
import {useHistory} from 'react-router';
import './styles.scss';
import {ITiming} from 'src/interfaces/store';
import StoreTimings from 'src/pages/Store/Hours/components/StoreTimings';
import {closeOutline} from 'ionicons/icons';
import Header from 'src/components/Header';

interface IProps {
  menuName: string;
  timings: ITiming[][];
  menuDetailId: string;
  validate: boolean;
  isDefault: boolean;
  canEditMenu?: boolean;
  canDeleteMenu?: boolean;
  deleteMenu?: () => void;
  setMenuName: (e: string) => void;
  setTimings: Dispatch<React.SetStateAction<ITiming[][]>>;
  validateInputs: (e: ITiming[][]) => boolean;
}

const NewMenu = ({
  menuName,
  setMenuName,
  timings,
  menuDetailId,
  validate,
  isDefault,
  deleteMenu,
  canEditMenu,
  canDeleteMenu,
  setTimings,
  validateInputs,
}: IProps) => {
  const history = useHistory();

  const [isShowConfirmation, setIsShowConfirmation] = useState(false);

  return (
    <>
      <div id="new-menu-container">
        <div className="space-around name-container">
          <p>Menu name </p>
          <IonInput
            value={menuName}
            placeholder="Menu Name"
            onIonChange={(e) => {
              setMenuName(e.detail.value ?? '');
            }}
            disabled={isDefault || !canEditMenu}
          />
          {menuDetailId && (
            <IonButton
              className="btn-outline add-item-btn"
              disabled={!canEditMenu}
              onClick={() => history.replace('/inventory')}>
              <IonIcon className="icon-add" />
              Add Items
            </IonButton>
          )}
        </div>
        <p className="label">MENU HOURS</p>
        <div>
          <StoreTimings
            type="menu"
            validate={validate}
            timings={timings}
            disabled={!canEditMenu}
            setTimings={setTimings}
            validateInputs={validateInputs}
          />
        </div>

        {menuDetailId && !isDefault && canDeleteMenu && (
          <IonButton
            className="btn-remove"
            mode="ios"
            disabled={isDefault}
            fill="outline"
            color="danger"
            expand="block"
            onClick={() => setIsShowConfirmation(true)}>
            Delete menu
          </IonButton>
        )}
      </div>
      <IonModal
        id="delete-option"
        isOpen={isShowConfirmation}
        showBackdrop
        onDidDismiss={() => {
          setIsShowConfirmation(false);
        }}>
        <div className="modal-content-container">
          <div className="modal-content">
            <Header
              border
              noBackground
              title="Delete"
              rightButton={
                <IonIcon
                  onClick={() => {
                    setIsShowConfirmation(false);
                  }}
                  icon={closeOutline}
                />
              }
            />
            <div className="modal-body">
              <div className="label">Are you sure you want to delete this menu?</div>
            </div>
            <IonFooter>
              <div className="action">
                <IonButton
                  className="btn-outline"
                  onClick={() => {
                    setIsShowConfirmation(false);
                  }}>
                  No
                </IonButton>
                <IonButton
                  className="btn-primary"
                  onClick={() => {
                    if (deleteMenu) {
                      setIsShowConfirmation(false);
                      deleteMenu();
                    }
                  }}>
                  Yes
                </IonButton>
              </div>
            </IonFooter>
          </div>
        </div>
      </IonModal>
    </>
  );
};

export default NewMenu;
