import {IonModal, IonButton} from '@ionic/react';
import './styles.scss';
import TextWithLines from 'src/components/TextWithLines';
import CodeInput from 'src/components/CodeInput';

interface IProps {
  verified: boolean;
  isOpen: boolean;
  resendCode: () => void;
  validateCode: (code: string) => void;
  onContinue: () => void;
  onClose: () => void;
}

const VerifyCodeModal = (props: IProps) => (
  <IonModal className="app-modal verify-code-modal" isOpen={props.isOpen} showBackdrop backdropDismiss={false}>
    <div className="modal-content-container">
      <div className="modal-content">
        <h2 className="modal-title">Enter verification code</h2>
        <p className="modal-desc">We just sent a 6-digit code to the e-mail address you provided.</p>

        <CodeInput length={6} readOnly={props.verified} onComplete={props.validateCode} />

        <TextWithLines text={props.verified ? "you're all set" : "can't find it?"} />

        {props.verified ? (
          <IonButton className="btn-primary mb-0" expand="block" onClick={props.onContinue}>
            Continue
          </IonButton>
        ) : (
          <IonButton className="btn-outline mb-0" expand="block" onClick={props.resendCode}>
            Resend Code
          </IonButton>
        )}

        <IonButton className="btn-primary footer-btn mb-0" expand="block" fill="clear" onClick={props.onClose}>
          Close this window
        </IonButton>
      </div>
    </div>
  </IonModal>
);

export default VerifyCodeModal;
