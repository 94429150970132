import {IonCard, IonCardContent, IonCardHeader, IonIcon, IonCardTitle} from '@ionic/react';
import './styles.scss';
import {ICustomerProposedSubstitutes} from 'src/interfaces/item';

interface IInstructionCard {
  title: string;
  description?: string;
  icon?: string;
  variant: 'warning' | 'danger' | 'muted';
  substitutes?: Array<ICustomerProposedSubstitutes>;
}

const InstructionsCard = ({title, description, icon, variant, substitutes}: IInstructionCard) => (
  <div id="order-instruction-card" className={`${variant}`}>
    <IonCard>
      <IonCardHeader>
        {icon && <IonIcon icon={icon} />}
        <IonCardTitle>{title}</IonCardTitle>
      </IonCardHeader>

      {(description || substitutes?.length) && (
        <IonCardContent>
          {description &&
            description?.split('\n').map((line, idx) => (
              <p key={idx}>
                {line}
                <br />
              </p>
            ))}
          {description && substitutes?.length && <hr />}
          {substitutes?.length && (
            <>
              <p>If this item is unavailable substitute for:</p>
              {substitutes?.map(({name, size, unitCount}, idx) => (
                <div key={idx} className="ion-margin-top">
                  <p className="title">{name}</p>
                  {size && <p className="desc">Size: {size}</p>}
                  {unitCount && <p className="desc">Count: {unitCount}</p>}
                </div>
              ))}
            </>
          )}
        </IonCardContent>
      )}
    </IonCard>
  </div>
);

export default InstructionsCard;
