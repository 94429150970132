import {makeRequest} from 'src/Axios';
import {INVENTORY_STATUS} from 'src/enums/item_status';
import {IItem, IQueryObject} from 'src/interfaces/item';
import {
  camelToSnakeCase,
  createFilterParams,
  createQueryParams,
  getItemFromStoreItemNew,
  mapCategoryCount,
} from 'src/utils/helpers';

const inventoryService = {
  getInventoryStats: async (storeId: string) => {
    try {
      const response: any = await makeRequest({
        url: `/inventory/${storeId}/stats`,
        isMicroservice: true,
      });
      return response.data?.[0];
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getInStockInventory: async (offset: number, queryObject: IQueryObject, store: any) => {
    try {
      const params = createQueryParams({
        ...queryObject,
        offset,
        show_global_items_content: store.showGlobalItemsContent,
        active: true,
      });
      const response: any = await makeRequest({url: `/inventory/${store.id}`, params: params, isMicroservice: true});

      return response.data.map((sItem: any) => getItemFromStoreItemNew(sItem));
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getArchivedInventory: async (offset: number, queryObject: IQueryObject, store: any) => {
    try {
      const params = createQueryParams({
        ...queryObject,
        offset,
        show_global_items_content: store.showGlobalItemsContent,
        active: false,
      });
      const response: any = await makeRequest({url: `/inventory/${store.id}`, params: params, isMicroservice: true});
      return response.data.map((sItem: any) => getItemFromStoreItemNew(sItem));
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getOutOfStockInventory: async (offset: number, queryObject: IQueryObject, store: any) => {
    try {
      const params = createQueryParams({
        ...queryObject,
        offset,
        show_global_items_content: store.showGlobalItemsContent,
        out_of_stock: true,
      });
      const response: any = await makeRequest({url: `/inventory/${store.id}`, params: params, isMicroservice: true});
      return response.data.map((sItem: any) => getItemFromStoreItemNew(sItem));
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getIncompleteStoreItems: async (queryObject: IQueryObject, store: any) => {
    try {
      const params = createQueryParams({
        ...queryObject,
        show_global_items_content: store.showGlobalItemsContent,
        incomplete: true,
      });
      const response: any = await makeRequest({url: `/inventory/${store.id}`, params: params, isMicroservice: true});

      return response.data.map((sItem: any) => getItemFromStoreItemNew(sItem));
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  patchArchivedItem: async (storeId: string, data: any, status: boolean) => {
    try {
      const body = {
        category_ids: data.selectedCategories,
        excluded_ids: data.exceptItems.map((item: IItem) => item.storeItemId),
        included_ids: data.selectedItems.map((item: IItem) => item.storeItemId),
      };
      const response: any = await makeRequest({
        url: `/inventory/${storeId}/statuses`,
        method: 'PATCH',
        body,
        params: {status},
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  searchInventory: async (
    searchTerm: string,
    type: number,
    offset: number,
    store: any,
    queryObject: IQueryObject,
    partner?: string,
  ) => {
    try {
      const params = createQueryParams({
        ...queryObject,
        search_string: searchTerm,
        offset,
        show_global_items_content: store.showGlobalItemsContent,
        active: type === INVENTORY_STATUS.ARCHIVED ? false : true,
      });
      const response: any = await makeRequest({
        url: `/inventory/${store.id}`,
        params: partner ? {...params, partner_name: partner} : params,
        isMicroservice: true,
      });
      return response.data.map((sItem: any) => getItemFromStoreItemNew(sItem));
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getInventoryCount: async (queryObject: IQueryObject, store: any, partner?: string) => {
    try {
      const params = createQueryParams(queryObject);
      const response: any = await makeRequest({
        url: `/inventory/${store.id}/inventory-count`,
        params: partner ? {...params, partner_name: partner} : params,
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getCategoryCount: async (storeId: string, active: boolean, queryObject: IQueryObject) => {
    try {
      const params = createFilterParams({...queryObject, active});
      const response: any = await makeRequest({
        url: `/inventory/${storeId}/count-by-category`,
        params: camelToSnakeCase(params),
        isMicroservice: true,
      });
      return {
        categoryCount: response.data.filtered_count.map((sItem: any) => mapCategoryCount(sItem)),
        filteredCategoryCount: response.data.original_count.map((sItem: any) => mapCategoryCount(sItem)),
      };
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  validateBulkPrice: async (storeId: string, data: any, active: boolean) => {
    try {
      let updated_data: any = {};
      updated_data.price = data.updated_data.price;
      updated_data.action_type = data.updated_data.action_type;

      const body = {
        category_ids: data.selectedCategories,
        updated_data,
        excluded_ids: data.exceptItems.map((item: IItem) => item.storeItemId),
        included_ids: data.selectedItems.map((item: IItem) => item.storeItemId),
      };
      const response: any = await makeRequest({
        url: `/inventory/${storeId}/validate-price-update`,
        method: 'PUT',
        body: body,
        params: {active},
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  updateBulkItems: async (storeId: string, data: any, active: boolean) => {
    try {
      let updated_data: any = {};
      if (data.updated_data.category_id) {
        updated_data.category_id = data.updated_data.category_id;
      } else if (data.updated_data.action_type) {
        updated_data.price = data.updated_data.price;
        updated_data.action_type = data.updated_data.action_type;
      }

      const body = {
        category_ids: data.selectedCategories,
        updated_data,
        excluded_ids: data.exceptItems.map((item: IItem) => item.storeItemId),
        included_ids: data.selectedItems.map((item: IItem) => item.storeItemId),
      };
      const response: any = await makeRequest({
        url: `/inventory/store/${storeId}/items/bulk-actions`,
        method: 'PUT',
        body: body,
        params: {active},
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  deleteBulkItems: async (storeId: string, data: any, active: boolean) => {
    try {
      const body = {
        category_ids: data.selectedCategories,
        excluded_ids: data.exceptItems.map((item: IItem) => item.storeItemId),
        included_ids: data.selectedItems.map((item: IItem) => item.storeItemId),
        store_id: storeId,
        active,
      };
      const response: any = await makeRequest({
        url: `/inventory/bulk/${storeId}`,
        method: 'DELETE',
        body: body,
        params: {active},
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  menuBulkItems: async (storeId: string, data: any, active: boolean) => {
    try {
      const body = {
        menu_ids: data.menuIds,
        category_ids: data.selectedCategories,
        excluded_ids: data.exceptItems.map((item: IItem) => item.storeItemId),
        included_ids: data.selectedItems.map((item: IItem) => item.storeItemId),
      };
      const response: any = await makeRequest({
        url: `/stores/${storeId}/menu/items`,
        method: 'POST',
        body: body,
        params: {active},
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  deleteMenuBulkItems: async (storeId: string, data: any, active: boolean) => {
    try {
      const body = {
        menu_ids: data.menuIds,
        category_ids: data.selectedCategories,
        excluded_ids: data.exceptItems.map((item: IItem) => item.storeItemId),
        included_ids: data.selectedItems.map((item: IItem) => item.storeItemId),
      };
      const response: any = await makeRequest({
        url: `/stores/${storeId}/menu/menu-item`,
        method: 'DELETE',
        body: body,
        params: {active},
        isMicroservice: true,
      });
      return response.data;
    } catch (error: any) {
      throw error.response?.data;
    }
  },
};

export default inventoryService;
