import {IonIcon, IonText} from '@ionic/react';
import {useAppSelector} from 'src/hooks';
import './styles.scss';

interface IProps {
  date: string;
  time: string;
  title: string;
  description?: string;
  username?: string;
}

const OrderTimelineEvent = ({date, time, title, description, username}: IProps) => {
  const {isDesktop} = useAppSelector((state) => state.platform);
  return (
    <div id="order-timeline-event">
      <div className="event-details">
        <div className="time-and-title">
          <IonText className="event-time">{time}</IonText>
          <div className="title-and-user-info">
            <IonText className="event-title">{title}</IonText>            
            {username && isDesktop && (
              <div className="user-details">
                <IonIcon className="icon-user" />
                <IonText>{username}</IonText>
              </div>
            )}
          </div>
        </div>
        <div className="event-date">
          <IonText>{date}</IonText>
        </div>
      </div>
    </div>
  );
};

export default OrderTimelineEvent;
