import './styles.scss';
import OrderHistoryFilter from 'src/pages/Orders/History/components/OrderHistoryFilter';
import OrderHistoryDate from 'src/pages/Orders/History/components/OrderHistoryDate';
import {HISTORY_FILTERS} from 'src/utils/constants';

interface IProps {
  orderTypes: Array<string | number>;
  onChangeOrderTypes: (value: any) => void;
  onDateChange: (startDate: string, endDate: string) => void;
}

const HistoryFilters = (props: IProps) => (
  <div id="history-filters">
    <div className="type-sort-container">
      <OrderHistoryDate onDateChange={props.onDateChange} />

      <OrderHistoryFilter
        selectedValues={props.orderTypes}
        values={HISTORY_FILTERS.type}
        onChange={props.onChangeOrderTypes}
      />
    </div>
  </div>
);

export default HistoryFilters;
