import {IonPage, IonContent, IonBackButton, IonRadioGroup} from '@ionic/react';
import './styles.scss';
import {IQueryObject} from 'src/interfaces/item';
import Header from 'src/components/Header';
import {closeOutline} from 'ionicons/icons';
import SortRadioButton from '../components/SortRadioButton';
import {mapSortSelectionToSortValues} from 'src/utils/helpers';

interface IProps {
  sortObject: IQueryObject;
  selectSort: (e: any) => void;
  onSort: () => void;
  onClose: () => void;
}

const TabScreen = (props: IProps) => {
  const sortButtons = Array(7)
    .fill(false)
    .map((item, index, array) => {
      const currentSortObject = mapSortSelectionToSortValues(index + 1);
      return (
        currentSortObject.sortDir === props.sortObject.sortDir &&
        currentSortObject.order === props.sortObject.order
      );
    });

  return (
    <IonPage id="item-sorting-tablet" className={`snow-background`}>
      <IonContent fullscreen scrollY={false} className="page-container">
        <div className="top-padding">
          <Header rightButton={<IonBackButton text="" icon={closeOutline} />} noBackground title="Sort By" />
        </div>
        <IonRadioGroup
          value={sortButtons.findIndex((isSelected) => isSelected) + 1}
          onIonChange={(e) => {
            props.selectSort(e.detail.value);
          }}>
          {sortButtons.map((isSelected, index) => (
            <SortRadioButton key={index} selected={isSelected} sortSelection={index + 1} />
          ))}
        </IonRadioGroup>
      </IonContent>
    </IonPage>
  );
};

export default TabScreen;
