import './styles.scss';
import {IonButton, IonContent, IonIcon, IonPage} from '@ionic/react';
import {arrowBack, closeOutline} from 'ionicons/icons';
import Header from 'src/components/Header';
import {useState} from 'react';

interface IProps {
  setShowEditModal: (e: boolean) => void;
  setShowAvailabilityModal: (e: boolean) => void;
  isInStock: boolean;
  patchInventory: (a: any, b: boolean, c: boolean) => void;
}

const AvailabilityOptionModal = (props: IProps) => {
  const [newInStockState, setNewInStockState] = useState(props.isInStock);
  const [showConfirmationOption, setShowConfirmationOption] = useState(false);

  return (
    <IonPage id="availability-option-mobile">
      <IonContent fullscreen scrollY={false}>
        <Header
          border
          noBackground
          title="Availability"
          leftButton={
            <IonIcon
              onClick={() => {
                props.setShowAvailabilityModal(false);
              }}
              icon={arrowBack}></IonIcon>
          }
          rightButton={
            <IonIcon
              onClick={() => {
                props.setShowAvailabilityModal(false);
                props.setShowEditModal(false);
              }}
              icon={closeOutline}></IonIcon>
          }
        />
        {!showConfirmationOption ?
        <>
        <div className="body">
          <div className="label"></div>
          <div className="availability-option-container">
            <div
              className={`availability-option instock ${newInStockState ? 'selected' : ''}`}
              onClick={() => {
                if (!newInStockState) setNewInStockState(true);
              }}>
              <p>In stock</p>
            </div>
            <div
              className={`availability-option outstock ${!newInStockState ? 'selected' : ''}`}
              onClick={() => {
                if (newInStockState) setNewInStockState(false);
              }}>
              <p>Out of stock</p>
            </div>
          </div>
        </div>
        <div className="action-div">
          <IonButton
            disabled={props.isInStock === newInStockState}
            className="action-btn"
            mode="ios"
            fill="solid"
            color="primary"
            expand="block"
            onClick={() => {
              setShowConfirmationOption(true);
            }}>
            Save edit
          </IonButton>
        </div>
        </>
        :
        <div className="confirmation-div">
            <div className="label">Are you sure you want to Update these items?</div>
            <div className="action">
              <IonButton
                className="btn-outline"
                onClick={() => {
                  setShowConfirmationOption(false);
                }}>
                No
              </IonButton>
              <IonButton
                className="btn-primary"
                onClick={() => {
                  props.patchInventory({}, true, newInStockState);
                }}>
                Yes
              </IonButton>
            </div>
          </div>
}
      </IonContent>
    </IonPage>
  );
};

export default AvailabilityOptionModal;
