import {useState} from 'react';
import {IonIcon} from '@ionic/react';
import {chevronDown, chevronUp} from 'ionicons/icons';
import './styles.scss';
import {IOrderItemDetails} from 'src/interfaces/item';

interface IProps {
  item: IOrderItemDetails;
}

const NewOrderItem = ({item}: IProps) => {
  const [showInstructions, setShowInstructions] = useState(false);

  return (
    <div id="order-item-component">
      <div className="order-item" onClick={() => setShowInstructions(!showInstructions)}>
        <div className="item-details">
          <p className="quantity">{item.quantity}x</p>
          <div>
            <p className="name">{item.itemName}</p>
            {item.external_id && <p className="location">{item.external_id}</p>}
          </div>
        </div>
        <div className="price">
          {item.itemLevelInstructions && <IonIcon src={showInstructions ? chevronUp : chevronDown} />}
        </div>
      </div>
      <p className="description">{item.description}</p>
      {item.location && <p className="description">Location: {item.location}</p>}
      {showInstructions && item.itemLevelInstructions && (
        <div className="item-instructions">
          <p className="message">{item.itemLevelInstructions}</p>
        </div>
      )}
    </div>
  );
};

export default NewOrderItem;
