import {useEffect, useState} from 'react';
import {IonPage, IonContent, IonGrid, IonRow, IonCol, IonLabel} from '@ionic/react';
import './styles.scss';
import {RouteComponentProps} from 'react-router';
import moment from 'moment';
import {AppVersion} from '@awesome-cordova-plugins/app-version';
import CommonHeader from 'src/components/CommonHeader';
import ListItem from 'src/components/ListItem';
import UserDetails from 'src/pages/Settings/Details';
import EmailSetting from 'src/pages/Settings/Email';
import PasswordSetting from 'src/pages/Settings/Password';
import NotificationSetting from 'src/pages/Settings/Notifications';
import {useAppSelector, useEffectOnce} from 'src/hooks';
import {ROUTE_LOCATION} from 'src/enums/route_location';
import * as PERMISSIONS from 'src/utils/permissions';

interface IProps extends RouteComponentProps {}

const ManageAccount = ({history, location, match}: IProps) => {
  const {user, menuIsOpen} = useAppSelector((state) => state.auth);
  const {isDesktop, isTablet, isNative, versionCode, versionNumber} = useAppSelector((state) => state.platform);
  const {id: storeId} = useAppSelector((state) => state.store);

  const canViewNotifications = user?.role.permissions?.some(
    (item: any) => item.name === PERMISSIONS.VIEW_ACCOUNT_NOTIFICATIONS,
  );

  const canViewAccountDetails = user?.role.permissions?.some(
    (item: any) => item.name === PERMISSIONS.VIEW_ACCOUNT_DETAILS,
  );

  const [passwordChanged, setPasswordChanged] = useState('');
  const [showUserDetails, setShowUserDetails] = useState(false);
  const [showEmail, setShowEmail] = useState(false);
  const [showPassword, setShowPasswordSettings] = useState(false);
  const [showNotifications, setShowNotifications] = useState(false);
  const [version, setVersion] = useState('');

  const detailsClick = () => {
    if (isDesktop) {
      setShowUserDetails(true);
      setShowEmail(false);
      setShowPasswordSettings(false);
      setShowNotifications(false);
    } else history.push('/settings/details');
  };

  const passwordClick = () => {
    if (isDesktop) {
      setShowUserDetails(false);
      setShowEmail(false);
      setShowPasswordSettings(true);
      setShowNotifications(false);
    } else history.push('/settings/password');
  };

  const emailClick = () => {
    if (isDesktop) {
      setShowUserDetails(false);
      setShowEmail(true);
      setShowPasswordSettings(false);
      setShowNotifications(false);
    } else history.push({pathname: '/settings/email', state: {from: ROUTE_LOCATION.SETTINGS}});
  };

  const notificationsClick = () => {
    if (isDesktop) {
      setShowUserDetails(false);
      setShowEmail(false);
      setShowPasswordSettings(false);
      setShowNotifications(true);
    } else history.push('/settings/notifications');
  };

  useEffectOnce(() => {
    (async () => {
      if (isNative) {
        setVersion(`Version: ${versionNumber} (${versionCode})`);
      }
    })();
    if (user?.passwordLastChanged) {
      setPasswordChanged(moment(user?.passwordLastChanged).fromNow());
    }
  });

  useEffect(() => {
    setShowUserDetails(false);
    setShowEmail(false);
    setShowPasswordSettings(false);
    setShowNotifications(false);
  }, [storeId]);

  return (
    <IonPage id="settings-manage-page" className="snow-background">
      <IonGrid>
        <IonRow>
          <IonCol sizeXl="8" className={`${isTablet && !menuIsOpen ? 'menu-close' : ''}`}>
            <IonContent fullscreen scrollY={false} className="page-container">
              <CommonHeader className="title-left" title="Settings" menuIsOpen={menuIsOpen} />

              <div className="body">
                {canViewAccountDetails ? (
                  <>
                    <p className="heading">General</p>
                    <ListItem
                      fullWidth
                      icon="icon-pencil"
                      title="Personal Details"
                      description="Edit Name and phone number"
                      onClick={detailsClick}
                      selected={showUserDetails}
                    />
                  </>
                ) : null}
                <p className="heading">Account</p>
                {canViewAccountDetails ? (
                  <>
                    <ListItem
                      fullWidth
                      verified={user?.isEmailVerified}
                      icon="icon-email"
                      title="E-Mail"
                      description="Change associated e-mail address"
                      onClick={emailClick}
                      selected={showEmail}
                    />
                    <ListItem
                      fullWidth
                      icon="icon-lock"
                      title="Password"
                      description={passwordChanged ? `Last changed ${passwordChanged}` : ''}
                      onClick={passwordClick}
                      selected={showPassword}
                    />
                  </>
                ) : null}

                {canViewNotifications && (
                  <ListItem
                    fullWidth
                    icon="icon-bell"
                    title="Notifications"
                    description="Manage how you get notified"
                    onClick={notificationsClick}
                    selected={showNotifications}
                  />
                )}
                {isNative && <IonLabel>{version}</IonLabel>}
              </div>
            </IonContent>
          </IonCol>

          {isDesktop && (
            <IonCol sizeXl="4">
              {canViewAccountDetails ? (
                <>
                  {showUserDetails && (
                    <UserDetails
                      history={history}
                      location={location}
                      match={match}
                      setShowUserDetails={setShowUserDetails}
                    />
                  )}
                  {showEmail && (
                    <EmailSetting history={history} location={location} match={match} setShowEmail={setShowEmail} />
                  )}
                  {showPassword && (
                    <PasswordSetting
                      history={history}
                      location={location}
                      match={match}
                      setShowPasswordSettings={setShowPasswordSettings}
                    />
                  )}
                </>
              ) : null}
              {showNotifications && canViewNotifications && (
                <NotificationSetting
                  history={history}
                  location={location}
                  match={match}
                  setShowNotifications={setShowNotifications}
                />
              )}
            </IonCol>
          )}
        </IonRow>
      </IonGrid>
    </IonPage>
  );
};

export default ManageAccount;
