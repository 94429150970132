/* eslint-disable react-hooks/exhaustive-deps */
import {useState, useEffect} from 'react';
import {RouteComponentProps} from 'react-router';
import {IonPage, IonContent, IonButton, IonFooter, IonSpinner, IonImg, IonIcon, IonAlert} from '@ionic/react';
import {chevronBackOutline, chevronForwardOutline, closeOutline} from 'ionicons/icons';
import './styles.scss';
import {useFeatureFlagEnabled} from 'posthog-js/react';
import Header from 'src/components/Header';
import Snackbar from 'src/components/Snackbar';
import ItemHeaderBar from './components/ItemHeaderBar';
import ItemDetailsComponent from 'src/components/ItemDetails';
import NoItem from 'src/components/NoItem';
import {IInventoryStats, IItem} from 'src/interfaces/item';
import itemService from 'src/services/item';
import fileService from 'src/services/file';
import {ITEM_TYPE} from 'src/enums/item_type';
import {useAppDispatch, useAppSelector, useEffectOnce} from 'src/hooks';
import {showLoading, hideLoading} from 'src/store/loadingSlice';
import {
  removeFromInventory,
  updateItemInInventory,
  archiveItem,
  unarchiveItem,
  addToInventory,
  fetchInventoryCount,
  fetchCategoryCount,
  fetchInventoryStats,
  setQueryObject,
  fetchOutOfStockItems,
  setOutOfStockOffset,
  fetchIncompleteItems,
  setIncompleteOffset,
  setOutOfStockInventory,
  refreshOutOfStockInventory,
  refreshIncompleteInventory,
} from 'src/store/inventorySlice';
import {showAlert} from 'src/store/alertSlice';
import {setNewMenuId} from 'src/store/inventorySlice';
import {
  getItemFromStoreItemNew,
  validateCreateOrUpdateItemsInputs,
  parseMenuList,
  checkPermission,
  convertBlobToBase64,
} from 'src/utils/helpers';
import AnalyticsHelper from 'src/utils/segment';
import {ITiming} from 'src/interfaces/store';
import {WEEK_DAYS} from 'src/utils/constants';
import {setIncompleteInventory} from 'src/store/inventorySlice';
import {fetchCategoriesAndSubcategories} from 'src/store/categorySlice';
import * as PERMISSIONS from 'src/utils/permissions';
import arrowBackBlack from 'src/assets/images/arrow-back-black.svg';
import itemSelected from 'src/assets/images/item-selected.svg';
import {ITEM_STATUS} from 'src/enums/item_status';

interface IProps extends RouteComponentProps {
  location: any;
  setShowDetails?: (show: boolean) => void;
  onNewMenuClick: () => void;
}

const ItemDetails = ({location, history, setShowDetails, onNewMenuClick}: IProps) => {
  const dispatch = useAppDispatch();
  const {isDesktop, isTablet, isMobile} = useAppSelector((state) => state.platform);

  const {menuIsOpen, user} = useAppSelector((state) => state.auth);
  const canEdit = checkPermission(user?.role.permissions, PERMISSIONS.EDIT_ITEM);
  const canDelete = checkPermission(user?.role.permissions, PERMISSIONS.DELETE_ITEM);
  const {
    outOfStockItems,
    inventoryCount,
    loadMoreOutOfStock,
    outOfStockOffset,
    incompleteItems,
    loadMoreIncomplete,
    incompleteOffset,
    inventoryTab,
  } = useAppSelector((state) => state.inventory);
  const isModifiersEnabled = useFeatureFlagEnabled('menu-modifiers');
  const {categories, subcategories} = useAppSelector((state) => state.category);
  const {id: storeId, menuList, showGlobalItemsContent} = useAppSelector((state) => state.store);

  const [items, setItems] = useState<Array<IItem>>([]);
  const [oldItems, setOldItems] = useState<IItem>();
  const [validate, setValidate] = useState(false);
  const [currentItem, setCurrentItem] = useState(0);
  const [status, setStatus] = useState<'disabled' | 'active' | 'loading' | 'success' | 'done'>('disabled');
  const [showSnackbar, setShowSnackbar] = useState(false);
  const [isPriceValid, setIsPriceValid] = useState(true);
  const [selectedMenu, setSelectedMenu] = useState<Array<string>>([]);
  const [removedMenu, setRemovedMenu] = useState<Array<string>>([]);
  const [newMenu, setNewMenu] = useState<Array<string>>([]);
  const [menuItem, setMenuItem] = useState<Array<string>>([]);
  const [timings, setTimings] = useState<Array<ITiming>>([]);
  const [isUnSave, setIsUnSave] = useState(false);
  const [totalItemsCount, setTotalItemsCount] = useState<number>(0);
  const [isClose, setIsClose] = useState(false);

  const dismissSnackbar = () => {
    setShowSnackbar(false);
  };

  const onItemUpdate = (item: IItem) => {
    const updatedItems = items.map((obj: any) => (obj.storeItemId === item.storeItemId ? {...item} : obj));
    setItems(updatedItems);
    const disabled =
      isModifiersEnabled && !item.canSellIndependently
        ? !item.name || !item.price
        : !item.name || !item.price || !item.category?.id || !item.size;
    setStatus(disabled ? 'disabled' : 'active');
    setIsUnSave(true);
  };

  const onItemRemove = (remove_item: IItem) => {
    if (!remove_item.storeItemId) return;
    dispatch(showLoading());
    itemService
      .removeItemFromStore(storeId, remove_item.storeItemId)
      .then((_) => {
        dispatch(hideLoading());
        const updatedItems = items.filter((item) => item.storeItemId !== remove_item.storeItemId);
        setItems(updatedItems);
        const updatedCurrentItem = currentItem - 1;
        if (updatedCurrentItem >= 0) setCurrentItem(updatedCurrentItem);
        dispatch(removeFromInventory(remove_item.storeItemId));
        dispatch(fetchInventoryCount());
        dispatch(fetchCategoryCount(inventoryTab === ITEM_STATUS.IN_STOCK));
        onClose();
      })
      .catch((error) => {
        dispatch(hideLoading());
        dispatch(
          showAlert({
            heading: 'Unable to remove',
            message: error?.message || 'Some error occurred while removing this items. Please, try again later',
          }),
        );
      });
  };

  const archiveUnarchiveItem = async (item: IItem) => {
    // if (!item.id) return;
    dispatch(showLoading());
    try {
      if (item.active) {
        await itemService.archiveItem(storeId, item.storeItemId);
        dispatch(archiveItem(item));
      } else {
        await itemService.unarchiveItem(storeId, item.storeItemId);
        dispatch(unarchiveItem(item));
      }
      dispatch(fetchInventoryCount());
      dispatch(fetchCategoryCount(inventoryTab === ITEM_STATUS.IN_STOCK));
      const updatedItems = items.map((obj) =>
        obj.storeItemId === item.storeItemId ? {...item, active: !obj.active} : obj,
      );
      setItems(updatedItems);
      dispatch(hideLoading());
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(
        showAlert({
          heading: `Unable to ${item.active ? 'archive' : 'unarchive'}`,
          message:
            error?.message ||
            `Some error occurred while ${
              item.active ? 'archiving' : 'unarchiving'
            } this items. Please, try again later`,
        }),
      );
    }
  };

  const createItem = async () => {
    setValidate(true);
    const {
      name,
      price,
      category,
      subcategory,
      upc,
      size,
      unitCount,
      description,
      canSellIndependently,
      quantity,
      active,
      image,
    } = items[currentItem];
    const parsedPriceInCents = !price ? '-1' : (parseFloat(price) * 100).toFixed(2);
    const inputsValid = validateCreateOrUpdateItemsInputs(items[currentItem]);
    const defaultMenuIds = menuList?.filter((menu) => menu.is_default)?.map((menu) => menu.id);
    let base64Image = undefined;
    if (image instanceof Blob) {
      await convertBlobToBase64(image as Blob).then((res) => (base64Image = res));
    }

    const getSelectedMenuIds = () => {
      if (canSellIndependently && newMenu.length > 0) {
        return newMenu;
      } else if (canSellIndependently && defaultMenuIds.length > 0) {
        return defaultMenuIds;
      } else {
        return undefined;
      }
    };

    if (inputsValid) {
      const data = {
        image: base64Image, // base64 encoded image
        name: name?.trim(), // Required field
        price: Number(parsedPriceInCents), // Required field
        size: size?.trim() || undefined, // Required field if canSellIndependently
        quantity: quantity || 1, // Required field if canSellIndependently
        category: category.name || undefined, // Required field if canSellIndependently
        sub_category: subcategory.name || undefined, // Required field if canSellIndependently
        unit_count: unitCount?.trim() || undefined, // Required field if canSellIndependently
        description: description?.substr(0, 350) || undefined, // There is limit to max 350 characters on BE,
        menu_ids: getSelectedMenuIds(), // New store items are created with default menus if canSellIndependently
        upc: upc?.trim() || undefined,
        active: active || true, // Required field if canSellIndependently and by default, new store items are active/in-stock
        sell_independently: canSellIndependently, // Required field
      };
      setStatus('loading');
      itemService
        .createItem(storeId, data)
        .then((response) => {
          const storeItem = getItemFromStoreItemNew(response.store_item);
          AnalyticsHelper.trackAddNewItem(storeItem, storeItem.isReviewed, 'Scratch');
          dispatch(addToInventory(storeItem));
          dispatch(fetchInventoryCount());
          dispatch(fetchCategoryCount(inventoryTab === ITEM_STATUS.IN_STOCK));
          setShowSnackbar(true);
          setStatus('success');
          setTimeout(() => {
            setStatus('done');
            onClose();
          }, 1500);
        })
        .catch((error) => {
          setStatus('active');
          dispatch(hideLoading());
          dispatch(
            showAlert({
              heading: 'Unable to create',
              message: error?.message || 'Some error occurred while saving record, Please try again later',
            }),
          );
        });
    } else {
      setStatus('active');
      dispatch(hideLoading());
      dispatch(
        showAlert({
          heading: 'Unable to create',
          message: 'Valid input for Item name, price, category, size is required. Please try again',
        }),
      );
      return;
    }
  };

  const saveDetails = async () => {
    setValidate(true);
    const {
      name,
      active,
      price,
      category,
      size,
      unitCount,
      subcategory,
      storeItemId,
      description,
      image,
      canSellIndependently,
    } = items[currentItem];
    const parsedPrice = !price ? '-1' : parseFloat(price).toFixed(2);
    const inputsValid = validateCreateOrUpdateItemsInputs(items[currentItem]);

    if (inputsValid) {
      setStatus('loading');
      const data = [
        {
          active,
          name,
          description,
          cognito_id: user?.username,
          store_item_id: storeItemId,
          price: Number(parsedPrice),
          sell_independently: canSellIndependently,
          category: category?.id ? category.name : undefined,
          subCategory: subcategory?.id ? subcategory.name : undefined,
          size: size ? size : undefined,
          unit_count: unitCount ? unitCount : undefined,
          removed_menus: canSellIndependently && removedMenu.length > 0 ? removedMenu : undefined,
          new_menus: canSellIndependently && newMenu.length > 0 ? newMenu : undefined,
          images: (image as string).length > 0 ? [image] : undefined,
          completed: location.state?.type === ITEM_TYPE.COMMON ? true : undefined, // completed flag is used for setting item status to completed only in item update flow for incomplete details
        },
      ];
      try {
        if (items[currentItem].image instanceof Blob) {
          let base64Image = undefined;
          await convertBlobToBase64(image as Blob).then((res) => (base64Image = res));
          const {image_url, isBackgroundRemoved} = await fileService.uploadFiles({
            image: base64Image,
            store_item_id: items[currentItem].storeItemId,
            store_id: storeId,
            category: category.name,
          });
          (data as any)[0]['images'] = [];
          (data as any)[0]['images']?.push(image_url);
          if (!isBackgroundRemoved) (data as any)[0]['is_reviewed'] = false;
        }
        await itemService.updateStoreItem(storeId, data, showGlobalItemsContent);
        if (location.state?.type === ITEM_TYPE.EDIT) fetchItemDetails(location.state?.id);
        const item = {...items[currentItem], price: parsedPrice};
        item.isAddedToDoorDash = false;
        item.isAddedToUberEats = false;
        item.isAddedToGrubHub = false;
        onItemUpdate({...item, completed: item.id === '' ? false : true});

        if (
          (data as any) &&
          (data as any).length !== 0 &&
          (data as any)[0]['images'] &&
          (data as any)[0]['images'].length !== 0
        )
          item.image = (data as any)[0]['images'][0];
        dispatch(updateItemInInventory(item));
        await dispatch(fetchInventoryStats())
          .unwrap()
          .then((inventoryStats: IInventoryStats) => {
            setQueryObject({minPrice: inventoryStats.minPrice, maxPrice: inventoryStats.maxPrice});
          });

        dispatch(fetchInventoryCount());
        dispatch(fetchCategoryCount(inventoryTab === ITEM_STATUS.IN_STOCK));
        setShowSnackbar(true);

        //segment: edit item
        if (location.state?.type === ITEM_TYPE.EDIT) AnalyticsHelper.trackEditItem(oldItems, items[currentItem]);
        setValidate(false);
        setStatus('success');
        setTimeout(() => {
          // if (currentItem === items.length - 1) setStatus('done');
          // else
          setStatus('active');
          dispatch(hideLoading());
        }, 1500);
      } catch (error: any) {
        setStatus('active');
        dispatch(
          showAlert({
            heading: 'Unable to update',
            message: error?.message || 'Some error occurred while updating record, Please try again later',
          }),
        );
      }
    } else {
      setStatus('active');
      dispatch(hideLoading());
      dispatch(
        showAlert({
          heading: 'Unable to update',
          message: 'Valid input for Item name, price, category, size is required. Please try again',
        }),
      );
      return;
    }
  };

  const fetchItemDetails = (itemId: string) => {
    dispatch(showLoading());
    itemService
      .getStoreItemDetails(storeId, itemId, showGlobalItemsContent)
      .then((item) => {
        setOldItems(item.storeItem);
        setItems([item.storeItem]);
        if (item.menu_timing.length) setTimings(item.menu_timing);
        setSelectedMenu(item.menu_item);
        setMenuItem(item.menu_item);
        setNewMenu([]);
        setRemovedMenu([]);
        setIsUnSave(false);
        dispatch(hideLoading());
      })
      .catch((error) => {
        setOldItems(undefined);
        setItems([]);
        setSelectedMenu([]);
        setMenuItem([]);
        setNewMenu([]);
        setRemovedMenu([]);
        setIsUnSave(false);
        dispatch(hideLoading());
        dispatch(
          showAlert({
            heading: 'Error',
            message: error?.message || 'Some error occurred while fetching record, Please try again later',
          }),
        );
      });
  };

  const createHours = () => {
    const hours = WEEK_DAYS.map((day) => ({
      id: '',
      openTime: '09:00',
      closeTime: '17:00',
      storeId: '',
      dayOfWeek: day.day_of_week,
      isClosed: true,
      invalid: false,
      updated: true,
    }));
    setTimings(hours);
  };

  useEffectOnce(() => {
    createHours();

    if (categories.length === 0 || subcategories.length === 0) {
      dispatch(fetchCategoriesAndSubcategories());
    }
  });

  const fetchItemData = () => {
    if (location.state?.type === ITEM_TYPE.OUT_OF_STOCK) {
      setItems(outOfStockItems);
    } else if (location.state?.type !== ITEM_TYPE.NEW) {
      if (location.state?.id) fetchItemDetails(location.state?.id);
      else setItems(incompleteItems);
    } else {
      setItems([
        {
          id: '',
          storeItemId: '',
          price: '',
          quantity: 0,
          image: '',
          name: '',
          size: '',
          unitCount: '',
          active: true,
          selected: false,
          completed: false,
          category: {id: '', name: ''},
          subcategory: {id: '', name: ''},
          upc: location.state?.upc || '',
          ean: location.state?.ean || '',
          description: '',
          createdAt: 0,
          modifierGroups: [],
        },
      ]);
    }
  };

  const onClose = () => {
    dispatch(setNewMenuId(''));
    if (isDesktop) {
      if (setShowDetails) setShowDetails(false);
    } else history.replace({pathname: '/inventory'});
  };

  const goToNextItem = () => {
    if (currentItem < items.length - 1) setCurrentItem(currentItem + 1);
    if (location.state?.type === ITEM_TYPE.OUT_OF_STOCK && loadMoreOutOfStock && currentItem + 2 === items.length) {
      dispatch(fetchOutOfStockItems())
        .unwrap()
        .then((res: any) => {
          setItems([...items, ...res]);
        });
    }
    if (location.state?.type === ITEM_TYPE.COMMON && loadMoreIncomplete && currentItem + 2 === items.length) {
      dispatch(fetchIncompleteItems())
        .unwrap()
        .then((res: any) => {
          setItems([...items, ...res]);
        });
    }
  };

  const getTotalCount = () => {
    if (location.state?.type === ITEM_TYPE.OUT_OF_STOCK) return inventoryCount.out_of_stock;
    else if (location.state?.type === ITEM_TYPE.COMMON) return inventoryCount.incomplete;
    else return items?.length;
  };

  const goToPrevItem = () => {
    if (currentItem > 0) setCurrentItem(currentItem - 1);
  };

  const getTitle = () => {
    switch (itemType) {
      case ITEM_TYPE.NEW:
        return 'Add Item';
      case ITEM_TYPE.EDIT:
        return 'Edit Item';
      case ITEM_TYPE.OUT_OF_STOCK:
        return 'Update items';
      default:
        return 'Complete items';
    }
  };

  useEffectOnce(() => {
    return () => {
      setItems([]);
      setValidate(false);
      setCurrentItem(0);
      setStatus('disabled');
      setShowSnackbar(false);
    };
  });

  useEffect(() => {
    fetchItemData();
  }, [location.state?.id]);

  useEffect(() => {
    if (outOfStockItems.length < outOfStockOffset) dispatch(setOutOfStockOffset(outOfStockItems.length));
    if (incompleteItems.length < incompleteOffset) dispatch(setIncompleteOffset(incompleteItems.length));
  }, [outOfStockItems, incompleteItems]);

  useEffect(() => {
    setTotalItemsCount(getTotalCount());
    if (location.state?.type === ITEM_TYPE.OUT_OF_STOCK) {
      dispatch(refreshOutOfStockInventory());
      dispatch(fetchOutOfStockItems())
        .unwrap()
        .then((res: any) => {
          if (res?.length) {
            dispatch(setOutOfStockInventory(res));
            setItems([...items, ...res]);
          } else {
            dispatch(setOutOfStockInventory([]));
          }
        });
    }

    if (location.state?.type === ITEM_TYPE.COMMON) {
      dispatch(refreshIncompleteInventory());
      dispatch(fetchIncompleteItems())
        .unwrap()
        .then((res: any) => {
          if (res?.length) {
            dispatch(setIncompleteInventory(res));
            setItems([...items, ...res]);
          } else {
            dispatch(setIncompleteInventory([]));
          }
        });
    }
  }, []);

  const itemType = location.state?.type;

  return (
    <>
      <IonPage id="item-details-page" className={`${isTablet && !menuIsOpen ? 'menu-close' : ''} snow-background`}>
        <IonContent fullscreen scrollY={false}>
          <Header
            noBackground
            leftButton={
              isDesktop ? (
                <IonButton
                  onClick={() => {
                    if (itemType === ITEM_TYPE.EDIT && (!!newMenu.length || !!removedMenu.length || isUnSave)) {
                      setIsClose(true);
                    } else {
                      if (onClose) onClose();
                    }
                  }}>
                  <IonIcon src={closeOutline} />
                </IonButton>
              ) : (
                <IonButton
                  onClick={() => {
                    if (itemType === ITEM_TYPE.EDIT && (!!newMenu.length || !!removedMenu.length || isUnSave)) {
                      setIsClose(true);
                    } else {
                      history.goBack();
                    }
                  }}>
                  <IonIcon src={arrowBackBlack} />
                </IonButton>
              )
            }
            title={getTitle()}
            rightButtons={
              itemType === ITEM_TYPE.SCANNED && (
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <IonButton color="dark" size="small" slot="start" fill="clear" onClick={goToPrevItem}>
                    <IonIcon slot="icon-only" icon={chevronBackOutline} />
                  </IonButton>
                  <IonButton color="dark" size="small" slot="end" fill="clear" onClick={goToNextItem}>
                    <IonIcon slot="icon-only" icon={chevronForwardOutline} />
                  </IonButton>
                </div>
              )
            }
          />

          {itemType === ITEM_TYPE.COMMON && (
            <ItemHeaderBar
              className={items[currentItem]?.completed ? 'completed' : 'incomplete'}
              title={items[currentItem]?.completed ? 'Complete item' : 'Incomplete item'}
              totalItems={totalItemsCount}
              currentItem={currentItem}
              goToNextItem={goToNextItem}
              goToPrevItem={goToPrevItem}
            />
          )}

          {itemType === ITEM_TYPE.OUT_OF_STOCK && (
            <ItemHeaderBar
              className={items[currentItem]?.active ? 'completed' : 'out-of-stock'}
              title={items[currentItem]?.active ? 'Complete item' : 'Out of Stock'}
              totalItems={totalItemsCount}
              currentItem={currentItem}
              goToNextItem={goToNextItem}
              goToPrevItem={goToPrevItem}
            />
          )}

          <div
            className={`body ${
              itemType === ITEM_TYPE.COMMON || itemType === ITEM_TYPE.OUT_OF_STOCK
                ? 'with-bulk-details-footer'
                : 'with-footer'
            }`}>
            <div className="content-container">
              {items[currentItem] ? (
                <ItemDetailsComponent
                  type={itemType}
                  key={items[currentItem].id}
                  categories={categories}
                  subcategories={subcategories}
                  validate={validate}
                  item={items[currentItem]}
                  onItemUpdate={onItemUpdate}
                  onItemRemove={itemType === ITEM_TYPE.NEW ? undefined : onItemRemove}
                  archiveUnarchiveItem={archiveUnarchiveItem}
                  expandedForTab={!isMobile}
                  goToNextItem={goToNextItem}
                  goToPrevItem={goToPrevItem}
                  setIsPriceValid={setIsPriceValid}
                  menuList={parseMenuList(menuList)}
                  timings={timings}
                  selectedMenu={selectedMenu}
                  setSelectedMenu={setSelectedMenu}
                  menuItem={menuItem}
                  removedMenu={removedMenu}
                  setRemovedMenu={setRemovedMenu}
                  newMenu={newMenu}
                  setNewMenu={setNewMenu}
                  setTimings={setTimings}
                  createHours={createHours}
                  onNewMenuClick={onNewMenuClick}
                  canEdit={canEdit}
                  canDelete={canDelete}
                />
              ) : (
                <NoItem message="No item found!" />
              )}
            </div>
          </div>
          <IonFooter>
            {status === 'done' ? (
              <IonButton className="btn-dark" expand="block" disabled>
                Save Changes
              </IonButton>
            ) : (
              <IonButton
                disabled={status === 'disabled' || status === 'loading' || !isPriceValid}
                className={`btn-dark ${status === 'success' ? 'success' : ''}`}
                expand="block"
                onClick={itemType === ITEM_TYPE.NEW ? createItem : saveDetails}>
                {itemType === ITEM_TYPE.COMMON ? (
                  'Save update'
                ) : itemType === ITEM_TYPE.OUT_OF_STOCK ? (
                  'Save edit'
                ) : (
                  <>
                    {status === 'disabled' && 'Enter your Details'}
                    {status === 'active' && itemType === ITEM_TYPE.NEW && 'Add Item'}
                    {status === 'active' && itemType !== ITEM_TYPE.NEW && 'Save and Continue'}
                  </>
                )}
                {status === 'loading' && <IonSpinner />}
                {status === 'success' && <IonImg src={itemSelected} />}
              </IonButton>
            )}
          </IonFooter>
        </IonContent>
      </IonPage>

      <IonAlert
        isOpen={isClose}
        header="Unsaved Changes"
        onDidDismiss={() => setIsClose(false)}
        message={'You have some unsaved changes, are you sure you want to leave?'}
        buttons={[
          {
            text: 'Yes',
            role: 'cancel',
            cssClass: 'secondary',
            handler: () => {
              if (onClose) onClose();
              else history.goBack();
            },
          },
          {
            text: 'No',
            handler: () => {
              // props.saveDetails();
              setIsClose(false);
            },
          },
        ]}
      />

      <Snackbar
        show={showSnackbar}
        type="success"
        title="Details saved successfully"
        onButtonClick={dismissSnackbar}
        onDismiss={dismissSnackbar}
      />
    </>
  );
};

export default ItemDetails;
