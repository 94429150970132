import {ComponentType} from 'react';
import * as Pages from 'src/pages';
import * as PERMISSIONS from 'src/utils/permissions';

export interface IRoute {
  path: string;
  component?: ComponentType<any>;
  public?: boolean;
  neutral?: boolean;
  permissions?: Array<string | undefined>;
  mobileOnly?: boolean;
}

export const routes: IRoute[] = [
  {path: '/', component: Pages.Splash, neutral: true},
  {path: '/welcome', component: Pages.Welcome, public: true},
  {path: '/onboarding', component: Pages.Onboarding, public: true, mobileOnly: true},
  {path: '/auth/login', component: Pages.Login, public: true},
  {path: '/auth/forgot-password', component: Pages.ForgotPassword, public: true},
  {path: '/auth/reset-password', component: Pages.ResetPassword, public: true},
  {path: '/auth/new-password', component: Pages.NewPassword, public: true},
  {
    path: '/support/:type',
    component: Pages.InAppBrowser,
    neutral: true,
    mobileOnly: true,
    permissions: [PERMISSIONS.VIEW_KNOWLEDGE_BASE],
  },
  {
    path: '/store/manage',
    component: Pages.StoreManage,
    permissions: [
      PERMISSIONS.VIEW_STORE_DETAILS,
      PERMISSIONS.EDIT_STORE_HOURS,
      PERMISSIONS.VIEW_PRINTER_SETTINGS,
      PERMISSIONS.VIEW_MENU,
      PERMISSIONS.VIEW_EMPLOYEES,
    ],
  },
  {
    path: '/store/manage/details',
    component: Pages.ManageStoreDetails,
    permissions: [PERMISSIONS.VIEW_STORE_DETAILS],
    mobileOnly: true,
  },
  {path: '/store/manage/menu', component: Pages.MenuList, permissions: [PERMISSIONS.VIEW_MENU], mobileOnly: true},
  {
    path: '/store/manage/menu/details',
    component: Pages.Details,
    permissions: [PERMISSIONS.VIEW_MENU],
    mobileOnly: true,
  },
  {
    path: '/store/manage/hours',
    component: Pages.ManageStoreHours,
    permissions: [PERMISSIONS.VIEW_STORE_DETAILS],
    mobileOnly: true,
  },
  {
    path: '/store/manage/welcome',
    component: Pages.ManageWelcomePackage,
    permissions: [PERMISSIONS.VIEW_STORE_DETAILS],
    mobileOnly: true,
  },
  {
    path: '/store/manage/printer',
    component: Pages.PrinterOverview,
    permissions: [PERMISSIONS.VIEW_PRINTER_SETTINGS],
    mobileOnly: true,
  },
  {
    path: '/store/manage/employees',
    component: Pages.StoreEmployees,
    permissions: [PERMISSIONS.VIEW_EMPLOYEES],
    mobileOnly: true,
  },
  {
    path: '/store/manage/employees/add',
    component: Pages.AddStoreEmployee,
    permissions: [PERMISSIONS.CREATE_EMPLOYEES],
    mobileOnly: true,
  },
  {
    path: '/store/manage/employees/edit',
    component: Pages.EditStoreEmployee,
    permissions: [PERMISSIONS.EDIT_EMPLOYEES],
    mobileOnly: true,
  },
  {path: '/store/manage/stores', component: Pages.StoresList, permissions: [PERMISSIONS.VIEW_STORE_DETAILS]},
  {path: '/orders', component: Pages.OrdersList, permissions: [PERMISSIONS.VIEW_ORDERS]},
  {path: '/orders/details', component: Pages.OrderDetails, permissions: [PERMISSIONS.VIEW_ORDERS], mobileOnly: true},
  {path: '/orders/history', component: Pages.OrderHistory, permissions: [PERMISSIONS.VIEW_ORDER_HISTORY]},
  {
    path: '/settings',
    component: Pages.SettingsManage,
    permissions: [PERMISSIONS.VIEW_ACCOUNT_DETAILS, PERMISSIONS.VIEW_ACCOUNT_NOTIFICATIONS],
  },
  {
    path: '/settings/details',
    component: Pages.UserDetails,
    permissions: [PERMISSIONS.VIEW_ACCOUNT_DETAILS],
    mobileOnly: true,
  },
  {
    path: '/settings/password',
    component: Pages.ChangePassword,
    permissions: [PERMISSIONS.VIEW_ACCOUNT_DETAILS],
    mobileOnly: true,
  },
  {
    path: '/settings/email',
    component: Pages.ChangeEmail,
    permissions: [PERMISSIONS.VIEW_ACCOUNT_DETAILS],
    mobileOnly: true,
  },
  {
    path: '/settings/notifications',
    component: Pages.Notifications,
    permissions: [PERMISSIONS.VIEW_ACCOUNT_NOTIFICATIONS],
    mobileOnly: true,
  },
  {path: '/items/common', component: Pages.CommonItems, permissions: [PERMISSIONS.VIEW_INVENTORY]},
  {
    path: '/items/common/search',
    component: Pages.SearchItem,
    permissions: [PERMISSIONS.VIEW_INVENTORY],
    mobileOnly: true,
  },
  {path: '/items/details', component: Pages.ItemDetails, permissions: [PERMISSIONS.VIEW_INVENTORY]},
  {path: '/inventory/sorting', component: Pages.InventorySorting, permissions: [PERMISSIONS.VIEW_INVENTORY]},
  {path: '/inventory', component: Pages.InventoryList, permissions: [PERMISSIONS.VIEW_INVENTORY]},
  {path: '/inventory/filtering', component: Pages.InventoryFiltering, permissions: [PERMISSIONS.VIEW_INVENTORY]},
  {path: '/billing', component: Pages.Billing, permissions: [PERMISSIONS.VIEW_BILLING]},
  {path: '/partners', component: Pages.Partners, permissions: [PERMISSIONS.EDIT_PARTNERS_STATUS]},
  {path: '/partners/schedule', component: Pages.SchedulePartner, permissions: [PERMISSIONS.EDIT_PARTNERS_STATUS]},
  {path: '/reports', component: Pages.Reports, permissions: [PERMISSIONS.VIEW_REPORTS]},
  {
    path: '/partners/details',
    component: Pages.PartnerDetails,
    permissions: [PERMISSIONS.EDIT_PARTNERS_STATUS],
    mobileOnly: true,
  },
  {
    path: '/insights',
    component: Pages.Insights,
    permissions: [
      PERMISSIONS.VIEW_INSIGHTS_OVERVIEW,
      PERMISSIONS.VIEW_INSIGHTS_INVENTORY,
      PERMISSIONS.VIEW_INSIGHTS_ORDERS,
      PERMISSIONS.VIEW_INSIGHTS_PAYOUTS,
      PERMISSIONS.VIEW_INSIGHTS_REPORTS,
      PERMISSIONS.VIEW_INSIGHTS_STORES,
    ],
  },
];
