import {useEffect, useState} from 'react';
import {IonButton, IonCheckbox, IonIcon, IonInput, IonLabel} from '@ionic/react';
import {
  documentTextOutline,
  swapHorizontalOutline,
  trashOutline,
  addOutline,
  expandOutline,
  removeOutline,
  warningOutline,
} from 'ionicons/icons';
import './styles.scss';
import {useFeatureFlagEnabled} from 'posthog-js/react';
import InstructionsCard from 'src/pages/Orders/components/InstructionsCard';
import {IModifierGroup, IOrderItemDetails} from 'src/interfaces/item';
import ItemImage from 'src/components/ItemImage';
import {IOrderUpdatePayloadItem} from 'src/interfaces/order';
import {useEffectOnce} from 'src/hooks';
import {PATCH_CART_TYPE} from 'src/enums/order';
import {PARTNER} from 'src/enums/partner';
import ModifierGroup from 'src/pages/Orders/components/ModifierGroup';
import AnalyticsHelper from 'src/utils/segment';

interface IProps {
  item: IOrderItemDetails;
  orderId: string;
  checkedItems?: Array<string>;
  outOfStockItems?: Array<string>;
  isSelectable?: boolean;
  payload?: Array<IOrderUpdatePayloadItem>;
  partner?: string;
  closeEditModal?: () => void;
  setCheckedItems?: (e: any) => void;
  onChangeQuantity?: (item: IOrderUpdatePayloadItem) => void;
  onChangeOutOfStock?: (itemId: string) => void;
  setShowSubstitutionsList?: (e: boolean) => void;
  setItemToSubstitute?: (item: any) => void;
  setDataForDetailsModal: (e: IOrderItemDetails) => void;
}

const ReceiptItem = ({
  item,
  orderId,
  checkedItems,
  outOfStockItems,
  isSelectable,
  payload,
  partner,
  setCheckedItems,
  onChangeQuantity,
  onChangeOutOfStock,
  setShowSubstitutionsList,
  setItemToSubstitute,
  setDataForDetailsModal,
}: IProps) => {
  const [quantity, setQuantity] = useState(item.quantity);
  const ghSubstitutionEnabled = useFeatureFlagEnabled('GH-substitutions') && partner === PARTNER.GRUB_HUB;
  const ddSubstitutionEnabled = useFeatureFlagEnabled('DD-substitutions') && partner === PARTNER.DOOR_DASH;
  const ueSubstitutionEnabled = useFeatureFlagEnabled('UE-substitutions') && partner === PARTNER.UBER_EATS;
  const isModifiersEnabled = useFeatureFlagEnabled('menu-modifiers');

  const increaseQuantity = () => {
    setQuantity(Number(quantity) + 1);
    if (onChangeQuantity) {
      onChangeQuantity({
        action_type: PATCH_CART_TYPE.REDUCE_QUANTITY,
        order_item_id: item.id,
        quantity: Number(quantity) + 1,
      });
    }
  };

  const decreaseQuantity = () => {
    const updatedQuantity = Number(quantity) - 1;
    if (updatedQuantity >= 0) setQuantity(updatedQuantity);
    if (onChangeQuantity) {
      if (updatedQuantity <= 0) {
        onChangeQuantity({
          action_type: PATCH_CART_TYPE.REMOVE_ITEM,
          order_item_id: item.id,
        });
      } else {
        onChangeQuantity({
          action_type: PATCH_CART_TYPE.REDUCE_QUANTITY,
          order_item_id: item.id,
          quantity: updatedQuantity,
        });
      }
    }
  };

  const onSelectItem = (e: any) => {
    e.stopPropagation();
    if (checkedItems && setCheckedItems) {
      if (!checkedItems.includes(item.id)) {
        setCheckedItems([...checkedItems, item.id]);
      } else {
        setCheckedItems(checkedItems.filter((itemId) => itemId !== item.id));
      }
    }
  };

  useEffect(() => {
    setQuantity(item.quantity);
  }, [item]);

  useEffectOnce(() => {
    // If item is already in the payload then use the updated quantity.
    if (!!payload) {
      const payloadItem = payload.find((p: any) => p.order_item_id === item.id);
      if (payloadItem?.action_type === PATCH_CART_TYPE.REMOVE_ITEM) {
        setQuantity(0);
      } else if (payloadItem?.action_type === PATCH_CART_TYPE.REDUCE_QUANTITY && payloadItem?.quantity) {
        setQuantity(payloadItem.quantity);
      }
    }
  });

  const checked = checkedItems && checkedItems.includes(item.id);
  const isItemSelectable = isSelectable && checkedItems && setCheckedItems;
  const isSubstitute = item.action === 'substitute';
  const isItemEdited = quantity !== item.quantity;
  const isDefaultInstructions = item.itemLevelInstructions?.toLowerCase() === 'pack item properly';

  return (
    <>
      <div
        id="receipt-item"
        className={`${isItemSelectable && checked ? 'checked' : ''} ${
          isSubstitute && onChangeQuantity ? 'disabled' : ''
        } ${isItemEdited ? 'edited ' : ''}  ${isItemSelectable ? 'is-selectable' : ''}`}
        onClick={isItemSelectable ? onSelectItem : undefined}>
        <div className="item-row" onClick={onSelectItem}>
          {isItemSelectable && (
            <div className="checkbox">
              <IonCheckbox checked={checked} onClick={onSelectItem} />
            </div>
          )}

          <div className="name-size-count-container">
            <div className="item-details">
              <p className="name">{item.itemName}</p>
              {item.size && (
                <p className="item-desc">
                  <span className="desc-heading">Size</span>
                  <span className="desc-value">{item.size}</span>
                </p>
              )}
              {item.unitCount && (
                <p className="item-desc">
                  <span className="desc-heading">Count</span>
                  <span className="desc-value">{item.unitCount}</span>
                </p>
              )}
              {!item.size && item.description && <p className="item-desc">{item.description}</p>}
            </div>
          </div>

          <div
            className="img-container"
            onClick={(e) => {
              e.stopPropagation();
              setDataForDetailsModal(item);
            }}>
            <IonIcon icon={expandOutline} />
            <ItemImage category={item.categoryName} image={item.image} />
          </div>
        </div>
        <div className="details-container">
          {isModifiersEnabled && !!item.modifierGroups?.length && (
            <div className="modifiers">
              {item.modifierGroups?.map((modifierGroupItem: IModifierGroup, index: number) => {
                return <ModifierGroup key={index} modifierGroupItem={modifierGroupItem} />;
              })}
              <hr />
            </div>
          )}

          <div className={`size-price-info`}>
            {item.quantity && (isSubstitute || !onChangeQuantity) && <p className="quantity">{`x ${item.quantity}`}</p>}

            {onChangeQuantity && (
              <div className="substitution-actions">
                <div className="substitution-actions-list">
                  {!isSubstitute && (
                    <div className="quantity-update-btn">
                      <IonButton onClick={decreaseQuantity} disabled={quantity <= 0}>
                        {quantity === 1 ? (
                          <IonIcon icon={trashOutline} />
                        ) : (
                          quantity !== 0 && quantity > 1 && <IonIcon icon={removeOutline} />
                        )}
                      </IonButton>
                      <IonInput readonly type="number" inputmode="numeric" placeholder="1" value={quantity} />
                      <IonButton
                        onClick={increaseQuantity}
                        disabled={quantity >= item.quantity || outOfStockItems?.includes(item.id)}>
                        <IonIcon icon={addOutline} />
                      </IonButton>
                    </div>
                  )}

                  {(ueSubstitutionEnabled || ddSubstitutionEnabled || ghSubstitutionEnabled) && !isSubstitute && (
                    <div
                      data-intercom-target="Substitution button"
                      className="substitution-icon"
                      onClick={() => {
                        setItemToSubstitute && setItemToSubstitute(item);
                        setShowSubstitutionsList && setShowSubstitutionsList(true);
                        AnalyticsHelper.trackBeginSubstitutionFlowClick(orderId, item);
                      }}>
                      <IonIcon icon={swapHorizontalOutline} />
                    </div>
                  )}

                  {onChangeOutOfStock && !isSubstitute && quantity === 0 && (
                    <div
                      className="out-of-stock-checkbox"
                      onClick={() => {
                        onChangeOutOfStock(item.id);
                        // We need to use this condition in reverse.
                        if (!outOfStockItems?.includes(item.id)) {
                          setQuantity(0);
                        } else {
                          setQuantity(item.quantity);
                        }
                      }}>
                      <IonCheckbox checked={outOfStockItems?.includes(item.id)} />
                      <IonLabel>Out of stock for future orders</IonLabel>
                    </div>
                  )}
                </div>
              </div>
            )}

            <p className="price">
              {`$ ${(parseFloat(item.itemPrice.toString()) * quantity).toFixed(2) || item.itemTotalPrice}`}
            </p>
          </div>

          {((item.itemLevelInstructions && !isDefaultInstructions) ||
            item.proposedSubstitutes?.length ||
            (isSubstitute && onChangeQuantity) ||
            quantity <= 0 ||
            outOfStockItems?.includes(item.id)) && (
            <div className="instructions">
              {((item.itemLevelInstructions && !isDefaultInstructions) || item.proposedSubstitutes?.length) && (
                <InstructionsCard
                  variant="warning"
                  title="Item Instructions"
                  description={item.itemLevelInstructions}
                  icon={documentTextOutline}
                  substitutes={item.proposedSubstitutes}
                />
              )}

              {isSubstitute && onChangeQuantity && (
                <InstructionsCard
                  variant="warning"
                  icon={warningOutline}
                  title="Item editing disabled"
                  description={`You are unable to edit substituted items.\n Please contact support to resolve any further issues.`}
                />
              )}

              {/* TODO: Uncomment and implement the functionality */}
              {/* <InstructionsCard
                variant="muted"
                title="Quantity edited"
                description="Quantity edited for this item"
                icon={createOutline}
              />

              <InstructionsCard
                variant="muted"
                title="Substituted item"
                description="This item has been substituted"
                icon={swapHorizontalOutline}
              /> */}

              {(quantity <= 0 || outOfStockItems?.includes(item.id)) && (
                <InstructionsCard variant="danger" title="Item removed" icon={trashOutline} />
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default ReceiptItem;
