export const STATES: {[key: string]: string[]} = {
  AL: ['AL', 'Alabama'],
  AK: ['AK', 'Alaska'],
  AZ: ['AZ', 'Arizona'],
  AR: ['AR', 'Arkansas'],
  AS: ['AS', 'American Samoa'],
  CA: ['CA', 'California'],
  CO: ['CO', 'Colorado'],
  CT: ['CT', 'Connecticut'],
  DE: ['DE', 'Delaware'],
  DC: ['DC', 'District of Columbia'],
  FL: ['FL', 'Florida'],
  GA: ['GA', 'Georgia'],
  GU: ['GU', 'Guam'],
  HI: ['HI', 'Hawaii'],
  ID: ['ID', 'Idaho'],
  IL: ['IL', 'Illinois'],
  IN: ['IN', 'Indiana'],
  IA: ['IA', 'Iowa'],
  KS: ['KS', 'Kansas'],
  KY: ['KY', 'Kentucky'],
  LA: ['LA', 'Louisiana'],
  ME: ['ME', 'Maine'],
  MD: ['MD', 'Maryland'],
  MA: ['MA', 'Massachusetts'],
  MI: ['MI', 'Michigan'],
  MN: ['MN', 'Minnesota'],
  MS: ['MS', 'Mississippi'],
  MO: ['MO', 'Missouri'],
  MT: ['MT', 'Montana'],
  NE: ['NE', 'Nebraska'],
  NV: ['NV', 'Nevada'],
  NH: ['NH', 'New Hampshire'],
  NJ: ['NJ', 'New Jersey'],
  NM: ['NM', 'New Mexico'],
  NY: ['NY', 'New York'],
  NC: ['NC', 'North Carolina'],
  ND: ['ND', 'North Dakota'],
  CM: ['CM', 'Northern Mariana Islands'],
  OH: ['OH', 'Ohio'],
  OK: ['OK', 'Oklahoma'],
  OR: ['OR', 'Oregon'],
  PA: ['PA', 'Pennsylvania'],
  PR: ['PR', 'Puerto Rico'],
  RI: ['RI', 'Rhode Island'],
  SC: ['SC', 'South Carolina'],
  SD: ['SD', 'South Dakota'],
  TN: ['TN', 'Tennessee'],
  TX: ['TX', 'Texas'],
  TT: ['TT', 'Trust Territories'],
  UT: ['UT', 'Utah'],
  VT: ['VT', 'Vermont'],
  VA: ['VA', 'Virginia'],
  VI: ['VI', 'Virgin Islands'],
  WA: ['WA', 'Washington'],
  WV: ['WV', 'West Virginia'],
  WI: ['WI', 'Wisconsin'],
  WY: ['WY', 'Wyoming'],
};

export const getStateAbbreviation = (stateName: string): string => {
  let result = stateName;
  Object.keys(STATES).forEach((abbreviation) => {
    const synonyms = STATES[abbreviation].map((state) => state.toLowerCase().trim());

    if (synonyms.indexOf(stateName.toLowerCase().trim()) > -1) {
      result = abbreviation;
      return;
    }
  });

  return result;
};
