import {useEffect, useState} from 'react';
import {IonButton, IonCheckbox, IonIcon, IonInput, IonLabel} from '@ionic/react';
import './styles.scss';
import {
  addOutline,
  documentTextOutline,
  expandOutline,
  refreshOutline,
  removeOutline,
  trashOutline,
} from 'ionicons/icons';
import ItemImage from 'src/components/ItemImage';
import {IModifierGroup, IOrderItemDetails} from 'src/interfaces/item';
import ModifierGroup from 'src/pages/Orders/components/ModifierGroup';
import InstructionsCard from 'src/pages/Orders/components/InstructionsCard';
import {useFeatureFlagEnabled} from 'posthog-js/react';
import {getSubstitutedItemQuantity} from 'src/utils/helpers';

interface IProps {
  item: IOrderItemDetails | any;
  orderItem?: IOrderItemDetails;
  outOfStockItems?: Array<string>;
  isSubstitute?: boolean;
  originalQuantity?: number;
  onChangeQuantity?: (itemId: string, quantity: number) => void;
  onChangeOutOfStock?: (itemId: string) => void;
  onUndoSubstitution?: (itemId: string) => void;
  setDataForDetailsModal: (e: IOrderItemDetails) => void;
}

const SubstitutedReceiptItem = ({
  item,
  orderItem,
  outOfStockItems,
  isSubstitute,
  originalQuantity,
  onChangeQuantity,
  onUndoSubstitution,
  onChangeOutOfStock,
  setDataForDetailsModal,
}: IProps) => {
  const isModifiersEnabled = useFeatureFlagEnabled('menu-modifiers');
  const [quantity, setQuantity] = useState(1);

  const increaseQuantity = () => {
    const updatedQuantity = Number(quantity) + 1;
    const originalTotalPrice = Number(orderItem?.itemTotalPrice) || 0;
    const totalSubstitutePrice = (Number(item?.price) || 0) * updatedQuantity;
    if (totalSubstitutePrice <= originalTotalPrice) {
      setQuantity(Number(quantity) + 1);
      if (onChangeQuantity) {
        onChangeQuantity(item.id, Number(quantity) + 1);
      }
    }
  };

  const decreaseQuantity = () => {
    const updatedQuantity = Number(quantity) - 1;
    if (updatedQuantity >= 1) setQuantity(updatedQuantity);
    if (onChangeQuantity) {
      onChangeQuantity(item.id, updatedQuantity);
    }
  };

  useEffect(() => {
    if (isSubstitute) {
      const updatedQuantity = getSubstitutedItemQuantity(orderItem as IOrderItemDetails, item, originalQuantity);
      setQuantity(updatedQuantity);
      if (onChangeQuantity) onChangeQuantity(item.id, updatedQuantity);
    }
  }, []);

  return (
    <div id="substituted-receipt-item" className={isSubstitute ? 'substituted' : 'non-substituted'}>
      {!isSubstitute && <div className="original-item">Original item</div>}
      <div id="substituted-receipt-container">
        <div className="item-row">
          <div className="details-container">
            <div className="item-details">
              <p className="name">{item.itemName || item.name}</p>
              {item.size && item.size.toUpperCase() !== 'NA' && (
                <p className="item-desc">
                  <span className="desc-heading">Size</span>
                  <span className="desc-value">{item.size}</span>
                </p>
              )}
              {item.unitCount && (
                <p className="item-desc">
                  <span className="desc-heading">Count</span>
                  <span className="desc-value">{item.unitCount}</span>
                </p>
              )}
              {(!item.size || item.size.toUpperCase() === 'NA') && item.description && (
                <p className="item-desc">{item.description}</p>
              )}
            </div>
          </div>

          <div
            className="img-container"
            onClick={(e) => {
              e.stopPropagation();
              setDataForDetailsModal(item);
            }}>
            <IonIcon icon={expandOutline} />
            <ItemImage category={item.categoryName} image={item.image} />
          </div>
        </div>

        {isModifiersEnabled && !!item.modifierGroups?.length && (
          <div className="modifiers">
            {item.modifierGroups?.map((modifierGroupItem: IModifierGroup, index: number) => {
              return <ModifierGroup key={index} modifierGroupItem={modifierGroupItem} />;
            })}
            <hr />
          </div>
        )}

        <div className={`size-price-info`}>
          <div className="quantity-control">
            {item.quantity && (isSubstitute || !onChangeQuantity) && <p className="quantity">{`x ${item.quantity}`}</p>}

            {isSubstitute && (
              <div className="quantity-update-btn">
                <IonButton onClick={decreaseQuantity} disabled={quantity <= 1}>
                  {quantity > 1 && <IonIcon icon={removeOutline} />}
                </IonButton>
                <IonInput readonly type="number" inputmode="numeric" placeholder="1" value={quantity} />
                <IonButton onClick={increaseQuantity}>
                  <IonIcon icon={addOutline} />
                </IonButton>
              </div>
            )}

            {!isSubstitute && onChangeOutOfStock && (
              <div className="out-of-stock-checkbox">
                <IonCheckbox
                  checked={outOfStockItems?.includes(item.id)}
                  onClick={() => {
                    onChangeOutOfStock(item.id);
                    // We need to use this condition in reverse.
                    if (!outOfStockItems?.includes(item.id)) {
                      setQuantity(0);
                    } else {
                      setQuantity(item.quantity);
                    }
                  }}
                />
                <IonLabel>Out of stock for future orders</IonLabel>
              </div>
            )}
          </div>

          {isSubstitute ? (
            <p className="price">{`$ ${
              (parseFloat(item.price.toString()) * quantity).toFixed(2) || item.itemTotalPrice
            }`}</p>
          ) : (
            <p className="price">
              {`$ ${(parseFloat(item.itemPrice.toString()) * item.quantity).toFixed(2) || item.itemTotalPrice}`}
            </p>
          )}
        </div>

        {!isSubstitute &&
          item.itemLevelInstructions &&
          item.itemLevelInstructions.toLowerCase() !== 'pack item properly' && (
            <InstructionsCard
              variant="warning"
              title="Item Instructions"
              description={item.itemLevelInstructions}
              icon={documentTextOutline}
            />
          )}

        {!isSubstitute && <InstructionsCard variant="danger" title="Item removed" icon={trashOutline} />}

        {isSubstitute && onUndoSubstitution && (
          <IonButton className="undo-action" onClick={() => onUndoSubstitution(item.id)}>
            <IonIcon className="undo" icon={refreshOutline} />
            <p>Undo</p>
          </IonButton>
        )}
      </div>
    </div>
  );
};

export default SubstitutedReceiptItem;
