/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router';
import {ITEM_STATUS} from 'src/enums/item_status';

import {useAppDispatch, useAppSelector, useEffectOnce} from 'src/hooks';
import {IInventoryCount, IQueryObject} from 'src/interfaces/item';
import {
  fetchArchivedItems,
  fetchCategoryCount,
  fetchInStockItems,
  fetchInventoryCount,
  refreshArchivedInventory,
  refreshInStockInventory,
  setArchivedInventory,
  setInStockInventory,
  setIsFiltered,
  setQueryObject,
} from 'src/store/inventorySlice';
import {setIsEditable, setSelectedCategory, setExceptItems, setSelectedItems} from 'src/store/inventorySlice';
import {fetchCategoriesAndSubcategories} from 'src/store/categorySlice';
import {DEFAULT_QUERY_OBJECT} from 'src/utils/constants';
import AnalyticsHelper from 'src/utils/segment';
import {parseMenuList} from 'src/utils/helpers';

import {Desktop, Tablet, Mobile} from 'src/utils/platform';
import DesktopScreen from './Desktop';
import MobileScreen from './Mobile';
import TabScreen from './Tablet';

interface IProps extends RouteComponentProps {
  location: any;
  showSearch: boolean;
  searchInventory: (refreshOffset?: number) => void;
  setShowFilters?: React.Dispatch<
    React.SetStateAction<{
      showPopover: boolean;
      event: undefined;
    }>
  >;
}

const InventoryFiltering = ({location, history, showSearch, searchInventory, setShowFilters}: IProps) => {
  const dispatch = useAppDispatch();
  const {queryObject, inventoryStats, inventoryTab, filteredCategoryCount} = useAppSelector((state) => state.inventory);
  const categories = useAppSelector((state) => state.category).categories.filter((category) =>
    filteredCategoryCount.some((element) => element.id === category.id),
  );
  const {menuList} = useAppSelector((state) => state.store);
  const {isDesktop} = useAppSelector((state) => state.platform);

  const [filterObject, setFilterObject] = useState<IQueryObject>({...queryObject});
  const [isOnClose, setIsOnClose] = useState(false);

  const selectFilter = (value: {[key: string]: any}) => {
    setIsOnClose(false);
    setFilterObject({...filterObject, ...value});
  };

  const onFilter = async () => {
    try {
      if (showSearch) searchInventory(0);
      dispatch(refreshInStockInventory());
      await dispatch(fetchInStockItems())
        .unwrap()
        .then((items) => {
          dispatch(setInStockInventory(items));
        });
      dispatch(refreshArchivedInventory());
      await dispatch(fetchArchivedItems())
        .unwrap()
        .then((items) => {
          dispatch(setArchivedInventory(items));
        });
    } catch (error: any) {
      console.log(`on filter error ${error?.message}`);
    } finally {
      if (
        filterObject.menuList.length === 0 &&
        filterObject.categories.length === 0 &&
        filterObject.endDate === DEFAULT_QUERY_OBJECT.endDate &&
        filterObject.startDate === DEFAULT_QUERY_OBJECT.startDate &&
        Math.ceil(Number(filterObject.maxPrice)) === Math.ceil(Number(props.inventoryStats.maxPrice)) &&
        Math.floor(Number(filterObject.minPrice)) === Math.floor(Number(props.inventoryStats.minPrice))
      )
        dispatch(setIsFiltered(false));
      else dispatch(setIsFiltered(true));
      closeFilterPage();
    }
  };

  const closeFilterPage = () => {
    if (!isDesktop) history.replace({pathname: '/inventory'});
    else if (setShowFilters)
      setShowFilters({
        showPopover: false,
        event: undefined,
      });
  };

  const onClose = () => {
    if (checkIsDifferent()) {
      dispatch(
        setQueryObject({
          ...queryObject,
          categories: filterObject.categories,
          endDate: filterObject.endDate,
          startDate: filterObject.startDate,
          maxPrice: Number(filterObject.maxPrice),
          minPrice: Number(filterObject.minPrice),
          menuList: filterObject.menuList,
          active: filterObject.active,
          incomplete: filterObject.incomplete,
          isRestricted: filterObject.isRestricted,
        }),
      );
      dispatch(fetchInventoryCount())
        .unwrap()
        .then((inventoryCount: IInventoryCount) => {
          AnalyticsHelper.trackInventoryFilter({
            ...queryObject,
            availability: location.state?.title,
            numberOfResults:
              location.state?.title === ITEM_STATUS.IN_STOCK ? inventoryCount.for_sale : inventoryCount.on_hold,
            categories: filterObject.categories,
            categoryNames: categories
              .filter((category) => filterObject.categories.find((categoryId) => categoryId === category.id))
              .map((category) => category.name),
            endDate: filterObject.endDate,
            startDate: filterObject.startDate,
            maxPrice: Number(filterObject.maxPrice),
            minPrice: Number(filterObject.minPrice),
            active: filterObject.active,
            incomplete: filterObject.incomplete,
            isRestricted: filterObject.isRestricted,
          });
        });
      dispatch(fetchCategoryCount(location.state?.title === ITEM_STATUS.IN_STOCK));
      dispatch(setIsEditable(false));
      dispatch(setSelectedCategory([]));
      dispatch(setExceptItems([]));
      dispatch(setSelectedItems([]));
      onFilter();
    } else {
      closeFilterPage();
    }
  };

  const onReset = () => {
    setIsOnClose(true);
    setFilterObject({
      ...queryObject,
      ...inventoryStats,
      startDate: DEFAULT_QUERY_OBJECT.startDate,
      endDate: DEFAULT_QUERY_OBJECT.endDate,
      categories: [...DEFAULT_QUERY_OBJECT.categories],
      menuList: [],
      active: DEFAULT_QUERY_OBJECT.active,
      incomplete: DEFAULT_QUERY_OBJECT.incomplete,
      isRestricted: DEFAULT_QUERY_OBJECT.isRestricted,
    });
  };

  useEffect(() => {
    if (isOnClose) onClose();
  }, [filterObject]);

  const checkIsDifferent = () => {
    const keys = Object.keys(filterObject);
    for (const key of keys) {
      if (filterObject[key] !== queryObject[key]) return true;
    }
    return false;
  };

  useEffectOnce(() => {
    if (categories.length === 0) {
      dispatch(fetchCategoriesAndSubcategories());
    }
  });

  const props = {
    isDesktop,
    inventoryTab,
    categories,
    menuList: parseMenuList(menuList),
    filterObject,
    inventoryStats,
    selectFilter,
    onFilter,
    onClose,
    onReset,
    onNewMenuClick: location.state?.onNewMenuClick,
  };

  return (
    <>
      {/* Desktop design is similar to mobile design so using mobile screen with the required different prop */}
      <Desktop>
        <DesktopScreen {...{...props, closeFilterPage}} />
      </Desktop>
      <Tablet>
        <TabScreen {...props} />
      </Tablet>
      <Mobile>
        <MobileScreen {...props} />
      </Mobile>
    </>
  );
};
export default InventoryFiltering;
