import {IonModal, IonButton} from '@ionic/react';
import './styles.scss';
import car from 'src/assets/images/car.svg';
import {useAppSelector} from 'src/hooks';

interface IProps {
  isOpen: boolean;
  onBack: () => void;
  onClose: () => void;
}

const ItemAddedModal = (props: IProps) => {
  const {isDesktop} = useAppSelector((state) => state.platform);
  return (
    <IonModal className="app-modal order-pickup-modal" isOpen={props.isOpen} showBackdrop backdropDismiss={false}>
      <div className="modal-content-container">
        <div className="modal-content">
          <img src={car} alt="" className="modal-main-image" />

          <h2 className="modal-title">Great job!</h2>
          <p className="modal-desc">A driver will arrive soon to pick up this order. Order completed.</p>

          {!isDesktop && (
            <IonButton className="btn-primary" expand="block" onClick={props.onBack}>
              Back to active Orders
            </IonButton>
          )}

          <IonButton
            className={`btn-primary ${isDesktop ? '' : 'footer-btn'} mb-0`}
            expand="block"
            fill="clear"
            onClick={props.onClose}>
            Close this message
          </IonButton>
        </div>
      </div>
    </IonModal>
  );
};

export default ItemAddedModal;
