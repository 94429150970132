import {createSlice, createAsyncThunk} from '@reduxjs/toolkit/dist';
import {IEmployee, IRole} from 'src/interfaces/user';
import {showLoading, hideLoading} from 'src/store/loadingSlice';
import {setCloseStore, setDeactivateStore, setStore} from 'src/store/storeSlice';
import userService from 'src/services/user';
import {showAlert} from 'src/store/alertSlice';
import {getItem, removeItem, setItem} from 'src/utils/storage';
import SocketHelper from 'src/utils/socket';
import storeService from 'src/services/store';
import {RootState} from './index';
import {STORAGE} from 'src/enums/storage';

interface IAuth {
  token: string | null;
  user: IEmployee | null;
  roles: Array<IRole>;
  hideWelcome: string;
  internetAvailable: boolean;
  menuIsOpen: boolean;
  menuAvailable: boolean;
  userStoresCount: number;
  userStores: Array<any>;
}

export const getProfile = createAsyncThunk(
  'auth/getProfile',
  async (_, {dispatch, fulfillWithValue, rejectWithValue}: any) => {
    try {
      const user = await userService.getProfile();
      const {count, stores} = await storeService.getAllUserStores({user_id: user.id, offset: 0});
      if (stores.length > 0) {
        dispatch(setStoreCount(count));
        dispatch(setStores(stores));

        const selectedStoreId = await getItem(STORAGE.SELECTED_STORE);
        const selectedStore = stores.find((s: any) => s.id === selectedStoreId);
        const store = selectedStore || stores[0];
        // If there is no store selected then select the first one.
        // If user don't have the access to the previous selected store then select the first one.
        if (selectedStoreId !== store?.id) setItem(STORAGE.SELECTED_STORE, stores[0].id);

        dispatch(setStore(store));
        dispatch(setCloseStore(store.isClosed));
        dispatch(setDeactivateStore(store.isDeactivated));
        // Listening for socket events.
        SocketHelper.connectSocket(store.id, dispatch);
      }
      dispatch(hideLoading());
      return fulfillWithValue({...user});
    } catch (error: any) {
      return rejectWithValue(error);
    }
  },
);

export const verifyToken = createAsyncThunk('auth/verifyToken', async (data: any, {dispatch}: any) => {
  try {
    dispatch(showLoading());
    await userService.verifyToken(data);
    dispatch(setIsEmailVerified(true));
    dispatch(hideLoading());
  } catch (error: any) {
    dispatch(hideLoading());
    dispatch(setIsEmailVerified(false));
    dispatch(
      showAlert({
        heading: 'Unable to verify',
        message: error?.message || 'Some error occurred while verifying. Please, try again later',
      }),
    );
  }
});

export const refreshAccessToken = createAsyncThunk(
  'auth/refreshAccessToken',
  async (token: string | null, {dispatch}: any) => {
    try {
      const {accessToken, lastFetched, idToken} = await userService.refreshToken(token);
      dispatch(setToken(accessToken));
      setItem(STORAGE.TOKEN, accessToken);
      setItem(STORAGE.LAST_FETCHED, lastFetched);
      setItem(STORAGE.ID_TOKEN, idToken);
      return true;
    } catch (error: any) {
      return error;
    }
  },
);

export const updateProfile = createAsyncThunk(
  'auth/updateProfile',
  async (user: any, {dispatch, fulfillWithValue, getState, rejectWithValue}) => {
    const {
      auth: {user: loggedInUser},
    } = getState() as RootState;
    if (!user.playerID) dispatch(showLoading());
    try {
      const data: any = {
        first_name: user.firstName,
        last_name: user.lastName,
        phone_number: user.phone,
        // role: loggedInUser?.role.name,
        user_rbac: loggedInUser?.isRBACUser,
      };
      if (user.email) data.email = user.email;
      if (user.password) data.password = user.password;
      if (user.playerID) data.player_id = user.playerID;
      await userService.updateProfile(user.id, data);
      dispatch(hideLoading());
      if (user.firstName || user.lastName || user.phone) {
        dispatch(
          showAlert({
            heading: 'Successfully updated',
            message: 'Profile details successfully updated.',
          }),
        );
      }
      // Logging out user in case of email or password change.
      if (user.password || user.email) {
        dispatch(logoutUser());
      }
      return fulfillWithValue(user);
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(
        showAlert({
          heading: 'Unable to update',
          message: error?.message || 'Some error occurred while updating profile details. Please, try again later',
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const getAllRoles = createAsyncThunk(
  'auth/getAllRoles',
  async (_, {dispatch, fulfillWithValue, rejectWithValue}) => {
    dispatch(showLoading());
    try {
      const roles = await userService.getRoles();
      dispatch(hideLoading());
      return fulfillWithValue(roles);
    } catch (error: any) {
      dispatch(hideLoading());
      return rejectWithValue(error);
    }
  },
);

const initialState: IAuth = {
  token: null,
  user: null,
  roles: [],
  hideWelcome: 'true',
  internetAvailable: true,
  menuIsOpen: false,
  menuAvailable: false,
  userStoresCount: 0,
  userStores: [],
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },
    setUser: (state, action) => {
      state.user = action.payload;
    },
    setIsEmailVerified: (state, action) => {
      if (state.user) {
        state.user = {...state.user, isEmailVerified: action.payload};
      }
    },
    logoutUser: (state) => {
      document.location.replace('/auth/login');
      removeItem(STORAGE.REFRESH_TOKEN);
      removeItem(STORAGE.LAST_FETCHED);
      removeItem(STORAGE.TOKEN);
      removeItem(STORAGE.ID_TOKEN);
    },
    setHideWelcome: (state, action) => {
      state.hideWelcome = action.payload;
    },
    setStoreCount: (state, action) => {
      state.userStoresCount = action.payload;
    },
    setStores: (state, action) => {
      state.userStores = action.payload;
    },
    updateStoreList: (state, action) => {
      if (state.user && state.userStores.length > 1) {
        state.userStores = state.userStores.map((store: any) =>
          store.id === action.payload.id ? {...store, ...action.payload, phone: action.payload.phone_number} : store,
        );
      }
    },
    setInternetAvailable: (state, action) => {
      state.internetAvailable = action.payload;
    },
    setMenuIsOpen: (state, action) => {
      state.menuIsOpen = action.payload;
    },
    setMenuAvailable: (state, action) => {
      state.menuAvailable = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getProfile.fulfilled, (state, action) => {
      state.user = action.payload;
    });
    builder.addCase(updateProfile.fulfilled, (state, action) => {
      if ((action.payload as unknown as any).password || (action.payload as unknown as any).email) {
        state.user = null;
      } else {
        state.user = {...state.user, ...action.payload} as IEmployee;
      }
    });
    builder.addCase(getAllRoles.fulfilled, (state, action) => {
      state.roles = action.payload;
    });
  },
});

export const {
  setToken,
  setUser,
  setIsEmailVerified,
  logoutUser,
  setHideWelcome,
  setStoreCount,
  setStores,
  updateStoreList,
  setInternetAvailable,
  setMenuIsOpen,
  setMenuAvailable,
} = authSlice.actions;

export default authSlice.reducer;
