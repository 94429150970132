import {createSlice} from '@reduxjs/toolkit';

interface IAlert {
  show: boolean;
  heading: string;
  message: string;
  disableBackdropDismiss: boolean;
}

const initialState: IAlert = {
  show: false,
  heading: '',
  message: '',
  disableBackdropDismiss: false,
};

const alertSlice = createSlice({
  name: 'alert',
  initialState,
  reducers: {
    showAlert: (state, action) => {
      state.show = true;
      state.heading = action.payload.heading;
      state.message = action.payload.message;
      state.disableBackdropDismiss = action.payload.disableBackdropDismiss;
    },
    hideAlert: (state) => {
      state.show = false;
      state.heading = '';
      state.message = '';
    },
  },
});

export const {showAlert, hideAlert} = alertSlice.actions;

export default alertSlice.reducer;
