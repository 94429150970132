import {IonModal, IonButton, IonIcon, IonFooter} from '@ionic/react';
import './styles.scss';

import Header from 'src/components/Header';
import {closeOutline} from 'ionicons/icons';

interface IProps {
  showConfirmationModal: boolean;
  setShowConfirmationModal: (e: boolean) => void;
  setShowEditModal: (e: boolean) => void;
  setPreviousModal: (e: boolean) => void;
  isInStock: boolean;
  onConfirm: () => void;
}

const BulkEditConfirmationModal = (props: IProps) => (
  <IonModal
    id="bulk-edit-confirmation"
    isOpen={props.showConfirmationModal}
    showBackdrop
    onDidDismiss={() => {
      props.setShowConfirmationModal(false);
    }}>
    <div className="modal-content-container">
      <div className="modal-content">
        <Header
          border
          noBackground
          title="Confirmation"
          rightButton={
            <IonIcon
              onClick={() => {
                props.setShowConfirmationModal(false);
              }}
              icon={closeOutline}
            />
          }
        />
        <div className="modal-body">
          <div className="label">Are you sure you want to Update these items?</div>
        </div>
        <IonFooter>
          <div className="action">
            <IonButton
              className="btn-outline"
              onClick={() => {
                props.setShowConfirmationModal(false);
                props.setPreviousModal(true);
              }}>
              No
            </IonButton>
            <IonButton
              className="btn-primary"
              onClick={() => {
                props.onConfirm();
                props.setShowConfirmationModal(false);
              }}>
              Yes
            </IonButton>
          </div>
        </IonFooter>
      </div>
    </div>
  </IonModal>
);

export default BulkEditConfirmationModal;
