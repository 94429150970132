/* eslint-disable react-hooks/exhaustive-deps */
import {useState} from 'react';
import {RouteComponentProps} from 'react-router';
import {
  IonPage,
  IonContent,
  IonBackButton,
  IonInput,
  IonIcon,
  IonItem,
  IonLabel,
  IonSpinner,
  IonButton,
} from '@ionic/react';
import {closeOutline, searchOutline} from 'ionicons/icons';
import './styles.scss';
import Snackbar from 'src/components/Snackbar';
import itemService from 'src/services/item';
import Header from 'src/components/Header';
import Item from 'src/components/CommonItem';
import NoItem from 'src/components/NoItem';
import TextWithLines from 'src/components/TextWithLines';
import {ISnackbar} from 'src/interfaces/snackbar';
import {useAppDispatch, useAppSelector, useEffectOnce} from 'src/hooks';
import {showAlert} from 'src/store/alertSlice';
import {showLoading, hideLoading} from 'src/store/loadingSlice';
import {addToInventory, fetchInventoryCount, removeFromInventory} from 'src/store/inventorySlice';
import {fetchSearchedItems, setLoadMoreSearch, setSearchedItems, setSearchOffset} from 'src/store/itemSlice';
import AnalyticsHelper from 'src/utils/segment';
import {getItemFromStoreItemNew} from 'src/utils/helpers';
import arrowBack from 'src/assets/images/arrow-back-black.svg';

interface IProps extends RouteComponentProps {
  setShowSearch?: (show: boolean) => void;
}

const SearchItem = ({history, setShowSearch}: IProps) => {
  const dispatch = useAppDispatch();
  const {menuIsOpen} = useAppSelector((state) => state.auth);
  const {searchItems, loadMoreSearch, loadingSearch} = useAppSelector((state) => state.item);
  const {isDesktop, isTablet} = useAppSelector((state) => state.platform);
  const {id: storeId} = useAppSelector((state) => state.store);
  const [searchTerm, setSearchTerm] = useState('');
  const [popularItems, setPopularItems] = useState([]);
  const [snackbar, setSnackbar] = useState<ISnackbar>({
    show: false,
    type: 'success',
    title: '',
    buttonTitle: 'undo',
    onButtonClick: () => {},
    onDismiss: () => {},
  });

  const dismissSnackbar = () => {
    setSnackbar({...snackbar, show: false, title: ''});
  };

  const addItem = (id: string) => {
    // First show user that item is selected.
    const updatedItems = searchItems.map((item: any) => (item.id === id ? {...item, selected: true} : item));
    dispatch(setSearchedItems(updatedItems));
    dispatch(showLoading());
    itemService
      .addItemToStore(storeId, id, true)
      .then((response: any) => {
        dispatch(hideLoading());
        const currentItem = getItemFromStoreItemNew(response);
        AnalyticsHelper.trackAddNewItem(currentItem, response.is_reviewed, 'Selection');
        const updatedItems = searchItems.map((item: any) => {
          if (item.id === id) {
            const updatedItem = {...item, storeItemId: response.id};
            dispatch(addToInventory(updatedItem));
            return updatedItem;
          } else return item;
        });
        dispatch(setSearchedItems(updatedItems));
        dispatch(fetchInventoryCount());
        setSnackbar({
          ...snackbar,
          show: true,
          type: 'success',
          title: 'Item added to Inventory.',
          onButtonClick: () => removeItem(id, response.id),
          onDismiss: dismissSnackbar,
        });
      })
      .catch((error) => {
        dispatch(hideLoading());
        dispatch(
          showAlert({
            heading: 'Unable to add',
            message: error?.message
              ? error?.message
              : 'Some error occurred while adding item to your store. Please, try again later',
          }),
        );
        // If error occurred while adding item then un select the item.
        const updatedCommonItems = searchItems.map((item: any) => (item.id === id ? {...item, selected: false} : item));
        dispatch(setSearchedItems(updatedCommonItems));
      });
  };

  const removeItem = (id: string, storeItemId: string) => {
    dispatch(showLoading());
    itemService
      .removeItemFromStore(storeId, storeItemId)
      .then((_) => {
        dispatch(hideLoading());
        const updatedItems = searchItems.map((item: any) => {
          if (item.id === id) {
            const updatedItem = {...item, storeItemId: 0};
            dispatch(removeFromInventory(storeItemId));
            return updatedItem;
          } else return item;
        });
        dispatch(setSearchedItems(updatedItems));
        dispatch(fetchInventoryCount());
        setSnackbar({
          ...snackbar,
          show: true,
          type: 'error',
          title: 'Item removed fom Inventory.',
          onButtonClick: () => addItem(id),
          onDismiss: dismissSnackbar,
        });
      })
      .catch((error) => {
        dispatch(hideLoading());
        dispatch(
          showAlert({
            heading: 'Unable to remove',
            message: error?.message || 'Some error occurred while removing item from store. Please, try again later',
          }),
        );
      });
  };

  const fetchPopularItems = () => {
    itemService.getPopularItems().then((items: any) => {
      setPopularItems(items);
    });
  };

  const fetchNextItems = (e: any) => {
    const {scrollHeight, scrollTop, clientHeight} = e.target;
    if (scrollHeight - scrollTop <= clientHeight + 10 && loadMoreSearch && !loadingSearch) {
      dispatch(fetchSearchedItems(searchTerm));
    }
  };

  const onInputChange = (value: string) => {
    setSearchTerm(value);
    dispatch(setSearchOffset(0));
    dispatch(setSearchedItems([]));
    if (value.trimStart().length) {
      dispatch(fetchSearchedItems(value.trimStart()));
      dispatch(setLoadMoreSearch(true));
    }
  };

  const onClose = () => {
    if (setShowSearch) setShowSearch(false);
  };

  useEffectOnce(() => {
    fetchPopularItems();
  });

  return (
    <>
      <IonPage id="search-items-page" className={`${isTablet && !menuIsOpen ? 'menu-close' : ''} snow-background`}>
        <IonContent fullscreen scrollY={false}>
          <Header
            noBackground
            title={isDesktop ? 'Search' : ''}
            leftButton={
              isDesktop ? (
                <IonButton onClick={onClose}>
                  <IonIcon src={closeOutline} />
                </IonButton>
              ) : (
                <IonBackButton defaultHref="/items/common" text="" icon={arrowBack} />
              )
            }
          />
          <div className="body" onScroll={fetchNextItems}>
            <h2 className="title">What are you looking for?</h2>

            <IonItem className="search-bar">
              <IonIcon className="search-icon" icon={searchOutline} />
              <IonInput
                className="search-input"
                placeholder="Start writing"
                debounce={750}
                value={searchTerm}
                onIonChange={(e) => onInputChange(e.detail.value!)}
              />
            </IonItem>

            {isTablet && <TextWithLines text="Search Results" />}

            <h2 className="sub-title">
              {searchItems.length ? 'Suggestions' : popularItems.length ? 'Popular searches' : ''}
            </h2>

            {loadingSearch ? (
              <div className="loading-container">
                <IonSpinner />
              </div>
            ) : (
              <>
                {!searchItems.length ? (
                  <div className="popular-search-container">
                    {popularItems.map((item, i) => (
                      <IonItem key={i} lines="none" onClick={() => setSearchTerm(item)}>
                        <IonLabel>{item}</IonLabel>
                      </IonItem>
                    ))}
                  </div>
                ) : (
                  <div className="suggestions">
                    {searchItems.map((item, i) => (
                      <Item
                        key={i}
                        {...{
                          ...item,
                          searchedItem: true,
                          onClick: item.storeItemId
                            ? () => removeItem(item.id, item.storeItemId)
                            : () => addItem(item.id),
                        }}
                      />
                    ))}
                    {loadMoreSearch && <IonSpinner className="load-more" color="primary" />}
                  </div>
                )}
                {!popularItems.length && !searchItems.length && searchTerm.trimStart() && (
                  <NoItem message="No Item found!" />
                )}
              </>
            )}
          </div>
        </IonContent>
      </IonPage>

      <Snackbar
        show={snackbar.show}
        type={snackbar.type}
        title={snackbar.title}
        buttonTitle={snackbar.buttonTitle}
        onButtonClick={snackbar.onButtonClick}
        onDismiss={snackbar.onDismiss}
      />
    </>
  );
};

export default SearchItem;
