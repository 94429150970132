/* eslint-disable react-hooks/exhaustive-deps */
import {IonPage, IonContent, IonBackButton, IonButton, IonFooter, IonIcon} from '@ionic/react';
import './styles.scss';
import {useHistory} from 'react-router';
import {closeOutline} from 'ionicons/icons';
import Header from 'src/components/Header';
import ListItem from 'src/components/ListItem';
import {useAppDispatch, useAppSelector, useEffectOnce} from 'src/hooks';
import {getMenu} from 'src/store/storeSlice';
import {checkPermission, parseMenuList} from 'src/utils/helpers';
import arrowBack from 'src/assets/images/arrow-back-black.svg';
import * as PERMISSIONS from 'src/utils/permissions';

interface IProps {
  setShowMenuList?: (show: boolean) => void;
  menuDetailClick: () => void;
  setMenuDetailId: (e: string) => void;
}

const MenuList = ({setShowMenuList, menuDetailClick, setMenuDetailId}: IProps) => {
  const history = useHistory();
  const dispatch = useAppDispatch();

  const {isDesktop, isTablet} = useAppSelector((state) => state.platform);
  const {menuIsOpen} = useAppSelector((state) => state.auth);
  const {menuList} = useAppSelector((state) => state.store);
  const parsedMenuList = parseMenuList(menuList);
  const {user} = useAppSelector((state) => state.auth);
  const canCreateMenu = checkPermission(user?.role.permissions, PERMISSIONS.CREATE_MENU);

  const onClose = () => {
    if (setShowMenuList) setShowMenuList(false);
  };

  const getAllMenu = async () => {
    dispatch(getMenu());
  };

  useEffectOnce(() => {
    getAllMenu();
  });

  return (
    <IonPage id="menu-list-page" className={`${!menuIsOpen ? 'menu-close' : ''}`}>
      <IonContent fullscreen scrollY={false} className={`${isTablet ? 'page-container' : ''}`}>
        <Header
          noBackground
          leftButton={!isDesktop && <IonBackButton defaultHref="/store/manage" text="" icon={arrowBack} />}
          rightButton={
            isDesktop && (
              <IonButton onClick={onClose}>
                <IonIcon src={closeOutline} />
              </IonButton>
            )
          }
          title="Menu"
        />

        <div className="body with-footer">
          {!parsedMenuList ? (
            <>
              <p>No menus created at this time</p>
              <div className="menu-container">
                <h2>What is a menu?</h2>
                <p>
                  A menu allows you to control when you sell items. You may specify when items are sold based on the
                  time and day of the week. Start using menus today and start taking control!
                </p>
              </div>
            </>
          ) : (
            parsedMenuList.map((menuItem: any, index: number) => (
              <ListItem
                key={index}
                icon=""
                title={menuItem.menu_name}
                description=""
                onClick={() => {
                  if (isDesktop) {
                    setMenuDetailId(menuItem.id);
                    menuDetailClick();
                  } else {
                    history.push({pathname: '/store/manage/menu/details', state: {menuDetailId: menuItem.id}});
                  }
                }}
              />
            ))
          )}
        </div>

        <IonFooter>
          <IonButton
            className="btn-primary"
            expand="block"
            disabled={!canCreateMenu}
            onClick={() => {
              if (isDesktop) {
                setMenuDetailId('');
                menuDetailClick();
              } else {
                history.push({pathname: '/store/manage/menu/details', state: {menuDetailId: '', isStayForEdit: true}});
              }
            }}>
            <IonIcon className="icon-add" />
            Create Menu
          </IonButton>
        </IonFooter>
      </IonContent>
    </IonPage>
  );
};

export default MenuList;
