import {IonIcon, IonItem, IonRadio} from '@ionic/react';
import {List, DollarSign, Calendar} from 'react-feather';
import './styles.scss';

import {mapSortSelectionToIcon, mapSortSelectionToSortOutput} from 'src/utils/helpers';
interface IProps {
  selected: boolean;
  sortSelection: number;
}

const SortRadioButton = (props: IProps) => {
  return (
    <IonItem lines="none" className={`radio-button-row ${props.selected ? 'selected' : ''}`}>
      {[2, 3].includes(props.sortSelection) ? (
        <IonIcon icon={mapSortSelectionToIcon(props.sortSelection)} />
      ) : props.sortSelection === 1 ? (
        <List />
      ) : [4, 5].includes(props.sortSelection) ? (
        <DollarSign />
      ) : [6, 7].includes(props.sortSelection) ? (
        <Calendar />
      ) : (
        <IonIcon icon={mapSortSelectionToIcon(props.sortSelection)} />
      )}
      <p className={`description ${props.selected ? 'selected' : ''}`}>
        {mapSortSelectionToSortOutput(props.sortSelection)}
      </p>
      <IonRadio mode="md" slot="end" value={props.sortSelection} />
    </IonItem>
  );
};

export default SortRadioButton;
