import {IonModal, IonButton} from '@ionic/react';

interface IProps {
  isOpen: boolean;
  scanBarcode: () => void;
  goToItem: () => void;
  onClose: () => void;
}

const ItemExistModal = (props: IProps) => (
  <IonModal className="app-modal" isOpen={props.isOpen} showBackdrop backdropDismiss={false}>
    <div className="modal-content-container">
      <div className="modal-content">
        <h2 className="modal-title">Item already added to your Inventory.</h2>

        <IonButton className="btn-primary mb-0" expand="block" onClick={props.scanBarcode}>
          Scan another Barcode
        </IonButton>

        <IonButton className="btn-secondary mb-0" expand="block" onClick={props.goToItem}>
          Go to this Item
        </IonButton>

        <IonButton className="btn-primary footer-btn mb-0" expand="block" fill="clear" onClick={props.onClose}>
          Close this message
        </IonButton>
      </div>
    </div>
  </IonModal>
);

export default ItemExistModal;
