import {IonButton, IonMenuToggle, IonRouterLink} from '@ionic/react';
import moment, {Moment} from 'moment';
import {useState} from 'react';
import {Check} from 'react-feather';
import {useDispatch} from 'react-redux';
import {useHistory} from 'react-router';
import './styles.scss';
import {useAppSelector} from 'src/hooks';
import {createInstentStorePause, getStorePauseSchedules, getStoreStatus} from 'src/store/storeSlice';
import {STORE_PAUSE} from 'src/enums/store_pause';

interface ItemProps {
  option: string;
  selected: boolean;
  onClick: () => void;
  isDesktop: boolean;
  index: Number;
}

interface IProps {
  partnerName?: string;
  onEndPause?: () => void;
}

const OPTIONS = [
  STORE_PAUSE.PAUSE_FOR_15_MINUTES,
  STORE_PAUSE.PAUSE_FOR_30_MINUTES,
  STORE_PAUSE.PAUSE_FOR_01_HOUR,
  STORE_PAUSE.PAUSE_UNTILL_TOMORROW,
  STORE_PAUSE.PAUSE_FOR_01_WEEK_OR_MORE,
];

const getStorePauseTime = (option: string) => {
  if (option === STORE_PAUSE.PAUSE_FOR_15_MINUTES) return moment().add(15, 'm');
  else if (option === STORE_PAUSE.PAUSE_FOR_30_MINUTES) return moment().add(30, 'm');
  else if (option === STORE_PAUSE.PAUSE_FOR_01_HOUR) return moment().add(1, 'h');
  else if (option === STORE_PAUSE.PAUSE_UNTILL_TOMORROW) return moment().endOf('day');
  else if (option === STORE_PAUSE.PAUSE_FOR_01_WEEK_OR_MORE) return moment().add(1, 'w');
  else return moment();
};

const Item = (props: ItemProps) => (
  <>
    {(props.isDesktop || props.index <= OPTIONS.length - 1) && (
      <>
        <div className="options">
          <p className={`${props.selected ? 'select-check' : ''}`} onClick={props.onClick}>
            {props.option}
          </p>
          {props.selected && <Check />}
        </div>
        {props.isDesktop && (
          <>
            {props.selected && props.index === OPTIONS.length - 1 && (
              <>
                <p className="text">You store has been paused for 1 week.</p>
                <p className="text">
                  If you want to go offline for an extended period of time, please contact our Customer Success team.
                </p>
                <p className="text">Call: (267) 263-7197</p>
                <p className="text">Email: wecare@luladelivery.com</p>
                <p className="text">
                  Have a few questions? Navigate to the
                  <IonRouterLink href="https://help.luladelivery.com/" target="_blank">
                    <> </>Help center
                  </IonRouterLink>
                  .
                </p>
              </>
            )}
          </>
        )}
      </>
    )}
  </>
);

const PauseOptions = ({partnerName, onEndPause}: IProps) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [selectedIndex, setSelectedIndex] = useState<Number | null>(null);
  const {isDesktop} = useAppSelector((state) => state.platform);
  const {storePauseScheduleList} = useAppSelector((state) => state.store);

  const onInstentStorePause = async (endDateAndTime: Moment) => {
    const data = {
      start_time: moment().format('HH:mm'),
      end_time: endDateAndTime.format('HH:mm'),
      start_date: moment().format('YYYY-MM-DD'),
      end_date: endDateAndTime.format('YYYY-MM-DD'),
      dsp_name: partnerName ? partnerName : 'All',
      utc_end_date: endDateAndTime.toISOString(),
    };
    await dispatch(createInstentStorePause(data));
    await dispatch(getStoreStatus());
  };

  return (
    <div id="pause-options">
      <div className="pause-menu-content">
        {OPTIONS.map((option, index) => (
          <Item
            key={index}
            option={option}
            selected={selectedIndex === index}
            onClick={async () => {
              // if already pause exist, then end this pause and create new pause
              onEndPause && (await onEndPause());
              setSelectedIndex(selectedIndex != null && selectedIndex === index ? null : index);
              selectedIndex !== index && (await onInstentStorePause(getStorePauseTime(OPTIONS[index])));
            }}
            isDesktop={isDesktop}
            index={index}
          />
        ))}
      </div>
      {!partnerName && (
        <div className="partner-button">
          <p>Need more pause options?</p>
          <IonMenuToggle menu="menu" autoHide={false}>
            <IonButton
              className="btn-outline"
              expand="block"
              mode="md"
              onClick={() => {
                dispatch(getStorePauseSchedules());
                isDesktop
                  ? history.push({pathname: '/partners', state: {showSchedulePause: true}})
                  : history.push({pathname: '/partners/schedule'});
              }}>
              {`Scheduled pauses (${storePauseScheduleList.length})`}
            </IonButton>
          </IonMenuToggle>
        </div>
      )}
    </div>
  );
};

export default PauseOptions;
