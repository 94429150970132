import {IonCheckbox} from '@ionic/react';
import './styles.scss';
import ItemImage from 'src/components/ItemImage';

interface IProps {
  item: any;
  selected?: boolean;
  onClick: (item: any, clear?: boolean) => void;
}

const SubstitutionListItem = ({item, selected, onClick}: IProps) => (
  <div id="substitution-list-item" className={selected ? 'select' : ''}>
    <div className="container">
      <div className="check">
        <IonCheckbox
          className="select-check"
          checked={selected}
          onIonChange={(event) => {
            if (!selected) onClick(item);
            else onClick(item, true);
          }}
        />
      </div>

      <ItemImage category={item.category?.name} image={item.image} />

      <div className="details" onClick={() => (selected ? onClick(item, true) : onClick(item))}>
        <div className="name-description-container">
          <div className="item-name">{item.name ? item.name : <div className="placeholder">No item name</div>}</div>

          {item.size && item.size.toUpperCase() !== 'NA' && (
            <div className="item-desc">
              <span className="desc-heading">Size</span>
              <span className="desc-value">{item.size}</span>
            </div>
          )}

          {item.unitCount && (
            <div className="item-desc">
              <span className="desc-heading">Count</span>
              <span className="desc-value">{item.unitCount}</span>
            </div>
          )}
        </div>

        <div className="price-container">
          <div className="price customer-price">
            <span className="price-label">Customer</span>
            <span className="price-value">{` $ ${Number(item.dspPrice).toFixed(2)}`}</span>
          </div>
          <div className="price store-price">
            <span className="price-label">Store</span>
            <span className="price-value">{` $ ${Number(item.price).toFixed(2)}`}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
);

export default SubstitutionListItem;
