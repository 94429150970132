import {useEffect, useState} from 'react';
import {
  IonPage,
  IonContent,
  IonBackButton,
  IonButton,
  IonToggle,
  IonIcon,
  IonAccordionGroup,
  IonAccordion,
  IonItem,
  IonLabel,
} from '@ionic/react';
import {chevronDownOutline} from 'ionicons/icons';
import './styles.scss';
import {RouteComponentProps} from 'react-router';
import {AndroidPermissions} from '@awesome-cordova-plugins/android-permissions';
import {Printers} from '@awesome-cordova-plugins/star-prnt';
import {useAppDispatch, useAppSelector, useEffectOnce} from 'src/hooks';
import CustomDropdown from 'src/components/CustomDropdown';
import Header from 'src/components/Header';
import {
  setHasBluetoothPermission,
  setPrinter,
  setPrinterEmulation,
  setPrinterModel,
  setShouldPrintOrder,
  setWasConnected,
} from 'src/store/printerSlice';
import {showAlert} from 'src/store/alertSlice';
import {setItem} from 'src/utils/storage';
import {
  PrinterService,
  PRINTER_MODEL_EMULATION_MAPPING,
  PRINTER_MODEL_LOCAL_KEY,
  PRINTER_PORT_LOCAL_KEY,
} from 'src/services/printer';
import {checkPermission} from 'src/utils/helpers';
import * as PERMISSIONS from 'src/utils/permissions';
import AnalyticsHelper from 'src/utils/segment';
import {SHOP_URL} from 'src/utils/constants';
import arrowBack from 'src/assets/images/arrow-back-black.svg';

interface IProps extends RouteComponentProps {
  setShowPrinterOverview?: (show: boolean) => void;
}

const PrinterOverview = ({history, setShowPrinterOverview}: IProps) => {
  const dispatch = useAppDispatch();

  const {isNative, isAndroid, device} = useAppSelector((state) => state.platform);
  const {menuIsOpen, user} = useAppSelector((state) => state.auth);
  const {printer} = useAppSelector((state) => state.printer);
  const [nearByPrinters, setNearByPrinters] = useState<Printers>([]);

  const canEditPrinter = checkPermission(user?.role.permissions, PERMISSIONS.EDIT_PRINTER_SETTINGS);

  const navigateToPartners = () => {
    history.push('/partners');
  };

  const toggleShouldPrintOrder = async (newStatus: boolean): Promise<void> => {
    dispatch(setShouldPrintOrder(newStatus));
  };

  const deletePrinter = async (): Promise<void> => {
    dispatch(setWasConnected(false));
    dispatch(setPrinterModel(''));
    dispatch(setPrinterEmulation(''));
    setItem(PRINTER_MODEL_LOCAL_KEY, '');
    setItem(PRINTER_PORT_LOCAL_KEY, '');
    PrinterService.getInstance().disconnect();
  };

  const checkBluetoothPermission = async () => {
    if (isNative && isAndroid && Number(device?.deviceInfo?.osVersion) < 12) {
      dispatch(setHasBluetoothPermission(true));
      return;
    }
    const permissions = [
      AndroidPermissions.PERMISSION.BLUETOOTH_CONNECT,
      AndroidPermissions.PERMISSION.BLUETOOTH_SCAN,
      AndroidPermissions.PERMISSION.BLUETOOTH,
    ];
    const permissionStatuses = await Promise.all(
      permissions.map((permission) => AndroidPermissions.checkPermission(permission)),
    );
    if (permissionStatuses.every((status) => status.hasPermission)) {
      dispatch(setHasBluetoothPermission(true));
      return;
    }
    const response = await AndroidPermissions.requestPermissions(permissions);

    if (response.hasPermission) {
      dispatch(setHasBluetoothPermission(true));
    } else {
      dispatch(
        showAlert({
          heading: 'Cannot connect to printer',
          message: 'Please allow Lula to access bluetooth in order to connect with printer.',
        }),
      );
    }
  };

  const onHandleSelect = (printerModel: string) => {
    const emulation = PRINTER_MODEL_EMULATION_MAPPING[printerModel];
    if (!emulation) {
      return;
    }
    setItem(PRINTER_MODEL_LOCAL_KEY, printerModel);
    dispatch(setPrinterEmulation(emulation));
    dispatch(setPrinterModel(printerModel));
    AnalyticsHelper.trackPrinter(printerModel);
    if (printer.portName) {
      PrinterService.getInstance().connectPrinter();
    }
  };

  const onHandleSelectPrinter = (modelName: string) => {
    if (modelName === null || modelName === undefined || modelName.trim() === '') {
      return;
    }
    const nearByPrinter = nearByPrinters.find(
      (printer) => printer.modelName === modelName || printer.portName === modelName,
    );
    setItem(PRINTER_PORT_LOCAL_KEY, nearByPrinter?.portName || '');
    dispatch(setPrinter(nearByPrinter));
    AnalyticsHelper.trackSetPrinter(nearByPrinter?.modelName || '', nearByPrinter?.macAddress || '');
    if (printer.emulation) {
      PrinterService.getInstance().connectPrinter();
    }
  };

  const getNearByPrinters = async () => {
    const printers = (await PrinterService.getInstance().getNearByPrinters()) || [];
    setNearByPrinters(printers);
  };

  const onClickShop = () => {
    if (!isNative) window.open(SHOP_URL);
    else {
      history.replace('/support/lula-shop');
    }
  };

  useEffect(() => {
    if (nearByPrinters.length === 1 && !printer.portName) {
      onHandleSelectPrinter(nearByPrinters[0].modelName || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [nearByPrinters]);

  useEffectOnce(() => {
    checkBluetoothPermission();
    getNearByPrinters();
  });

  const isDiscovered = !!printer.emulation && !!printer.model && !!printer.portName;

  return (
    <IonPage id="printer-page" className={`${!menuIsOpen ? 'menu-close' : ''} snow-background`}>
      <Header
        noBackground
        leftButton={<IonBackButton defaultHref="/store/manage" text="" icon={arrowBack} />}
        title="Printer"
      />
      <IonContent scrollY className="page-container">
        <div className="body">
          {!isDiscovered ? (
            <div className="printer-not-discovered">
              <div className="printer-card">
                <div className="printer-card-section">
                  <IonIcon slot="start" className="icon-print" />
                  <div>
                    <p>
                      Connecting your Lula tablet with a printer, enables automatic printing of all orders to ensure
                      <span> complete and accurate order fulfillment</span>.
                    </p>
                    <p>Need a printer? You can order one through the Lula Shop.</p>
                    <IonButton size="default" fill="outline" expand="block" onClick={onClickShop}>
                      Lula shop
                    </IonButton>
                  </div>
                </div>
                <div className="printer-card-section">
                  <IonIcon slot="start" className="icon-arrow-down" />
                  <p>
                    Ready to start printing? <span>Get started now</span> by selecting your printer model and following
                    the printer setup instructions.
                  </p>
                </div>
              </div>
              <div className="printer-dropdown">
                <p>Printer device</p>
                <CustomDropdown
                  disabled={!canEditPrinter}
                  options={nearByPrinters.map(
                    (printer) => printer?.modelName || printer?.portName || 'unrecognizable device',
                  )}
                  placeholder="Select printer model"
                  selectedValue={printer.modelName}
                  onHandleSelect={onHandleSelectPrinter}
                />
                <p className="error-msg">You must select your printer device</p>
              </div>
              <div className="printer-dropdown">
                <p>Printer model</p>
                <CustomDropdown
                  disabled={!canEditPrinter}
                  options={Object.keys(PRINTER_MODEL_EMULATION_MAPPING).map((v) => v)}
                  placeholder="Select printer model"
                  selectedValue={printer.model}
                  onHandleSelect={onHandleSelect}
                />
                <p className="error-msg">You must select your printer model before you can connect to your printer</p>
              </div>
            </div>
          ) : (
            <div className="printer-container">
              <div className={`printer-status ${printer.connectionStatus ? 'connected' : 'disconnected'}`}>
                <IonIcon slot="start" className="icon-partners" />
                <p>{printer.connectionStatus ? 'Connected' : 'Disconnected'}</p>
              </div>

              <div className="printer-model">
                <p>Printer model</p>
                <p className="printer-model-txt">{printer.model}</p>
              </div>

              <div className="divider"></div>

              <div className="auto-print-orders">
                <p>
                  Auto print orders: <span>{printer.shouldPrintOrder ? 'On' : 'Off'}</span>
                </p>
                <IonToggle
                  disabled={!printer.connectionStatus}
                  slot="start"
                  name="kiwi"
                  color="success"
                  checked={printer.shouldPrintOrder}
                  onIonChange={(e) => toggleShouldPrintOrder(e.detail.checked)}
                />
              </div>

              <div className="divider"></div>

              <div className="printer-delete-btn">
                <IonButton fill="outline" onClick={deletePrinter} size="default" expand="block">
                  <IonIcon slot="start" className="icon-trash" />
                  <span>Delete connection</span>
                </IonButton>
              </div>
            </div>
          )}

          <div className="divider ion-margin-vertical"></div>

          {isDiscovered && (
            <div className="note-container">
              <p>Note:</p>
              <ul>
                <li>Your tablet must be on and logged in to LSP</li>
                <li>
                  Your printer must be on, paired with the tablet, stocked with paper, and within range of the tablet
                </li>
              </ul>
            </div>
          )}

          <IonAccordionGroup className="accordion-group">
            <IonAccordion value="instructions" toggleIcon={chevronDownOutline} toggleIconSlot="end">
              <IonItem slot="header">
                <IonLabel>Instructions</IonLabel>
              </IonItem>
              <div slot="content" className="instructions-content">
                <div className="content-item ion-padding">
                  <div className="instruction-number">1</div>
                  <div className="instructions-details">
                    <div>Power up the printer</div>
                    <ul>
                      <li>Plug in and turn on the printer</li>
                      <li>
                        Once on, the printer should be in 'Bluetooth Mode' (Any issues, see printer manual for further
                        guidance)
                      </li>
                    </ul>
                  </div>
                </div>

                <div className="content-item ion-padding">
                  <div className="instruction-number">2</div>
                  <div className="instructions-details">
                    <div>Connect the tablet with your printer</div>
                    <ul>
                      <li>Exit Lula App and open Esper Settings</li>
                      <li>
                        Within Bluetooth Settings select the Star Micronics Printer option from the list of discovered
                        devices
                      </li>
                      <li>Pair with the printer by entering code: 1234</li>
                    </ul>
                  </div>
                </div>

                <div className="content-item ion-padding">
                  <div className="instruction-number">3</div>
                  <div className="instructions-details">
                    <div>Test the connection</div>
                    <ul>
                      <li>Open LSP, navigate to Store Settings, and ensure the auto-print toggle is on</li>
                      <li>Navigate to the Partners page and tap "Test Order"</li>
                      <li>Congrats! A test order should automatically print from your new printer</li>
                    </ul>
                    <IonButton
                      size="default"
                      fill="outline"
                      color="dark"
                      expand="block"
                      style={{textTransform: 'inherit'}}
                      onClick={navigateToPartners}>
                      Partners page
                    </IonButton>
                  </div>
                </div>
              </div>
            </IonAccordion>
            <IonAccordion value="faqs" toggleIcon={chevronDownOutline} toggleIconSlot="end">
              <IonItem slot="header">
                <IonLabel>FAQs</IonLabel>
              </IonItem>
              <div slot="content" className="faq-content">
                <div className="content-item ion-padding">
                  <div>My printer is no longer printing orders… What should I do?</div>
                  <ul>
                    <li>Check that the printer is plugged in and powered on</li>
                    <li>Check that the printer is not out of paper</li>
                    <li>Make sure the printer is within range of the tablet</li>
                    <li>Make sure your tablet is on and logged in to the LSP application</li>
                    <li>Make sure the auto-print toggle is turned on in the printer settings menu</li>
                  </ul>
                </div>

                <div className="content-item ion-padding">
                  <div>What should I do if my previously paired printer is no longer connected to my tablet?</div>
                  <p>
                    Leave the LSP app and open the Esper Settings app. Then, navigate to the Bluetooth page and attempt
                    to re-pair with the printer (code: 1234). You may need to unpair and re-pair with the printer.
                  </p>
                </div>

                <div className="content-item ion-padding">
                  <div>Can I have multiple printers connected to one tablet?</div>
                  <p>No, you are only able to connect to one printer at a time.</p>
                </div>

                <div className="content-item ion-padding">
                  <div>I'm out of paper, where can I get more?</div>
                  <p>You can order more printer paper directly from the Lula Shop.</p>
                </div>
              </div>
            </IonAccordion>
          </IonAccordionGroup>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default PrinterOverview;
