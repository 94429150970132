import {useState, useRef, useEffect} from 'react';
import './styles.scss';

interface IProps {
  length: number;
  readOnly?: boolean;
  onComplete: (code: string) => void;
}

const CodeInput = ({length, readOnly, onComplete}: IProps) => {
  const [code, setCode] = useState(new Array(length).fill(''));
  const inputs = useRef<any>([]);

  // Handling code input.
  const processInput = (e: any, slot: any) => {
    const num = e.target.value;
    if (num.length === 6 && !code.join('')) {
      // If user has pasted code.
      const numbers = num.split('').map((num: string) => (/[0-9]/.test(num) ? num : ''));
      if (numbers.every((num: string) => num !== '')) {
        setCode(numbers);
        onComplete(numbers.join(''));
      }
    } else {
      // If user manually enter code in input.
      if (num.length > 1 || !/[0-9]/.test(num)) return;
      const newCode = [...code];
      newCode[slot] = num;
      setCode(newCode);
      if (slot !== length - 1) inputs.current[slot + 1].focus();
      if (newCode.every((num) => num !== '')) onComplete(newCode.join(''));
    }
  };

  // Handling backspace.
  const onKeyUp = (e: any, slot: any) => {
    if (e.keyCode === 8) {
      const newCode = [...code];
      newCode[slot] = '';
      setCode(newCode);
      if (slot !== 0) inputs.current[slot - 1].focus();
    }
  };

  useEffect(() => {
    if (inputs.current) {
      setTimeout(() => {
        inputs.current[0].focus();
      }, 1000);
    }
  }, [inputs.current]);

  return (
    <div id="code-inputs-container">
      <div className="code-inputs">
        {code.map((num, idx) => (
          <input
            key={idx}
            ref={(ref) => inputs.current.push(ref)}
            className={`input ${num ? 'no-border' : ''}`}
            type="text"
            inputMode="numeric"
            value={num}
            readOnly={readOnly}
            onInput={(e) => processInput(e, idx)}
            onKeyUp={(e) => onKeyUp(e, idx)}
          />
        ))}
      </div>
    </div>
  );
};

export default CodeInput;
