/* eslint-disable react-hooks/exhaustive-deps */
import {useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router';
import {withScanner} from 'src/hoc/WithScanner';
import {ITEM_STATUS} from 'src/enums/item_status';
import {ITEM_TYPE} from 'src/enums/item_type';
import {IInventoryStats, IItem} from 'src/interfaces/item';
import {getInventoryType, getTotalSelectItemCount, parseMenuList} from 'src/utils/helpers';
import {useAppDispatch, useAppSelector} from 'src/hooks';
import {
  setLoading,
  fetchInStockItems,
  fetchArchivedItems,
  activeItem,
  refreshInStockInventory,
  setInStockInventory,
  refreshArchivedInventory,
  refreshOutOfStockInventory,
  setArchivedInventory,
  fetchInventoryStats,
  fetchInventoryCount,
  fetchCategoryCount,
  setIsEditable,
  setSelectedCategory,
  setExceptItems,
  setSelectedItems,
  setIsValidInputPrice,
  clearInventoryQuery,
  setQueryObject,
  setInventoryTab,
  fetchOutOfStockItems,
  setOutOfStockInventory,
  refreshIncompleteInventory,
  fetchIncompleteItems,
  setIncompleteInventory,
} from 'src/store/inventorySlice';
import inventoryService from 'src/services/inventory';
import {Desktop, Tablet, Mobile} from 'src/utils/platform';
import MobileScreen from './Mobile';
import TabScreen from './Tablet';
import DesktopScreen from './Desktop';
import {hideLoading, showLoading} from 'src/store/loadingSlice';
import {showAlert} from 'src/store/alertSlice';
import AnalyticsHelper from 'src/utils/segment';
import {getMenu} from 'src/store/storeSlice';
import {setNewMenuId} from 'src/store/inventorySlice';
import * as PERMISSIONS from 'src/utils/permissions';
import {checkPermission} from 'src/utils/helpers';

interface IProps extends RouteComponentProps {
  openScanner: () => void;
  goToCommonItems: () => void;
}

const InventoryList = ({history, openScanner, goToCommonItems}: IProps) => {
  const dispatch = useAppDispatch();
  const {isDesktop} = useAppSelector((state) => state.platform);
  const {user} = useAppSelector((state) => state.auth);
  const canEdit = checkPermission(user?.role.permissions, PERMISSIONS.EDIT_ITEM);
  const canCreate = checkPermission(user?.role.permissions, PERMISSIONS.CREATE_ITEM);
  const canDelete = checkPermission(user?.role.permissions, PERMISSIONS.DELETE_ITEM);

  const {
    isFiltered,
    loading,
    loadMoreInStock,
    inStockItems,
    loadMoreArchived,
    archivedItems,
    queryObject,
    inventoryStats,
  } = useAppSelector((state) => state.inventory);
  const store = useAppSelector((state) => state.store);
  const {menuIsOpen} = useAppSelector((state) => state.auth);
  const {categories} = useAppSelector((state) => state.category);
  const {menuList} = useAppSelector((state) => state.store);
  const [showSearch, setShowSearch] = useState(false);
  const [title, setTitle] = useState<ITEM_STATUS.IN_STOCK | ITEM_STATUS.ARCHIVED>(ITEM_STATUS.IN_STOCK);
  const [searchedItems, setSearchedItems] = useState<Array<IItem>>([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [searchOffset, setSearchOffset] = useState(0);
  const [loadMoreSearch, setLoadMoreSearch] = useState(true);
  const [id, setId] = useState('');
  const [showDetails, setShowDetails] = useState(false);
  const [showBulkIncompleteDetails, setShowBulkIncompleteDetails] = useState(false);
  const [showBulkOutOfStockDetails, setShowBulkOutOfStockDetails] = useState(false);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showEditOptionModal, setShowEditOptionModal] = useState(false);
  const [showAvailabilityModal, setShowAvailabilityModal] = useState(false);
  const [showPriceModal, setShowPriceModal] = useState(false);
  const [showCategoryModal, setShowCategoryModal] = useState(false);
  const [showMenuModal, setShowMenuModal] = useState(false);
  const [showNewMenuModal, setShowNewMenuModal] = useState(false);
  const [showNewMenuDetail, setShowNewMenuDetail] = useState(false);
  const {selectedItems, exceptItems, selectedCategories, categoryCount} = useAppSelector((state) => state.inventory);

  const onNewMenuClick = () => {
    setShowNewMenuDetail(true);
  };

  const validatePriceInput = (updated_data: any, active: boolean) => {
    inventoryService
      .validateBulkPrice(store.id, {selectedItems, exceptItems, selectedCategories, updated_data}, active)
      .then((response) => {
        if (Number(response.count) === 0) {
          dispatch(setIsValidInputPrice(true));
        } else {
          dispatch(setIsValidInputPrice(false));
        }
      })
      .catch((_) => {});
  };

  const patchInventory = (updated_data: any, is_archive = false, active: boolean) => {
    const bulkCount = getTotalSelectItemCount(selectedCategories, selectedItems, exceptItems, categoryCount);
    if (is_archive) {
      dispatch(showLoading());
      inventoryService
        .patchArchivedItem(store.id, {selectedItems, exceptItems, selectedCategories}, active)
        .then((_) => {
          const detailsMessage = active ? 'In Stock' : 'Out of Stock';
          AnalyticsHelper.trackEditBulkItem(bulkCount, detailsMessage, 'Availability');
          setShowAvailabilityModal(false);
          setShowCategoryModal(false);
          setShowEditOptionModal(false);
          dispatch(setIsEditable(false));
          dispatch(hideLoading());
          onRefresh();
          dispatch(
            showAlert({
              heading: 'Success',
              message:
                'While updates are quick, please allow up to 1 hour for changes to appear across delivery partners.',
            }),
          );
        })
        .catch((error) => {
          dispatch(hideLoading());
          dispatch(
            showAlert({
              heading: 'Error',
              message: error?.message || 'Error occurred while Saving. Please, try again later.',
            }),
          );
        });
    } else {
      dispatch(showLoading());
      inventoryService
        .updateBulkItems(store?.id, {selectedItems, exceptItems, selectedCategories, updated_data}, active)
        .then((_) => {
          const categoryName = categories.find((obj) => updated_data.category_id === obj.id)?.name;
          var price: string = '';
          var priceDetail: string = '';
          if (updated_data.action_type === 'fixed') {
            priceDetail = 'New Price, Price';
            price = '$ ' + updated_data.price;
          } else if (updated_data.action_type === 'percentage') {
            priceDetail = 'Percentage, Price';
            price = updated_data.price + '%';
          } else {
            priceDetail = 'Adjustment, Price';
            price = '$' + updated_data.price;
          }
          AnalyticsHelper.trackEditBulkItem(
            bulkCount,
            updated_data.category_id ? categoryName : price,
            updated_data.category_id ? 'Category' : priceDetail,
          );
          setShowAvailabilityModal(false);
          setShowCategoryModal(false);
          setShowEditOptionModal(false);
          dispatch(setIsEditable(false));
          dispatch(hideLoading());
          resetReduxList();
          onRefresh();
        })
        .catch((error) => {
          dispatch(hideLoading());
          dispatch(
            showAlert({
              heading: 'Error',
              message: error?.message || 'Error occurred while Saving. Please, try again later.',
            }),
          );
        });
    }
  };

  const getInventoryCounts = async (title: ITEM_STATUS.IN_STOCK | ITEM_STATUS.ARCHIVED) => {
    await dispatch(fetchInventoryCount());
    await dispatch(fetchCategoryCount(title === ITEM_STATUS.IN_STOCK));
  };

  const deleteInventory = (active: boolean) => {
    dispatch(showLoading());
    inventoryService
      .deleteBulkItems(store.id, {selectedItems, exceptItems, selectedCategories}, active)
      .then(() => {
        const bulkCount = getTotalSelectItemCount(selectedCategories, selectedItems, exceptItems, categoryCount);
        AnalyticsHelper.trackEditBulkItem(bulkCount, 'Delete', 'Delete');
        setShowAvailabilityModal(false);
        setShowCategoryModal(false);
        setShowEditOptionModal(false);
        setShowDeleteModal(false);
        dispatch(setIsEditable(false));
        setShowMenuModal(false);
        setShowNewMenuModal(false);
        dispatch(hideLoading());
        onRefresh();
      })
      .catch((error) => {
        dispatch(hideLoading());
        dispatch(
          showAlert({
            heading: 'Error',
            message: error?.message || 'Error occurred while Saving. Please, try again later.',
          }),
        );
      });
  };

  const menuBulkItem = async (menuIds: Array<string>, active: boolean, addToMenu: boolean) => {
    dispatch(showLoading());
    if (menuIds.length) {
      try {
        if (addToMenu) {
          await inventoryService.menuBulkItems(
            store?.id,
            {selectedItems, exceptItems, selectedCategories, menuIds},
            active,
          );
        } else {
          await inventoryService.deleteMenuBulkItems(
            store?.id,
            {selectedItems, exceptItems, selectedCategories, menuIds},
            active,
          );
        }

        setShowAvailabilityModal(false);
        setShowCategoryModal(false);
        setShowEditOptionModal(false);
        setShowDeleteModal(false);
        dispatch(setIsEditable(false));
        dispatch(hideLoading());
        resetReduxList();
      } catch (error: any) {
        dispatch(hideLoading());
        dispatch(
          showAlert({
            heading: 'Error',
            message: error?.message || 'Error occurred while Saving. Please, try again later.',
          }),
        );
      }
    }
  };

  const openHelp = () => window.Intercom('showArticle', 7277588);

  const goToSorting = () => {
    if (isDesktop) {
    } else history.push({pathname: '/inventory/sorting', state: {title: title}});
  };

  const goToFiltering = () => {
    if (isDesktop) {
    } else history.push({pathname: '/inventory/filtering', state: {title: title}});
  };

  const goToDetails = (item: IItem, isNewItem = false) => {
    dispatch(setNewMenuId(''));
    if (isNewItem) {
      if (isDesktop) {
        setShowDetails(true);
        setShowBulkIncompleteDetails(false);
        setShowBulkOutOfStockDetails(false);
        setId('');
      } else history.push({pathname: '/items/details', state: {id: '', type: ITEM_TYPE.NEW}});
    } else {
      if (isDesktop) {
        setShowDetails(true);
        setShowBulkIncompleteDetails(false);
        setShowBulkOutOfStockDetails(false);
        setId(item.storeItemId);
        if (searchedItems.length > 0) {
          const updatedItems = searchedItems.map((obj) =>
            obj.storeItemId === item.storeItemId ? {...item, activeIndex: true} : {...obj, activeIndex: false},
          );
          setSearchedItems(updatedItems);
        } else {
          dispatch(activeItem(item));
        }
      } else history.push({pathname: '/items/details', state: {id: item.storeItemId, type: ITEM_TYPE.EDIT}});
    }
  };

  const onSegmentChange = (e: any) => {
    // setTitle(e.detail.value);
    setSearchedItems([]);
    setSearchTerm('');
    setSearchOffset(0);
    setShowDetails(false);
    if (e.detail.value === ITEM_STATUS.IN_STOCK && inStockItems.length === 0) dispatch(fetchInStockItems());
    if (e.detail.value === ITEM_STATUS.ARCHIVED && archivedItems.length === 0) dispatch(fetchArchivedItems());
  };

  const onTitleChange = async (title: ITEM_STATUS.IN_STOCK | ITEM_STATUS.ARCHIVED) => {
    setTitle(title);
    setSearchedItems([]);
    setSearchTerm('');
    setSearchOffset(0);
    setShowDetails(false);
    dispatch(setInventoryTab(title));
    dispatch(setIsEditable(false));
    if (title === ITEM_STATUS.IN_STOCK && inStockItems.length === 0) dispatch(fetchInStockItems());
    if (title === ITEM_STATUS.ARCHIVED && archivedItems.length === 0) dispatch(fetchArchivedItems());
    await dispatch(fetchCategoryCount(title === ITEM_STATUS.IN_STOCK));
  };

  const searchInventory = (refreshOffset?: number) => {
    const offset = refreshOffset === 0 ? refreshOffset : searchOffset;
    dispatch(setLoading(true));
    if (searchTerm.trimStart().length) {
      inventoryService
        .searchInventory(searchTerm.trimStart(), getInventoryType(title), offset, store, queryObject)
        .then((items) => {
          if (offset === 0) setSearchedItems(items);
          else setSearchedItems([...searchedItems, ...items]);
          if (items.length < 20) setLoadMoreSearch(false);
          else setSearchOffset(offset + 20);
          dispatch(setLoading(false));
        });
    }
  };

  const fetchNextItems = async (e: any) => {
    const item = getItems();
    if (item && item?.length >= 20) {
      const {scrollHeight, scrollTop, clientHeight} = e.target;
      const loadMore = scrollHeight - scrollTop <= clientHeight + 10;

      if (loadMore && !loading) {
        if (searchTerm.trimStart().length && showSearch) {
          if (loadMoreSearch) searchInventory();
        } else {
          if (title === ITEM_STATUS.IN_STOCK && loadMoreInStock) dispatch(fetchInStockItems());

          if (title === ITEM_STATUS.ARCHIVED && loadMoreArchived) dispatch(fetchArchivedItems());
        }
      }
    }
  };

  const onInputChange = (e: any) => {
    setSearchOffset(0);
    setSearchTerm(e.detail.value!);
    setQueryObject({searchString: e.detail.value!});
    setLoadMoreSearch(true);
  };

  const getItems = () => {
    if (showSearch && searchTerm.trimStart().length) return searchedItems;
    else {
      if (title === ITEM_STATUS.IN_STOCK) return inStockItems;
      if (title === ITEM_STATUS.ARCHIVED) return archivedItems;
    }
  };

  const updateBulkIncompleteDetails = () => {
    if (isDesktop) {
      setShowBulkIncompleteDetails(true);
      setShowBulkOutOfStockDetails(false);
      setShowDetails(false);
    } else history.push({pathname: '/items/details', state: {type: ITEM_TYPE.COMMON}});
  };

  const updateBulkOutOfStockDetails = () => {
    if (isDesktop) {
      setShowBulkOutOfStockDetails(true);
      setShowBulkIncompleteDetails(false);
      setShowDetails(false);
    } else history.push({pathname: '/items/details', state: {type: ITEM_TYPE.OUT_OF_STOCK}});
  };

  const onRefresh = async () => {
    dispatch(setInventoryTab(title));
    if (isDesktop) {
      setShowDetails(false);
    }
    if (showSearch && searchTerm.trimStart().length) {
      setSearchOffset(0);
      setLoadMoreSearch(true);
      searchInventory(0);
    } else {
      await dispatch(fetchInventoryStats())
        .unwrap()
        .then((inventoryStats: IInventoryStats) => {
          const maxPrice =
            inventoryStats.maxPrice < queryObject.maxPrice ? inventoryStats.maxPrice : queryObject.maxPrice;
          const minPrice =
            inventoryStats.minPrice > queryObject.minPrice ? inventoryStats.minPrice : queryObject.minPrice;
          setQueryObject({minPrice, maxPrice});
        });
      dispatch(refreshInStockInventory());
      await dispatch(fetchInStockItems())
        .unwrap()
        .then((items: any) => {
          if (items?.length) dispatch(setInStockInventory(items));
          else dispatch(setInStockInventory([]));
        });
      dispatch(refreshArchivedInventory());
      await dispatch(fetchArchivedItems())
        .unwrap()
        .then((items: any) => {
          if (items?.length) dispatch(setArchivedInventory(items));
          else dispatch(setArchivedInventory([]));
        });
      dispatch(refreshOutOfStockInventory());
      await dispatch(fetchOutOfStockItems())
        .unwrap()
        .then((items: any) => {
          if (items?.length) dispatch(setOutOfStockInventory(items));
          else dispatch(setOutOfStockInventory([]));
        });
      dispatch(refreshIncompleteInventory());
      await dispatch(fetchIncompleteItems())
        .unwrap()
        .then((items: any) => {
          if (items?.length) dispatch(setIncompleteInventory(items));
          else dispatch(setIncompleteInventory([]));
        });
    }
    getInventoryCounts(title);
  };

  const resetReduxList = async () => {
    dispatch(setIsEditable(false));
    dispatch(setSelectedCategory([]));
    dispatch(setExceptItems([]));
    dispatch(setSelectedItems([]));
  };

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      if (!!searchTerm) searchInventory();
    }, 750);
    return () => clearTimeout(delayDebounceFn);
  }, [searchTerm]);

  useEffect(() => {
    if (!!searchTerm) searchInventory(0);
  }, [queryObject]);

  useEffect(() => {
    if (inStockItems.length === 0) {
      const fetch = async () => {
        await dispatch(fetchInventoryStats())
          .unwrap()
          .then(() => clearInventoryQuery());
        getInventoryCounts(title);
        dispatch(fetchInStockItems());
        dispatch(fetchArchivedItems());
      };
      fetch();
    }
    dispatch(getMenu());
  }, [store?.id]);

  useEffect(() => {
    resetReduxList();
    dispatch(activeItem(null));
  }, [title]);

  const props = {
    inventoryStats,
    queryObject,
    loading,
    showSearch,
    categories,
    categoryCount,
    title,
    items: getItems(),
    searchTerm,
    searchedItems,
    isFiltered,
    showDeleteModal,
    showEditOptionModal,
    showAvailabilityModal,
    showPriceModal,
    canCreate,
    canDelete,
    canEdit,
    onTitleChange,
    setShowSearch,
    onSegmentChange,
    fetchNextItems,
    goToCommonItems,
    openHelp,
    goToDetails,
    onInputChange,
    goToSorting,
    goToFiltering,
    setShowDeleteModal,
    setShowEditOptionModal,
    setShowAvailabilityModal,
    setShowPriceModal,
    validatePriceInput,
    searchInventory,
    patchInventory,
    showCategoryModal,
    setShowCategoryModal,
    deleteInventory,
    showMenuModal,
    setShowMenuModal,
    menuList: parseMenuList(menuList),
    showNewMenuModal,
    setShowNewMenuModal,
    menuBulkItem,
    showNewMenuDetail,
    setShowNewMenuDetail,
    onNewMenuClick,
    showBulkIncompleteDetails,
    setShowBulkIncompleteDetails,
    updateBulkIncompleteDetails,
    showBulkOutOfStockDetails,
    setShowBulkOutOfStockDetails,
    updateBulkOutOfStockDetails,
  };

  return (
    <>
      <Desktop>
        <DesktopScreen {...{...props, id, showDetails, setShowDetails, title, history}} />
      </Desktop>
      <Tablet>
        <TabScreen {...{...props, openScanner, menuIsOpen, onRefresh}} />
      </Tablet>
      <Mobile>
        <MobileScreen {...{...props, openScanner, menuIsOpen, onRefresh}} />
      </Mobile>
    </>
  );
};

export default withScanner(InventoryList);
