import {useRef, useState} from 'react';
import {IonIcon, IonLabel, IonPopover, IonList} from '@ionic/react';
import './styles.scss';
import {Controller, Control} from 'react-hook-form';
import {useAppSelector} from 'src/hooks';
import {IRole} from 'src/interfaces/user';
import {IEmployeeDetailFormInput} from 'src/interfaces/form';
import {nonRBACRoles, RULES} from 'src/utils/constants';

interface IProps {
  control: Control<IEmployeeDetailFormInput>;
}

const getSelectedRole = (roles: Array<IRole>, selectedRoleId: string) => roles.find((r) => r.id === selectedRoleId);

const RolesDropdown = ({control}: IProps) => {
  const [isOpen, setIsOpen] = useState(false);

  const {isRBACStore} = useAppSelector((state) => state.store);
  const {roles} = useAppSelector((state) => state.auth);
  const ROLES = isRBACStore ? roles : nonRBACRoles.filter((role) => role.id !== 'admin');

  const popover = useRef<HTMLIonPopoverElement>(null);

  const openPopover = (e: any) => {
    popover.current!.event = e;
    setIsOpen(true);
  };

  return (
    <Controller
      name="role"
      control={control}
      rules={RULES.required}
      render={({field: {onChange, value}, fieldState: {error, invalid}}) => {
        const ROLE = getSelectedRole(ROLES, value);

        return (
          <div id="roles-dropdown-container">
            <IonLabel className="roles-dropdown-label">Role</IonLabel>

            <div
              className={`roles-dropdown ${isOpen ? 'opened' : ''} ${invalid ? 'invalid' : ''}`}
              onClick={(e) => openPopover(e)}>
              <div className="role">
                {!!value ? (
                  <>
                    <IonIcon className={ROLE?.icon} style={{color: ROLE?.color}} />
                    <IonLabel>{ROLE?.name}</IonLabel>
                  </>
                ) : (
                  <p className="role-placeholder">Select Role</p>
                )}
              </div>
              <IonIcon className={isOpen ? 'icon-chevron-up' : 'icon-chevron-down'} slot="end" />
            </div>
            {error && <p className="error">{error.message}</p>}

            <IonPopover
              className="roles-popover"
              mode="md"
              showBackdrop={false}
              ref={popover}
              isOpen={isOpen}
              alignment="start"
              onDidDismiss={() => setIsOpen(false)}>
              {ROLES.map((role) => (
                <IonList
                  key={role.id}
                  onClick={(e: any) => {
                    onChange(role.id);
                    setIsOpen(false);
                  }}>
                  <IonIcon className={role.icon} style={{color: role.color}} />
                  <IonLabel>{role.name}</IonLabel>
                </IonList>
              ))}
            </IonPopover>
          </div>
        );
      }}
    />
  );
};

export default RolesDropdown;
