import {useEffect, useState} from 'react';
import {IonIcon, IonText} from '@ionic/react';
import Countdown from 'react-countdown';
import './styles.scss';
import {ORDER_STATUS} from 'src/enums/order';

interface IProps {
  order: any;
  completionDate: string;
}

const StatusBar = ({order, completionDate}: IProps) => {
  const [count, setCount] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      setCount(count + 1);
    }, 1000);

    return () => clearInterval(interval);
  });

  return (
    <div id="order-status-bar">
      {order?.status === ORDER_STATUS.WAITING &&
        (!order?.ongoing ? (
          <div className="action active-order">
            <IonIcon className="icon-stopwatch" />
            <IonText>Bag order within</IonText>
            <Countdown
              date={new Date(completionDate)}
              renderer={({minutes, seconds, completed}) =>
                !completed ? (
                  <IonText className="time">{`${minutes < 10 ? '0' + minutes : minutes}:${
                    seconds < 10 ? '0' + seconds : seconds
                  }`}</IonText>
                ) : (
                  <IonText className="time flash">00:00</IonText>
                )
              }
            />
          </div>
        ) : (
          <div className="action active-order">
            <IonIcon className="icon-car" />
            <IonText>Waiting for driver</IonText>
          </div>
        ))}

      {order?.status === ORDER_STATUS.COMPLETED && (
        <div className="action completed">
          <IonIcon className="icon-complete-2" />
          <IonText>Completed</IonText>
        </div>
      )}

      {order?.status === ORDER_STATUS.CANCELLED && (
        <div className="action cancelled">
          <IonIcon className="icon-close" />
          <IonText>Cancelled by DSP</IonText>
        </div>
      )}

      {order?.status === ORDER_STATUS.DENIED && (
        <div className="action denied">
          <IonIcon className="icon-close" />
          <IonText>Denied by store</IonText>
        </div>
      )}
    </div>
  );
};

export default StatusBar;
