import {makeRequest} from 'src/Axios';

const categoryService = {
  getCategories: async () => {
    try {
      const response: any = await makeRequest({url: '/inventory/category/all', isMicroservice: true});
      return response.data
        .map(({id, name}: any) => ({id, name}))
        .sort((a: any, b: any) => a.name.localeCompare(b.name));
    } catch (error: any) {
      throw error.response?.data;
    }
  },
  getSubcategories: async () => {
    try {
      const response: any = await makeRequest({url: '/inventory/subcategory/all', isMicroservice: true});
      return response.data.map(({id, name, category_id}: any) => ({id, name, category_id}));
    } catch (error: any) {
      throw error.response?.data;
    }
  },
};

export default categoryService;
