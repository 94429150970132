import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {showAlert} from 'src/store/alertSlice';
import {ICategory, ISubcategory} from 'src/interfaces/item';
import categoryService from 'src/services/category';

interface IState {
  categories: Array<ICategory>;
  subcategories: Array<ISubcategory>;
}

export const fetchCategoriesAndSubcategories = createAsyncThunk(
  'category/fetchCategoriesAndSubcategories',
  async (_, {dispatch, fulfillWithValue, rejectWithValue}: any) => {
    try {
      const categories = await categoryService.getCategories();
      const subcategories = await categoryService.getSubcategories();
      return fulfillWithValue({categories, subcategories});
    } catch (error: any) {
      dispatch(
        showAlert({
          heading: 'Unable to fetch data',
          message: error?.message || 'Some error occurred while fetching data. Please, try again later',
        }),
      );
      return rejectWithValue(error);
    }
  },
);

const initialState: IState = {
  categories: [],
  subcategories: [],
};

const categorySlice = createSlice({
  name: 'category',
  initialState,
  reducers: {
    setCategories: (state, action) => {
      state.categories = action.payload;
    },
    setSubcategories: (state, action) => {
      state.subcategories = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchCategoriesAndSubcategories.fulfilled, (state, action) => {
      state.categories = action.payload.categories;
      state.subcategories = action.payload.subcategories;
    });
  },
});

export const {setCategories, setSubcategories} = categorySlice.actions;

export default categorySlice.reducer;
