import {useState} from 'react';
import {IonPage, IonContent} from '@ionic/react';
import './styles.scss';
import CommonHeader from 'src/components/CommonHeader';
import {useAppSelector, useEffectOnce} from 'src/hooks';

const Billing = () => {
  const {menuIsOpen} = useAppSelector((state) => state.auth);
  const {company} = useAppSelector((state) => state.store);
  const {isTablet} = useAppSelector((state) => state.platform);

  const [ready, setReady] = useState(false);

  useEffectOnce(() => {
    const script = document.createElement('script');
    script.src = 'https://embed.explo.co/bundle.js';
    script.async = false;
    script.crossOrigin = 'anonymous';
    document.body.appendChild(script);
    setReady(true);
  });

  return (
    <IonPage id="billing-page" className={`${isTablet && !menuIsOpen ? 'menu-close' : ''}`}>
      <IonContent fullscreen scrollY={false} className="page-container">
        <CommonHeader className="title-left" title="Billing" menuIsOpen={menuIsOpen} />

        {ready && (
          <div className="body">
            <explo-dashboard
              dash-customer-token={`jDxemMExLG:${process.env.REACT_APP_EXPLO_CUSTOMER_TOKEN}`}
              updateUrlParams={true}
              isProduction={process.env.REACT_APP_EXPLO_ENVIRONMENT === 'production'}
              environment={process.env.REACT_APP_EXPLO_ENVIRONMENT}
              refresh-minutes={10}
              variables={JSON.stringify({company_id: [company?.id]})}
            />
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default Billing;
