import {IonButton, IonIcon, IonLabel, IonText} from '@ionic/react';
import {alertCircleOutline, documentTextOutline, checkmark} from 'ionicons/icons';
import './styles.scss';
import NoItem from 'src/components/NoItem';
import ReceiptItem from 'src/pages/Orders/components/ReceiptItem';
import InstructionsCard from 'src/pages/Orders/components/InstructionsCard';
import {PrinterService} from 'src/services/printer';
import {useAppSelector} from 'src/hooks';
import footer from 'src/assets/images/order-receipt-footer.svg';
import utensils from 'src/assets/images/utensils.svg';
import ItemDetailsModal from 'src/components/modals/ItemDetail';
import {useState} from 'react';
import {IOrderItemDetails} from 'src/interfaces/item';

const PriceItem = ({title, value}: {title: string; value: string}) => (
  <div className="price-item">
    <div>{title}</div>
    <div>{value}</div>
  </div>
);

interface IProps {
  order: any;
  isSelectable: boolean;
  checkedItems: Array<string>;
  setCheckedItems: (e: any) => void;
}

const OrderReceipt = ({order, isSelectable, checkedItems, setCheckedItems}: IProps) => {
  const [dataForDetailsModal, setDataForDetailsModal] = useState<IOrderItemDetails | null>(null);
  const {printer} = useAppSelector((state) => state.printer);

  return order ? (
    <div id="order-receipt">
      <div className="order-receipt-header">
        <IonLabel>Receipt</IonLabel>
        <p>
          Items <span>x {order?.quantity}</span>
        </p>
      </div>
      <div className="order-receipt-header-subtitle">
        <IonIcon icon={checkmark} />
        <IonText>Check off items by tapping as items are bagged</IonText>
      </div>
      <div className="order-receipt-body">
        <div className="order-receipt-content">
          {!!order?.allergens && order?.allergens !== 'N/A' && (
            <InstructionsCard
              variant="danger"
              icon={alertCircleOutline}
              title="Customer allergens"
              description={order.allergens}
            />
          )}

          {!!order?.disposableIncluded && (
            <InstructionsCard variant="warning" icon={utensils} title="Include utensils" />
          )}

          {!!order?.customerNote && order?.customerNote !== 'N/A' && (
            <InstructionsCard
              variant="warning"
              icon={documentTextOutline}
              title="Order instructions"
              description={order.customerNote}
            />
          )}

          {order?.orderItems?.map((item: any) => (
            <ReceiptItem
              key={item.id}
              item={item}
              orderId={order.id}
              isSelectable={isSelectable}
              checkedItems={checkedItems}
              setCheckedItems={setCheckedItems}
              setDataForDetailsModal={setDataForDetailsModal}
            />
          ))}

          <ItemDetailsModal
            item={dataForDetailsModal}
            isOpen={!!dataForDetailsModal?.id}
            onClose={() => setDataForDetailsModal(null)}
            onBack={() => setDataForDetailsModal(null)}
          />

          <div className="total-container">
            <div className="divider" />
            <div className="price-container">
              {order?.dis && <PriceItem title="Discount" value={'$ ' + order?.discount?.toFixed(2)} />}
              <PriceItem title="Subtotal" value={'$ ' + order?.subtotal?.toFixed(2)} />
              <PriceItem title="Tax" value={'$ ' + order?.tax?.toFixed(2)} />
              {!!order?.taxRemitted && (
                <PriceItem title="Remitted tax" value={'-$ ' + order?.taxRemitted?.toFixed(2)} />
              )}
              {!!order?.bagFee && <PriceItem title="Bag fee" value={'$ ' + order?.bagFee?.toFixed(2)} />}
            </div>
            <div className="divider" />
            <PriceItem title="Total" value={'$ ' + order?.totalPrice?.toFixed(2)} />
          </div>
        </div>
      </div>

      {printer?.connectionStatus && printer?.hasBluetoothPermission && (
        <IonButton
          className="btn-print-receipt"
          expand="block"
          onClick={() => PrinterService.getInstance().schedulePrintOrder(order, true)}>
          <IonIcon className="icon-print" />
          Print receipt
        </IonButton>
      )}

      <img className="order-receipt-footer" src={footer} alt="" />
    </div>
  ) : (
    <NoItem message="No Order Receipt!" />
  );
};

export default OrderReceipt;
