import {createSlice, createAsyncThunk} from '@reduxjs/toolkit';
import {showLoading, hideLoading} from 'src/store/loadingSlice';
import {showAlert} from 'src/store/alertSlice';
import storeService from 'src/services/store';
import {RootState} from 'src/store';

export const getActivePartners = createAsyncThunk(
  'partners/getActivePartners',
  async (_, {dispatch, getState, fulfillWithValue, rejectWithValue}): Promise<any> => {
    try {
      const {store: selectedStore} = getState() as RootState;
      if (selectedStore.id) {
        dispatch(showLoading());
        const activePartners = await storeService.getActivePartners();
        dispatch(hideLoading());
        return fulfillWithValue(activePartners);
      } else {
        return fulfillWithValue({
          doorDash: [],
          uberEats: [],
          grubHub: [],
        });
      }
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(
        showAlert({
          heading: 'Unable to fetch data',
          message: error?.message || 'Some error occurred while fetching data. Please, try again later',
        }),
      );
      return rejectWithValue(error);
    }
  },
);

export const createPartnerTestOrder = createAsyncThunk(
  'partners/createPartnerTestOrder',
  async (data: any, {dispatch, fulfillWithValue, rejectWithValue}): Promise<any> => {
    try {
      dispatch(showLoading());
      const testOrder = await storeService.createPartnerTestOrder(data);

      dispatch(hideLoading());
      return fulfillWithValue(testOrder);
    } catch (error: any) {
      dispatch(hideLoading());
      dispatch(
        showAlert({
          heading: 'Unable to send a test order',
          message: error?.message || 'Some error occurred while sending a test order. Please, try again later',
        }),
      );
      return rejectWithValue(error);
    }
  },
);

const initialState: any = {
  partners: {},
};

const partnerSlice = createSlice({
  name: 'partners',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getActivePartners.fulfilled, (state, action) => {
      state.partners = action.payload;
    });
  },
});

export default partnerSlice.reducer;
