import {IonContent, IonPage, IonButton, IonSlides, IonSlide, IonBackButton} from '@ionic/react';
import Header from 'src/components/Header';
import Slide from 'src/components/Slide';
import logo from 'src/assets/images/logo.png';
import arrowBack from 'src/assets/images/arrow-back.svg';
import './styles.scss';

interface IProps {
  startApp: () => void;
  openHelp: () => void;
  slideRef: any;
}

const MobileScreen = ({startApp, openHelp, slideRef}: IProps) => {
  return (
    <IonPage id="tutorial-page-mobile">
      <IonContent fullscreen scrollY={false}>
        <Header
          leftButton={<IonBackButton defaultHref="/welcome" text="" icon={arrowBack} />}
          rightButton={
            <IonButton fill="clear" strong onClick={startApp}>
              Skip
            </IonButton>
          }
        />

        <div className="body">
          <IonSlides ref={slideRef} pager options={{centeredSlides: true, slidesPerView: 'auto', spaceBetween: 20}}>
            <IonSlide>
              <Slide
                img={logo}
                title="Effortless Setup"
                desc="Get your store up and running and start adding items within minutes."
                openHelp={openHelp}
              />
            </IonSlide>

            <IonSlide>
              <Slide
                img={logo}
                title="Accept orders"
                desc="Once your store is live, customers can see your items and place orders."
                openHelp={openHelp}
              />
            </IonSlide>

            <IonSlide>
              <Slide
                img={logo}
                title="Track performance"
                desc="See what customers want and make real-time adjustments to your store."
                startApp={startApp}
              />
            </IonSlide>
          </IonSlides>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MobileScreen;
