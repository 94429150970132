import './styles.scss';

interface IProps {
  value: boolean;
  onChange: (value: boolean) => void;
}

const MenuEditTypeToggle = ({value, onChange}: IProps) => (
  <div id="menu-edit-type-toggle">
    <div className={value ? 'toggle-left' : 'toggle-right'}></div>

    <div className="toggle-text-container">
      <div className="toggle-text" onClick={() => onChange(true)}>
        <span className={value ? 'active ' : 'inactive'}>+ Add</span>
      </div>

      <div className="toggle-text" onClick={() => onChange(false)}>
        <span className={!value ? 'active' : 'inactive'}>- Remove</span>
      </div>
    </div>
  </div>
);

export default MenuEditTypeToggle;
