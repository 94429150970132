import {IonModal, IonButton, IonImg} from '@ionic/react';
import './styles.scss';
import itemSelected from 'src/assets/images/item-selected.svg';
import itemPlaceholder from 'src/assets/images/item-placeholder.png';

interface IProps {
  isOpen: boolean;
  image: string;
  title: string;
  addDetails: () => void;
  scanNext: () => void;
}

const ItemAddedModal = (props: IProps) => (
  <IonModal className="app-modal item-added-modal" isOpen={props.isOpen} showBackdrop backdropDismiss>
    <div className="modal-content-container">
      <div className="modal-content">
        <div className="img-container">
          <IonImg src={props.image ? props.image : itemPlaceholder} />
        </div>

        <IonImg className="item-selected-img" src={itemSelected} />

        <div className="details-container">
          <h2 className="modal-title mb-0">Item Added</h2>
          <p className="modal-desc">“{props.title}”</p>

          <IonButton className="btn-primary" expand="block" onClick={props.addDetails}>
            Add Item Details
          </IonButton>

          <IonButton className="btn-secondary" expand="block" onClick={props.scanNext}>
            Scan next Item
          </IonButton>

          <IonButton className="btn-primary footer-btn mb-0" expand="block" fill="clear" onClick={props.scanNext}>
            Wrong item? Re-Scan
          </IonButton>
        </div>
      </div>
    </div>
  </IonModal>
);

export default ItemAddedModal;
