import {useState} from 'react';
import {IonLabel, IonIcon} from '@ionic/react';
import {chevronDownOutline, chevronForwardOutline} from 'ionicons/icons';
import './styles.scss';
import moment from 'moment';
import MenuFilter from 'src/components/MenuFilter';
import MenuEditTypeToggle from 'src/components/MenuEditTypeToggle';
import {WEEK_DAYS} from 'src/utils/constants';
import {IMenuList, ITiming} from 'src/interfaces/store';
import {checkPermission} from 'src/utils/helpers';
import {useAppSelector} from 'src/hooks';
import * as PERMISSIONS from 'src/utils/permissions';

interface IProps {
  isMenuTitle: boolean;
  menuList: Array<IMenuList>;
  selectedMenu: Array<string>;
  timings: Array<ITiming>;
  addToMenu?: boolean;
  menuItem?: Array<string>;
  isBulkEditing?: boolean;
  onNewMenuClick: () => void;
  setMenu: (e: string) => void;
  setAddToMenu?: (value: boolean) => void;
}

const MenuItemHours = (props: IProps) => {
  const [showMenuDetail, setShowMenuDetail] = useState(true);

  const {user} = useAppSelector((state) => state.auth);

  const canEditMenu = checkPermission(user?.role.permissions, PERMISSIONS.EDIT_MENU);

  return (
    <div id="menu-container">
      {props.isMenuTitle && (
        <div className="hour-container">
          <p className="label">Item Hours</p>
          <div
            className="value-container"
            onClick={() => {
              if (showMenuDetail) setShowMenuDetail(false);
              else setShowMenuDetail(true);
            }}>
            <p>Custom menu hours</p>
            <IonIcon icon={showMenuDetail ? chevronDownOutline : chevronForwardOutline} slot="end" />
          </div>
        </div>
      )}
      {showMenuDetail && (
        <>
          <div className="menu-list">
            <div className="filter-group">
              {props.addToMenu !== undefined && props.setAddToMenu !== undefined && (
                <MenuEditTypeToggle value={props.addToMenu} onChange={props.setAddToMenu} />
              )}
              <IonLabel className="filter-title">Menu</IonLabel>
              {canEditMenu ? (
                <MenuFilter
                  isFilter={false}
                  isDesktop={true}
                  menuList={props.menuList}
                  onNewMenuClick={props.onNewMenuClick}
                  selectedMenu={props.selectedMenu}
                  setMenu={props.setMenu}
                  isBulkEditing={props.isBulkEditing}
                />
              ) : (
                <p>
                  {props.menuList.map((item: any, index) => (
                    <span>{index === props.menuList.length - 1 ? item.menu_name : `${item.menu_name}, `}</span>
                  ))}
                </p>
              )}
              {!props.addToMenu && (
                <p className="note">
                  Please note, the times below represent when the items will not be available or sold.
                </p>
              )}
            </div>
          </div>
          <div className="hour-label">
            <p>ITEM HOURS</p>
          </div>
          <div className="menu-hours">
            {WEEK_DAYS.map((day, i) => (
              <div className="day-item" key={i}>
                {props.timings[i]?.isClosed ? (
                  <>
                    <p>{day.title}</p>
                    <p>
                      <span>Unavailable</span>
                    </p>
                  </>
                ) : (
                  <>
                    <p>{day.title}</p>
                    <p>
                      {moment(props.timings[i]?.openTime, 'hh:mm A').format('hh:mm A')}
                      <span>to</span>
                      {moment(props.timings[i]?.closeTime, 'hh:mm A').format('hh:mm A')}
                    </p>
                  </>
                )}
              </div>
            ))}
          </div>
        </>
      )}
    </div>
  );
};

export default MenuItemHours;
