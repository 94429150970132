/* eslint-disable react-hooks/exhaustive-deps */
import {memo} from 'react';
import {IonButton, IonContent, IonIcon, IonImg, IonPage} from '@ionic/react';
import {checkmarkOutline} from 'ionicons/icons';
import {useHistory} from 'react-router';
import lulaStoreLogo from 'src/assets/images/store-logo.png';
import Footer from 'src/components/Footer';
import './styles.scss';

const ResetSuccess = memo(() => {
  const history = useHistory();

  return (
    <IonPage id="success">
      <IonContent fullscreen scrollY={false}>
        <div className="content-container">
          <div className="form-container m-20">
            <IonImg className="logo" src={lulaStoreLogo} />
            <div className="text-container">
              <h4 className="heading">
                New Password Created
                <span>
                  <IonIcon icon={checkmarkOutline} size="lg" />{' '}
                </span>
              </h4>
              <p className="description mb-0">Your new password has been created! You may now access Lula Store.</p>
            </div>

            <IonButton className="btn-dark mb-0 input-field" expand="block" onClick={() => history.push('/auth/login')}>
              Access Lula
            </IonButton>
          </div>
          <Footer backgroundColor="var(--custom-color-white)" />
        </div>
      </IonContent>
    </IonPage>
  );
});

export default ResetSuccess;
