/* eslint-disable react-hooks/exhaustive-deps */
import {useState} from 'react';
import {IonPage, IonContent, IonButton, IonRouterLink, IonGrid, IonRow, IonCol, IonImg} from '@ionic/react';
import './styles.scss';
import AppPartners from 'src/pages/Auth/components/Partners';
import {RouteComponentProps} from 'react-router';
import {useForm} from 'react-hook-form';
import {ILoginFormInput} from 'src/interfaces/form';
import userService from 'src/services/user';
import {setItem} from 'src/utils/storage';
import {useAppDispatch} from 'src/hooks';
import {showLoading, hideLoading} from 'src/store/loadingSlice';
import {fetchCategoriesAndSubcategories} from 'src/store/categorySlice';
import {showAlert} from 'src/store/alertSlice';
import {getProfile, setToken} from 'src/store/authSlice';
import {getStoreEmployeesCount, getStoreStatus} from 'src/store/storeSlice';
import AnalyticsHelper from 'src/utils/segment';
import {RULES, LULA_MANAGER_URL} from 'src/utils/constants';
import firebaseCrashlytics from 'src/utils/firebase-crashlytics';
import {ROUTE_LOCATION} from 'src/enums/route_location';
import {STORAGE} from 'src/enums/storage';
import Footer from 'src/components/Footer';
import Input from 'src/components/Input';
import lulaStoreLogo from 'src/assets/images/store-logo.png';

interface IProps extends RouteComponentProps {}

const Login = ({history}: IProps) => {
  const dispatch = useAppDispatch();
  const [hideLogin, setHideLogin] = useState(false);

  const {control, handleSubmit} = useForm<ILoginFormInput>({
    mode: 'onSubmit',
    defaultValues: {email: '', password: ''},
  });

  const login = (data: ILoginFormInput) => {
    dispatch(showLoading());
    userService
      .login(data)
      .then(async (res) => {
        if (res.new_password_required) {
          history.push({
            pathname: '/auth/new-password',
            state: {email: data.email, from: ROUTE_LOCATION.FORGOT_PASSWORD, token: res.token},
          });
          dispatch(hideLoading());
        } else {
          setHideLogin(true);
          setItem(STORAGE.HIDE_WELCOME, 'true');
          const {token, isRBACUser, lastFetched, refreshToken} = res;
          if (token) {
            setItem(STORAGE.TOKEN, token);
            if (isRBACUser && lastFetched) {
              setItem(STORAGE.LAST_FETCHED, lastFetched);
              setItem(STORAGE.REFRESH_TOKEN, refreshToken);
              setItem(STORAGE.ID_TOKEN, res.idToken);
            }
            dispatch(setToken(token));

            dispatch(getProfile())
              .unwrap()
              .then(async (user) => {
                await AnalyticsHelper.identifyLoginOrSignUp(user);
                AnalyticsHelper.trackLogin(user.role);
                AnalyticsHelper.groupStore();
                firebaseCrashlytics.setUserIdentifier(user);
                await dispatch(getStoreEmployeesCount());
                await dispatch(fetchCategoriesAndSubcategories());
                // Load active partners if any.
                await dispatch(getStoreStatus());
                history.replace('/orders');
              });
          }
        }
      })
      .catch((error) => {
        setItem(STORAGE.HIDE_WELCOME, 'true');
        dispatch(hideLoading());
        if (error?.status === 403)
          dispatch(
            showAlert({heading: 'Unable to login', message: error?.message || 'Incorrect username or password'}),
          );
        else dispatch(showAlert({heading: 'Unable to login', message: error?.message || 'Something went wrong'}));
      });
  };

  return hideLogin ? null : (
    <IonPage id="auth-page">
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonContent fullscreen scrollY={false}>
              <div className="content-container">
                <div className="form-container m-20">
                  <IonImg className="logo" src={lulaStoreLogo} />
                  <h4 className="heading">Welcome back!</h4>

                  <div className="input-field">
                    <Input
                      type="email"
                      inputmode="email"
                      name="email"
                      label="Email"
                      control={control}
                      rules={RULES.email}
                    />

                    <Input
                      type="password"
                      name="password"
                      label="Password"
                      control={control}
                      rules={RULES.required}
                      hideHelpText
                    />
                  </div>

                  <IonRouterLink className="link" routerLink="/auth/forgot-password">
                    Forgot your password?
                  </IonRouterLink>

                  <IonButton className="btn-dark mb-0 mt-0 input-field" expand="block" onClick={handleSubmit(login)}>
                    Log in
                  </IonButton>
                  <div className="divider"></div>

                  <p className="lm-link">
                    Looking for Lula Manager?{' '}
                    <IonRouterLink className="link" href={LULA_MANAGER_URL} target="_blank">
                      Log in here
                    </IonRouterLink>
                  </p>
                </div>
                <Footer backgroundColor="var(--custom-color-white)" />
              </div>
            </IonContent>
          </IonCol>

          <IonCol className="ion-hide-lg-down partners-column">
            <AppPartners />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonPage>
  );
};

export default Login;
