import {useState, useEffect} from 'react';
import {IonButton, IonContent, IonIcon, IonPage} from '@ionic/react';
import './styles.scss';
import {arrowBack, closeOutline} from 'ionicons/icons';
import Header from 'src/components/Header';
import MenuItemHours from 'src/components/MenuItemHours';
import {IMenuList, ITiming} from 'src/interfaces/store';
import menuService from 'src/services/menu';
import {WEEK_DAYS} from 'src/utils/constants';
import {useAppSelector, useEffectOnce} from 'src/hooks';

interface IProps {
  setShowEditModal: (e: boolean) => void;
  setShowMenuModal: (e: boolean) => void;
  isInStock: boolean;
  menuList: Array<IMenuList>;
  menuBulkItem: (data: Array<string>, active: boolean, addToMenu: boolean) => void;
  setShowNewMenuModal: (e: boolean) => void;
}

const MenuOptionModal = (props: IProps) => {
  const [selectedMenu, setSelectedMenu] = useState<Array<string>>([]);
  const [timings, setTimings] = useState<Array<ITiming>>([]);
  const [addToMenu, setAddToMenu] = useState(true);

  const {newMenuId} = useAppSelector((state) => state.inventory);
  const {id: storeId} = useAppSelector((state) => state.store);

  const onNewMenuClick = () => {
    props.setShowNewMenuModal(true);
  };

  const setMenu = (value: string) => {
    let newMenus: string[];
    newMenus = [...selectedMenu];
    if (newMenus.includes(value)) newMenus = newMenus.filter((item) => item !== value);
    else newMenus.push(value);
    setSelectedMenu(newMenus);
    if (!!newMenus.length) {
      menuService.getMenuHours(storeId, newMenus).then((res) => {
        setTimings(res);
      });
    } else {
      createHours();
    }
  };

  const createHours = () => {
    const hours = WEEK_DAYS.map((day) => ({
      id: '',
      openTime: '09:00',
      closeTime: '17:00',
      storeId: '',
      dayOfWeek: day.day_of_week,
      isClosed: true,
      invalid: false,
      updated: true,
    }));
    setTimings(hours);
  };

  const onSave = () => {
    if (selectedMenu.length) {
      props.menuBulkItem(selectedMenu, props.isInStock, addToMenu);
      props.setShowMenuModal(false);
      createHours();
      setSelectedMenu([]);
    }
  };

  useEffect(() => {
    setSelectedMenu([]);
    createHours();
  }, [addToMenu]);

  useEffect(() => {
    if (newMenuId) {
      setMenu(newMenuId);
    }
  }, [newMenuId]);

  useEffectOnce(() => {
    createHours();
  });

  return (
    <IonPage id="menu-option-mobile">
      <IonContent fullscreen scrollY={false}>
        <Header
          border
          noBackground
          title="Menu"
          leftButton={
            <IonIcon
              onClick={() => {
                props.setShowMenuModal(false);
              }}
              icon={arrowBack}></IonIcon>
          }
          rightButton={
            <IonIcon
              onClick={() => {
                props.setShowMenuModal(false);
                props.setShowEditModal(false);
              }}
              icon={closeOutline}></IonIcon>
          }
        />

        <div className="body">
          <MenuItemHours
            menuList={props.menuList}
            onNewMenuClick={onNewMenuClick}
            isMenuTitle={false}
            selectedMenu={selectedMenu}
            setMenu={setMenu}
            timings={timings}
            addToMenu={addToMenu}
            setAddToMenu={setAddToMenu}
            isBulkEditing={true}
          />
        </div>
        <div className="action-div">
          <IonButton
            disabled={false}
            className="action-btn"
            mode="ios"
            fill="solid"
            color="primary"
            expand="block"
            onClick={onSave}>
            Save edit
          </IonButton>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default MenuOptionModal;
