/* eslint-disable react-hooks/exhaustive-deps */
import {IonPage, IonContent, IonButton, IonRouterLink, IonGrid, IonCol, IonImg, IonRow, IonIcon} from '@ionic/react';
import './styles.scss';
import {RouteComponentProps} from 'react-router';
import {useForm} from 'react-hook-form';
import {IForgotPasswordFormInput} from 'src/interfaces/form';
import userService from 'src/services/user';
import {showLoading, hideLoading} from 'src/store/loadingSlice';
import {showAlert} from 'src/store/alertSlice';
import {useAppDispatch} from 'src/hooks';
import {ROUTE_LOCATION} from 'src/enums/route_location';
import {RULES} from 'src/utils/constants';
import Input from 'src/components/Input';
import Footer from 'src/components/Footer';
import arrowBack from 'src/assets/images/arrow-back-black.svg';
import lulaStoreLogo from 'src/assets/images/store-logo.png';
import AppPartners from 'src/pages/Auth/components/Partners';

interface IProps extends RouteComponentProps {}

const ForgotPassword = ({history}: IProps) => {
  const dispatch = useAppDispatch();

  const {control, handleSubmit} = useForm<IForgotPasswordFormInput>({
    mode: 'onSubmit',
    defaultValues: {
      email: '',
    },
  });

  const sendResetLink = (data: IForgotPasswordFormInput) => {
    dispatch(showLoading());
    userService
      .sendOTP(data.email)
      .then((response) => {
        dispatch(hideLoading());
        if (response.send_confirmation_code) {
          history.push({
            pathname: '/auth/reset-password',
            state: {email: data.email, from: ROUTE_LOCATION.FORGOT_PASSWORD},
          });
        } else {
          dispatch(
            showAlert({
              heading: 'Unable to send code',
              message: 'User does not exist',
            }),
          );
        }
      })
      .catch((error) => {
        dispatch(hideLoading());
        dispatch(
          showAlert({
            heading: 'Unable to send code',
            message: error?.message || 'Some error occurred while sending verification code. Please, try again later',
          }),
        );
      });
  };

  return (
    <IonPage id="auth-page">
      <IonGrid>
        <IonRow>
          <IonCol>
            <IonContent fullscreen scrollY={false}>
              <div className="content-container">
                <div className="form-container m-20">
                  <IonImg className="logo" src={lulaStoreLogo} />
                  <div className="text-container">
                    <h4 className="heading">Forgot your password?</h4>
                    <p className="description">
                      No worries, we will send you instruction on how to reset your password.
                    </p>
                  </div>

                  <Input
                    type="email"
                    inputmode="email"
                    wrapperClassName="input-field"
                    name="email"
                    label="Email"
                    control={control}
                    rules={RULES.email}
                  />

                  <IonButton className="btn-dark mb-0 input-field" expand="block" onClick={handleSubmit(sendResetLink)}>
                    Reset password
                  </IonButton>
                  <div className="divider"></div>
                  <div className="back-link">
                    <IonIcon icon={arrowBack} />
                    <IonRouterLink routerDirection="back" className="link" routerLink="/auth/login">
                      Back to log in
                    </IonRouterLink>
                  </div>
                </div>
                <Footer backgroundColor="var(--custom-color-white)" />
              </div>
            </IonContent>
          </IonCol>

          <IonCol className="ion-hide-lg-down partners-column">
            <AppPartners />
          </IonCol>
        </IonRow>
      </IonGrid>
    </IonPage>
  );
};

export default ForgotPassword;
