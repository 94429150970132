/* eslint-disable react-hooks/exhaustive-deps */
import {useState} from 'react';
import {Controller} from 'react-hook-form';
import {IonInput, IonIcon, IonLabel, IonItem} from '@ionic/react';
import {eyeOutline, eyeOffOutline} from 'ionicons/icons';
import {TextFieldTypes} from '@ionic/core';
import './styles.scss';
import {maskValue, unMaskValue} from 'src/utils/helpers';

interface IProps {
  wrapperClassName?: string;
  className?: string;
  type: TextFieldTypes;
  inputmode?: 'email' | 'search' | 'tel' | 'text' | 'url' | 'none' | 'numeric' | 'decimal' | undefined;
  placeholder?: string;
  name: string;
  rules?: any;
  control: any;
  label?: string;
  optional?: boolean;
  disabled?: boolean;
  mask?: string;
  maxLength?: number;
  hideHelpText?: boolean;
  addressPredictions?: Array<any>;
  showPredictions?: boolean;
  onClickAddress?: (id: string) => void;
  onTypingAddress?: (value: any) => void;
  onInputChange?: (value: any) => void;
  onFocus?: () => void;
}

const Input = (props: IProps) => {
  const [showPassword, setShowPassword] = useState(false);
  return (
    <div id="input-container" className={props.wrapperClassName}>
      {props.label && (
        <IonLabel>
          {props.label}
          {props.optional && <span> (optional)</span>}
        </IonLabel>
      )}
      <Controller
        name={props.name}
        control={props.control}
        rules={props.rules || {}}
        render={({field: {onChange, onBlur, value, ref}, formState: {isValid}, fieldState: {error, invalid}}) => (
          <div className="input-wrapper">
            <IonInput
              className={`${invalid && !isValid ? 'invalid' : ''} ${props.className ? props.className : ''}`}
              type={props.type === 'password' && showPassword ? 'text' : props.type}
              inputmode={props.inputmode ? props.inputmode : 'text'}
              placeholder={props.placeholder}
              disabled={props.disabled}
              maxlength={props.mask ? props.mask.length : props.maxLength ? props.maxLength : undefined}
              value={value}
              onIonBlur={onBlur}
              ref={ref}
              onFocus={() => (props.onFocus ? props.onFocus() : null)}
              onIonChange={(e) => {
                e.detail.value = props.mask
                  ? maskValue(props.mask, unMaskValue(props.mask, e.detail.value!))
                  : e.detail.value;
                onChange(e.detail.value);
                props.onInputChange && props.onInputChange(e.detail.value);
              }}
              onIonInput={(e: any) => {
                if (props.onTypingAddress) props.onTypingAddress(e.target.value);
              }}
            />

            {props.type === 'password' && (
              <IonIcon
                className="eye-icon"
                icon={showPassword ? eyeOutline : eyeOffOutline}
                onClick={() => setShowPassword(!showPassword)}
              />
            )}
            {error && !isValid ? (
              <p className="msg error">{error.message}</p>
            ) : props.type === 'password' && !props.hideHelpText ? (
              <p className="msg">
                Your password must be at least 8 characters, and contain at least one uppercase letter, one number, and
                one special character.
              </p>
            ) : null}
          </div>
        )}
      />
      {(props.placeholder === 'Address' || 'Billing Address') &&
      props.showPredictions &&
      (props.addressPredictions?.length || [].length > 0) ? (
        <div className="address-predictions">
          {props.addressPredictions?.map((place, i) => (
            <IonItem
              key={i}
              onClick={() => {
                if (props.onClickAddress) {
                  props.onClickAddress(place.placeID);
                }
              }}>
              <p>{place.description}</p>
            </IonItem>
          ))}
        </div>
      ) : (
        <div></div>
      )}
    </div>
  );
};

export default Input;
