import {IonRouterLink} from '@ionic/react';
import './styles.scss';
import {PRIVACY_URL} from 'src/utils/constants';
import {useAppSelector} from 'src/hooks';

const PrivacyText = () => {
  const {isNative} = useAppSelector((state) => state.platform);
  return !isNative ? (
    <IonRouterLink id="privacy-link" href={PRIVACY_URL} target="_blank">
      Privacy Policy
    </IonRouterLink>
  ) : (
    <IonRouterLink id="privacy-link" routerLink="/support/privacy">
      Privacy Policy
    </IonRouterLink>
  );
};

export default PrivacyText;
